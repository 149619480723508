import React, {useEffect, useState} from 'react';
import './ConfigurationEntry.css';
import {useAppDispatch, useAppSelector} from '../../../../app/hooks';
import {
	selectAllAccessoryConfigurations,
	selectAllInputValues,
	selectCartRequest,
	setAccessoryConfigurations,
	setActiveProductId,
	setProductInputValues
} from '../../ProductInputSlice';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {ConfigurationPageNavigation} from "../pageNavigation/ConfigurationPageNavigation";
import {VariantOverview} from "../variantOverview/VariantOverview";
import {CheckoutSignIn} from "../../../user/checkoutSignIn/CheckoutSignIn";
import {ConfigurationGroup} from "../ConfigurationGroup";
import {ConfigurationPaging, ReviewPage} from "../ConfigurationPaging";
import {Configuration} from "../Configuration";
import {ConfigurationGroupView} from "../groupView/ConfigurationGroupView"
import {Utils} from "../../../../utils/Utils";
import {ConfigurationControlBar} from "../controlBar/ConfigurationControlBar";
import {AddToCartPreview} from "../../../cart/addToCartPreview/AddToCartPreview";
import {RequiredToast} from "../../utils/RequiredToast";
import {ConfigurationReview} from "../review/ConfigurationReview";
import {ConfigurationRuleEvaluator} from "../rule/ConfigurationRuleEvaluator";
import {
	useAddProductToCartMutation,
	useGetCartProductInputValuesQuery,
	useGetConfigurationsForSiteProductQuery,
	useGetOrderProductInputValuesQuery,
	useGetProductQuery,
	useGetSiteProductVariantByIdQuery,
	useGetSiteProductVariantQuantityOptionQuery
} from "../../../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {RuleTypes} from "../rule/ConfigurationRule";
import {CartIdType, selectCartId, setCartId} from "../../../cart/CartSlice";
import ScrollToTop from "../../../helpers/ScrollToTop";
import {AddressInputVm} from "../../address/Address";
import {InputClassification, ProductInputValue} from "../../ProductInputValue";
import {Helmet} from "react-helmet-async";
import {selectCurrentConsumerUser} from "../../../user/login/AuthenticationSlice";
import {SimpleToast} from "../../utils/SimpleToast";
import {LoadingSpinner} from "../../utils/LoadingSpinner";
import {WideMessage} from "../../utils/WideMessage";
import {Container} from "reactstrap";
import {AnalyticsTools} from "../../../../utils/AnalyticsHelper";

export const ConfigurationEntryRoute = () => {
	let {siteProductVariantId, quantityOptionId} = useParams();
	const [searchParams] = useSearchParams();
	const pageNumber = searchParams.get("pageNumber");
	const cartProductId = searchParams.get("cartProductId");
	const consumerId = useAppSelector(selectCurrentConsumerUser)?.id;

	return (
		<ConfigurationEntry
			siteProductVariantId={Number.parseInt(siteProductVariantId!)}
			quantityOptionId={Number.parseInt(quantityOptionId!)}
			requestedPageIndex={pageNumber ? Number.parseInt(pageNumber) - 1 : undefined}
			propCartProductId={cartProductId ? Number.parseInt(cartProductId) : undefined}
			consumerId={consumerId}
			showCheckoutSignIn={true}/>
	)
}

interface ConfigurationEntryProps {
	siteId?: number,
	siteProductVariantId: number,
	quantityOptionId: number,
	requestedPageIndex?: number,
	propCartProductId?: number,
	propOrderProductId?: number,
	showCheckoutSignIn?: boolean,
	consumerId?: number,
	propCartId?: CartIdType,
	onVariantChangeClick?: () => void,
	onDone?: (cartId: CartIdType) => void,
	onBack?: () => void,
	onCancelChanges?: () => void,
}

export const ConfigurationEntry = ({
									   siteId,
									   siteProductVariantId,
									   quantityOptionId,
									   requestedPageIndex,
									   propCartProductId,
									   propOrderProductId,
									   showCheckoutSignIn = false,
									   consumerId,
									   propCartId,
									   onVariantChangeClick,
									   onDone,
									   onBack,
									   onCancelChanges
								   }: ConfigurationEntryProps) => {
	const {
		data: quantityOption,
		isFetching: isFetchingQuantityOption
	} = useGetSiteProductVariantQuantityOptionQuery(quantityOptionId!);
	const {
		data: siteProductVariant,
		isFetching: isFetchingProductVariant,
		isUninitialized: productVariantUninitialized
	} = useGetSiteProductVariantByIdQuery(siteProductVariantId);
	const {
		data: product,
		isFetching: isFetchingProduct
	} = useGetProductQuery(siteProductVariant?.siteProductId ?? skipToken);
	// skip this request if we're productVariant is uninitialized
	const {
		data: configurations,
		isFetching: isFetchingConfigurations
	} = useGetConfigurationsForSiteProductQuery(productVariantUninitialized || !siteProductVariant ? skipToken : {
		siteProductId: siteProductVariant.siteProductId,
		siteProductVariantId,
		quantityOptionId
	});
	const selectedCartId = useAppSelector(selectCartId);
	const cartId: CartIdType = propCartId ? propCartId : selectedCartId;
	const [cartProductId, setCartProductId] = useState<number | undefined>(propCartProductId);
	const productInputs = useAppSelector(selectAllInputValues);
	const accessoryConfigurations = useAppSelector(selectAllAccessoryConfigurations);
	const cartRequest = useAppSelector(s => selectCartRequest(s, siteProductVariantId, quantityOptionId, consumerId, cartId));
	const {
		data: cartProductInputValues,
		isError: isCartProductInputValuesError
	} = useGetCartProductInputValuesQuery(cartProductId ?? skipToken);
	
	const {
		data: orderProductInputValues,
		isError: isOrderProductInputValuesError
	} = useGetOrderProductInputValuesQuery(propOrderProductId ?? skipToken);
	const [addProductToCart, {isLoading: isAddingToCart}] = useAddProductToCartMutation();
	const [currentPageIndex, setCurrentPageIndex] = useState(0);
	const [currentPage, setCurrentPage] = useState<ConfigurationPaging | undefined>(undefined);
	const [groupedConfigurations, setGroupedConfigurations] = useState<ConfigurationGroup[]>([]);
	const [pages, setPages] = useState<ConfigurationPaging[]>([]);
	const [showRequiredFieldToast, setShowRequiredFieldToast] = useState(false);
	const [hasInitialized, setHasInitialized] = useState(false);
	const [checkInputs, setCheckInputs] = useState(false);
	const [showErrorToast, setShowErrorToast] = useState(false);
	const [errorToastText, setErrorToastText] = useState<string | undefined>();
	const [searchParams] = useSearchParams();
	const currentPathAndParams = window.location.pathname + searchParams;
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const consumer = useAppSelector(selectCurrentConsumerUser);
	const isReadOnly = !!propOrderProductId;

	useEffect(() => {
		const preventUnload = (event: BeforeUnloadEvent) => {
			// NOTE: This message isn't used in modern browsers, but is required
			const message = 'Are you sure you want to leave? Some data may be lost.';
			event.preventDefault();
			event.returnValue = message;
		};
		window.addEventListener('beforeunload', preventUnload);

		return () => {
			window.removeEventListener('beforeunload', preventUnload);
		};
	}, []);

	useEffect(() => {
		if ((cartProductInputValues ?? orderProductInputValues) && siteProductVariant) {
			dispatch(setActiveProductId(siteProductVariant.siteProductId));
			dispatch(setProductInputValues(cartProductInputValues ?? orderProductInputValues ?? []));
		} else if (isCartProductInputValuesError || isOrderProductInputValuesError) {
			// Clear input values on error, initialize state
			dispatch(setProductInputValues([]));
			setCartProductId(undefined);
			setHasInitialized(true);
			updateSelectedPage(0, false, false);
		}
	}, [cartProductInputValues, orderProductInputValues, siteProductVariant, isCartProductInputValuesError, isOrderProductInputValuesError]);

	useEffect(() => {
		if (requestedPageIndex !== currentPageIndex) updateSelectedPage(requestedPageIndex ?? 0, false, false);
	}, [requestedPageIndex]);

	useEffect(() => {
		const pages = getPagesFromConfiguration(configurations);
		// If readonly, force last page, otherwise get current page
		const currentPage = isReadOnly ? pages[pages.length - 1] : (pages.length > currentPageIndex ? pages[currentPageIndex] : undefined);
		setCurrentPage(currentPage);
		setPages(pages);
	}, [currentPageIndex, configurations]);

	useEffect(() => {
		if (configurations && currentPage) {
			const groupedConfigurations = getGroupedConfigurations();
			setGroupedConfigurations(groupedConfigurations);
		}
	}, [configurations, currentPage, accessoryConfigurations])

	// Send product config event to Google Analytics.
	useEffect(() => {
		if (currentPage !== undefined) {
			AnalyticsTools.recordProductConfigPageLoadEvent(currentPage?.name, currentPageIndex);
		}
	}, [currentPage])

	// On component unmount
	useEffect(() => {
		return (() => {
			dispatch({type: 'productVariants/reset'});
		})
	}, [dispatch]);

	const dismissRequiredFieldToast = () => {
		setShowRequiredFieldToast(false);
	}

	const getDocumentMetadata = () => {
		if (!siteProductVariant) {
			return null;
		}

		return (
			<Helmet>
				<title>{siteProductVariant.metaTitle}</title>
				<meta name="description" content={siteProductVariant.metaDescription}/>
			</Helmet>
		);
	}

	const updateSelectedPage = (requestedPageIndex: number, shouldScroll?: boolean, appendToHistory: boolean = true) => {
		if (isReadOnly) {
			// Force readonly to be final review page
			return;
		}

		if (requestedPageIndex === currentPageIndex) {
			// Do nothing
			return;
		}

		const areRequirementsMet = checkRequirementsMet(requestedPageIndex);

		if (areRequirementsMet) {
			setCheckInputs(false);
			if (appendToHistory) {
				const cartProductParam = cartProductId ? `&cartProductId=${cartProductId}` : '';
				const urlIncludingPageNumber = `${window.location.protocol}//${window.location.host}${window.location.pathname}?pageNumber=${(requestedPageIndex + 1)}${cartProductParam}`;
				window.history.pushState({path: urlIncludingPageNumber}, '', urlIncludingPageNumber);
			}
			setCurrentPageIndex(requestedPageIndex);

			if (shouldScroll) {
				setTimeout(function () {
					let scrollAnchor = document.getElementById("configuration-entry-scroll-to-anchor");
					scrollAnchor?.scrollIntoView();
				}, 100);
			}
		}
	}

	const checkRequirementsMet = (requestedPageIndex: number) => {
		let requirementsMet = true;
		// If on final page, evaluate required fields on all pages
		let startingPageIndex = currentPageIndex !== pages.length - 1 ? currentPageIndex : 0;

		// If going backwards or same page, then return true
		if (startingPageIndex === requestedPageIndex || startingPageIndex > requestedPageIndex)
			return true;

		while (startingPageIndex < requestedPageIndex) {
			const page = pages[startingPageIndex];
			const pagedConfigurations = [...configurations ?? [], ...accessoryConfigurations ?? []]?.filter(c => c.configurationDetail.configurationPagingId === page?.id);
			let exemptFromRequirementsBrokenRules = [];
			pagedConfigurations?.forEach((pc) => {
				// Leaving commented out for now, uncomment when its time to conditionally require fields based on configurationrules
				// exemptFromRequirementsBrokenRules = ConfigurationRuleEvaluator.evaluateRules(pc.rules, productInputs, [RuleTypes.DisabledWhenValueExists]);
				if (pc.configurationDetail.isRequired
					&& exemptFromRequirementsBrokenRules.length === 0
					&& !hasValue(pc, productInputs.filter(pi => pi.productInputId === pc.configurationDetail.id))) {
					requirementsMet = false;
				} else if (ConfigurationRuleEvaluator.evaluateRules(pc.rules, productInputs, siteProductVariant?.productVariantId, [RuleTypes.Matching, RuleTypes.Differing, RuleTypes.PositiveMatch]).length > 0) {
					requirementsMet = false;
				}
			});
			startingPageIndex++;
		}

		if (!requirementsMet) {
			setCheckInputs(true);
			setShowRequiredFieldToast(true);
			setTimeout(function () {
				setShowRequiredFieldToast(false);
			}, 5000);
		}

		return requirementsMet;
	}

	const hasValue = (configuration: Configuration, inputValues: ProductInputValue[]) => {
		let hasValue = false;

		inputValues.forEach((iv) => {
			if (iv.value && configuration.inputType.inputMethod === 'Address Input') {
				try {
					const address: AddressInputVm = JSON.parse(iv.value);
					hasValue = !!(address && iv.isComplete);
				} catch (err) {
					hasValue = false;
				}
			} else if (((iv.value || iv.selectedProductInputOptionId) && iv.isComplete !== false)
				&& (!configuration.shouldValidateValue || (configuration.shouldValidateValue && iv.isValidated))) {
				hasValue = true;
			}
		});

		return hasValue;
	}

	const getPagesFromConfiguration = (configurations: Configuration[] | undefined) => {
		let pagesToReturn: ConfigurationPaging[] = [];
		if (!configurations) {
			return pagesToReturn;
		}

		configurations.forEach((configuration) => {
			if (configuration.configurationDetail.paging &&
				pagesToReturn.filter((page) => page.pagingKey === configuration.configurationDetail.paging?.pagingKey).length < 1) {
				pagesToReturn.push(configuration.configurationDetail.paging);
			}
		});

		// Sort pages
		pagesToReturn = pagesToReturn.sort(Utils.sortBy('sortOrder', 'asc'));

		// Add review step
		if (pagesToReturn.length > 0) {
			pagesToReturn.push(ReviewPage);
		}

		return pagesToReturn;
	}

	const getGroupedConfigurations = () => {
		const isReviewPage = currentPage?.pagingKey === ReviewPage.pagingKey;
		let groupedConfigurations: ConfigurationGroup[] = [];

		if (!configurations) {
			return groupedConfigurations;
		}

		const combinedConfigurations = configurations.concat(accessoryConfigurations).filter((ac, index, self) => {
			return index === self.findIndex(c => c.id === ac.id && c.configurationDetail.id === ac.configurationDetail.id);
		});

		combinedConfigurations.forEach((configuration) => {

			if ((isConfigurationOnCurrentPage(configuration, currentPage) || isReviewPage)
				&& configuration.configurationDetail.group
				&& groupedConfigurations.filter((groupedConfiguration) => groupedConfiguration.groupKey === configuration.configurationDetail.group?.groupKey).length < 1) {
				groupedConfigurations.push(configuration.configurationDetail.group);
			}
		});

		// Sort groups
		groupedConfigurations.sort((a, b) => a.sortOrder < b.sortOrder ? -1 : a.sortOrder > b.sortOrder ? 1 : 0);

		// Place configurations into each distinct group
		groupedConfigurations = groupedConfigurations.map((groupedConfiguration) => ({
			...groupedConfiguration,
			configurations: combinedConfigurations.filter((configuration) => {
				return configuration.configurationDetail.configurationGroupId === groupedConfiguration.id;
			})
		}));
		return groupedConfigurations;
	}

	const isConfigurationOnCurrentPage = (configuration: Configuration, currentPage?: ConfigurationPaging) => {
		let isOnCurrentPage = false;

		if (currentPageIndex === 0 && !configuration.configurationDetail.paging && !configuration.configurationDetail.configurationPagingId) {
			isOnCurrentPage = true;
		} else if (currentPage && currentPage.id === configuration.configurationDetail.configurationPagingId) {
			isOnCurrentPage = true
		}

		return isOnCurrentPage;
	}

	const getBasePrice = () => {
		return siteProductVariant?.price ?? 0;
	}

	const addToCart = async () => {
		if (!checkRequirementsMet(pages.length - 1)) {
			return; // invalid configurations, don't add to cart, yet
		}
		try {
			const response = await addProductToCart({...cartRequest, cartId, cartProductId}).unwrap();
			dispatch(setAccessoryConfigurations([]));
			if (response.cartProduct) {
				AnalyticsTools.recordAddToCartEvent(response.cartProduct);
			}
			if (onDone) {
				onDone(response.cartId);
			} else {
				dispatch(setCartId(response.cartId));
				navigate(`/cart`);
			}
		} catch (err: any) {
			const errorMessage = (err && err.status === 409) ?
				"There are conflicting items in the cart. Please remove any other check products in your cart if you wish to add another." :
				"Failed to add to cart";
			setErrorToastText(errorMessage);
			setShowErrorToast(true);
		}
	}

	const cancelChanges = () => {
		if (onCancelChanges) {
			onCancelChanges();
			return;
		}
		navigate('/cart');
	}

	const getConfigurationValueById = (inputClassification: InputClassification, productInputId?: number) => {
		const productInput = productInputs.find(pi => pi.productInputId === productInputId && pi.inputClassification === inputClassification);
		return productInput ?? undefined;
	}

	if (isFetchingProductVariant || isFetchingConfigurations || isFetchingQuantityOption || isFetchingProduct) {
		return <LoadingSpinner size="sm"/>
	}

	if (!siteProductVariant || !configurations || !quantityOption || !product) {
		return (
			<WideMessage message="Product not found."></WideMessage>
		);
	} else if (requestedPageIndex !== undefined && !hasInitialized && pages.length > 0) {
		updateSelectedPage(requestedPageIndex, false, false);
		setHasInitialized(true);
	}

	const dismissToast = () => {
		setShowErrorToast(false);
	}

	const getErrorToast = () => {
		return (
			<span className='floating-toast'>
				<SimpleToast
					isOpen={showErrorToast}
					toastText={errorToastText ?? ""}
					dismissToast={dismissToast}
					isErrorToast={true}></SimpleToast>
			</span>
		);
	}

	return (
		<div className="configuration-entry-container">
			{getDocumentMetadata()}
			<ScrollToTop></ScrollToTop>

			<ConfigurationPageNavigation
				pages={pages}
				currentPageIndex={currentPageIndex}
				onSelectedPageChange={updateSelectedPage}
				isReadOnly={isReadOnly}
			/>

			<Container>
				{(currentPage?.pagingKey === ReviewPage.pagingKey || isReadOnly) ?
					<ConfigurationReview
						sku={siteProductVariant.sku}
						siteId={siteId}
						itemType={siteProductVariant.itemType}
						siteProductVariantId={siteProductVariantId}
						orderProductId={propOrderProductId}
						cartId={cartId}/> :
					<VariantOverview
						product={product}
						siteProductVariant={siteProductVariant}
						quantityOption={quantityOption}
						currentPageIndex={currentPageIndex}
						onVariantChangeClick={onVariantChangeClick}
						configurations={configurations}
					/>
				}

				{currentPageIndex === 0 && showCheckoutSignIn && !consumer &&
					<CheckoutSignIn redirectUrl={currentPathAndParams}/>}

				<div id="configuration-entry-scroll-to-anchor" className="hidden-anchor">Anchor</div>

				<span className="configuration-block">

					<span className="configuration-group-entry-container">

						{groupedConfigurations.map((groupedConfiguration) => {
							return (
								<ConfigurationGroupView
									key={groupedConfiguration.groupKey}
									basePrice={getBasePrice()}
									groupedConfiguration={groupedConfiguration}
									currentPage={currentPage}
									checkInputs={checkInputs}
									isReadOnly={isReadOnly}
									productTypes={product.types}
									siteProductId={siteProductVariant.siteProductId}
									productVariantId={siteProductVariant.productVariantId}
									getConfigurationValueById={getConfigurationValueById}
									consumerId={consumerId}
									selectedQuantityOption={quantityOption}
								/>
							)
						})}
					</span>

					{(currentPage?.pagingKey === ReviewPage.pagingKey && !isReadOnly) &&
						<AddToCartPreview
							siteProductVariant={siteProductVariant}
							quantityOption={quantityOption}
							isReadOnly={isReadOnly}/>
					}

				</span>

			</Container>

			<ConfigurationControlBar
				product={product}
				pages={getPagesFromConfiguration(configurations)}
				currentPageIndex={currentPageIndex}
				cartProductId={cartProductId}
				onSelectedPageChange={updateSelectedPage}
				addToCart={addToCart}
				isAddingToCart={isAddingToCart}
				isReadOnly={isReadOnly}
				onBack={onBack}
				onCancelChanges={cancelChanges}/>

			<RequiredToast isOpen={showRequiredFieldToast} dismissToast={dismissRequiredFieldToast}/>
			{getErrorToast()}
		</div>
	);
}
