import React, {useEffect, useState} from 'react';
import {Collapse, Navbar, NavbarBrand, NavbarToggler} from 'reactstrap';
import {Link, useLocation} from 'react-router-dom';
import './NavigationMenu.css';
import {NavigationItem} from '../item/NavigationItem';
import {CartButton} from '../../cart/CartButton';
import {fetchSiteNavigation, selectSiteNavigation} from '../NavigationSlice';
import {MediaQueryTypes, useAppDispatch, useAppSelector, useMediaQuery} from '../../../app/hooks';
import {SiteNavigation} from "../SiteNavigation";
import {Helmet} from "react-helmet-async";
import {MobileNavigationMenu} from "./MobileNavigationMenu";
import {useTranslation} from "react-i18next";
import {DisplayImage} from "../../image/display/DisplayImage";

export const NavigationMenu = () => {
    const {t} = useTranslation();
    const siteNavigation = useAppSelector(selectSiteNavigation);
    const dispatch = useAppDispatch();
    const location = useLocation();

    const siteNavigationStatus = useAppSelector(state => state.navigation.status);

    const [collapsed, setCollapsed] = useState(true);
    const [activeSiteNavigation, setActiveSiteNavigation] = useState<SiteNavigation | undefined>(undefined);

    const [parentFocused, setParentFocused] = useState(false);
    const [childFocused, setChildFocused] = useState(false);

    const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);

    // Close mobile navigation on navigation
    useEffect(() => {
        setCollapsed(true);
    }, [location]);

    useEffect(() => {
        if (siteNavigationStatus === "idle") {
            dispatch(fetchSiteNavigation());
        }
    }, [siteNavigationStatus, dispatch]);

    useEffect(() => {
        if (!childFocused && !parentFocused) {
            setActiveSiteNavigation(undefined);
        }
    }, [childFocused, parentFocused]);

    function toggleNavbar() {
        setCollapsed(!collapsed);
    }

    function onNavigationMouseOver(siteNavigation: SiteNavigation) {
        if (activeSiteNavigation?.id === siteNavigation.id) {
            setCollapsed(true);
            setActiveSiteNavigation(undefined);
        } else {
            setActiveSiteNavigation(siteNavigation ?? undefined);
        }
    }

    function onParentFocusChanged(isFocused: boolean) {
        setParentFocused(isFocused);
    }

    function onChildFocusChanged(isFocused: boolean) {
        setChildFocused(isFocused);
    }

    function onNavigation() {
        setChildFocused(false);
        setParentFocused(false);
        setActiveSiteNavigation(undefined);
    }

    function getDefaultDocumentMetadata() {
        return (
            <Helmet>
                <title>{t(`pages.homePage.metaTitle`)}</title>
                <meta name="description" content={t(`pages.homePage.metaDescription`)}/>
            </Helmet>
        )
    }

    return (
        <header id="StorefrontNav"
                className="navbar-container position-sticky top-0 z"
                style={{zIndex: 1000}}>
            {getDefaultDocumentMetadata()}
            <Navbar className="navbar-expand-md navbar-toggleable-lg product-catalog-navbar"
                    dark>
                <NavbarToggler onClick={toggleNavbar}
                               className={`mr-2 ${collapsed ? 'closed' : 'open'}`}/>
                <NavbarBrand tag={Link}
                             to="/"
                             className="navbar-brand">
                    <span className={isMobile ? 'title-logo mobile' : 'title-logo'}>
                        <DisplayImage imageName={isMobile ? t('mobileLogoImageName') : t('logoImageName')}
                                      showLoading={false}></DisplayImage>
                    </span>
                </NavbarBrand>
                <span className="navigation-menu-cart-button-container">
                    <CartButton isMobile={isMobile}/>
                </span>
                {
                    isMobile ? <></> :
                        <Collapse
                            className="d-none d-lg-inline-flex flex-lg-row-reverse navigation-menu-collapse-container"
                            isOpen={!collapsed} navbar>
                            <ul className="navbar-nav flex-grow navigation-menu-item-container"
                                tabIndex={0}
                                onBlur={() => onParentFocusChanged(false)}
                                onFocus={() => onParentFocusChanged(true)}>

                                {siteNavigation?.map(siteNavigationItem => (
                                    <NavigationItem siteNavigation={siteNavigationItem}
                                                    onNavigationMouseOver={onNavigationMouseOver}
                                                    key={siteNavigationItem.id}/>
                                ))}
                            </ul>
                        </Collapse>
                }
            </Navbar>
            {
                !isMobile ? <></> :
                    <Collapse className="mobile navigation-menu-collapse-container vh-100 overflow-scroll" 
                              isOpen={!collapsed} 
                              navbar>
                        {
                            <MobileNavigationMenu items={siteNavigation ?? []} />
                        }
                    </Collapse>
            }

        </header>
    );
}