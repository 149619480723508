import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { SiteCard } from './SiteCard';
import { CardItem } from './cardItem/CardItem';
import { fetchAll, fetchCardItemBySiteCardId } from './SiteCardAPI';

export interface SiteCardState {
  siteCards: SiteCard[];
  cardItems: CardItem[];
  status: 'idle' | 'loading' | 'failed' | 'succeeded';
  cardItemStatus: 'idle' | 'loading' | 'failed' | 'succeeded';
}

const initialState: SiteCardState = {
  siteCards: [],
  cardItems: [],
  status: 'idle',
  cardItemStatus: 'idle'
};

export const fetchSiteCards = createAsyncThunk(
  'siteCards/fetchAll',
  async (siteId: number) => {
    const response = await fetchAll(siteId);
    return response.data;
  }
);

export const fetchCardItems = createAsyncThunk(
  'siteCard/fetchCardItems',
  async (siteCardId: number) => {
    const response = await fetchCardItemBySiteCardId(siteCardId);
    return response.data;
  }
);

export const siteCardSlice = createSlice({
  name: 'siteCard',
  initialState,
  reducers: {
    
  },

  extraReducers: (builder) => {
    builder
      // Site Cards
      .addCase((fetchSiteCards.pending), (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSiteCards.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.siteCards = action.payload;
      })
      .addCase((fetchSiteCards.rejected), (state) => {
        state.status = 'failed';
      })
      // Card Items
      .addCase((fetchCardItems.pending), (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCardItems.fulfilled, (state, action) => {
        state.cardItemStatus = 'succeeded';
        state.cardItems = action.payload;
      })
      .addCase((fetchCardItems.rejected), (state) => {
        state.status = 'failed';
      });
  },
});

// Fetch site cards
export const selectSiteCards = (state: RootState) => state.siteCards.siteCards;

// Fetch card items
export const selectCardItems = (state: RootState) => state.cardItems.cardItems;

export default siteCardSlice.reducer;
