import styles from './UserProfileDetails.module.css';
import {useTranslation} from "react-i18next";
import {Consumer} from "../../../admin/customer/Consumer";
import {Button, Card, CardBody, CardHeader, FormGroup, Input, Label} from "reactstrap";
import {UserProfileDetailsInput} from "./UserProfileDetailsInput";
import React, {useEffect, useState} from "react";
import {SaveUserProfileDetailsRequest, UserProfileDetailsEdit} from "./UserProfileDetailsEdit";
import {
    useIsUserSubscribedQuery,
    useSubscribeCurrentUserMutation,
    useUpdateUserProfileDetailsMutation
} from "../../../../app/apiSlice";
import {updateConsumer} from "../../login/AuthenticationSlice";
import {useAppDispatch} from "../../../../app/hooks";
import {SavedPaymentBrowse} from "../../../payment/savedPaymentBrowse/SavedPaymentBrowse";
import {SimpleToast} from "../../../input/utils/SimpleToast";
import {EditPayment} from "../../../payment/edit/EditPayment";
import { EmailInput } from "../../../input/email/EmailInput";
import { PasswordInput } from "../../../input/password/PasswordInput";
import {showConfirmationModal, showInformationModal} from "../../../modal/ModalSlice";

interface UserProfileProps {
    consumer: Consumer
}

export const UserProfileDetails = ({consumer}: UserProfileProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [currentEditInput, setCurrentEditInput] = useState<UserProfileDetailsInput | undefined>(undefined);
    const [ showSuccessToast, setShowSuccessToast ] = useState(false);
    const [ showFailedToast, setShowFailedToast ] = useState(false);
    const [ successToastText, setSuccessToastText ] = useState("");
    const [userProfileDetailsEdit, setUserProfileDetailsEdit] = useState<UserProfileDetailsEdit>({
        email: consumer.email,
        isEmailSubscribed: false
    });
    const [ updateUserProfileDetails ] = useUpdateUserProfileDetailsMutation();
    const [ subscribeCurrentUser ] = useSubscribeCurrentUserMutation();
    const { data: isUserSubscribed } = useIsUserSubscribedQuery();

    useEffect(() => {
        setUserProfileDetailsEdit(old => ({...old, isEmailSubscribed: !!isUserSubscribed}));
    }, [isUserSubscribed]);

    const getUserProfileDetailsInput = (userProfileDetailInput: UserProfileDetailsInput) => {
        switch (userProfileDetailInput) {
            case UserProfileDetailsInput.Email:
                return currentEditInput === userProfileDetailInput ?
                    <span className={styles.inputContainer}>
                        <EmailInput id='Email'
                           className={styles.input}
                           name='email'
                           value={userProfileDetailsEdit.email ?? consumer.email ?? ''}
                           onChange={(e) => onInputChange(UserProfileDetailsInput.Email, e.target.value)}/>
                    </span>:
                    <div className={styles.input}>{consumer.email}</div>

            case UserProfileDetailsInput.Password:
                return currentEditInput === userProfileDetailInput ?
                    <span className={styles.inputContainer}>
                        <PasswordInput id='Password'
                               className={styles.input}
                               name='password'
                               value={userProfileDetailsEdit.password ?? ''}
                               onChange={(e) => onInputChange(UserProfileDetailsInput.Password, e.target.value)}/>
                    </span>:
                    <div className={styles.input}>********</div>

            case UserProfileDetailsInput.PaymentMethod:
                return currentEditInput === userProfileDetailInput ?
                    <EditPayment consumer={consumer}
                                 cancelEditPayment={cancelEditPayment}
                    ></EditPayment> :
                    <SavedPaymentBrowse consumer={consumer}
                                        isParentInEditMode={currentEditInput === UserProfileDetailsInput.SavedPaymentMethod}
                                        startEditPayment={startEditPayment}
                                        cancelEditPayment={cancelEditPayment}
                    ></SavedPaymentBrowse>

            case UserProfileDetailsInput.EmailSubscription:
                return (
                    <FormGroup switch>
                        <Input type="switch"
                               role="switch"
                               checked={userProfileDetailsEdit.isEmailSubscribed ?? false}
                               onChange={() => onToggleSwitch(UserProfileDetailsInput.EmailSubscription)}/>
                        <Label check>{t('profile.emailSubscriptionLabel')}</Label>
                    </FormGroup>
                );

        }
    }

    const startEditPayment = () => {
        setCurrentEditInput(UserProfileDetailsInput.SavedPaymentMethod);
    }

    const cancelEditPayment = () => {
        setCurrentEditInput(undefined);
    }

    const getUserProfileDetailsInputUpdateButtons = (userProfileDetailInput: UserProfileDetailsInput, value: string) => {
        return currentEditInput === userProfileDetailInput ?
            <div className={styles.inputButtonsRow}>
                <Button color="success"
                        className={styles.inputButton}
                        disabled={value === ''}
                        onClick={() => onSaveButtonClicked(userProfileDetailInput)}
                >Save</Button>
                <Button color="danger"
                        className={styles.inputButton}
                        onClick={() => onCancelButtonClicked(userProfileDetailInput)}
                >Cancel</Button>
            </div> :
            <Button color="primary"
                    className={styles.inputButton}
                    onClick={() => onUpdateAddButtonClicked(userProfileDetailInput)}
            >Update</Button>
    }

    const getUserProfileDetailsInputAddButton = (userProfileDetailInput: UserProfileDetailsInput) => {
        return currentEditInput === userProfileDetailInput || (currentEditInput === UserProfileDetailsInput.SavedPaymentMethod && userProfileDetailInput === UserProfileDetailsInput.PaymentMethod) ?
            <Button color="danger"
                    className={styles.inputButton}
                    onClick={() => onCancelButtonClicked(userProfileDetailInput)}
            >Cancel</Button> :
            <Button color="success"
                    className={styles.inputButton}
                    onClick={() => onUpdateAddButtonClicked(userProfileDetailInput)}
            >Add</Button>
    }

    const onUpdateAddButtonClicked = (userProfileDetailInput: UserProfileDetailsInput) => {
        setCurrentEditInput(userProfileDetailInput);
    }

    const onSaveButtonClicked = async (userProfileDetailInput: UserProfileDetailsInput) => {
        dispatch(showConfirmationModal({
            title: "Save changes?",
            content: "Are you sure you want to save changes?",
            affirmText: "Yes, Save",
            onConfirm: continueWithSave,
        }));
    }

    const continueWithSave = async () => {
        try {
            const updatedConsumer = await updateUserProfileDetails(userProfileDetailsEdit as SaveUserProfileDetailsRequest).unwrap();
            dispatch(updateConsumer(updatedConsumer));
            dispatch(showInformationModal({
                title: "Profile Update Successful!",
                text: "Your changes have been saved.",
            }));
        }
        catch (e) {
            dispatch(showInformationModal({
                title: "Profile Update Failed!",
                text: "There was a problem updating your profile. Please try again or contact support.",
            }));
        }
        setCurrentEditInput(undefined);
    }

    const onCancelButtonClicked = (userProfileDetailInput: UserProfileDetailsInput) => {
        setCurrentEditInput(undefined);
    }

    const onInputChange = async (userProfileDetailInput: UserProfileDetailsInput, value?: string) => {
        switch(userProfileDetailInput) {
            case UserProfileDetailsInput.Email:
                setUserProfileDetailsEdit({email: value});
                break;
            case UserProfileDetailsInput.Password:
                setUserProfileDetailsEdit({password: value});
                break;
            case UserProfileDetailsInput.EmailSubscription:
                setUserProfileDetailsEdit({isEmailSubscribed: (value === 'on')});
                try {
                    const subscribeResponse = await subscribeCurrentUser({subscribe: (value === 'on')}).unwrap();
                    if (value === 'on')
                        setSuccessToastText(subscribeResponse.wasAlreadySubscribed ? t(`footer.subscribe.alreadySubscribed`) : t(`footer.subscribe.successModal`));
                    else
                        setSuccessToastText(t(`footer.unsubscribe.successModal`));
                    setShowSuccessToast(true);
                    setTimeout(function() {
                        setShowSuccessToast(false);
                    }, 10000);
                } catch (err) {
                    setShowFailedToast(true);
                    setTimeout(function() {
                        setShowFailedToast(false);
                    }, 10000);
                }
                break;
        }
    }

    const onToggleSwitch = async (userProfileDetailInput: UserProfileDetailsInput) => {
        const newValue = userProfileDetailsEdit.isEmailSubscribed ? 'off' : 'on';
        await onInputChange(userProfileDetailInput, newValue);
    }

    const dismissToast = () => {
        setShowSuccessToast(false);
        setShowFailedToast(false);
    }

    return (
        <div className={styles.container}>
            <h3>Profile</h3>
            <Card className={styles.card}>
                <CardHeader className={styles.cardHeader}>
                    Account
                </CardHeader>
                <CardBody>

                    <Label for='Email' className={styles.inputLabel}>Email</Label>
                    <span className={styles.inputButtonContainer}>
                        {getUserProfileDetailsInput(UserProfileDetailsInput.Email)}
                        {getUserProfileDetailsInputUpdateButtons(UserProfileDetailsInput.Email, userProfileDetailsEdit.email ?? '')}
                    </span>

                    <Label for='Password' className={styles.inputLabel}>Password</Label>
                    <span className={styles.inputButtonContainer}>
                        {getUserProfileDetailsInput(UserProfileDetailsInput.Password)}
                        {getUserProfileDetailsInputUpdateButtons(UserProfileDetailsInput.Password, userProfileDetailsEdit.password ?? '')}
                    </span>

                </CardBody>
            </Card>

            <Card className={styles.card}>
                <CardHeader className={styles.cardHeader}>
                    Payment Method
                    {getUserProfileDetailsInputAddButton(UserProfileDetailsInput.PaymentMethod)}
                </CardHeader>
                <CardBody>
                    <span className={styles.inputButtonContainer}>
                        {getUserProfileDetailsInput(UserProfileDetailsInput.PaymentMethod)}
                    </span>
                </CardBody>
            </Card>

            <Card className={styles.card}>
                <CardHeader className={styles.cardHeader}>
                    Communication Preferences
                </CardHeader>
                <CardBody>
                    <span className={styles.inputButtonContainer}>
                        {getUserProfileDetailsInput(UserProfileDetailsInput.EmailSubscription)}
                    </span>
                </CardBody>
            </Card>

            <span className='subscribe-toast'>
                    <SimpleToast isOpen={showSuccessToast}
                                 toastText={successToastText}
                                 dismissToast={dismissToast}
                                 isErrorToast={false}></SimpleToast>
                </span>
            <span className='subscribe-toast'>
                    <SimpleToast isOpen={showFailedToast}
                                 toastText="There was an issue changing your subscription. Please try again later."
                                 dismissToast={dismissToast}
                                 isErrorToast={true}></SimpleToast>
                </span>
        </div>
    );

}