import {Button, Card, CardBody, CardHeader, Table} from "reactstrap";
import {FindProduct, ProductConfigurationState} from "./FindProduct";
import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {CartIdType, selectAdminCartId, setCartId} from "../../cart/CartSlice";
import {useAddProductToCartMutation, useGetCartProductsPricingQuery, useGetCartProductsQuery, useRemoveProductFromCartMutation} from "../../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {CartProductVM} from "../../cart/CartProduct";
import {CartProductRow} from "./CartProductRow";
import "./SelectedCartProductTable.css"

interface ProductTableProps {
  siteId: number,
  consumerId?: number,
}
export const SelectedCartProductTable = (props: ProductTableProps) => {
  const [ productConfigurationState, setProductConfigurationState ] = useState<ProductConfigurationState>({});
  
  const cartId = useAppSelector(selectAdminCartId);
  const { data: cartProducts } = useGetCartProductsQuery(cartId ?? skipToken);
  const { data: cartPricing } = useGetCartProductsPricingQuery({cartId});
  const [ updateCartProduct ] = useAddProductToCartMutation();
  const [ removeProductFromCart ] = useRemoveProductFromCartMutation();
  const [ searchIsOpen, setSearchIsOpen ] = useState(false);
  const toggleSearch = () => setSearchIsOpen(!searchIsOpen);
  const dispatch = useAppDispatch();

  const addProduct = () => {
    // ensure that we have cleared out the product configuration state to make way for the new product
    setProductConfigurationState({});
    setSearchIsOpen(true);
  }

  const editCartProduct = (cartProduct: CartProductVM) => {
    setProductConfigurationState({
      siteProductVariantId: cartProduct.siteProductVariantId,
      quantityOptionId: cartProduct.quantityOption.id,
      cartProductId: cartProduct.id,
    });
    setSearchIsOpen(true);
  }
  
  const removeFromCart = (cartProductId: number)  => {
    if (window.confirm('Are you sure you want to remove this product from the cart?')) {
      removeProductFromCart(cartProductId);
    }
  }
  
  const updateQuantity = (cartProductId: number, siteProductVariantId: number, quantityOptionId: number) => {
    updateCartProduct({
      cartProductId,
      siteProductVariantId,
      siteProductVariantQuantityOptionId: quantityOptionId
    });
  }

  const onProductAddedToCart = (cartId: CartIdType) => {
    dispatch(setCartId(cartId));
  }

  return (
    <Card>
      <CardHeader className='d-flex justify-content-between card-header'>
        <h5 className='mb-0'>Products</h5>
        <Button color='primary' onClick={() => addProduct()}>Add Product</Button>
        <FindProduct 
          siteId={props.siteId} 
          productConfigurationState={productConfigurationState}
          onProductAddedToCart={onProductAddedToCart}
          onProductConfigurationStateChanged={setProductConfigurationState}
          isOpen={searchIsOpen}
          toggleModal={toggleSearch}
          consumerId={props.consumerId}/>
      </CardHeader>
      <CardBody>
        <Table hover>
          <thead>
          <tr>
            <th>Sku</th>
            <th>Product Name</th>
            <th>Quantity</th>
            <th>Total</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {
            cartProducts?.map(p => (
              <CartProductRow key={p.id}
                              cartProduct={p}
                              pricing={cartPricing?.siteProductVariantTotals?.find(svp => svp.cartProductId === p.id)}
                              onQuantityOptionChange={quantityOptionId => updateQuantity(p.id, p.siteProductVariantId, quantityOptionId)}
                              onEditClick={editCartProduct}
                              onRemoveClick={removeFromCart} />
            ))
          }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}