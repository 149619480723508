import { OrderItemDownload } from "./OrderItemDownload";
import { ColumnConfig } from "../../admin/shared/Grid";
import {
  reportCol,
  reportDateToString,
  reportMoneyToString,
  reportValToOrderLink,
  ReportView
} from "../ReportView";
import { useGetOrderItemDownloadReportQuery } from "../../../app/apiSlice";

export const OrderItemDownloadReport = () => {
  const filterFunc = (lowerSearch: string, records: OrderItemDownload[]) =>
    records.filter(r =>
      r.brand.toLowerCase().includes(lowerSearch) ||
      r.orderStatus.toLowerCase().includes(lowerSearch) ||
      r.productCode.toLowerCase().includes(lowerSearch) ||
      r.productDescription.toLowerCase().includes(lowerSearch) ||
      r.productCategory.toLowerCase().includes(lowerSearch) ||
      r.shippingMethod.toLowerCase().includes(lowerSearch) ||
      r.paymentStatus.toLowerCase().includes(lowerSearch) ||
      r.ezShieldChoice.toLowerCase().includes(lowerSearch)
    );
  
  const columns: ColumnConfig<OrderItemDownload>[] = [
    reportCol('orderNumber', 'Order #', reportValToOrderLink),
    reportCol('brand', 'Brand'),
    reportCol('orderDate', 'Order Date', reportDateToString),
    reportCol('orderNetTotal', 'Order Net', reportMoneyToString),
    reportCol('orderStatus', 'Order Status'),
    reportCol('productCode', 'Product Code'),
    reportCol('productDescription', 'Product Desc.'),
    reportCol('productCategory', 'Product Cat.'),
    reportCol('shippingMethod', 'Shipping Meth.'),
    reportCol('paymentStatus', 'Payment Status'),
    reportCol('paymentAmount', 'Payment Amt.', reportMoneyToString),
    reportCol('productPrice', 'Product Price', reportMoneyToString),
    reportCol('productAdjustment', 'Product Adj.', reportMoneyToString),
    reportCol('shippingPrice', 'Shipping Price', reportMoneyToString),
    reportCol('shippingAdjustment', 'Ship Adj.', reportMoneyToString),
    reportCol('shippingCost', 'Shipping Cost', reportMoneyToString),
    reportCol('ezShieldChoice', 'EZ Shield Choice'),
    reportCol('ezShield', 'EZ Shield', reportMoneyToString),
    reportCol('unitQuantity', 'Quantity'),
    reportCol('woodCutPrice', 'WoodCut', reportMoneyToString),
    reportCol('customMessagePrice', 'Custom Mesg.', reportMoneyToString),
    reportCol('fontPrice', 'Font', reportMoneyToString),
    reportCol('extraSigLinePrice', 'Extra Sig Line', reportMoneyToString),
    reportCol('discountCode', 'Discount Code'),
    reportCol('discountAmount', 'Discount Amt', reportMoneyToString),
  ];
  
  return <ReportView
      title='Order Item Download'
      query={useGetOrderItemDownloadReportQuery}
      columns={columns}
      initialSort={{ column: 'orderDate', direction: 'asc'}}
      filterFunc={filterFunc}
      downloadPath='/api/Reports/OrderItemDownload'
      downloadName='CW_Order_Item Download'/>
}