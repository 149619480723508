import "./ProductSearchView.css"
import {useGetSiteProductsQuery} from "../../app/apiSlice";
import {Container} from "reactstrap";
import ScrollToTop from "../../components/helpers/ScrollToTop";
import {ProductSearchItem} from "../item/ProductSearchItem";
import {useSearchParams} from "react-router-dom";

export const ProductSearchView = () => {
	const [urlParams, setUrlParams] = useSearchParams();
	const term = urlParams.get("term") ?? "";
	const {data: products} = useGetSiteProductsQuery({search: term});

	return (
		<Container className="product-search-items-container">
			<ScrollToTop/>
			<h2 className="card-item-title mb-4">Product Search Results</h2>

			{(products?.length === 0) && (
				<h5>Sorry, no products matched your search for "{term}".</h5>
			)}

			<div className="card-item-previews aspect-ratio-2">
				{products?.map((product) =>
					<ProductSearchItem product={product} key={product.id}/>
				)}
			</div>
		</Container>
	);
};
