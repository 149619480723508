import './CheckPreview.css';
import { BankDisclaimer } from "../bank/disclaimer/BankDisclaimer";
import { useAppSelector, useDebounce } from "../../app/hooks";
import { selectGetPreviewRequest } from "../input/ProductInputSlice";
import {useGetProductPreviewQuery} from "../../app/apiSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Alert, Button, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import {ImageZoom} from "../image/zoom/ImageZoom";
import React, {useEffect, useMemo, useState} from "react";
import { CartIdType } from '../cart/CartSlice';
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import { SimpleToast } from '../input/utils/SimpleToast';

interface CheckPreviewProps {
  sku: string
  siteId?: number
  siteProductVariantId: number
  cartId: CartIdType
  orderProductId: number | undefined
}

export const CheckPreview = ({
	sku,
	siteId,
	siteProductVariantId,
	cartId,
	orderProductId,
}: CheckPreviewProps) => {
	const { t } = useTranslation();
  const previewRequest = useAppSelector(root => selectGetPreviewRequest(root, sku, siteProductVariantId, cartId, siteId));
  const debouncedRequest  = useDebounce(previewRequest, 2_000);
  const { data: previewImage, currentData: currentPreview, error, isFetching, refetch }
      = useGetProductPreviewQuery(orderProductId ?? debouncedRequest ?? skipToken);
  const isDebouncing = JSON.stringify(previewRequest) !== JSON.stringify(debouncedRequest);

	const [ toastIsOpen, setToastIsOpen ] = useState(false);
	// display toast when preview image is updated
	useEffect(() => {
		setToastIsOpen(!!currentPreview && !isFetching && !isDebouncing);
		const handle = setTimeout(() => setToastIsOpen(false), 2_000);
		return () => clearTimeout(handle);
	}, [currentPreview, isFetching, isDebouncing]);

  const previewToastClick = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  const alert = useMemo(() => {
    const statusCode = error && (error as FetchBaseQueryError)?.status
    if (!error) {
      return undefined;
    }
    else if (statusCode === 408) {
      return t('cart.previewTimeout');
    }
    else if (statusCode === 422) {
      return t('cart.customPrintingServiceError');
    }
    else {
      return t('cart.previewError');
    }
  }, [error, t]);
  
  return (
    <span className="check-preview-container">

      <h3 className="check-preview-title">Preview</h3>
      {isFetching && !previewImage &&
          <Spinner className='mx-auto my-5'>Loading</Spinner>
      }
      {!isFetching && error && alert &&
          <Alert color='warning'>{alert}</Alert>
      }
      {previewImage && !error &&
          <div id='PreviewImage' className={isFetching || isDebouncing ? 'loading' : ''}>
            <ImageZoom title='Personalized Preview' imageUrl={previewImage} />
          </div>
      }
  
      <Button color='link' onClick={refetch}>Refresh</Button>
      
      <BankDisclaimer></BankDisclaimer>

      <div className="floating-toast" onClick={previewToastClick}>
				<SimpleToast
					toastText="Preview Updated"
					isErrorToast={false}
					isOpen={toastIsOpen}
					dismissToast={() => setToastIsOpen(false)}
				/>
			</div>
    </span>
  );
}