import './TrustBanner.css';
import React from 'react';
import sslLogo from "../../assets/images/ssl.png";
import bbbRating from "../../assets/images/bbb_rating.png";
import madeInUsa from "../../assets/images/made_in_usa.png";
import {getTranslationValue} from "../../utils/Utils";
import {useTranslation} from "react-i18next";

interface TrustBannerProps {
    isFullWidth: boolean;
    showCheckIcon: boolean;
}
const DisplayCheckIcon = (showCheckIcon: boolean) => {
    if (showCheckIcon) {
        return (
            <figure className="trust-banner-item">
                <figure className="thirty-percent-more-badge">
                    <header>30% More</header>
                    <footer>Checks in Every Box!</footer>
                </figure>
            </figure>
        )
    }
}

export const TrustBanner = ({ isFullWidth, showCheckIcon }: TrustBannerProps) => {
    const {t} = useTranslation();

    return (
        <aside className={ isFullWidth ? 'trust-banner trust-banner-large' : 'trust-banner' }>
            {DisplayCheckIcon(showCheckIcon)}
            <figure className="trust-banner-item">
                <img src={sslLogo} alt={"SSL Protection"}/>
            </figure>
            <figure className="trust-banner-item">
                <img src={bbbRating} alt={"Better Business Bureau A+ rating"}/>
            </figure>
            <figure className="trust-banner-item">
                <img src={madeInUsa} alt={"Made in the USA"}/>
            </figure>
            <figure className="trust-banner-item">
                <a href={getTranslationValue(t, 'links.authorizeNet') + window.location.protocol + '//' + window.location.hostname}
                   className='mx-auto' rel="noopener noreferrer" target="_blank">
                    <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif" alt="Authorize.Net Merchant - Click to Verify"/>
                </a>
            </figure>
        </aside>
    );
}