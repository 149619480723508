import {RefundStatus} from "./RefundStatus";
import {Reason} from "../../../../order-status/Reason";
import {PaymentDetails} from "../../../../payment/PaymentDetails";

export interface Refund {
    id: number,
    orderProductId: number,
    typeId?: number,
    categoryId?: number,
    amount: number,
    reasonId?: number,
    timeCreated: string,
    timeCreatedLocal: string,
    createdById: number,
    creatorEmail: string,
    status: RefundStatus,
    reason?: Reason,
    category?: RefundCategory,
    type?: RefundType,
    orderId: number,
    storefront?: string,
}

export interface EditRefund {
    typeId?: number,
    categoryId?: number,
    reasonId?: number,
    amount: number,
    amountDisplay: string
}

export enum RefundTypeDescriptions {
    Percentage = "Percentage",
    Partial = "Partial",
    Full = "Full"
}

export enum RefundCategoryDescriptions {
    Handling = "Handling",
    ProductTotal = "Product Total",
    Shipping = "Shipping",
    TaxAmount = "Tax Amount",
    Total = "Total",
}

export enum RefundStatusNames {
    Approved = "Approved",
    Requested = "Requested",
    Declined = "Declined",
    Processed = "Processed",
    Failed = "Failed"
}

export interface RefundType {
    id: number,
    name: string
}

export interface RefundCategory {
    id: number,
    name: string
}

export interface RefundRequest {
    typeId: number,
    categoryId: number,
    reasonId: number,
    amount: number,
    orderProductId: number
}

export interface RefundApproveDenyRequest {
    refundId: number,
    isApproved: boolean,
    reason?: string,
    paymentDetails?: PaymentDetails,
    isRefundAndRecharge: boolean
}