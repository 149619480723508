
import {MicrEdit} from "../edit/MicrEdit";
import {MicrValueMemoized} from "../value/MicrValue";
import './MicrDetails.css';
import {OrderProductVm} from "../../../order/OrderProductVm";

interface MicrDetailsProps {
    orderId?: number
    orderProduct?: OrderProductVm
    micrFormatId?: number
    financialInstitutionId?: number
    isReadOnly: boolean
    onEditModeToggle: (orderProductId: number) => void
}

export const MicrDetails = ({orderId, orderProduct, micrFormatId, financialInstitutionId, isReadOnly, onEditModeToggle}: MicrDetailsProps) => {

    const noMicrFormat = () => {
        return (
            <MicrEdit orderId={orderId}
                      orderProduct={orderProduct}
                      allowFinancialInstitutionEdit={true}
                      onEditModeToggle={onEditModeToggle}
            ></MicrEdit>
        )
    }

    const getMicrFormatDisplay = () => {
        return isReadOnly ?
            <MicrValueMemoized orderId={orderId}
                               orderProduct={orderProduct}
                               propMicrFormatId={micrFormatId}
                               propFinancialInstitutionId={financialInstitutionId}
                               onEditModeToggle={onEditModeToggle}
            ></MicrValueMemoized> :
            <MicrEdit orderId={orderId}
                      orderProduct={orderProduct}
                      propMicrFormatId={micrFormatId}
                      propFinancialInstitutionId={financialInstitutionId}
                      allowFinancialInstitutionEdit={true}
                      onEditModeToggle={onEditModeToggle}
            ></MicrEdit>
    }

    return (
        <span className="micr-tab-container">
            {/*If editing, give option for financial institution picker*/}
            {micrFormatId ? getMicrFormatDisplay() : noMicrFormat()}
        </span>
    )
}
