import React, {useEffect} from "react";
import "./OrderEditPaymentPlan.css";
import {CartPricing} from "../../../../../../cart/CartPricing";
import {OrderPricing} from "../../../../../../order/OrderPricing";
import {OrderEditCartDetails} from "../../../../../../order/Order";
import {useGetOrderPaymentsQuery} from "../../../../../../../app/apiSlice";
import {DateTime} from "luxon";
import { Utils } from "../../../../../../../utils/Utils";
import {PaymentMethodName} from "../../../../../../payment/PaymentMethod";
import {PaymentStatus} from "../../../../../../payment/Payment";
import {CurrencyFormatter} from "../../../../../../../utils/CurrencyFormatter";

interface OrderEditPaymentPlanProps {
    orderEditCartDetails: OrderEditCartDetails,
    orderPricing: OrderPricing,
    cartPricing: CartPricing,
    onRequiresPaymentChanged?: (value: boolean) => void,
}

export const OrderEditPaymentPlan = (props: OrderEditPaymentPlanProps) => {

    const {data: orderPayments} = useGetOrderPaymentsQuery(props.orderEditCartDetails.orderId);
    const isAch = (orderPayments ?? []).some(op => op.paymentMethod === PaymentMethodName.ACH);
    const changedPayments = (orderPayments ?? []).filter(op => {
        return op.amountRemaining > 0 && props.cartPricing.total !== op.amountRemaining;
    });
    const totalCompletedPayments = (orderPayments ?? []).reduce((accumulator, op) => {
        const paymentAmount = op.paymentStatus === PaymentStatus.Complete ? op.amountRemaining : 0;
        return accumulator + (paymentAmount);
    }, 0) ?? 0;
    const totalPendingPayments = (orderPayments ?? []).reduce((accumulator, op) => {
        const paymentAmount = op.paymentStatus === PaymentStatus.Pending ? op.amountRemaining : 0;
        return accumulator + (paymentAmount);
    }, 0) ?? 0;
    const pricingUpdate = (props.cartPricing?.total ?? 0) - totalCompletedPayments;
    const pendingPricingUpdate = (props.cartPricing?.total ?? 0) - totalPendingPayments;

    useEffect(() => {
        if (props.onRequiresPaymentChanged)
            props.onRequiresPaymentChanged(pricingUpdate !== 0);
    }, [pricingUpdate]);

    const getPaymentBreakdown = () => {
        if (isAch)
            return getACHPaymentBreakdown();

        return getStandardPaymentBreakdown();
    }

    const getACHPaymentBreakdown = () => {
        return (
            <>
                <div className="order-edit-payment-plan-grid">
                    <div className='order-edit-payment-plan-details-container'>
                        {!!pricingUpdate && !!pendingPricingUpdate &&
                          <><h5>Payment Update</h5>
                              {getPaymentsToUpdate()}
                          </>
                        }
                        {(!pricingUpdate || !pendingPricingUpdate) &&
                          <h5>No Payments to Update</h5>
                        }
                    </div>
                </div>
            </>
        )
    };

    const getStandardPaymentBreakdown = () => {
        return (
            <>
                <div className="order-edit-payment-plan-grid">
                    <div className='order-edit-payment-plan-details-container'>
                        {changedPayments && changedPayments.length > 0 &&
                          <><h5>Payment to Refund</h5>
                          {getPaymentsToRefund()}
                          </>
                        }
                        {(!changedPayments || changedPayments.length <= 0) &&
                          <h5>No Payments to Refund</h5>
                        }
                    </div>
                    <div className='order-edit-payment-plan-details-container'>
                        {(props.cartPricing.total ?? 0) !== totalCompletedPayments &&
                          <><h5>Payment to Charge</h5>
                          {getPaymentsToCharge()}
                          </>
                        }
                        {props.cartPricing.total === totalCompletedPayments &&
                          <h5>No Payments to Charge</h5>
                        }
                    </div>
                </div>
            </>
        )
    };

    const getPaymentsToRefund = () => {
        return (
            <div className='order-edit-payment-plan-refund-container'>
                <table className='table table-bordered mb-0'>
                    <thead>
                    <tr>
                        <th>Amount</th>
                        <th>Transaction Status</th>
                        <th>Product(s)</th>
                        <th>Time of Payment</th>
                    </tr>
                    </thead>
                    <tbody>
                    {changedPayments.map(payment => {
                        return (
                            <tr key={payment.id}>
                                <td>{CurrencyFormatter.format(payment.amountRemaining)}</td>
                                <td>{Utils.mapCamelCaseToCapitalizedString(payment.transactionStatus)}</td>
                                <td>{payment.productNames}</td>
                                <td>{DateTime.fromISO(payment.timeOfPaymentBegan + 'Z').toLocaleString(DateTime.DATETIME_SHORT)}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }

    const getPaymentsToCharge = () => {
        return (
            <div className='order-edit-payment-plan-charge-container'>
                <table className='table table-bordered mb-0'>
                    <thead>
                    <tr>
                        <th>Amount</th>
                        <th>Product(s)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{CurrencyFormatter.format(props.cartPricing.total ?? 0)}</td>
                        <td>{(props.cartPricing.siteProductVariantTotals ?? [])
                            .map((spvt, i) => `${i > 0 ? ', ' : ''}${spvt.name}`)}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    const getPaymentsToUpdate = () => {
        return (
            <div className='order-edit-payment-plan-charge-container'>
                <table className='table table-bordered mb-0'>
                    <thead>
                    <tr>
                        <th>Amount</th>
                        <th>Product(s)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{CurrencyFormatter.format(pricingUpdate ?? 0)}</td>
                        <td>{props.cartPricing.siteProductVariantTotals?.map(spvt => spvt.name)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <>
            {getPaymentBreakdown()}
        </>
    );
}
