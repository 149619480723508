import {Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader,} from "reactstrap";
import React, {useMemo, useState} from "react";
import {useGetOrderPricingQuery,} from "../../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {Address} from "../../input/address/Address";
import {ShippingOption} from "../../shipping-options/ShippingOption";
import {LineItemSummary} from "./LineItemSummary";
import "./OrderSummary.css";
import {CartIdType} from "../../cart/CartSlice";
import {CartPricing, TaxLineItem} from "../../cart/CartPricing";
import {DiscountTypeNames} from "../../discount/Discount";
import {DiscountEdit} from "../../discount/edit/DiscountEdit";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {Order} from "../../order/Order";
import {DiscountTable} from "./DiscountTable";
import {ShippingDisplay} from "./ShippingDisplay";
import {LoadingSpinner} from "../../input/utils/LoadingSpinner";

interface OrderSummaryProps {
    // for new orders (carts)
    cartId?: CartIdType,
    order?: Order,
    cartPricing?: CartPricing,
    isFetchingCartPricing: boolean,
    // for existing orders
    orderId?: number,
    defaultShippingOptionId?: number,
    shippingOption?: ShippingOption,
    toAddress?: Address,
    onShippingOptionChange?: (shippingOption?: ShippingOption) => void,
    locked?: boolean,
}

export const OrderSummary = (props: OrderSummaryProps) => {
    const [isLineItemModalOpen, setIsLineItemModalOpen] = useState(false);
    const toggleLineItemModal = () => setIsLineItemModalOpen(old => !old);
    const [ isEditDiscountModalOpen, setIsEditDiscountModalOpen ] = useState(false);

    // pricing could be for the order or for the cart, depending on which id is provided
    const {data: orderPricing, isFetching: isFetchingOrderPricing} = useGetOrderPricingQuery(props.orderId ?? skipToken);
    const pricing = props.cartPricing ?? orderPricing;
    const taxLineItems = pricing?.taxLineItems;
    const [ isFetchingShippingOptions, setIsFetchingShippingOptions ] = useState(false);
    const isFetchingPricing = () => props.isFetchingCartPricing || isFetchingOrderPricing;
    const isFetching = () => isFetchingPricing() || isFetchingShippingOptions;


    // Disable new discounts if one of each discount is already added
    const newDiscountsDisabled = ((pricing?.discounts.length ?? 0) >= Object.keys(DiscountTypeNames).length) || (props.locked ?? false) || props.cartId === undefined;
    const discountsTotal = useMemo(() => {
        const discountsTotal = pricing?.discounts.reduce((accumulator, discount) =>
            accumulator + (discount?.effectiveAmount ?? 0)
        , -0) ?? -0;
        return -1 * discountsTotal;
    }, [pricing?.discounts]);

    const getRefundOrVoidedTotal = () => {
        return (pricing?.refundTotal ?? 0) + (pricing?.voidedTotal ?? 0);
    }

    return (
        <Card>
            <CardHeader className='d-flex justify-content-between card-header'>
                <h5 className='mb-0'>Order Summary</h5>
                <Button color='primary' onClick={() => setIsLineItemModalOpen(true)}>View Detail Pricing</Button>
            </CardHeader>
            <CardBody className="p-0">

                <div className="order-summary-pricing-details-container">
                    <div className="order-summary-pricing-details-label">Discounts:</div>
                    {isFetching()
                        ? <span className="order-summary-pricing-details-spinner"><LoadingSpinner size="sm" /></span>
                        : <span className="order-summary-pricing-details-value">{CurrencyFormatter.format(discountsTotal)}</span>
                    }
                    {!!props.cartId &&
                        <Button color='primary' 
                                onClick={() => setIsEditDiscountModalOpen(true)}
                                disabled={newDiscountsDisabled || isFetching()}>
                            Add Discount
                        </Button>
                    }
                </div>

                <div className="order-summary-pricing-details-container">
                    <div className="order-summary-pricing-details-label">Product Total:</div>
                    {isFetching()
                        ? <span className="order-summary-pricing-details-spinner"><LoadingSpinner size="sm" /></span>
                        : <div className="order-summary-pricing-details-value">{CurrencyFormatter.format(pricing?.retailProductTotal ?? 0)}</div>
                    }
                </div>

                {!!(pricing?.discounts?.length) &&
                    <DiscountTable discounts={pricing.discounts} isCart={!!props.cartId} isFetching={isFetching()}/>
                }

                {orderPricing &&
                    <div className="order-summary-pricing-details-container">
                        <div className="order-summary-pricing-details-label">Refunds:</div>
                        <div className="order-summary-pricing-details-value">{CurrencyFormatter.format(-1 * (getRefundOrVoidedTotal()))}</div>
                    </div>
                }

                <ShippingDisplay
                    onShippingOptionChange={props.onShippingOptionChange}
                    onFetchingShippingOptions={setIsFetchingShippingOptions}
                    locked={props.locked}
                    orderId={props.orderId}
                    cartId={props.cartId}
                    shippingOptionId={props.shippingOption?.id ?? props.defaultShippingOptionId}
                    toAddress={props.toAddress}
                    shippingPrice={pricing?.retailShippingTotal ?? 0}
                    shippingServiceDescription={props.order?.shippingService?.serviceDescription ?? ''}/>

                <div className="order-summary-pricing-details-container">
                    <div className="order-summary-pricing-details-label">Handling:</div>
                    {isFetching()
                        ? <span className="order-summary-pricing-details-spinner"><LoadingSpinner size="sm" /></span>
                        : <div className="order-summary-pricing-details-value">{CurrencyFormatter.format(pricing?.retailHandlingTotal ?? 0)}</div>
                    }
                </div>

                <div className="order-summary-pricing-details-container">
                    <div className="order-summary-pricing-details-label">Sub-Total:</div>
                    {isFetching()
                        ? <span className="order-summary-pricing-details-spinner"><LoadingSpinner size="sm" /></span>
                        : <div className="order-summary-pricing-details-value">{CurrencyFormatter.format(pricing?.subTotal ?? 0)}</div>
                    }
                </div>

                <div className="order-summary-pricing-details-container">
                <div className="order-summary-pricing-details-label">Tax:</div>
                    {isFetching()
                        ? <span className="order-summary-pricing-details-spinner"><LoadingSpinner size="sm" /></span>
                        : <div className="order-summary-pricing-details-value">{CurrencyFormatter.format(pricing?.retailTaxAmountWithoutLineItems ?? 0)}</div>
                    }
                </div>

                {taxLineItems?.map(((item: TaxLineItem) => {
                        return (
                          item.amount > 0 ? (
                            <div key={item.description} className="order-summary-pricing-details-container">
                                <div className="order-summary-pricing-details-label">{item.description}:</div>
                                {isFetching()
                                    ? <span className="order-summary-pricing-details-spinner"><LoadingSpinner size="sm" /></span>
                                    : <div className="order-summary-pricing-details-value">{CurrencyFormatter.format(item.amount ?? 0)}</div>
                                }
                            </div>
                          ) : <></>
                        )
                    }))
                }

                <div className="order-summary-pricing-details-container">
                    <div className="order-summary-pricing-details-label">Total:</div>
                    {isFetching()
                        ? <span className="order-summary-pricing-details-spinner"><LoadingSpinner size="sm" /></span>
                        : <div className="order-summary-pricing-details-value">{CurrencyFormatter.format(pricing?.total ?? 0)}</div>
                    }
                </div>

            </CardBody>

            {/* Line Item Modal */}
            <Modal size='xl' isOpen={isLineItemModalOpen} toggle={toggleLineItemModal}>
                <ModalHeader toggle={toggleLineItemModal}>Line Item Summary</ModalHeader>
                <ModalBody>
                    <LineItemSummary orderId={props.orderId} cartId={props.cartId} shippingOption={props.shippingOption}
                                    toAddress={props.toAddress}/>
                </ModalBody>
            </Modal>

            <DiscountEdit pricing={pricing} cartId={props.cartId} orderId={props.orderId} isOpen={isEditDiscountModalOpen} toggleModal={() => setIsEditDiscountModalOpen(old => !old)}/>
        </Card>
    );
}
