import './ImageZoom.css';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useGetImageHostQuery, useGetImagesQuery } from '../../../app/apiSlice';
import React, { useState } from 'react';
import ScrollToTop from '../../helpers/ScrollToTop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import { skipToken } from '@reduxjs/toolkit/query';

interface ImageZoomProps {
    title?: string,
    imageId?: number,
    imageName?: string,
    width?: number,
    height?: number,
    magnifierHeight?: number,
    magnifierWidth?: number,
    zoomLevel?: number,
    imageUrl?: string
}

export const ImageZoom = ({title, imageId, imageName, width, height, magnifierHeight = 300, magnifierWidth = 300, zoomLevel = 1.5, imageUrl} : ImageZoomProps) => {
    const { data: host } = useGetImageHostQuery();
    const { data: images } = useGetImagesQuery(!host ? skipToken : undefined);
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);
    const [ modal, setModal ] = useState(false);

    const imageIndex = imageId ? images?.findIndex(i => i.id === imageId) : images?.findIndex(i => i.name === imageName ?? "imageNotFound");
    
    let imageSrc = '';
    let imageAlt = '';
    if (imageId && imageIndex && images) {
        imageSrc = host + images[imageIndex].filePath;
        imageAlt = images[imageIndex].name;
    } else if (imageUrl) {
        imageSrc = imageUrl;
        imageAlt = 'Preview Check Image';
    }

    const toggle = () => setModal(!modal);

    const onMouseEnter = (e: React.MouseEvent) => {
         // update image size and turn-on magnifier
         const elem = e.currentTarget;
         const { width, height } = elem.getBoundingClientRect();
         
         setSize([width, height]);
         setShowMagnifier(true);
    }

    const onMouseMove = (e: React.MouseEvent) => {
        // update cursor position
        const elem = e.currentTarget as HTMLElement;
        const { top, left } = elem.getBoundingClientRect();

        // calculate cursor position on the image
        const x = e.pageX - left - window.scrollX;
        const y = e.pageY - top - window.scrollY;
        
        setXY([x, y]);
    }

    const onMouseLeave = () => {
        setShowMagnifier(false);
    }

    const getImageForZoom = () => {
        if ((!images || imageIndex === undefined || imageIndex === -1) && !imageUrl) {
            return <></>
        }

        return (
            <img 
                src={imageSrc} 
                alt={imageAlt}
                style={{ height: height, width: width }}
                onMouseEnter={(e) => {onMouseEnter(e)}}
                onMouseMove={(e) => {onMouseMove(e)}}
                onMouseLeave={onMouseLeave}
            ></img>
        );
    }

    return (
        <span>
            <span onClick={toggle} className="product-preview-image">
                <ScrollToTop></ScrollToTop>
                <img src={imageSrc} alt={imageAlt} key={imageId}></img>
                <span className='preview-image-zoom-option'>
                    <FontAwesomeIcon className='preview-image-zoom-option-icon' icon={faMagnifyingGlassPlus}></FontAwesomeIcon>
                </span>
            </span>
                <Modal isOpen={modal} toggle={toggle} size='xl'>
                    <ModalHeader toggle={toggle}>{title}</ModalHeader>
                    <ModalBody className='image-zoom-modal-body'>
                        <span className='image-zoom-image-container' style={{ height: height, width: width }}>
                            {getImageForZoom()}
                            <div
                            className='image-zoom-div'
                                style={{
                                display: showMagnifier ? "" : "none",
                                // move element center to cursor pos
                                top: `${y - magnifierHeight / 2}px`,
                                left: `${x - magnifierWidth / 2}px`,
                                backgroundImage: `url('${imageSrc}')`,
                                //calculate zoomed image size
                                backgroundSize: `${imgWidth * zoomLevel}px ${
                                    imgHeight * zoomLevel
                                }px`,
                                //calculate position of zoomed image.
                                backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
                                backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
                                }}
                            ></div>
                        </span>
                    </ModalBody>
                </Modal>
        </span>
    );
}
