import {FieldValues, useFormContext} from "react-hook-form";
import {skipToken} from "@reduxjs/toolkit/query";
import React, {useEffect} from "react";
import {FormSelect, FormSelectProps} from "../components/forms/FormSelect";
import {useAppSelector} from "../app/hooks";
import {selectCurrentConsumerUser} from "../components/user/login/AuthenticationSlice";
import {useGetAddressesByConsumerIdQuery} from "../app/apiSlice";

interface ConsumerAddressInputProps
    extends Omit<FormSelectProps<FieldValues>, 'options'> {

    readonly section?: 'billing' | 'shipping' | string;
}

export function ConsumerAddressInput(
    {
        name,
        section = '',
        onChange = () => void 0,
    }: ConsumerAddressInputProps) {
    const consumer = useAppSelector(selectCurrentConsumerUser);
    const {data: consumerAddresses = []} = useGetAddressesByConsumerIdQuery(consumer?.id ?? skipToken)

    const {setValue} = useFormContext();

    useEffect(() => {
        if (consumerAddresses) {
            let defaultAddress = consumerAddresses.find(
                a =>
                    section === 'shipping' ? a.isDefaultShippingAddress :
                        section === 'billing' ? a.isDefaultBillingAddress : null
            );
            if (defaultAddress)
                setValue(name, defaultAddress.id);
        }
    }, [consumerAddresses]);

    const options = [{
        text: 'New Address',
        value: -1
    }, ...consumerAddresses.map(o => ({
        text: o.street!,
        value: o.id!
    }))];

    return (
        <FormSelect name={name}
                    label="Saved Addresses"
                    options={options}
                    onChange={onChange}
        />
    );
}