import './ResetPassword.css';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {Alert, Button, Form, FormFeedback, FormGroup, Input, Label, Spinner} from 'reactstrap';
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {useGetIsResetPasswordTokenValidQuery, useSetPasswordMutation, useRequestNewResetTokenLinkMutation} from "../../../app/apiSlice";
import { PasswordInput } from "../../input/password/PasswordInput";
import {useAppDispatch} from "../../../app/hooks";
import {showInformationModal} from "../../modal/ModalSlice";
import {LoadingSpinner} from "../../input/utils/LoadingSpinner";

interface ResetPasswordFormState {
    password: string;
    confirmPassword: string,
    token: string | null,
}

export const ResetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const resetToken = searchParams.get("resetToken");
    const dispatch = useAppDispatch();
    
    const { data: resetPasswordTokenResponse, isFetching } = useGetIsResetPasswordTokenValidQuery(resetToken ? {token: resetToken} : skipToken);
    const [setPassword] = useSetPasswordMutation();
    const [requestNewResetToken, {isLoading: isRequestingNewResetToken}] = useRequestNewResetTokenLinkMutation();
    
    const [showResetSuccessful, setShowResetSuccessful] = useState(false)
    const [showErrors, setShowErrors] = useState(false);
    const [hasRequestedResetToken, setHasRequestedResetToken] = useState(false);

    const [formState, setFormState] = useState<ResetPasswordFormState>({
        password: '',
        confirmPassword: '',
        token: resetToken,
    });

    const onResetSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setShowErrors(true);
        if (!formState.password || !formState.confirmPassword || formState.password !== formState.confirmPassword || !resetToken) return; // invalid
        
        try {
            await setPassword({
                password: formState.password,
                token: resetToken,
            });
            setShowResetSuccessful(true);
        } catch (err: any) {
            dispatch(showInformationModal({
                title: 'Failed to Reset Password!',
                text: 'Please try again or contact support.'
            }));
        }
    }
   
    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({ ...prev, password: e.target.value}));
    }

    const onConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({ ...prev, confirmPassword: e.target.value}));
    }

    const onRequestNewTokenClicked = async () => {
        if (!resetToken)
            return;

        try {
            await requestNewResetToken({token: resetToken}).unwrap();

            setHasRequestedResetToken(true);
        } catch (e: any) {
            setHasRequestedResetToken(false);
        }
    }

    const renderInvalidTokenMessage = () => (
        <span className='d-flex flex-column'>
            <span className='reset-password-form-title'>
                {t(`setPassword.invalidTokenTitle`)}
            </span>
            <span className='reset-password-form-body'>
                {t(`setPassword.invalidTokenMessage`)}
            </span>
            <span className='pt-2'>
                <Button
                    onClick={onRequestNewTokenClicked}
                    disabled={isRequestingNewResetToken || hasRequestedResetToken}>
                     {t(`setPassword.newTokenRequestButtonText`)}
                    {isRequestingNewResetToken && <Spinner/>}
                </Button>
            </span>

            {hasRequestedResetToken &&
                <span className='reset-password-form-body pt-2'>
                    <Alert color='primary'>
                     {t(`setPassword.newTokenRequestedMessage`)}
                    </Alert>
                </span>
            }
        </span>
    )

    const renderResetPasswordForm = () => (
        <span className='d-flex flex-column'>
            <span className='reset-password-form-title'>
                {resetPasswordTokenResponse?.isNewAccount ? t(`setPassword.newAccount.title`) : t(`setPassword.resetPassword.title`)}
            </span>
            <Form onSubmit={onResetSubmit}>
                <FormGroup>
                    <Label htmlFor='emailAddress'>{t(`setPassword.emailLabel`)}</Label>
                    <Input id="emailAddress"
                           type='text'
                           disabled={true}
                           value={resetPasswordTokenResponse?.email ?? ""} />
                </FormGroup>
                <FormGroup>
                    <Label for="password">{t(`setPassword.passwordLabel`)}</Label>
                    <PasswordInput
                        id="password"
                        name="password"
                        value={formState.password}
                        onChange={(e) => onPasswordChange(e)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="confirmPassword">{t(`setPassword.confirmPasswordLabel`)}</Label>
                    <PasswordInput
                        id="confirm-password"
                        name="confirm-password"
                        isConfirmInput
                        value={formState.confirmPassword}
                        onChange={(e) => onConfirmPasswordChange(e)}
                        invalid={formState.confirmPassword !== formState.password}
                        suppressError={!showErrors}
                    />
                    { formState.confirmPassword !== formState.password && showErrors &&
                        <FormFeedback invalid className='d-block'>{t('setPassword.errors.confirmPasswordInvalid')}</FormFeedback>
                    }
                </FormGroup>
                <span className='container'>
                    <span className="mt-1 d-flex">
                        <Button type='submit' color='primary'>
                            {resetPasswordTokenResponse?.isNewAccount ? t(`setPassword.newAccount.buttonText`) : t(`setPassword.resetPassword.buttonText`)}
                        </Button>
                    </span>
                </span>
            </Form>
        </span>
    )

    const renderResetSuccessfulMessage = () => {
        return (
            <span className='d-flex flex-column'>
                <span className='reset-password-form-title'>
                    {resetPasswordTokenResponse?.isNewAccount ? t(`setPassword.newAccount.successTitle`) : t(`setPassword.resetPassword.successTitle`)}
                </span>
                <span className='reset-password-form-body mb-4'>
                    You can now sign in with your new password
                </span>
                <Link className="btn btn-primary sign-in-btn text-light" to={`/login`}>Sign in</Link>
            </span>
        );
    }

    const getLoadingSpinner = () => {
        return (
            <Spinner size="sm">
                Loading...
            </Spinner>
        );
    }

    if (!resetToken) {
        navigate('/sign-in');
    }

    return (
        <span className='reset-password-page d-flex'>
            <span className='reset-password-page-container d-flex flex-column'>
                <span className='reset-password-content d-flex flex-column'>
                    {isFetching && getLoadingSpinner()}
                    {!showResetSuccessful && !resetPasswordTokenResponse && !isFetching && renderInvalidTokenMessage()}
                    {!showResetSuccessful && resetPasswordTokenResponse && !isFetching && renderResetPasswordForm()}
                    {showResetSuccessful && renderResetSuccessfulMessage()}
                </span>
            </span>
        </span>
    );
}