import './Faq.css';
import {useTranslation} from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane, Container } from 'reactstrap';
import { SetStateAction, useState } from 'react';
import { ContactAccordion } from './accordions/ContactAccordion';
import { OrderAccordion } from './accordions/OrderAccordion';
import { SecurityAccordion } from './accordions/SecurityAccordion';
import ScrollToTop from '../../helpers/ScrollToTop';
import { Breadcrumb } from '../../breadcrumb/Breadcrumb';
import {Helmet} from "react-helmet-async";

export const Faq = () => {
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const { t } = useTranslation();

    const toggle = (tab: SetStateAction<string>) => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }

    function getDefaultDocumentMetadata () {
        return (
            <Helmet>
              <title>{t(`pages.faq.metaTitle`)}</title>
              <meta name="description" content={t(`pages.faq.metaDescription`)} />
            </Helmet>
        )
      }

    return (
        <span className='faq-page d-flex flex-column'>
            <ScrollToTop></ScrollToTop>
            {getDefaultDocumentMetadata()}
            <Container>
                <Breadcrumb currentPage={t(`pages.faq.title`)}></Breadcrumb>
                <span className='faq-container d-flex flex-column'>
                    <span className='faq-title d-flex'>
                        <h1 className=''>{t(`pages.faq.title`)}</h1>
                    </span>
                    <span className='faq-body d-flex flex-column'>
                        <span className='faq-nav-items d-flex flex-row'>
                            <Nav className='faq-nav' tabs>
                                <NavItem className={currentActiveTab === '1' ? 'faq-nav-item-active' : 'faq-nav-item'}>
                                    <NavLink className={currentActiveTab === '1' ? 'faq-nav-link-active' : 'faq-nav-link'} onClick={() => { toggle('1'); }}>
                                        {t(`pages.faq.help.title`)}
                                    </NavLink>
                                </NavItem>
                                <NavItem className={currentActiveTab === '2' ? 'faq-nav-item-active' : 'faq-nav-item'}>
                                    <NavLink className={currentActiveTab === '2' ? 'faq-nav-link-active' : 'faq-nav-link'} onClick={() => { toggle('2'); }}>
                                        {t(`pages.faq.contact.title`)}
                                    </NavLink>
                                </NavItem>
                                <NavItem className={currentActiveTab === '3' ? 'faq-nav-item-active' : 'faq-nav-item'}>
                                    <NavLink className={currentActiveTab === '3' ? 'faq-nav-link-active' : 'faq-nav-link'} onClick={() => { toggle('3'); }}>
                                        {t(`pages.faq.security.title`)}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </span>
                        <TabContent activeTab={currentActiveTab}>
                            <TabPane tabId='1'>
                                <OrderAccordion />
                            </TabPane>
                            <TabPane tabId='2'>
                                <ContactAccordion />
                            </TabPane>
                            <TabPane tabId='3'>
                                <SecurityAccordion />
                            </TabPane>
                        </TabContent>   
                    </span>
                </span>
            </Container>
        </span>
    );
};