
export interface OrderStatus {
    id: number;
    name: string;
    description: string;
}

export enum OrderStatuses {
    NewOrder = 'New Order',
    Reprint = 'Reprint',
    BadData = 'Bad Data',
    Hold = 'Hold',
    Manufacturing = 'Manufacturing',
    Complete = 'Complete',
    Cancelled = 'Cancelled',
    Resolved = 'Resolved',
    ErrorOnSubmission = 'Error On Submission',
    CancelledDNS = 'Cancelled DNS',
}