import {TrackForm} from './trackForm/TrackForm';
import './TrackOrder.css';
import {useTranslation} from 'react-i18next';
import {LoginForm} from '../../../user/login/loginForm/LoginForm';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {BrowserStorageType, fetchStorageValue} from '../../../../utils/Utils';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import {Container} from 'reactstrap';

export const TrackOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userInfoLocalStorageKey = 'ProductCatalogUserInfo-value';
  const consumer = fetchStorageValue(userInfoLocalStorageKey, BrowserStorageType.LocalStorage);

  useEffect(() => {
    if (consumer) {
      navigate(`/track-order/user-order-details`)
    }
  }, [navigate, consumer]);


  return (
    <span className='track-order-page'>
      <Container>
      <span className='track-order-page-container'>
        <span className='track-order-content'>
          <div className='login-form-container'>
            <LoginForm title={t(`tracking.signInForm.title`)} showSignUp={false} redirectLink='/track-order/user-order-details' />
          </div>

          <div className='vertical-line-container d-flex flex-column'>
            <hr className="vertical-line"/>
            <div className="vertical-line-text">OR</div>
            <hr className="vertical-line"/>
          </div>

          <TrackForm />
        </span>
        <div className='need-help-container d-flex flex-row'>
          <div className='need-help-icon-container'>
            <FontAwesomeIcon className='need-help-icon' icon={faPhone} />
          </div>
          <span className='need-help-text'>
            {t(`tracking.needHelpText`)} 
            <a className='need-help-phone-link' href={'tel:' + t(`phoneNumber.number`)}>{t(`phoneNumber.display`)}</a>
          </span>
        </div>
        
      </span>
      <div className='order-tracking-info-container d-flex flex-column'>
          <span><b>{t(`tracking.info.title`)}</b></span>
          <div><br /></div>
          <ul className='order-tracking-info-list'>
            <li>
              <span><b>{t(`tracking.info.listItem1Part1`)}</b>{t(`tracking.info.listItem1Part2`)}</span>
            </li>
            <li>
              <span><b>{t(`tracking.info.listItem2Part1`)}</b>{t(`tracking.info.listItem2Part2`)}</span>
            </li>
            <li>
              <span><b>{t(`tracking.info.listItem3Part1`)}</b>{t(`tracking.info.listItem3Part2`)}</span>
            </li>
            <li>
              <span>{t(`tracking.info.listItem4`)}</span>
            </li>
            <li>
              <span>{t(`tracking.info.listItem5`)}</span>
            </li><li>
              <span>{t(`tracking.info.listItem6`)}</span>
            </li>
            <li>
              <span>{t(`tracking.info.listItem7`)}<a href={t(`tracking.info.listItem7Link`)}>{t(`tracking.info.listItem7LinkText`)}</a>.</span>
            </li>
          </ul>
          <span>{t(`tracking.info.additionalInformation`)}<a href={t(`tracking.info.additionalInformationLink`)}>{t(`tracking.info.additionalInformationLinkText`)}</a>.</span>
        </div>
        </Container>
    </span>
  );
}
