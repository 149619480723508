import './Accordions.css';
import {useTranslation} from "react-i18next";
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap';
import { useState } from 'react';
import { ProductCard } from './OrderAccordionCards/ProductCard';
import { PricingCard } from './OrderAccordionCards/PricingCard';
import { OrderingCard } from './OrderAccordionCards/OrderingCard';
import { AfterOrderCard } from './OrderAccordionCards/AfterOrderCard';

export const OrderAccordion = () => {
    const { t } = useTranslation();
    
    const [open, setOpen] = useState('');
    const toggle = (id: string) => setOpen(open === id ? '0' : id);

    return (
        <div className='contact-accordion-container'>
            <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                    <AccordionHeader targetId='1'>{t(`pages.faq.help.product.title`)}</AccordionHeader>
                    <AccordionBody accordionId='1'>
                        <ProductCard />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId='2'>{t(`pages.faq.help.pricing.title`)}</AccordionHeader>
                    <AccordionBody accordionId='2'>
                        <PricingCard />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId='3'>{t(`pages.faq.help.ordering.title`)}</AccordionHeader>
                    <AccordionBody accordionId='3'>
                        <OrderingCard />
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId='4'>{t(`pages.faq.help.afterOrder.title`)}</AccordionHeader>
                    <AccordionBody accordionId='4'>
                        <AfterOrderCard />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </div>
    )
}