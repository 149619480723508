import './ContactForm.css';
import {useTranslation} from "react-i18next";
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { ChangeEvent, useState } from 'react';
import ContactErrors from './ContactErrors';
import { useContactMutation } from '../../../../app/apiSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { RequiredAsterisk } from '../../../input/utils/RequiredAsterisk';
import { RequiredToast } from '../../../input/utils/RequiredToast';
import ContactRequest from './ContactRequest';
import { EmailInput } from "../../../input/email/EmailInput";
import {formatPhoneNumber, phoneNumberInputMaxLength, Regexes} from "../../../../utils/Utils";
import { useAppDispatch } from '../../../../app/hooks';
import { showInformationModal } from '../../../modal/ModalSlice';

interface ContactFormState {
    name: string;
    email: string;
    phoneNumber?: string;
    comment: string;
    errors: ContactErrors;
}

export const ContactForm = () => {
    const { t } = useTranslation();
    const [contactMethod] = useContactMutation();
    const [ showRequiredFieldToast, setShowRequiredFieldToast ] = useState(false);
    const [ formState, setFormState ] = useState<ContactFormState>({
        name: "",
        email: "",
        phoneNumber: "",
        comment: "",
        errors: {
            name: t(`pages.contact.errors.name`),
            email: "",
            phoneNumber: "",
            comment: t(`pages.contact.errors.comment`),
        },
    });
    const [ showErrors, setShowErrors ] = useState(false);
    const [ clearingForm, setClearingForm ] = useState(false);
    const dispatch = useAppDispatch();

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        const err = e.target.value.length < 3 ? t(`pages.contact.errors.name`) : '';
        setFormState((prev) => ({ ...prev , name: e.target.value, errors: { ...prev.errors, name: err}}));
    }

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setClearingForm(false);
        let err = '';
        if (e.target.value === 'help@checkworks.com' || e.target.value === 'customerservice@checkworks.com') {
            err = t(`pages.contact.errors.email`);
        }
        setFormState((prev) => ({ ...prev , email: e.target.value, errors: { ...prev.errors, email: err }}));
    }

    const onPhoneNumberChange = (phoneNumber?: string) => {
        const phoneNumberValue = formatPhoneNumber(phoneNumber);
        let err = '';
        if (phoneNumber && phoneNumber!.length > 0) {
            err = !Regexes.phoneNumber.test(phoneNumberValue ?? "") ? t(`pages.contact.errors.phoneNumber`) : '';
        }
        setFormState((prev) => ({ ...prev , phoneNumber: phoneNumberValue, errors: { ...prev.errors, phoneNumber: err}}));
    }

    const onCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const err = e.target.value.length < 3 ? t(`pages.contact.errors.comment`) : '';
        setFormState((prev) => ({ ...prev , comment: e.target.value, errors: {...prev.errors, comment: err }}));
    }

    const hasAllRequiredFields = () => {
        return formState.name && formState.name.length >= 3 && formState.comment && formState.comment.length >= 3 && formState.email;
    }

    const submit = async () => {
        setShowErrors(true);
        const valid = Object.values(formState.errors).every(err => err === '');
        if (!valid || !hasAllRequiredFields()) {
            setShowRequiredFieldToast(true);            
            setTimeout(function() {
                setShowRequiredFieldToast(false);
            }, 5_000);
            return;
        }
    
        try {
            const { errors, ...contactRequest } = formState;
            await contactMethod(contactRequest as ContactRequest).unwrap();
            setFormState({
                name: "",
                email: "",
                phoneNumber: "",
                comment: "",
                errors: {
                    name: "",
                    email: "",
                    phoneNumber: "",
                    comment: "",
                },
            });
            setClearingForm(true);
            setShowErrors(false);
            dispatch(showInformationModal({
                title: t('pages.contact.successModal.title'),
                text: t('pages.contact.successModal.body') + t('phoneNumber.display')
            }))
        } catch (err) {
            dispatch(showInformationModal({
                title: t('pages.contact.errorModal.title'),
                text: t('pages.contact.errorModal.body')
            }));
        }
    };

    return (
        <span className='contact-form d-flex flex-column'>
            <Form id="contact-form">
                <FormGroup>
                    <Label  className='contact-us-form-label' for='Name'>
                        {t(`pages.contact.nameInput`)}
                        <RequiredAsterisk></RequiredAsterisk>
                    </Label>
                    <Input id='Name' type='text' name='name' value={formState.name} onChange={onNameChange} invalid={showErrors && !!formState.errors.name} />
                    <FormFeedback>{formState.errors.name}</FormFeedback>
                </FormGroup>

                <FormGroup>
                    <Label className='contact-us-form-label' for='Email'>
                        {t(`pages.contact.emailInput`)}
                        <RequiredAsterisk></RequiredAsterisk>
                    </Label>
                    <EmailInput id='Email' type='email' name='email' value={formState.email} onChange={onEmailChange} invalid={showErrors && !!formState.errors.email} suppressError={!showErrors} clear={clearingForm}/>
                    <FormFeedback>{formState.errors.email}</FormFeedback>
                </FormGroup>

                <FormGroup>
                    <Label className='contact-us-form-label' for='Phone'>{t(`pages.contact.phoneInput`)}</Label>
                    <Input id='Phone'
                           type='tel'
                           name='phone'
                           maxLength={phoneNumberInputMaxLength}
                           value={formState.phoneNumber}
                           placeholder='(   ) ___-____'
                           onChange={(e) => onPhoneNumberChange(e.target.value)}
                           invalid={showErrors && !!formState.errors.phoneNumber} />
                    <FormFeedback>{formState.errors.phoneNumber}</FormFeedback>
                </FormGroup>

                <FormGroup>
                    <Label  className='contact-us-form-label' for='Comment'>
                        {t(`pages.contact.commentInput`)}
                        <RequiredAsterisk></RequiredAsterisk>
                    </Label>
                    <Input id='Comment' type='textarea' name='comment' value={formState.comment} onChange={onCommentChange} invalid={showErrors && !!formState.errors.comment} />
                    <FormFeedback>{formState.errors.comment}</FormFeedback>
                </FormGroup>
                <span>
                    <Button className='contact-us-form-button' color='primary' onClick={submit}>
                        <span className='contact-us-button-content'>
                            <FontAwesomeIcon icon={faPaperPlane} />
                            <span className='contact-form-submit-text'>{t(`pages.contact.submitButton`)}</span>
                        </span>
                    </Button>
                    <span className='contact-us-required-toast'>                   
                        <RequiredToast  isOpen={showRequiredFieldToast} dismissToast={() => setShowRequiredFieldToast(false)}/>
                    </span>
                </span>
            </Form>
        </span>
    );
}