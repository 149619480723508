import './Links.css';
import {useTranslation} from "react-i18next";
import { Link } from 'react-router-dom';
import {doesTranslationValueExist} from "../../../utils/Utils";

export const Links = () => {
    const { t } = useTranslation();

    return (
        <div className='links-container d-flex flex-row sm-ms-3'>
            <div className='header-links-container d-flex flex-column'>
                <h4 className='heading'>{t(`footer.links.companyLinksHeader`)}</h4>
                <div className='company-links d-flex flex-column flex-start'>
                    {doesTranslationValueExist(t, 'footer.links.companyLinkContact') &&
                      <Link to={'/contact-us'} className='link'><p>{t(`footer.links.companyLinkContact`)}</p></Link>
                    }
                    {doesTranslationValueExist(t, 'footer.links.companyLinkAbout') &&
                      <Link to={'/about-us'} className='link'><p>{t(`footer.links.companyLinkAbout`)}</p></Link>
                    }
                    {doesTranslationValueExist(t, 'footer.links.companyLinkBlog') &&
                      <a href={t('footer.links.blog')} className='link'><p>{t(`footer.links.companyLinkBlog`)}</p></a>
                    }
                    {doesTranslationValueExist(t, 'footer.links.companyLinkFaq') &&
                      <Link to={'/faq'} className='link'><p>{t(`footer.links.companyLinkFaq`)}</p></Link>
                    }
                </div>
            </div>
            <div className='header-links-container d-flex flex-column'>
                <h4 className='heading'>{t(`footer.links.policiesLinksHeader`)}</h4>
                <div className='company-links d-flex flex-column'>
                    {doesTranslationValueExist(t, 'footer.links.policiesLinkReturn') &&
                      <Link to={'/return-policy'} className='link'><p>{t(`footer.links.policiesLinkReturn`)}</p></Link>
                    }
                    {doesTranslationValueExist(t, 'footer.links.policiesLinkGuarantee') &&
                      <Link to={'/guarantee'} className='link'><p>{t(`footer.links.policiesLinkGuarantee`)}</p></Link>
                    }
                    {doesTranslationValueExist(t, 'footer.links.policiesLinkPrivacy') &&
                      <Link to={'/privacy-policy'} className='link'><p>{t(`footer.links.policiesLinkPrivacy`)}</p></Link>
                    }
                    {doesTranslationValueExist(t, 'footer.links.policiesLinkCalifornia') &&
                      <Link to={'/ca-prop-65'} className='link'><p>{t(`footer.links.policiesLinkCalifornia`)}</p></Link>
                    }
                </div>
            </div>
        </div>
    );
};