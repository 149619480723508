import {ConfigurationRule, RuleTypes} from "./ConfigurationRule";
import {ProductInputValue} from "../../ProductInputValue";
import {AddressInputVm} from "../../address/Address";

export class ConfigurationRuleEvaluator {

    static evaluateRules = (rules: ConfigurationRule[], productInputValues: ProductInputValue[], productVariantId?: number, ruleTypesToEvaluate?: RuleTypes[]) => {

        const getConfigurationInputValue = (configurationDetailId: number) => {
            const inputValueIndex = productInputValues.findIndex(piv => piv.productInputId === configurationDetailId);
            return (inputValueIndex > -1 ) ? productInputValues[inputValueIndex] : null;
        }

        const shouldEvaluateRule = (rule: ConfigurationRule, ruleType: RuleTypes, productVariantId?: number, ruleTypesToEvaluate?: RuleTypes[]) => {
            // check to make sure that this rule applies to this product variant, first
            if (productVariantId && rule.referenceProductVariantId && rule.referenceProductVariantId !== productVariantId)
                return false;
            
            const ruleTypeShouldBeEvaluated = rule.configurationRuleType.name === ruleType && (!ruleTypesToEvaluate || ruleTypesToEvaluate.includes(ruleType));

            if (rule.referenceRegexValue && rule.referenceConfigurationDetailId) {
                const referenceInputValue = getConfigurationInputValue(rule.referenceConfigurationDetailId);
                const referenceValueMatches = referenceInputValue?.value ?
                    ConfigurationRuleEvaluator.evaluateRegexRule(referenceInputValue.value, rule.referenceRegexValue)
                    : false;

                return referenceValueMatches && ruleTypeShouldBeEvaluated;
            }

            return ruleTypeShouldBeEvaluated;
        }


        const brokenRules = [];
        let isFollowingRules = true;
        let productInputValue: ProductInputValue | null = null;
        for (let rule of rules) {
            productInputValue = getConfigurationInputValue(rule.configurationDetailId);
            // Break once first rule is found not being followed
            //if (!isFollowingRules) break;
            isFollowingRules = true;

            const inputValueToEvaluate = ConfigurationRuleEvaluator.getInputValueToEvaluate(productInputValue, rule);

            switch (true) {
                case shouldEvaluateRule(rule, RuleTypes.Matching, productVariantId, ruleTypesToEvaluate):
                    if (rule.referenceConfigurationDetailId) {
                        isFollowingRules = ConfigurationRuleEvaluator.evaluateMatchingRule(productInputValue, getConfigurationInputValue(rule.referenceConfigurationDetailId));
                    }
                    break;
                case shouldEvaluateRule(rule, RuleTypes.Differing, productVariantId, ruleTypesToEvaluate):
                    if (rule.referenceConfigurationDetailId) {
                        isFollowingRules = ConfigurationRuleEvaluator.evaluateDifferingRule(productInputValue, getConfigurationInputValue(rule.referenceConfigurationDetailId))
                    }
                    break;
                case shouldEvaluateRule(rule, RuleTypes.PositiveMatch, productVariantId, ruleTypesToEvaluate):
                    if (rule.regexValue && inputValueToEvaluate) {
                        isFollowingRules = ConfigurationRuleEvaluator.evaluateRegexRule(inputValueToEvaluate, rule.regexValue)
                    }
                    break;
                case shouldEvaluateRule(rule, RuleTypes.NegativeMatch, productVariantId, ruleTypesToEvaluate):
                    if (rule.regexValue && inputValueToEvaluate) {
                        isFollowingRules = ConfigurationRuleEvaluator.evaluateNegativeRegexRule(inputValueToEvaluate, rule.regexValue)
                    }
                    break;
                case shouldEvaluateRule(rule, RuleTypes.DisabledWhenValueExists, productVariantId, ruleTypesToEvaluate):
                    if (rule.referenceConfigurationDetailId) {
                        isFollowingRules = ConfigurationRuleEvaluator.evaluateDisabledWhenValueExistsRule(productInputValue, getConfigurationInputValue(rule.referenceConfigurationDetailId))
                    }
                    break;
            }

            if (!isFollowingRules) {
                brokenRules.push(rule);
            }
        }
        return brokenRules;


    }

    static evaluateMatchingRule = (productInputValue: ProductInputValue | null, referenceProductInputValue: ProductInputValue | null) => {
        return (referenceProductInputValue === null || (productInputValue === null || productInputValue.value === null || referenceProductInputValue?.value === productInputValue.value));
    }

    static evaluateDifferingRule = (productInputValue: ProductInputValue | null, referenceProductInputValue: ProductInputValue | null) => {
        return (referenceProductInputValue === null || (productInputValue === null || productInputValue.value === null || referenceProductInputValue?.value !== productInputValue.value));
    }

    static evaluateRegexRule = (input: string, regexValue: string) => {
        const regExp = new RegExp(regexValue, "ig");
        return !!input.match(regExp);
    }

    static evaluateNegativeRegexRule = (input: string, regexValue: string) => {
        const regExp = new RegExp(regexValue, "ig");
        return !input.match(regExp);
    }

    static evaluateDisabledWhenValueExistsRule = (productInputValue: ProductInputValue | null, referenceProductInputValue: ProductInputValue | null) => {
        return (referenceProductInputValue === null || (!referenceProductInputValue.value && !referenceProductInputValue.selectedProductInputOptionId));
    }

    static getInputValueToEvaluate = (productInputValue: ProductInputValue | null, rule: ConfigurationRule) => {

        if (!rule.metaTag) {
            return productInputValue?.value;
        }

        if (productInputValue?.value && rule.metaTag) {
            try {
                const address: AddressInputVm = JSON.parse(productInputValue.value);

                switch (rule.metaTag) {
                    case 'streetAddress':
                        return address.street;
                    case 'city':
                        return address.city;
                    case 'zip':
                        return address.zip;
                    case 'phoneNumber':
                        return address.phoneNumber;
                    case 'cityStateZip':
                        return `${address.city ?? ""}, ${address.stateCode ?? ""} ${address.zip ?? ""}`;
                }
            }
            catch (err) {
                return null;
            }
        }

        return null;

    }
};
