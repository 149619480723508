import '../Accordions.css';
import {useTranslation} from "react-i18next";
import {doesTranslationValueExist, getTranslationValue} from "../../../../../utils/Utils";

export const PricingCard = () => {
    const { t } = useTranslation();

    return (
        <span className='order-accordion-questions d-flex flex-column'>
            <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{t(`pages.faq.help.pricing.question1`)}</span>
                <span>
                  {t(`pages.faq.help.pricing.answer1`)}
                  {doesTranslationValueExist(t, 'pages.faq.help.pricing.answer1LinkText') && (
                    <>
                      <a href={getTranslationValue(t, 'pages.faq.help.pricing.answer1Link')}>
                        {getTranslationValue(t, 'pages.faq.help.pricing.answer1LinkText')}
                      </a>
                      <span>{getTranslationValue(t, 'pages.faq.help.pricing.answer1Pt2')}</span>
                    </>
                  )}
                </span>
            </span>
            <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{t(`pages.faq.help.pricing.question2`)}</span>
                <span>
                  {t(`pages.faq.help.pricing.answer2`)}
                  {doesTranslationValueExist(t, 'pages.faq.help.pricing.answer2LinkText') && (
                    <>
                      <a href={getTranslationValue(t, 'pages.faq.help.pricing.answer2Link')}>
                        {getTranslationValue(t, 'pages.faq.help.pricing.answer2LinkText')}
                      </a>
                      <span>{getTranslationValue(t, 'pages.faq.help.pricing.answer2Pt2')}.</span>
                    </>
                  )}
                </span>
            </span>
            <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{t(`pages.faq.help.pricing.question3`)}</span>
                <span>{t(`pages.faq.help.pricing.answer3`)}</span>
            </span>
          {doesTranslationValueExist(t, 'pages.faq.help.pricing.question4') && (
            <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{t(`pages.faq.help.pricing.question4`)}</span>
                <span>{t(`pages.faq.help.pricing.answer4`)}</span>
            </span>
          )}
        </span>
    );
};