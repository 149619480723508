import { Link } from "react-router-dom"
import { Button, ButtonProps, Input } from "reactstrap"

interface ToolRowProps {
    shouldShowCriteriaPanel: boolean
    title?: string
    printable?: boolean
    onDownload?: () => void
    newLink?: string
    newLinkText?: string
    onNewModalButtonClicked?: () => void
    onSearchChange?: (search: string) => void
    
    toggleCriteriaText: string
    onShowCriteriaToggle: () => void

    extraActions?: (ButtonProps | { key: string })[]
}
export const ToolRow = (props: ToolRowProps) =>
    <div className='d-flex justify-content-between mb-3 align-items-center'>
        { props.shouldShowCriteriaPanel && <Button color='secondary' className='criteria-toggle-button btn-secondary' outline={true} onClick={props.onShowCriteriaToggle}>{props.toggleCriteriaText} Criteria</Button>}
        {props.title && <h5 className='d-print-none'>{props.title}</h5>}
        <div className="d-flex justify-content-end w-50 me-2">
            { (props.printable ?? false) && <Button color='primary' className='mx-2' onClick={() => window.print()}>Print</Button> }
            { props.onDownload  && <Button color='primary' className='' onClick={props.onDownload}>Download</Button> }
            
            { props.newLink && <Link className='btn btn-primary mx-1 ' to={props.newLink}>{props.newLinkText ?? "New"}</Link> }
            { props.onNewModalButtonClicked && <Button className='btn mx-1 ' color='primary' onClick={props.onNewModalButtonClicked}>{props.newLinkText ?? "New"}</Button> }

            { props.extraActions?.length && props.extraActions.map(ea => <Button {...ea}/>)}

            { props.onSearchChange &&
                <Input
                    className='mx-1'
                    placeholder='Search by text...'
                    onChange={e => props.onSearchChange!(e.target.value)}/>
            }

        </div>
      </div>