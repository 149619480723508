import './NoteCreate.css';
import {
    Alert,
    Button,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import React, {useEffect, useState} from "react";
import {AddNotesRequest} from "../../order/tabs/details/AddNotesRequest";
import {useAddOrderNotesMutation, useGetOrderNoteCategoriesQuery} from "../../../../app/apiSlice";
import {getErrorMessage} from "../../../../utils/Utils";
import {RequiredAsterisk} from "../../../input/utils/RequiredAsterisk";

interface NoteCreateProps {
    orderId: number,
    isOpen: boolean,
    toggleModal: (shouldRefresh?: boolean) => void,
}
export const NoteCreate = ({orderId, isOpen, toggleModal}: NoteCreateProps) => {

    const [ addOrderNotes, {isLoading: isCreating} ] = useAddOrderNotesMutation();
    const { data: noteCategories } = useGetOrderNoteCategoriesQuery();
    const [ subjectText, setSubjectText ] = useState<string | undefined>();
    const [ noteText, setNote ] = useState<string | undefined>();
    const [ categoryId, setNoteCategoryId ] = useState<number | undefined>();
    const [ errorMessage, setErrorMessage ] = useState<string | undefined>(undefined);

    const modalNavigation = (
        <div>
            <button className='btn-close' onClick={() => toggleModal()}/>
        </div>
    );

    useEffect(() => {
        // Select first option when categories load
        const firstCategory = noteCategories?.find(() => true);
        setNoteCategoryId(firstCategory?.id);
    }, [noteCategories]);

    const onSubjectChanged = (subjectValue: string) => {
        setSubjectText(subjectValue);
    }

    const onNoteChanged = (noteValue: string) => {
        setNote(noteValue);
    }

    const onCategoryOptionChanged = (categoryValue?: string) => {
        const categoryId = categoryValue ? parseInt(categoryValue) : undefined;
        setNoteCategoryId(categoryId);
    }

    const onCreateClicked = async () => {
        setErrorMessage('');
        if (!noteText || !subjectText || !categoryId) {
            setErrorMessage('Please fill out all required fields.');
            return; // error state, missing information
        }

        const newAddNotesRequest = ({
            orderId,
            noteText,
            subjectText,
            categoryId} as AddNotesRequest);
        if (!newAddNotesRequest)
            return;
        try {
            await addOrderNotes(newAddNotesRequest).unwrap();
            toggleModal(true);
        }
        catch (e: any) {
            setErrorMessage(getErrorMessage(e));
        }
    }

    const shouldDisableCreate = () => {
        return isCreating;
    }

    const addNewNoteForm = () => {
        return (
            <>
                <FormGroup>
                    <Label for='Subject'>Subject <RequiredAsterisk/></Label>
                    <Input id='Subject' name='subject'  type="text" onChange={(e) => onSubjectChanged(e.target.value)} value={subjectText} />
                </FormGroup>

                <FormGroup>
                    <Label for='NoteCategory'>Category <RequiredAsterisk/></Label>
                    <Input type='select'
                           id='NoteCategory'
                           onChange={e => onCategoryOptionChanged(e.target.value)}
                           value={categoryId}
                           placeholder="Category">
                        {noteCategories?.map(noteCategory => (
                            <option key={noteCategory.id} value={noteCategory.id}>{noteCategory.name}</option>
                        ))}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for='Notes'>Notes <RequiredAsterisk/></Label>
                    <Input id='Notes' name='notes'  type="textarea" rows="4" onChange={(e) => onNoteChanged(e.target.value)} value={noteText} />
                </FormGroup>
            </>
        );
    }

    return (
        <Modal isOpen={isOpen} toggle={() => toggleModal()} size='xl'>
            <ModalHeader toggle={() => toggleModal()} close={modalNavigation}>New Note</ModalHeader>
            <ModalBody>
                {addNewNoteForm()}
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                <Row>
                    <div className='hold-new-button-container'>
                        <Button color='primary' onClick={onCreateClicked} disabled={shouldDisableCreate()}>
                            {isCreating && <Spinner></Spinner>}
                            Create Note
                        </Button>
                        <Button onClick={() => toggleModal()}>Cancel</Button>
                    </div>
                </Row>
            </ModalBody>
        </Modal>
    )
}