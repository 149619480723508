
import { Configuration } from '../configuration/Configuration';
import { Component } from '../component/Component';
import './CheckboxAccessory.css';
import {FormFeedback, Input, Label} from 'reactstrap';
import { CurrencyFormatter } from '../../../utils/CurrencyFormatter';
import {getConfigurationIsSecureClass, Utils} from "../../../utils/Utils";
import {RequiredAsterisk} from "../utils/RequiredAsterisk";
import {ProductInputValue} from "../ProductInputValue";
import {FieldModifier} from "../configuration/FieldModifier";
import {useConfigurationRules} from "../../../app/hooks";
import {RuleTypes} from "../configuration/rule/ConfigurationRule";
import {useGetSiteProductForAccessoryQuery} from "../../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {OnValueChangeParams} from "../view/ProductInputView";

interface CheckboxProps {
  configuration?: Configuration,
  component?: Component,
  productInputValue?: ProductInputValue,
  isInEditMode: boolean,
  getClassesForModifiers: (fieldModifiers?: FieldModifier[]) => string,
  containsFieldModifier: (valueKey: string, fieldModifiers?: FieldModifier[]) => boolean,
  onValueChange: (params: OnValueChangeParams) => void,
  onAccessoryChange: (accessoryConfiguration: Configuration[]) => void,
  validateValue: (value?: ProductInputValue) => void,
  checkInput: boolean,
  siteProductId: number,
  productVariantId?: number,
}

export const CheckboxAccessory = ({ configuration, productInputValue, isInEditMode, getClassesForModifiers,
                                    containsFieldModifier, onValueChange, onAccessoryChange, validateValue, checkInput,
                                    siteProductId, productVariantId }: CheckboxProps) => {
  const inputId = configuration?.id ?? -1;
  const inputLabel = (isInEditMode && configuration?.configurationDetail.actionLabel ? configuration?.configurationDetail.actionLabel : configuration?.configurationDetail.label) ?? "";
  const isRequired = configuration?.configurationDetail.isRequired;
  const rulesState = useConfigurationRules(configuration?.rules, productInputValue?.value, productVariantId,[RuleTypes.Matching, RuleTypes.Differing, RuleTypes.PositiveMatch]);

  const { data: product } = useGetSiteProductForAccessoryQuery(configuration?.configurationDetail?.accessoryProductId
      ? {accessoryProductId: configuration?.configurationDetail?.accessoryProductId, siteProductId: siteProductId}
      : skipToken);

  // Use lowest quantity option
  const lowestQuantityOption = [...product?.quantityOptions ?? []].sort(Utils.sortBy('quantity', 'desc')).find(() => true);

  function getPriceModificationText() {
    return configuration?.configurationDetail.priceModification ?
      `(+${CurrencyFormatter.format(configuration?.configurationDetail.priceModification)})` : "";
  }

  function getHelperText() {
    if (configuration?.helperText) {
        return (
            <span className="checkbox-input-helper-text" dangerouslySetInnerHTML={{__html: configuration.helperText}} />
        );
    } else {
        return <></>
    }
  }

  function onChange(isChecked: boolean) {
    if (lowestQuantityOption && isChecked) {
      onValueChange({ inputValue: lowestQuantityOption.id.toString(), quantityOptionId: lowestQuantityOption.id });
      onAccessoryChange(product?.configurations ?? []);
    }
    else {
      onValueChange({ inputValue: null });
      onAccessoryChange([]);
    }
  }

  function getEditModeTemplate() {
    return (
        <span>
          <span className="checkbox-input-edit-mode-container">
            <Input
                className={`${getInputClasses()}`}
                id={`checkbox-input-${inputId}`}
                value={productInputValue?.value ?? ""}
                defaultChecked={productInputValue?.value === "true"}
                type="checkbox"
                onChange={(e) => onChange(e.target.checked)}
                required={isRequired}
                invalid={rulesState.inputIsInvalid || (configuration?.shouldValidateValue && productInputValue?.isValidated === false)}
            ></Input>
            <FormFeedback tooltip>
              {getInvalidMessage()}
            </FormFeedback>
            {getCheckboxLabel()}
          </span>
          {getHelperText()}
        </span>
    );
  }

  function getInputClasses() {
    let classNames = getConfigurationIsSecureClass(configuration);

    return classNames;
  }

  function getInvalidMessage() {
    if (rulesState.inputIsInvalid) return rulesState.brokenRuleMessage;

    if (configuration?.shouldValidateValue && productInputValue?.isValidated === false) return 'Invalid';

    return "";
  }

  function getReadModeTemplate() {
    return (
        <>
          {getCheckboxLabel()}
          <span className={`input-checkbox-read-only-value ${getConfigurationIsSecureClass(configuration)}`}>
                {productInputValue?.value === "true" ? "Yes" : "No"}
            </span>
        </>
    );
  }

  function getValueRenderTemplate() {
    if (isInEditMode) {
      return getEditModeTemplate();
    }
    else {
      return getReadModeTemplate();
    }
  }

  function getClassesForLabel() {
    if (!isInEditMode) {
      return "half-width-text-input";
    }
    return "";
  }

  function getCheckboxLabel() {
      return (
          <Label for={`checkbox-input-${inputId}`} className={`input-checkbox-label ${getClassesForLabel()}`}>
            {inputLabel} {getPriceModificationText()}
            {isRequired && isInEditMode ? <RequiredAsterisk></RequiredAsterisk> : <></>}
          </Label>
      )
  }


  return (
      <span className={"input-checkbox-container " + getClassesForModifiers(configuration?.fieldModifiers)}>
        {getValueRenderTemplate()}
      </span>
  );
}
