import './ScrollToTopButton.css'
import {useTranslation} from "react-i18next";
import {useEffect, useRef} from "react";
import {doesTranslationValueExist, getTranslationValue} from "../../../utils/Utils";

export const ScrollToTopButton = () => {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const pagesToHideOn = ["productconfiguration"];
  
  // adds & removes scroll listen for window
  useEffect(() => {
    const onScroll = () => {
      if (!buttonRef.current) return; // nothing to do if no button available

      // Hide this if entering configuration, navigation controls already exist in this space
      const shouldHideButton = pagesToHideOn.some(p => window.location.pathname.toLowerCase().indexOf(p) !== -1);

      if ((document.body.scrollTop > 350 || document.documentElement.scrollTop > 350)
          && !shouldHideButton) {
        buttonRef.current.style.display = "block";
        buttonRef.current.style.animation = "slideIn .7s";
        buttonRef.current.style.animationFillMode = 'forwards';
      } else {
        buttonRef.current.style.animation = "slideOut .7s";
        buttonRef.current.style.animationFillMode = 'forwards';
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const scrollTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return (
    <div id='back-to-top-container'>
      <button ref={buttonRef} onClick={scrollTop} id="back-to-top-btn" className="btn-secondary" title="Go to top">
        <p>{doesTranslationValueExist(t, 'scrollToTopBtnText') ? getTranslationValue(t, 'scrollToTopBtnText') : 'Scroll To Top'}</p>
      </button>
    </div>
  )
}