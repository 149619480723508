
import './CategoryHeader.css';
import {SiteCategory} from "../Category";
import {NavLink, Container} from "reactstrap";
import {Link} from "react-router-dom";
import {DisplayImage} from "../../image/display/DisplayImage";
import { Breadcrumb } from '../../breadcrumb/Breadcrumb';

interface CategoryHeaderProps {
    siteCategory: SiteCategory,
    bubbleSiteCategories: SiteCategory[],
}

export const CategoryHeader = ({siteCategory, bubbleSiteCategories}: CategoryHeaderProps) => {

    return (
        <div className='category-header-background'>
            <Container className="category-header-container">
                <Breadcrumb currentPage={siteCategory.name} siteCategoryId={siteCategory.id}></Breadcrumb>

                <h1 className='category-view-name'>{siteCategory.name}</h1>

                <span className="category-view-child-categories">
                    {bubbleSiteCategories.map((cc) => {
                        return (
                            <NavLink className="category-view-child-category-container" key={cc.id} tag={Link} to={`/category/${cc.urlName}`}>
                                <div className="category-view-child-category-image-container">
                                    <DisplayImage imageId={cc.imageId}/>
                                </div>
                                <h4 className="category-view-child-category-name">
                                    {cc.name}
                                </h4>
                            </NavLink>
                        );
                    })}
                </span>
            </Container>
        </div>
    )
}
