import React, {useEffect, useState} from "react";
import "./ImageCarousel.css";
import {Carousel} from "react-responsive-carousel";
import {DisplayImage} from "../display/DisplayImage";
import {ImageZoom} from "../zoom/ImageZoom";
import {SiteProduct} from "../../product/Product";
import {SiteProductImage} from "../../product/SiteProductImage";
import {useAppSelector} from "../../../app/hooks";
import {selectChosenColorOption} from "../../input/ProductInputSlice";
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ImageCarouselProps {
    images?: SiteProductImage[],
    product: SiteProduct
}

export const ImageCarousel = ({images, product}: ImageCarouselProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [visibleImages, setVisibleImages] = useState<SiteProductImage[] | undefined>();
    const colorChoice = useAppSelector(selectChosenColorOption);

    useEffect(() => {
        setVisibleImages(images?.filter((image) => image.componentOptionId === null || image.componentOptionId === colorChoice));
    }, [colorChoice, images])

    function showShowThumbnails() {
        return (visibleImages && visibleImages.length > 1);
    }

    function getThumbnailImages() {
        if (!visibleImages) {
            return [<span key={1}></span>];
        }

        return visibleImages ? visibleImages?.map(i => {
            return (
                <DisplayImage imageId={i.imageId} shouldLimit={false} key={i.imageId}></DisplayImage>
            );
        }) : [<></>];
    }

    function getLargeImages() {
        if (!visibleImages) {
            return [<span key={1}></span>];
        }

        return visibleImages ? visibleImages?.map(i => {
            return (
                <ImageZoom title={product.name} imageId={i.imageId} key={i.imageId}/>
            );
        }) : [<></>];
    }

    function getCarouselControls () {
        if (!visibleImages || visibleImages.length < 2) {
            return <></>
        }

        return (
            <div id='preview-image-carousel' className="carousel-navigation-controls">
                <FontAwesomeIcon icon={faCircleArrowLeft} className="carousel-navigation-arrow-button" onClick={() => setActiveIndex(activeIndex - 1)}/>
                <FontAwesomeIcon icon={faCircleArrowRight} className="carousel-navigation-arrow-button" onClick={() => setActiveIndex(activeIndex + 1)}/>
            </div>
        )
    }

    if (!images) {
        return <></>
    }

    return (
        <span>
            <Carousel showArrows={false}
                      showIndicators={false}
                      renderThumbs={getThumbnailImages}
                      showThumbs={showShowThumbnails()}
                      selectedItem={activeIndex}
                      thumbWidth={200}
                      infiniteLoop={true}
                      onChange={setActiveIndex}>
                {getLargeImages()}
            </Carousel>

            {getCarouselControls()}
        </span>
    );
}