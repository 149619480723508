import './FinancialInstitutionDetails.css';
import {
	useGetFinancialInstitutionByAbaQuery,
	useGetFinancialInstitutionByIdQuery,
	useGetFinancialInstitutionHistoryQuery,
	useGetMicrFormatsQuery,
	useSaveFinancialInstitutionMutation
} from "../../../../app/apiSlice";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
	Alert,
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	FormFeedback,
	FormGroup,
	Input,
	Label,
	Nav,
	NavItem,
	NavLink,
	Row,
	Table
} from "reactstrap";
import {MicrBrowse} from "../../micr/browse/MicrBrowse";
import {Address, AddressInputVm} from "../../../input/address/Address";
import React, {useEffect, useState} from "react";
import {FinancialInstitutionEdit, FinancialInstitutionFieldNames} from "../FinancialInstitution";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {DateTime} from "luxon";
import {
	formatPhoneNumber,
	getErrorMessage,
	getPrettyAddress,
	isAddressEmpty,
	phoneNumberInputMaxLength,
	Regexes
} from "../../../../utils/Utils";
import {useAppDispatch, useClaims} from "../../../../app/hooks";
import {ADMIN, TEAM_LEAD} from "../../../../utils/Roles";
import {LoadingSpinner} from "../../../input/utils/LoadingSpinner";
import {ValidatedAddressInput} from "../../../input/validatedAddress/ValidatedAddressInput";
import {showConfirmationModal} from "../../../modal/ModalSlice";

export const FinancialInstitutionDetails = () => {
	const { paramFinancialInstitutionId } = useParams();
	const navigate = useNavigate();
	const { hasPermission } = useClaims();
	const dispatch = useAppDispatch();
	const [ financialInstitutionId, setFinancialInstitutionId ] = useState<number|undefined>(paramFinancialInstitutionId ? Number.parseInt(paramFinancialInstitutionId) : undefined);
	const [ financialInstitutionEdit, setFinancialInstitutionEdit] = useState<FinancialInstitutionEdit | undefined>(undefined);
	const [ errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const [ activeTab, setActiveTab] = useState('General');
	const { data: queryFinancialInstitution, isLoading } = useGetFinancialInstitutionByIdQuery(financialInstitutionId ?? skipToken);
	const { data: existingFi } = useGetFinancialInstitutionByAbaQuery(financialInstitutionEdit?.abaNumber !== queryFinancialInstitution?.abaNumber && financialInstitutionEdit?.abaNumber?.length === 9 ? financialInstitutionEdit?.abaNumber : skipToken);
	const { data: history } = useGetFinancialInstitutionHistoryQuery(financialInstitutionId ?? skipToken);
	const { data: micrFormats } = useGetMicrFormatsQuery(financialInstitutionId ?? skipToken);
	const [ saveFinancialInstitution ] = useSaveFinancialInstitutionMutation();
	const [showErrors, setShowErrors] = useState(false);

	// validations
	const validateAba = (aba?: string) => {
		if (!aba) return 'Required';
		if (!Regexes.aba.test(aba)) return 'Must be 9 digits';
	}
	const validateFractionalNum = (fr?: string) => {
		if (!fr) return 'Required';
		if (!Regexes.fractionalNumber.test(fr)) return 'Invalid Format';
	}
	const abaError = validateAba(financialInstitutionEdit?.abaNumber);
	const fullBankNameError = !financialInstitutionEdit?.fullBankName ? 'Required' : undefined;
	const nameError = !financialInstitutionEdit?.name ? 'Required' : undefined;
	const fractionalNumberError = validateFractionalNum(financialInstitutionEdit?.fractionalNumber);
	const phoneNumberError = financialInstitutionEdit?.phoneNumber && financialInstitutionEdit.phoneNumber.length !== 12 ? 'Invalid Phone Number' : undefined;
	const allValid = !abaError && !fullBankNameError && !nameError && !fractionalNumberError && !phoneNumberError;

	useEffect(() => {
		if (queryFinancialInstitution) {
			setFinancialInstitutionEdit(queryFinancialInstitution);
		}
		else if (!queryFinancialInstitution && !financialInstitutionId) {
			setFinancialInstitutionEdit({});
		}
	}, [queryFinancialInstitution, financialInstitutionId]);

	const getAffectedMicrFormatTemplateCount = () => {
		if (queryFinancialInstitution?.abaNumber && financialInstitutionEdit?.abaNumber !== queryFinancialInstitution.abaNumber) {
			return micrFormats?.filter(mf => mf.formatTemplate.includes(queryFinancialInstitution.abaNumber ?? "")).length ?? 0;
		}
		return 0;
	}

	const confirmSaveFinancialInstitution = async () => {
		if (!financialInstitutionEdit)
			return;

		try {
			const financialInstitutionId = await saveFinancialInstitution({...financialInstitutionEdit}).unwrap();
			setFinancialInstitutionId(financialInstitutionId);
			navigate(`/admin/financialInstitution/${financialInstitutionId}`);
		} catch (e: any) {
			setErrorMessage(getErrorMessage(e));
		}
	}

	const onUpdateFinancialInstitutionClick = async () => {
		setShowErrors(true);
		if (!financialInstitutionEdit
			|| !allValid
			|| ((!isAddressEmpty(financialInstitutionEdit.mailingAddress)) && !(financialInstitutionEdit.mailingAddress?.isValid ?? false))
			|| ((!isAddressEmpty(financialInstitutionEdit.headquartersAddress)) && !(financialInstitutionEdit.headquartersAddress?.isValid ?? false))) {
			return;
		}
		const {diffList, content} = buildFinancialInstitutionDiffList();
		const size = Math.max(...(diffList.map(fidl => fidl.length))) > 30 ? 'xl' : 'm';

		dispatch(showConfirmationModal({
			title: 'Save Financial Institution Changes?',
			content,
			affirmText: 'Yes, Save',
			size,
			onConfirm: confirmSaveFinancialInstitution,
		}));
	}

	const buildFinancialInstitutionDiffList = () => {
		const diffList: string[] = [];

		if (queryFinancialInstitution?.abaNumber !== financialInstitutionEdit?.abaNumber) {
			diffList.push(
				`ABA was updated from ${queryFinancialInstitution?.abaNumber} to ${financialInstitutionEdit?.abaNumber}`
			);
		}
		if (queryFinancialInstitution?.fullBankName !== financialInstitutionEdit?.fullBankName) {
			diffList.push(
				`Full Bank Name was updated from ${queryFinancialInstitution?.fullBankName} to ${financialInstitutionEdit?.fullBankName}`
			);
		}
		if (queryFinancialInstitution?.name !== financialInstitutionEdit?.name) {
			diffList.push(
				`Name was updated from ${queryFinancialInstitution?.name} to ${financialInstitutionEdit?.name}`
			);
		}
		if (queryFinancialInstitution?.phoneNumber !== financialInstitutionEdit?.phoneNumber) {
			diffList.push(
				`Phone Number was updated from ${queryFinancialInstitution?.phoneNumber ?? "No value"} to ${financialInstitutionEdit?.phoneNumber?.toString() ?? "No value"}`
			);
		}
		if (queryFinancialInstitution?.fractionalNumber !== financialInstitutionEdit?.fractionalNumber) {
			diffList.push(
				`Fractional Number was updated from ${queryFinancialInstitution?.fractionalNumber ?? "No value"} to ${financialInstitutionEdit?.fractionalNumber ?? "No value"}`
			);
		}
		if (getPrettyAddress(queryFinancialInstitution?.headquartersAddress) !== getPrettyAddress(financialInstitutionEdit?.headquartersAddress)) {
			diffList.push(
				`Headquarters Address was updated from ${getPrettyAddress(queryFinancialInstitution?.headquartersAddress) ?? "No value"} 
                to ${getPrettyAddress(financialInstitutionEdit?.headquartersAddress) ?? "No value"}`
			);
		}
		if (getPrettyAddress(queryFinancialInstitution?.mailingAddress) !== getPrettyAddress(financialInstitutionEdit?.mailingAddress)) {
			diffList.push(
				`Mailing Address was updated from ${getPrettyAddress(queryFinancialInstitution?.mailingAddress) ?? "No value"} 
                to ${getPrettyAddress(financialInstitutionEdit?.mailingAddress) ?? "No value"}`
			);
		}
		if (getAffectedMicrFormatTemplateCount() > 0) {
			diffList.push(
				`There are ${getAffectedMicrFormatTemplateCount()} micr formats that will be updated to reflect these changes.`
			);
		}

		const content = <>
			<p>Are you sure you want to update the Financial Institution?</p>
			<ul className="confirmation-modal-list-points-container">
				{ diffList.map(dl =>
					<li key={dl.replace(' ', '-')}>{dl}</li>
				)}
			</ul>
		</>

		return { diffList, content };
	}

	const onFinancialInstitutionValueChange = (financialInstitutionFieldName: FinancialInstitutionFieldNames, value?: string) => {
		if (!financialInstitutionEdit) {
			return;
		}
		switch (financialInstitutionFieldName) {
			case FinancialInstitutionFieldNames.FullBankName:
				setFinancialInstitutionEdit({...financialInstitutionEdit, fullBankName: value ?? ""})
				break;
			case FinancialInstitutionFieldNames.Name:
				setFinancialInstitutionEdit({...financialInstitutionEdit, name: value ?? ""})
				break;
			case FinancialInstitutionFieldNames.LegacyName:
				setFinancialInstitutionEdit({...financialInstitutionEdit, legacyName: value})
				break;
			case FinancialInstitutionFieldNames.AbaNumber:
				setFinancialInstitutionEdit({...financialInstitutionEdit, abaNumber: value})
				break;
			case FinancialInstitutionFieldNames.PhoneNumber:
				const phoneNumberValue = formatPhoneNumber(value);
				setFinancialInstitutionEdit({...financialInstitutionEdit, phoneNumber: phoneNumberValue});
				break;
			case FinancialInstitutionFieldNames.FractionalNumber:
				setFinancialInstitutionEdit({...financialInstitutionEdit, fractionalNumber: value});
				break;
		}
	}

	const onAddressChanged = (financialInstitutionFieldName: FinancialInstitutionFieldNames, value?: AddressInputVm) => {
		if (!financialInstitutionEdit) {
			return;
		}
		// Clear out empty address objects which may still contain an id
		if (isAddressEmpty(value)) {
			value = undefined;
		}

		switch (financialInstitutionFieldName) {
			case FinancialInstitutionFieldNames.HeadquartersAddress:
				setFinancialInstitutionEdit(old => ({...old, headquartersAddress: value}));
				break;
			case FinancialInstitutionFieldNames.MailingAddress:
				setFinancialInstitutionEdit(old => ({...old, mailingAddress: value}));
				break;
		}
	}

	const areChangesPending = () => {
		return (
			!queryFinancialInstitution || (
				queryFinancialInstitution.abaNumber !== financialInstitutionEdit?.abaNumber ||
				queryFinancialInstitution.fullBankName !== financialInstitutionEdit?.fullBankName ||
				queryFinancialInstitution.name !== financialInstitutionEdit?.name ||
				queryFinancialInstitution.legacyName !== financialInstitutionEdit?.legacyName ||
				queryFinancialInstitution.phoneNumber !== financialInstitutionEdit?.phoneNumber ||
				queryFinancialInstitution.fractionalNumber !== financialInstitutionEdit?.fractionalNumber ||
				getPrettyAddress(queryFinancialInstitution.headquartersAddress) !== getPrettyAddress(financialInstitutionEdit?.headquartersAddress) ||
				getPrettyAddress(queryFinancialInstitution.mailingAddress) !== getPrettyAddress(financialInstitutionEdit?.mailingAddress)
			)
		);
	}

	const addressIsRequired = (addr?: Address) =>
		!!(addr?.street || addr?.city || addr?.stateCode || addr?.zip);

	if (isLoading) {
		return <LoadingSpinner size="sm" />
	}

	if (!financialInstitutionEdit) {
		return (<>No result found</>);
	}

	return (
		<Container>

			<Nav tabs>
				<NavItem>
					<NavLink active={activeTab === 'General'} onClick={() => setActiveTab('General')}>General</NavLink>
				</NavItem>
				<NavItem>
					<NavLink active={activeTab === 'History'}
					         onClick={() => setActiveTab('History')}>History</NavLink>
				</NavItem>
			</Nav>

			{
				activeTab === 'General' &&
                <>
                    <div className='save-btn d-flex justify-content-end mt-3'>
						{ hasPermission([ADMIN, TEAM_LEAD]) &&
                            <Button color="success"
                                    disabled={!areChangesPending() || existingFi?.name !== undefined}
                                    onClick={onUpdateFinancialInstitutionClick}
                            >Update</Button>
						}
                    </div>

                    <Card className="financial-institution-details-card">
                        <CardHeader className='d-flex justify-content-between card-header'>
                            <h5 className="m-0">Institution Details</h5>
							{errorMessage && <Alert color='danger'>{errorMessage}</Alert>}
							{existingFi && <Alert color='warning'>The financial institution <Link to={`/admin/financialInstitution/${existingFi.id}`}>{existingFi.name}</Link> already has this ABA.</Alert>}
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs="8">
                                    <FormGroup className="financial-institution-details-input-container">
                                        <Label for='FinancialInstitutionABA' className="financial-institution-details-input-label-left">ABA</Label>
                                        <Input id='FinancialInstitutionABA'
                                               name='aba'
                                               invalid={showErrors && !!abaError}
                                               value={financialInstitutionEdit?.abaNumber ?? ''}
                                               onChange={(e) => onFinancialInstitutionValueChange(FinancialInstitutionFieldNames.AbaNumber, e.target.value)}/>
                                        <FormFeedback tooltip className="financial-institution-details-form-feedback">{abaError}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs="4">
                                    <FormGroup className="financial-institution-details-input-container">
                                        <Label for='FinancialInstitutionCreatedBy' className="financial-institution-details-input-label-right">Created By</Label>
                                        <div>{financialInstitutionEdit?.createdByName}</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="8">
                                    <FormGroup className="financial-institution-details-input-container">
                                        <Label for='FinancialInstitutionBankName' className="financial-institution-details-input-label-left">Bank Name</Label>
                                        <Input id='FinancialInstitutionBankName'
                                               name='name'
                                               invalid={showErrors && !!nameError}
                                               value={financialInstitutionEdit?.name ?? ''}
                                               onChange={(e) => onFinancialInstitutionValueChange(FinancialInstitutionFieldNames.Name, e.target.value)}/>
                                        <FormFeedback tooltip className="financial-institution-details-form-feedback">{nameError}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs="4">
                                    <FormGroup className="financial-institution-details-input-container">
                                        <Label for='FinancialInstitutionCreatedDate' className="financial-institution-details-input-label-right">Created Date</Label>
                                        <div>{financialInstitutionEdit?.timeCreated
											? DateTime.fromISO(financialInstitutionEdit.timeCreated + 'Z').toLocaleString(DateTime.DATETIME_SHORT)
											: ''}</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="8">
                                    <FormGroup className="financial-institution-details-input-container">
                                        <Label for='FinancialInstitutionBankName' className="financial-institution-details-input-label-left">Full Bank Name</Label>
                                        <Input id='FinancialInstitutionBankName'
                                               name='name'
                                               invalid={showErrors && !!fullBankNameError}
                                               value={financialInstitutionEdit?.fullBankName ?? ''}
                                               onChange={(e) => onFinancialInstitutionValueChange(FinancialInstitutionFieldNames.FullBankName, e.target.value)}/>
                                        <FormFeedback tooltip className="financial-institution-details-form-feedback">{fullBankNameError}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col xs="4">
                                    <FormGroup className="financial-institution-details-input-container">
                                        <Label for='FinancialInstitutionUpdatedBy' className="financial-institution-details-input-label-right">Last Updated By</Label>
                                        <div>{financialInstitutionEdit?.updatedByName}</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="8">
                                    <FormGroup className="financial-institution-details-input-container">
                                        <Label for='FinancialInstitutionLegacyBankName' className="financial-institution-details-input-label-left">Legacy Bank Name</Label>
                                        <Input id='FinancialInstitutionLegacyBankName'
                                               name='name'
                                               value={financialInstitutionEdit?.legacyName ?? ''}
                                               onChange={(e) => onFinancialInstitutionValueChange(FinancialInstitutionFieldNames.LegacyName, e.target.value)}/>
                                    </FormGroup>
                                </Col>
                                <Col xs="4">
                                    <FormGroup className="financial-institution-details-input-container">
                                        <Label for='FinancialInstitutionUpdatedAt' className="financial-institution-details-input-label-right">Updated At</Label>
                                        <div>{financialInstitutionEdit?.timeLastUpdated
											? DateTime.fromISO(financialInstitutionEdit.timeLastUpdated + 'Z').toLocaleString(DateTime.DATETIME_SHORT)
											: ''}</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="8">
                                    <FormGroup className="financial-institution-details-input-container">
                                        <Label for='FinancialInstitutionPhoneNumber' className="financial-institution-details-input-label-left">Phone Number</Label>
                                        <Input id='FinancialInstitutionPhoneNumber'
                                               name='phoneNumber'
                                               value={financialInstitutionEdit?.phoneNumber ?? ''}
                                               maxLength={phoneNumberInputMaxLength}
                                               invalid={showErrors && !!phoneNumberError}
                                               onChange={(e) => onFinancialInstitutionValueChange(FinancialInstitutionFieldNames.PhoneNumber, e.target.value)}/>
                                        <FormFeedback tooltip className="financial-institution-details-form-feedback">{phoneNumberError}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="8">
                                    <FormGroup className="financial-institution-details-input-container">
                                        <Label for='FinancialInstitutionFractionalNumber' className="financial-institution-details-input-label-left">Fractional Number</Label>
                                        <Input id='FinancialInstitutionFractionalNumber'
                                               name='fractionalNumber'
                                               value={financialInstitutionEdit?.fractionalNumber ?? ''}
                                               invalid={showErrors && !!fractionalNumberError}
                                               onChange={(e) => onFinancialInstitutionValueChange(FinancialInstitutionFieldNames.FractionalNumber, e.target.value)}/>
                                        <FormFeedback className="financial-institution-details-form-feedback" tooltip>
											{fractionalNumberError}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                    <Card className="financial-institution-details-card">
                        <CardHeader className='d-flex justify-content-between card-header'>
                            <h5 className="m-0">Headquarters</h5>
                        </CardHeader>
                        <CardBody>
                            <ValidatedAddressInput
                                value={financialInstitutionEdit?.headquartersAddress ?? {}}
                                suppressError={!showErrors}
                                required={addressIsRequired(financialInstitutionEdit?.headquartersAddress)}
                                emitInvalid
                                disableBrowserAutoComplete
                                onChange={address => onAddressChanged(FinancialInstitutionFieldNames.HeadquartersAddress, address)}
                            />
                        </CardBody>
                    </Card>

                    <Card className="financial-institution-details-card">
                        <CardHeader className='d-flex justify-content-between card-header'>
                            <h5 className="m-0">Mailing</h5>
                        </CardHeader>
                        <CardBody>
                            <ValidatedAddressInput
                                value={financialInstitutionEdit?.mailingAddress ?? {}}
                                suppressError={!showErrors}
                                required={addressIsRequired(financialInstitutionEdit?.mailingAddress)}
                                emitInvalid
                                disableBrowserAutoComplete
                                onChange={address => onAddressChanged(FinancialInstitutionFieldNames.MailingAddress, address)}
                            />
                        </CardBody>
                    </Card>

                    <div className="financial-details-micr-browse-container">
                        <MicrBrowse financialInstitutionId={financialInstitutionEdit?.id}></MicrBrowse>
                    </div>
                </>
			}

			{
				activeTab === 'History' &&
                <Table>
                    <thead>
                    <tr>
                        <th>User</th>
                        <th>Event Date</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
					{ history?.map(record => (
						<tr key={record.id}>
							<td>{record.changeBy}</td>
							<td>{DateTime.fromISO(record.timeOfChangeLocal).toLocaleString(DateTime.DATETIME_SHORT)}</td>
							<td className="financial-institution-details-history-description">{record.changeDescription}</td>
						</tr>
					))}
                    </tbody>
                </Table>
			}
			
		</Container>
	)
}
