import { PagingInfo } from "../../../utils/Grid";
import { acquireAccessToken } from "../../../index";
import axios from "axios";

export interface SearchAndPaging {
  search?: string,
  paging?: PagingInfo,
}

export async function fetchAll(query: SearchAndPaging) {
  const token = await acquireAccessToken();
  return await axios.get('/api/FinancialInstitutions', {
    headers: {
      authorization: `Bearer ${token}`,
    },
    params: { search: query.search, ...query.paging },
  });
}