import {FieldValues, useFormContext} from "react-hook-form";
import {Button, Label, Row} from "reactstrap";
import React, {ReactElement, useEffect, useState} from "react";
import styles from '../components/input/validatedAddress/ValidatedAddressInput.module.css';
import {at} from "lodash";
import {ConsumerAddressInput} from "./ConsumerAddressInput";
import {FormInputField, FormInputProps} from "../components/forms/FormInputField";
import {AddressInputVm} from "../components/input/address/Address";
import {useAppSelector, useSmartyStreets} from "../app/hooks";
import {selectCurrentConsumerUser} from "../components/user/login/AuthenticationSlice";
import {AddressSchema, isSavedAddress} from "./AddressSchema";
import {useGetAddressesByConsumerIdQuery} from "../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {FormStateDropdown} from "./FormStateDropdown";
import AddressLabel from "./AddressLabel";
import cn from "classnames";
import {curry} from "lodash/fp";

type OnChange = (field: string, value: string) => string | Promise<string>;

interface FormAddressInputProps<Schema extends FieldValues>
    extends Omit<FormInputProps<Schema>, 'type' | 'placeholder' | 'parseValue' | 'onChange'> {

    readonly section?: 'billing' | 'shipping' | string;
    readonly before?: ReactElement;
    readonly after?: ReactElement;
    readonly onVerificationComplete?: (verified: boolean) => void | Promise<void>;
    readonly onChange?: OnChange;
    readonly streetLabel?: string;
    readonly cityLabel?: string;
    readonly stateLabel?: string;
    readonly zipLabel?: string;
}

export function FormAddressInput<Schema extends FieldValues>(
    {
        name,
        label,
        before,
        after,
        readOnly,
        section = '',
        onChange = (_field, value) => value,
        onVerificationComplete = () => void 0,
        requiredAsterisk = false,
        zipLabel = 'Zip',
        stateLabel = 'State',
        cityLabel = 'City',
        streetLabel = 'Street',
    }: FormAddressInputProps<Schema>) {

    const [showAddressFields, setShowAddressFields] = useState(true);
    const [searchAddress, setSearchAddress] = useState<AddressInputVm>({});
    const [verifyAddress, setVerifyAddress] = useState<AddressInputVm>({});
    const [showSuggestedAddresses, setShowSuggestedAddresses] = useState(false);

    const consumer = useAppSelector(selectCurrentConsumerUser);
    const {data: consumerAddresses = []} = useGetAddressesByConsumerIdQuery(consumer?.id ?? skipToken)

    const {matches: suggestedAddresses} = useSmartyStreets(searchAddress);
    const {isVerified} = useSmartyStreets(verifyAddress);

    const {
        watch,
        setValue: setFormValue,
    } = useFormContext();

    useEffect(() => {
        const subscription = watch((form, {name: changedFieldName}) => {
            // If the changed field is "street" and the change was made via user input (type === "change")
            const [value] = at(form, name) as [AddressSchema];

            if (!changedFieldName?.startsWith(name)) return;

            if (isSavedAddress(value)) {
                return;
            }

            if (changedFieldName === `${name}.street`) {
                setSearchAddress({
                    street: value?.street
                });
                setVerifyAddress({
                    ...verifyAddress,
                    street: value?.street
                });
            }

            // If the changed field is "city" and the change was made via user input (type === "change")
            if (changedFieldName === `${name}.city`) {
                setSearchAddress({});
                setVerifyAddress({
                    ...verifyAddress,
                    city: value?.city
                });
            }

            // If the changed field is "state" and the change was made via user input (type === "change")
            if (changedFieldName === `${name}.stateCode`) {
                setSearchAddress({});
                setVerifyAddress({
                    ...verifyAddress,
                    stateCode: value?.stateCode
                });
            }

            // If the changed field is "zip" and the change was made via user input (type === "change")
            if (changedFieldName === `${name}.zip`) {
                setSearchAddress({});
                setVerifyAddress({
                    ...verifyAddress,
                    zip: value?.zip
                });
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, verifyAddress]);

    useEffect(() => {
        onVerificationComplete(isVerified);
    }, [isVerified]);

    const setValue: typeof setFormValue = (childName, value, options) =>
        setFormValue(`${name}.${childName}`, value, options);

    const savedAddressId = watch(`${name}.id`);

    useEffect(() => {
        if (savedAddressId !== undefined && savedAddressId != -1) {
            setShowAddressFields(!consumer)
            setAddressFields({
                street: '',
                city: '',
                stateCode: '',
                zip: ''
            }, false);
            const savedAddress = consumerAddresses
                .find(a => a?.id == savedAddressId);
            if (savedAddress) {
                setAddressFields(savedAddress);
            }
        } else {
            setShowAddressFields(true);
        }
    }, [savedAddressId, consumer]);

    const value = watch(name);

    // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
    const getAutoComplete = (value: string) =>
        (section !== 'shipping' && section !== 'billing')
            ? `section-${section} ${value}`
            : `${section} ${value}`;

    const setAddressFields = (addr: AddressInputVm, shouldMess = true) => {
        const setValueOptions = {
            shouldValidate: shouldMess,
            shouldTouch: shouldMess,
            shouldDirty: shouldMess,
        };
        // @ts-ignore
        setValue(`street`, addr.street, setValueOptions);
        // @ts-ignore
        setValue(`city`, addr.city, setValueOptions);
        // @ts-ignore
        setValue(`stateCode`, addr.stateCode, setValueOptions);
        // @ts-ignore
        setValue(`zip`, addr.zip, setValueOptions);

        setSearchAddress({});

        setVerifyAddress({
            street: addr.street,
            city: addr.city,
            stateCode: addr.stateCode,
            zip: addr.zip,
        });
    }

    const suggestedAddressRow = (addr: AddressInputVm, ix: number) => {
        return (
            <Button type='button' color='link' key={ix} className={styles.suggestedAddress}
                    onClick={() => setAddressFields(addr)}>
                {addr.street}, {addr.city}, {addr.stateCode}, {addr.zip}
            </Button>
        );
    }

    // if the fields are removed from the dom then 
    // rhf will not pick them up even if the value is set
    // use css to hide them to prevent that
    const addressFieldsClass = cn({
        'd-none': readOnly
    });

    return <div>
        <div className="row">
            <Label className="bolded-text">{label}</Label>
        </div>

        {before ??
            <div className="row">
                {before}
            </div>}

        {consumer && !readOnly && <div className="row">
            <ConsumerAddressInput name={`${name}.id`}
                                  label="Saved Addresses"
                                  section={section}
                                  onChange={e => {
                                      if (e === '-1') {
                                          setAddressFields({}, false);
                                      }
                                      handleOnChange('id', onChange, e);
                                  }}
            />
        </div>}

        {showAddressFields &&
            <div className={`row ${addressFieldsClass}`}>
                <FormInputField name={`${name}.street`}
                                label={streetLabel}
                                valid={isVerified}
                                disabled={readOnly}
                                requiredAsterisk={requiredAsterisk}
                                onFocus={() => setShowSuggestedAddresses(true)}
                                onBlur={() => {
                                    setTimeout(function () {
                                        setShowSuggestedAddresses(false);
                                    }, 250);
                                }}
                                onChange={handleOnChange('street', onChange)}
                                autoComplete={getAutoComplete('address-line1')}
                />
                {suggestedAddresses.length > 0 && showSuggestedAddresses &&
                    <div className={styles.suggestedAddresses}>
                        {suggestedAddresses.map(suggestedAddressRow)}
                    </div>
                }
            </div>
        }

        {showAddressFields && <>
            <div className={`row ${addressFieldsClass}`}>
                <div className="col-md">
                    <FormInputField name={`${name}.city`}
                                    label={cityLabel}
                                    valid={isVerified}
                                    disabled={readOnly}
                                    autoComplete={getAutoComplete('address-level2')}
                                    requiredAsterisk={requiredAsterisk}
                                    onChange={handleOnChange('city', onChange)}
                    />
                </div>
                <div className="col-md">
                    <FormStateDropdown name={`${name}.stateCode`}
                                       label={stateLabel}
                                       valid={isVerified}
                                       disabled={readOnly}
                                       requiredAsterisk={requiredAsterisk}
                                       autoComplete={getAutoComplete('address-level1')}
                                       onChange={handleOnChange('stateCode', onChange)}
                    />
                </div>
                <div className="col-md">
                    <FormInputField name={`${name}.zip`}
                                    label={zipLabel}
                                    type="numeric"
                                    valid={isVerified}
                                    autoComplete={getAutoComplete('postal-code')}
                                    requiredAsterisk={requiredAsterisk}
                                    disabled={readOnly}
                                    onChange={handleOnChange('zip', onChange)}
                    />
                </div>
            </div>
        </>}

        {readOnly &&
            <AddressLabel address={value}/>}

        {after && <Row>
            {after}
        </Row>}
    </div>;
}

const handleOnChange = curry(async (prop: string, onChange: OnChange, value: string) => {
    await onChange(prop, value);
})