import { OrderAchReject } from "./OrderAchReject";
import { ColumnConfig } from "../../admin/shared/Grid";
import {
  reportCol,
  reportDateToString,
  reportMoneyToString,
  reportValToConsumerLink,
  reportValToOrderLink,
  ReportView
} from "../ReportView";
import { useGetOrderAchRejectReportQuery } from "../../../app/apiSlice";

export const OrderAchRejectReport = () => {
  const filterFunc = (lowerSearch: string, records: OrderAchReject[]) =>
    records.filter(r =>
      r.rejectDate.includes(lowerSearch) ||
      r.entryDate.includes(lowerSearch) ||
      r.customerId.toString().includes(lowerSearch) ||
      r.brand.toLowerCase().includes(lowerSearch) ||
      r.aba.includes(lowerSearch) ||
      r.accountNumber.includes(lowerSearch) ||
      r.rejectReason.toLowerCase().includes(lowerSearch) ||
      r.orderNumber.toString().includes(lowerSearch) ||
      r.orderDate.includes(lowerSearch) ||
      `${r.firstNameBilling.toLowerCase()} ${r.lastNameBilling.toLowerCase()}`.includes(lowerSearch)
    );
  
  const columns: ColumnConfig<OrderAchReject>[] = [
    reportCol('rejectDate', 'Reject Date', reportDateToString),
    reportCol('entryDate', 'Entry Date', reportDateToString),
    reportCol('customerId', 'Cust. #', reportValToConsumerLink),
    reportCol('brand', 'Brand'),
    reportCol('aba', 'ABA'),
    reportCol('accountNumber', 'Account #'),
    reportCol('orderNetTotal', 'Order Net', reportMoneyToString),
    reportCol('rejectReason', 'Reject Reason'),
    reportCol('orderNumber', 'Order #', reportValToOrderLink),
    reportCol('orderDate', 'Order Date', reportDateToString),
    reportCol('firstNameBilling', 'Billing F Name'),
    reportCol('lastNameBilling', 'Billing L Name'),
  ];
  
  return (
    <ReportView
      title='Order ACH Rejections'
      query={useGetOrderAchRejectReportQuery}
      columns={columns}
      initialSort={{ column: 'rejectDate', direction: 'asc'}}
      filterFunc={filterFunc}
      downloadPath='/api/Reports/OrderAchReject'
      downloadName='CW_Order_ACH Reject'/>
  )
}