import './UserOrders.css';
import {Button, Card, CardBody, CardFooter, CardHeader, Spinner} from "reactstrap";
import { useCreateOrderEditCartMutation, useGetCurrentUsersOrdersQuery } from "../../../app/apiSlice";
import React, {useEffect, useState} from "react";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {OrderProductPreview} from "../../order/productPreview/OrderProductPreview";
import {OrderProductVm} from "../../order/OrderProductVm";
import {OrderVm} from "../../order/Order";
import { useAppDispatch } from "../../../app/hooks";
import { setCartId } from "../../cart/CartSlice";
import {useNavigate, useParams} from "react-router-dom";
import {OrderDetails} from "../../order/orderDetails/OrderDetails";
import {formatDate} from "../../../utils/Utils";
import {UserProfileRouteModel} from "../profile/UserProfileRouteModel";
import {ProfileTab} from "../profile/ProfileTab";
import {useTranslation} from "react-i18next";
import {showInformationModal} from "../../modal/ModalSlice";

export const UserOrders = () => {
    const { t } = useTranslation();
    const { data: orders, isLoading: isLoadingOrders } = useGetCurrentUsersOrdersQuery();
    const [ createOrderCart, {isLoading: isCreatingOrderCart} ] = useCreateOrderEditCartMutation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [ selectedOrder, setSelectedOrder ] = useState<OrderVm>();
    
    const { profileTabRoute: paramSelectedOrderId } = useParams() as UserProfileRouteModel;
    
    useEffect(() => {
        if (!Array.isArray(orders)) 
            return;
        
        let orderToSet: OrderVm | undefined = undefined;
        if (paramSelectedOrderId && /^[0-9]+$/g.test(paramSelectedOrderId)) {
            const parsedId = parseInt(paramSelectedOrderId);
            if (selectedOrder?.id == parsedId) 
                return;
            
            orderToSet = orders.find(o => o.id === parsedId);
        }
        
        if (selectedOrder?.id == orderToSet?.id)
            return;
        
        setSelectedOrder(orderToSet);
        
    }, [orders, paramSelectedOrderId]);

    const beginReorder = async (orderId: number) => {
        try {
            const cartId = await createOrderCart({orderId, isReorder: true}).unwrap();
            dispatch(setCartId(cartId));
            navigate('/cart');
        } catch (e:any) {
            dispatch(showInformationModal({
                title: 'Could not start reorder',
                text: 'Sorry! We were unable to start this reorder at this time.'
            }));
        }
    }
    
    function setSelectedOrderPath(order?: OrderVm) {
        navigate(`/profile/${ProfileTab.OrderHistory}${order ? ('/' + order.id) : ''}`);
    }

    const shouldDisableReorder = (orderProducts?: OrderProductVm[]) => {
        return !orderProducts?.some(op => !op.doNotReorder) || isCreatingOrderCart;
    }

    const getOrderDetailsView = (o: OrderVm) => {
        return (
            <Card className="user-orders-card" key={o.id}>
                <CardHeader className="user-orders-card-header card-header">
                    <div className='d-flex justify-content-between w-100'>
                        <div className='d-flex flex-column'>
                            <span>{formatDate(o.timeCreated)}</span>
                            <span className="user-orders-muted-text">
                                    Order #:{o.id}
                            </span>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <span>{CurrencyFormatter.format(o.totalCharged)}</span>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>

                    {o.products?.map((op: OrderProductVm, idx: number) => {
                        return (
                            <div key={op.id}>
                                {idx > 0 ? <hr></hr> : <></>}
                                <OrderProductPreview key={op.id} orderProduct={op}></OrderProductPreview>
                            </div>
                        )
                    })}

                </CardBody>
                <CardFooter>
                    <span className='d-flex justify-content-between'>
                        <Button color="primary" onClick={() => setSelectedOrderPath(o)}>
                            View Details
                        </Button>

                        <Button color="primary" disabled={shouldDisableReorder(o.products)} onClick={() => beginReorder(o.id)}>
                            {isCreatingOrderCart && <Spinner/>}
                            Reorder
                        </Button>
                    </span>
                </CardFooter>
            </Card>
        );
    }

    return (
        <div className="user-orders-container">
            <h3>Order History</h3>

            {!selectedOrder && !isLoadingOrders && (!orders || orders.length === 0) &&
                <div className="user-orders-no-orders-text">
                    {t('profile.noOrdersText')}
                </div>
            }

            {selectedOrder ?
                <OrderDetails selectedOrder={selectedOrder}></OrderDetails>
                : orders?.map((o) => {
                return (
                    getOrderDetailsView(o)
                );
            })}
        </div>
    );

}