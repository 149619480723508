import {MicrDetails} from "../../micr/details/MicrDetails";
import {useGetMicrFormatIdsByOrderIdQuery, useGetFinancialInstitutionIdByOrderIdQuery} from "../../../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import './MicrTab.css';
import React, {useState} from "react";
import {Button, Spinner} from "reactstrap";
import {OrderProductVm} from "../../../order/OrderProductVm";

interface MicrTabProps {
    orderId?: number,
    orderProducts?: OrderProductVm[]
}

export const MicrTab = ({orderId, orderProducts}: MicrTabProps) => {

    const { data: financialInstitutionId, isLoading: isFinancialInstitutionLoading } = useGetFinancialInstitutionIdByOrderIdQuery(orderId ?? skipToken);
    const { data: micrFormatOrderProductIds, isLoading: isMicrFormatIdLoading } = useGetMicrFormatIdsByOrderIdQuery(orderId ?? skipToken);
    const [editModeOrderProductIds, setEditModeOrderProductIds] = useState<number[]>([]);
    const [newMicrOrderProductIds, setNewMicrOrderProductIds] = useState<number[]>([]);

    const onEditModeToggle = (orderProductId: number) => {
        const currentOrderProductIdIndex = editModeOrderProductIds.indexOf(orderProductId);
        if (currentOrderProductIdIndex > -1) {
            setEditModeOrderProductIds(editModeOrderProductIds.slice(currentOrderProductIdIndex, currentOrderProductIdIndex ))
        }
        else {
            setEditModeOrderProductIds(editModeOrderProductIds.concat([orderProductId]));
        }
    }

    const isMicrReadOnly = (orderProductId: number) => {
        return editModeOrderProductIds.indexOf(orderProductId) < 0;
    }

    const addNewMicrProduct = (orderProductId: number) => {
        setNewMicrOrderProductIds(newMicrOrderProductIds.concat([orderProductId]));
    }

    const getMicrDetailsView = () => {
        return (orderProducts ?? [])?.map((op) => {
            const isNewMicr = newMicrOrderProductIds.some(nmop => nmop === op.id);
            const micrFormatInfo = micrFormatOrderProductIds?.find(mfop => mfop.orderProductId === op.id);
            if (micrFormatInfo?.isMicrProduct) {
                return (micrFormatInfo.micrFormatId || isNewMicr) ?
                    (
                        <MicrDetails isReadOnly={isMicrReadOnly(op.id)}
                                             orderId={orderId}
                                             orderProduct={op}
                                             micrFormatId={micrFormatInfo.micrFormatId}
                                             financialInstitutionId={financialInstitutionId}
                                             onEditModeToggle={onEditModeToggle}
                                             key={op.id}
                        ></MicrDetails>
                    ) :
                    (
                        <div className="micr-tab-no-micr-match-container m-2" key={op.id}>
                            <div className="d-flex">
                                <Button color='primary'  onClick={() => addNewMicrProduct(op.id)}>Create New Micr</Button>
                                <div className="d-flex align-items-center m-2">{`${op.siteProductVariantName} (${op.sku}) has no matching Micr Format.`}</div>
                            </div>
                        </div>
                    )
            }
        });
    }

    const containsMicrProduct = () => {
        return micrFormatOrderProductIds?.some(mfop => mfop.isMicrProduct);
    }

    const getNoMicrProductsView = () => {
        return (
            <div className="micr-tab-no-micr-match-container">
                There are no check products in this order.
            </div>
        )
    }

    const getLoadingSpinner = () => {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner size="sm">
                    Loading...
                </Spinner>
            </div>
        );
    }

    if (isFinancialInstitutionLoading || isMicrFormatIdLoading || containsMicrProduct() === undefined) {
        return getLoadingSpinner();
    }

    return (
        <span className="micr-tab-container">
            {(containsMicrProduct()) ? getMicrDetailsView() : getNoMicrProductsView()}
        </span>
    )
}
