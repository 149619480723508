import { PagingInfo } from "../../../utils/Grid";
import { acquireAccessToken } from "../../../index";
import axios from "axios";
import {ConsumerFetchSearchCriteria} from "./ConsumerSearchCriteria";

export interface CriteriaAndPaging {
    criteria?: ConsumerFetchSearchCriteria,
    paging?: PagingInfo,
}

export async function fetchAll(query: CriteriaAndPaging) {
    const token = await acquireAccessToken();
    return await axios.get('/api/Consumers', {
        headers: {
            authorization: `Bearer ${token}`,
        },
        params: { ...query.criteria, ...query.paging },
    });
}
