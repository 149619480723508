
import './FinancialInstitutionBrowse.css';
import {Container} from "reactstrap";
import {Link} from "react-router-dom";
import { PagingInfo, SortState } from "../../../../utils/Grid";
import {FinancialInstitution} from "../FinancialInstitution";
import {useEffect, useState} from "react";
import {GridPage} from "../../shared/GridPage";
import {ColumnConfig, GridConfig} from "../../shared/Grid";
import {DateTime} from "luxon";
import { useAppDispatch, useAppSelector, useClaims } from "../../../../app/hooks";
import { ADMIN, TEAM_LEAD } from "../../../../utils/Roles";
import {
    canFetchMoreFinancialInstitutions, changeSearch, fetchFinancialInstitutions,
    fetchMoreFinancialInstitutions, isFetchingFinancialInstitutions,
    selectFinancialInstitutions
} from "../FinancialInstitutionSlice";

export const FinancialInstitutionBrowse = () => {
    const { hasPermission } = useClaims();
    const financialInstitutions = useAppSelector(selectFinancialInstitutions);
    const moreAvailable = useAppSelector(canFetchMoreFinancialInstitutions);
    const isLoading = useAppSelector(isFetchingFinancialInstitutions);
    const dispatch = useAppDispatch();
    const fetchStatus = useAppSelector(state => state.financialInstitutions.status);
    
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState<SortState<FinancialInstitution>>({ column: 'name', serverColumn: 'fi.Name', direction: 'asc' });
    
    // initial pull of data and on sort/search change
    useEffect(() => {
        dispatch(fetchFinancialInstitutions({ search, paging: { sortKey: sort.serverColumn!, sortDirection: sort.direction }}));
    }, [sort, search]);
    
    // on search change, reset the slice
    useEffect(() => {
        dispatch(changeSearch());
    }, [search, dispatch]);

    const fetchMore = (paging: PagingInfo) => {
        if (fetchStatus !== 'succeeded') return; // don't try to grab again if we're already loading
        dispatch(fetchMoreFinancialInstitutions({search, paging}));
    }

    const config: GridConfig<FinancialInstitution> = {
        configSections: [
            {
                columns: [
                    new ColumnConfig({
                        field: 'id',
                        serverColumn: 'fi.Id',
                        display: 'Action',
                        cellDisplay: val => val ? <Link className='btn btn-primary mx-1 med-max-width' to={`/admin/financialInstitution/${val}`}>Edit</Link> : '',
                        isSortable: false
                    }),
                    new ColumnConfig({
                        field: 'name',
                        serverColumn: 'fi.Name',
                        display: 'Name',
                    }),
                    new ColumnConfig({
                        field: 'abaNumber',
                        serverColumn: 'fi.AbaNumber',
                        display: 'ABA',
                    }),
                    new ColumnConfig({
                        field: 'timeLastUpdated',
                        serverColumn: 'fi.TimeLastUpdated',
                        display: 'Last Updated',
                        cellDisplay: val => val ? DateTime.fromISO(val as string + 'Z').toLocaleString(DateTime.DATETIME_SHORT) : ''
                    })
                ]
            }
        ]
    };

    return (
        <Container>
            <GridPage records={financialInstitutions ?? []}
                      gridConfig={config}
                      title={'Financial Institutions'}
                      newLink={ hasPermission([ADMIN, TEAM_LEAD]) ? '/admin/financialInstitution' : undefined}
                      sort={sort}
                      shouldShowCriteriaPanel={false}
                      onSortChanged={setSort}
                      onSearchChange={setSearch}
                      moreRecordsAvailable={moreAvailable}
                      onShouldFetchMore={fetchMore}
                      isLoading={isLoading}
            />
        </Container>
    )
}