import React from 'react';
import {SiteCardView} from './siteCard/SiteCardView';
import {HomeHeader} from './promotion/block/HomeHeader';
import {Container} from 'reactstrap';
import './Home.css';

export const Home = () => {
	return (
		<>
			<HomeHeader />
			<Container className="home-content-container">
				<SiteCardView />
			</Container>
		</>
	);
}
