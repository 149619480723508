import { ProductInputView } from '../view/ProductInputView';
import './ProductInputList.css';
import {InputClassification, ProductInputValue} from '../ProductInputValue';
import { Configuration } from "../configuration/Configuration";
import { Component } from "../component/Component";
import { SiteProductVariantQuantityOption } from "../../product/SiteProductVariantQuantityOption";
import { Utils } from "../../../utils/Utils";
import { ConfigurationPaging } from "../configuration/ConfigurationPaging";
import {ProductType} from "../../product/Product";

interface ProductInputListProps {
  basePrice: number;
  configurations?: Configuration[],
  components?: Component[],
  quantityOptions?: SiteProductVariantQuantityOption[],
  currentPage?: ConfigurationPaging,
  isInEditMode: boolean,
  checkInputs: boolean,
  productTypes: ProductType[],
  siteProductId: number,
  productVariantId?: number,
  getConfigurationValueById: (inputClassification: InputClassification, productInputId?: number) => ProductInputValue | undefined,
  consumerId?: number,
  selectedQuantityOption?: SiteProductVariantQuantityOption
}

export const ProductInputList = ({
                                   basePrice,
                                   configurations,
                                   components,
                                   quantityOptions,
                                   currentPage,
                                   isInEditMode,
                                   checkInputs,
                                   productTypes,
                                   siteProductId,
                                   productVariantId,
                                   getConfigurationValueById,
                                   consumerId,
                                   selectedQuantityOption
                                 }: ProductInputListProps) => {


  const getConfigurationDetailsIdByKey = (configurationKey: string) => {
      return configurations?.find(c => c.configurationKey === configurationKey)?.configurationDetail.id;
  }

  const getConfigurationByKey = (configurationKey: string) => {
      return configurations?.find(c => c.configurationKey === configurationKey);
  }

  const getComponentInputToRender = () =>
    components?.map((component, index) => {
        return <ProductInputView
            basePrice={basePrice}
            inputClassification={InputClassification.Component}
            productInputId={component.id}
            name={component.name}
            component={component}
            key={`${component.id} - component - ${index}`}
            isInEditMode={isInEditMode}
            currentPage={currentPage}
            checkInputs={checkInputs}
            productTypes={productTypes}
            siteProductId={siteProductId}
            productVariantId={productVariantId}
            getConfigurationByKey={getConfigurationByKey}
            getConfigurationDetailsIdByKey={getConfigurationDetailsIdByKey}
            getConfigurationValueById={getConfigurationValueById}
            consumerId={consumerId}
        />
    })

  const getConfigurationInputToRender = () =>
  [...configurations ?? []]
      .sort(Utils.sortBy(c => c.configurationDetail.sortOrder ?? -1, 'asc'))
      .map((configuration, index) =>
              <ProductInputView
                  basePrice={basePrice}
                  configuration={configuration}
                  inputClassification={InputClassification.Configuration}
                  productInputId={configuration.configurationDetail.id}
                  name={configuration.configurationKey}
                  selectedQuantityOption={selectedQuantityOption}
                  key={`${configuration.id} - configuration - ${index}`}
                  isInEditMode={isInEditMode}
                  currentPage={currentPage}
                  checkInputs={checkInputs}
                  productTypes={productTypes}
                  siteProductId={siteProductId}
                  productVariantId={productVariantId}
                  getConfigurationByKey={getConfigurationByKey}
                  getConfigurationDetailsIdByKey={getConfigurationDetailsIdByKey}
                  getConfigurationValueById={getConfigurationValueById}
                  consumerId={consumerId}
                  />
      )

  const getQuantityInputToRender = () => {
    if (quantityOptions) {
        return (
            <>
                <h4 className='product-input-view-label'>Choose a quantity</h4>
                <ProductInputView
                    basePrice={basePrice}
                    inputClassification={InputClassification.Quantity}
                    productInputId={-1}
                    name='Quantity'
                    quantityOptions={quantityOptions}
                    isInEditMode={isInEditMode}
                    currentPage={currentPage}
                    checkInputs={checkInputs}
                    productTypes={productTypes}
                    siteProductId={siteProductId}
                    productVariantId={productVariantId}
                    getConfigurationByKey={getConfigurationByKey}
                    getConfigurationDetailsIdByKey={getConfigurationDetailsIdByKey}
                    getConfigurationValueById={getConfigurationValueById}
                    consumerId={consumerId}
                />
            </>
        )
    }
  }

  return (
    <form>
        <div className="product-input-list-container">
    
          <div className="product-input-view">
    
            {getComponentInputToRender()}
    
            {getQuantityInputToRender()}
    
            {getConfigurationInputToRender()}
    
          </div>
        </div>
    </form>
  );
}