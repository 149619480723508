
import './MicrLegend.css';
import {Card, CardBody, CardHeader, ListGroup, ListGroupItem} from "reactstrap";

export const MicrLegend = () => {

    return (
        <Card className="financial-institution-details-card">

            <CardHeader className='d-flex justify-content-between card-header'>
                <h5 className="m-0">Legend</h5>
            </CardHeader>
            <CardBody>
                <ListGroup>
                    <ListGroupItem>
                        Account Number: <b>#</b> (Required), <b>Z</b> (Optional)
                    </ListGroupItem>
                    <ListGroupItem>
                        Routing Number: <b>R</b>
                    </ListGroupItem>
                    <ListGroupItem>
                        Check Number: <b>N</b>
                    </ListGroupItem>
                </ListGroup>
            </CardBody>
        </Card>
    )
}