import '../Accordions.css';
import {useTranslation} from "react-i18next";
import {doesTranslationValueExist, getTranslationValue} from "../../../../../utils/Utils";

export const ProductCard = () => {
    const { t } = useTranslation();

    return (
        <span className='order-accordion-questions d-flex flex-column'>
            <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{t(`pages.faq.help.product.question1`)}</span>
                <ol>
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answerItemTitle1') && (
                      <li>
                        <span className='order-accordion-answer-list-item-title'>
                            {t(`pages.faq.help.product.answerItemTitle1`)} -
                        </span>
                          {doesTranslationValueExist(t, 'pages.faq.help.product.answerItem1') && (
                            <span> {t(`pages.faq.help.product.answerItem1`)}</span>
                          )}
                      </li>
                    )}
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answerItemTitle2') && (
                      <li>
                        <span className='order-accordion-answer-list-item-title'>
                            {t(`pages.faq.help.product.answerItemTitle2`)} -
                        </span>
                          {doesTranslationValueExist(t, 'pages.faq.help.product.answerItem2') && (
                            <span> {t(`pages.faq.help.product.answerItem2`)}</span>
                          )}
                      </li>
                    )}
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answerItemTitle3') && (
                      <li>
                        <span className='order-accordion-answer-list-item-title'>
                            {t(`pages.faq.help.product.answerItemTitle3`)} -
                        </span>
                          {doesTranslationValueExist(t, 'pages.faq.help.product.answerItem3') && (
                            <span> {t(`pages.faq.help.product.answerItem3`)}</span>
                          )}
                      </li>
                    )}
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answerItemTitle4') && (
                      <li>
                        <span className='order-accordion-answer-list-item-title'>
                            {t(`pages.faq.help.product.answerItemTitle4`)} -
                        </span>
                          {doesTranslationValueExist(t, 'pages.faq.help.product.answerItem4') && (
                            <span> {t(`pages.faq.help.product.answerItem4`)}</span>
                          )}
                      </li>
                    )}
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answerItemTitle5') && (
                      <li>
                        <span className='order-accordion-answer-list-item-title'>
                            {t(`pages.faq.help.product.answerItemTitle5`)} -
                        </span>
                          {doesTranslationValueExist(t, 'pages.faq.help.product.answerItem5') && (
                            <span> {t(`pages.faq.help.product.answerItem5`)}</span>
                          )}
                      </li>
                    )}
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answerItemTitle6') && (
                      <li>
                        <span className='order-accordion-answer-list-item-title'>
                            {t(`pages.faq.help.product.answerItemTitle6`)} -
                        </span>
                          {doesTranslationValueExist(t, 'pages.faq.help.product.answerItem6') && (
                            <span> {t(`pages.faq.help.product.answerItem6`)}</span>
                          )}
                      </li>
                    )}
                </ol>
            </span>
            {doesTranslationValueExist(t, 'pages.faq.help.product.question2') && (
                <span className='d-flex flex-column'>
                    <span className='order-accordion-order-question'>{t(`pages.faq.help.product.question2`)}</span>
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer2') && (
                        <span>{t(`pages.faq.help.product.answer2`)}</span>
                    )}
                </span>
            )}
            {doesTranslationValueExist(t, 'pages.faq.help.product.question3') && (
                <span className='d-flex flex-column'>
                    <span className='order-accordion-order-question'>{t(`pages.faq.help.product.question3`)}</span>
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer3Part1') && (
                        <span>
                            {t(`pages.faq.help.product.answer3Part1`)}
                            {doesTranslationValueExist(t, 'pages.faq.help.product.answer3Link1') && (
                                <>
                                    <a href={getTranslationValue(t, 'pages.faq.help.product.answer3Link1')}>
                                        {getTranslationValue(t, 'pages.faq.help.product.answer3LinkText1')}
                                    </a>
                                    {getTranslationValue(t, 'pages.faq.help.product.answer3Part2')}
                                </>
                            )}
                            {doesTranslationValueExist(t, 'pages.faq.help.product.answer3Link2') && (
                                <>
                                    <a href={getTranslationValue(t, 'pages.faq.help.product.answer3Link2')}>
                                        {getTranslationValue(t, 'pages.faq.help.product.answer3LinkText2')}
                                    </a>
                                    <span>{getTranslationValue(t, 'pages.faq.help.product.answer3Part3')}</span>
                                </>
                            )}
                        </span>
                    )}
                </span>
            )}







            {doesTranslationValueExist(t, 'pages.faq.help.product.answer3IndentTitle1') && (
                <span className='d-flex flex-column ms-5'>
                    <span className='order-accordion-order-question'>{t(`pages.faq.help.product.answer3IndentTitle1`)}</span>
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer3IndentBodyPt1') && (
                        <span>
                            {t(`pages.faq.help.product.answer3IndentBodyPt1`)}
                            {doesTranslationValueExist(t, 'pages.faq.help.product.answer3Link3') && (
                                <>
                                    <a href={getTranslationValue(t, 'pages.faq.help.product.answer3Link3')}>
                                        {getTranslationValue(t, 'pages.faq.help.product.answer3LinkText3')}
                                    </a>
                                    {getTranslationValue(t, 'pages.faq.help.product.answer3IndentBodyPt2')}
                                </>
                            )}
                        </span>
                    )}
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer3IndentBodyPt3') && (
                        <>
                            <br/>
                            <span>{t(`pages.faq.help.product.answer3IndentBodyPt3`)}</span>
                        </>
                    )}
                </span>
            )}
            {doesTranslationValueExist(t, 'pages.faq.help.product.answer3IndentTitle2') && (
                <span className='d-flex flex-column ms-5'>
                    <span className='order-accordion-order-question'>{t(`pages.faq.help.product.answer3IndentTitle2`)}</span>
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer3IndentBodyPt4') && (
                        <span>
                            {t(`pages.faq.help.product.answer3IndentBodyPt4`)}
                            {doesTranslationValueExist(t, 'pages.faq.help.product.answer3Link4') && (
                                <>
                                    <a href={getTranslationValue(t, 'pages.faq.help.product.answer3Link4')}>
                                        {getTranslationValue(t, 'pages.faq.help.product.answer3LinkText4')}
                                    </a>
                                    {getTranslationValue(t, 'pages.faq.help.product.answer3IndentBodyPt5')}
                                </>
                            )}
                        </span>
                    )}
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer3IndentBodyPt6') && (
                        <>
                            <br/>
                            <span>{t(`pages.faq.help.product.answer3IndentBodyPt6`)}</span>
                        </>
                    )}
                </span>
            )}










            {doesTranslationValueExist(t, 'pages.faq.help.product.question4') && (
                <span className='d-flex flex-column'>
                    <span className='order-accordion-order-question'>{t(`pages.faq.help.product.question4`)}</span>
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer4Pt1') && (
                        <span>
                            {t(`pages.faq.help.product.answer4Pt1`)}
                            {doesTranslationValueExist(t, 'pages.faq.help.product.answer4Link1') && (
                                <>
                                    <a href={getTranslationValue(t, 'pages.faq.help.product.answer4Link1')}>
                                        {getTranslationValue(t, 'pages.faq.help.product.answer4LinkText1')}
                                    </a>
                                    {getTranslationValue(t, 'pages.faq.help.product.answer4Pt2')}
                                </>
                            )}
                        </span>
                    )}
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer4Pt3') && (
                        <>
                            <br/>
                            <span>{t(`pages.faq.help.product.answer4Pt3`)}</span>
                        </>
                    )}
                </span>
            )}
            {doesTranslationValueExist(t, 'pages.faq.help.product.question5') && (
                <span className='d-flex flex-column'>
                    <span className='order-accordion-order-question'>{t(`pages.faq.help.product.question5`)}</span>
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer5Pt1') && (
                        <span>
                            {t(`pages.faq.help.product.answer5Pt1`)}
                            {doesTranslationValueExist(t, 'pages.faq.help.product.answer5Link1') && (
                                <>
                                    <a href={getTranslationValue(t, 'pages.faq.help.product.answer5Link1')}>
                                        {getTranslationValue(t, 'pages.faq.help.product.answer5LinkText1')}
                                    </a>
                                    {getTranslationValue(t, 'pages.faq.help.product.answer5Pt2')}
                                </>
                            )}
                        </span>
                    )}
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer5Pt3') && (
                        <>
                            <br/>
                            <span>{t(`pages.faq.help.product.answer5Pt3`)}</span>
                        </>
                    )}
                </span>
            )}
            {doesTranslationValueExist(t, 'pages.faq.help.product.question6') && (
                <span className='d-flex flex-column'>
                    <span className='order-accordion-order-question'>{t(`pages.faq.help.product.question6`)}</span>
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer6') && (
                        <span>
                            {doesTranslationValueExist(t, 'pages.faq.help.product.answer6Link1') && (
                                <>
                                    <a href={getTranslationValue(t, 'pages.faq.help.product.answer6Link1')}>
                                        {getTranslationValue(t, 'pages.faq.help.product.answer6LinkText1')}
                                    </a>
                                </>
                            )}
                            {getTranslationValue(t, 'pages.faq.help.product.answer6')}
                        </span>
                    )}
                </span>
            )}
            {doesTranslationValueExist(t, 'pages.faq.help.product.question7') && (
                <span className='d-flex flex-column'>
                    <span className='order-accordion-order-question'>{t(`pages.faq.help.product.question7`)}</span>
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer7Pt1') && (
                        <span>{t(`pages.faq.help.product.answer7Pt1`)}</span>
                    )}
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer7Pt2') && (
                        <>
                            <br/>
                            <span>{t(`pages.faq.help.product.answer7Pt2`)}</span>
                        </>
                    )}
                </span>
            )}
            {doesTranslationValueExist(t, 'pages.faq.help.product.question8') && (
                <span className='d-flex flex-column'>
                    <span className='order-accordion-order-question'>{t(`pages.faq.help.product.question8`)}</span>
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer8') && (
                        <span>{t(`pages.faq.help.product.answer8`)}</span>
                    )}
                </span>
            )}
            {doesTranslationValueExist(t, 'pages.faq.help.product.question9') && (
                <span className='d-flex flex-column'>
                    <span className='order-accordion-order-question'>{t(`pages.faq.help.product.question9`)}</span>
                    {doesTranslationValueExist(t, 'pages.faq.help.product.answer9') && (
                        <span>{t(`pages.faq.help.product.answer9`)}</span>
                    )}
                </span>
            )}
        </span>
    );
};