import {CartIdType} from "../cart/CartSlice";

export interface PaymentMethod {
    id: number,
    name: string,
    isEnabled: boolean
}

export enum PaymentMethodOption {
    NewPaymentMethod,
    SavedPaymentMethod
}

export interface PaymentMethodsRequest {
    cartId?: CartIdType,
    orderId?: number
}

export enum PaymentMethodName {
    ACH = "ACH",
    CreditCard = "Credit Card",
    SavedPayment = "Saved Payment"
}