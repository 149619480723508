import {getPricingTableViewModel, SiteProduct} from "./Product";
import {Table} from "reactstrap";
import {CurrencyFormatter} from "../../utils/CurrencyFormatter";
import {useGetComponentsForSiteProductQuery, useGetSiteProductVariantsBySiteProductIdQuery} from "../../app/apiSlice";
import {sortBy} from "lodash";
import './PriceGrid.css';

export type PriceGridProps = {
    readonly siteProduct: SiteProduct;
}

export default function PriceGrid({siteProduct}: PriceGridProps) {

    const {data: components} = useGetComponentsForSiteProductQuery(siteProduct.id);
    const {data: variants} = useGetSiteProductVariantsBySiteProductIdQuery(siteProduct.id);

    if (!siteProduct.quantityOptions || siteProduct.quantityOptions.length <= 1) {
        return null;
    }

    const quantities = getPricingTableViewModel(
        siteProduct,
        variants || [],
        components || []
    );

    return (quantities !== null ?
            <Table bordered className="price-grid">
                <caption className="mt-2">
                    {siteProduct.name} Pricing
                </caption>
                <thead className="table-primary">
                <tr>
                    <th scope="col">Quantity</th>
                    {quantities?.headers.map(header => (
                        <th scope="col">
                            {header}
                        </th>
                    ))}
                </tr>
                </thead>

                <tbody>
                {quantities !== null && sortBy(Object.entries(quantities?.prices || {}), ([_, {quantity}]) => quantity)
                    .map(([quantity, options]) => (
                        <tr>
                            <td>
                                {quantity}
                            </td>
                            <td>
                                {typeof options.price1 === 'number' ?
                                    CurrencyFormatter.format(options.price1)
                                    : options.price1}
                            </td>
                            <td>
                                {typeof options.price2 === 'number' ?
                                    CurrencyFormatter.format(options.price2)
                                    : options.price2}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            : null
    )
}
