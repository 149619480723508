import './MicrValueDisplay.css';
import {Input, Spinner} from "reactstrap";
import {createArrayOfNumbersUsingMinAndMax} from "../../../../utils/Utils";
import {MicrFormatConstraints} from "../MicrFormat";
import React, {memo, useCallback, useEffect, useState} from "react";
import {usePreviewMicrFormatQuery} from "../../../../app/apiSlice";
import {PreviewMicrFormatRequest} from "../PreviewMicrFormatRequest";
import {debounce} from "lodash";
import {skipToken} from "@reduxjs/toolkit/query";

interface MicrValueDisplayProps {
    micrFormatTemplate: string,
    orderProductId: number,
    isOrderProductSpecific: boolean
}

export const MicrValueDisplay = ({ micrFormatTemplate, orderProductId, isOrderProductSpecific }: MicrValueDisplayProps) => {

    const micrLengthRange = createArrayOfNumbersUsingMinAndMax(0, MicrFormatConstraints.MicrTextLength - 1);
    const [previewMicrFormatRequest, setPreviewMicrFormatRequest] = useState<PreviewMicrFormatRequest | undefined>(undefined);
    const [isDebouncing, setIsDebouncing] = useState<boolean>(false);
    const { data: previewMicrResponse, isFetching} = usePreviewMicrFormatQuery(previewMicrFormatRequest ?? skipToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouceFetchMicrFormatPreview = useCallback(debounce(fetchMicrFormatPreview, 2000), [micrFormatTemplate, orderProductId]);

    function fetchMicrFormatPreview() {

        if (!micrFormatTemplate) {
            setPreviewMicrFormatRequest(undefined);
            return;
        }

        if (micrFormatTemplate && orderProductId) {
            setPreviewMicrFormatRequest({
                micrFormat: micrFormatTemplate,
                orderProductId: orderProductId,
                isOrderProductSpecific: isOrderProductSpecific
            });
        }

        setIsDebouncing(false);
    }

    useEffect(() => {
        setIsDebouncing(true);
        debouceFetchMicrFormatPreview();
    }, [micrFormatTemplate]);

    const getMicrTextValueAtIndex = (micrRequestedIndex: number) => {
        const micrValueAtIndex = previewMicrResponse?.micrPreviewText ? previewMicrResponse?.micrPreviewText[micrRequestedIndex] :
            (micrFormatTemplate ? micrFormatTemplate[micrRequestedIndex] : "");
        return micrValueAtIndex ?? "";
    }

    const getLoadingSpinner = () => {
        return (
            <Spinner size="sm">
                Loading...
            </Spinner>
        );
    }

    const getMicrIndexText = (index: number) => {
        return (index % 5 > 0 && index !== (MicrFormatConstraints.MicrTextLength))
            ? '.' : (MicrFormatConstraints.MicrTextLength - index);
    }

    return (
        <>
            <h5 className="m-0">Micr Preview {isFetching || isDebouncing ? getLoadingSpinner() : ''}</h5>
            <div className="micr-preview-container">
                <div className="micr-preview-text-container">
                    {micrLengthRange.map((micrIndex) => {
                        return (
                            <div className="micr-text-input-column"
                                 key={`micr-text-input-${micrIndex}`}>
                                <Input type="text"
                                       className="micr-text-input-value"
                                       readOnly={true}
                                       disabled={true}
                                       value={getMicrTextValueAtIndex(micrIndex)}></Input>
                                <span className="micr-text-input-index-text">{getMicrIndexText(micrIndex)}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    )
}
export const MicrValueDisplayMemoized = memo(MicrValueDisplay);