import { Configuration } from '../configuration/Configuration';
import { Component } from '../component/Component';
import './ColoredRadioSelect.css';
import { ProductInputValue } from '../ProductInputValue';
import {RequiredAsterisk} from "../utils/RequiredAsterisk";
import {useEffect} from "react";
import {useAppSelector} from "../../../app/hooks";
import {selectChosenColorOption} from "../ProductInputSlice";
import {OnValueChangeParams} from "../view/ProductInputView";

interface ColoredRadioSelectProps {
    configuration?: Configuration,
    component?: Component,
    productInputValue?: ProductInputValue,
    onValueChange: (params: OnValueChangeParams) => void
}

export const ColoredRadioSelect = ({ component, productInputValue, onValueChange }: ColoredRadioSelectProps) => {
    const isRequired = (component !== undefined);
    const colorChoice = useAppSelector(selectChosenColorOption);
    
    function getSelectedColor() {
        return component?.componentOptions?.find(co => co.value === productInputValue?.value)?.description;
    }

    useEffect(() => {
        if (!productInputValue?.isDirty && !productInputValue) {
            const defaultOption = getDefaultOption();
            if (defaultOption)
                setTimeout(() => onValueChange({ inputValue: defaultOption.value, optionId: defaultOption.id }));
        }
    }, [productInputValue]);

    function getDefaultOption() {
        const defaultOptionIndex = component?.componentOptions?.findIndex(co => co.isDefault);
        if (!component?.componentOptions || defaultOptionIndex === undefined || defaultOptionIndex === -1) {
            return null;
        }
        return component.componentOptions[defaultOptionIndex];
    }

    function isOptionSelected(value: string) {
        return productInputValue?.value === value;
    }

    function hideIfRedundant() {
        // Return hidden class if there's only one option, and its selected
        if (component?.componentOptions && component.componentOptions.length < 2 && productInputValue?.value)
            return "colored-option-hide";
    }

    return (
        <div className={`colored-radio-selected-input-container ${hideIfRedundant()}`}>
            <h4>
                {component?.label} - {getSelectedColor()}
                {isRequired ? <RequiredAsterisk></RequiredAsterisk> : <></>}
            </h4>


            <div className="colored-option-container">
                {component?.componentOptions?.map((componentOption) => {
                    return (
                        <span key={componentOption.id} className="colored-option-content">
                          <div className={`circular-outline ${isOptionSelected(componentOption.value) ? 'selected-option' : 'unselected-option'}`}
                               onClick={(e) => onValueChange({ inputValue: componentOption.value, optionId: componentOption.id })}>
                            <div className="circular-radio-button" style={{backgroundColor: componentOption.value}}></div>
                          </div>
                        </span>
                    )

                })}
            </div>

        </div>
    );
}
