import './StorefrontErrorMessage.css';
import {Container} from 'reactstrap';
import ScrollToTop from "../../../helpers/ScrollToTop";
import React from "react";
import {useTranslation} from "react-i18next";

export const StorefrontErrorMessage = () => {
    const { t } = useTranslation();

    return (
        <span className='storefront-error-boundary-page d-flex flex-column'>
            <ScrollToTop></ScrollToTop>
            <Container>
                <span className='storefront-error-boundary-container d-flex flex-column'>
                    <span className='storefront-error-boundary-title d-flex'>
                        {t('storefrontError.title')}
                    </span>
                    <span className='storefront-error-boundary-body d-flex flex-column'>
                        {t('storefrontError.message')}
                    </span>
                </span>
            </Container>
        </span>
    );
}