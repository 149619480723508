import {useGetTaxReportQuery} from "../../../app/apiSlice";
import {ColumnConfig} from "../../admin/shared/Grid";
import {reportCol, reportDateToString, reportMoneyToString, reportValToOrderLink, ReportView} from "../ReportView";
import {TaxReport} from "./TaxReport"

export const TaxReportReport = () => {
    const filterFunc = (lowerSearch: string, records: TaxReport[]) =>
        records.filter(r =>
            r.orderNumber.toString().includes(lowerSearch) ||
            r.orderStatus.toLowerCase().includes(lowerSearch) ||
            r.brand.toLowerCase().includes(lowerSearch) ||
            r.shippingAddress.toLowerCase().includes(lowerSearch) ||
            r.shippingCity.toLowerCase().includes(lowerSearch) ||
            r.shippingState.toLowerCase().includes(lowerSearch) ||
            r.shippingZip.includes(lowerSearch)
        );

    const columns: ColumnConfig<TaxReport>[] = [
        reportCol('orderNumber', 'Order #', reportValToOrderLink),
        reportCol('orderDate', 'Order Date', reportDateToString),
        reportCol('orderStatus', 'Status'),
        reportCol('brand', 'Brand'),
        reportCol('finalShipDate', 'Final Ship Date', reportDateToString),
        reportCol('shippingAddress', 'Ship Addr'),
        reportCol('shippingCity', 'Ship City'),
        reportCol('shippingState', 'Ship State'),
        reportCol('shippingZip', 'Ship Zip'),
        reportCol('netOrderTotal', 'Order Net', reportMoneyToString),
        reportCol('taxTotal', 'Tax Total', reportMoneyToString),
        reportCol('retailDeliveryFee', 'Retail Delivery Fee', reportMoneyToString),
    ];

    return <ReportView
        title='Tax Report'
        query={useGetTaxReportQuery}
        columns={columns}
        initialSort={{ column: 'orderDate', direction: 'asc'}}
        filterFunc={filterFunc}
        downloadPath='/api/Reports/TaxReport'
        downloadName='Tax Report'/>
}