
import './CartPreviewDisclaimer.css';
import {useTranslation} from "react-i18next";


export const CartPreviewDisclaimer = () => {
    const { t } = useTranslation();

    return (
        <span className="cart-preview-disclaimer-container">
            <p>{t('cart.previewDisclaimer.body')}</p>
        </span>
    )
}
