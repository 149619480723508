import { GridPage } from "../shared/GridPage";
import { useGetRolesQuery, useGetAdminsQuery } from "../../../app/apiSlice";
import { useEffect, useState } from "react";
import { ColumnConfig, GridConfig } from "../shared/Grid";
import { SortState } from "../../../utils/Grid";
import { Utils } from "../../../utils/Utils";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { AdminSearchCriteria } from "./AdminSearchCriteria";
import { Admin, Role } from "../../user/Admin";
import {useDebounce} from "../../../app/hooks";
import {skipToken} from "@reduxjs/toolkit/dist/query";

export const ManageAdmins = () => {
  const { data: roles } = useGetRolesQuery();
  const [defaultCriteria, setDefaultCriteria] = useState<AdminSearchCriteria>({});
  const [criteria, setCriteria] = useState(defaultCriteria);
  const debouncedCriteria = useDebounce(criteria, 500);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<SortState<Admin>>({ column: 'id', direction: 'asc' });
  
  const { data: queryAdmins, isLoading } = useGetAdminsQuery(debouncedCriteria ?? skipToken);
  const [ sortedAdmins, setSortedAdmins ] = useState(queryAdmins);
  
  useEffect(() => {
    setDefaultCriteria({
      roles: roles?.map(r => r.id),
    })
  }, [roles])
  
  // sort
  useEffect(() => {
    setSortedAdmins(users => {
      const workingUsers = [...users ?? queryAdmins ?? []];
      return sort.column === 'roles' ?
          workingUsers.sort(Utils.sortBy(u => u.roles.map(r => r.name).join(','), sort.direction)) :
          workingUsers.sort(Utils.sortBy(sort.column, sort.direction));
    });
  }, [sort, queryAdmins]);
  
  // filter
  useEffect(() => {
    const lowerSearch = search.toLowerCase();
    setSortedAdmins(
      !lowerSearch ? queryAdmins : queryAdmins?.filter(u =>
        u.id.toString().includes(lowerSearch) ||
        u.email.toLowerCase().includes(lowerSearch) ||
        u.roles.map(r => r.name.toLowerCase()).join(',').includes(lowerSearch)
      )
    );
  }, [search, queryAdmins]);
  
  const config: GridConfig<Admin> = {
    configSections: [
      {
        columns: [
          new ColumnConfig({
            field: 'id',
            display: 'User Id',
            validation: val => (val && val < 1) ? 'User Id must be greater than 0' : undefined,
            cellDisplay: val => (<Link to={`/admin/admin/${val}`}>{val as string}</Link>),
          }),
          new ColumnConfig({
            field: 'email',
            display: 'Email',
          }),
          new ColumnConfig({
            field: 'roles',
            display: 'Roles',
            type: 'multi-select',
            choices: roles?.map(r => ({ value: r.id, display: r.name })) ?? [],
            cellDisplay: roles => (roles as Role[])?.map(r => r.name).join(', ') ?? '',
          }),
          new ColumnConfig({
            field: 'enabled',
            display: 'Enabled',
            isSearchCriteria: false,
            cellDisplay: isEnabled => isEnabled ? 'Yes' : 'No',
          }),
          new ColumnConfig({
            field: 'user.timeLastUpdatedLocal',
            display: 'Last Modified Date',
            isSearchCriteria: false,
            cellDisplay: val => val ? DateTime.fromISO(val as string).toLocaleString(DateTime.DATETIME_SHORT) : 'Never modified.',
          }),
          new ColumnConfig({
            field: 'user.updatedBy',
            display: 'Updated By',
            cellDisplay: val => val as string ?? 'Never modified.',
            isSearchCriteria: false,
          })
        ]
      }
    ]
  };
  
  return (
    <GridPage records={sortedAdmins ?? []}
              gridConfig={config}
              defaultCriteria={defaultCriteria}
              newLink='/admin/admin/new'
              sort={sort}
              shouldShowCriteriaPanel={true}
              onSortChanged={setSort}
              onCriteriaChange={setCriteria}
              onSearchChange={setSearch}
              isLoading={isLoading}/>
  )
}