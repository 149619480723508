import { useTranslation } from 'react-i18next';
import { useGetConsumerOrderTrackingInformationQuery } from '../../../../../app/apiSlice';
import { TrackingDetails } from '../trackingDetails/TrackingDetails';

export const ConsumerOrderTracking = () => {
    const { t } = useTranslation();
    const { data: orderTrackingList } = useGetConsumerOrderTrackingInformationQuery();

    return (
        <TrackingDetails orderTrackingList={orderTrackingList ?? []} noOrdersMessage={t(`tracking.trackingTable.errors.userNotAvailable`)} />
    );
}