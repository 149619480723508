import { FormGroup, Input } from "reactstrap"
import { MultiSelect } from "../../input/MultiSelect"
import { DateRangeInput } from "../../input/dateRange/DateRangeInput"
import { ColumnConfig } from "./Grid"
import { get as _get } from 'lodash'

interface CriteriaInputProps<T> {
    config: ColumnConfig<T>
    criteria: T
    onChange: (value: any) => void,
}
export const CriteriaInput = <T,>({ config, criteria, onChange }: CriteriaInputProps<T>) => {
    const value = _get(criteria, config.field) ?? '';
    
    if (config.type === 'multi-select')
    return <MultiSelect options={config.choices!} values={value} onChange={onChange} />
    
    if (config.type === 'date')
    return <DateRangeInput id={config.field} value={value} onChange={onChange} />
    
    if (config.type === 'boolean-tri-state')
    return (
        <Input id={config.field} type='select' value={value} onChange={e => onChange(e.target.value)}>
                <option value=""></option>
                <option value="true">True</option>
                <option value="false">False</option>
            </Input>
        );
        
    if (config.type === 'toggle') {
        const boolVal = _get(criteria, config.field) ?? false;
        return (
            <FormGroup switch>
                <Input id={config.field} type='switch' role='switch' onChange={e => onChange(e.target.checked)} checked={boolVal}/>
            </FormGroup>
        );
    }

    if (config.type === 'select')
        return (
            <Input type='select' name={config.field} value={value} onChange={e => onChange(Number.parseInt(e.target.value))}>
                { config.choices?.map(c => <option key={c.value} value={c.value}>{c.display}</option>)}
            </Input>
        )
    
    return <Input id={config.field} type={config.type === 'single-date' ? 'date' : config.type} options={config.choices} name={config.field} autoComplete={config.field} onChange={e => onChange(e.target.value)} value={value}/>
}
