
import { SiteNavigation } from '../SiteNavigation';
import './NavigationChildren.css';
import {Link} from "react-router-dom";
import {DropdownItem, DropdownMenu, NavLink} from "reactstrap";
import {DisplayImage} from "../../image/display/DisplayImage";
import {Utils} from "../../../utils/Utils";

interface NavigationChildrenProps {
    siteNavigationChildren: SiteNavigation[];
}

export const NavigationChildren = ({siteNavigationChildren}: NavigationChildrenProps) => {

    return (
        <DropdownMenu className="navigation-children-dropdown-menu">
                {[...siteNavigationChildren].sort(Utils.sortBy('sortOrder', 'asc')).map((snc, index) => {
                    return (
                        <DropdownItem key={snc.id} tag={Link} to={`/category/${snc.category.urlName}`} className="navigation-children-dropdown-item">
                            <span className="navigation-child-item-name">{snc.displayName}</span>
                        </DropdownItem>
                    )
                })}
        </DropdownMenu>
    );

}