import {Button, Spinner} from "reactstrap";
import "./PaymentDetailsEntry.css";
import {PaymentMethod} from "../PaymentMethod";
import {PaymentDetails} from "../PaymentDetails";
import {CreditCardDetailsEntry} from "./creditCard/CreditCardDetailsEntry";
import {Address} from "../../input/address/Address";
import {useEffect} from "react";
import {SavedPaymentDetails} from "../SavedPaymentDetails";
import {ValidationHook} from "../../../app/hooks";
import {ACHPaymentDetails} from "../ACHPaymentDetails";

interface PaymentDetailsEntryProps {
    billingAddress?: Address,
    paymentMethod?: PaymentMethod,
    allowSavingPaymentType: boolean,
    savedPaymentDetails?: SavedPaymentDetails,
    onPaymentDetailsChanged: (paymentDetails?: PaymentDetails) => void,
    onSavePaymentDetailsClicked: () => void,
    paymentInfo?: PaymentDetails,
    validation?: ValidationHook,
    isSavingPaymentDetails: boolean,
}

export const PaymentDetailsEntry = ({billingAddress, paymentMethod, allowSavingPaymentType, savedPaymentDetails,
                                        onPaymentDetailsChanged, onSavePaymentDetailsClicked, paymentInfo, validation,
                                    isSavingPaymentDetails}: PaymentDetailsEntryProps) => {
    useEffect(() => {
        if (paymentMethod && paymentMethod.name === "ACH")
            onPaymentDetailsChanged({paymentDetailsType: "ACHPaymentDetails", paymentMethodId: paymentMethod.id, billingAddress} as ACHPaymentDetails)
        else
            onPaymentDetailsChanged(undefined);

    }, [paymentMethod]);

    const getSavePaymentDetailsButton = () => {
        if (!allowSavingPaymentType) {
            return <></>;
        }
        return <Button color="secondary"
                       onClick={(e) => onSavePaymentDetailsClicked()}
                       disabled={isSavingPaymentDetails}>
            {isSavingPaymentDetails && <Spinner/>}
            Save Payment Details
        </Button>;
    }

    const getPaymentDetailsEntry = () => {

        if (!paymentMethod) {
            return <></>
        }

        switch (paymentMethod.name) {
            case ("Credit Card"):
                return (
                    <div className="payment-details-credit-card-details-entry-container">
                        <CreditCardDetailsEntry billingAddress={billingAddress}
                                                paymentMethodId={paymentMethod.id}
                                                onPaymentDetailsChanged={onPaymentDetailsChanged}
                                                paymentInfo={paymentInfo}
                                                validation={validation}
                                                savedPaymentDetails={savedPaymentDetails}></CreditCardDetailsEntry>
                        {getSavePaymentDetailsButton()}
                    </div>
                );
            case ("ACH"):
                return<></>
            default:
                return (<></>);
        }
    }

    if (!paymentMethod) {
        return <></>;
    }

    return (
        getPaymentDetailsEntry()
    );
}