import {isNumber, isUndefined} from "lodash";
import {curry, filter, find, flatMap, flow, identity, includes, map, overArgs, some, sortBy} from "lodash/fp";
import {ConfigurationGroup} from "../components/input/configuration/ConfigurationGroup";
import {Configuration} from "../components/input/configuration/Configuration";
import {ConfigurationPaging, ReviewPage} from "../components/input/configuration/ConfigurationPaging";
import {SiteProductVariant} from "../components/product/SiteProductVariant";
import {Component} from "../components/input/component/Component";
import {ComponentOption} from "../components/input/component/ComponentOption";
import {SiteProductVariantQuantityOption} from "../components/product/SiteProductVariantQuantityOption";
import {ConfigurationOption} from "../components/input/configuration/ConfigurationOption";
import {AddOn} from "../components/addOn/AddOn";

export interface ProductConfigurationGroup extends Omit<ConfigurationGroup, 'configurations'> {
    configs: Configuration[];
}

export interface ProductConfigurationPage extends ConfigurationPaging {
    groups: ProductConfigurationGroup[];
}

export const configKey = ({configurationKey}: Configuration) => configurationKey;
export const configPaging = (c: Configuration) =>
    c.configurationDetail.paging;

export const hasPaging = (c: Configuration) =>
    !!configPaging(c);

export const configPagingKey = (c: Configuration) =>
    configPaging(c)?.pagingKey;

export const configGroup = ({configurationDetail: {group}}: Configuration) => group;
export const hasGroup = (c: Configuration) => !isUndefined(configGroup(c));
export const configGroupKey = (c: Configuration) => configGroup(c)?.groupKey;
export const groupConfigs = ({configs}: ProductConfigurationGroup) => configs;
export const groupPage = ({configs}: ProductConfigurationGroup) => configPaging(configs[0]);

export const pageGroups = ({groups}: ProductConfigurationPage) => groups;

export const configSortOrder = ({configurationDetail: {sortOrder}}: Configuration) => sortOrder;

type Sortable = {
    sortOrder: number;
}

export const sortOrder = <T extends Sortable>({sortOrder}: T) => sortOrder;

export const makeGroup = (configs: Configuration[]) => ({
    ...(configGroup(configs[0])!),
    configs: sortBy(configSortOrder, configs),
});

export const makePage = (groups: ProductConfigurationGroup[]) => ({
    ...(groupPage(groups[0])!),
    groups: sortBy(sortOrder, groups),
});

export const pageConfigKeys: (p: ProductConfigurationPage) => string[] = flow(
    pageGroups,
    flatMap(groupConfigs),
    map(configKey)
)

export const makeReviewPage: () => ProductConfigurationPage =
    () => ({
        ...ReviewPage,
        groups: []
    })


export const allConfigs: (ps: ProductConfigurationPage[]) => Configuration[] = flow(
    flatMap(p => p.groups),
    flatMap(g => g.configs),
);

interface PriceModifier {
    readonly priceModification?: number;
    readonly priceModificationPercent?: number;
    readonly addOn?: AddOn;
}

const priceModification = (pm: PriceModifier) => pm.priceModification;
const priceModificationPercent = (pm: PriceModifier) => pm.priceModificationPercent;
const hasPriceChange = (pm: PriceModifier) =>
    !!pm.addOn
    || isNumber(priceModification(pm))
    || isNumber(priceModificationPercent(pm));

export const configurationPriceModification = ({configurationDetail}: Configuration) =>
    priceModification(configurationDetail);

function priceModificationForValue(c: Configuration, v: any): PriceModifier | undefined {
    if (!v) {
        return;
    }

    return hasPriceChange(c.configurationDetail)
        ? c.configurationDetail
        : find(
            optionMatchesValue(v)
            , c.configurationDetail.options);
}

const optionMatchesValue = curry((v: any, o1: ConfigurationOption) =>
    o1.value == v
    || some(
        o2 => o2.value == v,
        o1.childConfigurationOptions));

export const configurationPriceModificationPercent = ({configurationDetail}: Configuration) =>
    priceModificationPercent(configurationDetail);

export const configurationHasPriceChange = (c: Configuration) =>
    hasPriceChange(c.configurationDetail)
    || some(hasPriceChange, c.configurationDetail.options);


type GetSelectedComponentOptions =
    (components: readonly Component[], spv: SiteProductVariant) => readonly ComponentOption[]

/**
 * Gets the selected component options for the site product variant.
 *
 * Will check each components options for inclusion in the
 * variants componentIds collection
 * @param spv
 * @param components
 */
export const getSelectedComponentOptions: GetSelectedComponentOptions =
    (components, spv) => flow(
        flatMap(componentOptions),
        filter(hasOption(spv))
    )(components);

const componentOptions = (c: Component) => c.componentOptions ?? [];
const hasOption = curry((spv: SiteProductVariant, co: ComponentOption) =>
    includes(co.id, spv.componentIds));

type ConfigurationPriceModifications = (
    qo: SiteProductVariantQuantityOption,
    cs: Configuration[],
    values: any
) => [config: Configuration, change: number][];

export const configurationPriceModifications: ConfigurationPriceModifications = flow(
    overArgs(Array.of, [
        identity,
        filter(configurationHasPriceChange),
        identity
    ]),
    ([qo, cs, values]) => map(c => [
            c,
            priceChange(qo, c, values[c.configurationKey])
        ] as [Configuration, number],
        cs),
    filter(([, change]) => change > 0)
);


function priceChange(
    qo: SiteProductVariantQuantityOption,
    c: Configuration,
    values: any
): number {

    const pm = priceModificationForValue(c, values);

    if (!pm) {
        return 0;
    }

    if (pm.priceModification) {
        return pm.priceModification;
    }

    if (pm.addOn) {
        return pm.addOn.price || 0;
    }

    return qo.price * (pm.priceModificationPercent ?? 0);
}

export function optionForValue(
    config: Configuration,
    value: any,
): ConfigurationOption | undefined {
    if (!config.configurationDetail.options
        || config.configurationDetail.options.length < 1) {
        return undefined;
    }
    return find(optionMatchesValue(value), config.configurationDetail.options)
}