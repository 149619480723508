import { Input, Label } from "reactstrap";
import React from "react";
import { DateRange } from "./DateRange";

interface DateRangeInputProps {
  id: string,
  value: DateRange,
  onChange: (newValue: DateRange) => void,
}
export const DateRangeInput = ({ id, value, onChange }: DateRangeInputProps) => {
  const { beginDate, endDate } = value ?? {};
  const innerChangeHandler = (type: 'begin' | 'end', newValue: string) => {
    onChange({
      beginDate: type === 'begin' ? newValue : value.beginDate,
      endDate: type === 'end' ? newValue : value.endDate
    });
  }
  
  return (
    <div>
      <Input id={id} autoComplete="autoComplete_off" type='date' max={endDate} name='beginDate' value={beginDate ?? ''}
             onChange={e => innerChangeHandler('begin', e.target.value)}/>
      <Label for={id + 'End'}>to</Label>
      <Input id={id + 'End'} autoComplete="autoComplete_off" type='date' min={beginDate} name='endDate' value={endDate ?? ''}
             onChange={e => innerChangeHandler('end', e.target.value)}/>
    </div>
  )
}