import {InputClassification, ProductInputValue} from '../ProductInputValue';
import './ProductInputView.css';
import {InputSelectImageAndText} from '../selectImageAndText/InputSelectImageAndText';
import {CheckboxAccessory} from '../accessories/CheckboxAccessory';
import {ColoredRadioSelect} from '../coloredRadioSelect/ColoredRadioSelect';
import {TextRadioSelect} from '../textRadioSelect/TextRadioSelect';
import {ButtonRevealingTextInput} from "../buttonRevealingTextInput/ButtonRevealingTextInput";
import {Component} from '../component/Component';
import {BankConfigurationKeys, Configuration} from '../configuration/Configuration';
import {SiteProductVariantQuantityOption} from "../../product/SiteProductVariantQuantityOption";
import {InputText} from "../text/InputText";
import {ButtonSingleSelect} from "../buttonSingleSelect/ButtonSingleSelect";
import {
  RadioSelectWithButtonSingleSelect
} from "../radioSelectWithButtonSingleSelect/RadioSelectWithButtonSingleSelect";
import {RadioSelectWithBoxedDescription} from "../radioSelectWithBoxedDescription/RadioSelectWithBoxedDescription";
import {NumericInput} from "../numeric/NumericInput";
import {AddressInput} from "../address/AddressInput";
import {ConfigurationPaging, ReviewPage} from "../configuration/ConfigurationPaging";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
  productInputValueChanged,
  productInputValueValidated,
  selectInputValue,
  setAccessoryConfigurations
} from "../ProductInputSlice";
import {FieldModifier} from "../configuration/FieldModifier";
import {PrintingInput} from '../printingSoftware/PrintingInput';
import {AddProductAccessory} from '../accessories/AddProductAccessory';
import {useValidateBankingUserInputMutation, useValidateUserInputMutation} from "../../../app/apiSlice";
import {ProductType} from "../../product/Product";
import {HiddenInput} from "../HiddenInput";

interface ProductInputViewProps {
  basePrice: number,
  productInputId: number,
  name: string,
  component?: Component,
  configuration?: Configuration,
  quantityOptions?: SiteProductVariantQuantityOption[],
  inputClassification: InputClassification,
  isInEditMode: boolean,
  currentPage?: ConfigurationPaging,
  checkInputs: boolean,
  productTypes: ProductType[],
  siteProductId: number,
  productVariantId?: number,
  getConfigurationByKey: (configurationKey: string) => Configuration | undefined,
  getConfigurationDetailsIdByKey: (configurationKey: string) => number | undefined,
  getConfigurationValueById: (inputClassification: InputClassification, productInputId?: number) => ProductInputValue | undefined,
  consumerId?: number,
  selectedQuantityOption?: SiteProductVariantQuantityOption
}

export interface OnValueChangeParams {
  inputValue: string | null;
  optionId?: number;
  quantityOptionId?: number;
  isComplete?: boolean;
  bypassValidation?: boolean;
}

export const ProductInputView = ({
  basePrice,
  productInputId,
  name,
  component,
  configuration,
  quantityOptions,
  inputClassification,
  isInEditMode,
  currentPage,
  checkInputs,
  productTypes,
  siteProductId,
  productVariantId,
  getConfigurationByKey,
  getConfigurationDetailsIdByKey,
  getConfigurationValueById,
  consumerId,
  selectedQuantityOption
}: ProductInputViewProps) => {
  const dispatch = useAppDispatch();
  const [ validateUserInput ] = useValidateUserInputMutation();
  const [ validateBankingUserInput ] = useValidateBankingUserInputMutation();
  const productInputValue = useAppSelector(state => selectInputValue(state, productInputId, inputClassification));
  const configurationComponentId = configuration?.id ?? component?.id ?? -1;
  const configurationKeysToBeNumericInput = ["RoutingNumber", "ReEnterRoutingNumber", "BankAccountNumber", "ReEnterBankAccountNumber"];

  function getClassesForModifiers(fieldModifiers?: FieldModifier[], optionId?: number) {
    if (currentPage && currentPage.pagingKey === ReviewPage.pagingKey && !isInEditMode && !configuration?.showInReview) {
      return "hide-input-in-review";
    }
    // No field modifiers are used in edit mode
    if (!isInEditMode) {
      return "";
    }

    let modifierClasses = "";
    configuration?.fieldModifiers.forEach((fieldModifier) => {
      if (fieldModifier.configurationOptionId && fieldModifier.configurationOptionId !== optionId) {
        return;
      }

      switch (fieldModifier.value) {
        case 'width-half':
          modifierClasses += ' half-width-text-input ';
          break;
        case 'font-block':
          modifierClasses += ' font-block ';
          break;
        case 'font-classic-condensed':
          modifierClasses += ' font-classic-condensed ';
          break;
        case 'font-title-plate':
          modifierClasses += ' font-title-plate ';
          break;
        case 'font-calligraphy':
          modifierClasses += ' font-calligraphy ';
          break;
        case 'font-flair':
          modifierClasses += ' font-flair ';
          break;
        case 'font-garamond':
          modifierClasses += ' font-garamond ';
          break;
        case 'font-executive':
          modifierClasses += ' font-executive ';
          break;
        case 'font-classic-block':
          modifierClasses += ' font-classic-block ';
          break;
        case 'font-broadway':
          modifierClasses += ' font-broadway ';
          break;
        case 'font-excalibur':
          modifierClasses += ' font-excalibur ';
          break;
        case 'font-handwriting':
          modifierClasses += ' font-handwriting ';
          break;
      }
    })

    return modifierClasses;
  }

  function containsFieldModifier(valueKey: string, fieldModifiers?: FieldModifier[]) {
    const fieldModifierIndex = fieldModifiers?.findIndex(fm => fm.value === valueKey);
    return !!(fieldModifierIndex && fieldModifierIndex > -1);
  }

  const onValueChange = async ({ inputValue, optionId, quantityOptionId, isComplete, bypassValidation }: OnValueChangeParams) => {
    if (inputValue) {
      inputValue = inputValue.trimStart();
    }

    dispatch(productInputValueChanged({
      ...productInputValue!,
      productInputId,
      name,
      inputClassification,
      configurationId: configurationComponentId,
      value: inputValue,
      selectedProductInputOptionId: optionId,
      selectedSiteProductVariantQuantityOptionId: quantityOptionId,
      isDirty: true,
      isValidated: undefined,
      isValidating: !!configuration?.shouldValidateValue,
      isComplete: isComplete,
      configurationKey: configuration?.configurationKey,
      showWarning: false,
      warningMessage: undefined,
      isSecure: configuration?.isSecure ?? false
    }));

    !bypassValidation && markRelatedValuesAsValidating(productInputValue);
  }

  const onAccessoryChange = async(accessoryConfigurations: Configuration[]) => {
    dispatch(setAccessoryConfigurations(accessoryConfigurations));
  }

  const isBankConfigurationInput = (configurationKey: string) => {
    return configurationKey === BankConfigurationKeys.AccountNumber || configurationKey === BankConfigurationKeys.RoutingNumber
        || configurationKey === BankConfigurationKeys.DepositRoutingNumber;
  }

  function markRelatedValuesAsValidating(value?: ProductInputValue) {
    if (value?.value && configuration?.shouldValidateValue && productVariantId) {
      try {

        // Mark other related configurations as pending validation
        if (isBankConfigurationInput(configuration.configurationKey)) {
          const routingNumberInputValue = configuration.configurationKey === BankConfigurationKeys.RoutingNumber
              ? undefined : getConfigurationValueById(InputClassification.Configuration, getConfigurationDetailsIdByKey(BankConfigurationKeys.RoutingNumber));
          const depositRoutingNumberInputValue = configuration.configurationKey === BankConfigurationKeys.DepositRoutingNumber
              ? undefined : getConfigurationValueById(InputClassification.Configuration, getConfigurationDetailsIdByKey(BankConfigurationKeys.DepositRoutingNumber));
          const accountNumberInputValue = configuration.configurationKey === BankConfigurationKeys.AccountNumber
              ? undefined : getConfigurationValueById(InputClassification.Configuration, getConfigurationDetailsIdByKey(BankConfigurationKeys.AccountNumber));

          if (routingNumberInputValue)
            dispatch(productInputValueValidated({
              ...routingNumberInputValue,
              isValidating: true
            }));
          if (accountNumberInputValue)
            dispatch(productInputValueValidated({
              ...accountNumberInputValue,
              isValidating: true
            }));
          if (depositRoutingNumberInputValue)
            dispatch(productInputValueValidated({
              ...depositRoutingNumberInputValue,
              isValidating: true
            }));

        }

      } catch (err) {
        // Fail silently
      }
    }
  }

  async function validateValue(value?: ProductInputValue) {
    if (value?.value && configuration?.shouldValidateValue && productVariantId) {
      try {

        if (isBankConfigurationInput(configuration.configurationKey)) {
          const routingNumberInputValue = configuration.configurationKey === BankConfigurationKeys.RoutingNumber
              ? value : getConfigurationValueById(InputClassification.Configuration, getConfigurationDetailsIdByKey(BankConfigurationKeys.RoutingNumber));
          const depositRoutingNumberInputValue = configuration.configurationKey === BankConfigurationKeys.DepositRoutingNumber
              ? value : getConfigurationValueById(InputClassification.Configuration, getConfigurationDetailsIdByKey(BankConfigurationKeys.DepositRoutingNumber));
          const accountNumberInputValue = configuration.configurationKey === BankConfigurationKeys.AccountNumber
              ? value : getConfigurationValueById(InputClassification.Configuration, getConfigurationDetailsIdByKey(BankConfigurationKeys.AccountNumber));
          const bankNameConfiguration = getConfigurationByKey(BankConfigurationKeys.BankName);
          const bankAddressConfiguration = getConfigurationByKey(BankConfigurationKeys.BankAddress);
          const bankConfiguration = {
            routingNumber: routingNumberInputValue?.value ?? undefined,
            depositRoutingNumber: depositRoutingNumberInputValue?.value ?? undefined,
            accountNumber: accountNumberInputValue?.value ?? undefined,
            configurationId: configuration.id,
            stringValue: value?.value,
            productVariantId: productVariantId
          };

          const validationResponse = await validateBankingUserInput(bankConfiguration).unwrap();
          if (routingNumberInputValue)
            dispatch(productInputValueValidated({
              ...routingNumberInputValue,
              isValidated: !validationResponse.isBlocked,
              showWarning: validationResponse.showWarning,
              warningMessage: validationResponse.warningMessage,
              isValidating: false,
              isSecure: true
            }));
          if (accountNumberInputValue)
            dispatch(productInputValueValidated({
              ...accountNumberInputValue,
              isValidated: !validationResponse.isBlocked,
              showWarning: validationResponse.showWarning,
              warningMessage: validationResponse.warningMessage,
              isValidating: false,
              isSecure: true
            }));
          if (depositRoutingNumberInputValue)
            dispatch(productInputValueValidated({
              ...depositRoutingNumberInputValue,
              isValidated: !validationResponse.isBlocked,
              showWarning: validationResponse.showWarning,
              warningMessage: validationResponse.warningMessage,
              isValidating: false,
              isSecure: true
            }));

          // Then update bank name and address based on returned bank info
          if (bankNameConfiguration)
            dispatch(productInputValueChanged({
              ...productInputValue!,
              configurationId: bankNameConfiguration.id,
              productInputId: bankNameConfiguration.configurationDetail.id,
              name: BankConfigurationKeys.BankName,
              inputClassification: InputClassification.Configuration,
              value: validationResponse.bankName ?? null,
              isDirty: true,
              configurationKey: BankConfigurationKeys.BankName,
            }));

          if (bankAddressConfiguration)
            dispatch(productInputValueChanged({
              ...productInputValue!,
              configurationId: bankAddressConfiguration.id,
              productInputId: bankAddressConfiguration.configurationDetail.id,
              name: BankConfigurationKeys.BankAddress,
              inputClassification: InputClassification.Configuration,
              value: validationResponse.printedBankAddress ?? null,
              isDirty: true,
              configurationKey: BankConfigurationKeys.BankAddress,
            }));

        }
        else {

          const isValidated = await validateUserInput({
            configurationId: configuration.id,
            stringValue: value.value
          }).unwrap();

          dispatch(productInputValueValidated({
            ...value,
            isValidated: isValidated
          }));

        }


      } catch (err) {
        // Fail silently, input was not able to be validated
      }
    }
  }

  function getSpecificInputTypeElement() {

    if (inputClassification === InputClassification.Quantity) {
      return <TextRadioSelect
                basePrice={basePrice}
                productTypes={productTypes}
                productInputValue={productInputValue}
                quantityOptions={quantityOptions?.filter(qo => qo.isActive)}
                onValueChange={onValueChange}
              />;
    }
    else if (inputClassification === InputClassification.Component) {

      switch (component?.inputType.name) {
        case "Single Select Image and Text":
          return <InputSelectImageAndText 
                    component={component}
                    productInputValue={productInputValue}
                    onValueChange={onValueChange}
                  />;
        
        case "Radio Select Box Text Only":
          return <TextRadioSelect
                    productInput={component}
                    basePrice={basePrice}
                    productTypes={productTypes}
                    productInputValue={productInputValue}
                    onValueChange={onValueChange}
                  />;
        
        case "Colored Radio Select":
          return <ColoredRadioSelect
                    component={component}
                    productInputValue={productInputValue}
                    onValueChange={onValueChange}
                />

        case "Radio Select With Button Single Select":
          return <RadioSelectWithButtonSingleSelect
                    productInput={component}
                    productInputValue={productInputValue}
                    isInEditMode={isInEditMode}
                    getClassesForModifiers={getClassesForModifiers}
                    onValueChange={onValueChange}
                    checkInput={checkInputs}
                />
        
        case "Button Single Select":
          return <ButtonSingleSelect
                    productInput={component}
                    productInputValue={productInputValue}
                    isInEditMode={isInEditMode}
                    getClassesForModifiers={getClassesForModifiers}
                    onValueChange={onValueChange}
                  />
  
        default:
          return <></>;
      }
    }
    else if (inputClassification === InputClassification.Configuration) {

      switch (configuration?.inputType.name) {
        case "Text":
          return <InputText
            configuration={configuration}
            productInputValue={productInputValue}
            isInEditMode={isInEditMode}
            getClassesForModifiers={getClassesForModifiers}
            containsFieldModifier={containsFieldModifier}
            onValueChange={onValueChange}
            validateValue={validateValue}
            checkInput={checkInputs}
            productVariantId={productVariantId}
            toBeNumeric={configurationKeysToBeNumericInput.includes(configuration?.configurationKey)}
        />;
  
        case "Numeric":
          return <NumericInput
                  configuration={configuration}
                  productInputValue={productInputValue}
                  isInEditMode={isInEditMode}
                  getClassesForModifiers={getClassesForModifiers}
                  onValueChange={onValueChange}
                  validateValue={validateValue}
                  checkInput={checkInputs}
                  productVariantId={productVariantId}
                  selectedQuantityOption={selectedQuantityOption}
                />;
        
        case "Address":
          return <AddressInput
                  configuration={configuration}
                  productInputValue={productInputValue}
                  isInEditMode={isInEditMode}
                  containsFieldModifier={containsFieldModifier}
                  getClassesForModifiers={getClassesForModifiers}
                  onValueChange={onValueChange}
                  checkInput={checkInputs}
                  productVariantId={productVariantId}
                />;
  
        case "Single Select Image and Text":
          return <InputSelectImageAndText
                    productInputValue={productInputValue}
                    onValueChange={onValueChange}
                  />;
        
        case "Radio Select Box Text Only":
          return <TextRadioSelect
                    basePrice={basePrice}
                    productTypes={productTypes}
                    productInputValue={productInputValue}
                    onValueChange={onValueChange}
                  />;
        
        case "CheckboxAccessory":
          return <CheckboxAccessory
                    configuration={configuration}
                    productInputValue={productInputValue}
                    isInEditMode={isInEditMode}
                    getClassesForModifiers={getClassesForModifiers}
                    containsFieldModifier={containsFieldModifier}
                    onValueChange={onValueChange}
                    onAccessoryChange={onAccessoryChange}
                    validateValue={validateValue}
                    checkInput={checkInputs}
                    siteProductId={siteProductId}
                    productVariantId={productVariantId}
                />;
        
        case "Colored Radio Select":
          return <ColoredRadioSelect
                  productInputValue={productInputValue}
                  onValueChange={onValueChange}
                />

        case "Button Revealing Text Input":
          return <ButtonRevealingTextInput
                  configuration={configuration}
                  productInputValue={productInputValue}
                  isInEditMode={isInEditMode}
                  getClassesForModifiers={getClassesForModifiers}
                  onValueChange={onValueChange}
                  productVariantId={productVariantId}
                />

        case "Button Single Select":
          return <ButtonSingleSelect
                  productInput={configuration}
                  productInputValue={productInputValue}
                  isInEditMode={isInEditMode}
                  getClassesForModifiers={getClassesForModifiers}
                  onValueChange={onValueChange}
                />

        case "Radio Select With Button Single Select":
          return <RadioSelectWithButtonSingleSelect
                  productInput={configuration}
                  productInputValue={productInputValue}
                  isInEditMode={isInEditMode}
                  getClassesForModifiers={getClassesForModifiers}
                  onValueChange={onValueChange}
                  checkInput={checkInputs}
                />

        case "Radio Select With Boxed Description":
          return <RadioSelectWithBoxedDescription
                  configuration={configuration}
                  productInputValue={productInputValue}
                  isInEditMode={isInEditMode}
                  getClassesForModifiers={getClassesForModifiers}
                  onValueChange={onValueChange}
                />

        case "PrintingSoftware":
          return <PrintingInput
                  configuration={configuration}
                  productInputValue={productInputValue}
                  isInEditMode={isInEditMode}
                  getClassesForModifiers={getClassesForModifiers}
                  onValueChange={onValueChange}
                  checkInput={checkInputs}
                  productVariantId={productVariantId}
                  consumerId={consumerId}
                />

        case "AddProductAccessory":
          return <AddProductAccessory
                  configuration={configuration}
                  productInputValue={productInputValue}
                  isInEditMode={isInEditMode}
                  getClassesForModifiers={getClassesForModifiers}
                  onValueChange={onValueChange}
                  onAccessoryChange={onAccessoryChange}
                  siteProductId={siteProductId}
                  productVariantId={productVariantId}
                ></AddProductAccessory>
        
        case "Hidden":
          return <HiddenInput productInputValue={productInputValue}/>

        default:
          return <></>;
      }
    }

  }

  return (
    <>
      {
        getSpecificInputTypeElement()
      }
    </>
  );
}