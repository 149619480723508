
import './RequiredToast.css';
import {Toast, ToastBody} from "reactstrap";
import {useEffect, useState} from "react";

interface RequiredToastProps {
    isOpen: boolean,
    dismissToast: () => void
}

export const RequiredToast = ({ isOpen, dismissToast }: RequiredToastProps) => {

    const [ showRequiredFieldToast, setShowRequiredFieldToast ] = useState(isOpen);

    useEffect(() => {
        setShowRequiredFieldToast(isOpen);
    }, [isOpen])

    return (
        <Toast isOpen={showRequiredFieldToast} className="required-fields-toast" onClick={dismissToast} title="Dismiss">
            <ToastBody>
                Please enter all required fields.
            </ToastBody>
        </Toast>
    )
}
