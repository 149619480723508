import {useEffect, useState} from "react";
import {ColumnConfig, GridConfig} from "../admin/shared/Grid"
import {GridPage} from "../admin/shared/GridPage"
import {TaxFile} from "./TaxItem"
import {SortState} from "../../utils/Grid";
import {reportCol, reportDateToString, reportTaxFileNameToFriendlyLink} from "../reports/ReportView";
import {DateTime} from "luxon";
import {useGetProcessedTaxFilesInRangeQuery} from "../../app/apiSlice";
import {Utils} from "../../utils/Utils";

interface ProcessedTaxFilesCriteria {
  fromDate: string;
  toDate: string;
}

export const ViewProcessedTaxFilesPage = () => {
  const [criteria, setCriteria] = useState<ProcessedTaxFilesCriteria>({ fromDate: DateTime.now().set({day: -1}).toISODate(),
    toDate: DateTime.now().toISODate()});
  const [defaultCriteria, setDefaultCriteria] = useState(criteria);
  const { data: queryTaxFiles, isFetching, refetch: refetchTaxFiles } = useGetProcessedTaxFilesInRangeQuery({
    fromDate: criteria?.fromDate,
    toDate: criteria?.toDate
  });
  const [ sort, setSort ] = useState<SortState<TaxFile>>({ column: 'key', direction: 'asc'});
  const [ sortedFiles, setSortedFiles ] = useState(queryTaxFiles);
    const onCriteriaChange = (newCriteria: ProcessedTaxFilesCriteria) => setCriteria(newCriteria);
  
    const onResetCriteria = () => {
    setCriteria(defaultCriteria);
    refetchTaxFiles();
  };
  
  useEffect(() => {
    setSortedFiles([...queryTaxFiles ?? []]?.sort(Utils.sortBy(sort.column, sort.direction)) ?? []);
  }, [sort, queryTaxFiles])
  
  const config: GridConfig<TaxFile> = {
    configSections: [
      {
        columns: [
          reportCol('key', 'Filename', reportTaxFileNameToFriendlyLink),
          reportCol('taxPeriodId', 'Tax Export ID'),
          reportCol('lastModified', 'Created On', reportDateToString),
          reportCol('createdByName', 'Created By'),
          reportCol('minDocumentDate', "Oldest Document Date", reportDateToString),
          reportCol('maxDocumentDate', "Latest Document Date", reportDateToString),

          // criteria field
          new ColumnConfig<TaxFile>({
            field: 'fromDate',
            display: 'From Date',
            isHidden: true,
            isSearchCriteria: true,
            type: 'single-date',
          }),
          new ColumnConfig<TaxFile>({
            field: 'toDate',
            display: 'To Date',
            isHidden: true,
            isSearchCriteria: true,
            type: 'single-date',
          })
        ]
      }
    ],
  }

  return <GridPage
    gridConfig={config}
    onCriteriaChange={onCriteriaChange}
    onResetCriteria={onResetCriteria}
    defaultCriteria={defaultCriteria}
    onShouldRefetch={() => { refetchTaxFiles(); }}
    title={`Processed Tax Files (${sortedFiles?.length ?? 0})`}
    records={isFetching ? [] : (sortedFiles ?? [])}
    sort={sort}
    isLoading={isFetching}
    onSortChanged={setSort}
    shouldShowCriteriaPanel={true}/>
}