import {Button, InputProps} from "reactstrap";
import { Link } from "react-router-dom";
import {ReactComponent as CartWhite} from '../../assets/icons/cart-white.svg';
import "./CartButton.css";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {forgetCartId, selectCartId} from "./CartSlice";
import {useGetCartExistsQuery, useGetCartProductsQuery} from "../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {useEffect} from "react";
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


interface CartButtonProps {
  isMobile: boolean
}

export const CartButton = ({ isMobile } : CartButtonProps) => {

    const cartId = useAppSelector(selectCartId);
    const { data: cartExists } = useGetCartExistsQuery(cartId ?? skipToken);
    const { data: cartProducts } = useGetCartProductsQuery(cartId);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (cartExists === false) {
            dispatch(forgetCartId());
        }
    }, [cartExists]);

    const getCartCount = () => {
        if (!cartProducts || cartProducts.length === 0) {
            return <></>;
        }
        return `(${cartProducts.length})`;
    }

  return (
    <Link to="/cart">
      {isMobile ? (
          <div className='mobile-cart-btn'>
            <FontAwesomeIcon icon={faCartShopping} />
            <span className='cart-count'>{getCartCount()}</span>
          </div>
      ) : (
        <Button color="primary" className='btn-primary'>
          <h6>Cart</h6>
          <FontAwesomeIcon icon={faCartShopping} />
          {getCartCount()}
        </Button>
      )}
    </Link>
  );
}