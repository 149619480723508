import React from "react";
import './WideMessage.css';

interface WideMessageProps {
    message: string
}
export const WideMessage = ({message}: WideMessageProps) => {

    return (
        <div className="wide-message-container">
            {message}
        </div>
    )
}
