import { FormFeedback, Input, InputProps } from "reactstrap"
import { ChangeEvent, useCallback, useEffect, useState } from "react";

interface RegexValidatedInputProps extends InputProps {
    regex?: RegExp
    // I could make these undefined-able, but considering we use controlled components, this is good to force
    // parents to follow best practices
    value: string,
    onChange: NonNullable<InputProps['onChange']>,
    
    clear?: boolean, // true if you need to clear out the value from the parent
    suppressError?: boolean // true if you want to hold off on showing error until the user submits
    invalidMessage?: string // message to display if invalid
}

/**
 * Input for strings with regex validation. Only emits valid strings and empty strings
 */
export const RegexValidatedInput = (props: RegexValidatedInputProps) => {
    const { clear, suppressError, invalidMessage, regex, ...parentProps } = props;
    const [ value, setValue ] = useState(props.value);
    
    const isValid = useCallback((val: string) =>
        regex?.test(val)
    , [regex]);
    
    // if parent wants to change value, they can, but it must be valid
    useEffect(() => {
        if (isValid(props.value)) {
            setValue(props.value)
        }
    }, [props.value, isValid]);

    // parent can clear value through clear prop
    useEffect(() => {
        if (clear) setValue('');
    }, [clear])
    
        
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue(newValue);
        
        // only emit valid values
        if (isValid(newValue)) {
            props.onChange(e);
            return;
        }
        
        // if parent thinks there's a value, clear it, parent will not get any more updates until we're valid
        if (props.value !== '') {
            // not sure why name doesn't pop out, so manually setting it so Utils.handle functions can pick it up
            props.onChange({ ...e, target: { ...e.target, name: e.target.name, value: ''} });
        }
    }

    return <>
        <Input {...parentProps}
               invalid={props.invalid || ((!props.suppressError) && !isValid(value))}
               onChange={onChange}
               value={value}/>
        <FormFeedback>{props.invalidMessage}</FormFeedback>
    </>;
}