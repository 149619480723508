import {Input, Label} from "reactstrap";
import "./EditPayment.css";
import {
    useGetPaymentMethodsQuery,
    useSavePaymentDetailsMutation,
    useUpdateSavedPaymentDetailsMutation
} from "../../../app/apiSlice";
import {useEffect, useState} from "react";
import {PaymentDetailsEntry} from "../detailsEntry/PaymentDetailsEntry";
import {PaymentDetails} from "../PaymentDetails";
import {Address} from "../../input/address/Address";
import {BillingAddress} from "../../admin/order/BillingAddress";
import {Consumer} from "../../admin/customer/Consumer";
import {SavedPaymentDetails} from "../SavedPaymentDetails";
import {CreditCardPaymentDetails} from "../CreditCardPaymentDetails";
import {
    Regexes,
    validateCCFullNameString,
    validateCVCString,
    validateExpirationDateString,
} from "../../../utils/Utils";
import {useAppDispatch, useValidation} from "../../../app/hooks";
import {showInformationModal} from "../../modal/ModalSlice";

interface EditPaymentProps {
    consumer?: Consumer,
    savedPaymentDetails?: SavedPaymentDetails,
    cancelEditPayment?: () => void;
}

export const EditPayment = ({consumer, savedPaymentDetails, cancelEditPayment}: EditPaymentProps) => {
    const {data: paymentMethods} = useGetPaymentMethodsQuery();
    const [ selectedPaymentMethodId, setSelectedPaymentMethodId ] = useState<number | undefined>(undefined);
    const [ billingAddress, setBillingAddress ] = useState<Address | undefined>(savedPaymentDetails?.billingAddress);
    const [ savePaymentDetails, {isLoading: isSavingPaymentDetails} ] = useSavePaymentDetailsMutation();
    const [ updateSavedPaymentDetails , {isLoading: isUpdatingPaymentDetails}] = useUpdateSavedPaymentDetailsMutation();
    const [ creditCardPaymentDetailsEdit, setCreditCardPaymentDetailsEdit ] = useState<CreditCardPaymentDetails | undefined>();
    const dispatch = useAppDispatch();
    
    const required = (name: string) => (val?: string) => !val ? `${name} is required.` : undefined;
    const validation = useValidation({
        firstName: required('First Name'),
        lastName: required('Last Name'),
        fullName: val => validateCCFullNameString(val),
        cardNumber: val => !val || !Regexes.ccWithFormatting.test(val) ? 'Please enter a valid card number' : undefined,
        cardExpiration: val => validateExpirationDateString(val),
        cvc: val => validateCVCString(val)
    });

    useEffect(() => {
        if (paymentMethods?.[0]) {
            setSelectedPaymentMethodId(paymentMethods[0].id);
        }
    }, [paymentMethods]);

    useEffect(() => {
        if (!selectedPaymentMethodId) {
            const firstPaymentMethodId = paymentMethods && paymentMethods.length > 0 ? paymentMethods[0].id : -1;
            setSelectedPaymentMethodId(firstPaymentMethodId);
        }
    }, [paymentMethods]);

    const onPaymentDetailsChanged = (paymentDetails?: PaymentDetails) => {
        const creditCardPaymentDetails = paymentDetails as CreditCardPaymentDetails;
        setCreditCardPaymentDetailsEdit({
            ...creditCardPaymentDetails
        });
    }
    const onSavePaymentDetailsClicked = async () => {
        if (validation.allValid()) {
            // If updating, update
            if (savedPaymentDetails?.paymentProfileId)
                return await updatePaymentDetails();
            // Else create
            return await createPaymentDetails();
        }
    }

    const updatePaymentDetails = async () => {

        if (!creditCardPaymentDetailsEdit || !consumer)
            return;
        try {
            await updateSavedPaymentDetails({
                customerPaymentProfileId: savedPaymentDetails?.paymentProfileId,
                paymentDetails: creditCardPaymentDetailsEdit,
                userId: consumer.userId
            }).unwrap();
            dispatch(showInformationModal({
                title: "Saved!",
                text: "Payment method updated.",
            }));
            cancelEditPayment?.();
        } catch (e) {
            dispatch(showInformationModal({
                title: "Failed to Save!",
                text: "Failed to update payment method.",
            }));
        }
    }

    const createPaymentDetails = async () => {
        if (!creditCardPaymentDetailsEdit || !consumer)
            return;
        try {
            const newPaymentProfileId = await savePaymentDetails({
                paymentDetails: creditCardPaymentDetailsEdit,
                userId: consumer.userId
            }).unwrap();

            if (newPaymentProfileId) {
                dispatch(showInformationModal({
                    title: "Saved!",
                    text: "Payment method saved.",
                }));
                cancelEditPayment?.();
            }
            else {
                dispatch(showInformationModal({
                    title: "Failed to Save!",
                    text: "Failed to save payment method. It's possible this payment method may already be saved.",
                }));
            }
        } catch (e) {
            dispatch(showInformationModal({
                title: "Failed to Save!",
                text: "Failed to save payment method.",
            }));
        }
    }

    const isOptionSelected = (paymentMethodId: number) => {
        return paymentMethodId === selectedPaymentMethodId;
    }

    const getPaymentMethodsRadioControl = () => {
        if (!paymentMethods) {
            return <></>;
        }
        return (
            <span className="new-payment-option-container">
                {paymentMethods.map((paymentMethod, index) => {
                    return (
                        <span key={'radio-button-payment-method-select-' + paymentMethod.id}>
                            <Input
                                name="payment-method-selection"
                                id={`PaymentMethod${paymentMethod.id}`}
                                type="radio"
                                value={paymentMethod.id}
                                checked={isOptionSelected(paymentMethod.id)}
                                className="radio-option-input"
                                onChange={() => setSelectedPaymentMethodId(paymentMethod.id)}
                            />
                            <Label check for={`PaymentMethod${paymentMethod.id}`}>
                                {paymentMethod.name}
                            </Label>
                        </span>);
                })}
            </span>
        )
    }

    const getSelectedPaymentMethod = () => {
        const paymentMethodIndex = paymentMethods?.findIndex(pm => pm.id === selectedPaymentMethodId);
        return !!(paymentMethods && paymentMethodIndex !== undefined && paymentMethods[paymentMethodIndex])
            ? paymentMethods[paymentMethodIndex] : undefined;
    }

    const onBillingChanged = (address?: Address) => {
        setBillingAddress(address);
    }

    const getPaymentMethodEntry = () => {
        return (
            <span className="new-payment-container">
                <BillingAddress
                    consumerId={consumer?.id}
                    address={billingAddress}
                    onAddressChanged={onBillingChanged}
                    useCardContainer={false}/>
                {getPaymentMethodsRadioControl()}
                <PaymentDetailsEntry billingAddress={billingAddress}
                                     paymentMethod={getSelectedPaymentMethod()}
                                     validation={validation}
                                     isSavingPaymentDetails={isSavingPaymentDetails || isUpdatingPaymentDetails}
                                     allowSavingPaymentType={true}
                                     savedPaymentDetails={savedPaymentDetails}
                                     onPaymentDetailsChanged={onPaymentDetailsChanged}
                                     onSavePaymentDetailsClicked={onSavePaymentDetailsClicked}></PaymentDetailsEntry>
            </span>
        );
    }

    return (
        getPaymentMethodEntry()
    );
}