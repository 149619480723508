import {ProductInputValue} from "./ProductInputValue";
import {Input} from "reactstrap";

interface HiddenInputProps {
    productInputValue?: ProductInputValue,
}
export const HiddenInput = ({productInputValue}: HiddenInputProps) => {
    return (
        <Input type='hidden' value={productInputValue?.value ?? ''}/>
    )
}