import {ComponentOptionValuesEnum, OrderProductVm} from "../../../../order/OrderProductVm";
import {
    Badge, Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, FormFeedback,
    FormGroup,
    Input, Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader
} from "reactstrap";
import {ReactComponent as SquareCheck} from '../../../../../assets/icons/square-check.svg';
import React, {ChangeEvent, useState} from "react";
import { CurrencyFormatter } from "../../../../../utils/CurrencyFormatter";
import {
  useCreateOrderEditCartMutation,
  useGetOrderProductPricingQuery,
  useGetSiteProductVariantQuantityOptionsQuery, useUpdateOrderProductMutation
} from "../../../../../app/apiSlice";
import './OrderProductRow.css';
import {useAppDispatch, useAppSelector, useValidation} from "../../../../../app/hooks";
import { selectEditedQuantityValue, setOrderProductQuantity } from "../../../../order/EditOrderSlice";
import {OrderProductUnitDetail} from "./orderProduct/unitDetail/OrderProductUnitDetail";
import {useNavigate} from "react-router-dom";
import {VendorOrderProductStatus, VendorStatusName} from "../../../vendor/VendorOrderProductStatus";

interface OrderProductRowProps {
  orderProduct: OrderProductVm,
  locked?: boolean,
  vendorProductStatus?: VendorOrderProductStatus,
  onQuantityOptionChange: (quantityOptionId: number) => void,
  onEditClick: (orderProduct: OrderProductVm) => void,
  onViewConfigurationClick: (orderProduct: OrderProductVm) => void,
}
export const OrderProductRow = ({ orderProduct, locked, vendorProductStatus, onQuantityOptionChange, onEditClick, onViewConfigurationClick }: OrderProductRowProps) => {
  const dispatch = useAppDispatch();
  const { data: quantityOptions } = useGetSiteProductVariantQuantityOptionsQuery(orderProduct.siteProductVariantId);
  const { data: pricing } = useGetOrderProductPricingQuery(orderProduct.id);
  const [ updateOrderProduct ] = useUpdateOrderProductMutation();
  const [ createOrderCart ] = useCreateOrderEditCartMutation();
  const navigate = useNavigate();
  const editedQuantity = useAppSelector(s => selectEditedQuantityValue(s, orderProduct.id));
  const [ notes, setNotes ] = useState<string>('');
  const [ isOpen, setIsOpen ] = useState(false);
  const [ openModal, setOpenModal ] = useState<'Do Not Ship' | 'Do Not Re-Order' | ''>('');
  const orderEditCartId = useAppSelector(state => state.cart.orderEditCart?.cartId);
  const toggle = () => setIsOpen(!isOpen);
  const required = (name: string) => (val?: string) => !val ? `${name} are required.` : undefined;
  const validation = useValidation({
      notes: required('Notes')
  });

  const updateNotes = (e: ChangeEvent<HTMLInputElement>) => {
      validation.setValue('notes', e.target.value);
      setNotes(e.target.value);
  }
    
  const openNotesModal = (modalName: 'Do Not Ship' | 'Do Not Re-Order' | '') => {
      setOpenModal(modalName);
      setIsOpen(true);
  }
    
  const closeNotesModal = () => {
      setOpenModal('');
      setIsOpen(false);
      setNotes('');
      validation.setValue('notes', '');
  }

  const [ unitModalIsOpen, setUnitModalIsOpen ] = useState(false);
  const [ dropDownOpen, setDropDownOpen ] = useState(false);
  const toggleDropdown = () => setDropDownOpen(old => !old);
  const toggleUnitModalIsOpen = () => setUnitModalIsOpen(!unitModalIsOpen);

  const setQuantity = (quantityId: number) => {
    dispatch(setOrderProductQuantity({ orderProductId: orderProduct.id, siteProductVariantQuantityOptionId: quantityId}));
    onQuantityOptionChange(quantityId)
  }

    const onSetClearDoNoShipClicked = async() => {
        if (!validation.allValid()) {
            return;
        }
        await updateOrderProduct({orderProductIds: [orderProduct.id], doNotShip: !orderProduct.doNotShip, notes: notes})
        closeNotesModal();
    }

    const onSetClearDoNoReorderClicked = async() => {
        if (!validation.allValid()) {
            return;
        }
        await updateOrderProduct({orderProductIds: [orderProduct.id], doNotReorder: !orderProduct.doNotReorder, notes: notes})
        closeNotesModal();
    }

    const onReorderClicked = async () => {
        try {
            const cartId = await createOrderCart({orderId: orderProduct.orderId, orderProductId: orderProduct.id, isReorder: true}).unwrap();
            navigate(`/admin/order/new?cartId=${cartId}`);
        } catch (e:any) {
            window.alert('Sorry! We were unable to start this reorder!');
            console.error(JSON.stringify(e));
        }
    }

  const UnitModal = () => (
      <Modal size='xl' isOpen={unitModalIsOpen} toggle={toggleUnitModalIsOpen}>
          <ModalHeader toggle={toggleUnitModalIsOpen}><h4>Unit Detail - {orderProduct.siteProductVariantName}</h4></ModalHeader>
        <ModalBody>
          <OrderProductUnitDetail orderProduct={orderProduct} vendorProductStatus={vendorProductStatus}/>
        </ModalBody>
      </Modal>
  )

    const shouldDisableEdits = () => {
      return (orderProduct.isAcknowledged && !orderProduct.isCancelled) || orderEditCartId === undefined;
    }

   const shouldDisableDoNotShip = () => {
      return vendorProductStatus &&
          [VendorStatusName.Shipped.toString(), VendorStatusName.Finalized.toString(), VendorStatusName.Canceled.toString()]
              .includes(vendorProductStatus.vendorOrderProductStatusName);
   }

  return (
      <>
          <tr className="order-product-row">
              <td>{orderProduct.sku}</td>
              <td>{orderProduct.siteProductVariantName}</td>
              <td>{orderProduct.componentOptionValues.find(co => co.includes(ComponentOptionValuesEnum.Single) || co.includes(ComponentOptionValuesEnum.Duplicates)) ?? 'N/A'}</td>
              <td>
                <Input type='select' disabled={locked || shouldDisableEdits()} value={orderEditCartId ? editedQuantity : orderProduct.quantityOption.id} onChange={e => setQuantity(Number.parseInt(e.target.value))}>
                      { quantityOptions?.map(qo =>
                          <option key={qo.id} value={qo.id}>{qo.description} - {CurrencyFormatter.format(qo.price)} { !qo?.isActive ? "(Inactive)" : "" }</option>
                      )}
                  </Input>
              </td>
              <td>{pricing?.retailSubTotalPrice && CurrencyFormatter.format(pricing.retailProductPrice)}</td>
              <td className="d-flex align-items-center justify-content-between">
                  <Dropdown isOpen={dropDownOpen} toggle={toggleDropdown}>
                      <DropdownToggle caret>Actions</DropdownToggle>
                      <DropdownMenu>
                          {orderProduct.isConfigurable &&
                            <DropdownItem disabled={locked || shouldDisableEdits()} onClick={() => onEditClick(orderProduct)}>Customize</DropdownItem>
                          }
                          {orderProduct.isConfigurable &&
                            <DropdownItem onClick={() => onViewConfigurationClick(orderProduct)}>View Configuration</DropdownItem>
                          }
                          <DropdownItem onClick={() => setUnitModalIsOpen(true)}>View Units</DropdownItem>
                          <DropdownItem disabled={orderProduct.doNotReorder} onClick={() => onReorderClicked()}>Re-Order</DropdownItem>
                          <DropdownItem onClick={() => openNotesModal('Do Not Re-Order')}>Set/Clear Do Not Re-order</DropdownItem>
                          <DropdownItem disabled={shouldDisableDoNotShip()} onClick={() => openNotesModal('Do Not Ship')}>Set/Clear Do Not Ship</DropdownItem>
                      </DropdownMenu>
                  </Dropdown>
                  {orderProduct.doNotShip && <Badge color="danger">Do Not Ship</Badge>}
                  {orderProduct.doNotReorder && <Badge color="danger">Do Not Reorder</Badge>}
                  <span>{orderProduct.isCancelled ?
                      <Badge color="danger">Cancelled</Badge>
                      : (orderProduct.isAcknowledged ?
                      <div className="order-product-row-svg" title="Vendor has acknowledged the product"><SquareCheck/></div> :
                      <></>)}
                  </span>
              </td>
          </tr>
          {UnitModal()}

          <Modal isOpen={isOpen} toggle={toggle}>
              <ModalHeader toggle={toggle}>{openModal}</ModalHeader>
              <ModalBody>
                  <FormGroup>
                      <Label for='Notes'>Notes</Label>
                      <Input required
                             id='notes'
                             name='notes'
                             type='textarea'
                             value={notes ?? ''}
                             invalid={validation?.getError('notes') !== undefined}
                             onChange={(e) => updateNotes(e)} />
                      <FormFeedback>{ validation?.getError('notes') }</FormFeedback>
                  </FormGroup>
              </ModalBody>
              <ModalFooter>
                  <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                  <Button id="SubmitButton" onClick={openModal === 'Do Not Ship' ? onSetClearDoNoShipClicked : onSetClearDoNoReorderClicked} color='primary'>Update</Button>
              </ModalFooter>
          </Modal>
      </>
  )
}