
import './ACHRejectionNew.css';
import {Alert, Button, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React, {useEffect, useState} from "react";
import {
    useCreateACHRejectionMutation,
    useGetACHRejectionCodesQuery,
    useGetOrderPaymentHistoryQuery
} from "../../../app/apiSlice";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {DateTime} from "luxon";
import {PaymentMethodName} from "../PaymentMethod";
import { refetchAllHoldFlags } from '../../admin/holdsQueue/HoldFlagSlice';
import { getErrorMessage } from '../../../utils/Utils';
import { useAppDispatch } from '../../../app/hooks';

interface ACHRejectionNewProps {
    orderId: number,
    isOpen: boolean,
    toggleModal: () => void,
}

export const ACHRejectionNew = ({orderId, isOpen, toggleModal}: ACHRejectionNewProps) => {
    const [ createACHRejection ] = useCreateACHRejectionMutation();
    const { data: achRejectionCodes } = useGetACHRejectionCodesQuery();
    const { data: orderPayments } = useGetOrderPaymentHistoryQuery(orderId);
    const [ achRejectionCodeId, setACHRejectionCodeId ] = useState<number>();
    const [ orderPaymentId, setOrderPaymentId ] = useState<number>();
    const [ errorMessage, setErrorMessage ] = useState('');
    const dispatch = useAppDispatch();
    
    // when achRejectionCodes load, set selected code
    useEffect(() => {
        setACHRejectionCodeId(achRejectionCodes?.[0]?.id);
    }, [achRejectionCodes])

    // when payments load, set selected paymnent
    useEffect(() => {
        setOrderPaymentId(orderPayments?.[0]?.id);
    }, [orderPayments])
    
    const save = async () => {
        setErrorMessage('');
        if (!achRejectionCodeId || !orderPaymentId) {
            setErrorMessage('Please select a Code and an Order Payment.');
            return;
        }

        try {
            await createACHRejection({
                orderId,
                achRejectionCodeId,
                paymentId: orderPaymentId,
            }).unwrap();
            dispatch(refetchAllHoldFlags({}))
            toggleModal();
        } catch (e: any) {
            setErrorMessage(getErrorMessage(e) ?? 'Unknown error occurred. Please try again.');
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggleModal} size='xl'>
            <ModalHeader toggle={toggleModal} close={<div><button className='btn-close' onClick={toggleModal}/></div>}>New ACH Rejection</ModalHeader>
            <ModalBody>
                <Container>
                <FormGroup>
                        <Label for='ACHRejectionCode'>Codes</Label>
                        <Input id='ACHRejectionCode' name='achRejectionCode'  type="select" onChange={(e) => setACHRejectionCodeId(parseInt(e.target.value, 10))} value={achRejectionCodeId}>
                            { achRejectionCodes?.map(achrc => <option key={`${achrc.id}-ach`} value={achrc.id}>{`${achrc.code} - ${achrc.name}`}</option>) }
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label for='OrderPayments'>Order Payments</Label>
                        <Input id='OrderPayments' name='orderPayments'  type="select" onChange={(e) => setOrderPaymentId(parseInt(e.target.value, 10))} value={orderPaymentId}>
                            { orderPayments?.filter(op => op.paymentMethod === PaymentMethodName.ACH)?.map(op => (
                                <option key={`${op.id}-op`} value={op.id}>
                                    {`${CurrencyFormatter.format(op.amount)} - ${DateTime.fromISO(op.timeOfPaymentBegan + 'Z').toLocaleString(DateTime.DATETIME_SHORT)}`}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    { errorMessage && <Alert color='danger'>{errorMessage}</Alert> }
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={save}>Create</Button>
                <Button color='secondary' onClick={toggleModal}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}