import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { OrderProductQuantityChange, UpdateOrderVm } from "./UpdateOrderVm";


const initialState: UpdateOrderVm = {
  
};

export const editOrderSlice = createSlice({
  name: 'editOrder',
  initialState,
  reducers: {
    setOrderProductQuantity: (state, {payload}: PayloadAction<OrderProductQuantityChange>) => {
      const ix = state.quantityChanges?.findIndex(op => op.orderProductId === payload.orderProductId) ?? -1;
      if (state.quantityChanges === undefined)
        state.quantityChanges = [ payload ];
      else if (ix === -1)
        state.quantityChanges.push(payload);
      else
        state.quantityChanges[ix] = payload;
    },
  }
});

export const selectEditedQuantityValue = (state: RootState, orderProductId: number): number | undefined =>
  state.editOrder.quantityChanges?.find(qc => qc.orderProductId === orderProductId)?.siteProductVariantQuantityOptionId;

export const { setOrderProductQuantity } = editOrderSlice.actions;

export default editOrderSlice.reducer;