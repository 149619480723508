import { OrderProductMix } from "./OrderProductMix";
import { CellDisplayFunc, ColumnConfig } from "../../admin/shared/Grid";
import { reportCol, reportMoneyToString, ReportView } from "../ReportView";
import { useGetOrderProductMixReportQuery } from "../../../app/apiSlice";

export const OrderProductMixReport = () => {
  const filterFunc = (lowerSearch: string, report: OrderProductMix[]) =>
    report.filter(r =>
      r.productCategory.toLowerCase().includes(lowerSearch) ||
      r.brand.toLowerCase().includes(lowerSearch)
    );
  
  const reportPercent: CellDisplayFunc<OrderProductMix> = val =>
    val !== undefined && typeof(val) === 'number' ? `${val.toFixed(2)}%` : '';
  
  const columns: ColumnConfig<OrderProductMix>[] = [
    reportCol('productCategory', 'Product Category'),
    reportCol('brand', 'Brand'),
    reportCol('totalUnitsCompleted', 'Total Units Completed'),
    reportCol('unitPercent', 'Unit %', reportPercent),
    reportCol('totalOfSales', 'Total of Sales', reportMoneyToString),
    reportCol('salesPercent', 'Sales %', reportPercent)
  ];
  
  return <ReportView
    title='Order Product Mix'
    query={useGetOrderProductMixReportQuery}
    columns={columns}
    filterFunc={filterFunc}
    initialSort={{column: 'productCategory', direction: 'asc'}}
    downloadPath='/api/Reports/OrderProductMix'
    downloadName='CW_Order_Product Mix'/>
}