import React from "react";
import {RelatedOrdersBrowse} from "./related/RelatedOrdersBrowse";

interface RelatedOrdersTabProps {
    orderId: number
}

export const RelatedOrdersTab = ({orderId}: RelatedOrdersTabProps) => {

    return (
        <RelatedOrdersBrowse orderId={orderId}></RelatedOrdersBrowse>
    );

}