import {SiteProduct} from '../Product';
import {Card, CardBody, CardTitle} from 'reactstrap';
import './ProductPreview.css';
import {Link} from 'react-router-dom';
import {DisplayImage} from "../../image/display/DisplayImage";
import {SiteCategory} from "../../category/Category";

interface ProductPreviewProps {
  product: SiteProduct,
  siteCategory?: SiteCategory,
  contextualSiteCategory?: SiteCategory,
  tagText?: string
}

export const ProductPreview = ({ product, siteCategory, tagText, contextualSiteCategory }: ProductPreviewProps) => {

  function getPromotionCardImage() {
    if (!product.thumbnailId &&  !product.imageId) {
      return <></>
    }

    return (
        <span className="product-preview-image">
          <DisplayImage imageId={product.thumbnailId ?? product.imageId} shouldLimit={false}></DisplayImage>
        </span>
    );
  }
  
  function getProductUrl() {
    if (siteCategory?.urlName) return `/category/${siteCategory.urlName}/${product.urlName}`;
    if (contextualSiteCategory?.urlName) return `/category/${contextualSiteCategory.urlName}/${product.urlName}`
    return `/product/${product.urlName}`;
  }

  return (
    <div className="product-preview-container" >
      <Link to={getProductUrl()}> 

        <Card className="product-preview-card">
          {getPromotionCardImage()}
          <CardBody>
            <CardTitle tag="span">
              <div>
                <p className='product-preview-category'>{tagText ?? product.primaryCategoryName}</p>
                <h5 className='product-preview-name'>{product.name}</h5>
              </div>
            </CardTitle>
          </CardBody>
        </Card>
      </Link>
    </div>
  );
}