import './CaliforniaProp.css';
import {useTranslation} from "react-i18next";
import ScrollToTop from '../../helpers/ScrollToTop';
import { Breadcrumb } from '../../breadcrumb/Breadcrumb';
import {Container} from 'reactstrap';
import {Helmet} from "react-helmet-async";

export const CaliforniaProp = () => {
    const { t } = useTranslation();

    function getDefaultDocumentMetadata () {
        return (
            <Helmet>
              <title>{t(`pages.californiaProp.metaTitle`)}</title>
              <meta name="description" content={t(`pages.californiaProp.metaDescription`)} />
            </Helmet>
        )
      }
    

    return (
        <span className='ca-prop-page d-flex flex-column'>
            <ScrollToTop></ScrollToTop>
            {getDefaultDocumentMetadata()}
            <Container>
                <Breadcrumb currentPage={t(`pages.californiaProp.title`)}></Breadcrumb>
                <span className='ca-prop-container d-flex flex-column'>
                    <span className='ca-prop-title d-flex'>
                        <h1 className=''>{t(`pages.californiaProp.title`)}</h1>
                    </span>
                    <span className='ca-prop-body d-flex flex-column flex-row'>
                        <span className='ca-prop-body-text d-flex'>
                            {t(`pages.californiaProp.body1`)}
                        </span>
                        <span className='ca-prop-body-text'>
                            {t(`pages.californiaProp.body2`)}
                            <a href={'https://' + t(`pages.californiaProp.link`)}  rel="noreferrer" className='ca-prop-body-link'>
                                {t(`pages.californiaProp.link`)}
                            </a>.
                        </span>
                    </span>
                </span>
            </Container>
        </span>
    );
};