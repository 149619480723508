import './TrackForm.css';
import { useTranslation } from "react-i18next";
import { Alert, Button, Form, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { ChangeEvent, useState } from 'react';
import { useValidateTrackingInformationMutation } from '../../../../../app/apiSlice';
import {OrderStatus, OrderTrackingFetch} from '../../../Order';
import {ValidateTrackingResponse} from "../../ValidateTrackingResponse";

interface TrackingFormErrors {
    orderId?: string;
    zip?: string;
}

interface TrackingFormState {
    orderId: string;
    zip: string;
    errors: TrackingFormErrors;
}

export const TrackForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [validateMethod, response] = useValidateTrackingInformationMutation();
    const [trackingMessage, setTrackingMessage] = useState('')
    const [formState, setFormState] = useState<TrackingFormState>({
        orderId: '',
        zip: '',
        errors: {
            orderId: '',
            zip: ''
        }
    });

    const onorderIdChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({ ...prev, orderId: e.target.value }));
        validateorderId(e.target.value);
    }

    const onZipChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({ ...prev, zip: e.target.value }));
        validateZip(e.target.value);
    }

    const validateorderId = (orderId: string) => {
        const orderIdRegex = RegExp('^[0-9]{1,8}$')
        
        if (!orderIdRegex.test(orderId)) {
            formState.errors.orderId = t(`tracking.trackingForm.errors.orderId`);
        } else {
            formState.errors.orderId = '';
        }
    }

    const validateZip = (zip: string) => {
        const zipRegex = RegExp('^\\d{5}(?:[-\\s]\\d{4})?$');

        if (!zipRegex.test(zip)) {
            formState.errors.zip = t(`tracking.trackingForm.errors.zipCode`);
        } else {
            formState.errors.zip = '';
        }
    }

    const onTrackingSubmit = async () => {
       validateZip(formState.zip);
       validateorderId(formState.orderId);
       const errorsNull = Object.values(formState.errors).every(x => x === null || x === '');

       if (errorsNull) {
            const validateTrackingFetch: OrderTrackingFetch = {
                orderId: Number(formState.orderId),
                zip: formState.zip
            };

            try {
                const validateTrackingResponse = await validateMethod(validateTrackingFetch).unwrap();

                if (shouldNavigateToTrackingView(validateTrackingResponse)) {
                    navigate(`/track-order/order-details/${formState.orderId}/${formState.zip}`);
                }
                else {
                    setTrackingMessage(getValidationMessage(validateTrackingResponse));
                }
            } catch (err) {
                console.error(err);
            }
        }
    }

    const shouldNavigateToTrackingView = (validateTrackingResponse: ValidateTrackingResponse) => {
        return validateTrackingResponse?.foundOrder &&
            validateTrackingResponse.haveAnyOrderProductsShipped;
    }

    const getValidationMessage = (validateTrackingResponse: ValidateTrackingResponse) => {

        switch (validateTrackingResponse.orderStatus?.toLowerCase()) {
            case OrderStatus.NewOrder.toLowerCase():
            case OrderStatus.Manufacturing.toLowerCase():
                return t('tracking.trackingForm.notYetShippedMessage');
            case OrderStatus.Hold.toLowerCase():
                return t('tracking.trackingForm.orderOnHoldMessage');
            default:
                return t('tracking.trackingForm.orderNotFound');
        }
    }

    const getValidationTrackingError = () => {
        if (trackingMessage) {
            return (
                <Alert color={'danger'}>
                    <span>{trackingMessage}</span>
                </Alert>
            );
        } else {
            return <></>
        }
    }
    
    return (
        <span className='track-form-content d-flex flex-column'>
            <span className='track-form-title'>{t(`tracking.trackingForm.title`)}</span>
            <Form>
                <FormGroup>
                    <Label htmlFor='orderId'>{t(`tracking.trackingForm.orderIdLabel`)}</Label>
                    <Input id="orderId" type='text' onChange={onorderIdChange} invalid={!!formState.errors.orderId} />
                    <FormFeedback>{formState.errors.orderId}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor='zip'>{t(`tracking.trackingForm.zipCodeLabel`)}</Label>
                    <Input id="zip" maxLength={10} type='text' onChange={onZipChange} invalid={!!formState.errors.zip} />
                    <FormFeedback>{formState.errors.zip}</FormFeedback>
                </FormGroup>
                {getValidationTrackingError()}
                <span className='container'>
                    <span className="mt-1 d-flex">
                        <Button type='button' onClick={onTrackingSubmit} color='primary'>{t(`tracking.trackingForm.button`)}</Button>
                    </span>
                </span>
            </Form>
        </span>
    );
  }