export interface Payment {
    id: number,
    paymentMethodId: number,
    paymentStatusId: number,
    amount: number,
    timeOfPaymentBegan: string,
    timeOfPaymentEnd?: string,
    vendorTransactionId?: string,
    invoiceId: string,
    lastFourDigits: string,
    orderId?: number,
    orderProductId?: number
}

export enum PaymentStatus {
    Complete = "Complete",
    Pending = "Pending",
    Failed = "Failed"
}