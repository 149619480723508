import React from "react";
import "./OrderEditCartComparison.css";
import {CurrencyFormatter} from "../../../../../../../utils/CurrencyFormatter";
import {CartPricing, SiteProductVariantPricing} from "../../../../../../cart/CartPricing";
import {OrderPricing} from "../../../../../../order/OrderPricing";
import {OrderEditCartDetails} from "../../../../../../order/Order";

interface OrderEditComparisonPricing {
    orderProductPricing?: SiteProductVariantPricing,
    cartProductPricing?: SiteProductVariantPricing
}
interface OrderEditProductComparisonProps {
    orderEditCartDetails: OrderEditCartDetails,
    orderPricing: OrderPricing,
    cartPricing: CartPricing
}
export const OrderEditProductComparison = (props: OrderEditProductComparisonProps) => {

    const orderMap = props.orderPricing.siteProductVariantTotals?.map(ospvt => {
        return {
            orderProductPricing: ospvt,
            cartProductPricing: props.cartPricing.siteProductVariantTotals?.find(cspvt => cspvt.orderProductId === ospvt.orderProductId)
        }
    }) ?? [];
    const cartMap = props.cartPricing.siteProductVariantTotals?.filter(cspvt => !cspvt.orderProductId)
        .map(cspvt => {
            return {
                orderProductPricing: undefined,
                cartProductPricing: cspvt
            }
        }) ?? [];
    const orderEditComparisonPricing = [...orderMap, ...cartMap];

    const getPriceToDisplay = (price: number = 0, comparisonPrice: number = 0, indicateDelta: boolean) => {
        const isHigher = price > comparisonPrice;
        const isLower = price < comparisonPrice;
        const deltaDisplayClass = indicateDelta ?
            (isHigher ? 'order-product-comparison-pricing-higher' : (isLower ? 'order-product-comparison-pricing-lower' : '')) :
            '';
        const deltaPrice = price - comparisonPrice;
        const deltaIndicator = deltaPrice < 0 ? '' : '+';
        return (
            <span className='order-product-comparison-pricing'>
                <span>{CurrencyFormatter.format(comparisonPrice)}</span>
                <span>
                    {`${deltaIndicator} ${CurrencyFormatter.format(deltaPrice)}`}
                </span>
                <hr className="low-margin-horizontal-bar"/>
                <span className={deltaDisplayClass}>
                    {CurrencyFormatter.format(price)}
                </span>
            </span>
        );
    }
    const ComparisonDetails = (orderCartPricing: OrderEditComparisonPricing) => {
        return ProductDetails(orderCartPricing.cartProductPricing, orderCartPricing.orderProductPricing, true);
    }

    const ProductDetails = (productPricing?: SiteProductVariantPricing, comparisonPricing?: SiteProductVariantPricing, indicateDelta: boolean = true) => {
        return (
            <>
                <div className="order-edit-product-comparison-grid">
                    {indicateDelta &&
                      <div className='order-edit-product-comparison-pricing-details-container'>
                        <h5>{productPricing?.name}</h5>
                    </div>}
                    <table className='table table-bordered mb-0'>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Base Price</th>
                            <th>EZ Shield</th>
                            <th>Woodcut</th>
                            <th>Custom Message</th>
                            <th>Extra Signature Line</th>
                            <th>Font</th>
                            <th>Tax</th>
                            <th>Shipping</th>
                            <th>Handling</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <span className='order-product-comparison-pricing'>
                                    <span><b>Original</b></span>
                                    <span><b>Change</b></span>
                                    <hr className="low-margin-horizontal-bar"/>
                                    <span><b>Current</b></span>
                                </span>
                            </td>
                            <td>{getPriceToDisplay(productPricing?.basePrice, comparisonPricing?.basePrice, indicateDelta)}</td>
                            <td>{getPriceToDisplay(productPricing?.ezShield, comparisonPricing?.ezShield, indicateDelta)}</td>
                            <td>{getPriceToDisplay(productPricing?.woodCut, comparisonPricing?.woodCut, indicateDelta)}</td>
                            <td>{getPriceToDisplay(productPricing?.customMessage, comparisonPricing?.customMessage, indicateDelta)}</td>
                            <td>{getPriceToDisplay(productPricing?.extraSigLine, comparisonPricing?.extraSigLine, indicateDelta)}</td>
                            <td>{getPriceToDisplay(productPricing?.font, comparisonPricing?.font, indicateDelta)}</td>
                            <td>{getPriceToDisplay(productPricing?.taxAmount, comparisonPricing?.taxAmount, indicateDelta)}</td>
                            <td>{getPriceToDisplay(productPricing?.shippingPrice, comparisonPricing?.shippingPrice, indicateDelta)}</td>
                            <td>{getPriceToDisplay(productPricing?.handling, comparisonPricing?.handling, indicateDelta)}</td>
                            <td>{getPriceToDisplay(productPricing?.total, comparisonPricing?.total, indicateDelta)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    };

    const getPriceBreakdown = () => {
        return orderEditComparisonPricing.map((oecp, index) => {
            return (
                <span key={index}>
                    {ComparisonDetails(oecp)}
                </span>
            )
        });
    }

    return (
        <>
            {getPriceBreakdown()}
        </>
    );
}
