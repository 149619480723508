import { useGetRoyaltiesFeesReportQuery } from "../../../app/apiSlice";
import { ColumnConfig } from "../../admin/shared/Grid";
import { ReportView, reportCol, reportDateToString, reportMoneyToString, reportValToOrderLink } from "../ReportView";
import { RoyaltiesFees } from "./RoyaltiesFees"

export const RoyaltiesFeesReport = () => {
    const filterFunc = (lowerSearch: string, records: RoyaltiesFees[]) =>
        records.filter(r =>
            r.orderNumber.toString().includes(lowerSearch) ||
            r.orderStatus.toLowerCase().includes(lowerSearch) ||
            r.shippingZip.includes(lowerSearch) ||
            r.finalShipDate?.includes(lowerSearch) ||
            r.productCode.toLowerCase().includes(lowerSearch) ||
            r.productDescription.toLowerCase().includes(lowerSearch) ||
            r.royaltyVendorCode.toLowerCase().includes(lowerSearch) ||
            r.royaltyVendor.toLowerCase().includes(lowerSearch) ||
            r.artist?.toLowerCase()?.includes(lowerSearch) ||
            r.royaltyPercentage?.toString()?.includes(lowerSearch) ||
            r.quantity.toString().includes(lowerSearch) ||
            r.productPrice.toString().includes(lowerSearch) ||
            r.vendorAddress?.toLowerCase()?.includes(lowerSearch) ||
            r.vendorCity?.toLowerCase()?.includes(lowerSearch) ||
            r.vendorState?.toLowerCase()?.includes(lowerSearch) ||
            r.vendorZip?.includes(lowerSearch) ||
            r.royalty?.toString()?.includes(lowerSearch)
        );

    const columns: ColumnConfig<RoyaltiesFees>[] = [
        reportCol('orderNumber', 'Order #', reportValToOrderLink),
        reportCol('orderDate', 'Order Date', reportDateToString),
        reportCol('orderStatus', 'Status'),
        reportCol('shippingZip', 'Ship Zip'),
        reportCol('finalShippingDate', 'Final Ship Date', reportDateToString),
        reportCol('productCode', 'Product Code'),
        reportCol('productDescription', 'Product Desc'),
        reportCol('royaltyVendorCode', 'Vendor Code'),
        reportCol('royaltyVendor', 'Vendor'),
        reportCol('artist', 'Artist'),
        reportCol('royaltyPercentage', 'Royalty %', val => val ? val.toString() + '%' : ''),
        reportCol('quantity', 'Quantity'),
        reportCol('productPrice', 'Price', reportMoneyToString),
        reportCol('vendorAddress', 'Vendor Addr'),
        reportCol('vendorCity', 'City'),
        reportCol('vendorState', 'State'),
        reportCol('vendorZip', 'Zip'),
        reportCol('royalty', 'Royalty', reportMoneyToString),
    ];

    return <ReportView
        title='Royalties Fees'
        query={useGetRoyaltiesFeesReportQuery}
        columns={columns}
        initialSort={{ column: 'orderDate', direction: 'asc'}}
        filterFunc={filterFunc}
        downloadPath='/api/Reports/RoyaltiesFees'
        downloadName='Royalties Fees'/>
}