import {CurrencyFormatter} from "../../../utils/CurrencyFormatter"
import {CartIdType} from "../../cart/CartSlice"
import {Address} from "../../input/address/Address"
import {ShippingOptionSelector} from "../../shipping-options/ShippingOptionSelector"

interface ShippingDisplayProps {
    onShippingOptionChange?: () => void
    onFetchingShippingOptions: (isFetching: boolean) => void
    locked?: boolean
    orderId?: number
    cartId?: CartIdType
    shippingOptionId?: number
    toAddress?: Address
    shippingPrice: number
    shippingServiceDescription: string
}
export const ShippingDisplay = ({ onShippingOptionChange, onFetchingShippingOptions, locked, orderId, cartId, shippingOptionId, toAddress, shippingPrice, shippingServiceDescription }: ShippingDisplayProps) => {
    if (cartId && onShippingOptionChange) {
        return (
            <div className="order-summary-pricing-details-shipping-container">
                <ShippingOptionSelector
                    locked={locked}
                    orderId={orderId}
                    cartId={cartId}
                    onChange={onShippingOptionChange}
                    onFetchingShippingOptions={onFetchingShippingOptions}
                    value={shippingOptionId}
                    toAddress={toAddress}/>
            </div>
        );
    }

    return (
        <div className="order-summary-pricing-details-container">
            <div className="order-summary-pricing-details-label">Shipping:</div>
            <div className="order-summary-pricing-details-value">
                {CurrencyFormatter.format(shippingPrice)}
                <span className='shipper-information'>{shippingServiceDescription}</span>
            </div>
        </div>
    )
}