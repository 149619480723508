import './MobileNavigationMenu.css';

import {SiteNavigation} from "../SiteNavigation";
import {Accordion, AccordionBody, AccordionHeader, AccordionItem, NavLink} from "reactstrap";
import {useState} from "react";
import {Link} from "react-router-dom";

export interface MobileNavigationMenuProps {
    items: SiteNavigation[]
}

export function MobileNavigationMenu({ items }: MobileNavigationMenuProps) {
    const [open, setOpen] = useState('');
    
    const onOpen = (val: string) => setOpen(val === open ? '' : val);
    const getSiteNav = (item: SiteNavigation) =>
      <NavLink tag={Link} to={`/category/${item.category.urlName}`} key={item.id}>
          <div className='mobile-nav-display-name'>
              {item.displayName}
          </div>
      </NavLink>
    
    return <div className="mobile-navigation-menu">
        {
            items.map(item =>
                (item.children?.length ?? 0) > 0 ? 
                    <Accordion open={open} toggle={onOpen} key={item.id}>
                        <AccordionItem>
                            <AccordionHeader targetId={`${item.id}`}>{ item.displayName }</AccordionHeader>
                            <AccordionBody accordionId={`${item.id}`}>
                                { item.children!.map( child => getSiteNav(child)) }
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                    : getSiteNav(item)
            )
        }
    </div>
}