import './Footer.css';
import {Subscribe} from './subscribe/Subscribe';
import {CopyrightAndSocials} from './copyrightAndSocials/CopyrightAndSocials';
import {Contact} from './contact/Contact';
import {Links} from './links/Links';
import {WhyCheckworks} from './whyCheckworks/WhyCheckworks';
import {useLocation} from 'react-router-dom';
import {Container} from 'reactstrap';
import {WelcomeMessage} from "./WelcomeMessage";
import {useGetHomePageBottomContentQuery} from "../../app/apiSlice";
import {useTranslation} from "react-i18next";
import {doesTranslationValueExist, getTranslationValue} from "../../utils/Utils";

export enum HomePageBottomContentValues {
	WelcomeMessage = 'WelcomeMessage'
}

export const Footer = () => {
	const path = useLocation().pathname;
	const {data: homePageBottomContent} = useGetHomePageBottomContentQuery();
	const {t} = useTranslation();

	return (
		<div className="footer-container">
			{path === "/" && homePageBottomContent === HomePageBottomContentValues.WelcomeMessage ?
				<WelcomeMessage/> : <></>}
			{path === "/" || path.includes("/category/") ? <WhyCheckworks/> : <></>}

			<div className='footer-contact-container d-flex flex-column'>
				<Subscribe/>

				<div className='footer-section d-flex flex-column'>
					<Container className='footer-section-container d-flex flex-column'>
						<div className='footer-section-content'>
							<Contact/>
							<Links/>
							<span className='footer-icon-container'>
                                {doesTranslationValueExist(t, 'links.authorizeNet') && (
	                                <a href={getTranslationValue(t, 'links.authorizeNet') + window.location.protocol + '//' + window.location.hostname}
	                                   className='mx-auto' rel="noopener noreferrer" target="_blank">
		                                <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif"
		                                     width="90" height="72" alt="Authorize.Net Merchant - Click to Verify"/>
	                                </a>
                                )}
								{
									doesTranslationValueExist(t, 'links.bbbMemberLink') &&
                                    <a href='https://www.bbb.org/us/al/birmingham/profile/check-printing/checkworks-inc-0463-90188403'
                                       target="_blank" rel="nofollow">
                                        <img src='https://seal-centralalabama.bbb.org/seals/darkgray-seal-280-80-bbb-90188403.png'
                                            alt="CheckWorks, Inc. BBB Business Review"/>
                                    </a>
								}
                            </span>
						</div>
						<CopyrightAndSocials/>
					</Container>
				</div>
			</div>
		</div>
	);
}