import { useGetBannersByTypeQuery } from '../../app/apiSlice';
import './BannerView.css';
import {ReactComponent as Headphones} from '../../assets/icons/headphones.svg';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const BannerView = () => {
  const BANNER_TYPE_ID = 1;
  const { data: banners} = useGetBannersByTypeQuery(BANNER_TYPE_ID);
  
  
  return (<>
      { banners && banners.length > 0 && banners.map(banner =>
        <div key={banner.id} className="banner-container">
            <span className="banner-icon-circle">
              <FontAwesomeIcon icon={faSnowflake} />
            </span>
            {banner.textField1}
        </div>
        )
      }
  </>);
}