import "./PrivacyPolicy.css";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../helpers/ScrollToTop";
import { Breadcrumb } from "../../breadcrumb/Breadcrumb";
import { Container } from "reactstrap";
import {doesTranslationValueExist, getTranslationValue} from "../../../utils/Utils";
import { Helmet } from "react-helmet-async";
import { Parser } from "html-to-react";

export const PrivacyPolicy = () => {
	const { t } = useTranslation();
	const prefix = "pages.privacyPolicyHtml";

	function getDefaultDocumentMetadata() {
		return (
			<Helmet>
				<title>{getTranslationValue(t, `${prefix}.title`)}</title>
				<meta name="description" content={getTranslationValue(t, `${prefix}.metaDescription`)} />
			</Helmet>
		);
	}

	return (
		<span className="privacy-policy-page d-flex flex-column">
			<ScrollToTop></ScrollToTop>
			{getDefaultDocumentMetadata()}

			<Container>
				<Breadcrumb currentPage={getTranslationValue(t, `${prefix}.title`)} />

				<span className="privacy-policy-container d-flex flex-column">
					{doesTranslationValueExist(t, `${prefix}.title`) && (
						<span className="privacy-policy-title d-flex">
							<h1>{getTranslationValue(t, `${prefix}.title`)}</h1>
						</span>
					)}

					<span className="privacy-policy-content flex-column">
						{
							<>
								<div>{Parser().parse(getTranslationValue(t, `${prefix}.styles`))}</div>
								<div>{Parser().parse(getTranslationValue(t, `${prefix}.htmlContent1`))}</div>
								<div>{Parser().parse(getTranslationValue(t, `${prefix}.htmlContent2`))}</div>
								<div>{Parser().parse(getTranslationValue(t, `${prefix}.htmlContent3`))}</div>
							</>
						}
					</span>

				</span>
			</Container>

		</span>
	);
};
