import './UserProfile.css';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {clearSession, selectCurrentConsumerUser} from "../login/AuthenticationSlice";
import {useEffect} from "react";
import {useGetCurrentConsumerDetailsQuery} from "../../../app/apiSlice";
import {ProfileTab} from "./ProfileTab";
import {UserProfileDetails} from "./details/UserProfileDetails";
import {Spinner} from "reactstrap";
import {UserOrders} from "../orders/UserOrders";
import {useNavigate, useParams} from "react-router-dom";
import {UserProfileRouteModel} from "./UserProfileRouteModel";
import {UserAddresses} from "../addresses/userAddresses/UserAddresses";
import {skipToken} from "@reduxjs/toolkit/query";

export const UserProfile = () => {
    const consumer = useAppSelector(selectCurrentConsumerUser);
    const navigate = useNavigate();
    
    const { profileTab } = useParams() as UserProfileRouteModel;
    const { data: currentConsumer } = useGetCurrentConsumerDetailsQuery(consumer ? undefined : skipToken);

    useEffect(() => {
        // If the user return is not the same as the user we thought was logged in
        // Or the current user apparently logged out
        if ((consumer && currentConsumer && consumer.id !== currentConsumer.id) ||
            (consumer && !currentConsumer))
            clearSession();
    }, [currentConsumer]);

    const getTabClasses = (tab: ProfileTab) => {
        let classNames = "user-profile-navigation-element";

        if (tab === (profileTab ?? ProfileTab.ProfileDetails)) {
            classNames += " selected-profile-tab"
        }

        return classNames;
    }

    const onTabSelected = (tab: ProfileTab) => {
        navigate(`/profile/${tab}`);
    }

    const getLoadingSpinner = () => {
        return (
            <Spinner size="sm">
                Loading...
            </Spinner>
        );
    }

    const getSelectedTabContent = () => {
        if (!consumer)
            return getLoadingSpinner();
        switch(profileTab) {
            case ProfileTab.ProfileDetails:
                return consumer ?
                    <UserProfileDetails consumer={consumer} /> : getLoadingSpinner();
            case ProfileTab.SavedAddresses:
                return consumer ?
                    <UserAddresses consumer={consumer} /> : getLoadingSpinner();
            case ProfileTab.OrderHistory:
                return <UserOrders/>
            default:
                return <UserProfileDetails consumer={consumer}/>
        }
    }

    return (
        <div className="user-profile-container">
            <div className="user-profile-left-side-content">
                <div className={getTabClasses(ProfileTab.ProfileDetails)}
                     onClick={() => onTabSelected(ProfileTab.ProfileDetails)}>
                    Profile
                </div>
                <div className={getTabClasses(ProfileTab.SavedAddresses)}
                     onClick={() => onTabSelected(ProfileTab.SavedAddresses)}>
                    Saved Addresses
                </div>
                <div className={getTabClasses(ProfileTab.OrderHistory)}
                     onClick={() => onTabSelected(ProfileTab.OrderHistory)}>
                    Order History
                </div>
            </div>
            <hr className="d-md-none"/>
            <div className="user-profile-center-content">
                {getSelectedTabContent()}
            </div>
        </div>
    );

}