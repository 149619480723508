
import './CategoryPreview.css';
import {useGetCategoryProductsQuery, useGetAspectRatiosQuery} from "../../../app/apiSlice";
import { AspectRatio } from"../../../components/image/AspectRatio";
import {useEffect, useState, useMemo} from "react";
import {SiteCategory} from "../Category";
import {Button} from "reactstrap";
import {ProductPreview} from "../../product/preview/ProductPreview";

interface CategoryPreviewProps {
    siteCategory: SiteCategory,
    defaultShouldLimitCardItems: boolean,
    contextualSiteCategory?: SiteCategory,
}

export const CategoryPreview = ({siteCategory, defaultShouldLimitCardItems, contextualSiteCategory}: CategoryPreviewProps) => {
    const limitedCount = 8;
    const {data: products} = useGetCategoryProductsQuery(siteCategory.id);
    const [ shouldLimitCardItems, setShouldLimitCardItems ] = useState(defaultShouldLimitCardItems);
    const { data: aspectRatios } = useGetAspectRatiosQuery();
    const aspectRatio = useMemo(()=>aspectRatios?.find((e: AspectRatio)=>e.categoryId === siteCategory.categoryId)?.aspectRatio, [aspectRatios, siteCategory.categoryId])

    useEffect(() => {
        setShouldLimitCardItems(defaultShouldLimitCardItems);
    }, [defaultShouldLimitCardItems])

    const showAllClicked = () => {
        setShouldLimitCardItems(!shouldLimitCardItems);
    }

    const getToggleAllButton = () => {
        return (defaultShouldLimitCardItems && products && products.length > limitedCount)
                ? (<Button color="primary"
                        className="see-more-button"
                        onClick={showAllClicked}>
                {shouldLimitCardItems ? "Show All" : "Show Less"}
                </Button>)
                : <></>;
    }

    const getTopMessage = () => {
        return (!defaultShouldLimitCardItems)
            ? (<div className="category-flavor-text"><p>{contextualSiteCategory?.topMessage}</p></div>)
            : <></>;
    }
    
    const getDisplayProducts = () =>
        shouldLimitCardItems ?
            products?.slice(0, limitedCount) :
            products;

    return (
        <div className="category-preview-container">

            <div className="card-item-header">
                <h2 className="card-item-title">{siteCategory.subHeader ? siteCategory.subHeader : siteCategory.name}</h2>

                {getToggleAllButton()}
            </div>
            {getTopMessage()}
            <hr />

            <div className={`card-item-previews aspect-ratio-${aspectRatio}`}>
                { getDisplayProducts()?.map((product, index) => 
                    <ProductPreview product={product} siteCategory={siteCategory} contextualSiteCategory={contextualSiteCategory} key={product.id}/>
                )}

            </div>

        </div>
    );
}
