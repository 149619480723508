import './CopyrightAndSocials.css'
import {useTranslation} from "react-i18next";
import {ReactComponent as Twitter} from '../../../assets/icons/twitter.svg';
import {ReactComponent as LinkedIn} from '../../../assets/icons/linkedin.svg';
import {ReactComponent as Facebook} from '../../../assets/icons/facebook.svg';
import {ReactComponent as Message} from '../../../assets/icons/message.svg';

export const CopyrightAndSocials = () => {
    const { t } = useTranslation();

    return (
        <div className='copyright-and-socials-content d-flex'>
            <span className='copyright-content'>
                {t(`footer.copyrightAndSocials.copyright`, { year: new Date().getFullYear()})}
            </span>
            <div className='socials-container d-flex flex-row'>
                <a href={t(`footer.copyrightAndSocials.twitterLink`)}>
                    <Twitter className='socials-icon'/>
                </a>
                <a href={t(`footer.copyrightAndSocials.linkedinLink`)}>
                    <LinkedIn className='socials-icon'/>
                </a>
                <a href={t(`footer.copyrightAndSocials.facebookLink`)}>
                    <Facebook className='socials-icon'/>
                </a>
                <a href={'sms:' + t(`phoneNumber.number`)}>
                    <Message className='socials-icon'/>
                </a>
            </div>
        </div>
    );
};