import { useGetPaymentMethodRollupReportQuery } from "../../../app/apiSlice";
import { PaymentMethodRollup } from "./PaymentMethodRollup";
import { ColumnConfig } from "../../admin/shared/Grid";
import { reportCol, reportMoneyToString, ReportView } from "../ReportView";


export const PaymentMethodRollupReport = () => {
  const filterFunc = (lowerSearch: string, records: PaymentMethodRollup[]) =>
    records.filter(r =>
      r.brand.toLowerCase().includes(lowerSearch) ||
      r.orderStatus.toLowerCase().includes(lowerSearch)
    );
  
  const columns: ColumnConfig<PaymentMethodRollup>[] = [
    reportCol('brand', 'Brand'),
    reportCol('orderStatus', 'Order Status'),
    reportCol('creditCardNetTotal', 'Credit Card Net Total', reportMoneyToString),
    reportCol('achOrderNetTotal', 'ACH Order Net Total', reportMoneyToString),
  ];
  
  return (
    <ReportView
      title='Payment Method Rollup'
      query={useGetPaymentMethodRollupReportQuery}
      columns={columns}
      initialSort={{ column : "brand", direction: 'asc' }}
      filterFunc={filterFunc}
      downloadPath='/api/Reports/PaymentMethodRollup'
      downloadName='CV_Payment Method Rollup'/>
  )
}