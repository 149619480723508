import './DiscountEdit.css';
import {Alert, Button, Container, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React, {useEffect, useState} from "react";
import {CartIdType} from "../../cart/CartSlice";
import {DiscountTypeNames,} from "../Discount";
import {useGetDiscountTypesQuery, useSaveDiscountMutation} from "../../../app/apiSlice";
import {CartPricing} from "../../cart/CartPricing";
import {OrderPricing} from "../../order/OrderPricing";
import { getErrorMessage } from '../../../utils/Utils';

interface DiscountEditProps {
    pricing?: CartPricing | OrderPricing,
    cartId?: CartIdType,
    orderId?: number,
    isOpen: boolean,
    toggleModal: () => void,
}
export const DiscountEdit = ({pricing, cartId, orderId, isOpen, toggleModal}: DiscountEditProps) => {
    const { data: discountTypes } = useGetDiscountTypesQuery();
    const [saveDiscount] = useSaveDiscountMutation();

    const [ amount, setAmount ] = useState<number>(0);
    const [ percentage, setPercentage ] = useState<number>(0);
    const [ discountTypeId, setDiscountTypeId ] = useState<number>(0);
    const [ errorMessage, setErrorMessage ] = useState('');
    const [saveClicked, setSaveClicked] = useState<boolean>(false);

    const discountTypeName = discountTypes?.find(dt => dt.id === discountTypeId)?.name;

    
    // Select first option when discount types loaded
    useEffect(() => {
        setDiscountTypeId(discountTypes?.[0]?.id ?? 0);
    }, [discountTypes]);
    
    // clear state on modal close
    useEffect(() => {
        if (discountTypeId > 0) {
            const isInUse = isDiscountTypeAlreadyInUse(discountTypeId);
            isInUse && setDiscountTypeId(0);
        }
        
        if (!isOpen && discountTypes?.find(x => x.name === DiscountTypeNames.FreeShipping)?.id !== discountTypeId) {
            setErrorMessage('');
            setAmount(0);
            setPercentage(0);
        }
    }, [isOpen])
    
    // also set amount when percentage changed
    const onPercentageChanged = (percentage: string) => {
        let percentageValue = parseInt(percentage);
        percentageValue = percentageValue < 0 ? 0 : percentageValue;
        percentageValue = percentageValue > 100 ? 100 : percentageValue;
        setPercentage(percentageValue);
        setAmount(getPercentageOffTotal(isNaN(percentageValue) ? 0 : percentageValue));
    }

    const getPercentageOffTotal = (percentageOff: number) => {
        return Math.round(((pricing?.total ?? 0) * (percentageOff / 100)) * 100) / 100;
    }

    // also set amount when type changed (for free shipping)
    const onDiscountTypeChanged = (discountTypeId: number) => {
        setDiscountTypeId(discountTypeId);
        if (discountTypes?.find(dt => dt.id === discountTypeId)?.name === DiscountTypeNames.FreeShipping) {
            setAmount(pricing?.shippingTotal ?? 0);
        }
    }

    const isDiscountTypeAlreadyInUse = (discountTypeId: number) => {
        return pricing?.discounts?.some(d => d.discountTypeId === discountTypeId) ?? false;
    }

    const save = async () => {
        if (!discountTypeId) {
            setErrorMessage('Please select a discount type.');
            return;
        }
        if (!amount && !percentage) {
            setErrorMessage('Please enter a valid amount or a percentage.');
            return;
        }
        try {
            setSaveClicked(true);
            await saveDiscount({
                orderId: orderId,
                cartId: cartId,
                amount: amount,
                percentage: percentage,
                discountTypeId: discountTypeId
            }).unwrap();
            toggleModal();
            setSaveClicked(false);
        } catch (e: any) {
            setErrorMessage(getErrorMessage(e) ?? 'Unknown error occurred while saving discount. Please try again.');
            setSaveClicked(false);
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggleModal} size='md'>
            <ModalHeader toggle={toggleModal} close={<div><button className='btn-close' onClick={toggleModal}/></div>}>Discount</ModalHeader>
            <ModalBody>
                <Container>
                    <FormGroup>
                        <Label for='Amount'>Dollar Amount</Label>
                        <InputGroup>
                            <InputGroupText>$</InputGroupText>
                            <Input id='Amount'
                                    name='amount'
                                    type='number'
                                    onChange={(e) => setAmount(e.target.valueAsNumber)}
                                    disabled={discountTypeName === DiscountTypeNames.FreeShipping ||
                                        discountTypeName === DiscountTypeNames.Percentage}
                                    value={isNaN(amount) ? '' : amount} />
                        </InputGroup>
                    </FormGroup>
                    {discountTypeName === DiscountTypeNames.Percentage &&
                        <FormGroup>
                            <Label for='Percentage'>Percentage</Label>
                            <InputGroup>
                                <Input id='Percentage' className='form-control' type='text' maxLength={3} value={isNaN(percentage) ? '' : percentage} onChange={e => onPercentageChanged(e.target.value.replace(/[^0-9]/g, ""))} placeholder='Percentage' />
                                <InputGroupText>%</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    }
                    <FormGroup>
                        <Label for='DiscountType'>Type</Label>
                        <Input type='select'
                               id='DiscountType'
                               onChange={e => onDiscountTypeChanged(parseInt(e.target.value))}
                               value={discountTypeId}
                               placeholder="Category">
                            <option value={0}
                                    disabled={true}>
                            </option>
                            {discountTypes?.map(discountType => {
                                const alreadyInUse = isDiscountTypeAlreadyInUse(discountType.id);
                                return (
                                    <option key={discountType.id}
                                            value={discountType.id}
                                            disabled={alreadyInUse}>
                                        {discountType.name} {alreadyInUse ? ' (Already Used)' : ''}
                                    </option>
                                )
                            })}
                        </Input>
                    </FormGroup>
                    {errorMessage && <Alert color='danger'>{errorMessage}</Alert>}
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={save} disabled={saveClicked}>Create</Button>
                <Button color='secondary' onClick={toggleModal}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}