import {Alert, Button} from "reactstrap"
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter"
import {Discount} from "../../discount/Discount"
import {useDeleteDiscountMutation} from "../../../app/apiSlice"
import {getErrorMessage} from "../../../utils/Utils"
import React, {useState} from "react"
import {LoadingSpinner} from "../../input/utils/LoadingSpinner";

interface DiscountTableProps {
    discounts: Discount[]
    isCart: boolean
    isFetching: boolean
}
export const DiscountTable = ({ discounts, isCart, isFetching }: DiscountTableProps) => {
    const [deleteDiscount] = useDeleteDiscountMutation();
    const [ errorMessage, setErrorMessage ] = useState('');
    
    const onDeleteDiscountClicked = async (discountId: number) => {
        try {
            await deleteDiscount(discountId).unwrap();
        } catch (e: any) {
            console.error(e);
            setErrorMessage(getErrorMessage(e) ?? 'Unknown error occurred while removing discount.');
        }
    }
    
    return (
        <div className="order-summary-pricing-details-discount-table-container">
            <h5>Discount List</h5>
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                <tr>
                    <th>Percentage</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {discounts.map(d => (
                        <tr key={d.id}>
                            <td className="align-middle">{d.percentage ? `${d.percentage}%` : '-'}</td>
                            {(isFetching && (d.type.name === 'Percentage' || d.type.name === 'Free Shipping'))
                                ? <LoadingSpinner size="sm" shouldRemoveMargin={true} />
                                : <td className="align-middle">{CurrencyFormatter.format(-(d.effectiveAmount ?? 0))}</td>
                            }
                            <td className="align-middle">{d.type?.name}</td>
                            <td className={isCart ? 'd-flex flex-row-reverse align-middle' : 'align-middle'}>
                                {isCart && <Button color='danger' onClick={() => onDeleteDiscountClicked(d.id)}>Delete</Button>}
                            </td>
                        </tr>
                    )
                )}
                </tbody>
            </table>
            {errorMessage && <Alert color='danger'>{errorMessage}</Alert>}
        </div>
    );
}