export function formatDateForInput(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}`
}

export function today() {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

export function addDays(days: number, date: Date) {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
}