
import React, {useEffect, useMemo, useState} from "react";
import {Alert, Button, Card, CardBody, CardHeader, Col, Row, Spinner} from "reactstrap";
import { OrderSummary } from "../../OrderSummary";
import { ShippingInformation, ShippingInformationCard } from "../../ShippingInformation";
import { SelectedOrderProductTable } from "./SelectedOrderProductTable";
import {
  useGetOrderShippingInformationQuery,
  useMigrateOrderProductsToCartMutation,
  useCreateOrderEditCartMutation,
  useGetCartProductsPricingQuery,
  useGetVendorOrderProductStatusQuery,
  useGetAdminOrderTrackingInformationQuery,
  useGetOrderProductsQuery,
  useGetBillingAddressByOrderIdQuery,
  useHasLastOrderPaymentFailedQuery
} from "../../../../../app/apiSlice";
import {getErrorMessage} from "../../../../../utils/Utils";
import { ShippingOption } from "../../../../shipping-options/ShippingOption";
import {
  useAppDispatch,
  useAppSelector,
  useClaims,
  useDebounce,
  useShippingAddressValidation,
} from "../../../../../app/hooks";
import { Address } from "../../../../input/address/Address";
import "./OrderDetailsTab.css";
import {Order, OrderEditCartDetails} from "../../../../order/Order";
import {CartIdType, forgetOrderEditCartId, selectOrderEditCart, setOrderEditCart} from "../../../../cart/CartSlice";
import {UpdateOrder} from "./UpdateOrder";
import {OrderEditPaymentPreview} from "./OrderEditPaymentPreview";
import {OrderShippingInformationWithRepeatEmail} from "./OrderShippingInformation";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {OrderPaymentsBrowse} from "../../payments/browse/OrderPaymentsBrowse";
import {CartPricingRequest} from "../../../../cart/CartPricing";
import {CustomLogoManager} from "../../../../image/custom-logo/CustomLogoManager";
import { TrackingDetails } from "../../../../order/tracking/trackingTable/trackingDetails/TrackingDetails";
import { CustomOverlayManager } from '../../../../image/custom-overlay/CustomOverlayManager';
import {OrderStatuses} from "../../../../order-status/OrderStatus";
import {BillingAddress} from "../../BillingAddress";

export interface OrderDetailsTabProps {
  order: Order,
  locked: boolean
}

const UpdateOrderDisclaimer = ({hasEditingRole, orderEditCartDetails}: {hasEditingRole: boolean, orderEditCartDetails?: OrderEditCartDetails}) =>
    hasEditingRole && (orderEditCartDetails)
        ? <Alert color="primary" className="order-details-tab-badge">This order has pending changes.</Alert>
        : <></>;


const UnpaidProductsDisclaimer = (
  {order}: {order?: Order}) =>
    ![OrderStatuses.Cancelled.toString(), OrderStatuses.CancelledDNS.toString()].includes(order?.status ?? '')
        ? <Alert color="danger" className="order-details-tab-badge">This order has unpaid for products.</Alert>
        : <></>;

const EditButton = ({hasEditingRole, orderEditCartDetails, shouldDisable, isLoading, onEditClick}:
                        {hasEditingRole: boolean, orderEditCartDetails?: OrderEditCartDetails, shouldDisable: boolean, isLoading: boolean, onEditClick: () => void}) =>
    hasEditingRole && !orderEditCartDetails
        ? <Button color='primary'
                  className='order-details-update-order-button'
                  disabled={shouldDisable}
                  onClick={onEditClick}>
          {isLoading && <Spinner></Spinner>}
          Edit Order
        </Button>
        : <></>;


const UpdateButton = ({hasEditingRole, orderEditCartDetails, disabled, onUpdateClick}:
                          {hasEditingRole: boolean, orderEditCartDetails?: OrderEditCartDetails, disabled: boolean, onUpdateClick: () => void}) =>
    hasEditingRole && orderEditCartDetails
        ? <Button color='success'
                  className='order-details-update-order-button'
                  disabled={disabled}
                  onClick={onUpdateClick}>Update Order</Button>
        : <></>;

export const OrderDetailsTab = ({order, locked}: OrderDetailsTabProps) => {
  const dispatch = useAppDispatch();
  const orderEditCartDetails = useAppSelector(state => selectOrderEditCart(state, order.id));
  const [ migrateOrderProductsToCart ] = useMigrateOrderProductsToCartMutation();
  const [ createOrderCart, {isLoading: isCreatingOrderCart} ] = useCreateOrderEditCartMutation();
  const { data: orderProducts, isLoading: isLoadingOrderProducts } = useGetOrderProductsQuery(order.id);
  const {data: vendorProductStatusOverview} = useGetVendorOrderProductStatusQuery(order.id);
  const { data: trackingDetails } = useGetAdminOrderTrackingInformationQuery(order.id);
  const {data: shippingInfoQuery, isLoading: loadingShippingInfo, error: shippingError} = useGetOrderShippingInformationQuery(order.id);
  const { data: billingAddress } = useGetBillingAddressByOrderIdQuery(order.id);
  const { data: lastPaymentFailed } = useHasLastOrderPaymentFailedQuery(order.id);
  const { hasEditingRole } = useClaims();
  const [ shippingInfo, setShippingInfo ] = useState<OrderShippingInformationWithRepeatEmail | undefined>(undefined);
  const [ editingShippingInfo, setEditingShippingInfo ] = useState<boolean>( false);
  const [ shippingOption, setShippingOption ] = useState<ShippingOption | undefined>(undefined);
  const [ orderEditCartPricingRequest, setOrderEditCartPricingRequest ] = useState<CartPricingRequest | undefined>();
  const { data: cartPricing, isFetching: isFetchingCartPricing } = useGetCartProductsPricingQuery(orderEditCartPricingRequest ?? skipToken);
  const isUpdateButtonDisabled = useMemo(() => !cartPricing || !orderEditCartDetails || !shippingOption || isFetchingCartPricing,
      [cartPricing, orderEditCartDetails, shippingOption, isFetchingCartPricing]);
  // Debouncing disabled property because shipping option request can initiate a subsequent pricing request
  // Bypass the debounce if the value becomes true, we only want to debounce making the button enabled
  const debouncedIsUpdateButtonDisabled = useDebounce(isUpdateButtonDisabled, 250, isUpdateButtonDisabled);
  const [ updateOrderIsOpen, setUpdateOrderIsOpen ] = useState(false);
  const toggleUpdateOrder = () => setUpdateOrderIsOpen(!updateOrderIsOpen);
  const toggleIsEditingShipping = () => setEditingShippingInfo(!editingShippingInfo);
  const isEditing = cartPricing !== undefined;

  // Returning a callback here essentially mimics componentWillUnmount
  useEffect(() => {
    return () => {
      // What ever is written here will happen before the component unmounts
      cancelEdit();
    }
  }, [])

  const cancelEdit = () => {
    dispatch(forgetOrderEditCartId());
    if (shippingInfoQuery) {
      const orderShippingInformationPlusRepeatEmail: OrderShippingInformationWithRepeatEmail = {
        ...shippingInfoQuery,
        repeatEmail: shippingInfoQuery?.email || "",
      };
      setShippingInfo(orderShippingInformationPlusRepeatEmail);
    }
  }
  const validation = useShippingAddressValidation();
  
  useEffect(() => {
    if (orderEditCartDetails) {
      const productShippingServiceSelections = shippingOption?.items.map((i) => {
        return {
          cartProductId: i.cartProductId,
          cost: i.price ?? 0,
          retailCost: i.retailPrice ?? 0
        }
      }) ?? [];
      setOrderEditCartPricingRequest({
        cartId: orderEditCartDetails?.cartId,
        orderId: orderEditCartDetails?.orderId,
        toAddress: shippingInfo?.address,
        productShippingServiceSelections
      });
    }
    else {
      setOrderEditCartPricingRequest(undefined);
    }
  }, [shippingInfo, orderEditCartDetails, shippingOption]);

  const shippingOptionChange = async (newShippingOption: ShippingOption | undefined) => {
    setShippingOption(newShippingOption);
    // If we aren't in an order edit cart context, and there was a previous shipping option that was different than this one,
    // then this is an edit and we should create an order edit cart
    if (!orderEditCartDetails && newShippingOption && shippingOption && newShippingOption.shippingServiceId !== shippingOption.shippingServiceId) {
      await createCartFromOrder();
    }

  }
  
  const shippingInfoChange = (newShipping?: ShippingInformation) => {
    if (
          newShipping?.firstName !== shippingInfo?.firstName ||
          newShipping?.lastName !== shippingInfo?.lastName ||
          newShipping?.phoneNumber !== shippingInfo?.phoneNumber ||
          newShipping?.email !== shippingInfo?.email ||
          newShipping?.repeatEmail !== shippingInfo?.repeatEmail ||
          !Address.equals(newShipping?.address, shippingInfo?.address)
    ) {
          const newShippingInfo = newShipping as OrderShippingInformationWithRepeatEmail;
          setShippingInfo(newShippingInfo);
    }

    const setValue = (name: string, val: string) => validation.setValue(`shipping_${name}`, val);

    // shipping specific validation fields
    setValue('firstName', newShipping?.firstName ?? "");
    setValue('lastName', newShipping?.lastName ?? "");
    setValue('phoneNumber', newShipping?.phoneNumber ?? "");
    setValue('email', newShipping?.email ?? "");
    setValue('repeatEmail', newShipping?.repeatEmail ?? "");
  }

  useEffect(() => {
    shippingInfoChange({
      ...shippingInfoQuery,
      repeatEmail: shippingInfoQuery?.email,
    });
  }, [shippingInfoQuery]);

  const confirmOrderUpdate = () => {
    if (!validation.allValid()) return;

    if (!orderEditCartDetails) return; // do nothing
    setUpdateOrderIsOpen(true);
  }
  
  if (loadingShippingInfo || isLoadingOrderProducts) {
    return <Spinner className='mx-auto mt-3'>Loading...</Spinner>
  }

  if (shippingError || !shippingInfo || ((!orderProducts || orderProducts.length === 0) && !isLoadingOrderProducts)) {
    return <Alert color='danger' className='d-flex flex-column mx-auto mt-3'>
      <div>
        {!orderProducts || orderProducts.length === 0 ? 'No order products available.' :  'No order shipping data available.'}
      </div>
      { shippingError && <div>Shipping data error: {getErrorMessage(shippingError)}</div> }
    </Alert>
  }

  const onProductAddedToCart = async (cartId: CartIdType) => {
    if (!orderEditCartDetails) {
      await migrateOrderProductsToCart({orderId: order.id, cartId}).unwrap();
    }
    dispatch(setOrderEditCart({orderId: order.id, cartId}));
  }

  const updateOrderProductQuantity = async (orderProductId: number, quantityOptionId: number) => {
    const cartId = await createOrderCart({
      orderId: order.id,
      orderProductId,
      siteProductVariantQuantityOptionId: quantityOptionId,
    }).unwrap();

    dispatch(setOrderEditCart({orderId: order.id, cartId}));
  }

  const createCartFromOrder = async () => {
    const cartId = await createOrderCart({
      orderId: order.id,
      isReorder: false
    }).unwrap();

    dispatch(setOrderEditCart({orderId: order.id, cartId}));
  }

  return (
    <div className='d-flex flex-column px-4 mt-3'>
      <Row className='mb-0'>
        <Col className="d-flex align-items-center">
          <UpdateOrderDisclaimer hasEditingRole={hasEditingRole} orderEditCartDetails={orderEditCartDetails}/>
          { lastPaymentFailed && <UnpaidProductsDisclaimer order={order}/> }
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          {!locked && <EditButton hasEditingRole={hasEditingRole}
                                  orderEditCartDetails={orderEditCartDetails}
                                  shouldDisable={locked || isCreatingOrderCart}
                                  isLoading={isCreatingOrderCart}
                                  onEditClick={createCartFromOrder}/>}
          {isEditing && <Button color='danger'
                                className='order-details-update-order-button'
                                onClick={cancelEdit}>
              Cancel
          </Button>}
          {!locked && <UpdateButton hasEditingRole={hasEditingRole}
                                    orderEditCartDetails={orderEditCartDetails}
                                    disabled={debouncedIsUpdateButtonDisabled}
                                    onUpdateClick={confirmOrderUpdate}/>}
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <SelectedOrderProductTable siteId={order?.siteId}
                                     orderId={order.id}
                                     consumerId={order.consumerId}
                                     locked={locked || !hasEditingRole}
                                     orderEditCartId={orderEditCartDetails?.cartId}
                                     vendorProductStatusOverview={vendorProductStatusOverview}
                                     onProductAddedToCart={onProductAddedToCart}
                                     updateOrderProductQuantity={updateOrderProductQuantity}
                                     createCartFromOrder={createCartFromOrder}/>
        </Col>
      </Row>

      { trackingDetails?.length &&
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <h5 className="m-0">Tracking</h5>
              </CardHeader>
              <CardBody>
                <TrackingDetails orderTrackingList={trackingDetails} noOrdersMessage={''} tableOnly/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      }
      
      <CustomLogoManager orderId={order.id} cartId={orderEditCartDetails?.cartId} locked={locked}/>

      <CustomOverlayManager orderId={order.id} cartId={orderEditCartDetails?.cartId} consumerId={order.consumerId} locked={locked}/>
      
      <Row className='my-2'>
        <Col>
            <ShippingInformationCard
              shipping={shippingInfo}
              validation={validation.createProxy('shipping')}
              locked={locked || !hasEditingRole}
              readOnlyDefault={!orderEditCartDetails}
              toggleIsEditingShipping={toggleIsEditingShipping}
              onShippingChanged={shippingInfoChange}
              createCartFromOrder={createCartFromOrder}/>
            {billingAddress && <div className='mt-3'>
                <BillingAddress address={billingAddress} onAddressChanged={() => {}} useCardContainer={true} locked={true} />
              </div>
            }
        </Col>
        <Col xs='5'>
          <OrderSummary
            cartId={orderEditCartDetails?.cartId}
            order={order}
            orderId={order.id}
            locked={locked || !hasEditingRole}
            toAddress={shippingInfo.address}
            defaultShippingOptionId={order.shippingService?.shippingOptionId}
            shippingOption={shippingOption}
            cartPricing={cartPricing}
            isFetchingCartPricing={isFetchingCartPricing}
            onShippingOptionChange={shippingOptionChange}/>
        </Col>
      </Row>

      <Row className='my-2'>
        <Col xs='7'>
          <Card>
            <CardHeader className="card-header">
              <h5 className='m-0'>Transaction History</h5>
            </CardHeader>
            <CardBody id="order-payments-card-body">
              <OrderPaymentsBrowse orderId={order.id}></OrderPaymentsBrowse>
            </CardBody>
          </Card>
        </Col>

        <Col>
          {orderEditCartDetails && cartPricing &&
              <OrderEditPaymentPreview
                  orderEditCartDetails={orderEditCartDetails}
                  cartPricing={cartPricing}
              ></OrderEditPaymentPreview>
          }
        </Col>
      </Row>

      <Row>
        <Col className="d-flex">
          <UpdateOrderDisclaimer hasEditingRole={hasEditingRole} orderEditCartDetails={orderEditCartDetails}/>
          { lastPaymentFailed && <UnpaidProductsDisclaimer order={order}/> }
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          {!locked && <UpdateButton hasEditingRole={hasEditingRole}
                                    orderEditCartDetails={orderEditCartDetails}
                                    disabled={debouncedIsUpdateButtonDisabled}
                                    onUpdateClick={confirmOrderUpdate}/>}
          {!locked && <EditButton hasEditingRole={hasEditingRole}
                                  orderEditCartDetails={orderEditCartDetails}
                                  shouldDisable={locked || isCreatingOrderCart}
                                  isLoading={isCreatingOrderCart}
                                  onEditClick={createCartFromOrder}/>}
        </Col>
      </Row>

      {(orderEditCartDetails || editingShippingInfo) &&
        <UpdateOrder
            order={order}
            orderEditCartDetails={orderEditCartDetails}
            isOpen={updateOrderIsOpen}
            shippingOption={shippingOption}
            shippingInformation={shippingInfo}
            shouldDisableSubmitButton={!orderEditCartDetails || !shippingOption || isFetchingCartPricing}
            toggleModal={toggleUpdateOrder}/>
      }

    </div>
  );
}