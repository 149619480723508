import {GridPage} from "../../../shared/GridPage";
import React, {useEffect, useState} from "react";
import {ColumnConfig, GridConfig} from "../../../shared/Grid";
import {SortState} from "../../../../../utils/Grid";
import {Utils} from "../../../../../utils/Utils";
import './RelatedOrdersBrowse.css';
import {DateTime} from "luxon";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {useGetRelatedOrdersQuery} from "../../../../../app/apiSlice";
import {RelatedOrder} from "./RelatedOrder";
import {Link} from "react-router-dom";
import {LoadingSpinner} from "../../../../input/utils/LoadingSpinner";

interface RelatedOrdersBrowseProps {
    orderId: number
}

export const RelatedOrdersBrowse = ({ orderId }: RelatedOrdersBrowseProps) => {
    const { data: queryOrders, isLoading } = useGetRelatedOrdersQuery(orderId ?? skipToken);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState<SortState<RelatedOrder>>({ column: 'id', direction: 'asc' });
    const [ sortedOrders, setSortedOrders ] = useState(queryOrders);

    // sort
    useEffect(() => {
        setSortedOrders(orders => {
            const workingOrders = [...orders ?? queryOrders ?? []];
            return workingOrders.sort(Utils.sortBy(sort.column, sort.direction));
        });
    }, [sort, queryOrders]);

    // filter
    useEffect(() => {
        const lowerSearch = search.toLowerCase();
        setSortedOrders(
            !lowerSearch ? queryOrders : queryOrders?.filter(order =>
                (order.consumerEmail?.toLowerCase().includes(lowerSearch)) ||
                (order.bankInfo?.includes(lowerSearch)) ||
                (order.address?.toLowerCase().includes(lowerSearch)) ||
                (order.status?.toLowerCase().includes(lowerSearch)) ||
                (order.phoneNumber?.includes(lowerSearch))
            )
        );
    }, [search, queryOrders]);

    const config: GridConfig<RelatedOrder> = {
        configSections: [
            {
                columns: [
                    new ColumnConfig({
                        field: 'id',
                        display: 'Order Id',
                        cellDisplay: val => (<Link to={`/admin/order/${val}`}>{val as string}</Link>),
                    }),
                    new ColumnConfig({
                        field: 'timeCreated',
                        display: 'Time Created',
                        cellDisplay: val => val ? DateTime.fromISO(val as string + 'Z').toLocaleString(DateTime.DATETIME_SHORT) : ''
                    }),
                    new ColumnConfig({
                        field: 'status',
                        display: 'Current Status'
                    }),
                    new ColumnConfig({
                        field: 'matchingOn',
                        display: 'Matching On'
                    }),
                    new ColumnConfig({
                        field: 'consumerId',
                        display: 'Consumer Id',
                        cellDisplay: val => (<Link to={`/admin/consumer/${val}`}>{val as string}</Link>),
                    }),
                    new ColumnConfig({
                        field: 'consumerEmail',
                        display: 'Email'
                    }),
                    new ColumnConfig({
                        field: 'consumerName',
                        display: 'Consumer Name'
                    }),
                    new ColumnConfig({
                        field: 'bankInfo',
                        display: 'ABA & Account Num.'
                    }),
                    new ColumnConfig({
                        field: 'address',
                        display: 'Shipping Address'
                    }),
                    new ColumnConfig({
                        field: 'phoneNumber',
                        display: 'Phone Number'
                    }),
                ]
            }
        ]
    };
    
    if (isLoading) {
        return <LoadingSpinner size="sm" />
    }

    return (
        <GridPage records={sortedOrders ?? []}
                  gridConfig={config}
                  title={'Orders'}
                  sort={sort}
                  shouldShowCriteriaPanel={false}
                  onSortChanged={setSort}
                  onSearchChange={setSearch}
                  noRecordsMessage={queryOrders && queryOrders.length > 0
                      ? "No related orders found using the search criteria"
                      : "No related orders exist on record for this Order"}/>
    )
}