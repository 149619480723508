import { GridPage } from "../../../../shared/GridPage";
import React, { useEffect, useState } from "react";
import { ColumnConfig, GridConfig } from "../../../../shared/Grid";
import { SortState } from "../../../../../../utils/Grid";
import {Utils} from "../../../../../../utils/Utils";
import './RefundBrowse.css';
import {DateTime} from "luxon";
import { useClaims } from "../../../../../../app/hooks";
import {Refund, RefundStatusNames} from "../Refund";
import {useGetOrderRefundsQuery} from "../../../../../../app/apiSlice";
import {Button} from "reactstrap";
import {RefundApproveDeny} from "../approveDeny/RefundApproveDeny";
import {CurrencyFormatter} from "../../../../../../utils/CurrencyFormatter";
import { ADMIN, FINANCE, TEAM_LEAD } from "../../../../../../utils/Roles";
import { reportValToOrderLink } from "../../../../../reports/ReportView";

interface RefundProps {
    orderId?: number
}

export const RefundBrowse = ({ orderId }: RefundProps) => {
    const { hasPermission } = useClaims();
    const { data: queryRefunds, isLoading } = useGetOrderRefundsQuery(orderId);
    const [ search, setSearch] = useState('');
    const [ sort, setSort] = useState<SortState<Refund>>({ column: 'id', direction: 'asc' });
    const [ sortedRefunds, setSortedRefunds ] = useState(queryRefunds);
    const [ approveDenyRefundIsOpen, setApproveDenyRefundIsOpen ] = useState(false);
    const [ focusedRefund, setFocusedRefund ] = useState<Refund | undefined>(undefined);
    const [ focusedIsInApproval, setFocusedIsInApproval ] = useState<boolean>(false);
    
    // sort
    useEffect(() => {
        setSortedRefunds(refunds => {
            const workingRefunds = [...refunds ?? queryRefunds ?? []];
            return workingRefunds.sort(Utils.sortBy(sort.column, sort.direction));
        });
    }, [sort, queryRefunds]);

    // filter
    useEffect(() => {
        const lowerSearch = search.toLowerCase();
        setSortedRefunds(
            !lowerSearch ? queryRefunds : queryRefunds?.filter(r =>
                (r.orderId?.toString().toLowerCase().includes(lowerSearch)) ||
                (r.status.name?.toLowerCase().includes(lowerSearch)) ||
                (r.amount && CurrencyFormatter.format(r.amount).toLowerCase().includes(lowerSearch)) ||
                (r.category?.name?.toLowerCase()?.includes(lowerSearch)) ||
                (r.type?.name?.toLowerCase()?.includes(lowerSearch)) ||
                (r.reason?.name?.toLowerCase()?.includes(lowerSearch)) ||
                (r.timeCreated && DateTime.fromISO(r.timeCreated + 'Z').toLocaleString(DateTime.DATETIME_SHORT).toLowerCase().includes(lowerSearch)) ||
                (r.creatorEmail?.toLowerCase().includes(lowerSearch)) ||
                (r.storefront?.toLowerCase().includes(lowerSearch)))
        );
    }, [search, queryRefunds]);

    const getActionButtons = (idValue: string | number) => {
        const refundId = typeof idValue === 'string' ? parseInt(idValue) : idValue;
        const matchingRefund = sortedRefunds?.find(sr => sr.id === refundId);
        if (hasPermission([ADMIN, TEAM_LEAD, FINANCE]) && matchingRefund && matchingRefund.status.name === RefundStatusNames.Requested) {
            return (
                <span className="refund-browse-action-button-container">
                <Button color="primary" onClick={() => onApproveClicked(matchingRefund)}>Approve</Button>
                <Button color="danger" onClick={() => onDenyClicked(matchingRefund)}>Deny</Button>
            </span>
            )
        }
        return <></>

    }
    
    const onApproveClicked = (refund: Refund) => {
        setApproveDenyRefundIsOpen(true);

        setFocusedIsInApproval(true);
        setFocusedRefund(refund);
    }

    const onDenyClicked = (refund: Refund) => {
        setApproveDenyRefundIsOpen(true);

        setFocusedIsInApproval(false);
        setFocusedRefund(refund);
    }

    const closeModal = () => {
        setApproveDenyRefundIsOpen(false);
    }

    const config: GridConfig<Refund> = {
        configSections: [
            {
                columns: [
                    new ColumnConfig({
                        field: 'id',
                        display: 'Actions',
                        cellDisplay: val => typeof val === 'string' || typeof val === 'number' ? getActionButtons(val) : '',
                        isSortable: false
                    }),
                    new ColumnConfig({
                        field: 'storefront',
                        display: 'Storefront',
                        isHidden: orderId !== undefined
                    }),
                    new ColumnConfig({
                        field: 'orderId',
                        display: 'Order Id',
                        isHidden: orderId !== undefined,
                        cellDisplay: val => reportValToOrderLink(val as number)
                    }),
                    new ColumnConfig({
                        field: 'status.name',
                        display: 'Status',
                    }),
                    new ColumnConfig({
                        field: 'amount',
                        display: 'Amount',
                        cellDisplay: val => typeof val === 'number' ? CurrencyFormatter.format(val) : '',
                    }),
                    new ColumnConfig({
                        field: 'category.name',
                        display: 'Category',
                    }),
                    new ColumnConfig({
                        field: 'type.name',
                        display: 'Type',
                    }),
                    new ColumnConfig({
                        field: 'reason.name',
                        display: 'Reason',
                    }),
                    new ColumnConfig({
                        field: 'timeCreated',
                        display: 'Time Created',
                        cellDisplay: val => val ? DateTime.fromISO(val as string + 'Z').toLocaleString(DateTime.DATETIME_SHORT) : ''
                    }),
                    new ColumnConfig({
                        field: 'creatorEmail',
                        display: 'Created By'
                    })
                ]
            }
        ]
    };

    return (
        <>
            <GridPage records={sortedRefunds ?? []}
                      gridConfig={config}
                      sort={sort}
                      title={orderId ? '' : 'Pending Refunds'}
                      shouldShowCriteriaPanel={false}
                      onSortChanged={setSort}
                      onSearchChange={setSearch}
                      noRecordsMessage={orderId
                          ? "No refunds exist on record for this order"
                          : "No refunds are currently pending"}
                      isLoading={isLoading}/>


            {focusedRefund && <RefundApproveDeny orderId={focusedRefund?.orderId}
                               requestedRefund={focusedRefund}
                               isApproval={focusedIsInApproval}
                               toggleModal={closeModal}
                               isOpen={approveDenyRefundIsOpen}
            ></RefundApproveDeny>}
        </>
    )
}