import '../Accordions.css';
import {Trans, useTranslation} from "react-i18next";
import { Link } from 'react-router-dom';
import {doesTranslationValueExist, getTranslationValue} from "../../../../../utils/Utils";

export const OrderingCard = () => {
    const { t } = useTranslation();

    return (
       <span>
          <span className='d-flex flex-column'>
            {doesTranslationValueExist(t, 'pages.faq.help.ordering.answer1Part3') ? (
                <span className='d-flex flex-column'>
                  <span className='order-accordion-order-question'>{t(`pages.faq.help.ordering.question1`)}</span>
                  <span>
                    {t(`pages.faq.help.ordering.answer1Part1`)}
                    <a className='link-primary' href={'tel:' + t(`phoneNumber.number`)}>{t(`phoneNumber.display`)}</a>
                    {t(`pages.faq.help.ordering.answer1Part2`)}
                    <a className='link-primary' href={'tel:' + t(`phoneNumber.number`)}>{t(`phoneNumber.display`)}</a>
                    {t(`pages.faq.help.ordering.answer1Part3`)}
                  </span>
                </span>
            ) : (
              <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{t(`pages.faq.help.ordering.question1`)}</span>
                <span>{t(`pages.faq.help.ordering.answer1`)}</span>
                <ol>
                  <li>
                    {getTranslationValue(t, 'pages.faq.help.ordering.answer1ListItem1')}
                    <a href={getTranslationValue(t, 'pages.faq.help.ordering.answer1ListItem1Link')}>
                      {getTranslationValue(t, 'pages.faq.help.ordering.answer1ListItem1LinkText')}
                    </a>.
                  </li>
                  <li>
                    {getTranslationValue(t, 'pages.faq.help.ordering.answer1ListItem2')}
                    <a href={getTranslationValue(t, 'pages.faq.help.ordering.answer1ListItem2Link')}>
                      {getTranslationValue(t, 'pages.faq.help.ordering.answer1ListItem2LinkText')}
                    </a>
                    {getTranslationValue(t, 'pages.faq.help.ordering.answer1ListItem2Pt2')}.
                  </li>
                </ol>
              </span>
            )}
          </span>
          <span className='d-flex flex-column'>
              <span className='order-accordion-order-question'>{t(`pages.faq.help.ordering.question2`)}</span>
              {doesTranslationValueExist(t, 'pages.faq.help.ordering.answer2ListItem1') ? (
                <ol>
                  <li>
                    {getTranslationValue(t, `pages.faq.help.ordering.answer2ListItem1`)} <a href='/' target='_blank'>
                    {getTranslationValue(t, `pages.faq.help.ordering.answer2Website`)}
                  </a>
                  </li>
                  <li>
                    {getTranslationValue(t, `pages.faq.help.ordering.answer2ListItem2Part1`)}
                    <a href={'tel:' + getTranslationValue(t, `phoneNumber.number`)}>
                      {getTranslationValue(t, `phoneNumber.display`)}
                    </a>,
                    {getTranslationValue(t, `pages.faq.help.ordering.answer2ListItem2Part2`)}
                  </li>
                  <li>
                    {getTranslationValue(t, `pages.faq.help.ordering.answer2ListItem3`)}
                    {getTranslationValue(t, `address.name`)} {getTranslationValue(t, `address.part1`)} {getTranslationValue(t, `address.part2`)}.
                  </li>
                </ol>
              ) : (
                <span>{getTranslationValue(t, `pages.faq.help.ordering.answer2Part1`)}</span>
              )}
          </span>
          <span className='d-flex flex-column'>
              <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question3`)}</span>
              <span>{getTranslationValue(t, `pages.faq.help.ordering.answer3`)}</span>
          </span>
          <span className='d-flex flex-column'>
              <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question4`)}</span>
              {doesTranslationValueExist(t, 'pages.faq.help.ordering.answer4ListItem3') ? (
                <ul>
                  <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer4ListItem1')}</li>
                  <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer4ListItem2')}</li>
                  <li> <Trans i18nKey={'pages.faq.help.ordering.answer4ListItem3'} components={{ 1: <br /> }} /></li>
                </ul>
              ) : (
                <span>
                  {getTranslationValue(t, `pages.faq.help.ordering.answer4Part1`)}
                  <a className='link-primary' href={'tel:' + getTranslationValue(t, `phoneNumber.number`)}>{getTranslationValue(t, `phoneNumber.display`)}</a>
                  {getTranslationValue(t, `pages.faq.help.ordering.answer4Part2`)}
              </span>
              )}
          </span>
          <span className='d-flex flex-column'>
              <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question5`)}</span>
              {doesTranslationValueExist(t, 'answer5Item3') ? (
                <>
                  <span>{getTranslationValue(t, `pages.faq.help.ordering.answer5Item1`)}</span>
                  <span>{getTranslationValue(t, `pages.faq.help.ordering.answer5Item2`)}</span>
                  <span>{getTranslationValue(t, `pages.faq.help.ordering.answer5Item3`)}</span>
                  <span>{getTranslationValue(t, `pages.faq.help.ordering.answer5Body`)}</span>
                </>
              ) : (
                <span><Trans i18nKey='pages.faq.help.ordering.answer5' components={{ 1: <br /> }} /></span>
              )}
          </span>
          <span className='d-flex flex-column'>
              <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question6`)}</span>
              <span>{getTranslationValue(t, `pages.faq.help.ordering.answer6`)}</span>
          </span>
          <span className='d-flex flex-column'>
              <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question7`)}</span>
              <span>{getTranslationValue(t, `pages.faq.help.ordering.answer7`)}</span>
          </span>
          <span className='d-flex flex-column'>
              <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question8`)}</span>
              <span>{getTranslationValue(t, `pages.faq.help.ordering.answer8`)}</span>
          </span>
          
          <span className='d-flex flex-column'>
              <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question9`)}</span>
              {doesTranslationValueExist(t, 'pages.faq.help.ordering.answer9MailItem1Part2') ? (
                <>
                  <span>{getTranslationValue(t, `pages.faq.help.ordering.answer9Part1`)}</span>
                  <span>
                  {getTranslationValue(t, `pages.faq.help.ordering.answer9MailItem1Part1`)}
                    (<Link className='link-primary' to={'/category/free-shipping-checks'}>{getTranslationValue(t, `pages.faq.help.ordering.answer9MailItemLinkText`)}</Link>)
                    {getTranslationValue(t, `pages.faq.help.ordering.answer9MailItem1Part2`)}
                  </span>
                  <span>{getTranslationValue(t, `pages.faq.help.ordering.answer9MailItem2`)}</span>
                  <span>{getTranslationValue(t, `pages.faq.help.ordering.answer9MailItem3`)}</span>
                  <span>{getTranslationValue(t, `pages.faq.help.ordering.answer9MailItem4`)}</span>
                  <span>{getTranslationValue(t, `pages.faq.help.ordering.answer9MailItem5`)}</span>
                </>
              ) : (
                <span>{getTranslationValue(t, 'pages.faq.help.ordering.answer9')}</span>
              )}
              
          </span>
          
          <span className='d-flex flex-column'>
              <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question10`)}</span>
              {doesTranslationValueExist(t, 'pages.faq.help.ordering.answer10ListItem5') ? (
                <ol>
                  <li>
                    <span>{getTranslationValue(t, `pages.faq.help.ordering.answer10ListItem1`)}</span>
                  </li>
                  <li>
                    <span>{getTranslationValue(t, `pages.faq.help.ordering.answer10ListItem2`)}</span>
                  </li>
                  <li>
                    <span>{getTranslationValue(t, `pages.faq.help.ordering.answer10ListItem3`)}</span>
                  </li>
                  <li>
                    <span>{getTranslationValue(t, `pages.faq.help.ordering.answer10ListItem4`)}</span>
                  </li>
                  <li>
                    <span>{getTranslationValue(t, `pages.faq.help.ordering.answer10ListItem5`)}</span>
                  </li>
                </ol>
              ) : (
                <span>
                  {getTranslationValue(t, 'pages.faq.help.ordering.answer10')}
                  <a href={getTranslationValue(t, 'pages.faq.help.ordering.answer10Link')}>
                    {getTranslationValue(t, 'pages.faq.help.ordering.answer10LinkText')}
                  </a>
                  {getTranslationValue(t, 'pages.faq.help.ordering.answer10Pt2')}
                </span>
              )}
          </span>
          <span className='d-flex flex-column'>
              <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question11`)}</span>
              {doesTranslationValueExist(t, 'pages.faq.help.ordering.answer11ListItem6') ? (
                <span>
                  <Trans i18nKey='pages.faq.help.ordering.answer11' components={{ 1: <br /> }} />
                  <ul>
                    <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer11ListItem1')}</li>
                    <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer11ListItem2')}</li>
                    <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer11ListItem3')}</li>
                    <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer11ListItem4')}</li>
                    <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer11ListItem5')}</li>
                    <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer11ListItem6')}</li>
                  </ul>
                  <br />
                  {getTranslationValue(t, 'pages.faq.help.ordering.answer11Pt2')}
                </span>
              ) : (
                <span>
                  {getTranslationValue(t, `pages.faq.help.ordering.answer11Part1`)}
                  <a className='link-primary' href={'tel:' + getTranslationValue(t, `phoneNumber.number`)}>{getTranslationValue(t, `phoneNumber.display`)}</a>
                  {getTranslationValue(t, `pages.faq.help.ordering.answer11Part2`)}
                  <a className='link-primary' href={'mailto:' + getTranslationValue(t, `emails.customerService`)}>{getTranslationValue(t, `emails.customerService`)}.</a>
                </span>
              )}
          </span>
          <span className='d-flex flex-column'>
              <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question12`)}</span>
              {doesTranslationValueExist(t, 'pages.faq.help.ordering.answer12ListItem5') ? (
                <ol>
                  <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer12ListItem1')}</li>
                  <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer12ListItem2')}</li>
                  <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer12ListItem3')}</li>
                  <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer12ListItem4')}</li>
                  <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer12ListItem5')}</li>
                </ol>
              ) : (
                <span>{getTranslationValue(t, `pages.faq.help.ordering.answer12`)}</span>
              )}
          </span>
         
         {doesTranslationValueExist(t, 'pages.faq.help.ordering.question13') && (
           <span className='d-flex flex-column'>
             <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question13`)}</span>
                <span>{getTranslationValue(t, `pages.faq.help.ordering.answer13`)}</span>
            </span>
          </span>
         )}

         {doesTranslationValueExist(t, 'pages.faq.help.ordering.question14') && (
           <span className='d-flex flex-column'>
             <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question14`)}</span>
                <span>{getTranslationValue(t, `pages.faq.help.ordering.answer14`)}</span>
            </span>
          </span>
         )}

         {doesTranslationValueExist(t, 'pages.faq.help.ordering.question15') && (
           <span className='d-flex flex-column'>
             <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question15`)}</span>
                <span>{getTranslationValue(t, `pages.faq.help.ordering.answer15`)}</span>
            </span>
          </span>
         )}

         {doesTranslationValueExist(t, 'pages.faq.help.ordering.question16') && (
           <span className='d-flex flex-column'>
             <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question16`)}</span>
                <span>{getTranslationValue(t, `pages.faq.help.ordering.answer16`)}</span>
               <ol>
                 <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer16ListItem1')}</li>
                 <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer16ListItem2')}</li>
                 <li>{getTranslationValue(t, 'pages.faq.help.ordering.answer16ListItem3')}</li>
               </ol>
            </span>

               {doesTranslationValueExist(t, 'pages.faq.help.ordering.answer16Pt2') && (
                   <span className='d-flex flex-column'>
                       <span>
                           {getTranslationValue(t, `pages.faq.help.ordering.answer16Pt2`)}
                           <a href={getTranslationValue(t, `pages.faq.help.ordering.answer16Link1`)} target='_blank'>
                               {getTranslationValue(t, `pages.faq.help.ordering.answer16Link1Text`)}
                           </a>
                           {getTranslationValue(t, `pages.faq.help.ordering.answer16Pt3`)}
                           <a href={getTranslationValue(t, `pages.faq.help.ordering.answer16Link2`)} target='_blank'>
                               {getTranslationValue(t, `pages.faq.help.ordering.answer16Link2Text`)}
                           </a>
                       </span>
                   </span>
               )}
          </span>
         )}

         {doesTranslationValueExist(t, 'pages.faq.help.ordering.question17') && (
           <span className='d-flex flex-column'>
             <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question17`)}</span>
                <span>
                  {getTranslationValue(t, `pages.faq.help.ordering.answer17`)}
                  <a href={getTranslationValue(t, `pages.faq.help.ordering.answer17Link`)} target='_blank'>
                    {getTranslationValue(t, `pages.faq.help.ordering.answer17LinkText`)}
                  </a>
                  {getTranslationValue(t, `pages.faq.help.ordering.answer17Pt2`)}
                  <br />
                  <br />
                  {getTranslationValue(t, `pages.faq.help.ordering.answer17Pt3`)}
                </span>
            </span>
          </span>
         )}

         {doesTranslationValueExist(t, 'pages.faq.help.ordering.question18') && (
           <span className='d-flex flex-column'>
             <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question18`)}</span>
                <span>{getTranslationValue(t, `pages.faq.help.ordering.answer18`)}</span>
            </span>
          </span>
         )}

         {doesTranslationValueExist(t, 'pages.faq.help.ordering.question19') && (
           <span className='d-flex flex-column'>
             <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question19`)}</span>
                <span>
                  {getTranslationValue(t, `pages.faq.help.ordering.answer19`)}
                  <a href={getTranslationValue(t, `pages.faq.help.ordering.answer19Link1`)} target='_blank'>
                    {getTranslationValue(t, `pages.faq.help.ordering.answer19Link1Text`)}
                  </a>.
                  {getTranslationValue(t, `pages.faq.help.ordering.answer19Pt2`)}
                  <a href={'tel:' + getTranslationValue(t, `pages.faq.help.ordering.answer19Link2`)} target='_blank' rel='noreferrer'>
                    {getTranslationValue(t, `pages.faq.help.ordering.answer19Link2Text`)}
                  </a>
                  {getTranslationValue(t, `pages.faq.help.ordering.answer19Pt3`)}
                  <a href={getTranslationValue(t, `pages.faq.help.ordering.answer19Link3`)} target='_blank'>
                    {getTranslationValue(t, `pages.faq.help.ordering.answer19Link3Text`)}
                  </a>.
                </span>
            </span>
          </span>
         )}

         {doesTranslationValueExist(t, 'pages.faq.help.ordering.question20') && (
           <span className='d-flex flex-column'>
             <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.ordering.question20`)}</span>
                <span>
                  {getTranslationValue(t, `pages.faq.help.ordering.answer20`)}
                  <a href={getTranslationValue(t, `pages.faq.help.ordering.answer20Link`)} target='_blank' rel='noreferrer'>
                    {getTranslationValue(t, `pages.faq.help.ordering.answer20LinkText`)}
                  </a>.
               </span>
              <br />
               <span>
                 {getTranslationValue(t, `pages.faq.help.ordering.answer20Pt2`)}
               </span>
               <br />
               <span>
                 {getTranslationValue(t, `pages.faq.help.ordering.answer20Pt3`)}
               </span>
              <br />
              <span>
                 {getTranslationValue(t, `pages.faq.help.ordering.answer20Pt4`)}
               </span>
               <br />
               <span>
                 {getTranslationValue(t, `pages.faq.help.ordering.answer20Pt5`)}
               </span>
            </span>
          </span>
         )}
       </span>
    );
};