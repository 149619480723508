
export interface ProductInputValue {
  productInputId: number,
  configurationId: number,
  name: string,
  value: string | null,
  isDirty: boolean,
  selectedProductInputOptionId?: number,
  selectedSiteProductVariantQuantityOptionId?: number,
  inputClassification: InputClassification,
  isValidated?: boolean,
  isValidating?: boolean,
  isComplete?: boolean,
  configurationKey?: string
  showWarning?: boolean,
  warningMessage?: string
  isSecure: boolean
}

export enum InputClassification {
  Configuration = "Configuration",
  Component = "Component",
  Quantity = "Quantity"
}

export interface ValidateInputRequest {
  configurationId: number,
  stringValue: string
}