import './Subscribe.css';
import {useTranslation} from "react-i18next";
import {Button, Form, FormGroup, Container, Spinner} from "reactstrap";
import { useSubscribeMutation } from '../../../app/apiSlice';
import { ChangeEvent, useState } from 'react';
import { SimpleToast } from '../../input/utils/SimpleToast';
import { EmailInput } from "../../input/email/EmailInput";
import {LoadingSpinner} from "../../input/utils/LoadingSpinner";

export const Subscribe = () => {
    const { t } = useTranslation();
    const [subscribeMethod, {isLoading}] = useSubscribeMutation();
    const [ showSuccessToast, setShowSuccessToast ] = useState(false);
    const [ showFailedToast, setShowFailedToast ] = useState(false);
    const [ successToastText, setSuccessToastText ] = useState("");
    const [ email, setEmail ] = useState('');
    const [ errors, setErrors ] = useState<string>();

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setErrors(
            e.target.value === 'help@checkworks.com' || e.target.value === 'customerservice@checkworks.com' ?
            t('pages.contact.errors.email') :
            undefined
        );
    }

    const submit = async () => {
        // exit early if there are errors
        if (errors || !email) return;

        try {
            const subscribeResponse = await subscribeMethod({ email }).unwrap();
            setSuccessToastText(subscribeResponse.wasAlreadySubscribed ? t(`footer.subscribe.alreadySubscribed`) : t(`footer.subscribe.successModal`));
            setShowSuccessToast(true);            
            setTimeout(function() {
                setShowSuccessToast(false);
            }, 10000);
        } catch (err) {
            setShowFailedToast(true);
            setTimeout(function() {
                setShowFailedToast(false);
            }, 10000);
        }
    };

    return (
        <div className='subscribe-section d-flex d-column'>
            <Container className='subscribe-content d-flex'>
                <div className='subscribe-text-container d-flex flex-column'>
                    <h2 className='subscribe-title-text'>
                        {t(`footer.subscribe.title`)}
                    </h2>
                    <p className='subscribe-body-text'>
                        {t(`footer.subscribe.body`)}
                    </p>
                </div>
                <div className='d-flex flex-column'>
                    <Form>
                        <FormGroup className='subscribe-email-capture d-flex'>
                            <EmailInput className={errors ? 'subscribe-error-field subscribe-email-input' : 'subscribe-email-input'}
                                type='email' placeholder={t(`footer.subscribe.inputPlaceholder`)} onChange={onEmailChange} value={email} suppressError={!email}/>
                            <Button className='subscribe-email-button d-flex flex-row' color='secondary' onClick={submit} disabled={!email}>
                                {isLoading && <Spinner></Spinner>}
                                {t(`footer.subscribe.buttonText`)}
                            </Button>
                        </FormGroup>
                    </Form>
                    {errors &&  <span style={{color: "red"}}>{errors}</span>}
                </div>
                <span className='subscribe-toast'>
                    <SimpleToast isOpen={showSuccessToast}
                                 toastText={successToastText}
                                 dismissToast={() => setShowSuccessToast(false)}
                                 isErrorToast={false}/>
                </span>
                <span className='subscribe-toast'>
                    <SimpleToast isOpen={showFailedToast}
                                toastText="There was an issue subscribing. Please try again later."
                                dismissToast={() => setShowFailedToast(false)}
                                isErrorToast={true}/>
                </span>
            </Container>
        </div>
    );
}