import { useMsal } from "@azure/msal-react";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";

export const Unauthorized = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const loggedInAccounts = instance.getAllAccounts()
    .map(account => account.name)
    .join(', ');
  
  return (
    <div className='p-3'>
      <div>Sorry, but you are not authorized to access this part of the application.</div>
      <div>If you believe this to be in error, please contact support.</div>
      { loggedInAccounts && <div>Currently logged in as: {loggedInAccounts}</div> }
      <div className='d-flex flex-row'>
        <Button className='mx-2' onClick={() => instance.logoutRedirect()}>Logout</Button>
        <Button className='mx-2' onClick={() => navigate('/')}>Go to Storefront</Button>
      </div>
    </div>
  ); 
}