import './ReturnPolicy.css';
import {useTranslation} from "react-i18next";
import ScrollToTop from '../../helpers/ScrollToTop';
import { Breadcrumb } from '../../breadcrumb/Breadcrumb';
import {Container} from 'reactstrap';
import {doesTranslationValueExist, getTranslationValue} from "../../../utils/Utils";
import {Helmet} from "react-helmet-async";

export const ReturnPolicy = () => {
    const { t } = useTranslation();

    function getDefaultDocumentMetadata () {
      return (
          <Helmet>
            <title>{t(`pages.returnPolicy.metaTitle`)}</title>
            <meta name="description" content={t(`pages.returnPolicy.metaDescription`)} />
          </Helmet>
      )
    }

    return (
        <div className='return-page d-flex flex-column'>
            <ScrollToTop></ScrollToTop>
            {getDefaultDocumentMetadata()}
            <Container>
                <Breadcrumb currentPage={getTranslationValue(t, 'pages.returnPolicy.title')}></Breadcrumb>
                <div className='return-container d-flex flex-column'>
                    <div className='return-title d-flex'>
                        <h1 className=''>{getTranslationValue(t, 'pages.returnPolicy.title')}</h1>
                    </div>
                    <div className='return-body d-flex flex-column'>
                        {doesTranslationValueExist(t, 'pages.returnPolicy.header1') &&
                          <span className='return-body-text'>
                              {getTranslationValue(t, 'pages.returnPolicy.header1')}
                          </span>
                        }
                        {doesTranslationValueExist(t, 'pages.returnPolicy.subTitle1') &&
                          <span className='return-body-text ps-4 pt-3'>
                            <span>
                              <span className='bolded-text'>{getTranslationValue(t, 'pages.returnPolicy.subTitle1')}</span>
                              <span className="p-1">{getTranslationValue(t, 'pages.returnPolicy.body1')}</span>
                            </span>
                          </span>
                        }
                        {doesTranslationValueExist(t, 'pages.returnPolicy.subTitle2') &&
                          <span className='return-body-text ps-4 pt-3'>
                            <span>
                              <span className='bolded-text'>{getTranslationValue(t, 'pages.returnPolicy.subTitle2')}</span>
                              <span className="p-1">{getTranslationValue(t, 'pages.returnPolicy.body2')}</span>
                            </span>
                          </span>
                        }
                        {doesTranslationValueExist(t, 'pages.returnPolicy.subTitle3') &&
                          <span className='return-body-text ps-4 pt-3'>
                            <span>
                              <span className='bolded-text'>{getTranslationValue(t, 'pages.returnPolicy.subTitle3')}</span>
                              <span className="p-1">{getTranslationValue(t, 'pages.returnPolicy.body3')}</span>
                            </span>
                          </span>
                        }
                        {doesTranslationValueExist(t, 'pages.returnPolicy.subTitle4') &&
                          <span className='return-body-text ps-4 pt-3 pb-3'>
                            <span>
                              <span className='bolded-text'>{getTranslationValue(t, 'pages.returnPolicy.subTitle4')}</span>
                              <span className="p-1">{getTranslationValue(t, 'pages.returnPolicy.body4')}</span>
                            </span>
                          </span>
                        }
                        {doesTranslationValueExist(t, 'pages.returnPolicy.header2') &&
                          <span className='return-body-text'>
                            {getTranslationValue(t, 'pages.returnPolicy.header2')}
                          </span>
                        }
                        {doesTranslationValueExist(t, 'pages.returnPolicy.letteredPointA') &&
                          <span className='return-body-text ps-4 pt-3'>
                            <span>
                              <span className='bolded-text'>a.</span>
                              <span className="p-1">{getTranslationValue(t, 'pages.returnPolicy.letteredPointA')}</span>
                            </span>
                          </span>
                        }
                        {doesTranslationValueExist(t, 'pages.returnPolicy.letteredPointB') &&
                          <span className='return-body-text ps-4 pt-3'>
                            <span>
                              <span className='bolded-text'>b.</span>
                              <span className="p-1">{getTranslationValue(t, 'pages.returnPolicy.letteredPointB')}</span>
                            </span>
                          </span>
                        }
                        {doesTranslationValueExist(t, 'pages.returnPolicy.letteredPointC') &&
                          <span className='return-body-text ps-4 pt-3'>
                            <span>
                              <span className='bolded-text'>c.</span>
                              <span className="p-1">{getTranslationValue(t, 'pages.returnPolicy.letteredPointC')}</span>
                            </span>
                          </span>
                        }
                        {doesTranslationValueExist(t, 'pages.returnPolicy.letteredPointD') &&
                          <span className='return-body-text ps-4 pt-3'>
                            <span>
                              <span className='bolded-text'>d.</span>
                              <span className="p-1">{getTranslationValue(t, 'pages.returnPolicy.letteredPointD')}</span>

                              <span className='d-flex flex-column ps-4'>
                                <span>{t(`address.name`)}</span>
                                <span>{t(`address.part1`)}</span>
                                <span>{t(`address.part2`)}</span>
                              </span>
                            </span>
                          </span>
                        }
                        {doesTranslationValueExist(t, 'pages.returnPolicy.letteredPointE') &&
                          <span className='return-body-text ps-4 pt-3 pb-3'>
                            <span>
                              <span className='bolded-text'>e.</span>
                              <span className="p-1">{getTranslationValue(t, 'pages.returnPolicy.letteredPointE')}</span>
                            </span>
                          </span>
                        }
                        {doesTranslationValueExist(t, 'pages.returnPolicy.footer1') &&
                          <span className='return-body-text'>
                            {t(`pages.returnPolicy.footer1`)}

                            <a href={'tel:' + getTranslationValue(t, 'phoneNumber.number')} className='return-body-link'>
                                {getTranslationValue(t, 'phoneNumber.display')}
                            </a>

                            {getTranslationValue(t, 'pages.returnPolicy.footer2')}

                            <a href={'mailto:' + getTranslationValue(t, 'emails.customerService')} className='return-body-link'>
                                {getTranslationValue(t, 'emails.customerService')}
                            </a>.
                          </span>
                        }
                    </div>
                </div>
            </Container>
        </div>
    );
};