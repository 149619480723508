import {VendorUnitStatus} from "./VendorUnitStatus";

export interface VendorOrderProductStatus {
    vendorProductId: number,
    orderProductId: number,
    quantity: number,
    vendorOrderProductStatusName: string,
    units: VendorUnitStatus[]
}

export enum VendorStatusName {
    Ready = "Ready",
    Shipped = "Shipped",
    InProcess = "InProcess",
    Canceled = "Canceled",
    Finalized = "Finalized"
}