import './AddToCartPreview.css';
import {SiteProductVariant} from "../../product/SiteProductVariant";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {removeProductInputValueByConfigurationDetailId, selectAccessoryProductVariantQuantityOptionId, selectSelectedConfigurationsRequest} from "../../input/ProductInputSlice";
import {ComponentOption} from "../../input/component/ComponentOption";
import {SelectedConfigurationsRequest} from "../../input/configuration/Configuration";
import {LineItemClassification} from "./AddToCartPreviewLineItem";
import {CartPreviewDisclaimer} from "../previewDisclaimer/CartPreviewDisclaimer";
import {useGetComponentsForVariantQuery, useGetSiteProductVariantWithConfigurationsPricingQuery} from "../../../app/apiSlice";
import {SiteProductVariantQuantityOption} from "../../product/SiteProductVariantQuantityOption";
import {ConfigurationPricing} from "../CartPricing";
import {skipToken} from "@reduxjs/toolkit/query";
import {Fragment} from "react";

interface AddToCartPreviewProps {
    siteProductVariant: SiteProductVariant;
    quantityOption: SiteProductVariantQuantityOption;
    isReadOnly: boolean;
}

export const AddToCartPreview = ({ siteProductVariant, quantityOption, isReadOnly }: AddToCartPreviewProps) => {
    const { data: components } = useGetComponentsForVariantQuery(siteProductVariant.id);
    const priceRequest = useAppSelector(s => selectSelectedConfigurationsRequest(s, quantityOption.id));
    const accessorySiteProductQuantityOptionId = useAppSelector(selectAccessoryProductVariantQuantityOptionId);
    const { data: siteProductVariantPricing } = useGetSiteProductVariantWithConfigurationsPricingQuery(priceRequest);
    const { data: accessoryPricing } = useGetSiteProductVariantWithConfigurationsPricingQuery(accessorySiteProductQuantityOptionId ? { siteProductVariantQuantityOptionId: accessorySiteProductQuantityOptionId } as SelectedConfigurationsRequest : skipToken);
    const dispatch = useAppDispatch();
    
    const getAccessoriesToRender = () => {
        if (accessoryPricing)        
            return (
                <>
                    <div className='add-to-cart-preview-line-item'>
                        <span>{accessoryPricing.name}</span>
                        <span className='bolded-text add-to-cart-preview-line-item-price'>{CurrencyFormatter.format(accessoryPricing.basePrice)}</span>
                    </div>
                    <hr />
                </>
            )
    }
    
    function getComponentOptionDisplayValue(componentOptions?: ComponentOption[]) {
        let returnValue = "";

        if (componentOptions) {
            componentOptions.forEach((co) => {
                if (co.description) {
                    returnValue += co.description;
                }
            })
        }

        return returnValue;
    }


    const getLineItemTotal = (configurationPricing: ConfigurationPricing) => (
        <Fragment key={configurationPricing.configurationId}>
            <div className='add-to-cart-preview-line-item'>
                <span>{configurationPricing.label}</span>
                <span className="bolded-text add-to-cart-preview-line-item-price">{CurrencyFormatter.format(configurationPricing.price)}</span>
                {!isReadOnly && <span className="add-to-cart-preview-remove-button-container">
                    <span className="add-to-cart-preview-remove-button"
                        onClick={() => dispatch(removeProductInputValueByConfigurationDetailId(configurationPricing.configurationDetailId))}>
                        Remove
                    </span>
                </span>}
            </div>
            <hr />
        </Fragment>
    );

    function getConfigurationLineItems() {
        if (!siteProductVariantPricing?.configurationTotals) {
            return <></>;
        }

        return siteProductVariantPricing.configurationTotals
            .filter(ct => ct.classification === LineItemClassification.Configuration.toString() && ct.price > 0)
            .map(lineItem => getLineItemTotal(lineItem))
    }

    function getAddOnLineItems() {
        if (!siteProductVariantPricing?.configurationTotals) {
            return <></>;
        }

        return siteProductVariantPricing.configurationTotals
            .filter(ct => ct.classification === LineItemClassification.AddOn.toString() && ct.price > 0)
            .map(lineItem => getLineItemTotal(lineItem))
    }

    function getBasePrice() {
        if (!siteProductVariantPricing) {
            return "";
        }
        return CurrencyFormatter.format(siteProductVariantPricing.basePrice);
    }

    function getSubtotal() {
        if (!siteProductVariantPricing) {
            return "";
        }
        return CurrencyFormatter.format(siteProductVariantPricing.productTotal + (accessoryPricing?.subTotal ?? 0));
    }

    return (
        <div className="add-to-cart-preview-outer-container">

            <div className="add-to-cart-preview-container">

                <div className="add-to-cart-preview-header">
                    <h3 className="add-to-cart-preview-variant-name">Item Summary</h3>
                </div>

                <div className="add-to-cart-preview-block">
                    <h5>{siteProductVariant.name}</h5>
                    <span>{getBasePrice()}</span>
                </div>

                {components?.map((component) => {
                    return (
                        <div className="add-to-cart-preview-block"
                             key={component.id}>
                            {component.reviewLabel ?? component.name}: {getComponentOptionDisplayValue(component.componentOptions)}
                        </div>
                    )
                })}

                {getConfigurationLineItems()}

                {getAccessoriesToRender()}

                {getAddOnLineItems()}


                <div className="add-to-cart-preview-line-item">
                    <span className="bolded-text">SubTotal:</span>
                    <span className="bolded-text add-to-cart-preview-line-item-price">{getSubtotal()}</span>
                </div>
                
                <span className="add-to-cart-preview-line-item-end"></span>

                {!isReadOnly && <CartPreviewDisclaimer></CartPreviewDisclaimer>}

            </div>
        </div>
    );
}