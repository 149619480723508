import {DependencyList, Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import type {AppDispatch, RootState} from './store';
import {ConfigurationRule, RuleTypes} from "../components/input/configuration/rule/ConfigurationRule";
import {selectAllInputValues} from "../components/input/ProductInputSlice";
import {ConfigurationRuleEvaluator} from "../components/input/configuration/rule/ConfigurationRuleEvaluator";
import {useGetClaimsQuery} from "./apiSlice";
import {EDITING_ROLES, hasAdmin, hasPermission} from "../utils/Roles";
import {autocompleteAddress, validateAddress} from "../utils/SmartyHelper";
import {Address, AddressInputVm} from '../components/input/address/Address';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useElementIsOnScreen = (deps: DependencyList, options?: IntersectionObserverInit) => {
	const containerRef = useRef(null);
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(entries => {
			const [entry] = entries;
			setIsVisible(entry.isIntersecting);
		}, options);

		if (containerRef.current)
			observer.observe(containerRef.current);

		return () => observer.disconnect();
	}, [containerRef, deps, options]);

	return [containerRef, isVisible] as const;
}

interface ConfigurationRulesState {
	inputIsInvalid: boolean,
	inputShouldBeDisabled: boolean,
	disabledMetaTag?: string,
	brokenRuleMessage?: string,
	disabledRuleMessage?: string,
	brokenRules: ConfigurationRule[]
}

export const useConfigurationRules = (rules?: ConfigurationRule[], currentValue?: string | null, productVariantId?: number, ruleTypesToEvaluate?: RuleTypes[], bypass: boolean = false): ConfigurationRulesState => {
	const otherInputs = useAppSelector(selectAllInputValues);
	const [state, setState] = useState<ConfigurationRulesState>({
		inputIsInvalid: false,
		inputShouldBeDisabled: false,
		brokenRules: []
	});

	useEffect(() => {
		if (!currentValue) { // reset if no value
			setState({
				inputIsInvalid: false,
				inputShouldBeDisabled: false,
				disabledMetaTag: undefined,
				brokenRuleMessage: undefined,
				disabledRuleMessage: undefined,
				brokenRules: []
			});
		} else if (!bypass && rules && rules.length > 0) {
			const brokenRules = ConfigurationRuleEvaluator.evaluateRules(
				rules,
				otherInputs,
				productVariantId,
				[RuleTypes.Matching, RuleTypes.Differing, RuleTypes.PositiveMatch, RuleTypes.NegativeMatch]);
			const brokenDisabledRules = ConfigurationRuleEvaluator.evaluateRules(rules, otherInputs, productVariantId, [RuleTypes.DisabledWhenValueExists]);

			setState({
				inputIsInvalid: brokenRules.length !== 0,
				inputShouldBeDisabled: brokenDisabledRules.length !== 0,
				disabledMetaTag: brokenDisabledRules[0] ? brokenDisabledRules[0].metaTag : undefined,
				brokenRuleMessage: brokenRules[0] ? brokenRules[0].ruleFailedMessage : undefined,
				disabledRuleMessage: brokenDisabledRules[0] ? brokenDisabledRules[0].ruleFailedMessage : undefined,
				brokenRules: brokenRules
			});
		}
	}, [currentValue, otherInputs, rules]);

	return state;
}

interface ClaimsState {
	claimsAreLoaded: boolean,
	hasClaims: boolean,
	hasAdmin: boolean,
	hasEditingRole: boolean,
	hasPermission: (roles: string[]) => boolean,
}

export const useClaims = (): ClaimsState => {
	const {data: claims, isLoading} = useGetClaimsQuery();
	const [state, setState] = useState<ClaimsState>({
		claimsAreLoaded: false,
		hasClaims: false,
		hasAdmin: false,
		hasEditingRole: false,
		hasPermission: roles => false,
	});

	// on claims update
	useEffect(() => {
		setState({
			claimsAreLoaded: !isLoading,
			hasClaims: !!claims,
			hasAdmin: hasAdmin(claims),
			hasEditingRole: hasPermission(claims ?? [], EDITING_ROLES),
			hasPermission: roles => hasPermission(claims ?? [], roles),
		})
	}, [claims, isLoading])

	return state;
}

type ErrorFunc = (value?: string) => string | undefined;

interface ValidationState {
	value?: string,
	getError: ErrorFunc,
}

export interface ValidationHook {
	getError: (key: string) => string | undefined,

	// call this to get errors to display (prevents us from harassing user before they do input)
	allValid: () => boolean,
	// call this to check if is invalid without actively error message display state
	isActivelyInvalid: () => boolean,
	setValue: (key: string, val?: string) => void,
	getValue: (key: string) => string | undefined,
	hideErrors: (shouldHideErrors: boolean) => void,
	shouldShowErrors: boolean,

	// returns a validation hook for a certain subpath of this hook's values.
	createProxy: (prefix: string) => ValidationHook,
}

export const useValidation = (input: { [index: string]: ErrorFunc }): ValidationHook => {
	const [state, setState] = useState<Map<string, ValidationState>>(createMapFromInput());
	const [showErrors, setShowErrors] = useState(false); // don't show errors until we're ready


	// Refreshes the state error function callbacks on each build cycle
	useEffect(() => {
		const updated = createMapFromInput();

		// Sets the new state object values from the new state map from the input
		Array.from(updated.entries()).forEach(([key, validationState]) => {
			const current = state.get(key);

			// Updates the old Validation state with the new error provider but leaves the value the same
			if (current)
				current.getError = validationState.getError;

		});

	}, [input]);

	function createMapFromInput() {
		return new Map(Object.entries(input).map(([k, v]) => ([k, {getError: v}])));
	}

	const getError = (key: string) => {
		if (!showErrors) return undefined; // don't bother users until we try to submit form

		const validation = state.get(key);
		return validation?.getError(validation?.value);
	};

	const isActivelyInvalid = () => {
		if (!showErrors)
			return false;
		return Array.from(state.values()).some(v => v.getError(v.value));
	}

	const allValid = () => {
		setShowErrors(true);
		// return NOT there exists some error
		return !Array.from(state.values()).some(v => v.getError(v.value))
	};

	const setValue = (key: string, val?: string) => {
		let old = state.get(key);

		if (old) old.value = val;
		else old = {value: val, getError: _ => undefined};

		setState(map => new Map(map.set(key, old!)));
	};

	const getValue = (key: string) => {
		const item = state.get(key);
		return item?.value?.toString();
	};

	const hideErrors = (shouldHideErrors: boolean) => {
		setShowErrors(!shouldHideErrors);
	};

	const createProxy = (prefix: string): ValidationHook => ({
		getError: key => getError(`${prefix}_${key}`),
		allValid,
		isActivelyInvalid,
		setValue: (key, value) => setValue(`${prefix}_${key}`, value),
		getValue: key => getValue(key),
		hideErrors,
		shouldShowErrors: showErrors,
		createProxy: subPrefix => createProxy(`${prefix}_${subPrefix}`),
	});

	return {
		getError,
		allValid,
		isActivelyInvalid,
		setValue,
		getValue,
		hideErrors,
		shouldShowErrors: showErrors,
		createProxy,
	}
}

export const validationRequired = (name: string) => (val?: string) => !val ? `${name} is required.` : undefined;

export const useShippingAddressValidation = (input: { [index: string]: ErrorFunc } = {}): ValidationHook => {
	const validEmailRequired = 'A valid email address is required.';
	const emailAddressesMustMatch = 'The email addresses must match.';
	const validationHook = useValidation({
		...input,
		shipping_firstName: validationRequired('First name'),
		shipping_lastName: validationRequired('Last name'),
		shipping_email: val => {
			if (!val) {
				return validEmailRequired;
			} else if (validationHook.getValue("shipping_repeatEmail") !== val) {
				return emailAddressesMustMatch;
			} else {
				return undefined;
			}
		},
		shipping_repeatEmail: val => {
			if (!val) {
				return validEmailRequired;
			} else if (validationHook.getValue("shipping_email") !== val) {
				return emailAddressesMustMatch;
			} else {
				return undefined;
			}
		},
		shipping_phoneNumber: val => !val || val.length !== 12 ? 'Phone number is required and must be 10 numbers long.' : undefined, // don't count the hyphens for the message
	});

	return validationHook;
}

export function useDebounce<T>(value: T, delay: number, bypassDebounce: boolean = false): T {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handle = setTimeout(() => {
			setDebouncedValue(value)
		}, bypassDebounce ? 0 : delay);
		return () => clearTimeout(handle)
	}, [value, delay, bypassDebounce]);

	return debouncedValue;
}

export enum MediaQueryTypes {
	/* Used to detect viewport width in code. */
	IS_MOBILE = "(max-width: 767px)",
	IS_VIEWPORT_XXL = "(max-width: 1199px)"
}

export function useMediaQuery(query: MediaQueryTypes | string): boolean {
	const [matches, setMatches] = useState<boolean>(
		window.matchMedia(query).matches
	);

	useEffect(() => {
		const mediaQuery = window.matchMedia(query);
		const callback = () => setMatches(mediaQuery.matches);
		mediaQuery.addEventListener('change', callback);

		return () => mediaQuery.removeEventListener('change', callback);

	}, [query]);

	return matches;
}

interface AddressVerification {
	matches: AddressInputVm[];
	isVerified: boolean;
	isLoading: boolean;
}

const smartyStreetsInitialValue: AddressVerification = {
	matches: [],
	isVerified: false,
	isLoading: false,
};
export const useSmartyStreets = (inputAddress: AddressInputVm) => {
	const debouncedInput = useDebounce(inputAddress, 750);
	const [verification, setVerification] = useState<AddressVerification>(smartyStreetsInitialValue);
	const isDebouncing = !Address.equals(debouncedInput, inputAddress);

	// This handles setting loading indicator while the input has changed and is debouncing
	useEffect(() => {
		setVerification(old => ({...old, isLoading: isDebouncing}));
	}, [isDebouncing]);

	// debounced
	useEffect(() => {
		if (debouncedInput.isVerified ||
			(!debouncedInput.street && !debouncedInput.city && !debouncedInput.stateCode && !debouncedInput.zip)) {
			setVerification(old => ({...old, isLoading: false, isVerified: debouncedInput.isVerified ?? false}));
			return; // exit & don't try to re-verify again
		}

		// Set loading to true before we make our request, since the input is no longer debouncing
		setVerification(old => ({...old, isLoading: true}));
		autocompleteAddress(debouncedInput).then(matches => {
			if (matches.length > 0 && !debouncedInput.zip?.includes('-')) { // if the zip has a dash, call validate api, autocomplete doesn't provide 9 digit zips
				setVerification({
					matches,
					isVerified: matches.findIndex(m =>
						m.street?.trim().toUpperCase() === debouncedInput.street?.trim().toUpperCase() &&
						m.city?.trim().toUpperCase() === debouncedInput.city?.trim().toUpperCase() &&
						m.stateCode?.trim().toUpperCase() === debouncedInput.stateCode?.trim().toUpperCase() &&
						m.zip === debouncedInput.zip) > -1,
					isLoading: false,
				});
			} else {
				// if we don't have any autocomplete matches, try to get validation addresses
				validateAddress(debouncedInput).then(([match, isVerified]) => {
					setVerification({
						matches: match ? [match] : [],
						isVerified,
						isLoading: false,
					});
				});
			}
		});
	}, [debouncedInput.street, debouncedInput.city, debouncedInput.stateCode, debouncedInput.zip, debouncedInput.isVerified]);

	// no-debounce (either for clearing state, or for using pre-existing matches to get verification without making request)
	useEffect(() => {
		if (!inputAddress.street && !inputAddress.city && !inputAddress.stateCode && !inputAddress.zip) {
			// clear out if given clear address
			setVerification(smartyStreetsInitialValue);
		}
		setVerification(old => ({
			...old,
			isVerified: old.matches.findIndex(m => m.street?.trim().toUpperCase() === inputAddress.street?.trim().toUpperCase() && m.city?.trim().toUpperCase() === inputAddress.city?.trim().toUpperCase() && m.stateCode?.trim().toUpperCase() === inputAddress.stateCode?.trim().toUpperCase() && m.zip?.trim() === inputAddress.zip?.trim()) > -1
		}));
	}, [inputAddress.street, inputAddress.city, inputAddress.stateCode, inputAddress.zip, inputAddress.isVerified])

	return verification;
};

export function useObjState<T>(initialValue: T): [T, (key: keyof T, val: T[keyof T]) => void, Dispatch<SetStateAction<T>>] {
	const [state, setState] = useState(initialValue);

	const setInnerState = (key: keyof T, val: T[keyof T]) =>
		setState(old => ({
			...old,
			[key]: val
		}));

	return [state, setInnerState, setState];
}
