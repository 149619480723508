import axios from "axios";
import { OrderSearchCriteria } from "../admin/order/OrderSearchCriteria";
import { PagingInfo } from "../../utils/Grid";
import { acquireAccessToken } from "../../index";

export interface CriteriaAndPaging {
  criteria: OrderSearchCriteria,
  paging?: PagingInfo
}
export async function fetchAll(query: CriteriaAndPaging) {
  const token = await acquireAccessToken();
  return await axios.get('/api/Orders', {
    headers: {
      authorization: `Bearer ${token}`,
    },
    params: { ...query.criteria, ...query.paging },
  });
}