import "./ProductSearchForm.css";
import {Button, Input} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FormEvent, useState} from "react";
import {useNavigate} from "react-router-dom";

export const ProductSearchForm = () => {
	const [term, setTerm] = useState('');
	const navigate = useNavigate();

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		navigate(`/product/search?term=${encodeURI(term)}`);
	};

	return (
		<form onSubmit={handleSubmit} className="product-search-form">
			<Input bsSize="sm"
			       className="product-search-form-input"
			       placeholder="Search Products..."
			       value={term}
			       onChange={e => setTerm(e.target.value)}
			       onFocus={e => e.target.select()}
			/>
			<Button type="submit" size="sm" color="primary" outline>
				<FontAwesomeIcon icon={faSearch}/>
			</Button>
		</form>
	);
};
