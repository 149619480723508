import './Contact.css';
import {useTranslation} from "react-i18next";
import ScrollToTop from '../../helpers/ScrollToTop';
import { Breadcrumb } from '../../breadcrumb/Breadcrumb';
import { ContactForm } from './contactForm/ContactForm';
import { Container } from 'reactstrap';

export const Contact = () => {
    const { t } = useTranslation();

    return (
        <span className='contact-page d-flex'>
            <ScrollToTop></ScrollToTop>
            <Container>
                <Breadcrumb currentPage={t(`pages.contact.title`)}></Breadcrumb>
                <span className='contact-us-container'>
                    <h1 className='contact-title'>{t(`pages.contact.title`)}</h1>
                    <span className='contact-text d-flex flex-column'>
                        <span className='d-flex flex-column'>
                            <span className='contact-checkworks'>{t(`pages.contact.contactTitle`)}</span>
                            <span>{t(`pages.contact.contactBody`)}</span>
                        </span>
                        <span className='d-flex flex-column'>
                            <span className='contact-us-form-section-title'>{t(`pages.contact.emailTitle`)}:</span>
                            <a href={'mailto:' + t(`emails.customerService`)} className='contact-us-link'>
                                {t(`emails.customerService`)}
                            </a>
                        </span>
                        <span className='d-flex flex-column'>
                            <span className='contact-us-form-section-title'>{t(`pages.contact.phoneTitle`)}:</span>
                            <span>{t(`phoneNumber.display`)}</span>
                            <span>{t(`pages.contact.phoneHours`)}</span>
                        </span>
                        <span className='d-flex flex-column'>
                            <span className='contact-us-form-section-title'>{t(`pages.contact.mailTitle`)}:</span>
                            <span>{t(`address.name`)}</span>
                            <span>{t(`address.part1`)}</span>
                            <span>{t(`address.part2`)}</span>
                        </span>
                    </span>
                    <ContactForm />   
                </span>  
            </Container>    
        </span>
    );
};