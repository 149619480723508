import {useEffect, useState} from 'react';
import './AddProductAccessory.css';
import {Configuration} from '../configuration/Configuration';
import {Button, Input, Label} from 'reactstrap';
import {ProductInputValue} from '../ProductInputValue';
import {useTranslation} from "react-i18next";
import {FieldModifier} from "../configuration/FieldModifier";
import {
    useGetSiteProductForAccessoryQuery
} from "../../../app/apiSlice";
import {faCartShopping, faCheck} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {Utils} from "../../../utils/Utils";
import {OnValueChangeParams} from "../view/ProductInputView";

interface AddProductAccessoryProps {
    configuration?: Configuration,
    productInputValue?: ProductInputValue,
    isInEditMode: boolean,
    getClassesForModifiers: (fieldModifiers?: FieldModifier[]) => string,
    onValueChange: (params: OnValueChangeParams) => void,
    onAccessoryChange: (accessoryConfiguration: Configuration[]) => void,
    siteProductId: number,
    productVariantId?: number,
}

export const AddProductAccessory = ({ configuration, productInputValue, isInEditMode, getClassesForModifiers, onValueChange, onAccessoryChange, siteProductId, productVariantId }: AddProductAccessoryProps) => {
    const { t } = useTranslation();
    const inputId = configuration?.id ?? -1;
    const inputLabel = configuration?.configurationDetail.label ?? "";
    const { data: product } = useGetSiteProductForAccessoryQuery(configuration?.configurationDetail?.accessoryProductId
        ? {accessoryProductId: configuration?.configurationDetail?.accessoryProductId, siteProductId: siteProductId}
        : skipToken);

    const quantityOptions = [...product?.quantityOptions ?? []].sort(Utils.sortBy('variantDescription', 'desc'));
    const [ quantityId, setQuantityId] = useState<number | undefined>();
    const [ willAddToCart, setWillAddToCart] = useState(false);

    useEffect(() => {
        if (productInputValue?.isDirty && productInputValue.selectedSiteProductVariantQuantityOptionId && !quantityId) {
            setQuantityId(productInputValue.selectedSiteProductVariantQuantityOptionId);
            setWillAddToCart(true);
        }
    }, [productInputValue])

    useEffect(() => {
        if (!willAddToCart) {
            onValueChange({ inputValue: null });
            onAccessoryChange([]);
        }
        else {
            onValueChange({ inputValue: quantityId?.toString() ?? null, quantityOptionId: quantityId });
            onAccessoryChange(product?.configurations ?? []);
        }
    }, [willAddToCart]);

    function onSelectionValueChange(value?: string) {
        setQuantityId(value ? parseInt(value) : undefined);
    }

    function getInputLabel(labelType: string) {
        return (
            <Label for={`${labelType}-input-${inputId}`} className={`product-accessory-label ${getClassesForLabel()}`}>
                { t(`productConfiguration.${inputLabel}.${labelType}`) }
            </Label>
        )
    }

    function getInputValue() {
        return quantityId;
    }

    function getInputValueText() {
        const matchingIndex = quantityOptions?.findIndex((qo) => qo.id === productInputValue?.selectedSiteProductVariantQuantityOptionId);
        if (!quantityOptions || matchingIndex === undefined || matchingIndex < 0)
            return "None";

        let selectedQuantity = quantityOptions[matchingIndex];
        let checksCount = "";
        if (selectedQuantity?.checksPerPad && selectedQuantity.padsPerUnit)
            checksCount = `${selectedQuantity.padsPerUnit * selectedQuantity.checksPerPad}`;

        return `${checksCount} ${selectedQuantity.variantDescription ? '- ' + selectedQuantity.variantDescription : ""} - $${selectedQuantity.price}`
    }

    async function onAddToCartClicked() {
        if (!quantityId) {
            const firstQuantity = quantityOptions?.[0];
            setQuantityId(firstQuantity?.id)
        }
        setWillAddToCart(!willAddToCart);
    }

    const getAddToCartButton = () => {
        if (willAddToCart) {
            return (
                <Button className='product-accessory-add-to-cart-button' color="success" onClick={() => {onAddToCartClicked()}}>
                    <FontAwesomeIcon icon={faCheck} />
                    Will add to cart
                </Button>
            );
        }

        return (
            <Button className='product-accessory-add-to-cart-button' color="primary" onClick={() => {onAddToCartClicked()}}>
                <FontAwesomeIcon icon={faCartShopping} />
                {t(`productConfiguration.${inputLabel}.addToCart`)}
            </Button>
        );
    }

    function getEditModeTemplate() {
        return (
            <>  
                <span className="product-accessory-question-text">{t(`productConfiguration.${inputLabel}.question`)}</span>
                {/*Deposit slips*/}
                <span className="">
                    <Label for={`product-accessory-input-${inputId}`} className={`product-accessory-label ${getClassesForLabel()}`}>
                        {t(`productConfiguration.${inputLabel}.quantityLabel`)}
                    </Label>
                    <span className='product-accessory-input-select-container d-flex flex-row'>
                        <Input
                            id={`product-accessory-input-${inputId}`}
                            type="select"
                            onChange={(e) => onSelectionValueChange(e.target.value)}
                            value={getInputValue()}>
                            {quantityOptions?.map((quantity) => {
                                let checksCount;
                                if (quantity.checksPerPad && quantity.padsPerUnit)
                                    checksCount = quantity.padsPerUnit * quantity.checksPerPad;

                                return (
                                    <option key={quantity.id} value={quantity.id}>
                                        {`${checksCount} ${quantity.variantDescription ? '- ' + quantity.variantDescription : ""} - $${quantity.price}`}
                                    </option>
                                )
                            })}
                        </Input>
                        {getAddToCartButton()}
                    </span>
                </span>
                    
            </>
        );
    }

    function getDepositSlipSelectValue() {
        return (
            <>
                <div className="half-width-text-input">
                    <div>{getInputValueText()}</div>
                </div>
            </>
        )
    }

    function getReadModeTemplate() {
        return (
            <>
                {getInputLabel('readonlyLabel')}
                {getDepositSlipSelectValue()}
            </>
        );
    }

    function getValueRenderTemplate() {
        if (isInEditMode) {
            return getEditModeTemplate();
        }
        else {
            return getReadModeTemplate();
        }
    }

    function getClassesForLabel() {
        if (!isInEditMode) {
            return "half-width-text-input";
        }
        return "";
    }

    return (
        <span className={"product-accessory-input-container " + getClassesForModifiers(configuration?.fieldModifiers) + " "}>

            {getValueRenderTemplate()}

        </span>
    );
}
