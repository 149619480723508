import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeModal, selectModalState, toggleModal } from "./ModalSlice";
import { Button, Modal as BsModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ModalType } from "./ModalType";


export const Modal = () => {
    const state = useAppSelector(selectModalState);
    const dispatch = useAppDispatch();
    
    const confirm = () => {
        if (state.onConfirm) state.onConfirm();
        dispatch(closeModal());
    }
    
    const closeBtn = <button className='btn-close' onClick={() => dispatch(closeModal())}/>
    
    return (
        <BsModal isOpen={state.isOpen} toggle={() => dispatch(toggleModal())} size={state.size}>
            <ModalHeader toggle={() => dispatch(toggleModal())} close={closeBtn}>
                {state.title}
            </ModalHeader>
            {state.showTextContent &&
              <ModalBody>
                {state.content}
                </ModalBody>
            }
            <ModalFooter>
                <Button onClick={confirm} color={state.modalType === ModalType.Delete ? 'danger' : 'primary'}>{state.affirmText}</Button>
                <Button color='secondary' onClick={() => dispatch(closeModal())}>{state.cancelText}</Button>
            </ModalFooter>
        </BsModal>
    )
}