import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, FormGroup, Label, Nav, NavItem, NavLink, Row, Table } from "reactstrap";
import { useEffect, useState } from "react";
import { Utils, getErrorMessage } from "../../../utils/Utils";
import { MultiSelect } from "../../input/MultiSelect";
import {
  useGetRolesQuery,
  useGetUserHistoryQuery,
  useGetAdminsQuery,
  useSaveAdminMutation,
} from "../../../app/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { DateTime } from "luxon";
import { Admin } from "../../user/Admin";
import { hasAdmin } from "../../../utils/Roles";
import { EmailInput } from "../../input/email/EmailInput";
import { useAppDispatch, useClaims } from "../../../app/hooks";
import { showConfirmationModal, showInformationModal } from "../../modal/ModalSlice";

export const EditAdmin = () => {
  const { adminId } = useParams();
  const navigate = useNavigate();
  const { hasAdmin } = useClaims();
  const dispatch = useAppDispatch();
  const adminIdInt = adminId && adminId !== 'new' ? Number.parseInt(adminId) : undefined;

  const { data: roles } = useGetRolesQuery();
  const { data: admins, isLoading: adminsLoading } = useGetAdminsQuery(adminIdInt ? {id: adminIdInt} : skipToken);
  const [ saveAdmin ] = useSaveAdminMutation();

  const [admin, setAdmin] = useState<Admin>(admins ? admins[0] : {id: adminIdInt ?? -1, enabled: true} as Admin);

  const { data: history } = useGetUserHistoryQuery(admin.user?.id ?? skipToken);
  
  const [adminRoles, setAdminRoles] = useState<number[]>(admins ? admins[0].roles.map(r => r.id) : []);
  const [activeTab, setActiveTab] = useState('General');

  const handle = Utils.handleChanges(setAdmin);
  
  let editableRoles = roles;
  if (!hasAdmin) { // if we're not an admin, don't show Admin checkbox
    editableRoles = editableRoles?.filter(r => r.name !== 'Admin');
  }
  
  useEffect(() => {
    if (admins) {
      setAdmin(admins[0])
      setAdminRoles(admins[0].roles.map(r => r.id))
    }
  }, [admins]);

  const handleNetworkError = (e: any) => {
    dispatch(showInformationModal({
      title: 'Update Failed',
      text: e.status === 403 ? 'Sorry, you are not authorized to change this user.' : `Error while trying to change user: ${getErrorMessage(e)}`
    }));
  }

  const changeUserEnablement = async () => {
    try {
      await (saveAdmin({...admin, roleIds: adminRoles, enabled: !admin.enabled}).unwrap());
      navigate('/admin/admins');
    } catch (e: any) {
      handleNetworkError(e);
    }
  }

  const onDisableUserClick = () => {
    const action = admin.enabled ? 'disable' : 'enable';
    dispatch(showConfirmationModal({
      content: `Are you sure you want to ${action} this user?`,
      onConfirm: changeUserEnablement,
    }))
  };
  
  const isSaveDisabled = () => 
      !admin.email || !adminRoles.length;

  const onSaveClick = async () => {
    try {
      await saveAdmin({
        ...admin,
        roleIds: adminRoles
      }).unwrap();

      navigate('/admin/admins');
    } catch (e: any) {
      handleNetworkError(e);
    }
  };

  return (
    <div className='d-flex flex-column px-4'>
      <div className="d-flex justify-content-between mb-2">
        <h2 className="mb-0">User</h2>
        <div className="d-flex">
          <Button disabled={isSaveDisabled()} color='success' className='me-1' onClick={onSaveClick}>Save</Button>
          {adminId !== 'new' && <Button color='warning' className='mx-1'
                                       onClick={onDisableUserClick}>{admin.enabled ? 'Disable' : 'Enable'} User</Button>}
          <Button color='danger' className='ms-1' onClick={() => navigate('/admin/admins')}>Cancel</Button>
        </div>
      </div>

      <Nav tabs>
        <NavItem>
          <NavLink active={activeTab === 'General'} onClick={() => setActiveTab('General')}>General</NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === 'History'} disabled={!adminIdInt}
                   onClick={() => setActiveTab('History')}>History</NavLink>
        </NavItem>
      </Nav>

      {activeTab === 'General' &&
          <Row className='pt-3'>
            {/*Fields*/}
              <Col>
                <FormGroup>
                  <Label for='Email'>Email: </Label>
                  <EmailInput id='Email' name='email' value={admin.email ?? ''} onChange={handle} suppressError={adminsLoading}/>
                </FormGroup>

                <FormGroup className="criteria-panel-control-container">
                  <Label>Roles</Label>
                  <MultiSelect options={editableRoles?.map(r => ({display: r.name, value: r.id})) ?? []} values={adminRoles}
                               onChange={newRoles => setAdminRoles(newRoles)}/>
                </FormGroup>
              </Col>

            {/*Details*/}
            {adminIdInt &&
                <Col>
                  <Row>
                    <Col xs='4'>
                      <strong>Created Date:</strong>
                    </Col>
                    <Col>
                      {DateTime.fromISO(admin.user?.timeCreatedLocal).toLocaleString(DateTime.DATETIME_SHORT)}
                    </Col>
                  </Row>
                  <Row>
                      <Col xs='4'>
                        <strong>Created By:</strong>
                      </Col>
                      <Col>
                        {admin.user?.createdBy ?? 'System'}
                      </Col>
                  </Row>
                  <Row>
                    <Col xs='4'>
                      <strong>Last Modified Date:</strong>
                    </Col>
                    <Col>
                      {DateTime.fromISO(admin.user?.timeLastUpdatedLocal).toLocaleString(DateTime.DATETIME_SHORT)}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='4'>
                      <strong>Last Modified By:</strong>
                    </Col>
                    <Col>
                      {admin.user?.updatedBy ?? 'System'}
                    </Col>
                  </Row>
                </Col>
            }
          </Row>
      }

      {
        activeTab === 'History' &&
          <Table>
            <thead>
              <tr>
                <th>User</th>
                <th>Event Date</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
            { history?.map(record => (
              <tr key={record.id}>
                <td>{record.changeBy}</td>
                <td>{DateTime.fromISO(record.timeOfChangeLocal).toLocaleString(DateTime.DATETIME_SHORT)}</td>
                <td>{record.changeDescription}</td>
              </tr>
            ))}
            </tbody>
          </Table>
      }
    </div>
  )
}