import {Alert, Button, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import {
    useDeleteSavedPaymentDetailsForUserMutation,
    useGetCartProductsPricingQuery,
    useGetSavedPaymentDetailsForUserQuery, useUpdateOrderByCartIdMutation,
    useSavePaymentDetailsMutation, useGetOrderPaymentHistoryQuery, useGetPreviousOrderPaymentInformationQuery
} from "../../../../../app/apiSlice";
import {Order, OrderEditCartDetails} from "../../../../order/Order";
import {OrderEditPaymentDelta} from "./orderEdit/paymentDelta/OrderEditPaymentDelta";
import {PaymentSelection} from "../../../../payment/selection/PaymentSelection";
import React, {useEffect, useState} from "react";
import {Address} from "../../../../input/address/Address";
import {useAppDispatch, useDebounce, useValidation} from "../../../../../app/hooks";
import {BillingAddress} from "../../BillingAddress";
import {PaymentDetails, PaymentDetailsType} from "../../../../payment/PaymentDetails";
import {SaveOrderVm} from "../../SaveOrderVm";
import {ShippingOption} from "../../../../shipping-options/ShippingOption";
import {ShippingInformation} from "../../ShippingInformation";
import { forgetOrderEditCartId } from "../../../../cart/CartSlice";
import {CartPricingRequest} from "../../../../cart/CartPricing";
import {skipToken} from "@reduxjs/toolkit/query";
import "./UpdateOrder.css";
import {
    getErrorMessage,
    Regexes,
    validateCCFullNameString, validateCVCString,
    validateExpirationDateString
} from "../../../../../utils/Utils";
import {PaymentUtils} from "../../../../payment/utils/PaymentUtils";
import {PaymentMethodName, PaymentMethodOption} from "../../../../payment/PaymentMethod";

interface UpdateOrderProps {
    order: Order,
    isOpen: boolean,
    shouldDisableSubmitButton: boolean,
    toggleModal: () => void,
    orderEditCartDetails?: OrderEditCartDetails,
    shippingOption?: ShippingOption,
    shippingInformation: ShippingInformation
}
export const UpdateOrder = ({
                                order,
                                isOpen,
                                shouldDisableSubmitButton,
                                toggleModal,
                                orderEditCartDetails,
                                shippingOption,
                                shippingInformation
                            }: UpdateOrderProps) => {

    const [ savePaymentDetails, {isLoading: isSavingPaymentDetails} ] = useSavePaymentDetailsMutation();
    const [ deleteSavedPaymentDetailsForUser, {isLoading: isDeletingSavedPayment} ] = useDeleteSavedPaymentDetailsForUserMutation();
    const [ updateOrder, {isLoading: isUpdating} ] = useUpdateOrderByCartIdMutation();

    const [ cartPricingRequest, setCartPricingRequest ] = useState<CartPricingRequest | undefined>();
    const debounceCartPricingRequest = useDebounce(cartPricingRequest, 2_000);
    const { data: cartPricing } = useGetCartProductsPricingQuery(debounceCartPricingRequest ?? skipToken);
    const { data: savedPaymentDetails } = useGetSavedPaymentDetailsForUserQuery(order.consumer.userId);
    const { data: orderPayments } = useGetOrderPaymentHistoryQuery(order.id);
    const [ selectedPaymentProfileId, setSelectedPaymentProfileId ] = useState<string | undefined>(undefined);
    const [ billingAddress, setBillingAddress ] = useState<Address | undefined>(undefined);
    const [ paymentDetails, setPaymentDetails ] = useState<PaymentDetails | undefined>(undefined);
    const [ paymentMethodOption, setPaymentMethodOption ] = useState<PaymentMethodOption>();
    const [ errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [ successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [ requiresPayment, setRequiresPayment] = useState<boolean>(false);
    const isAch = (orderPayments ?? []).some(op => op.paymentMethod === PaymentMethodName.ACH);
    const { data: previousPaymentInformation } = useGetPreviousOrderPaymentInformationQuery(order.id);

    const modalNavigation = (
        <div>
            <button className='btn-close' onClick={toggleModal}/>
        </div>
    );

    const dispatch = useAppDispatch();
    const required = (name: string) => (val?: string) => !val ? `${name} is required.` : undefined;
    const [paymentDetailsType, setPaymentDetailsType] = useState<PaymentDetailsType | undefined>(PaymentDetailsType.CreditCardPaymentDetails);
    const validation = useValidation({
        ...PaymentUtils.wrapPaymentDetailsValidationMap(paymentDetailsType, PaymentDetailsType.CreditCardPaymentDetails, {
            payment_firstName: required('First Name'),
            payment_lastName: required('Last Name'),
            payment_fullName: val => validateCCFullNameString(val),
            payment_cardNumber: val => (!val || !Regexes.ccWithFormatting.test(val)) ? 'Please enter a valid card number' : undefined,
            payment_cardExpiration: val => validateExpirationDateString(val),
            payment_cvc: val => validateCVCString(val),
        }),
        ...PaymentUtils.wrapPaymentMethodOptionValidationMap(paymentMethodOption, PaymentMethodOption.SavedPaymentMethod,
            {
                payment_savedMethod: required('Payment Method')
            }
        )
    });

    // Fill in billing info on previous payment loads
    useEffect(() => {
        setBillingAddress(previousPaymentInformation?.billingAddress);
    }, [previousPaymentInformation]);

    // Fill in saved payment details on previous payment loads
    useEffect(() => {
        if (previousPaymentInformation?.vendorPaymentProfileId && !selectedPaymentProfileId) {
            setSelectedPaymentProfileId(previousPaymentInformation.vendorPaymentProfileId);
        }
    }, [previousPaymentInformation]);

    useEffect(() => {
        if (orderEditCartDetails) {
            const productShippingServiceSelections = shippingOption?.items.map((i) => {
                return {
                    cartProductId: i.cartProductId,
                    cost: i.price ?? 0,
                    retailCost: i.retailPrice ?? 0
                }
            }) ?? [];
            setCartPricingRequest({
                cartId: orderEditCartDetails.cartId,
                orderId: orderEditCartDetails.orderId,
                toAddress: shippingInformation?.address,
                productShippingServiceSelections});
        }
        else {
            setCartPricingRequest(undefined);
        }
    }, [orderEditCartDetails, shippingInformation, shippingOption]);

    const onPaymentDetailsChanged = (paymentDetails?: PaymentDetails) => {
        setPaymentDetailsType(paymentDetails ? PaymentDetailsType[paymentDetails.paymentDetailsType as keyof typeof PaymentDetailsType] : undefined);
        setPaymentDetails(paymentDetails);
    }

    const shouldHideBillingAddress = () => {
        return paymentDetails?.paymentDetailsType === PaymentDetailsType.SavedPaymentDetails;
    }

    const onSavePaymentDetailsClicked = async () => {
        if (!paymentDetails || !order.consumer?.id) {
            setErrorMessage("Missing Information. Please enter all of the payment fields including billing address.");
            return;
        }

        try {
            const newPaymentProfileId = await savePaymentDetails({
                paymentDetails: paymentDetails,
                userId: order.consumer.userId
            }).unwrap();

            if (newPaymentProfileId) {
                setSuccessMessage('Payment method saved!');
            }
            else {
                setErrorMessage('Failed to save payment method. It\'s possible this payment method may already be saved.');
            }
        } catch (e) {
            setErrorMessage('Failed to save payment method.');
        }

    }

    const onRemoveSavedPaymentMethodClicked = async (customerPaymentProfileId: string) => {
        if (!order.consumer?.id) {
            return;
        }

        try {
            await deleteSavedPaymentDetailsForUser({userId: order.consumer.userId, customerPaymentProfileId}).unwrap();
        } catch (e) {
            alert('Error deleting payment details: ' + JSON.stringify(e));
        }
    }

    const onSubmitClicked = async () => {
        if (isUpdating || (orderRequiresPaymentDetails() && !validation.allValid())) return;

        const updateOrderRequest: SaveOrderVm = {
            orderId: order.id,
            cartId: orderEditCartDetails?.cartId,
            email: shippingInformation?.email ?? "",
            phoneNumber: shippingInformation?.phoneNumber ?? "",
            shippingAddress: {
                ...shippingInformation?.address,
                id: shippingInformation?.address?.id ?? -1,
                name: shippingInformation?.firstName,
                nameTwo: shippingInformation?.lastName
            },
            changeType: 'Customer Requested Change',
            paymentDetails: paymentDetails,
            cartProductShippingEstimateIds: shippingOption?.items?.map(i => i.cartProductShippingEstimateId) ?? [],
            consumer: order.consumer
        };

        try {
            await updateOrder(updateOrderRequest).unwrap();
            dispatch(forgetOrderEditCartId());
            toggleModal();
        } catch (e: any) {
            setErrorMessage(getErrorMessage(e) ?? 'Failed to update order.');
        }
    }

    const onCancelClicked = () => {
        toggleModal();
    }

    const orderRequiresPaymentDetails = () => {
        if (!cartPricing?.siteProductVariantTotals || !orderPayments || isAch) {
            return false;
        }

        return requiresPayment;
    }

    const getPaymentDetailsIfNeeded = () => {
        if (!orderRequiresPaymentDetails())
            return <></>;

        return (
            <>
                <Row className="mb-4">
                    {!shouldHideBillingAddress() && <BillingAddress
                                    consumerId={order.consumer.id}
                                    address={billingAddress}
                                    onAddressChanged={setBillingAddress}
                                    suppressError={!validation.shouldShowErrors}
                                    useCardContainer={false}/>
                    }
                </Row>
                <Row className="mb-4">
                    <PaymentSelection selectedPaymentProfileId={selectedPaymentProfileId}
                                      enabledSavedPaymentMethods={true}
                                      savedPaymentDetails={savedPaymentDetails}
                                      billingAddress={billingAddress}
                                      paymentInfo={paymentDetails}
                                      onPaymentOptionMethodChange={setPaymentMethodOption}
                                      onPaymentDetailsChanged={onPaymentDetailsChanged}
                                      onSavePaymentDetailsClicked={onSavePaymentDetailsClicked}
                                      onRemoveSavedPaymentMethodClicked={onRemoveSavedPaymentMethodClicked}
                                      validation={validation.createProxy('payment')}
                                      isSavingPaymentDetails={isSavingPaymentDetails}
                                      isDeletingSavedPayment={isDeletingSavedPayment}
                                      useCardContainer={false}
                                      orderId={order.id}/>
                </Row>
            </>
        );
    }

    const getErrorMessageDisplay = () => {
        return errorMessage && (
            <Alert color='danger'>
                {errorMessage}
            </Alert>
        )
    }

    const getSuccessMessageDisplay = () => {
        return successMessage && (
            <Alert color='success'>
                {successMessage}
            </Alert>
        )
    }

    const shouldDisableSubmit = () => {
        return isUpdating || shouldDisableSubmitButton || (orderRequiresPaymentDetails() && (!paymentDetails ||
            (!billingAddress && paymentDetails?.paymentDetailsType !== PaymentDetailsType.SavedPaymentDetails)));
    }

    return (
        <Modal isOpen={isOpen} toggle={toggleModal} size='xl'>
            <ModalHeader toggle={toggleModal} close={modalNavigation}>Update Order</ModalHeader>
            <ModalBody>
                <Row className="mb-4">
                    {cartPricing && orderEditCartDetails &&
                      <OrderEditPaymentDelta
                        includeDetails={true}
                        cartPricing={cartPricing}
                        orderEditCartDetails={orderEditCartDetails}
                        onRequiresPaymentChanged={setRequiresPayment}></OrderEditPaymentDelta>}
                </Row>
                {getPaymentDetailsIfNeeded()}
                {getErrorMessageDisplay()}
                {getSuccessMessageDisplay()}
                <Row>
                    <div className='update-order-button-container'>
                        <Button color='success' onClick={onSubmitClicked} disabled={shouldDisableSubmit()}>
                            {(isUpdating || shouldDisableSubmitButton) && <Spinner></Spinner>}
                            Submit
                        </Button>
                        <Button onClick={onCancelClicked}>Cancel</Button>
                    </div>
                </Row>
            </ModalBody>
        </Modal>
    );
}