import { OrderDailySales } from "./OrderDailySales";
import { ColumnConfig } from "../../admin/shared/Grid";
import { reportCol, reportDateToString, reportMoneyToString, ReportView } from "../ReportView";
import { useGetOrderDailySalesReportQuery } from "../../../app/apiSlice";

export const OrderDailySalesReport = () => {
 
  const columns: ColumnConfig<OrderDailySales>[] = [
    reportCol('orderDate', 'Order Date', reportDateToString),
    reportCol('brand', 'Brand'),
    reportCol('totalNumberOfUnits', 'Total Number Of Units'),
    reportCol('runningTotalOfUnits', 'Running Total of Units'),
    reportCol('totalOfDateSales', 'Total of Date Sales', reportMoneyToString),
    reportCol('runningTotalOfSales', 'Running Total of Sales', reportMoneyToString),
    reportCol('totalNumberOfOrders', 'Total Number of Orders')
  ];
  
  return <ReportView
    title='Order Daily Sales'
    query={useGetOrderDailySalesReportQuery}
    columns={columns}
    initialSort={{column: 'orderDate', direction: 'asc'}}
    downloadPath='/api/Reports/OrderDailySales'
    downloadName='CW_Order_Daily Sales'/>
}