
import './ConsumerBrowse.css';
import {Link} from "react-router-dom";
import { PagingInfo, SortState } from "../../../../utils/Grid";
import {useEffect, useState} from "react";
import {GridPage} from "../../shared/GridPage";
import {ColumnConfig, GridConfig} from "../../shared/Grid";
import {DateTime} from "luxon";
import {useAppDispatch, useAppSelector, useDebounce} from "../../../../app/hooks";
import {Consumer} from "../Consumer";
import {
    canFetchMoreConsumerDetails,
    changeSearch,
    fetchConsumerDetails,
    fetchMoreConsumerDetails, isFetchingConsumerDetails, selectConsumerDetails,
} from "../ConsumerSlice";
import {useGetSitesQuery} from "../../../../app/apiSlice";
import {ConsumerFetchSearchCriteria} from "../ConsumerSearchCriteria";
import {CartIdType} from "../../../cart/CartSlice";
import {User} from "../../../user/User";

const IdCellDisplay = ( {val}: {val: string}) => { return <Link to={`/admin/consumer/${val}`}>{val}</Link>};

export const ConsumerBrowse = () => {
    const { data: sites } = useGetSitesQuery();
    const [criteria, setCriteria] = useState<ConsumerFetchSearchCriteria>({});
    const debouncedCriteria = useDebounce(criteria, 500);
    const consumers = useAppSelector(selectConsumerDetails);
    const moreAvailable = useAppSelector(canFetchMoreConsumerDetails);
    const isLoading = useAppSelector(isFetchingConsumerDetails);
    const dispatch = useAppDispatch();
    const fetchStatus = useAppSelector(state => state.consumers.status);

    const [search, setSearch] = useState('');
    const [sort, setSort] = useState<SortState<Consumer>>({ column: 'firstName', serverColumn: 'c.firstName', direction: 'asc' });


    // initial pull of data and on sort/search change
    useEffect(() => {
        dispatch(fetchConsumerDetails({ criteria: {...debouncedCriteria, search}, paging: { sortKey: sort.serverColumn!, sortDirection: sort.direction }}));
    }, [debouncedCriteria, sort, search, dispatch]);

    // on search change, reset the slice
    useEffect(() => {
        dispatch(changeSearch());
    }, [debouncedCriteria, search, dispatch]);

    const fetchMore = (paging: PagingInfo) => {
        if (fetchStatus !== 'succeeded') return; // don't try to grab again if we're already loading
        dispatch(fetchMoreConsumerDetails({ criteria: {...debouncedCriteria, search}, paging}));
    }
    
    const getIdCellDisplay = (val: number | CartIdType | User) => {
        return <IdCellDisplay val={val as string} />;
    }

    const config: GridConfig<Consumer> = {
        configSections: [
            {
                columns: [
                    new ColumnConfig({
                        field: 'siteId',
                        display: 'Storefront',
                        isHidden: true,
                        type: 'select',
                        choices: [ { value: -1, display: 'All Storefronts' }, ...(sites?.map(s => ({ value: s.id, display: s.name })) ?? [])],
                    }),
                    new ColumnConfig({
                        field: 'id',
                        display: 'Consumer Id',
                        serverColumn: 'c.Id',
                        validation: val => (val && typeof val === 'number' && val < 1) ? 'Consumer Id must be greater than 0' : undefined,
                        cellDisplay: getIdCellDisplay,
                    }),
                    new ColumnConfig({
                        field: 'email',
                        serverColumn: 'c.Email',
                        display: 'Email',
                    }),
                    new ColumnConfig({
                        field: 'firstName',
                        serverColumn: 'c.FirstName',
                        display: 'First Name',
                    }),
                    new ColumnConfig({
                        field: 'lastName',
                        serverColumn: 'c.LastName',
                        display: 'Last Name',
                    }),
                    new ColumnConfig({
                        field: 'isWhitelisted',
                        isSortable: false,
                        display: 'Is Whitelisted',
                        type: 'boolean-tri-state',
                        cellDisplay: val => typeof(val) == 'boolean' && val ? "True" : "False",
                    }),
                    new ColumnConfig({
                        field: 'isBlacklisted',
                        isSortable: false,
                        display: 'Is Blacklisted',
                        type: 'boolean-tri-state',
                        cellDisplay: val => typeof(val) == 'boolean' && val ? "True" : "False",
                    }),
                    new ColumnConfig({
                        field: 'mostRecentRoutingNumber',
                        isSortable: false,
                        display: 'Most Recent Routing Number',
                    }),
                    new ColumnConfig({
                        field: 'user.timeLastUpdatedLocal',
                        serverColumn: 'u.TimeLastUpdated',
                        display: 'Last Modified Date',
                        isSearchCriteria: false,
                        cellDisplay: val => val ? DateTime.fromISO(val as string).toLocaleString(DateTime.DATETIME_SHORT) : 'Never modified.',
                    }),
                    new ColumnConfig({
                        field: 'siteName',
                        serverColumn: 's.Name',
                        display: 'Site',
                        isSearchCriteria: false
                    }),
                ]
            }
        ]
    };

    return (
        <GridPage records={consumers ?? []}
                  gridConfig={config}
                  title={'Consumers'}
                  sort={sort}
                  shouldShowCriteriaPanel={true}
                  onSortChanged={setSort}
                  onSearchChange={setSearch}
                  moreRecordsAvailable={moreAvailable}
                  onCriteriaChange={setCriteria}
                  onShouldFetchMore={fetchMore}
                  isLoading={isLoading}
        />
    )
}