import {NoteBrowse} from "../../note/browse/NoteBrowse";

interface NotesTabProps {
    orderId: number
}

export const NotesTab = ({orderId}: NotesTabProps) => {

    return (
        <NoteBrowse orderId={orderId}></NoteBrowse>
    );

}