import { FormFeedback, Input, InputProps } from "reactstrap"
import { Regexes } from "../../../utils/Utils";
import { ChangeEvent, ClipboardEvent, DragEvent, useEffect, useState } from "react";

interface EmailInputProps extends InputProps {
    // I could make these undefined-able, but considering we use controlled components, this is good to force
    // parents to follow best practices
    value: string,
    onChange: NonNullable<InputProps['onChange']>,
    onPaste?: NonNullable<InputProps['onPaste']>,
    onDrop?: NonNullable<InputProps['onDrop']>
    clear?: boolean, // true if you need to clear out the value from the parent
    suppressError?: boolean // true if you want to hold off on showing error until the user submits
    errorText?: string
}

/**
 * Input for emails with validation. Only emits valid emails and empty strings
 */
export const EmailInput = (props: EmailInputProps) => {
    const { suppressError, clear, errorText = "Invalid Email", ...parentProps } = props;
    
    const [ value, setValue ] = useState(props.value);
    
    // if parent wants to change value, they can, but it must be valid
    useEffect(() => {
        if (isValid(props.value)) {
            setValue(props.value)
        }
    }, [props.value]);

    // parent can clear value through clear prop
    useEffect(() => {
        if (clear) setValue('');
    }, [clear])
    
    const isValid = (val: string) =>
        Regexes.email.test(val);
        
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue(newValue);
        
        // only emit valid values
        if (isValid(newValue)) {
            props.onChange(e);
            return;
        }
        
        // if parent thinks there's a value, clear it, parent will not get any more updates until we're valid
        if (props.value !== '') {
            // not sure why name doesn't pop out, so manually setting it so Utils.handle functions can pick it up
            props.onChange({ ...e, target: { ...e.target, name: e.target.name, value: ''} });
        }
    }
    
    const onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
        if (props.onPaste) {
            props.onPaste(e);
        }
    };
    
    const onDrop = (e: DragEvent<HTMLInputElement>) => {
        if (props.onDrop) {
            props.onDrop(e);
        }
    };

    return <>
        <Input {...parentProps}
               type='email'
               invalid={props.invalid || ((!suppressError) && !isValid(value))}
               onChange={onChange}
               onPaste={onPaste}
               onDrop={onDrop}
               maxLength={100}
               value={value}/>
        <FormFeedback>{errorText}</FormFeedback>
    </>;
}