import {
    useGetMicrCheckProductTypesQuery,
    useGetMicrFormatByIdQuery
} from "../../../../app/apiSlice";
import {Link, useParams} from "react-router-dom";
import './MicrValue.css';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Label,
    Row
} from "reactstrap";
import {
    MicrFormatTemplate,
    DEFAULT_MICR_MAX_ACCOUNT_NUMBER_LENGTH,
    DEFAULT_MICR_MIN_ACCOUNT_NUMBER_LENGTH,
    MicrFormatEdit
} from "../MicrFormat";
import {MicrTextInput} from "../textInput/MicrTextInput";
import {MicrLegend} from "../legend/MicrLegend";
import React, {memo, useEffect, useState} from "react";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {ProductType} from "../../../product/Product";
import {MicrValueDisplayMemoized} from "../valueDisplay/MicrValueDisplay";
import {OrderProductVm} from "../../../order/OrderProductVm";
import {useAppDispatch} from "../../../../app/hooks";
import { showConfirmationModal } from "../../../modal/ModalSlice";

interface MicrValueProps {
    orderId?: number
    orderProduct?: OrderProductVm,
    propMicrFormatId?: number
    propFinancialInstitutionId?: number
    onEditModeToggle: (orderProductId: number) => void
}

export const MicrValue = ({orderId, orderProduct, propMicrFormatId, propFinancialInstitutionId, onEditModeToggle}: MicrValueProps) => {

    const { paramMicrFormatId, paramFinancialInstitutionId } = useParams();
    const dispatch = useAppDispatch();
    const [micrFormatId, setMicrFormatId] = useState<number | undefined>(paramMicrFormatId ? Number.parseInt(paramMicrFormatId) : (propMicrFormatId ? propMicrFormatId : undefined));
    const financialInstitutionId = paramFinancialInstitutionId ? Number.parseInt(paramFinancialInstitutionId) : (propFinancialInstitutionId ? propFinancialInstitutionId : undefined);
    const { data: micrFormat, isLoading: isMicrFormatLoading } = useGetMicrFormatByIdQuery(micrFormatId ?? skipToken);
    const { data: checkProductTypes} = useGetMicrCheckProductTypesQuery();
    const [micrFormatDisplay, setMicrFormatDisplay] = useState<MicrFormatEdit | undefined>(undefined);

    useEffect(() => {
        setMicrFormatId(propMicrFormatId);
    }, [propMicrFormatId]);

    useEffect(() => {
        if (micrFormat) {
            setMicrFormatDisplay({...micrFormat,
                usesAccountNumberMatch: (micrFormat.accountNumberMatch !== undefined && micrFormat.accountNumberMatch !== null)});
        }
        else if (!micrFormat && financialInstitutionId && !micrFormatDisplay && !propMicrFormatId) {
            setMicrFormatDisplay({
                financialInstitutionId: financialInstitutionId,
                formatTemplate: MicrFormatTemplate.Default,
                accountNumberMinLength: DEFAULT_MICR_MIN_ACCOUNT_NUMBER_LENGTH,
                accountNumberMaxLength: DEFAULT_MICR_MAX_ACCOUNT_NUMBER_LENGTH
            });
        }
    }, [micrFormat, propMicrFormatId]);

    const confirmEditMicr = async () => {
        if (orderProduct) {
            onEditModeToggle(orderProduct.id);
        }
    }

    const onEditMicrClick = async () => {
        dispatch(showConfirmationModal({
            title: 'Override Micr Format?',
            content: 'Are you sure you want to edit and override the Micr Format?',
            size: 'lg',
            affirmText: 'Yes, Edit',
            onConfirm: confirmEditMicr,
        }));
    }

    const isProductTypeSelected = (checkProductType: ProductType) => {
        if (!micrFormatDisplay) {
            return false;
        }

        const checkProductTypeIndex = micrFormatDisplay.checkProductTypes?.findIndex(cpt => cpt.id === checkProductType.id) ?? -1;
        return checkProductTypeIndex > -1;
    }

    const getMatchInputRow = () => {
        if (!micrFormatDisplay) {
            return <></>;
        }
        return micrFormatDisplay.usesAccountNumberMatch
            ? (
                <Row>
                    <Col className="micr-value-input-column">
                        <Label className="micr-value-input-label">Account Number Match:</Label>
                        <span>{micrFormatDisplay.accountNumberMatch}</span>
                    </Col>
                </Row>
            )
            : (
                <Row>
                    <Col className="micr-value-input-column">
                        <Label className="micr-value-input-label">Account Number Min Length:</Label>
                        <span>{micrFormatDisplay.accountNumberMinLength}</span>
                    </Col>
                    <Col className="micr-value-input-column">
                        <Label className="micr-value-input-label">Account Number Max Length:</Label>
                        <span>{micrFormatDisplay.accountNumberMaxLength}</span>
                    </Col>
                </Row>
            )
    }

    const getOrderIdDisplay = () => {
        if (!orderProduct)
            return <></>;

        return (
            <>
                <Label className="micr-value-input-label">
                    Order Product: {orderProduct.sku}
                </Label>
            </>
        )
    }

    const getMicrValueDisplayRow = () => {
        if (!micrFormatDisplay || !orderProduct || isMicrFormatLoading) {
            return <></>;
        }

        return (
            <Row className="micr-value-template-edit">
                <Col>
                    <MicrValueDisplayMemoized micrFormatTemplate={micrFormatDisplay.formatTemplate}
                                              orderProductId={orderProduct.id}
                                              isOrderProductSpecific={!!micrFormatDisplay.orderProductId}
                    ></MicrValueDisplayMemoized>
                </Col>
            </Row>
        );
    }

    if (!micrFormatDisplay) {
        return <></>;
    }

    return (
        <Container>
            <Card className="financial-institution-details-card">

                <CardHeader className='d-flex justify-content-between card-header'>
                    <h5 className="m-0">Micr Format</h5>
                    <span className="micr-value-action-button-container">
                        <Button color="primary"
                                onClick={onEditMicrClick}
                        >Edit</Button>
                    </span>
                </CardHeader>
                <CardBody>

                    <Row>
                        <Col>
                            <Link to={`/admin/financialInstitution/${micrFormatDisplay.financialInstitutionId}`}
                                  className="micr-value-financial-instutition-name">
                                {micrFormatDisplay.financialInstitution?.name}
                            </Link>
                        </Col>
                        <Col>
                            {getOrderIdDisplay()}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="micr-value-input-matches-on-selector-container">
                            <span>
                                {micrFormatDisplay.usesAccountNumberMatch ?
                                    <span>Matches on Account Number Exact Match</span> :
                                    <span>Matches on Account Number Range</span>
                                }
                            </span>
                        </Col>
                    </Row>

                    {getMatchInputRow()}

                    <Row>
                        <Label className="micr-value-input-label">Check Product Types:</Label>
                        {checkProductTypes?.map((cpt) => {
                            return (
                                <span key={`micr-edit-product-type-${cpt.id}`}>{isProductTypeSelected(cpt) ? cpt.name : ''}</span>
                            )
                        })}
                    </Row>

                    <Row className="micr-value-template-edit">
                        <Col>
                            <h5 className="m-0">Micr Template</h5>
                            <MicrTextInput textValue={micrFormatDisplay.formatTemplate ?? ""}
                                           micrFormat={micrFormatDisplay}
                                           isReadOnly={true}
                            ></MicrTextInput>
                        </Col>
                    </Row>

                    {getMicrValueDisplayRow()}

                    <Row>
                        <Col xs='6'>
                            <MicrLegend></MicrLegend>
                        </Col>
                    </Row>

                </CardBody>
            </Card>
        </Container>
    )
}

export const MicrValueMemoized = memo(MicrValue);