import {useEffect, useState} from "react";
import {ColumnConfig, GridConfig} from "../admin/shared/Grid"
import {GridPage} from "../admin/shared/GridPage"
import {TaxItem} from "./TaxItem"
import {SortState} from "../../utils/Grid";
import {reportCol, reportDateToString, reportMoneyToString} from "../reports/ReportView";
import {DateTime} from "luxon";
import {apiSlice, TagTypes, useGetTaxProcessingPreviewQuery} from "../../app/apiSlice";
import {downloadWithAuth, Utils} from "../../utils/Utils";
import {useAppDispatch} from "../../app/hooks";
import {showConfirmationModal, showInformationModal} from "../modal/ModalSlice";

interface TaxProcessingCriteria {
    processDate: string;
}

export const TaxProcessingPage = () => {
    const [criteria, setCriteria] = useState<TaxProcessingCriteria>({ processDate: DateTime.now().toISODate()});
    const [defaultCriteria, setDefaultCriteria] = useState(criteria);
    const { data: queryTaxItems, isFetching, refetch: refetchTaxItems } = useGetTaxProcessingPreviewQuery(criteria.processDate);
    const [sort, setSort] = useState<SortState<TaxItem>>({ column: 'documentDate', direction: 'asc'});
    const [ sortedTaxItems, setSortedTaxItems ] = useState(queryTaxItems);
    const dispatch = useAppDispatch();
    
    // sort
    useEffect(() => {
        setSortedTaxItems([...queryTaxItems ?? []]?.sort(Utils.sortBy(sort.column, sort.direction)) ?? []);
    }, [sort, queryTaxItems])
    
    const onCriteriaChange = (newCriteria: TaxProcessingCriteria) => setCriteria(newCriteria);

    const onResetCriteria = () => {
        setCriteria(defaultCriteria);
        refetchTaxItems();
    };
    
    const confirmProcessTaxes = () => {
        dispatch(showConfirmationModal({
            content: 'Are you sure you want to process taxes for orders up to (and including): ' + criteria.processDate,
            onConfirm: processTaxes
        }));
    }
    
    const processTaxes = async () => {
        const hasContent = await downloadWithAuth(`/api/TaxProcessing?processDate=${criteria.processDate}`, `TaxItems-${criteria.processDate}.csv`, 'POST');
        if (hasContent) {
            apiSlice.util.invalidateTags([TagTypes.TaxProcessing]); // since this is a downloaded file, we have to go outside of rtk query, but we should still invalidate the cache
            refetchTaxItems();
        } else {
            dispatch(showInformationModal({ 
                text: 'No records found to process. No file uploaded or downloaded.'
            }));
        }
    }
    
    const config: GridConfig<TaxItem> = {
        configSections: [
            {
                columns: [
                    reportCol('orderId', 'Doc #'),
                    reportCol('documentDate', 'Doc Date', reportDateToString),
                    reportCol('shipFromAddress', 'Origin'),
                    reportCol('shipToAddress', 'Destination'),
                    reportCol('classCode', 'Product Class Code'),
                    reportCol('taxAmount', 'Tax Amount', reportMoneyToString),
                    
                    // criteria field
                    new ColumnConfig<TaxItem>({
                        field: 'processDate',
                        display: 'Process Date',
                        isHidden: true,
                        isSearchCriteria: true,
                        type: 'single-date',
                    })
                ]
            }
        ],
    }

    return <GridPage
            gridConfig={config}
            extraActions={[
                {
                    key: 'process',
                    children: 'Begin Processing',
                    color: 'primary',
                    onClick: confirmProcessTaxes,
                    className: 'mx-1',
                    disabled: !sortedTaxItems?.length
                }
            ]}
            onCriteriaChange={onCriteriaChange}
            onShouldRefetch={() => { refetchTaxItems(); }}
            onResetCriteria={onResetCriteria}
            defaultCriteria={defaultCriteria}
            title='Tax Processing'
            records={isFetching ? [] : (sortedTaxItems ?? [])}
            sort={sort}
            isLoading={isFetching}
            onSortChanged={setSort}
            shouldShowCriteriaPanel={true}/>
}