import React, {useState} from 'react';
import { Configuration } from '../configuration/Configuration';
import { Component } from '../component/Component';
import './ButtonRevealingTextInput.css';
import {Input, Label, Button, FormFeedback} from 'reactstrap';
import { ProductInputValue } from '../ProductInputValue';
import {FieldModifier} from "../configuration/FieldModifier";
import {useConfigurationRules} from "../../../app/hooks";
import {RuleTypes} from "../configuration/rule/ConfigurationRule";
import {getConfigurationIsSecureClass} from "../../../utils/Utils";
import {OnValueChangeParams} from "../view/ProductInputView";

interface ButtonRevealingTextInputProps {
    configuration?: Configuration,
    component?: Component,
    productInputValue?: ProductInputValue,
    isInEditMode: boolean,
    getClassesForModifiers: (fieldModifiers?: FieldModifier[]) => string,
    onValueChange: (params: OnValueChangeParams) => void,
    productVariantId?: number,
}

export const ButtonRevealingTextInput = ({ configuration, productInputValue, isInEditMode, getClassesForModifiers, onValueChange, productVariantId }: ButtonRevealingTextInputProps) => {
    const inputId = configuration?.id ?? -1;
    const inputLabel = (isInEditMode && configuration?.configurationDetail.actionLabel ? configuration?.configurationDetail.actionLabel : configuration?.configurationDetail.label) ?? "";
    const [ showTextInput, setShowTextInput ] = useState(!!productInputValue?.value);
    const rulesState = useConfigurationRules(configuration?.rules, productInputValue?.value, productVariantId, [RuleTypes.Matching, RuleTypes.Differing, RuleTypes.PositiveMatch]);

    function getEditModeTemplate() {
        return (
            <>
                {!showTextInput ? getToggleButton() : <></>}

                {showTextInput ? getTextInput() : <></>}
            </>
        )
    }

    function getReadModeTemplate() {
        return (
            <>
                {getTextInputLabel()}
                <span className={`text-input-read-only-value ${getConfigurationIsSecureClass(configuration)}`}>
                    {productInputValue?.value}
                </span>
            </>
        );
    }

    function getValueRenderTemplate() {
        if (isInEditMode) {
            return getEditModeTemplate();
        }
        else {
            return getReadModeTemplate();
        }
    }

    function getClassesForLabel() {
        if (!isInEditMode) {
            return "half-width-text-input";
        }
        return "";
    }

    // If in read only mode and there's no value, then show nothing
    if (!isInEditMode && !productInputValue?.value) {
        return <></>
    }

    function clearAndToggleTextInput() {
        onValueChange({ inputValue: null });
        toggleTextInput();
    }

    function toggleTextInput() {
        setShowTextInput(!showTextInput);
    }

    function getToggleButton() {
        return (
            <Button className="button-to-reveal-text-input" onClick={() => toggleTextInput()}> <h6>+ {inputLabel}</h6></Button>
        );
    }

    function getTextInputLabel() {
        return (
            <Label for={`text-input-${inputId}`} className={`button-revealing-text-input-label ${getClassesForLabel()}`}>
                {inputLabel}
            </Label>
        )
    }

    function getTextInput() {
        return (
            <>
                {getTextInputLabel()}
                <div className="button-to-reveal-text-input-user-input-container">
                    <div className="input-and-feedback-container">
                        <Input
                            className={getInputClasses()}
                            id={`text-input-${inputId}`}
                            type="text"
                            value={productInputValue?.value ?? ""}
                            onChange={(e) => onValueChange({ inputValue: e.target.value })}
                            invalid={rulesState.inputIsInvalid}
                        ></Input>
                        <FormFeedback tooltip>
                            {rulesState.brokenRuleMessage}
                        </FormFeedback>

                    </div>

                    <div>
                        <Button color="danger" className="remove-button-revealed-text-input-button" onClick={clearAndToggleTextInput} outline><h6>Remove</h6></Button>
                    </div>
                </div>
                { configuration?.helperText && <span>{configuration.helperText}</span> }
            </>
        )
    }

    function getInputClasses() {
        let classNames = getConfigurationIsSecureClass(configuration);

        if (rulesState.inputIsInvalid || (configuration?.shouldValidateValue && productInputValue?.isValidated === false)) {
            classNames += "invalid-text-input";
        }

        return classNames;
    }

    return (
        <span className={"button-revealing-text-input-container " + getClassesForModifiers()}>

            {getValueRenderTemplate()}

        </span>
    );
}
