import './OrderSearchCriteriaPanel.css'
import {OrderSearchCriteria} from "./OrderSearchCriteria";
import React, {useEffect, useState} from "react";
import {Button, Collapse, FormGroup, Input, Label} from "reactstrap";
import {MultiSelect} from "../../input/MultiSelect";
import {formatDate, Utils} from "../../../utils/Utils";
import {useGetOrderStatusesQuery, useGetSitesQuery} from "../../../app/apiSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {isFetchingOrders, selectOrderSearchCriteria, setOrderSearchCriteria} from "../../order/OrderSlice";
import {addDays, formatDateForInput, today} from "../../../utils/dates";

interface OrderSearchCriteriaPanelProps {
    isOpen: boolean
}

export const OrderSearchCriteriaPanel = (props: OrderSearchCriteriaPanelProps) => {
    const dispatch = useAppDispatch();
    const orderSearchCriteria = useAppSelector(selectOrderSearchCriteria);
    const [localCriteria, setLocalCriteria] = useState<OrderSearchCriteria>(orderSearchCriteria);
    
    const changeHandle = Utils.handleChanges(setLocalCriteria);
    const {data: statuses} = useGetOrderStatusesQuery();
    const {data: sites} = useGetSitesQuery();
    const isLoading = useAppSelector(isFetchingOrders);

    useEffect(() => {
        if (statuses) {
            const newCriteria = {
                statusIds: statuses.map(s => s.id),
                endDate: formatDateForInput(addDays(0, today())),
                startDate: formatDateForInput(addDays(-7, today())),
                ...localCriteria
            }
            setLocalCriteria(newCriteria);
            dispatch(setOrderSearchCriteria(newCriteria))
        }
    }, [statuses]);

    useEffect(() => {
        setLocalCriteria(orderSearchCriteria)
    }, [orderSearchCriteria]);
    
    function onResetClicked() {
        const defaultCriteria: OrderSearchCriteria = {
            statusIds: statuses?.map(s => s.id)
        };
        setLocalCriteria(defaultCriteria);
        dispatch(setOrderSearchCriteria(defaultCriteria));
    }

    const onEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !isLoading) {
            executeSearch()
        }
    };

    const executeSearch = () => dispatch(setOrderSearchCriteria(localCriteria));
    
    const ActionRow = (className?: string) => (
        <div className={'d-flex justify-content-between flex-row-reverse ' + (className ?? '')}>
            <Button color='primary'
                    onClick={executeSearch}
                    disabled={isLoading}
            >
                Search
            </Button>
            <Button onClick={onResetClicked}>
                Reset
            </Button>
        </div>
    );

    return (
        <Collapse horizontal isOpen={props.isOpen}>
            <div id='OrderSearchCriteriaContainer'>
                {ActionRow('mb-3')}

                <FormGroup>
                    <Input placeholder='Order #'
                           id='OrderId'
                           type='number'
                           name='orderId'
                           autoComplete="autoComplete_off"
                           value={localCriteria.orderId?.toString() ?? ''}
                           onKeyDown={onEnterKeyDown}
                           onChange={changeHandle}/>
                    {(localCriteria.orderId ?? 1) <= 0 &&
                        <p className='text-danger'>Invalid Order #. It must be 1 or greater.</p>}
                </FormGroup>

                <FormGroup>
                    <Input id="StorefrontId" 
                           name="siteId"
                           autoComplete="autoComplete_off" 
                           type="select"
                           value={localCriteria.siteId ?? ''} onChange={changeHandle}>
                        <option value=''>Any Storefront</option>
                        {sites?.map(s => (
                            <option key={s.id} value={s.id}>{s.name}</option>
                        ))}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label for='StartDate'>Order Date</Label>
                    <Input id='StartDate' 
                           autoComplete="autoComplete_off" 
                           type='date'
                           max={localCriteria.endDate}
                           name='startDate'
                           value={localCriteria.startDate ?? ''}
                           onKeyDown={onEnterKeyDown}
                           onChange={changeHandle}/>
                    <Label for='EndDate'>to</Label>
                    <Input id='EndDate'
                           autoComplete="autoComplete_off"
                           type='date'
                           min={localCriteria.startDate}
                           name='endDate'
                           value={localCriteria.endDate ?? ''}
                           onKeyDown={onEnterKeyDown}
                           onChange={changeHandle}/>
                </FormGroup>

                <FormGroup className="criteria-panel-control-container">
                    <Label>Order Status</Label>
                    <MultiSelect options={statuses?.map(s => ({value: s.id, display: s.name})) ?? []}
                                 values={localCriteria.statusIds ?? []}
                                 onChange={ids => setLocalCriteria(old => ({...old, statusIds: ids}))}/>
                    {localCriteria.statusIds && localCriteria.statusIds?.length < 1 &&
                        <p className='text-danger'>At least one Order Status must be selected.</p>}
                </FormGroup>

                <FormGroup>
                    <Input placeholder='Customer Id'
                           name='consumerId'
                           autoComplete="autoComplete_off"
                           value={localCriteria.consumerId ?? ''}
                           onKeyDown={onEnterKeyDown}
                           onChange={changeHandle}
                    />
                </FormGroup>
                <FormGroup>
                    <Input placeholder='Customer Name'
                           name='userName'
                           autoComplete="autoComplete_off"
                           value={localCriteria.userName ?? ''}
                           onKeyDown={onEnterKeyDown}
                           onChange={changeHandle}
                    />
                </FormGroup>
                <FormGroup>
                    <Input placeholder='ABA'
                           name='aba'
                           autoComplete="autoComplete_off"
                           value={localCriteria.aba ?? ''}
                           onKeyDown={onEnterKeyDown}
                           onChange={changeHandle}
                    />
                </FormGroup>
                <FormGroup>
                    <Input placeholder='Account Number'
                           name='accountNumber'
                           autoComplete="autoComplete_off"
                           value={localCriteria.accountNumber ?? ''}
                           onKeyDown={onEnterKeyDown}
                           onChange={changeHandle}
                    />
                </FormGroup>
                <FormGroup>
                    <Input placeholder='Email'
                           name='userEmail'
                           value={localCriteria.userEmail ?? ''}
                           onKeyDown={onEnterKeyDown}
                           onChange={changeHandle}
                    />
                </FormGroup>

                {ActionRow('mt-3')}
            </div>
        </Collapse>
    );
};
