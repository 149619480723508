import { GridPage } from "../../shared/GridPage";
import { useGetMicrFormatsQuery } from "../../../../app/apiSlice";
import { useEffect, useState } from "react";
import { ColumnConfig, GridConfig } from "../../shared/Grid";
import { SortState } from "../../../../utils/Grid";
import {Utils} from "../../../../utils/Utils";
import { Link } from "react-router-dom";
import './MicrBrowse.css';
import {MicrFormat} from "../MicrFormat";
import {MicrFormatSearchCriteria} from "../MicrFormatSearchCriteria";
import {DateTime} from "luxon";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import { useClaims } from "../../../../app/hooks";
import { ADMIN, TEAM_LEAD } from "../../../../utils/Roles";
import {ProductType} from "../../../product/Product";

interface MicrBrowseProps {
    financialInstitutionId?: number
}

export const MicrBrowse = ({ financialInstitutionId }: MicrBrowseProps) => {
    const { hasPermission } = useClaims();
    const { data: queryMicrFormats } = useGetMicrFormatsQuery(financialInstitutionId ?? skipToken);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState<SortState<MicrFormat>>({ column: 'id', direction: 'asc' });
    const [ sortedMicrFormats, setSortedMicrFormats ] = useState(queryMicrFormats);
    const [criteria, setCriteria] = useState<MicrFormatSearchCriteria>({});

    // sort
    useEffect(() => {
        setSortedMicrFormats(micrFormats => {
            const workingMicrFormats = [...micrFormats ?? queryMicrFormats ?? []];
            return workingMicrFormats.sort(Utils.sortBy(sort.column, sort.direction));
        });
    }, [sort, queryMicrFormats]);

    // filter
    useEffect(() => {
        const lowerSearch = search.toLowerCase();
        setSortedMicrFormats(
            !lowerSearch ? queryMicrFormats : queryMicrFormats?.filter(mf =>
                (mf.accountNumberMinLength?.toString().toLowerCase().includes(lowerSearch)) ||
                (mf.accountNumberMaxLength?.toString().toLowerCase().includes(lowerSearch)) ||
                (mf.accountNumberMatch?.toString().toLowerCase().includes(lowerSearch)) ||
                (mf.formatTemplate?.toString().toLowerCase().includes(lowerSearch)) ||
                (mf.timeCreated && DateTime.fromISO(mf.timeCreated + 'Z', {zone: 'utc'}).toLocaleString(DateTime.DATETIME_SHORT).includes(lowerSearch)) ||
                (mf.timeLastUpdated && DateTime.fromISO(mf.timeLastUpdated + 'Z', {zone: 'utc'}).toLocaleString(DateTime.DATETIME_SHORT).includes(lowerSearch)) ||
                (mf.checkProductTypes?.map(pt => pt.name).join('').toLowerCase().includes(lowerSearch))
            )
        );
    }, [search, queryMicrFormats]);

    const config: GridConfig<MicrFormat> = {
        configSections: [
            {
                columns: [
                    new ColumnConfig({
                        field: 'id',
                        display: 'Actions',
                        cellDisplay: val => val ? <Link className='btn btn-primary mx-1 med-max-width' to={`/admin/financialInstitution/micrEdit/${val}`}>Edit</Link> : '',
                        isSortable: false
                    }),
                    new ColumnConfig({
                        field: 'isActive',
                        display: 'Active',
                        cellDisplay: val => <div className="text-center">{`${val ? 'Yes' : 'No'}`}</div>
                    }),
                    new ColumnConfig({
                        field: 'accountNumberMinLength',
                        display: 'Account Number Min Length',
                    }),
                    new ColumnConfig({
                        field: 'accountNumberMaxLength',
                        display: 'Account Number Max Length',
                    }),
                    new ColumnConfig({
                        field: 'accountNumberMatch',
                        display: 'Account Number Match',
                    }),
                    new ColumnConfig({
                        field: 'checkProductTypes',
                        display: 'Check Product Types',
                        cellDisplay: productTypes => (productTypes as ProductType[])?.map(pt => pt.name).join(', ') ?? ''
                    }),
                    new ColumnConfig({
                        field: 'formatTemplate',
                        display: 'Format Template',
                    }),
                    new ColumnConfig({
                        field: 'timeLastUpdated',
                        display: 'Time Last Updated',
                        cellDisplay: val => val ? DateTime.fromISO(val as string + 'Z').toLocaleString(DateTime.DATETIME_SHORT) : ''
                    }),
                    new ColumnConfig({
                        field: 'timeCreated',
                        display: 'Time Created',
                        cellDisplay: val => val ? DateTime.fromISO(val as string + 'Z').toLocaleString(DateTime.DATETIME_SHORT) : ''
                    }),
                ]
            }
        ]
    };

    if (!financialInstitutionId) {
        return (<>Save the Financial Institution in order to add Micrs</>);
    }

    return (
        <GridPage records={sortedMicrFormats ?? []}
                  gridConfig={config}
                  title={'Micrs'}
                  newLink={hasPermission([ADMIN, TEAM_LEAD]) ? `/admin/financialInstitution/${financialInstitutionId}/micrEdit` : undefined}
                  sort={sort}
                  shouldShowCriteriaPanel={false}
                  onSortChanged={setSort}
                  onCriteriaChange={setCriteria}
                  onSearchChange={setSearch}
                  noRecordsMessage={queryMicrFormats && queryMicrFormats.length > 0
                      ? "No Micrs found using the search criteria"
                      : "No Micrs exist on record for this Financial Institution"}/>
    )
}