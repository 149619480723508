import './WelcomeMessage.css';
import {useTranslation} from "react-i18next";

export const WelcomeMessage = () => {
    const { t } = useTranslation();
    return (
        <div className='welcome-section'>
            <div className='welcome-container d-flex flex-column container'>
                <p>
                    {t('welcomeMessage.body1')}
                </p>

                <p>
                    {t('welcomeMessage.body2')}
                </p>

                <p>
                    {t('welcomeMessage.body3')}
                </p>

                <p>
                    {t('welcomeMessage.body4')}
                </p>
            </div>
        </div>
    )
}