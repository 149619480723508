import { Configuration } from '../configuration/Configuration';
import { Component } from '../component/Component';
import './RadioSelectWithBoxedDescription.css';
import { Input, Label } from 'reactstrap';
import { ProductInputValue } from '../ProductInputValue';
import {AddOnDetails} from "../../addOn/details/AddOnDetails";
import {AddOn} from "../../addOn/AddOn";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {FieldModifier} from "../configuration/FieldModifier";
import {ConfigurationOption} from "../configuration/ConfigurationOption";
import {RequiredAsterisk} from "../utils/RequiredAsterisk";
import {useEffect} from "react";
import {getConfigurationIsSecureClass} from "../../../utils/Utils";
import {OnValueChangeParams} from "../view/ProductInputView";

interface RadioSelectWithBoxedDescriptionProps {
    configuration?: Configuration,
    component?: Component,
    productInputValue?: ProductInputValue,
    isInEditMode: boolean,
    getClassesForModifiers: (fieldModifiers?: FieldModifier[]) => string,
    onValueChange: (params: OnValueChangeParams) => void
}

export const RadioSelectWithBoxedDescription = ({ configuration, productInputValue, isInEditMode, getClassesForModifiers, onValueChange }: RadioSelectWithBoxedDescriptionProps) => {
    const inputId = configuration?.id ?? -1;
    const inputLabel = configuration?.configurationDetail.label ?? "";
    const options = configuration?.configurationDetail.options ?? [];
    const isRequired = configuration?.configurationDetail.isRequired;

    useEffect(() => {
        if (!productInputValue?.isDirty && !productInputValue) {
            const defaultOption = getDefaultOption();
            if (defaultOption)
                onValueChange({ inputValue: defaultOption.value, optionId: defaultOption.id });
        }
    }, [productInputValue]);

    function onOptionSelected(option: ConfigurationOption) {
        onValueChange({ inputValue: option.value, optionId: option.id });
    }

    function getDefaultOption() {
        const defaultOptionIndex = options.findIndex(o => o.isDefault);
        return options[defaultOptionIndex];
    }

    function getEditModeTemplate() {
        return (
            <>
            <span className="radio-select-options-container">
                {options.map((option) => {
                    return (
                        <span key={'radio-button-select-' + option.value + '-' + option.id} className="radio-option-input-container">
                            <Input
                                id={option.id.toString()}
                                className={`radio-option-input ${getInputClasses()}`}
                                name={configuration?.configurationKey}
                                type="radio"
                                value={option.value}
                                checked={isOptionSelected(option) ?? false}
                                onChange={(e) => onOptionSelected(option)}
                            />
                            <Label for={option.id.toString()} check>
                                {option.displayName}
                                {option.addOn?.price ? ` +(${CurrencyFormatter.format(option.addOn.price)})` : ""}
                            </Label>
                        </span>);
                })}
            </span>

            <span className="boxed-description-container">
                {getBoxedDescriptions()}
            </span>
            </>
        );
    }

    function getReadModeTemplate() {
        return (
            <span className={`text-input-read-only-value ${getConfigurationIsSecureClass(configuration)}`}>
                {productInputValue?.value}
            </span>
        );
    }

    function getValueRenderTemplate() {
        if (isInEditMode) {
            return getEditModeTemplate();
        }
        else {
            return getReadModeTemplate();
        }
    }

    function getInputClasses() {
        let classNames = getConfigurationIsSecureClass(configuration);
        return classNames;
    }

    function getClassesForLabel() {
        if (!isInEditMode) {
            return "half-width-text-input";
        }
        return "";
    }

    function isOptionSelected(option: ConfigurationOption) {
        return productInputValue?.selectedProductInputOptionId === option.id
            || (productInputValue?.isDirty && !productInputValue.selectedProductInputOptionId && option.isNoSelection);
    }

    function onAddOnDetailsClicked(addOnId: number) {
        const filteredOptions = options.filter((option) => option.addOn?.id === addOnId);
        if (filteredOptions.length > 0) {
            onOptionSelected(filteredOptions[0]);
        }
    }

    function isAddOnSelected(addOnId: number) {
        return options
            .filter((option) => option.id === productInputValue?.selectedProductInputOptionId)
            .map((option) => option.addOn?.id)
            .includes(addOnId);
    }

    function getBoxedDescriptions() {

        const addOnOptions = options
            .map((option) => {
                return option.addOn
            });

        return (
            (addOnOptions
                .filter((addOnOption) => addOnOption) as AddOn[])
                .map((addOnOption) => {
                return(
                    <AddOnDetails key={addOnOption.sku} addOn={addOnOption} isSelected={isAddOnSelected(addOnOption.id)} onAddOnDetailsClicked={onAddOnDetailsClicked}></AddOnDetails>
                );
            })
        )
    }

    return (
        <span className={"radio-select-with-boxed-description-container " + getClassesForModifiers()}>

            <Label for={`text-input-${inputId}`} className={getClassesForLabel()}>
                {inputLabel}
                {isRequired && isInEditMode ? <RequiredAsterisk></RequiredAsterisk> : <></>}
            </Label>

            {getValueRenderTemplate()}

        </span>
    );
}
