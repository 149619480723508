import {useState, useEffect, useRef} from "react";

export const StickySidebar = (props: {children: JSX.Element})=> {
    const sidebar = useRef<HTMLDivElement>(null);
    const content = useRef<HTMLDivElement>(null);
    const [scrollPosition, setScrollPosition] = useState(window.scrollY);
    const [windowDimensions, setWindowDimensions] = useState({height: window.innerHeight, width: window.innerWidth});
  
    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    const handleResize = () => {
        setWindowDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
    }
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        window.addEventListener('resize', handleResize, { passive: true} );
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(()=>{
        if(!(sidebar.current?.parentElement && content.current)) return;
        if(windowDimensions.width < 769){//mobile layout
            sidebar.current.style.position = "static";
            sidebar.current.style.top = "auto";
            sidebar.current.style.bottom = "auto";
            sidebar.current.style.width = `${sidebar.current?.parentElement?.clientWidth}px`;
            return;
        }

        sidebar.current.style.width = `${sidebar.current?.parentElement?.clientWidth}px`;
        const height = content.current.clientHeight;
        const parentPosition = {
            top: sidebar.current?.parentElement?.getBoundingClientRect().top,
            bottom: sidebar.current?.parentElement?.getBoundingClientRect().bottom
        }

        if (height > windowDimensions.height){//if the sidebar is taller than viewport
            if(parentPosition.bottom < windowDimensions.height){
                sidebar.current.style.position = "relative";
                sidebar.current.style.top = `${parentPosition.bottom - parentPosition.top - height}px`;
                sidebar.current.style.bottom = "auto";
            }else if(parentPosition.top + height < windowDimensions.height){
                sidebar.current.style.position = "fixed";
                sidebar.current.style.top = "auto";
                sidebar.current.style.bottom = "0";
            }else{
                sidebar.current.style.position = "static";
                sidebar.current.style.top = "auto";
                sidebar.current.style.bottom = "auto";
            }
        }else{//if the sidebar can fit in the viewport
            if(parentPosition.top >= 0){
                sidebar.current.style.position = "static";
                sidebar.current.style.top = "auto";
                sidebar.current.style.bottom = "auto";
            }
            else if(height > parentPosition.bottom ){
                sidebar.current.style.position = "relative";
                sidebar.current.style.top = `${parentPosition.bottom - parentPosition.top - height}px`;
                sidebar.current.style.bottom = "auto";
            }else{
                sidebar.current.style.position = "fixed";
                sidebar.current.style.top = "0";
                sidebar.current.style.bottom = "auto";
            }
        } 
    }, [scrollPosition, windowDimensions, props]);

    return(
        <div ref = {sidebar} className='sticky-sidebar'>
            <div ref = {content} className='sticky-sidebar-content'>
                {props.children}
            </div>
        </div>
    )
}