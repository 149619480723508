import React, { useRef, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup, InputGroupText,
    Label,
    Row, Spinner
} from "reactstrap";
import styles from './CustomLogoManager.module.css';
import {
    useGetCustomLogoQuery,
    useGetImageHostQuery,
    useUpdateCustomLogoMutation
} from "../../../app/apiSlice";
import { getErrorMessage, readFileToBase64 } from "../../../utils/Utils";
import { CartIdType } from "../../cart/CartSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppDispatch } from "../../../app/hooks";
import { showInformationModal } from "../../modal/ModalSlice";

interface CustomLogoManagerProps {
    orderId?: number;
    cartId?: CartIdType;
    locked: boolean
}
export const CustomLogoManager = ({ orderId, cartId, locked }: CustomLogoManagerProps) => {
    const { data: host } = useGetImageHostQuery();
    const { data: currentLogo } = useGetCustomLogoQuery(!host ? skipToken : { orderId, cartId });
    const [ updateCustomLogo, { isLoading: isAttachingToOrder } ] = useUpdateCustomLogoMutation();
    const dispatch = useAppDispatch();
    
    const [ fileName, setFileName ] = useState<string>();
    const [ containsPersonalization, setContainsPersonalization ] = useState<boolean>();
    const [ fileBase64, setFileBase64 ] = useState<string>();
    const [ imgRefresh, setImgRefresh ] = useState(new Date().getTime());
    const [ readyForValidation, setReadyForValidation ] = useState(false);
    const [ manualEditMode, setManualEditMode ] = useState(false);
    const [ lastImageLoadWasSuccessful, setLastImageLoadWasSuccessful ] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const currFileName = fileName ?? currentLogo?.name ?? '';
    const currContainsPersonalization = containsPersonalization ?? currentLogo?.containsPersonalization ?? false;
    const noChanges = currentLogo && currFileName === currentLogo.name && containsPersonalization === currentLogo.containsPersonalization && !fileBase64;
    const isMissingRequiredFields = !(currFileName && (lastImageLoadWasSuccessful || fileBase64));
    const shouldBeInReadOnlyMode = !!(locked || (!cartId && currentLogo && !manualEditMode));

    const fileSelected = async (file: File) => {
        setFileName(file.name);
        setFileBase64(await readFileToBase64(file));
    }
    
    const clearFile = () => {
        setFileName(undefined);
        setFileBase64(undefined);
        if (fileInputRef.current) fileInputRef.current.value = ''; // reset file picker
    }
    
    const attachToOrder = async () => {
        setReadyForValidation(true);

        // validate filename is present
        if (!currFileName) return;
        
        // Only upload straight to the order if we're not in a cart context
        let uploadOrderId = cartId ? undefined : orderId;
        
        try {
            await updateCustomLogo({
                fileName: currFileName,
                containsPersonalization: currContainsPersonalization,
                fileBase64,
                cartId: cartId,
                orderId: uploadOrderId,
            }).unwrap();
            setImgRefresh(new Date().getTime());
            setManualEditMode(false);
        } catch (e: any) {
            dispatch(showInformationModal({
                title: 'Upload Failed',
                text: 'Error uploading custom logo: ' + getErrorMessage(e)
            }));
        }
    };

    if (!(currentLogo?.orderOrCartCanUseCustomLogo ?? false)) {
        return <></> // can't use a custom logo for this order
    }

    return <Card>
        <CardHeader>
            <h5>Custom Logo</h5>
        </CardHeader>
        <CardBody>
            <Row>
                <Col>
                    <FormGroup>
                        <Label className='w-100'>File Name:
                            <Input disabled={shouldBeInReadOnlyMode || !!fileBase64}
                                   type='text' value={currFileName}
                                   onChange={e => setFileName(e.target.value)}
                                   invalid={readyForValidation && !currFileName}/>
                            <FormFeedback>File name is required!</FormFeedback>
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Label className='w-100'>File Upload:
                            <InputGroup>
                                <Input innerRef={fileInputRef}
                                       disabled={shouldBeInReadOnlyMode}
                                       type='file'
                                       onChange={e => fileSelected(e.target.files![0])}/>
                                {!shouldBeInReadOnlyMode && <InputGroupText>
                                    <Button onClick={clearFile} size='sm' color='danger' className='py-0'>Clear</Button>
                                </InputGroupText>}
                            </InputGroup>
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Label>Contains personalization?
                            <Input disabled={shouldBeInReadOnlyMode} className='ms-1' type='checkbox' checked={currContainsPersonalization}
                                   onChange={e => setContainsPersonalization(e.target.checked)}/>
                        </Label>
                    </FormGroup>
                    {
                        shouldBeInReadOnlyMode ?
                            <Button color='primary' onClick={() => setManualEditMode(true)}>
                                Edit
                            </Button>:
                            <Button disabled={isAttachingToOrder || isMissingRequiredFields || noChanges} color='primary' onClick={attachToOrder}>
                                Attach to Order
                                { isAttachingToOrder && <Spinner/> }
                            </Button>
                    }
                </Col>

                <Col>
                    <div className={styles.logoPreviewContainer}>
                        <img className={styles.logoPreview}
                             onLoad={() => setLastImageLoadWasSuccessful(true)}
                             onError={() => setLastImageLoadWasSuccessful(false)}
                             src={`${host}/customlogo/${currFileName}?date=${imgRefresh}`}
                             alt=""/>
                    </div>
                </Col>
            </Row>
        </CardBody>
    </Card>
}