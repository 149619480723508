
import './ConfigurationReview.css';
import {CheckPreview} from "../../../check/CheckPreview";
import {ItemType} from "../../../product/ItemType";
import { CartIdType } from '../../../cart/CartSlice';

interface ConfigurationGroupViewProps {
  sku: string
  siteId?: number
  itemType: ItemType
  siteProductVariantId: number
  cartId: CartIdType
  orderProductId: number | undefined
}

export const ConfigurationReview = ({ sku, siteId, itemType, siteProductVariantId, cartId, orderProductId }: ConfigurationGroupViewProps) => {

    if (!itemType.supportsPreview) {
        return <></>
    }

    return (
        <div className="configuration-review-view-container">
            <CheckPreview sku={sku} siteId={siteId} siteProductVariantId={siteProductVariantId} cartId={cartId} orderProductId={orderProductId}/>
        </div>
    );
}