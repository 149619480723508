import '../Accordions.css';
import {useTranslation} from "react-i18next";
import { Link } from 'react-router-dom';
import {doesTranslationValueExist, getTranslationValue} from "../../../../../utils/Utils";

export const AfterOrderCard = () => {
    const { t } = useTranslation();

    return (
        <span className='order-accordion-questions d-flex flex-column'>
            <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.afterOrder.question1`)}</span>
                {doesTranslationValueExist(t, `pages.faq.help.afterOrder.answer1Pt1`) ? (
                  <span>
                    {getTranslationValue(t, 'pages.faq.help.afterOrder.answer1Pt1')}
                    <a href={'tel:' + getTranslationValue(t, 'pages.faq.help.afterOrder.answer1Link')} target='_blank' rel='noreferrer'>
                        {getTranslationValue(t, 'pages.faq.help.afterOrder.answer1LinkText')}
                    </a>,
                    {getTranslationValue(t, 'pages.faq.help.afterOrder.answer1Pt2')}
                  </span>
                ) : (
                  <span>
                    {getTranslationValue(t, `pages.faq.help.afterOrder.answer1Part1`)}
                    <a className='link-primary' href={'tel:'+getTranslationValue(t, `phoneNumber.number`)}>
                    {getTranslationValue(t, `phoneNumber.display`)}
                    </a>,
                    {getTranslationValue(t, `pages.faq.help.afterOrder.answer1Part2`)}
                  </span>
                )}
            </span>
            <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.afterOrder.question2`)}</span>
                {doesTranslationValueExist(t, `pages.faq.help.afterOrder.answer2Link2`) ? (
                  <span>
                    {getTranslationValue(t, 'pages.faq.help.afterOrder.answer2')}
                    <a href={'tel:' + getTranslationValue(t, 'pages.faq.help.afterOrder.answer2Link')} target='_blank' rel='noreferrer'>
                        {getTranslationValue(t, 'pages.faq.help.afterOrder.answer2LinkText')}
                    </a>
                    {getTranslationValue(t, 'pages.faq.help.afterOrder.answer2Pt2')}
                    <a href={getTranslationValue(t, 'pages.faq.help.afterOrder.answer2Link2')} target='_blank'>
                        {getTranslationValue(t, 'pages.faq.help.afterOrder.answer2Link2Text')}
                    </a>.
                  </span>
                ) : (
                  <span>
                    {getTranslationValue(t, 'pages.faq.help.afterOrder.answer2')}
                  </span>
                )}
            </span>
            <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.afterOrder.question3`)}</span>
                {doesTranslationValueExist(t, 'pages.faq.help.afterOrder.answer3Part2') ? (
                  <span>
                    <span>
                      {getTranslationValue(t, `pages.faq.help.afterOrder.answer3Part1`)}
                      <a href={getTranslationValue(t, `pages.faq.help.afterOrder.answer3Link`)} target='_blank'>
                        {getTranslationValue(t, `pages.faq.help.afterOrder.answer3LinkText`)}
                      </a>
                      {getTranslationValue(t, `pages.faq.help.afterOrder.answer3Part2`)}
                      <a href={getTranslationValue(t, `pages.faq.help.afterOrder.answer3Link2`)} target='_blank'>
                        {getTranslationValue(t, `pages.faq.help.afterOrder.answer3LinkText2`)}
                      </a>.
                      {getTranslationValue(t, `pages.faq.help.afterOrder.answer3Part3`)}
                    </span>
                  </span>
                ) : (
                  <span>{getTranslationValue(t, `pages.faq.help.afterOrder.answer3`)}</span>
                )}
            </span>
            <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.afterOrder.question4`)}</span>
                {doesTranslationValueExist(t, 'pages.faq.help.afterOrder.answer4Link2') ? (
                  <span>
                    {getTranslationValue(t, `pages.faq.help.afterOrder.answer4`)}
                    <a href={'tel:' + getTranslationValue(t, `pages.faq.help.afterOrder.answer4Link`)} target='_blank' rel='noreferrer'>
                      {getTranslationValue(t, `pages.faq.help.afterOrder.answer4LinkText`)}
                    </a>
                    {getTranslationValue(t, `pages.faq.help.afterOrder.answer4Pt2`)}
                    <a href={getTranslationValue(t, `pages.faq.help.afterOrder.answer4Link2`)} target='_blank'>
                      {getTranslationValue(t, `pages.faq.help.afterOrder.answer4Link2Text`)}
                    </a>.
                  </span>
                ) : (
                  <span>
                    {getTranslationValue(t, `pages.faq.help.afterOrder.answer4Part1`)}
                    <Link className='link-primary' to={'/'}>{getTranslationValue(t, `pages.faq.help.afterOrder.answer4Website`)}</Link>
                    {getTranslationValue(t, `pages.faq.help.afterOrder.answer4Part2`)}
                  </span>
                )}
            </span>
            <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.afterOrder.question5`)}</span>
                {doesTranslationValueExist(t, 'pages.faq.help.afterOrder.answer5Link2') ? (
                  <span>
                      {getTranslationValue(t, `pages.faq.help.afterOrder.answer5`)}
                      <a href={'tel:' + getTranslationValue(t, `pages.faq.help.afterOrder.answer5Link`)} target='_blank'>
                        {getTranslationValue(t, `pages.faq.help.afterOrder.answer5LinkText`)}
                      </a>
                    {getTranslationValue(t, `pages.faq.help.afterOrder.answer5Pt2`)}
                    <a href={getTranslationValue(t, `pages.faq.help.afterOrder.answer5Link2`)} target='_blank'>
                        {getTranslationValue(t, `pages.faq.help.afterOrder.answer5Link2Text`)}
                      </a>.
                    </span>
                ) : (
                  <span>{getTranslationValue(t, `pages.faq.help.afterOrder.answer5`)}</span>
                )}
            </span>
            <span className='d-flex flex-column'>
                <span className='order-accordion-order-question'>{getTranslationValue(t, `pages.faq.help.afterOrder.question6`)}</span>
                <span>{getTranslationValue(t, `pages.faq.help.afterOrder.answer6`)}</span>
            </span>
        </span>
    );
};