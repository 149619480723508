import { SiteProductVariantQuantityOption } from "../product/SiteProductVariantQuantityOption";
import {Address} from "../input/address/Address";
import {UnitDetail} from "../admin/order/tabs/details/orderProduct/unitDetail/UnitDetail";
import {OrderProductReprint} from "../admin/order/tabs/details/orderProduct/unitDetail/OrderProductReprint";

export interface OrderProductVm {
  id: number,
  orderId: number,
  siteProductVariantId: number,
  componentOptionValues: string[],
  sku: string,
  siteProductVariantName: string,
  imageId?: number,
  thumbnailId?: number,
  doNotShip: boolean,
  doNotReorder: boolean,
  quantityDescription: string,
  quantity: number,
  weight: number,
  length: number,
  width: number,
  shippingOptionName: string,
  isAcknowledged: boolean,
  isCancelled: boolean,
  isConfigurable: boolean,
  quantityOption: SiteProductVariantQuantityOption,
  archivedProductDetail: string,
  archivedProductDetailValues: Record<string, object>,
  address: Address,
  totalCharged: number,
  orderProductReprints: OrderProductReprint[],
  unitDetails?: UnitDetail[]
}

export enum ComponentOptionValuesEnum {
  Single = 'Single',
  Duplicates = 'Duplicates',
  Blue = 'Blue',
  Green = 'Green',
  Yellow = 'Yellow',
  NormalCollation = 'Normal Collation',
  ReverseCollation = 'Reverse Collation'
}