import {
    Card,
    CardBody,
    CardHeader,
    Input
} from "reactstrap";
import React, {useEffect, useState} from "react";
import {
    useGetOrderPricingQuery, useGetOrderProductsQuery,
} from "../../../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import "./ProductSummary.css";
import {CurrencyFormatter} from "../../../../utils/CurrencyFormatter";
import {SiteProductVariantPricing} from "../../../cart/CartPricing";

interface Discount {
    code?: string,
    percentage?: number,
    dollarAmount?: number,
    description?: string
}
interface ProductSummaryProps {
    orderId: number,
    locked?: boolean,
    onOrderProductSelected: (siteProductVariantPricing?: SiteProductVariantPricing) => void,
}
export const ProductSummary = (props: ProductSummaryProps) => {

    const [ state, setState ] = useState<{
        discounts: Discount[],
    }>({
        discounts: [],
    });

    const { data: pricing } = useGetOrderPricingQuery(props.orderId ?? skipToken);
    const { data: orderProducts } = useGetOrderProductsQuery(props.orderId ?? skipToken);

    const [ selectedOrderProductId, setSelectedOrderProductId ] = useState<number | undefined>();

    useEffect(() => {
        props.onOrderProductSelected(getSiteProductVariantTotal());
    }, [selectedOrderProductId, pricing]);

    const getShippingTotal = () => {
        const spvt = getSiteProductVariantTotal();
        if (!spvt || spvt.shippingPrice === undefined)
            return "";

        return CurrencyFormatter.format(spvt.shippingPrice);
    }

    const getHandlingTotal = () => {
        const spvt = getSiteProductVariantTotal();
        if (!spvt || spvt.handling === undefined)
            return "";

        return CurrencyFormatter.format(spvt.handling);
    }

    const getTaxTotal = () => {
        const spvt = getSiteProductVariantTotal();
        if (!spvt || spvt.taxAmount === undefined)
            return "";

        return CurrencyFormatter.format(spvt.taxAmount);
    }

    const getProductTotal = () => {
        const spvt = getSiteProductVariantTotal();
        if (!spvt || spvt.productTotal === undefined)
            return "";

        return CurrencyFormatter.format(spvt.productTotal);
    }

    const getTotal = (orderProductId?: number) => {
        const spvt = getSiteProductVariantTotal(orderProductId);
        if (!spvt || spvt.total === undefined)
            return "";

        return CurrencyFormatter.format(spvt.total)
    }

    const getSiteProductVariantTotal = (orderProductId?: number) => {
        if ((!orderProductId && !selectedOrderProductId) || !pricing || !pricing.siteProductVariantTotals)
            return;

        if (orderProductId)
            return pricing.siteProductVariantTotals.find(spvt => spvt.orderProductId === orderProductId);

        return pricing.siteProductVariantTotals.find(spvt => spvt.orderProductId === selectedOrderProductId);
    }

    const onSelectedOrderProductChanged = (value?: string) => {
        setSelectedOrderProductId(value ? parseInt(value) : undefined);
    }

    const getOrderProductChooser = () => {
        return (
            <Input type="select"
                className="order-product-summary-product-select-input"
                onChange={(e) => onSelectedOrderProductChanged(e.target.value)}
                value={selectedOrderProductId}>
                <option value="">No Product Selected</option>
                {orderProducts?.map((op) => {
                    return (
                        <option key={`order-product-summary-chooser-${op.id}`}
                            value={op.id}>
                            {`${op.siteProductVariantName} - ${getTotal(op.id)}`}
                        </option>
                    );
                })}
            </Input>
        )
    }

    const ProductDetails = (productPricing: SiteProductVariantPricing) => {
        return (
            <>
                <div className="product-summary-grid">
                    <table className='table table-bordered mb-0'>
                        <thead>
                            <tr>
                                <th>Base Price</th>
                                <th>EZ Shield</th>
                                <th>Woodcut</th>
                                <th>Custom Message</th>
                                <th>Extra Signature Line</th>
                                <th>Font</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{CurrencyFormatter.format(productPricing.basePrice)}</td>
                                <td>{CurrencyFormatter.format(productPricing.ezShield)}</td>
                                <td>{CurrencyFormatter.format(productPricing.woodCut)}</td>
                                <td>{CurrencyFormatter.format(productPricing.customMessage)}</td>
                                <td>{CurrencyFormatter.format(productPricing.extraSigLine)}</td>
                                <td>{CurrencyFormatter.format(productPricing.font)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='product-summary-pricing-details-container'>
                    <h5><b>Product: </b></h5>
                    <h5>{getProductTotal()}</h5>
                </div>
                <div className="product-summary-grid">
                    <table className='table table-bordered mb-0'>
                        <thead>
                            <tr>
                                <th>Tax</th>
                                <th>Shipping</th>
                                <th>Handling</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{getTaxTotal()}</td>
                                <td>{getShippingTotal()}</td>
                                <td>{getHandlingTotal()}</td>
                            </tr>
                        </tbody>
                    </table>
                </div >

                <div className='product-summary-pricing-details-container'>
                    <h5><b>Total: </b></h5>
                    <h5>{getTotal()}</h5>
                </div>
            </>
        )
    };

    const getPriceBreakdown = () => {
        const spvt = getSiteProductVariantTotal();
        if (!spvt)
            return "";
        return ProductDetails(spvt);
    }

    return (
        <Card>
            <CardHeader className='d-flex justify-content-between card-header'>
                <h5 className='mb-0'>Order Product Summary</h5>
                {getOrderProductChooser()}
            </CardHeader>
            <CardBody>
                {getPriceBreakdown()}
            </CardBody>

        </Card>
    );
}
