import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useGetOrderTrackingInformationQuery} from '../../../../../app/apiSlice';
import {TrackingDetails} from '../trackingDetails/TrackingDetails';
import {Container} from "reactstrap";
import React from "react";
import {LoadingSpinner} from "../../../../input/utils/LoadingSpinner";
import "./OrderTracking.css";

export const OrderTracking = () => {
    const { t } = useTranslation();
    let orderId = Number(useParams().orderId ?? '');
    let zip = useParams().zip ?? '';
    const {data: orderTrackingList, isLoading } = useGetOrderTrackingInformationQuery({ orderId: orderId, zip: zip })

    if (isLoading) {
        return (
            <Container>
                <div className="tracking-information-loading-row">
                    Fetching tracking information
                </div>
                <div className="tracking-information-loading-row">
                    <LoadingSpinner />
                </div>
            </Container>
        )
    }

    return (
        <TrackingDetails orderTrackingList={orderTrackingList ?? []} noOrdersMessage={t(`tracking.trackingTable.errors.notAvailable`)}/>
    );
}