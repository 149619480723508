
export interface ConfigurationPaging {
    id: number,
    pagingKey: string,
    name: string,
    description?: string,
    sortOrder: number,
    actionLabel?: string
}

export const ReviewPage: ConfigurationPaging = {
    id: -1,
    pagingKey: "FinalReview",
    name: "Review",
    sortOrder: 100,
    actionLabel: "Review"
}