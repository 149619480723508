import './VariantOverview.css';
import {SiteProductVariant} from "../../../product/SiteProductVariant";
import {SiteProductVariantQuantityOption} from "../../../product/SiteProductVariantQuantityOption";
import {CurrencyFormatter} from "../../../../utils/CurrencyFormatter";
import {Link, useLocation} from "react-router-dom";
import {useGetComponentsForVariantQuery} from "../../../../app/apiSlice";
import {SiteProduct} from "../../../product/Product";
import {UncontrolledTooltip} from "reactstrap";
import {useState} from "react";
import {useAppSelector} from "../../../../app/hooks";
import {selectAllInputValues} from "../../ProductInputSlice";
import {Configuration} from "../Configuration";
import {calculatePrice} from "../../../../utils/Utils";

interface VariantOverviewProps {
    product: SiteProduct,
    siteProductVariant: SiteProductVariant,
    quantityOption: SiteProductVariantQuantityOption,
    currentPageIndex: number,
    onVariantChangeClick?: () => void,
    configurations: Configuration[]
}

export const VariantOverview = ({ product, siteProductVariant, quantityOption, currentPageIndex, onVariantChangeClick, configurations }: VariantOverviewProps) => {
    const { data: components } = useGetComponentsForVariantQuery(siteProductVariant.id);
    const [ tooltipMsg, setTooltipMsg ] = useState('Click to copy');
    const isAdmin = useLocation().pathname.includes('/admin/');
    const productInputs = useAppSelector(selectAllInputValues);

    const getComponentOptionValues = () =>
        components?.filter(c => c.componentOptions).map(component =>
            component?.componentOptions?.map(componentOption => (
                <span key={componentOption.value} className="variant-component-option-text">{componentOption.description}</span>
            ))
        );

    const getProductHeader = () => {
        const parentCategoryIndex = product.categories?.findIndex(c => c.parentCategoryId === null) ?? -1;
        return product.categories ? product.categories[parentCategoryIndex].name : '';
    }
    
    const getChangeButton = () => {
        // if we're in admin, but don't have a variant change handler, we should not display anything
        if (onVariantChangeClick) {
            return (
              <span className='change-variant-link' onClick={onVariantChangeClick}>
                Change
              </span>
            )
        } else if (!isAdmin) {
            // display router link if we're not on admin
            return (
              <Link className="change-variant-link" to={'/product/' + siteProductVariant.urlName}>
                  Change
              </Link>
            );
        }
    }
    
    const copySku = async (sku: string) => {
        if (!navigator.clipboard) return; // no access to clipboard
        await navigator.clipboard.writeText(sku);
        setTooltipMsg('Copied!');
    }
    
    return (
        <div className="variant-overview-container">
            <span className="variant-overview-category-name">{getProductHeader()}</span>
            <span className="variant-overview-name">{siteProductVariant.name}</span>
            <span id="Sku" className="sku-container text-muted" onClick={() => copySku(siteProductVariant.sku)}>SKU: {siteProductVariant.sku}</span>
            <UncontrolledTooltip target="Sku" placement='left'>{tooltipMsg}</UncontrolledTooltip>

            <span className="component-info">
                <div>
                    <span>
                        {getComponentOptionValues()}
    
                        {components && components.length > 0 ? (<span className="separator-dash">-</span>) : <></>}
                    </span>
    
                    <span>
                         {quantityOption.description}
                    </span>
                </div>

                { getChangeButton() }

                <span className="variant-overview-price-text">
                    {CurrencyFormatter.format(calculatePrice(configurations ?? [], productInputs ?? [], quantityOption))}
                </span>
            </span>
        </div>
    )
}
