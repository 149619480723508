import {DateTime} from "luxon";
import {ColumnConfig} from "../../admin/shared/Grid";
import {TransactionReport} from "./TransactionReport"
import {reportCol, reportMoneyToString, reportValToOrderLink, ReportView} from "../ReportView";
import {useGetTransactionReportQuery} from "../../../app/apiSlice";

export const TransactionReportReport = () => {
    const filterFunc = (lowerSearch: string, records: TransactionReport[]) =>
        records.filter(r =>
            r.brand.toLowerCase().includes(lowerSearch) ||
            r.transactionType.toLowerCase().includes(lowerSearch) ||
            r.transactionId.toLowerCase().includes(lowerSearch) ||
            r.referenceTransactionId.toLowerCase().includes(lowerSearch) ||
            r.refundCategory.toLowerCase().includes(lowerSearch) ||
            r.paymentType.toLowerCase().includes(lowerSearch) ||
            r.orderNumber.toString().includes(lowerSearch) ||
            r.shippingAddress.toLowerCase().includes(lowerSearch) ||
            r.shippingCity.toLowerCase().includes(lowerSearch) ||
            r.shippingState.toLowerCase().includes(lowerSearch) ||
            r.shippingZip.includes(lowerSearch)
        );

    const columns: ColumnConfig<TransactionReport>[] = [
        // localTimeCreated is split into 2 columns
        reportCol('localTimeCreated', 'Date', (val?: string | number) =>
            val ? DateTime.fromISO(val.toString()).toLocaleString(DateTime.DATE_SHORT) : ''),
        reportCol('localTimeCreated', 'Time Stamp', (val?: string | number) =>
            val ? DateTime.fromISO(val.toString()).toLocaleString(DateTime.TIME_24_SIMPLE) : ''),
        reportCol('authorizeNetTransactionSettledTime', 'Authorize.Net Settlement Time', (val?: string | number) =>
            val ? DateTime.fromISO(val.toString()).toLocaleString(DateTime.DATETIME_MED) : 'N/A'),

        reportCol('brand', 'Brand'),
        reportCol('transactionType', 'Transaction Type'),
        reportCol('transactionId', 'Transaction ID'),
        reportCol('referenceTransactionId', 'Ref. Transaction ID'),
        reportCol('refundCategory', 'Refund Cat'),
        reportCol('paymentType', 'Payment Type'),
        reportCol('orderNumber', 'Order #', reportValToOrderLink),
        reportCol('shippingAddress', 'Ship Addr'),
        reportCol('shippingCity', 'Ship City'),
        reportCol('shippingState', 'Ship State'),
        reportCol('shippingZip', 'Ship Zip'),
        reportCol('transactionNetTotal', 'Transaction Net Total', reportMoneyToString),
        reportCol('productTotal', 'Product Total', reportMoneyToString),
        reportCol('shippingTotal', 'Ship Total', reportMoneyToString),
        reportCol('handlingTotal', 'Handling Total', reportMoneyToString),
        reportCol('discountTotal', 'Discount Total', reportMoneyToString),
        reportCol('refundTotal', 'Refund Total', reportMoneyToString),
        reportCol('taxTotal', 'Tax Total', reportMoneyToString),
        reportCol('retailDeliveryFee', 'Retail Delivery Fee', reportMoneyToString)
    ];

    return <ReportView
        title='Transaction Report'
        query={useGetTransactionReportQuery}
        columns={columns}
        initialSort={{ column: 'localTimeCreated', direction: 'asc' }}
        filterFunc={filterFunc}
        downloadPath='/api/Reports/TransactionReport'/>
}