import { SourceCodeSales } from "./SourceCodeSales";
import { ColumnConfig } from "../../admin/shared/Grid";
import { reportCol, reportDateToString, reportMoneyToString, reportValToOrderLink, ReportView } from "../ReportView";
import { useGetSourceCodeSalesReportQuery } from "../../../app/apiSlice";

export const SourceCodeSalesReport = () => {
  const filterFunc = (lowerSearch: string, report: SourceCodeSales[]) =>
    report.filter(r =>
      r.orderNumber.toString().includes(lowerSearch) ||
      r.brand.toLowerCase().includes(lowerSearch) ||
      r.sourceCode.toLowerCase().includes(lowerSearch)
    );
  
  const columns: ColumnConfig<SourceCodeSales>[] = [
    reportCol('orderDate', 'Order Date', reportDateToString),
    reportCol('brand', 'Brand'),
    reportCol('orderNumber', 'Order #', reportValToOrderLink),
    reportCol('sourceCode', 'Source Code'),
    reportCol('orderNetTotal', 'Order Net', reportMoneyToString),
    reportCol('netProductSales', 'Net Product', reportMoneyToString),
    reportCol('netShippingSales', 'Net Shipping', reportMoneyToString),
    reportCol('netHandlingSales', 'Net Handling', reportMoneyToString),
    reportCol('netTax', 'Net Tax', reportMoneyToString),
    reportCol('netDiscount', 'Net Discount', reportMoneyToString),
    reportCol('netAdjustments', 'Net Adj.', reportMoneyToString),
    reportCol('netRefunds', 'Net Refunds', reportMoneyToString),
  ];
  
  return <ReportView
    title='Source Code Sales'
    query={useGetSourceCodeSalesReportQuery}
    columns={columns}
    initialSort={{column: 'orderDate', direction: 'asc'}}
    filterFunc={filterFunc}
    downloadPath='/api/Reports/SourceCodeSales'
    downloadName='CW_Source_Code_Sales'/>
}