
import {Button, Label, Spinner} from "reactstrap";
import './SavedPaymentBrowse.css';
import {
    useDeleteSavedPaymentDetailsMutation,
    useGetSavedPaymentDetailsQuery
} from "../../../app/apiSlice";
import {Consumer} from "../../admin/customer/Consumer";
import {useEffect, useState} from "react";
import {SavedPaymentDetails} from "../SavedPaymentDetails";
import {EditPayment} from "../edit/EditPayment";
import {showConfirmationModal, showInformationModal} from "../../modal/ModalSlice";
import {useAppDispatch} from "../../../app/hooks";

interface SavedPaymentBrowseProps {
    consumer: Consumer
    startEditPayment?: () => void;
    cancelEditPayment?: () => void;
    isParentInEditMode: boolean
}

export const SavedPaymentBrowse = ({consumer, startEditPayment, cancelEditPayment, isParentInEditMode}: SavedPaymentBrowseProps) => {
    const { data: savedPaymentDetails } = useGetSavedPaymentDetailsQuery();
    const [ deleteSavedPaymentDetails, {isLoading: isDeletingSavedPayment}  ] = useDeleteSavedPaymentDetailsMutation();
    const [ isInEditMode, setIsInEditMode ] = useState<boolean>(false);
    const [ savedPaymentDetailsEdit, setSavedPaymentDetailsEdit ] = useState<SavedPaymentDetails | undefined>();
    const dispatch = useAppDispatch();
    const [ focusedSavedPaymentId, setFocusedSavedPaymentId ] = useState<string | undefined>();


    useEffect(() => {
        if(!isParentInEditMode) {
            setIsInEditMode(false);
        }
    }, [isParentInEditMode]);

    const cancelEditSavedPayment = () => {
        cancelEditPayment?.();
        setIsInEditMode(false);
    }

    const onRemoveSavedPaymentMethodClicked = async (customerPaymentProfileId: string) => {
        try {
            setFocusedSavedPaymentId(customerPaymentProfileId);
            dispatch(showConfirmationModal({
                title: "Delete Saved Payment Method",
                affirmText: 'Delete',
                cancelText: 'Never mind',
                content: 'Are you sure you want to delete this saved payment method?',
                onConfirm: async () => {
                    await deleteSavedPaymentDetails(customerPaymentProfileId).unwrap();
                    cancelEditPayment?.();
                },
            }));
        } catch (e) {
            dispatch(showInformationModal({
                title: "Failed to Delete!",
                text: "Failed to delete payment method.",
            }));
        }
        setFocusedSavedPaymentId(undefined);
    }

    const onUpdateSavedPaymentMethodClicked = async (savedPaymentDetail: SavedPaymentDetails) => {
        setIsInEditMode(true);
        setSavedPaymentDetailsEdit(savedPaymentDetail);
        startEditPayment?.();
    }

    return (
        <span className="saved-payment-selection-option-container">
            {!isInEditMode &&
                savedPaymentDetails?.map((spd) => {
                    return (
                        <span className="saved-payment-selection-option" key={spd.paymentProfileId}>
                            <Label className="saved-payment-selection-option-label">{spd.cardNumber + ' - ' + spd.expirationDateString}</Label>
                            <Button color="primary" className="m-1" onClick={() => onUpdateSavedPaymentMethodClicked(spd)}>Update</Button>
                            <Button color="danger"
                                    className="m-1"
                                    onClick={() => onRemoveSavedPaymentMethodClicked(spd.paymentProfileId)}
                                    disabled={isDeletingSavedPayment}>
                                {isDeletingSavedPayment && focusedSavedPaymentId === spd.paymentProfileId && <Spinner/>}
                                Remove
                            </Button>
                        </span>
                    );
                })
            }
            {!isInEditMode && (!savedPaymentDetails || savedPaymentDetails.length === 0) ? 'No saved payment methods' : ''}

            {isInEditMode &&
                <div className="saved-payment-browse-edit-container">
                    <EditPayment consumer={consumer}
                                 savedPaymentDetails={savedPaymentDetailsEdit}
                                 cancelEditPayment={cancelEditSavedPayment}
                    ></EditPayment>
                </div>
            }

        </span>
    );

}