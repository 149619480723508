import './TrackingDetails.css'
import {CardTitle, Container, Table} from 'reactstrap';
import { TrackingDetailsItem } from './trackingDetailsItem/TrackingDetailsItem';
import { useTranslation } from 'react-i18next';
import { OrderTracking } from '../../../Order';

interface TrackingDetailsProps {
    orderTrackingList: OrderTracking[];
    noOrdersMessage: string;
    tableOnly?: boolean
}

export const TrackingDetails = ({ orderTrackingList, noOrdersMessage, tableOnly }: TrackingDetailsProps) => {
    const { t } = useTranslation();

    const getOrderItems = () => {
        if (orderTrackingList && orderTrackingList?.length > 0) {
            return (
                <Table striped={!tableOnly} bordered={!tableOnly} hover id='tracking-details-table'>
                    <thead>
                        <tr>
                            <th>Product Name</th>
                            <th>Status</th>
                            <th>Order Date</th>
                            <th>Unit Quantity</th>
                            <th>Shipping Option</th>
                            <th>Tracking Number</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        orderTrackingList?.map((ot, index) => (
                            <TrackingDetailsItem key={index}
                                orderTracking={ot}
                            />
                        ))
                    }
                    </tbody>
                </Table>
            );
        } else {
            return <div className='tracking-details-no-orders'>{noOrdersMessage}</div>
        }
    }

    if (tableOnly)
        return getOrderItems();
    
    return (
        <div className='tracking-details-container d-flex flex-column'>
            <Container>
                <CardTitle tag='h5'>
                    {t('tracking.trackingTable.title')}
                </CardTitle>
                {getOrderItems()}
            </Container>
        </div>
    );
}