import {
  useGetAvailableOrderStatusesQuery,
  useGetOrderStatusReasonsQuery,
  useUpdateOrderMutation
} from "../../../app/apiSlice";
import {
  Alert,
  Button, FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Spinner,
} from "reactstrap";
import React, { ChangeEvent, useEffect, useState } from "react";
import { getErrorMessage, Utils } from "../../../utils/Utils";
import { UpdateOrderVm } from "../../order/UpdateOrderVm";
import {useValidation} from "../../../app/hooks";
import { skipToken } from "@reduxjs/toolkit/query";

interface ChangeOrderStatusProps {
  orderId: number
}

export const ChangeOrderStatus = ({ orderId }: ChangeOrderStatusProps) => {
  const [ state, setState ] = useState<UpdateOrderVm>({
    statusIdChange: undefined,
    statusChangeReasonId: undefined, 
    notes: ''
  });
  const [ errorMessage, setErrorMessage ] = useState('');
  const { data: statuses } = useGetAvailableOrderStatusesQuery(orderId);
  const { data: reasons } = useGetOrderStatusReasonsQuery(state.statusIdChange ?? skipToken);
  const [ updateOrder, {isLoading: isUpdating} ] = useUpdateOrderMutation();
  
  // pick good defaults when our lists load
  useEffect(() => {
    setState(old =>({
      ...old,
      statusIdChange: old.statusIdChange ?? (statuses?.length ? statuses[0].id : undefined),
    }))
  }, [statuses]);
  useEffect(() => {
    setState(old =>({
      ...old,
      statusChangeReasonId: old.statusChangeReasonId ?? (reasons?.length ? reasons[0].id : undefined),
    }))
  }, [reasons]);
  
  const handle = Utils.handleChanges(setState);
  const [ isOpen, setIsOpen ] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const required = (name: string) => (val?: string) => !val ? `${name} are required.` : undefined;
  const validation = useValidation({
    notes: required('Notes')
  });
  
  const updateNotes = (e: ChangeEvent<HTMLInputElement>) => {
    validation.setValue('notes', e.target.value);
    setState(old => ({
      ...old,
      notes: e.target.value
    }));
  }
  
  const updateOrderStatus = async () => {
    setErrorMessage('');
    if (!validation.allValid()) return;
    try {
      await updateOrder([orderId, { statusIdChange: state.statusIdChange, statusChangeReasonId: state.statusChangeReasonId , notes: state.notes}]).unwrap();
      toggle();
    } catch (e: any) {
      setErrorMessage(getErrorMessage(e) ?? 'Unknown Error while setting order status. Try again.');
    }
  }
  
  return (<>
    <Button color='link' className='px-0' onClick={() => setIsOpen(true)}>Update</Button>
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Change Order Status</ModalHeader>
      <ModalBody>
        {/*New Status*/}
        <FormGroup>
          <Label for='StatusId'>New Status</Label>
          <Input id="StatusId" name='statusIdChange' type='select' value={state.statusIdChange ?? ''} onChange={handle}>
            {/* You should ONLY filter statuses server-side */}
            {statuses?.map(s => (
              <option key={s.id} value={s.id}>{s.name}</option>
            ))}
          </Input>
        </FormGroup>
        
        {/*Reason*/}
        <FormGroup>
          <Label for='ReasonId'>Reason</Label>
          <Input id='ReasonId' name='statusChangeReasonId' type='select' value={state.statusChangeReasonId ?? ''} onChange={handle}>
            {reasons?.map(r => (
              <option key={r.id} value={r.id}>{r.name}</option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for='Notes'>Notes</Label>
          <Input required id='Notes' name='notes' type='textarea' value={state.notes ?? ''} invalid={validation?.getError('notes') !== undefined} onChange={(e) => updateNotes(e)} />
          <FormFeedback>{ validation?.getError('notes') }</FormFeedback>
        </FormGroup>

        { errorMessage && <Alert>{errorMessage}</Alert> }
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setIsOpen(false)}>Cancel</Button>
        <Button id="SubmitButton" onClick={updateOrderStatus} disabled={isUpdating} color='primary'>
          {isUpdating ? <Spinner></Spinner> : <></>}
          Update
        </Button>
      </ModalFooter>
    </Modal>
  </>);
}