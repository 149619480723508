import {Button, Input, InputGroup, Table} from "reactstrap";
import './ProductSearch.css';
import {useState} from "react";
import {useGetSiteProductsQuery} from "../../../app/apiSlice";
import {DisplayImage} from "../../image/display/DisplayImage";
import {useDebounce} from "../../../app/hooks";

interface ProductTableProps {
  siteId: number,
  onProductSelected: (productUrlName: string) => void,
}
export const ProductSearch = (props: ProductTableProps) => {
  const [ input, setInput ] = useState('');
  const searchDebounced = useDebounce(input, 600);
  const { data: products } = useGetSiteProductsQuery({ siteId: props.siteId, search: searchDebounced });
  
  return (<>
    <InputGroup>
      <Input placeholder='Search by product id, name, category, or subcategory...' value={input}
             onChange={e => setInput(e.target.value)}/>
    </InputGroup>
    { products?.length ?
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>Image</th>
          <th>Id</th>
          <th className="product-search-product-name-container">Name</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        { products?.map(p => (
          <tr key={p.id}>
            <td className="product-search-product-image-container">
              <DisplayImage imageId={p.thumbnailId ?? p.imageId}></DisplayImage>
            </td>
            <td>{p.id}</td>
            <td className="product-search-product-name-container">{p.name}</td>
            <td>
              <Button color='primary' onClick={() => props.onProductSelected(p.urlName!)}>Select</Button>
            </td>
          </tr>
        ))}
        </tbody>
      </Table> :
      <div className='my-2 text-center'>Sorry, no products found. Change your search and try again.</div>
    }
  </>);
}