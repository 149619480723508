import {useEffect, useState} from "react";
import {ColumnConfig, GridConfig} from "../admin/shared/Grid"
import {GridPage} from "../admin/shared/GridPage"
import {TaxItem} from "./TaxItem"
import {SortState} from "../../utils/Grid";
import {reportCol, reportDateToString, reportMoneyToString} from "../reports/ReportView";
import {DateTime} from "luxon";
import {useGetHistoricalExportsInRangeQuery} from "../../app/apiSlice";
import {downloadWithAuth, Utils} from "../../utils/Utils";

interface HistoricalTaxLineItemsCriteria {
  fromDate: string;
  toDate: string;
}

export const ViewHistoricalExportsPage = () => {
  const [criteria, setCriteria] = useState<HistoricalTaxLineItemsCriteria>({ fromDate: DateTime.now().set({day: -1}).toISODate(),
    toDate: DateTime.now().toISODate()});
  const [defaultCriteria, setDefaultCriteria] = useState(criteria);
  const { data: queryTaxItems, isFetching, refetch: refetchTaxItems } = useGetHistoricalExportsInRangeQuery({
    fromDate: criteria?.fromDate,
    toDate: criteria?.toDate
  });
  const [sort, setSort] = useState<SortState<TaxItem>>({ column: 'taxPeriodId', direction: 'desc'});
  const [ sortedTaxItems, setSortedTaxItems ] = useState(queryTaxItems);
  const downloadUrl = `api/TaxProcessing/HistoricalExportsInRange/export?format=csv&fromDate=${criteria.fromDate}&toDate=${criteria.toDate}`;
  const onDownload = async () => await downloadWithAuth(downloadUrl, `Historical Export.csv`);
  const onCriteriaChange = (newCriteria: HistoricalTaxLineItemsCriteria) => setCriteria(newCriteria);

  const onResetCriteria = () => {
    setCriteria(defaultCriteria);
    refetchTaxItems();
  };
  
  useEffect(() => {
    setSortedTaxItems([...queryTaxItems ?? []]?.sort(Utils.sortBy(sort.column, sort.direction)) ?? []);
  }, [sort, queryTaxItems]);
  
  const config: GridConfig<TaxItem> = {
    configSections: [
      {
        columns: [
          reportCol('orderId', 'Document Number'),
          reportCol('taxPeriodId', 'Tax Export Id'),
          reportCol('taxPeriodDate', 'Posting Date', reportDateToString),
          reportCol('taxPeriodCreatedDate', 'Created Date', reportDateToString),
          reportCol('documentDate', 'Document Date', reportDateToString),
          reportCol('shipFromAddress', 'Origin Address'),
          reportCol('shipToAddress', 'Destination Address'),
          reportCol('classCode', 'Product Class Code'),
          reportCol('taxAmount', 'Total Tax Amount', reportMoneyToString),

          // criteria field
          new ColumnConfig<TaxItem>({
            field: 'fromDate',
            display: 'From Created Date',
            isHidden: true,
            isSearchCriteria: true,
            type: 'single-date',
          }),
          new ColumnConfig<TaxItem>({
            field: 'toDate',
            display: 'To Created Date',
            isHidden: true,
            isSearchCriteria: true,
            type: 'single-date',
          })
        ]
      }
    ],
  };

  return <GridPage
    gridConfig={config}
    onCriteriaChange={onCriteriaChange}
    onShouldRefetch={() => { refetchTaxItems(); }}
    onResetCriteria={onResetCriteria}
    defaultCriteria={defaultCriteria}
    title={`Historical Exports ${ (sortedTaxItems?.length ?? 0) > 0 ? `(${sortedTaxItems?.length})` : ''}`}
    records={isFetching ? [] : (sortedTaxItems ?? [])}
    sort={sort}
    isLoading={isFetching}
    onSortChanged={setSort}
    shouldShowCriteriaPanel={true}
    onDownload={onDownload}/>
};