import { ProductPreview } from '../../product/preview/ProductPreview';
import './CardItemBrowse.css';
import { SiteCard } from '../SiteCard';
import {Link} from "react-router-dom";
import {
    useGetSiteCardItemsQuery,
    useGetSiteCategoryByUrlNameOrIdQuery,
    useGetAspectRatiosQuery
} from "../../../app/apiSlice";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AspectRatio } from"../../../components/image/AspectRatio";
import {useMemo} from "react";

interface CardItemBrowseProps {
  siteCard: SiteCard
}

export const CardItemBrowse = ({ siteCard }: CardItemBrowseProps) => {
  const {data: cardItems} = useGetSiteCardItemsQuery(siteCard.id);
  const { data: siteCategory } = useGetSiteCategoryByUrlNameOrIdQuery(siteCard.siteCategoryId);
  const { data: aspectRatios } = useGetAspectRatiosQuery();
  const aspectRatio = useMemo(()=>aspectRatios?.find((e: AspectRatio)=>e.siteCategoryId === siteCard.siteCategoryId)?.aspectRatio, [aspectRatios, siteCard.siteCategoryId])

  if (!cardItems || cardItems.length < 1) {
    return (<></>);
  }

  return (
    <div className="card-item-browse-container">

      <div className="card-item-header">
        <h2 className="card-item-title">{siteCard.title}</h2>

        <Link className="btn-primary ms-3" to={`/category/${siteCategory ? siteCategory.urlName : siteCard.siteCategoryId}`}>
          <h6>View All</h6>
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
      </div>

      <hr className='card-item-browse-hr' />

      <div className={`card-item-previews aspect-ratio-${aspectRatio}`}>
        { cardItems
            ?.filter(cardItem => cardItem.product)
            ?.map(cardItem => (
                <ProductPreview key={cardItem.id} product={cardItem.product!} siteCategory={siteCategory} tagText={siteCard.productTagName} />
        )) }
      </div>

    </div>
  );
}