
export class Address {
  id?: number
  street?: string
  city?: string
  stateCode?: string
  zip?: string
  name?: string
  nameTwo?: string
  isVerified?: boolean

  /**
   * If both addresses have ids, returns whether or not those ids are equal.
   * Or if one address is defined and the other isnt, returns false.
   * Otherwise, returns true if all other fields are equal between the two Addresses.
   */
  static equals(add1?: Address, add2?: Address) {
    if (!add1 && !add2) return true;
    if ((add1 && !add2) || (!add1 && add2)) return false;

    return add1?.street === add2?.street &&
      add1?.city === add2?.city &&
      add1?.stateCode === add2?.stateCode &&
      add1?.zip === add2?.zip &&
      (add1?.id === add2?.id) &&
      add1?.isVerified === add2?.isVerified;
  }
}


export interface AddressVm extends Address {
  isDefaultBillingAddress: boolean
  isDefaultShippingAddress: boolean
}

export interface AddressInputVm extends Address {
  street2?: string,
  phoneNumber?: string,
  isValid?: boolean,
}

export enum DefaultAddressType {
  Shipping,
  Billing,
}