import { OrderRefundReport } from "./OrderRefundReport";
import { ColumnConfig } from "../../admin/shared/Grid";
import { reportCol, reportDateToString, reportMoneyToString, reportValToOrderLink, ReportView } from "../ReportView";
import { useGetOrderRefundReportReportQuery } from "../../../app/apiSlice";

export const OrderRefundReportReport = () => {
  const filterFunc = (lowerSearch: string, records: OrderRefundReport[]) =>
    records.filter(r =>
      r.orderNumber.toString().includes(lowerSearch) ||
      r.brand.toLowerCase().includes(lowerSearch) ||
      r.paymentMethod.toLowerCase().includes(lowerSearch) ||
      r.creditCardNumber?.includes(lowerSearch) ||
      r.transactionId.toLowerCase().includes(lowerSearch)
    );
  
  const columns: ColumnConfig<OrderRefundReport>[] = [
    reportCol('orderNumber', 'Order #', reportValToOrderLink),
    reportCol('brand', 'Brand'),
    reportCol('orderDate', 'Order Date', reportDateToString),
    reportCol('refundDateSubmitted', 'Refund Submitted', reportDateToString),
    reportCol('refundDateApproved', 'Refund Approved', reportDateToString),
    reportCol('refundDateIssued', 'Refund Issued', reportDateToString),
    reportCol('paymentMethod', 'Payment Meth.'),
    reportCol('creditCardNumber', 'CC #.'),
    reportCol('orderNetTotal', 'Order Net', reportMoneyToString),
    reportCol('refundAmount', 'Refund Amount', reportMoneyToString),
    reportCol('transactionId', 'Transaction ID'),
  ];
  
  return <ReportView
    title='Order Refund'
    query={useGetOrderRefundReportReportQuery}
    columns={columns}
    initialSort={{column: 'orderDate', direction: 'asc'}}
    filterFunc={filterFunc}
    downloadPath='/api/Reports/OrderRefundReport'
    downloadName='CW_Order_Refund Report'/>
}