import React, {useEffect, useState} from "react";
import {Collapse, FormGroup, Label} from "reactstrap";
import {ConfigSection} from "./Grid";
import {get as _get} from 'lodash';
import './GridCriteriaPanel.css';
import {CriteriaInput} from "./CriteriaInput";
import {ActionRow} from "./ActionRow";

interface CriteriaPanelProps<T> {
    configSections: ConfigSection<T>[]
    defaultCriteria?: any,
    onSearch: (criteria: T) => void
    onShouldRefetch?: () => void
    onResetCriteria?: () => void
    isOpen: boolean
    autoSearch?: boolean
}

export function GridCriteriaPanel<T>({ configSections, defaultCriteria, onSearch, onShouldRefetch, onResetCriteria, isOpen, autoSearch = true }: CriteriaPanelProps<T>) {
    const [criteria, setCriteria] = useState<T>(defaultCriteria ?? {} as T);
    
    const onSearchClick = () => {
        onSearch(criteria);
        onShouldRefetch?.();
    };

    useEffect(() => {
        setCriteria(defaultCriteria ?? {});
    }, [defaultCriteria]);

    useEffect(() => {
        if (autoSearch) onSearch(criteria);
    }, [criteria, onSearch, autoSearch]);
    
    const onResetClick = () => {
        setCriteria(defaultCriteria ?? {});
        onResetCriteria?.();
    };

    return (
        <Collapse horizontal isOpen={isOpen}>
            <div id='CriteriaContainer'>
                <ActionRow className='mb-3' onSearch={onSearchClick} onReset={onResetClick} />
                <span>Search Criteria</span>
                {configSections.map((cs, index) => (
                    <React.Fragment key={cs.name ?? index}>
                        <h6 className={index !== 0 ? 'mt-3' : ''}>{cs.name}</h6>
                        {cs.columns.filter(c => c.isSearchCriteria).map(col => {
                            const validationMesg = col.validation?.(_get(criteria, col.field));
                            return (
                                <FormGroup key={col.field.toString()} className='criteria-panel-control-container'>
                                    <Label for={col.field.toString()}>{col.display}</Label>
                                    <CriteriaInput config={col} criteria={criteria} onChange={val => setCriteria(old => ({ ...old, [col.field]: val }))} />
                                    {validationMesg && <p className='text-danger'>{validationMesg}</p>}
                                </FormGroup>
                            )
                        })}
                    </React.Fragment>
                ))}
                <ActionRow className='mt-3' onSearch={onSearchClick} onReset={onResetClick} />
            </div>
        </Collapse>
    )
}