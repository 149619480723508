import { Button, FormGroup, Input, Label } from "reactstrap";
import { ChangeEvent, useEffect, useState } from "react";

export interface SelectOption {
  value: number,
  display?: string,
}

interface MultiSelectProps {
  options: SelectOption[]
  values?: number[],
  onChange: (values: number[]) => void,
}

export function MultiSelect({options, values, onChange}: MultiSelectProps) {
  const [hasSelection, setHasSelection] = useState((values?.length ?? 0) >= 1);
  useEffect(() => {
    setHasSelection((values?.length ?? 0) >= 1);
  }, [values])
  const handleCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = Number.parseInt(e.target.value);
    if (e.target.checked)
      onChange(values?.concat(val) ?? [val]);
    else
      onChange(values?.filter(v => v !== val) ?? []);
  }
  
  const handleSelectAllClick = () => {
    if (hasSelection) {
      onChange([]);
    } else {
      onChange(options.map(o => o.value));
    }
  }
  
  const Option = ({ value, display }: SelectOption) => {
    const elId = crypto.randomUUID();
    return (
      <FormGroup key={value} check>
        <Input id={elId} type='checkbox' value={value} checked={values?.includes(value) ?? false} onChange={handleCheckChange}/>
        <Label check for={elId}>{display ?? value}</Label>
      </FormGroup>
    )
  }

  return <div className='rounded border p-2 overflow-auto' style={{maxHeight: '6em'}}>
    <Button color='link' className='m-0 p-0' onClick={handleSelectAllClick}>{hasSelection ? 'Uns' : 'S'}elect All</Button>
    {options.map(Option)}
  </div>
}