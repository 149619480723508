import './UserAddresses.css';
import {Button, Card, CardBody} from "reactstrap";
import {useGetAddressesByConsumerIdQuery} from "../../../../app/apiSlice";
import {Consumer} from "../../../admin/customer/Consumer";
import React, {useState} from "react";
import {AddEditUserAddress} from "./addUserAddress/AddEditUserAddress";

type ViewMode = 'Add' | 'Edit' | 'List'

interface UserAddressesProps {
    consumer: Consumer
}

export const UserAddresses = ({ consumer } : UserAddressesProps) => {
    const { data: addresses } = useGetAddressesByConsumerIdQuery(consumer.id);
    const [ viewMode, setViewMode ] = useState<ViewMode>('List');
    const [ addressId, setAddressId ] = useState<number>();

    const onEditAddressClick = (id: number | undefined) => {
        setAddressId(id);
        setViewMode('Edit');
    }

    return (
        <div className="user-addresses-container">
            <div className="d-flex justify-content-between align-items-center">
                <h3>
                    { viewMode === 'List' ? 'Saved Addresses' : `${viewMode} Address`}
                </h3>
                { viewMode === 'List' && <Button color='primary' onClick={() => setViewMode('Add')}>Add Address</Button> }
            </div>
            { viewMode === 'List' && addresses && addresses.map((address) => {
                return <Card className="user-address-card" key={address.id}>
                    <CardBody className="d-flex justify-content-between align-items-center">
                        <div className="address d-flex flex-column">
                            <span className="street">{address.street}</span>
                            <span className="city-state-zip">{address.city}, {address.stateCode} {address.zip}</span>
                            <span className="isDefaultAddress">{address.isDefaultBillingAddress && "Default Billing Address"}</span>
                            <span className="isDefaultAddress">{address.isDefaultShippingAddress && "Default Shipping Address"}</span>
                        </div>
                        <button className="edit-btn" onClick={() => onEditAddressClick(address.id)} >Edit</button>
                    </CardBody>
                </Card>
            })}
            {(viewMode === 'Add' || viewMode === 'Edit') &&
                <AddEditUserAddress isEditMode={viewMode === 'Edit'} returnToListView={() => setViewMode('List')} addressId={addressId} />
            }
        </div>
    );
}