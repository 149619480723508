import "./ProductSearchItem.css";
import {SiteProduct} from "../../components/product/Product";
import {Card, CardBody, CardTitle, Spinner} from 'reactstrap';
import {Link} from 'react-router-dom';
import {DisplayImage} from "../../components/image/display/DisplayImage";

interface ProductSearchPreviewProps {
	product: SiteProduct
}

export const ProductSearchItem = ({product}: ProductSearchPreviewProps) => {

	function getPromotionCardImage() {
		if (!product.thumbnailId && !product.imageId) {
			return <Spinner size='sm'/>
		}

		return (
			<span className="product-search-item-image">
				<DisplayImage imageId={product.thumbnailId ?? product.imageId} shouldLimit={false}/>
			</span>
		);
	}

	function getProductUrl() {
		return `/product/${product.urlName}`
	}

	return (
		<div className="product-search-item-container">
			<Link to={getProductUrl()}>
				<Card className="product-search-item-card">
					{getPromotionCardImage()}
					<CardBody>
						<CardTitle tag="span">
							<div>
								<h6 className='product-search-item-name'>{product.name}</h6>
							</div>
						</CardTitle>
					</CardBody>
				</Card>
			</Link>
		</div>
	);
};
