
import './BankInformation.css';
import {useTranslation} from "react-i18next";
import {DisplayImage} from "../../image/display/DisplayImage";


export const BankInformation = () => {
    const { t } = useTranslation();

    return (
        <span className="bank-information-container">
            <DisplayImage imageName="BankLineDescription"></DisplayImage>

            <span className="bank-information-bottom-banner-text">
                <p>{t('bank.information.bottomBannerText')}</p>
            </span>
        </span>
    )
}
