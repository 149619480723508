import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ModalType } from "./ModalType";

export interface ModalState {
    isOpen: boolean;
    content: React.ReactElement | string,
    onConfirm?: () => void | Promise<void>,
    title: string,
    affirmText: string,
    cancelText: string,
    modalType: ModalType,
    size: string,
    showTextContent: boolean,
}

const initialState: ModalState = {
    isOpen: false,
    content: 'Are you sure?',
    title: 'Confirm',
    affirmText: 'Ok',
    cancelText: 'Cancel',
    modalType: ModalType.Standard,
    size: 'm',
    showTextContent: true,
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        showConfirmationModal: (state, { payload }: PayloadAction<{
            content?: React.ReactElement | string,
            onConfirm: () => void | Promise<void>,
            modalType?: ModalType,
            affirmText?: string,
            cancelText?: string,
            size?: string,
            title?: string,
            showTextContent?: boolean}>) => {
            state.onConfirm = payload.onConfirm;
            state.title = payload.title ?? initialState.title;
            state.content = payload.content ?? 'Are you sure you want to delete this record?';
            state.modalType = payload.modalType ?? ModalType.Standard;
            state.affirmText = payload.affirmText ?? ((state.modalType === ModalType.Standard) ? initialState.affirmText : 'Yes, Delete');
            state.cancelText = payload.cancelText ?? initialState.cancelText;
            state.size = payload.size ?? initialState.size;
            state.isOpen = true;
            state.showTextContent = payload.showTextContent ?? initialState.showTextContent;
        },
        showInformationModal: (state, { payload }: PayloadAction<{text: React.ReactElement | string, title?: string}>) => {
            state.title = payload?.title ?? '';
            state.content = payload.text;
            state.onConfirm = undefined;
            state.isOpen = true;
        },
        toggleModal : state => {
            state.isOpen = !state.isOpen;
        },
        closeModal: state => {
            state.isOpen = false;
            state.content = initialState.content;
            state.title = initialState.title;
            state.affirmText = initialState.affirmText;
            state.cancelText = initialState.cancelText;
            state.modalType = initialState.modalType;
            state.size = initialState.size;
        }
    }
});

export const selectModalState = (state: RootState): ModalState => state.modal;

export const {
    showConfirmationModal,
    showInformationModal,
    toggleModal,
    closeModal,
} = modalSlice.actions;

export default modalSlice.reducer;