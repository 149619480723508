import downloadFile from "./download-file";
import {useAppSelector} from "../app/hooks";
import {getAccessToken} from "../components/user/login/AuthenticationSlice";

type DownloadOptions = Parameters<typeof downloadFile>[0];

type UseConsumerDownload = (opt: DownloadOptions) => Promise<boolean>;

export default function (): UseConsumerDownload {
    
    const accessToken = useAppSelector(getAccessToken);
    
    return (opt) => 
        downloadFile({
            ...opt,
            headers: {
                ...(opt.headers || {}),
                authorization: `Bearer ${accessToken}`
            }
        });
}