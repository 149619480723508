import './DisplayImage.css';
import {useState} from "react";
import {useGetImageHostQuery, useGetImagesQuery} from "../../../app/apiSlice";
import {LoadingSpinner} from "../../input/utils/LoadingSpinner";
import {skipToken} from '@reduxjs/toolkit/query';
import {useElementIsOnScreen} from '../../../app/hooks';
import {isPrerender} from '../../../utils/Utils';

interface DisplayImageProps {
    imageName?: string,
    imageId?: number,
    shouldLimit?: boolean,
    showLoading?: boolean
}

export const DisplayImage = ({imageName, imageId, shouldLimit = true, showLoading = true}: DisplayImageProps) => {
    const { data: host } = useGetImageHostQuery();
    const { data: images } = useGetImagesQuery(!host ? skipToken : undefined);
    const image = images?.find(i => i.id === imageId || i.name === imageName);
    
    // This allows the images to wait to load in until they approach the browser viewable window
    const [imgRef, imgIsVisible] = useElementIsOnScreen([images, image, imageId, imageName], { 
        root: null,
        rootMargin: '0px',
        threshold: 0.1 // Change this value to adjust when image should appear, range is 0 - 1, with 1 being once the image is 100% in view
    });
    const [imageHasLoaded, setImageHasLoaded] = useState(false);

    if ((!images || !image)) { // no image to show
        return <></>
    }
    
    return <>
        <img onLoad={() => setImageHasLoaded(true)}
                className={(!isPrerender() && !imageHasLoaded) ? 'hidden-image' : ''}
                alt={image.name}
                src={!shouldLimit || imgIsVisible || imageHasLoaded ? `${host}${image?.filePath}` : undefined}
                ref={imgRef}/>
        {(!isPrerender() && !imageHasLoaded) && showLoading &&
            <div className="display-image-loading-spinner-container">
                <LoadingSpinner size="sm" />
            </div>
        }
    </>;
}