import { FinancialInstitution } from "./FinancialInstitution";
import { SliceState } from "../../../utils/Utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAll, SearchAndPaging } from "./FinancialInstitutionAPI";
import { RootState } from "../../../app/store";

export interface FinancialInstituionState {
  institutions?: FinancialInstitution[];
  status: SliceState;
  canGetMore: boolean;
}

const initialState: FinancialInstituionState = {
  institutions: undefined,
  status: 'idle',
  canGetMore: true,
};

export const fetchFinancialInstitutions = createAsyncThunk(
  'financialInstitutions/fetchAll',
  async (query: SearchAndPaging) =>
    (await fetchAll(query)).data
);

export const fetchMoreFinancialInstitutions = createAsyncThunk(
  'financialInstitutions/fetchMore',
  async (query: SearchAndPaging) =>
    (await fetchAll(query)).data
);

export const financialInstitutionSlice = createSlice({
  name: 'financialInstitution',
  initialState,
  reducers: {
    changeSearch: state => {
      state.status = 'idle';
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchFinancialInstitutions.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchFinancialInstitutions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.institutions = action.payload;
        state.canGetMore = action.payload.length === 50;
      })
      .addCase(fetchFinancialInstitutions.rejected, state => {
        state.status = 'failed';
        state.canGetMore = false;
      })
      
      // fetch more
      .addCase(fetchMoreFinancialInstitutions.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchMoreFinancialInstitutions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload.length) state.institutions = state.institutions?.concat(action.payload);
        state.canGetMore = action.payload.length === 50;
      })
      .addCase(fetchMoreFinancialInstitutions.rejected, state => {
        state.status = 'failed';
        state.canGetMore = false;
      });
  }
});

// Fetch financial institutions
export const selectFinancialInstitutions = (state: RootState) => state.financialInstitutions.institutions;
export const canFetchMoreFinancialInstitutions = (state: RootState) => state.financialInstitutions.canGetMore;
export const isFetchingFinancialInstitutions = (state: RootState) => state.financialInstitutions.status === 'loading' ||
    state.financialInstitutions.status === 'idle';
const { actions, reducer } = financialInstitutionSlice;
export const { changeSearch } = actions;
export default reducer;