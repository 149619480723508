import './RadioSelectWithButtonSingleSelect.css';
import {Accordion, AccordionBody, AccordionHeader, AccordionItem, Alert, Button, Input, Label} from 'reactstrap';
import { ProductInputValue } from '../ProductInputValue';
import {useEffect, useState} from "react";
import {ConfigurationOption, GroupedConfigurationOptions} from "../configuration/ConfigurationOption";
import {FieldModifier} from "../configuration/FieldModifier";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {RequiredAsterisk} from "../utils/RequiredAsterisk";
import {DisplayImage} from "../../image/display/DisplayImage";
import {getConfigurationIsSecureClass, Utils} from "../../../utils/Utils";
import {
    getProductInputKey,
    getProductInputLabel, getProductInputOptionDisplayName, getProductInputOptionName,
    getProductInputOptions,
    isConfigurationOption,
    isProductInputRequired,
    ProductInput, ProductInputOption
} from "../ProductInput";
import {OnValueChangeParams} from "../view/ProductInputView";

interface RadioSelectWithButtonSingleSelectProps {
    productInput: ProductInput,
    productInputValue?: ProductInputValue,
    isInEditMode: boolean,
    getClassesForModifiers: (fieldModifiers?: FieldModifier[]) => string,
    onValueChange: (params: OnValueChangeParams) => void,
    checkInput: boolean,
}

export const RadioSelectWithButtonSingleSelect = ({ productInput, productInputValue, isInEditMode, getClassesForModifiers, onValueChange, checkInput }: RadioSelectWithButtonSingleSelectProps) => {
    const inputLabel = getProductInputLabel(productInput, isInEditMode);
    const options = getProductInputOptions(productInput) ?? [];
    const [groupedOptions, setGroupedOptions] = useState<GroupedConfigurationOptions[] | null>([]);
    const [ radioValue, setRadioValue ] = useState<number | null>(null);
    const isRequired = isProductInputRequired(productInput);
    const [open, setOpen] = useState('');
    const toggle = (id: string) => setOpen(open === id ? '' : id);

    useEffect(() => {
        if (!productInputValue?.isDirty && (!productInputValue?.selectedProductInputOptionId)) {
            const defaultOption = options.find(o => o.isDefault);
            if (defaultOption) setRadioValue(defaultOption.id);
            onValueChange({ inputValue: defaultOption?.value ?? null, optionId: defaultOption?.id });
        }
        else if (!radioValue && productInputValue.selectedProductInputOptionId) {
            const parentOption = getParentOption(productInputValue.selectedProductInputOptionId);
            if (parentOption) setRadioValue(parentOption.id);
        }
    }, [productInputValue]); 

    useEffect(() => {
        if (radioValue) {
            const selectedParentOption = getOptionById(radioValue);
            setGroupedOptions(selectedParentOption && isConfigurationOption(selectedParentOption) ? groupOptions(selectedParentOption.childConfigurationOptions) : []);
        }
    }, [radioValue]);

    useEffect(() => {
        if (groupedOptions !== null && groupedOptions.length>0) {
            setOpen(groupedOptions[0].optionGroupKey);
        }
    }, [groupedOptions]);

    function groupOptions(options?: ConfigurationOption[]) {
        const groupedOptions: GroupedConfigurationOptions[] = [];

        options?.forEach((o => {
            // Create group if it doesn't exist
            const optionGroupIndex = groupedOptions.findIndex((go => go.optionGroupKey === o.optionGroupKey));
            if (optionGroupIndex < 0) {
                groupedOptions.push({optionGroupKey: o.optionGroupKey ?? "", options: [o]});
            }
            else {
                groupedOptions[optionGroupIndex].options.push(o);
            }
        }));

        return groupedOptions.sort(Utils.sortBy('optionGroupKey', 'asc'));
    }

    function onOptionSelected(option: ConfigurationOption) {
        onValueChange({ inputValue: option.value, optionId: option.id });
    }

    function getPriceModification(option: ProductInputOption) {
        return isConfigurationOption(option) && option.priceModification ?
          `+(${CurrencyFormatter.format(option.priceModification)})` : "";
    }
    
    const shouldShowRequired = () => checkInput && isRequired && !productInputValue?.selectedProductInputOptionId;

    function getEditModeTemplate() {
        return (
            <>
            <span className={`radio-select-with-button-single-select-option-container ${shouldShowRequired() ? 'is-invalid' : ''}`}>
                { shouldShowRequired() && <p className="text-danger">Please choose an option or choose None</p> }
                {[...options].sort(Utils.sortBy('sortOrder', 'asc')).map((option) => {
                    return (
                        <span key={'radio-button-select-' + option.value + '-' + option.id} className="radio-option-input-container">
                            <Input
                                className={`radio-option-input ${getInputClasses()}`}
                                name={getProductInputKey(productInput)}
                                type="radio"
                                value={option.id}
                                onChange={() => onRadioChange(option)}
                                checked={(isConfigurationOption(option) && isParentOptionSelected(option)) ?? false}
                            />
                            <Label check
                                   className="radio-option-input-label"
                                   onClick={() => onRadioChange(option)}>
                                {getProductInputOptionDisplayName(option)} {getPriceModification(option)}
                            </Label>
                        </span>);
                })}
            </span>

            <span className="radio-button-select-button-container">
                {getSingleSelectButtons()}
            </span>
            </>
        );
    }

    function getReadModeTemplate() {
        const selectedOption = getOptionById(productInputValue?.selectedProductInputOptionId);
        return (
            <span className={`radio-select-with-button-single-select-input-read-only-value radio-select-with-button-single-select-input-option-image-container ${getConfigurationIsSecureClass(productInput)}`}>
                {selectedOption && selectedOption.imageId
                    ? <DisplayImage imageId={selectedOption.imageId}></DisplayImage>
                    : getProductInputOptionName(selectedOption)}
            </span>
        );
    }

    function getValueRenderTemplate() {
        if (isInEditMode) {
            return getEditModeTemplate();
        }
        else {
            return getReadModeTemplate();
        }
    }

    function getInputClasses() {
        let classNames = getConfigurationIsSecureClass(productInput);
        return classNames;
    }

    function getClassesForLabel(inputLabel: string) {
        if (!isInEditMode) {
            return "half-width-text-input";
        } else if (inputLabel.toLowerCase() == 'choose a custom symbol or monogram') {
            return "non-input-label"
        }
        return "";
    }

    function onRadioChange(option: ProductInputOption) {
        clearInputValue();
        setRadioValue(option.id);

        // If this is not a 'no value' option, and there are no child options, then the 'parent' option is the value
        if ((!isConfigurationOption(option)) || !option.isNoSelection && (!option.childConfigurationOptions || option.childConfigurationOptions.length < 1)) {
            onValueChange({ inputValue: option.value, optionId: option.id });
        }
    }

    function clearInputValue() {
        onValueChange({ inputValue: null, optionId: undefined });
    }

    function isButtonSelected(optionId: number) {
        return productInputValue?.selectedProductInputOptionId === optionId;
    }

    const isParentOptionSelected = (option: ConfigurationOption) =>
        (option.childConfigurationOptions?.some(cco => cco.id === productInputValue?.selectedProductInputOptionId)
            || radioValue === option.id)
            || (productInputValue?.isDirty && !radioValue && !productInputValue.selectedProductInputOptionId && option.isNoSelection);

    const getOptionButton = (option: ConfigurationOption) => {
        return (
            <Button
                active={isButtonSelected(option.id)}
                onClick={(e) => onOptionSelected(option)}
                key={"radio-button-selected-button-option-single-select-" + option?.value}
                className="radio-button-selected-button-option radio-select-with-button-single-select-input-option-image-container">
                {option.imageId ? <DisplayImage imageId={option.imageId}></DisplayImage> : option?.displayName}
            </Button>
            )
    }

    function getWoodcutImage() {
        if (productInputValue?.value == "no-icon") {
            return <></>
        } else {
            return (
            <div className="image-selection-preview">
                <p>Selected Woodcut:</p>
                <DisplayImage imageId={Number(productInputValue?.value)}></DisplayImage>
            </div> 
            )
        }
    }

    function getSingleSelectButtons() {
        if (!radioValue || groupedOptions === null) {
            return <></>
        }

        const informationTextOption = options.find(
          (option) => isConfigurationOption(option) && option.informationalText !== null && isParentOptionSelected(option)
        ) as ConfigurationOption;

        if (groupedOptions.length === 0 && informationTextOption?.informationalText) {
            return (
                <span className='radio-select-with-button-single-select-alert-container'>
                    <Alert color='primary'>
                        <span dangerouslySetInnerHTML={{__html: informationTextOption?.informationalText}} />
                    </Alert>
                </span>
            );
            }

        if (groupedOptions.length === 0) {
            return <></>
        } else {
            
            return (
                <div className="woodcut-accordion">
                    <Accordion open={open} toggle={toggle}>
                    {groupedOptions.map((go) => {
                        return (
                            <AccordionItem key={go.optionGroupKey}>
                                <span className="radio-select-with-button-single-select-option-group-container"
                                    key={"radio-button-selected-button-option-group-" + go.optionGroupKey}>
                                    <AccordionHeader targetId={go.optionGroupKey}>
                                        <h5>{go.optionGroupKey}</h5>
                                    </AccordionHeader>
                                    <AccordionBody accordionId={go.optionGroupKey}>
                                        <span className="radio-select-with-button-single-select-option-button-container">
                                            {[...go.options].sort(Utils.sortBy('sortOrder', 'asc')).map((buttonOption) => {
                                                return(
                                                    getOptionButton(buttonOption)
                                                );
                                            })}
                                        </span>
                                    </AccordionBody>
                                </span>
                            </AccordionItem>
                        );
                    })}
                </Accordion>
                {getWoodcutImage()}
            </div>
            );
        }
    }

    function getParentOption(childOptionId: number) {
        const parentOptionIndex = options.findIndex(o => o.id === childOptionId || (isConfigurationOption(o) && o.childConfigurationOptions?.find(cco => cco.id === childOptionId)));
        return options[parentOptionIndex];
    }

    // Finds option in `options` or in the `childConfigurationOptions` of each `option`
    function getOptionById(optionId?: number) {
        let optionIndex = options.findIndex(o => o.id === optionId);
        if (optionIndex > -1) {
            return options[optionIndex];
        }
        
        for (let i = 0; i < options.length; i++) {
            const option = options[i];
            if (!isConfigurationOption(option)) continue;
            
            const ix = option.childConfigurationOptions?.findIndex(cco => cco.id === optionId) ?? -1;
            if (option.childConfigurationOptions && ix > -1) {
                return option.childConfigurationOptions[ix];
            }
        }

        return undefined;
    }

    // If in read only mode, (there's no value OR selected value is no selection value) and its not required, then show nothing
    const selectedProductInputOption = getOptionById(productInputValue?.selectedProductInputOptionId);
    if (!isInEditMode &&
        (!productInputValue?.selectedProductInputOptionId || isConfigurationOption(selectedProductInputOption) && selectedProductInputOption.isNoSelection) &&
        !isProductInputRequired(productInput)) {
        return <></>
    }

    return (
        <span className={"radio-select-with-button-single-select-container " + getClassesForModifiers()}>

            <Label className={`radio-select-with-button-single-select-input-label ${getClassesForLabel(inputLabel)}`}>
                {inputLabel}
                {isRequired && isInEditMode ? <RequiredAsterisk></RequiredAsterisk> : <></>}
            </Label>

            {getValueRenderTemplate()}

        </span>
    );
}
