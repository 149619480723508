import {Dropdown, DropdownToggle, NavItem, NavLink} from 'reactstrap';
import { Link } from 'react-router-dom';
import { SiteNavigation } from '../SiteNavigation';
import './NavigationItem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import {NavigationChildren} from "../children/NavigationChildren";
import React, {useState} from "react";

interface NavigationItemProps {
    siteNavigation: SiteNavigation;
    onNavigationMouseOver: (siteNavigation: SiteNavigation) => void;
}

export const NavigationItem = ({siteNavigation, onNavigationMouseOver}: NavigationItemProps) => {
    const [isActive, setIsActive] = useState(false);
    
    const toggle = () => {
        setIsActive(!isActive);
    };

    function getNavItemChevron(siteNavigation: SiteNavigation) {
        if (siteNavigation.children && siteNavigation.children.length > 0) {
            return isActive ?
                (<FontAwesomeIcon icon={faChevronDown} className="flipped-svg chevron"/>) :
                (<FontAwesomeIcon icon={faChevronDown} className="chevron"/>);
        }
    }

    function getNavItemContent(siteNavigation: SiteNavigation) {
        if (siteNavigation.children && siteNavigation.children?.length > 0) {
            return (
                <Dropdown isOpen={isActive} toggle={toggle} nav inNavbar inlist={false}>
                    <DropdownToggle nav>
                        <NavItem className="navigation-item-link-text" inList={false}>
                            <span className='padding-right'>{siteNavigation.displayName}</span>
                            {getNavItemChevron(siteNavigation)}
                        </NavItem>
                    </DropdownToggle>
                    <NavigationChildren siteNavigationChildren={siteNavigation.children} />
                </Dropdown>
            );
        }

        return (
            <NavItem>
                <NavLink tag={Link} className="navigation-item-link-text" to={`/category/${siteNavigation.category.urlName}`}>
                    <span className='padding-right'>{siteNavigation.displayName}</span>
                </NavLink>
            </NavItem>
        )

    }

  return (
      <>
          <span className="navigation-item-link-container"
                   onClick={() => onNavigationMouseOver(siteNavigation)}>
              {getNavItemContent(siteNavigation)}
          </span>
      </>
  );

}