
import './SimpleToast.css';
import {Toast, ToastBody} from "reactstrap";
import {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

interface SuccessToastProps {
    isOpen: boolean,
    toastText: string,
    isErrorToast: boolean,
    dismissToast: () => void
}

export const SimpleToast = ({ isOpen, toastText, isErrorToast, dismissToast }: SuccessToastProps) => {
    const [ showSuccessToast, setShowSuccessToast ] = useState(isOpen);

    useEffect(() => {
        setShowSuccessToast(isOpen);
    }, [isOpen]);

    const getToastClasses = () => {
        if (isErrorToast) {
            return 'simple-toast error-toast';
        }
        else {
            return 'simple-toast success-toast';
        }
    }

    return (
        <Toast isOpen={showSuccessToast} className={getToastClasses()} onClick={dismissToast} title="Dismiss">
            <ToastBody className="simple-toast">
                <p>{toastText}</p>
            </ToastBody>
        </Toast>
    )
}
