import React, {ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {Button, Col, FormFeedback, FormGroup, Input, Label, Row, Spinner} from "reactstrap";
import {
	useCreateOrderMutation,
	useDeleteSavedPaymentDetailsForUserMutation,
	useGetCartProductsPricingQuery,
	useGetCartProductsQuery,
	useGetCurrentCartCheckAddressQuery,
	useGetOrderByCartIdQuery,
	useGetOrderShippingInformationQuery,
	useGetPreviousOrderPaymentInformationQuery,
	useGetSavedPaymentDetailsForUserQuery,
	useGetSitesQuery,
	useRemoveAllProductsFromCartMutation,
	useSavePaymentDetailsMutation,
} from "../../../app/apiSlice";
import {CustomerSelect} from "../customer/CustomerSelect";
import {Consumer} from "../customer/Consumer";
import {SelectedCartProductTable} from "../product/SelectedCartProductTable";
import {OrderSummary} from "./OrderSummary";
import {ShippingInformation, ShippingInformationCard} from "./ShippingInformation";
import {BillingAddress} from "./BillingAddress";
import {PaymentSelection} from "../../payment/selection/PaymentSelection";
import {Address} from "../../input/address/Address";
import {
	useAppDispatch,
	useAppSelector,
	useDebounce,
	useShippingAddressValidation,
	validationRequired
} from "../../../app/hooks";
import {forgetCartId, selectAdminCartId, setCartId} from "../../cart/CartSlice";
import {OrderOriginEnum, SaveOrderVm} from "./SaveOrderVm";
import {useNavigate, useSearchParams} from "react-router-dom";
import {PaymentDetails, PaymentDetailsType} from "../../payment/PaymentDetails";
import {ShippingOption} from "../../shipping-options/ShippingOption";
import {skipToken} from "@reduxjs/toolkit/query";
import {
	getErrorMessage,
	Regexes,
	Utils,
	validateCCFullNameString,
	validateCVCString,
	validateExpirationDateString
} from "../../../utils/Utils";
import {RequiredAsterisk} from "../../input/utils/RequiredAsterisk";
import {OrderCreationResponse} from "../../order/Order";
import {PaymentUtils} from "../../payment/utils/PaymentUtils";
import {PaymentMethodOption} from "../../payment/PaymentMethod";
import {showConfirmationModal, showInformationModal} from "../../modal/ModalSlice";
import {setProductInputValues} from "../../input/ProductInputSlice";
import {CartPricingRequest} from "../../cart/CartPricing";
import {AnalyticsTools} from "../../../utils/AnalyticsHelper";

interface CreateOrderButtonProps {
	submitOrder: () => void,
	createOrderButtonText: string | JSX.Element,
	disabled: boolean
}

const CreateOrderButton = ({submitOrder, createOrderButtonText, disabled}: CreateOrderButtonProps) =>
	<Button color="success"
			onClick={submitOrder}
			disabled={disabled}>
		{createOrderButtonText}
	</Button>

interface NewOrderState {
	siteId?: number
	consumer?: Consumer
	shipping?: ShippingInformation
	billingAddress?: Address,
	cartId?: number,
	shippingOption?: ShippingOption,
	paymentDetails?: PaymentDetails
}

export const NewOrder = () => {
	const {data: sites} = useGetSitesQuery();
	const {data: checkAddress} = useGetCurrentCartCheckAddressQuery();
	const [createOrder, {isLoading: isPlacingOrder, isError: errorPlacingOrder}] = useCreateOrderMutation();
	const [savePaymentDetails, {isLoading: isSavingPaymentDetails}] = useSavePaymentDetailsMutation();
	const [deleteSavedPaymentDetailsForUser, {isLoading: isDeletingSavedPayment}] = useDeleteSavedPaymentDetailsForUserMutation();
	const [removeAllProductFromCart] = useRemoveAllProductsFromCartMutation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const cartId = useAppSelector(selectAdminCartId);
	const [cartPricingRequest, setCartPricingRequest] = useState<CartPricingRequest | undefined>();
	const [isMailInOrder, setIsMailInOrder] = useState(false);
	const {data: cartProducts} = useGetCartProductsQuery(cartId ?? skipToken);
	const {
		currentData: cartPricing,
		isFetching: isFetchingCartPricing
	} = useGetCartProductsPricingQuery(cartPricingRequest ?? skipToken);
	const {data: cartOriginOrder} = useGetOrderByCartIdQuery(cartId ?? skipToken);
	const {data: originOrderShippingInfo} = useGetOrderShippingInformationQuery(cartOriginOrder?.id ?? skipToken);
	const {data: previousPaymentInformation} = useGetPreviousOrderPaymentInformationQuery(cartOriginOrder?.id ?? skipToken);

	// Forgets old admin cart upon load of the component to get clean setup
	useEffect(() => {
		const cartId = searchParams.get("cartId");
		if (cartId) {
			dispatch(setCartId(cartId));
		} else {
			dispatch(forgetCartId());
		}
	}, []);

	const [state, setState] = useState<NewOrderState>({
		siteId: undefined,
	});
	const [enableSavedPayments, setEnableSavedPayments] = useState(false);
	const {data: savedPaymentDetails} = useGetSavedPaymentDetailsForUserQuery(state.consumer?.userId ?? skipToken);
	const [selectedPaymentProfileId, setSelectedPaymentProfileId] = useState<string | undefined>(undefined);
	const [paymentMethodOption, setPaymentMethodOption] = useState<PaymentMethodOption>();
	const [paymentDetailsType, setPaymentDetailsType] = useState<PaymentDetailsType | undefined>(PaymentDetailsType.CreditCardPaymentDetails);

	// require payment if we have no pricing yet or we do have pricing and its > 0
	const orderRequiresPaymentDetails = (!cartPricing || (cartPricing && (cartPricing.total ?? 0)) > 0);
	const validation = useShippingAddressValidation({
		storeFront: validationRequired('Storefront'),
		payment_method: validationRequired('Payment type'),

		...PaymentUtils.wrapPaymentDetailsValidationMap(orderRequiresPaymentDetails ? paymentDetailsType : undefined, PaymentDetailsType.CreditCardPaymentDetails, {
			payment_firstName: validationRequired('First Name'),
			payment_lastName: validationRequired('Last Name'),
			payment_fullName: val => validateCCFullNameString(val),
			payment_cardNumber: val => (!val || !Regexes.ccWithFormatting.test(val)) ? 'Please enter a valid card number' : undefined,
			payment_cardExpiration: val => validateExpirationDateString(val),
			payment_cvc: val => validateCVCString(val),
		}),
		...PaymentUtils.wrapPaymentMethodOptionValidationMap(orderRequiresPaymentDetails ? paymentMethodOption : undefined, PaymentMethodOption.SavedPaymentMethod,
			{
				payment_savedMethod: validationRequired('Payment Method')
			}
		)
	});

	useEffect(() => {
		if (cartId) {
			const productShippingServiceSelections = state.shippingOption?.items.map((i) => {
				return {
					cartProductId: i.cartProductId,
					cost: i.price ?? 0,
					retailCost: i.retailPrice ?? 0
				}
			}) ?? [];
			setCartPricingRequest({cartId, toAddress: state.shipping?.address, productShippingServiceSelections});
		} else {
			setCartPricingRequest(undefined);
		}
	}, [cartId, state.shipping?.address, state.shippingOption]);

	const hasAllOrderRequiredInfo = useMemo(() => {
		const hasShippingOption = ((state.shippingOption?.items?.length ?? 0) >= 1);
		const hasShippingAddress = !!(state.shipping?.address?.street && state.shipping.address.city
			&& state.shipping.address.stateCode && state.shipping.address.zip);
		const hasPaymentDetails = !!state.paymentDetails;
		const hasBillingAddressOrIsSavedPayment = !!((state.billingAddress?.street
				&& state.billingAddress?.city && state.billingAddress.stateCode && state.billingAddress.zip)
			|| state.paymentDetails?.paymentDetailsType === PaymentDetailsType.SavedPaymentDetails);

		return hasShippingOption && hasShippingAddress
			&& (!orderRequiresPaymentDetails || (hasPaymentDetails && hasBillingAddressOrIsSavedPayment));
	}, [state.shippingOption, state.shipping, state.paymentDetails, state.billingAddress]);

	const setSelectedSiteAndConsumerInfo = useCallback((siteId?: number, consumer?: Consumer, billingAddress?: Address) => {
		validation.setValue('storeFront', `${siteId ?? ''}`);
		
		// Only clear all info if we are going from one consumer to another
		const shouldClearAllInfo = !!state.consumer?.id && !!consumer?.id && state.consumer.id !== consumer.id;
		
		setState(old => ({
			...old,
			consumer: consumer,
			billingAddress: shouldClearAllInfo ? billingAddress : billingAddress ?? old.billingAddress,
			shipping: shouldClearAllInfo ? undefined : old.shipping,
			siteId: siteId,
		}));
		
		// Enable saved payments if we have a consumer
		setEnableSavedPayments(!!consumer);

		// Clear input values
		dispatch(setProductInputValues([]));
	}, [validation]);

	useEffect(() => {
		const paramCartId = searchParams.get("cartId");
		
		if (cartOriginOrder && paramCartId && previousPaymentInformation) {
			setSelectedSiteAndConsumerInfo(cartOriginOrder.siteId, cartOriginOrder.consumer, previousPaymentInformation.billingAddress);
		} else if (!cartId) {
			setSelectedSiteAndConsumerInfo(undefined, undefined, undefined);
		}
	}, [cartOriginOrder, previousPaymentInformation, cartId]);

	// Fill in saved payment details on previous payment loads
	useEffect(() => {
		if (previousPaymentInformation?.vendorPaymentProfileId && !selectedPaymentProfileId) {
			setSelectedPaymentProfileId(previousPaymentInformation.vendorPaymentProfileId);
		}
	}, [previousPaymentInformation]);

	useEffect(() => {
		if (originOrderShippingInfo && cartId) {
			onShippingChanged(originOrderShippingInfo);
		}
	}, [originOrderShippingInfo, cartId]);

	const isPlaceOrderButtonDisabled = useMemo(() => !hasAllOrderRequiredInfo || isPlacingOrder || isFetchingCartPricing,
		[hasAllOrderRequiredInfo, isPlacingOrder, isFetchingCartPricing]);
	
	// Debouncing disabled property because shipping option request can initiate a subsequent pricing request
	// Bypass the debounce if the value becomes true, we only want to debounce making the button enabled
	const debouncedIsPlaceOrderButtonDisabled = useDebounce(isPlaceOrderButtonDisabled, 250, isPlaceOrderButtonDisabled);

	const onSiteChanged = async (e: ChangeEvent<HTMLInputElement>) => {
		const parsed = Number.parseInt(e.target.value);
		const newId = Utils.isValidNumber(parsed) ? parsed : undefined;
		
		setSelectedSiteAndConsumerInfo(newId);
		
		if (cartId) {
			try {
				await removeAllProductFromCart(cartId).unwrap();
			} catch (e: any) {
				dispatch(showInformationModal({
					title: 'Cart Clear Failed',
					text: `An error occurred while trying to clear the cart: ${getErrorMessage(e)}`
				}));
			}
		}
	};

	const onConsumerChanged = (consumer?: Consumer) => {
		setEnableSavedPayments(consumer !== undefined);
		setState(old => ({
			...old,
			consumer,
			shipping: {
				...old.shipping,
				firstName: consumer?.firstName,
				lastName: consumer?.lastName,
				phoneNumber: consumer?.phoneNumber,
				email: consumer?.email,
				repeatEmail: consumer?.email,
				address: undefined,
			},
			billingAddress: undefined,
		}));
	}

	const onShippingOptionChanged = (shippingOption?: ShippingOption) => {
		setState(old => ({
			...old,
			shippingOption,
		}));
	}

	const fillAddress = (address: 'shipping' | 'billingAddress') => {
		if (address === 'shipping') {
			const parts = checkAddress?.name?.split(' ') ?? ['', ''];
			const firstName = parts[0];
			const lastName = parts[parts.length - 1];

			setState(old => ({
				...old,
				shipping: {
					...old.shipping,
					firstName,
					lastName,
					phoneNumber: checkAddress?.phoneNumber,
					address: {...checkAddress, name: checkAddress?.name, id: undefined},
				}
			}));
		} else {
			setState(old => ({
				...old,
				billingAddress: {...checkAddress, id: undefined},
			}));
		}
	}

	const onShippingChanged = (shipping: ShippingInformation) => {
		const setValue = (name: string, val: string) => validation.setValue(`shipping_${name}`, val);

		setState(old => ({
			...old,
			shipping,
		}));

		// shipping specific validation fields
		setValue('firstName', shipping.firstName ?? "");
		setValue('lastName', shipping.lastName ?? "");
		setValue('phoneNumber', shipping?.phoneNumber ?? "");
		setValue('email', shipping?.email ?? "");
		setValue('repeatEmail', shipping?.repeatEmail ?? "");
	}

	const onBillingChanged = (billingAddress?: Address) => {
		setState(old => ({
			...old,
			billingAddress,
		}));
	}

	const onPaymentDetailsChanged = (paymentDetails?: PaymentDetails) => {
		setPaymentDetailsType(paymentDetails ? PaymentDetailsType[paymentDetails.paymentDetailsType as keyof typeof PaymentDetailsType] : undefined);
		setState(old => ({
			...old,
			paymentDetails,
		}));
	}

	const onMailInOrderCheck = () => {
		setIsMailInOrder(!isMailInOrder);
	}

	const validate = () => {
		let allGood = true;
		let hints = '';

		if (((state.shippingOption?.items?.length ?? 0) < 1)) {
			allGood = false;
			hints += ' Please select products and a shipping option for the order. ';
		}

		if (!allGood) {
			dispatch(showInformationModal({
				title: 'Order Validation',
				text: hints,
			}));
		}
		return allGood;
	}

	const onSavePaymentDetailsClicked = async () => {
		if (!state.paymentDetails || !state.consumer?.id) {
			dispatch(showInformationModal({
				title: 'Missing Information',
				text: 'Please enter all of the payment fields, billing address, and select a customer.'
			}));
			return;
		}
		try {
			const newPaymentProfileId = await savePaymentDetails({
				paymentDetails: state.paymentDetails,
				userId: state.consumer.userId
			}).unwrap();

			if (newPaymentProfileId) {
				dispatch(showInformationModal({
					title: 'Saved!',
					text: 'Payment method saved.',
				}));
			} else {
				dispatch(showInformationModal({
					title: 'Failed to Save!',
					text: "Failed to save payment method. It's possible this payment method may already be saved."
				}));
			}
		} catch (e) {
			dispatch(showInformationModal({
				title: 'Failed to Save!',
				text: 'Failed to save payment method.'
			}));
		}
	}

	const onRemoveSavedPaymentMethodClicked = async (customerPaymentProfileId: string) => {
		if (!state.consumer?.id) {
			return;
		}

		try {
			await deleteSavedPaymentDetailsForUser({userId: state.consumer.userId, customerPaymentProfileId}).unwrap();
		} catch (e) {
			alert('Error deleting payment details: ' + JSON.stringify(e));
		}
	}

	const proceedToDetails = (orderId: number) => {
		dispatch(forgetCartId());
		navigate(`/admin/order/${orderId}`);
	}

	const submitOrder = async () => {
		if (isPlacingOrder || !validation.allValid() || !validate() || !hasAllOrderRequiredInfo) return;

		const orderConsumer = state.consumer ?? {
			id: -1,
			userId: -1,
			siteId: state.siteId!,
			firstName: state.shipping!.firstName,
			lastName: state.shipping!.lastName,
			email: state.shipping!.email!,
			phoneNumber: state.shipping!.phoneNumber,
		} as Consumer;

		const order: SaveOrderVm = {
			orderId: -1,
			cartId,
			email: state.shipping?.email ?? "",
			phoneNumber: state.shipping?.phoneNumber ?? "",
			orderOrigin: isMailInOrder ? OrderOriginEnum.MailIn : OrderOriginEnum.AdminSite,
			consumer: orderConsumer,
			shippingAddress: {
				...state.shipping?.address,
				id: state.shipping?.address?.id ?? -1,
				name: state.shipping?.firstName,
				nameTwo: state.shipping?.lastName
			},
			changeType: 'New Order',
			paymentDetails: orderRequiresPaymentDetails ? state.paymentDetails : undefined,
			cartProductShippingEstimateIds: state.shippingOption?.items?.map(i => i.cartProductShippingEstimateId) ?? []
		};
		
		let orderCreationResponse: OrderCreationResponse | undefined = undefined;
		
		try {
			orderCreationResponse = await createOrder(order).unwrap();
		} catch (e: any) {
			dispatch(showInformationModal({
				title: 'Failed to Create Order!',
				text: 'Failed to created order. ' + (getErrorMessage(e) ?? 'Unknown error'),
			}));
		}
		
		if (orderCreationResponse && cartPricing) {
			AnalyticsTools.recordOrderEvent(orderCreationResponse.orderId, cartPricing, cartProducts ?? []);
		}
		
		if (orderCreationResponse && orderCreationResponse.isPartiallyComplete) {
			const orderId = orderCreationResponse.orderId;
			dispatch(showConfirmationModal({
				title: 'There was an issue',
				content: 'One or more products were not able to be ordered. The products that were not able to be purchased are still in the cart here. ' +
					'Would you like to proceed to the order details page or remain here to attempt to purchase these products again?',
				affirmText: 'Continue to Order Details',
				cancelText: 'Remain Here',
				onConfirm: (() => proceedToDetails(orderId))
			}));
		} else if (orderCreationResponse) {
			proceedToDetails(orderCreationResponse.orderId)
		}
	};

	const getCreateButtonText = () => {
		return !isPlacingOrder || errorPlacingOrder ? "Create" : (
			<>
				<Spinner></Spinner>
				{"Creating"}
			</>
		);
	}

	const getMailInCheckbox = () => (
		<span className='me-3 mb-2'>
			<label htmlFor='mail-in-checkbox'>Mail in order</label>
			<input name="mail-in-checkbox" id="mail-in-checkbox" type="checkbox" onChange={onMailInOrderCheck} 
				   value={isMailInOrder ? 'checked' : 'unchecked'} className="form-check-input ms-1"/>
		</span>
	)

	return (
		<form className='d-flex flex-column px-4'>
			<div className='d-flex justify-content-between mb-2'>
				<h2 className='mb-0'>New Order</h2>

				{Utils.isValidNumber(state.siteId) &&
					<CreateOrderButton submitOrder={submitOrder}
									   createOrderButtonText={getCreateButtonText()}
									   disabled={debouncedIsPlaceOrderButtonDisabled || validation.isActivelyInvalid()}/>
				}
			</div>

			<Row className='mb-2'>
				<Col md={6}>
					<FormGroup>
						<Label for='SiteId'>Storefront</Label>
						<RequiredAsterisk></RequiredAsterisk>
						<Input type='select' id='SiteId' name='siteId' value={state.siteId}
							   defaultValue={undefined}
							   onChange={onSiteChanged}
							   invalid={validation?.getError('storeFront') !== undefined}>
							<option value={undefined} hidden={true}>Select Site</option>
							{sites?.map(s => (
								<option key={s.id} value={s.id}>{s.name}</option>
							))}
						</Input>
						<FormFeedback>{validation?.getError('storeFront')}</FormFeedback>
					</FormGroup>
				</Col>

				{
					!Utils.isValidNumber(state.siteId) ? <></> :
						<Col md={6}>
							<FormGroup>
								<Label>Customer</Label>
								<CustomerSelect siteId={state.siteId} value={state.consumer}
												onChange={onConsumerChanged}
												placeholder='New customer to be created from order information.'/>
							</FormGroup>
						</Col>
				}
			</Row>

			{
				!Utils.isValidNumber(state.siteId) ? <></> :
					<Fragment>
						<Row className='my-2'>
							{getMailInCheckbox()}
							<Col xs='12'>
								<SelectedCartProductTable siteId={state.siteId} consumerId={state.consumer?.id}/>
							</Col>
						</Row>

						<Row className='my-2'>
							<Col>
								<ShippingInformationCard consumerId={state.consumer?.id}
														 shipping={state.shipping}
														 validation={validation.createProxy('shipping')}
														 suppressError={!validation.shouldShowErrors}
														 onFillFromCheck={checkAddress ? () => fillAddress('shipping') : undefined}
														 onShippingChanged={onShippingChanged}
								/>
							</Col>
							<Col xs='5'>
								<OrderSummary cartId={cartId}
											  onShippingOptionChange={onShippingOptionChanged}
											  shippingOption={state.shippingOption}
											  cartPricing={cartPricing}
											  isFetchingCartPricing={isFetchingCartPricing}
											  toAddress={state.shipping?.address}/>
							</Col>
						</Row>

						{orderRequiresPaymentDetails && <Row className='my-2'>
							<Col>
								<BillingAddress consumerId={state.consumer?.id}
												address={state.billingAddress}
												suppressError={!validation.shouldShowErrors}
												onFillFromCheck={checkAddress ? () => fillAddress('billingAddress') : undefined}
												onAddressChanged={onBillingChanged}
												useCardContainer={true}/>
							</Col>
							<Col xs='5'>
								<PaymentSelection selectedPaymentProfileId={selectedPaymentProfileId}
												  enabledSavedPaymentMethods={enableSavedPayments}
												  savedPaymentDetails={savedPaymentDetails}
												  billingAddress={state.billingAddress}
												  onPaymentDetailsChanged={onPaymentDetailsChanged}
												  onSavePaymentDetailsClicked={onSavePaymentDetailsClicked}
												  onRemoveSavedPaymentMethodClicked={onRemoveSavedPaymentMethodClicked}
												  onPaymentOptionMethodChange={setPaymentMethodOption}
												  validation={validation.createProxy('payment')}
												  isSavingPaymentDetails={isSavingPaymentDetails}
												  isDeletingSavedPayment={isDeletingSavedPayment}
												  useCardContainer={true}
												  cartId={cartId}/>
							</Col>
						</Row>}
					</Fragment>
			}
			<div className="d-flex flex-row-reverse">
				{Utils.isValidNumber(state.siteId) &&
					<CreateOrderButton submitOrder={submitOrder}
									   createOrderButtonText={getCreateButtonText()}
									   disabled={debouncedIsPlaceOrderButtonDisabled || validation.isActivelyInvalid()}/>
				}
			</div>
		</form>
	);
}
