
import './AddOnDetails.css';
import {AddOn, AddOnDescription} from "../AddOn";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";

interface AddOnDetailsProps {
    addOn: AddOn,
    isSelected?: boolean,
    onAddOnDetailsClicked: (addOnId: number) => void
}

export const AddOnDetails = ({ addOn, isSelected, onAddOnDetailsClicked }: AddOnDetailsProps) => {
    let addOnDescription: AddOnDescription | null = null;
    try {
        addOnDescription = JSON.parse(addOn.description);
    }
    catch (ex) {
        addOnDescription = {body: addOn.description, listItems: []}
    }

    function onAddOnClicked() {
        onAddOnDetailsClicked(addOn.id);
    }

    return (
        <span className={"add-on-details-container " + (isSelected ? "add-on-details-selected" : "")} onClick={(e) => onAddOnClicked()}>

            <span className="add-on-header">
                <span className="add-on-name">{addOn.name}</span>

                <span className="add-on-price">{addOn.price ? CurrencyFormatter.format(addOn.price) : ""}</span>
            </span>
            <span>
                {addOnDescription?.body}
                <ul>
                    {addOnDescription?.listItems.map(itemString => {
                        return (
                            <li key={itemString}>
                                {itemString}
                            </li>
                        );
                    })}
                </ul>
            </span>


        </span>
    );
}
