import {useFormContext} from "react-hook-form";
import {CheckoutSchema} from "../cart/checkout/schema";
import {Address} from "../input/address/Address";
import {selectCartId} from "../cart/CartSlice";
import {useEffect, useState} from "react";
import {ShippingOptionsRequest} from "../shipping-options/ShippingOptionsRequest";
import {useFetchShippingOptionsQuery} from "../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {CurrencyFormatter} from "../../utils/CurrencyFormatter";
import {FormRadioGroup} from "../forms/FormRadioGroup";
import {selectCurrentConsumerUser} from "../user/login/AuthenticationSlice";
import {ShippingOption} from "../shipping-options/ShippingOption";
import {setSelectedShippingOption} from "./CheckoutSlice";
import {ShippingDisclaimer} from "../shipping-options/disclaimer/ShippingDisclaimer";

interface ShippingAndHandlingFieldsProps {
    readonly toAddress: Address;
    readonly consumerId?: number;
}

export function ShippingAndHandlingFields({
                                              toAddress,
                                          }: ShippingAndHandlingFieldsProps) {

    const dispatch = useAppDispatch();
    
    const [shippingOptionsRequest, setShippingOptionsRequest] = useState<ShippingOptionsRequest | null>(null);
    
    const cartId = useAppSelector(selectCartId);
    const consumer = useAppSelector(selectCurrentConsumerUser);

    const {
        data: options = []
    } = useFetchShippingOptionsQuery(shippingOptionsRequest ?? skipToken);

    const {
        control,
        watch,
    } = useFormContext<CheckoutSchema>();

    useEffect(() => {
        const {street, city, stateCode, zip} = toAddress || {};

        const hasAddress = toAddress !== null
            && street && city && stateCode && zip && zip.length >= 5;

        if (hasAddress && cartId) {
            setShippingOptionsRequest({
                cartId,
                toAddress,
                consumerId: consumer?.id
            })
        }
    }, [toAddress, cartId, consumer]);

    const selectedOptionId = watch('shippingAndHandlingOption');
    useEffect(() => {
        if (selectedOptionId) {
            const selectedOption = options.find(o => o.id == selectedOptionId);
            if (selectedOption) {
                dispatch(setSelectedShippingOption(selectedOption))
            }
        }
    }, [selectedOptionId, options]);

    return (
        <>
            <FormRadioGroup control={control}
                            name="shippingAndHandlingOption"
                            label={''}
                            options={options?.map(o => ({
                                text: `${CurrencyFormatter.format(o.price)} ${o.name} (${o.description})`,
                                value: o.id
                            }))}/>
            <ShippingDisclaimer />
        </>
    );
}

