import styles from './PasswordInput.module.css';
import { Button, FormFeedback, Input, InputGroup, InputProps } from "reactstrap"
import { ChangeEvent, useEffect, useState } from "react";
import { Regexes } from "../../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface PasswordInputProps extends InputProps {
    // I could make these undefined-able, but considering we use controlled components, this is good to force
    // parents to follow best practices
    value: string,
    onChange: NonNullable<InputProps['onChange']>
    
    suppressError?: boolean // true if you want to hold off on showing error until the user submits
    isConfirmInput?: boolean // true to hide password requirements hints
}

/**
 * Input for consumer passwords with validation. Only emits valid passwords and empty strings
 * @param props
 * @constructor
 */
export const PasswordInput = (props: PasswordInputProps) => {
    let inputProps = { ...props };
    // remove props not on InputProps
    delete inputProps.suppressError;
    delete inputProps.isConfirmInput;

    const [ value, setValue ] = useState(props.value);
    const [ showPassword, setShowPassword ] = useState(false);

    // if parent wants to change value, they can, but it must be valid
    useEffect(() => {
        if (isValid(props.value)) {
            setValue(props.value)
        }
    }, [props.value]);
    
    const has8Chars = (val: string) =>
        val.length > 8;
    
    const hasLower = (val: string) =>
        /(?=.*[a-z])./.test(val);
    
    const hasUpper = (val: string) =>
        /(?=.*[A-Z])./.test(val);
    
    const hasNumber = (val: string) =>
        /(?=.*\d)./.test(val);
    
    const hasSpecialCharacter = (val: string) =>
        /(?=.*[_!@#$%^&*-])./.test(val);

    const isValid = (val: string) =>
        Regexes.password.test(val);
    
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue(newValue);

        // only emit valid values
        if (isValid(newValue)) {
            props.onChange(e);
            return;
        }

        // if parent thinks theres a value, clear it, parent will not get anymore updates until we're valid
        if (props.value !== '') {
            // not sure why name doesn't pop out, so manually setting it so Utils.handle functions can pick it up
            props.onChange({ ...e, target: { ...e.target, name: e.target.name, value: ''} });
        }
    }

    return <InputGroup>
        <Input {...inputProps}
               type={showPassword ? 'text' : 'password'}
               invalid={props.invalid || (!props.suppressError) && !isValid(value)}
               valid={!(props.suppressError ?? false)}
               maxLength={100}
               onChange={onChange}
               value={value}/>
        <Button className={styles.showPasswordButton} type='button' onClick={() => setShowPassword(!showPassword)}>
            <FontAwesomeIcon icon={showPassword ? faEyeSlash :faEye}/>
        </Button>
        { !(props.isConfirmInput ?? false) &&
            <>
                <FormFeedback valid={isValid(value)}>Password must contain all of the following:</FormFeedback>
                <FormFeedback valid={has8Chars(value)}>&#9702; At Least 8 Characters</FormFeedback>
                <FormFeedback valid={hasLower(value)}>&#9702; A Lowercase Letter</FormFeedback>
                <FormFeedback valid={hasUpper(value)}>&#9702; An Uppercase Letter</FormFeedback>
                <FormFeedback valid={hasNumber(value)}>&#9702; A Number</FormFeedback>
                <FormFeedback valid={hasSpecialCharacter(value)}>&#9702; A Special Character (_!@#$%^&*-)</FormFeedback>
            </>
        }
    </InputGroup>;
}