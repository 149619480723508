
import {CartPricing} from "../../../../../../cart/CartPricing";
import {OrderEditCartDetails} from "../../../../../../order/Order";
import {useGetOrderPricingQuery} from "../../../../../../../app/apiSlice";
import {OrderEditProductComparison} from "../productComparison/OrderEditProductComparison";
import {OrderEditPaymentPlan} from "../paymentPlan/OrderEditPaymentPlan";

interface OrderEditPaymentDeltaProps {
    cartPricing: CartPricing,
    orderEditCartDetails: OrderEditCartDetails,
    includeDetails: boolean,
    onRequiresPaymentChanged?: (value: boolean) => void,
}
export const OrderEditPaymentDelta = ({ cartPricing, orderEditCartDetails, includeDetails, onRequiresPaymentChanged }: OrderEditPaymentDeltaProps) => {
    const {data: orderPricing} = useGetOrderPricingQuery(orderEditCartDetails.orderId);

    return (
        <>
            {includeDetails && orderPricing && <OrderEditProductComparison
              orderEditCartDetails={orderEditCartDetails}
              orderPricing={orderPricing}
              cartPricing={cartPricing}></OrderEditProductComparison>}
            {orderPricing && <OrderEditPaymentPlan
                orderEditCartDetails={orderEditCartDetails}
                orderPricing={orderPricing}
                cartPricing={cartPricing}
                onRequiresPaymentChanged={onRequiresPaymentChanged}></OrderEditPaymentPlan>}
        </>
    )
}