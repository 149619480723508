
import {Card, CardBody, CardHeader} from "reactstrap";
import './OrderProductRow.css';
import {OrderEditCartDetails} from "../../../../order/Order";
import {OrderEditPaymentDelta} from "./orderEdit/paymentDelta/OrderEditPaymentDelta";
import {CartPricing} from "../../../../cart/CartPricing";

interface OrderEditPaymentPreviewProps {
    cartPricing: CartPricing,
    orderEditCartDetails: OrderEditCartDetails
}
export const OrderEditPaymentPreview = ({ cartPricing, orderEditCartDetails }: OrderEditPaymentPreviewProps) => {

    return (
        <Card>
            <CardHeader className="order-details-tab-card-header">
                <h5 className='m-0'>Payment Changes</h5>
            </CardHeader>
            <CardBody>
                {<OrderEditPaymentDelta
                    includeDetails={false}
                    cartPricing={cartPricing}
                    orderEditCartDetails={orderEditCartDetails}></OrderEditPaymentDelta>}
            </CardBody>
        </Card>
    )
}