import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import AuthenticationResponse from './AuthenticationResponse'
import type {RootState} from '../../../app/store'
import {BrowserStorageType, fetchStorageValue, persistInStorage, removeFromStorage} from "../../../utils/Utils";
import {Consumer} from "../../admin/customer/Consumer";
import { apiSlice } from '../../../app/apiSlice';

const authTokenLocalStorageKey = 'ProductCatalogAuthToken-value';
const refreshTokenLocalStorageKey = 'ProductCatalogRefreshToken-value';
const refreshTokenExpirationLocalStorageKey = 'ProductCatalogRefreshTokenExpiration-value';
const userInfoLocalStorageKey = 'ProductCatalogUserInfo-value';
const cartIdLocalStorageKey = 'CartId-value';
const productValuesLocalStorageKey = 'ProductInputState-values';
const slice = createSlice({
  name: 'authentication',
  initialState: {
    consumer: fetchStorageValue(userInfoLocalStorageKey, BrowserStorageType.LocalStorage) ?? undefined,
    token: fetchStorageValue(authTokenLocalStorageKey, BrowserStorageType.LocalStorage) ?? undefined,
    refreshToken: fetchStorageValue(refreshTokenLocalStorageKey, BrowserStorageType.LocalStorage) ?? undefined,
    refreshTokenExpiration: fetchStorageValue(refreshTokenExpirationLocalStorageKey, BrowserStorageType.LocalStorage) ?? undefined
  } as AuthenticationResponse,
  reducers: {
    setCredentials: (
        state,
        { payload:  resp  }: PayloadAction<AuthenticationResponse>
    ) => {
      // store user's token in local storage
      persistInStorage(authTokenLocalStorageKey, resp.token, BrowserStorageType.LocalStorage);
      persistInStorage(userInfoLocalStorageKey, resp.consumer, BrowserStorageType.LocalStorage);
      persistInStorage(refreshTokenLocalStorageKey, resp.refreshToken, BrowserStorageType.LocalStorage);
      persistInStorage(refreshTokenExpirationLocalStorageKey, resp.refreshTokenExpiration, BrowserStorageType.LocalStorage);
      state.consumer = resp.consumer
      state.token = resp.token
      state.refreshToken = resp.refreshToken
      state.refreshTokenExpiration = resp.refreshTokenExpiration
    },
    clearSession: (
        state
    ) => {
      // remove user's info from local storage
      removeFromStorage(authTokenLocalStorageKey);
      removeFromStorage(refreshTokenLocalStorageKey);
      removeFromStorage(refreshTokenExpirationLocalStorageKey);
      removeFromStorage(userInfoLocalStorageKey);
      removeFromStorage(cartIdLocalStorageKey);
      removeFromStorage(productValuesLocalStorageKey);
      state.consumer = undefined
      state.token = undefined
      state.refreshToken = undefined
      state.refreshTokenExpiration = undefined
    },
    updateConsumer: (
        state,
        { payload:  consumer  }: PayloadAction<Consumer>
    ) => {
      persistInStorage(userInfoLocalStorageKey, consumer, BrowserStorageType.LocalStorage);
      state.consumer = consumer;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(apiSlice.endpoints.authenticate.matchFulfilled, (state, { payload: resp }) => {
      slice.caseReducers.setCredentials(state, {type: '', payload: resp});
    })
  }
})

export const { setCredentials, clearSession, updateConsumer } = slice.actions

export default slice.reducer

export const selectCurrentConsumerUser = (state: RootState) => state.authentication.consumer;
export const getRefreshToken = (state: RootState) => state.authentication.refreshToken;
export const getAccessToken = (state: RootState) => state.authentication.token;