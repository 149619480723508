import {Spinner} from "reactstrap";
import React from "react";
import './LoadingSpinner.css';

interface LoadingSpinnerProps {
    size?: string
    shouldRemoveMargin?: boolean
}
export const LoadingSpinner = ({size, shouldRemoveMargin}: LoadingSpinnerProps) => {

    return (
        
        <div className={shouldRemoveMargin ? 'loading-spinner-container-no-margin' : 'loading-spinner-container'} >
            <Spinner size={size ?? ''} >
                Loading...
            </Spinner>
        </div>
    )
}
