import { CurrencyFormatter } from "../../../utils/CurrencyFormatter";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Tooltip} from "reactstrap";
import { CartProductVM } from "../../cart/CartProduct";
import { SiteProductVariantPricing } from "../../cart/CartPricing";
import { useGetSiteProductVariantQuantityOptionsQuery } from "../../../app/apiSlice";
import React, { useState } from "react";
import { faSave } from '@fortawesome/free-solid-svg-icons';
import './CartProductRow.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface CartProductRowProps {
  cartProduct: CartProductVM,
  pricing?: SiteProductVariantPricing,
  onQuantityOptionChange: (quantityOptionId: number) => void,
  onEditClick: (cartProduct: CartProductVM) => void,
  onRemoveClick: (cartProductId: number) => void,
  showUnsavedIcon?: boolean
}
export const CartProductRow = ({ cartProduct, pricing, onQuantityOptionChange, onEditClick, onRemoveClick, showUnsavedIcon }: CartProductRowProps) => {
  const { data: quantityOptions } = useGetSiteProductVariantQuantityOptionsQuery(cartProduct.siteProductVariantId);

  const [ dropDownOpen, setDropDownOpen ] = useState(false);
  const toggleDropdown = () => setDropDownOpen(old => !old);

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const shouldDisableEdits = () => {
        return !cartProduct.isEditable;
    }
  
  return (
    <tr className="cart-product-row">
      <td>{cartProduct.sku}</td>
      <td>{cartProduct.siteProductName}</td>
      <td>
        <Input type='select'
               disabled={shouldDisableEdits()}
               value={cartProduct.quantityOption.id}
               onChange={e => onQuantityOptionChange(Number.parseInt(e.target.value))}>
          { quantityOptions?.map(qo => (
              <option key={qo.id} value={qo.id}>{qo.description} - {CurrencyFormatter.format(qo.price)} {!qo?.isActive ? "(Inactive)" : ""}</option>
          ))}
        </Input>
      </td>
      <td>{pricing?.retailSubTotalPrice && CurrencyFormatter.format(pricing.retailProductPrice)}</td>
      <td>
          <div className="cart-product-row-item">
              <Dropdown isOpen={dropDownOpen} toggle={toggleDropdown}>
                  <DropdownToggle caret>Actions</DropdownToggle>
                  <DropdownMenu>
                      {cartProduct.isConfigurable && <DropdownItem onClick={() => onEditClick(cartProduct)}
                                    disabled={shouldDisableEdits()}>
                          Edit
                      </DropdownItem>}
                      <DropdownItem onClick={() => onRemoveClick(cartProduct.id)}
                                    disabled={shouldDisableEdits()}>
                          Remove
                      </DropdownItem>
                  </DropdownMenu>
              </Dropdown>

              {showUnsavedIcon ?
                  <span id={"tooltip-" + cartProduct.id}>
                    <FontAwesomeIcon icon={faSave}/>

                    <Tooltip placement="right"
                             trigger="hover"
                             isOpen={tooltipOpen}
                             target={"tooltip-" + cartProduct.id}
                             toggle={toggleTooltip}>
                        Unsaved order changes
                    </Tooltip>
                  </span> : <></>}
          </div>
      </td>
    </tr>
  )
}