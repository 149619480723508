import { useEffect } from 'react';
import { Component } from '../component/Component';
import './InputSelectImageAndText.css';
import { ProductInputValue } from '../ProductInputValue';
import {RequiredAsterisk} from "../utils/RequiredAsterisk";
import {ComponentOption} from "../component/ComponentOption";
import {OnValueChangeParams} from "../view/ProductInputView";

interface InputSelectImageAndTextProps {
  component?: Component,
  productInputValue?: ProductInputValue,
  onValueChange: (params: OnValueChangeParams) => void
}

export const InputSelectImageAndText = ({ component, productInputValue, onValueChange }: InputSelectImageAndTextProps) => {
  const isRequired = (component !== undefined);

  useEffect(() => {
    if (!productInputValue?.isDirty && !productInputValue) {
      const defaultOption = getDefaultOption();
      if (defaultOption)
        onValueChange({ inputValue: defaultOption.value, optionId: defaultOption.id });
    }
  }, [productInputValue]);

  function getPromotionCardImage(componentOption: ComponentOption) {
    if (!component?.id) {
      return <></>
    }
    else if (componentOption.value === "Single") {
      return (
        <div className="check-input-option-container">
          <svg className="single-svg" viewBox='0 0 236 136' xmlns='http://www.w3.org/2000/svg'>
            <rect width='200' height='100' x='18' y='18' rx='7' />
          </svg>
        </div>
      )
    }
    else if (componentOption.value === "Duplicates") {
      return (
        <div className="check-input-option-container">
          <svg className="duplicates-svg" viewBox='0 0 236 136' xmlns='http://www.w3.org/2000/svg'>
            <rect width='200' height='100' x='3' y='3' rx='7' />
            <rect width='200' height='100' x='30' y='30' rx='7' />
          </svg>
        </div>
      )
    }
  }

  function getDefaultOption() {
    const defaultOptionIndex = component?.componentOptions?.findIndex(co => co.isDefault);
    if (!component?.componentOptions || defaultOptionIndex === undefined || defaultOptionIndex === -1) {
      return component?.componentOptions?.[0] ? component?.componentOptions?.[0] : null;
    }
    return component.componentOptions[defaultOptionIndex];
  }

  function isOptionSelected(value: string) {
    return productInputValue?.value === value;
  }

  function hideIfRedundant() {
    // Return hidden class if there's only one option, and its selected
    if (component?.componentOptions && component.componentOptions.length < 2 && productInputValue?.value)
      return "check-input-option-hide";
  }

  return (
    <div className={`input-select-image-and-text-container ${hideIfRedundant()}`}>
      <h4 className='product-input-view-label'>
        {component?.label}
        {isRequired ? <RequiredAsterisk></RequiredAsterisk> : <></>}
      </h4>

      <div className="component-option-container">
        {component?.componentOptions?.map((componentOption) => {
          return (
            <div key={componentOption.id} 
                  className={`component-option-content ${isOptionSelected(componentOption.value) ? 'selected-image-and-text' : ''}`} 
                  onClick={(e) => onValueChange({ inputValue: componentOption.value, optionId: componentOption.id })}>
                {getPromotionCardImage(componentOption)}

                <div className="component-option-text">{componentOption.description}</div>
            </div>
          )

        })}
      </div>

    </div>
  );
}
