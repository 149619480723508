
import './Breadcrumb.css';
import {Link} from "react-router-dom";
import {NavLink} from "reactstrap";
import {useGetParentSiteCategoriesQuery} from "../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";


interface BreadcrumbProps {
    currentPage: string,
    siteCategoryId?: number,
}
export const Breadcrumb = ({ currentPage, siteCategoryId}: BreadcrumbProps) => {
    const { data: parentSiteCategories } = useGetParentSiteCategoriesQuery(siteCategoryId ?? skipToken);

    if (!parentSiteCategories)
        return <div className="breadcrumb-spacer"></div>

    return (
        <div className='breadcrumbs-container'>
            <span className="breadcrumbs-non-active-link">
                <Link to="/"><p>Home</p></Link>
            </span>

            { parentSiteCategories?.filter(pc => pc.name !== currentPage && pc.urlName)?.map((pc) => {
                    return (
                        <span key={pc.id} className="breadcrumbs-parent-link-container">
                            <span className="breadcrumbs-separator-slash"> / </span>
        
                            <NavLink className="breadcrumbs-parent-category" tag={Link} to={`/category/${pc.urlName}`}>
                                <p>{pc.name}</p>
                            </NavLink>
                        </span>
                    )
                })
            }
            
            
            <span className="breadcrumbs-parent-link-container d-md-block d-none">
                <span className="breadcrumbs-separator-slash"> / </span>
                <span className="breadcrumbs-active">{currentPage}</span>
            </span>
        </div>
    )
}
