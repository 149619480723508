import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ReactComponent as Checkmark} from '../../assets/icons/checkmark.svg';
import {useAppSelector} from "../../app/hooks";
import {getAccessToken, selectCurrentConsumerUser} from "../user/login/AuthenticationSlice";
import {ProfileTab} from "../user/profile/ProfileTab";
import {useCheckOrderCreationValidationQuery} from "../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {LoadingSpinner} from "../input/utils/LoadingSpinner";
import {selectCartId} from "../cart/CartSlice";
import ScrollToTop from "../helpers/ScrollToTop";
import useConsumerDownload from "../../download/use-consumer-download";
import {Button, Spinner} from "reactstrap";
import {useState} from "react";
import {selectLatestConsumerOrderId} from "./OrderSlice";

export const OrderConfirmation = () => {
    const order = useAppSelector(selectLatestConsumerOrderId);
    const cartId = useAppSelector(selectCartId);
    const consumer = useAppSelector(selectCurrentConsumerUser);
    const accessToken = useAppSelector(getAccessToken);
    const {t} = useTranslation();
    const {data: orderValidationCheck, isLoading} =
        useCheckOrderCreationValidationQuery(order ? {
            orderId: order.orderId,
            cartId
        } : skipToken);

    const SignedInActions = () => (<>
        <Link className='btn btn-outline-primary mx-2' to={`/profile/${ProfileTab.OrderHistory}`}>View orders</Link>
        <Link className='btn btn-outline-primary mx-2' to='/'>Continue shopping</Link>
    </>)

    const AnonymousActions = () => (<div className='d-flex flex-column align-items-center'>
        <p>{t('orderConfirmation.trackOrder')}</p>
        <Link className='btn btn-outline-primary mx-2' to='/sign-up'>Create an account</Link>
        <p className='text-center my-2'>or</p>
        <Link to='/'>Continue shopping</Link>
    </div>)

    const GetBodyContent = () => {
        if (isLoading || !order)
            return (
                <>
                    <LoadingSpinner key="m" size="sm"/>
                    <p className='fs-3'>{t('orderConfirmation.checking')}</p>
                    <div className='text-muted'>Order #: {order?.orderId}</div>
                    <div className='my-2'>
                        {consumer ? <SignedInActions/> : <AnonymousActions/>}
                    </div>
                </>
            )

        if (!orderValidationCheck?.isRecentAndValid)
            return (
                <>
                    <div className='text-muted'>Order #: {order?.orderId}</div>
                    <div>{t('orderConfirmation.notFound')}</div>
                    <div className='my-2'>
                        {consumer ? <SignedInActions/> : <AnonymousActions/>}
                    </div>
                </>
            )

        if (orderValidationCheck.isPartiallyComplete)
            return (
                <>
                    <Checkmark/>
                    <p className='fs-3'>{t('orderConfirmation.thankYou')}</p>
                    <div className='text-muted'>Order #: {order.orderId}</div>
                    <div>{t('orderConfirmation.partialComplete')}</div>
                    <div>{t('orderConfirmation.whenShipped')}</div>
                    <div className='my-2'>
                        {consumer ? <SignedInActions/> : <AnonymousActions/>}
                    </div>
                </>
            )

        return (
            <>
                <Checkmark/>
                <p className='fs-3'>{t('orderConfirmation.thankYou')}</p>
                <div className='text-muted'>Order #: {order.orderId}</div>
                <div>{t('orderConfirmation.orderReceived')}</div>
                <div>{t('orderConfirmation.whenShipped')}</div>
                <div className='my-2'>
                    {consumer ? <SignedInActions/> : <AnonymousActions/>}
                    {order && <DownloadButton orderInvoiceId={order.orderInvoiceGuid}
                                              orderId={order.orderId}
                                     accessToken={accessToken!}/>
                    }
                </div>
            </>
        )
    }

    return (
        <div className='py-5 px-5 d-flex flex-column align-items-center text-center'>
            <ScrollToTop></ScrollToTop>
            {GetBodyContent()}
        </div>
    );
}

function DownloadButton(props: { orderInvoiceId: string, accessToken: string, orderId: number }) {
    const downloadFile = useConsumerDownload();
    const [downloading, setDownloading] = useState(false);
    
    const onClick = async () => {
        setDownloading(true);
        await downloadFile({
            url: `/api/orders/${props.orderInvoiceId}/invoice`,
            fileName: `invoice-order-${props.orderId}.pdf`,
        });
        setDownloading(false);
    }

    return (
        <Button className="mx-2 my-3"
                outline
                color="primary"
                onClick={onClick}
        >
            Download Invoice {downloading && <Spinner size={'sm'} />}
        </Button>
    )
}