
import './CheckoutSignIn.css';
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";

interface CheckoutSignInProps {
    redirectUrl?: string
}

export const CheckoutSignIn = ({redirectUrl}: CheckoutSignInProps) => {
    const { t } = useTranslation();
    const redirectUrlParam = redirectUrl ? `?redirectTo=${redirectUrl}` : '';

    return (
        <div className="checkout-sign-in-container">
            <div className="checkout-sign-in-text">
                <h2 className="checkout-sign-in-title">
                    {t('checkoutSignIn.title')}
                </h2>
                <p className="checkout-sign-in-body">
                    {t('checkoutSignIn.body')}
                </p>
            </div>
            <span className="checkout-sign-in-action-button-container">
                <Link className="checkout-sign-in-action-button text-dark" to={`/login${redirectUrlParam}`}><h6>Sign In</h6></Link>
                <Link className="checkout-create-an-account-action-button text-light btn-secondary" to='/sign-up'><h6>Create an account</h6></Link>
            </span>
        </div>
    )
}
