import {ActionButton, GridAction, PagingInfo, SortButton, SortState} from "../../../utils/Grid";
import React, {useEffect} from "react";
import {useElementIsOnScreen} from "../../../app/hooks";
import {Table} from "reactstrap";
import {InputType} from "reactstrap/types/lib/Input";
import {SelectOption} from "../../input/MultiSelect";
import {get as _get} from 'lodash';
import './Grid.css';
import {LoadingSpinner} from "../../input/utils/LoadingSpinner";

export interface HasId { id: number }
export type CellDisplayFunc<T> = (value?: T[keyof T]) => string | JSX.Element;

export class ColumnConfig<T> {
  field: string
  serverColumn?: string
  display: string
  type: InputType | 'multi-select' | 'toggle' | 'boolean-tri-state' | 'single-date'
  isSearchCriteria: boolean
  choices?: SelectOption[]
  isSortable?: boolean
  isHidden?: boolean
  validation?: (value?: T[keyof T]) => string | undefined
  cellDisplay: CellDisplayFunc<T>
  
  constructor(arg: {
    // don't be afraid of the types here, it just looks up the types
    // of each property so that they stay consistent with the REAL
    // types that are defined above :)
    
    // Name of the field on T, use dot-notation for nested fields, thank you, lodash.get (as _get)
    field: ColumnConfig<T>['field'],
    
    // Name for server-side sorting
    serverColumn?: ColumnConfig<T>['serverColumn'],
    
    // String to display for field name, default: `field.toString()`
    display?: ColumnConfig<T>['display'],
    
    // InputType of the field, default: `'text'`
    type?: ColumnConfig<T>['type'],
    
    // Whether or not to show this field in the search criteria panel, default: `true`
    isSearchCriteria?: ColumnConfig<T>['isSearchCriteria'],
    
    // (optional) Choices for multi-select & regular select criteria inputs
    choices?: ColumnConfig<T>['choices'],
    
    // (optional) Validation function that returns a string to display given a provided value in the search criteria input
    validation?: ColumnConfig<T>['validation']
    
    // Function to display value for this column in the grid, default: `value as string`
    cellDisplay?: ColumnConfig<T>['cellDisplay']

    // (optional) Whether a column short not have sort control, default: true
    isSortable?: ColumnConfig<T>['isSortable']

    // (optional) Whether a column should appear, default: false
    isHidden?: ColumnConfig<T>['isHidden']
  }) {
    this.field = arg.field;
    this.serverColumn = arg.serverColumn;
    this.display = arg.display ?? arg.field.toString();
    this.type = arg.type ?? 'text';
    this.isSearchCriteria = arg.isSearchCriteria ?? true;
    this.cellDisplay = arg.cellDisplay ?? function (v) { return v as string; }
    this.choices = arg.choices;
    this.validation = arg.validation;
    this.isSortable = arg.isSortable ?? true;
    this.type = arg.type ?? 'text';
    this.isHidden = arg.isHidden ?? false;
  }
}

export class ConfigSection<T> {
  name?: string
  columns: ColumnConfig<T>[] = []
}

export interface GridConfig<T> {
  configSections: ConfigSection<T>[]
  actions?: GridAction<T>[]
}

interface GridProps<T> {
  columns: ColumnConfig<T>[] 
  records?: T[]
  moreRecordsAvailable?: boolean
  sort: SortState<T>
  onSortChanged: (newSort: SortState<T>) => void
  actions?: GridAction<T>[]
  onShouldFetchMore?: (paging: PagingInfo) => void
  onShouldDisplayMore?: () => void
  noRecordsMessage?: string
  isLoading?: boolean
  onRowClick?: (record: T) => void
}

export function Grid<T>({
    columns,
    records,
    moreRecordsAvailable,
    sort,
    onSortChanged,
    actions,
    onShouldFetchMore,
    onShouldDisplayMore,
    noRecordsMessage = "Sorry, no records found. Change your search and try again.",
    isLoading = false,
    onRowClick
  }: GridProps<T>) {
  const lastRecord = records ? records[records.length - 1] : undefined;
  const [ref, isVisible] = useElementIsOnScreen([lastRecord]);
  const rowClasses = onRowClick ? 'grid-row-is-clickable' : '';
  
  useEffect(() => {
    if (isVisible && (moreRecordsAvailable ?? false)) {
      if (onShouldFetchMore && recordHasId(lastRecord)) {
        onShouldFetchMore({
          lastId: lastRecord?.id,
          lastValue: _get(lastRecord, sort.column)?.toString(),
          sortKey: sort?.serverColumn ?? sort?.column.toString(),
          sortDirection: sort?.direction,
        });
      }
      else if (onShouldDisplayMore) {
        onShouldDisplayMore();
      }
    }
  }, [isVisible, lastRecord, moreRecordsAvailable, sort, onShouldFetchMore]);

  if (isLoading && (!records || records.length < 1))
    return <LoadingSpinner size="sm" />
  
  if (!records?.length)
    return <span>{noRecordsMessage}</span>


  const getRowRef = (index: number) =>
    records !== undefined && index === (records.length - 5) ? ref : null;


  return (
    <div className="grid-table-container">
      <Table striped bordered hover className="mb-0">
        <thead>
        <tr>
          { columns.filter(c => !c.isHidden).map(col => (
            <th key={col.field + col.display}>
              { col.isSortable ?
                <SortButton column={col.field as keyof T} serverColumn={col.serverColumn ?? col.field} sortState={sort} sortStateChanged={onSortChanged}>{col.display}</SortButton> :
                <p className="grid-column-header-title-text">{col.display}</p>
              }
            </th>
          ))}
          { actions?.length && <th>Actions</th> }
        </tr>
        </thead>
        <tbody>
        { records?.map((r, ix) => (
            <tr key={getRecordKey(r)} ref={getRowRef(ix)} onClick={() => onRowClick?.(r)} className={rowClasses}>
              {columns.filter(c => !c.isHidden).map(col => <td key={col.field + col.display} className='grid-table-td-display'>{col.cellDisplay(_get(r, col.field))}</td>)}
              { actions?.length && 
                <td>
                  {actions.map((action, i) => <ActionButton key={action.name} action={action} entity={r}/>)}
                </td>
              }
            </tr>
          )
        )}
        </tbody>
      </Table>
    </div>
  );
}

const recordHasId = <T,>(record: T): record is  T & { id: number} =>
  record && typeof(record) === 'object' &&
  'id' in record && typeof(record.id) === 'number'

const getRecordKey = (record: any) =>
  record.key ?? record.id ?? record.name ?? record.timeCreated ?? record.orderId ?? record.orderNumber ?? `${record.orderDate}-${record.brand}-${record.orderStatus}`