
import './BankDisclaimer.css';
import {useTranslation} from "react-i18next";


export const BankDisclaimer = () => {
    const { t } = useTranslation();

    return (
        <span className="variant-disclaimer-container">
            <span className="variant-disclaimer-title">
                {t('bank.disclaimer.title')}
            </span>
            {t('bank.disclaimer.body')}
        </span>
    )
}
