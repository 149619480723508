import { useNavigate, useParams } from "react-router-dom";
import {
  useGetOrderOverviewQuery,
  useSendOrderConfirmationEmailMutation,
  useSendOrderTrackingEmailMutation,
  useUpdateOrderProductMutation,
  useGetOrderProductsQuery,
  useCreateOrderEditCartMutation, useGetVendorOrderProductStatusQuery
} from "../../../app/apiSlice";
import React, {ChangeEvent, useState} from "react";
import {
  Button, Col, DropdownItem, DropdownMenu,
  DropdownToggle, FormFeedback, FormGroup, Input,
  Label, Modal, ModalBody, ModalFooter, ModalHeader,
  Nav, NavItem, NavLink, Row, UncontrolledDropdown
} from "reactstrap";
import { MicrTab } from "./tabs/MicrTab";
import { OrderDetailsTab } from "./tabs/details/OrderDetailsTab";
import { RefundTab } from "./tabs/refund/details/RefundTab";
import { RelatedOrdersTab } from "./tabs/RelatedOrdersTab";
import { NotesTab } from "./tabs/NotesTab";
import { HoldInfoTab } from "./tabs/HoldInfoTab";
import { HistoryTab } from "./tabs/history/HistoryTab";
import { DateTime } from "luxon";
import { ChangeOrderStatus } from "./ChangeOrderStatus";
import './OrderDetails.css';
import { useAppDispatch, useClaims, useValidation } from "../../../app/hooks";
import {VendorStatusName} from "../vendor/VendorOrderProductStatus";
import { EDITING_ROLES } from "../../../utils/Roles";
import { showInformationModal } from "../../modal/ModalSlice";
import {OrderStatuses} from "../../order-status/OrderStatus";
import {Order} from "../../order/Order";
import {OrderOriginEnum} from "./SaveOrderVm";

enum OrderDetailsNavTab {
  OrderDetails = 'Order Details',
  Micr = 'MICR',
  Refund = 'Refund',
  RelatedOrders = 'Related Orders',
  Notes = 'Notes',
  HoldInfo = 'Hold Info',
  History = 'History',
}

export const OrderDetails = () => {
  const navigate = useNavigate();
  let {orderId, tab} = useParams<{ orderId: string, tab?: OrderDetailsNavTab}>();

  const numOrderId = Number.parseInt(orderId!);
  const {data: order} = useGetOrderOverviewQuery(numOrderId);
  const { data: orderProducts } = useGetOrderProductsQuery(numOrderId);
  const {data: vendorProductStatusOverview} = useGetVendorOrderProductStatusQuery(numOrderId);
  const [ sendConfirmationEmail ] = useSendOrderConfirmationEmailMutation();
  const [ createOrderCart ] = useCreateOrderEditCartMutation();
  const [ sendTrackingEmail ] = useSendOrderTrackingEmailMutation();
  const [ updateOrderProduct ] = useUpdateOrderProductMutation();
  const { hasPermission } = useClaims();
  const [ notes, setNotes ] = useState<string>('');
  const locked = [
      OrderStatuses.Manufacturing.toString(),
      OrderStatuses.Complete.toString(),
      OrderStatuses.Cancelled.toString(),
      OrderStatuses.CancelledDNS.toString()
  ].includes(order?.status ?? '');
  const [ isOpen, setIsOpen ] = useState(false);
  const [ openModal, setOpenModal ] = useState<'Do Not Ship' | 'Do Not Re-Order' | ''>('');
  const toggle = () => setIsOpen(!isOpen);
  const dispatch = useAppDispatch();
  const shouldDisableReorder = !orderProducts?.some(op => !op.doNotReorder) || !hasPermission(EDITING_ROLES);


  const required = (name: string) => (val?: string) => !val ? `${name} are required.` : undefined;
  const validation = useValidation({
    notes: required('Notes')
  });

  const updateNotes = (e: ChangeEvent<HTMLInputElement>) => {
    validation.setValue('notes', e.target.value);
    setNotes(e.target.value);
  }
  
  const openNotesModal = (modalName: 'Do Not Ship' | 'Do Not Re-Order' | '') => {
    setOpenModal(modalName);
    setIsOpen(true);
  }
  
  const closeNotesModal = () => {
    setOpenModal('');
    setIsOpen(false);
    setNotes('');
    validation.setValue('notes', '');
  }
  
  const onSendConfirmationClick = async () => {
    await sendConfirmationEmail(orderId);
  }

  const onSendTrackingClick = async () => {
    await sendTrackingEmail(orderId);
  }

  const onSetClearDoNoReorderClicked = async() => {
    if (!orderProducts || !validation.allValid()) {
      return;
    }
    // If all order products are set do not ship, then toggle all to true. else toggle all to false
    await updateOrderProduct({orderProductIds: orderProducts.map(op => op.id), doNotReorder: orderProducts.some(op => !op.doNotReorder), notes: notes})
    closeNotesModal();
  }

  const onSetClearDoNoShipClicked = async() => {
    if (!orderProducts || !validation.allValid()) {
      return;
    }
    // If all order products are set do not ship, then toggle all to true. else toggle all to false
    await updateOrderProduct({orderProductIds: orderProducts.map(op => op.id), doNotShip: orderProducts.some(op => !op.doNotShip), notes: notes})
    closeNotesModal();
  }

  const onReorderClicked = async () => {
    try {
      const cartId = await createOrderCart({orderId: numOrderId, isReorder: true}).unwrap();
      navigate(`/admin/order/new?cartId=${cartId}`);
    } catch (e:any) {
      dispatch(showInformationModal({
        title: 'Error Reordering',
        text: 'Sorry! We were unable to start this reorder!'
      }));
      console.error(JSON.stringify(e));
    }
  }

  const shouldDisableDoNotShip = () => {
    return !!(vendorProductStatusOverview && vendorProductStatusOverview.orderProductStatuses
        .filter(ops => ![VendorStatusName.Shipped.toString()]
            .includes(ops.vendorOrderProductStatusName)).length < 1);
  }
  
  const getOrderStorefront = (order: Order) => {
    return (
      <div>
        {order.siteName} {order.orderOrigin === OrderOriginEnum.MailIn ? (
          <span>- {order.orderOrigin}</span>
      ) : <></>}
      </div>
    )
  }

  return (
    <div className="d-flex flex-column px-4">
      <div className="d-flex justify-content-between">
        <div>
          <h2>Order #{orderId}</h2>
          {vendorProductStatusOverview && <div>{vendorProductStatusOverview.vendorName} Order Id: {vendorProductStatusOverview.vendorOrderId}</div>}
        </div>
        <UncontrolledDropdown>
          <DropdownToggle caret>Quick Actions</DropdownToggle>
          <DropdownMenu>
            <DropdownItem disabled={shouldDisableReorder} onClick={() => onReorderClicked()}>Re-Order</DropdownItem>
            <DropdownItem onClick={() => openNotesModal('Do Not Re-Order')}>Set/Clear Do Not Re-Order</DropdownItem>
            <DropdownItem disabled={shouldDisableDoNotShip()} onClick={() => openNotesModal('Do Not Ship')}>Set/Clear Do Not Ship</DropdownItem>
            <DropdownItem onClick={() => onSendConfirmationClick()}>Send Confirmation</DropdownItem>
            {order?.status === 'Complete' && <DropdownItem onClick={() => onSendTrackingClick()}>Send Tracking</DropdownItem>}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>

      { order && // don't show until order ready 
        <Row className='my-2'>
          <Col>
              <div>{getOrderStorefront(order)}</div>
              <div>Status: {order.status} {!locked && hasPermission(EDITING_ROLES) && <ChangeOrderStatus orderId={numOrderId}/>}</div>
          </Col>
          <Col>
            <div>Customer: {order.consumerName}, {order.consumerEmail}</div>
          </Col>
          <Col>Created: {DateTime.fromISO(order.timeCreatedLocal).toLocaleString(DateTime.DATETIME_SHORT)} by {order.createdBy}</Col>
          <Col>Updated: {DateTime.fromISO(order.timeLastUpdatedLocal).toLocaleString(DateTime.DATETIME_SHORT)} by {order.updatedBy}</Col>
        </Row>
      }
      
      <Nav tabs className="mb-3">
        {
          Object.values(OrderDetailsNavTab).map((t, index) => (
            <NavItem key={t} className="order-details-nav-item">
              <NavLink active={t === tab || (!tab && index === 0)} onClick={() => navigate(`/admin/order/${orderId}/${t}`)}>{t}</NavLink>
            </NavItem>
          ))
        }
      </Nav>
      
      {/*Find Tab To Show*/}
      {order && (tab === OrderDetailsNavTab.OrderDetails || !tab) && <OrderDetailsTab order={order} locked={locked}/>}
      {tab === OrderDetailsNavTab.Micr && <MicrTab orderId={numOrderId} orderProducts={orderProducts}/>}
      {tab === OrderDetailsNavTab.Refund && <RefundTab orderId={numOrderId} />}
      {tab === OrderDetailsNavTab.RelatedOrders && <RelatedOrdersTab orderId={numOrderId}/>}
      {tab === OrderDetailsNavTab.Notes && <NotesTab orderId={numOrderId}/>}
      {tab === OrderDetailsNavTab.HoldInfo && <HoldInfoTab orderId={numOrderId}/>}
      {tab === OrderDetailsNavTab.History && <HistoryTab orderId={numOrderId}/>}

      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{openModal}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for='Notes'>Notes</Label>
            <Input required id='notes' name='notes' type='textarea' value={notes ?? ''} invalid={validation?.getError('notes') !== undefined} onChange={(e) => updateNotes(e)} />
            <FormFeedback>{ validation?.getError('notes') }</FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button id="SubmitButton" onClick={openModal === 'Do Not Ship' ? onSetClearDoNoShipClicked : onSetClearDoNoReorderClicked} color='primary'>Update</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}