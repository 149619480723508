import {PaymentDetails} from "./PaymentDetails";
import {Address} from "../input/address/Address";

export interface CreditCardPaymentDetails extends PaymentDetails {
    cardNumber: string,
    expirationDateString: string,
    cvc: string,
    billingAddress: Address,
    firstName: string,
    lastName: string
}

export enum CreditCardEntryFields {
    CardNumber,
    ExpirationYear,
    ExpirationMonth,
    CVC,
    FirstName,
    LastName
}