
import {Button, Card, CardBody, CardHeader, Spinner, Table} from "reactstrap";
import {getPrettyAddress, Utils} from "../../../../utils/Utils";
import "./ConsumerControlListValues.css";
import {ControlListValue} from "../../controlList/ControlListValue";
import {ControlListGroup, ControlListValueType} from "../../controlList/ControlListGroup";
import React, {useState} from "react";
import {useGetConsumerControlListValuesQuery} from "../../../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {Address} from "../../../input/address/Address";
import {ControlListValueEdit} from "../../controlList/edit/ControlListValueEdit";

interface ConsumerControlListValueProps {
    consumerId: number
}

interface GroupedControlListValues {
    group: ControlListGroup,
    controlListValues: ControlListValue[]
}

export const ConsumerControlListValues = ({consumerId}: ConsumerControlListValueProps) => {

    const [ showEditModal, setShowEditModal ] = useState(false);
    const [ focusedControlListValue, setFocusedControlListValue ] = useState<ControlListValue>();

    const { data: consumerControlListValues, isLoading: isFetchingConsumerControlListValues } = useGetConsumerControlListValuesQuery(consumerId ?? skipToken);
    const groupedControlListValues = [...consumerControlListValues?.controlListValues ?? []].sort(Utils.sortBy('controlListGroupId', 'asc'))
        .reduce((accumulator: GroupedControlListValues[], currentValue) => {
        const index = accumulator.findIndex(item => item.group.id === currentValue.controlListGroup.id);
        if (index === -1) {
            accumulator.push({group: currentValue.controlListGroup, controlListValues: [currentValue]});
        } else {
            accumulator[index].controlListValues.push(currentValue);
        }
        return accumulator;
    }, []);

    const toggleModal = (controlListValue?: ControlListValue) => {
        setFocusedControlListValue(controlListValue);
        setShowEditModal(old => !old);
    }

    const getStringDisplayValue = (val?: string) => {
        if (!val)
            return "";
        try {
            const address: Address = JSON.parse(val);
            if (address.street)
                return getPrettyAddress(address);
            return val;
        }
        catch {
            return val;
        }
    }

    return (
        <div>

            <div className="consumer-control-list-value-header">

                <Button color="primary" onClick={() => toggleModal()}>Add Control List Value</Button>

                {groupedControlListValues.length === 0 && !isFetchingConsumerControlListValues &&
                  <div className="consumer-control-list-value-no-matches">
                    This user does not match any active control list filters.
                  </div>
                }
                {isFetchingConsumerControlListValues && <div className="consumer-control-list-value-spinner-container"><Spinner></Spinner></div>}

            </div>

            {groupedControlListValues.map(gclv => {
                return (
                    <Card key={gclv.group.id}>
                        <CardHeader>
                            Grouping: {gclv.group.name}
                        </CardHeader>
                        <CardBody>
                            <Table>
                                <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>{gclv.group.stringValueOneLabel}</th>
                                    {gclv.group.valueTwoType !== ControlListValueType.None ? <th>{gclv.group.stringValueTwoLabel}</th> : <th></th>}
                                    <th>Description</th>
                                </tr>
                                </thead>
                                <tbody>
                                { gclv.controlListValues?.map(clv => (
                                    <tr key={clv.id} onClick={() => toggleModal(clv)} className="consumer-control-list-value-row">
                                        <td>{clv.controlListAction.name}</td>
                                        <td>{clv.userId ? 'By Account Id' : getStringDisplayValue(clv.stringValueOne)}</td>
                                        {clv.controlListGroup.valueTwoType !== ControlListValueType.None ? <td>{getStringDisplayValue(clv.stringValueTwo)}</td> : <td></td>}
                                        <td>{clv.description}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                )
            })}

            <ControlListValueEdit isOpen={showEditModal}
                                  onIsOpenChange={() => toggleModal(undefined)}
                                  requestedControlListValue={focusedControlListValue}
                                  consumerId={consumerId}/>
        </div>
    )
}