
import './HoldNew.css';
import {
    Alert,
    Button,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import React, {useEffect, useState} from "react";
import {
    useCreateHoldFlagMutation,
    useGetHoldFlagReasonsQuery,
    useGetHoldFlagStatusesQuery
} from "../../../../app/apiSlice";
import {NewHoldFlagRequest} from "../HoldsFlag";
import {getErrorMessage} from "../../../../utils/Utils";

interface HoldNewProps {
    orderId: number,
    isOpen: boolean,
    toggleModal: (shouldRefresh?: boolean) => void,
}
export const HoldNew = ({orderId, isOpen, toggleModal}: HoldNewProps) => {

    const { data: holdsFlagStatuses } = useGetHoldFlagStatusesQuery();
    const { data: holdsFlagReasons } = useGetHoldFlagReasonsQuery();
    const [ createHoldFlag, {isLoading: isCreating}  ] = useCreateHoldFlagMutation();
    const [ statusId, setStatusId ] = useState<number | undefined>();
    const [ reasonId, setReasonId ] = useState<number | undefined>();
    const [ note, setNote ] = useState<string | undefined>();
    const [ errorMessage, setErrorMessage ] = useState<string | undefined>(undefined);

    const modalNavigation = (
        <div>
            <button className='btn-close' onClick={() => toggleModal()}/>
        </div>
    );

    useEffect(() => {
        const firstStatus = holdsFlagStatuses?.find(() => true);
        const firstReason = holdsFlagReasons?.find(() => true);

        if (firstStatus && firstReason) {
            setReasonId(firstReason.id);
            setStatusId(firstStatus.id);
        }

    }, [holdsFlagStatuses, holdsFlagReasons]);

    const onReasonChanged = (reasonValue: string) => {
        const newReasonId = parseInt(reasonValue);
        setReasonId(newReasonId);
    }
    const onStatusChanged = (statusValue: string) => {
        const newStatusId = parseInt(statusValue);
        setStatusId(newStatusId);
    }

    const onNoteChanged = (noteValue: string) => {
        setNote(noteValue);
    }

    const onCreateClicked = async () => {
        const newHoldFlagRequest = ({orderId, statusId, reasonId, note} as NewHoldFlagRequest);
        if (!newHoldFlagRequest)
            return;
        try {
            await createHoldFlag(newHoldFlagRequest).unwrap();
            toggleModal(true);
        }
        catch (e: any) {
            setErrorMessage(getErrorMessage(e));
        }
    }

    const shouldDisableCreate = () => {
        return isCreating;
    }

    const getHoldFlagDetailsTable = () => {
        return (
            <>
                <FormGroup>
                    <Label for='Reason'>Reason</Label>
                    <Input id='Reason' name='reason'  type="select" onChange={(e) => onReasonChanged(e.target.value)} value={reasonId}>
                        {holdsFlagReasons?.map((hfr) => {
                            return (
                                <option key={hfr.id} value={hfr.id}>
                                    {hfr.name}
                                </option>
                            );
                        })}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label for='Status'>Status</Label>
                    <Input id='Status' name='status'  type="select" onChange={(e) => onStatusChanged(e.target.value)} value={statusId}>
                        {holdsFlagStatuses?.map((hfs) => {
                            return (
                                <option key={hfs.id} value={hfs.id}>
                                    {hfs.name}
                                </option>
                            );
                        })}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label for='Notes'>Notes</Label>
                    <Input id='Notes' name='notes'  type="textarea" rows="4" onChange={(e) => onNoteChanged(e.target.value)} value={note} />
                </FormGroup>
            </>
        );
    }

    return (
        <Modal isOpen={isOpen} toggle={() => toggleModal()} size='xl'>
            <ModalHeader toggle={() => toggleModal()} close={modalNavigation}>New Hold Flag</ModalHeader>
            <ModalBody>
                {getHoldFlagDetailsTable()}
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                <Row>
                    <div className='hold-new-button-container'>
                        <Button color='primary' onClick={onCreateClicked} disabled={shouldDisableCreate()}>
                            {isCreating && <Spinner></Spinner>}
                            Create Hold Flag
                        </Button>
                        <Button onClick={() => toggleModal()}>Cancel</Button>
                    </div>
                </Row>
            </ModalBody>
        </Modal>
    )
}