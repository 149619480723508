import { SliceState } from "../../../utils/Utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAll, CriteriaAndPaging } from "./ConsumerAPI";
import { RootState } from "../../../app/store";
import {ConsumerDetails} from "./Consumer";

export interface ConsumerState {
    consumersDetails?: ConsumerDetails[];
    status: SliceState;
    canGetMore: boolean;
}

const initialState: ConsumerState = {
    consumersDetails: undefined,
    status: 'idle',
    canGetMore: true,
};

export const fetchConsumerDetails = createAsyncThunk(
    'consumers/fetchAll',
    async (query: CriteriaAndPaging) =>
        (await fetchAll(query)).data
);

export const fetchMoreConsumerDetails = createAsyncThunk(
    'consumers/fetchMore',
    async (query: CriteriaAndPaging) =>
        (await fetchAll(query)).data
);

export const consumerSlice = createSlice({
    name: 'consumer',
    initialState,
    reducers: {
        changeSearch: state => {
            state.status = 'idle';
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchConsumerDetails.pending, state => {
                state.status = 'loading'
            })
            .addCase(fetchConsumerDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.consumersDetails = action.payload;
                state.canGetMore = action.payload.length === 50;
            })
            .addCase(fetchConsumerDetails.rejected, state => {
                state.status = 'failed';
                state.canGetMore = false;
            })

            // fetch more
            .addCase(fetchMoreConsumerDetails.pending, state => {
                state.status = 'loading'
            })
            .addCase(fetchMoreConsumerDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (action.payload.length) state.consumersDetails = state.consumersDetails?.concat(action.payload);
                state.canGetMore = action.payload.length === 50;
            })
            .addCase(fetchMoreConsumerDetails.rejected, state => {
                state.status = 'failed';
                state.canGetMore = false;
            });
    }
});

// Fetch consumers
export const selectConsumerDetails = (state: RootState) => state.consumers.consumersDetails;
export const canFetchMoreConsumerDetails = (state: RootState) => state.consumers.canGetMore;
export const isFetchingConsumerDetails = (state: RootState) => state.consumers.status === 'loading' ||
    state.consumers.status === 'idle';
const { actions, reducer } = consumerSlice;
export const { changeSearch } = actions;
export default reducer;