import { skipToken } from "@reduxjs/toolkit/dist/query"
import { useGetAllConsumersQuery } from "../../../../app/apiSlice"
import { Consumer } from "../../customer/Consumer"
import { Input } from "reactstrap"
import { Typeahead } from "react-bootstrap-typeahead"
import { useState } from "react"

interface ControlListConsumerInputProps {
    id: string
    value?: number
    onChange: (selectedUserId?: number) => void
    forceConsumer?: Consumer
    invalid: boolean
}
export const ControlListConsumerInput = ({id, value, onChange, forceConsumer, invalid}: ControlListConsumerInputProps) => {
    // get list of consumers to display only if we do not have a forced consumer already selected
    const { data: consumers } = useGetAllConsumersQuery(forceConsumer ? skipToken : undefined);

    const [ showTypeaheadOptions, setShowTypeaheadOptions ] = useState(false);

    if (forceConsumer) {
        // just display a disabled input showing this consumer's name (used for editing control list value on consumer edit page)
        return <Input id={id} disabled value={`${forceConsumer.firstName} ${forceConsumer.lastName}`}/>
    }

    const onSelect = (selectedOptions?: Consumer[]) => {
        setShowTypeaheadOptions(false);
        onChange(selectedOptions?.[0]?.userId);
    }

    const selectedValue = consumers?.find(c => c.userId === value);
    return <Typeahead clearButton
                      maxResults={25}
                      paginate
                      id={id}
                      isInvalid={invalid}
                      options={consumers ?? []}
                      labelKey={opt => typeof opt === 'string' ? opt : `${opt.email} - ${opt.firstName} ${opt.lastName} - ${opt.siteName}`}
                      onChange={options => onSelect(options as Consumer[])}
                      placeholder='Select a consumer...'
                      open={showTypeaheadOptions}
                      onFocus={() => setShowTypeaheadOptions(true)}
                      onBlur={() => setShowTypeaheadOptions(false)}
                      selected={selectedValue ? [selectedValue] : []}
                      style={{ maxHeight: '38px' }}
                      />
}