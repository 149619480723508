import { OrderStatusReport } from "./OrderStatusReport";
import { ColumnConfig } from "../../admin/shared/Grid";
import { reportCol, reportDateToString, reportValToOrderLink, ReportView } from "../ReportView";
import { useGetOrderStatusReportReportQuery } from "../../../app/apiSlice";

export const OrderStatusReportReport = () => {
  const filterFunc = (lowerSearch: string, records: OrderStatusReport[]) =>
    records.filter(r =>
      r.orderNumber.toString().includes(lowerSearch) ||
      r.brand.toLowerCase().includes(lowerSearch) ||
      r.aba.includes(lowerSearch) ||
      r.accountNumber.includes(lowerSearch) ||
      r.orderStatus.toLowerCase().includes(lowerSearch) ||
      `${r.firstNameShipping} ${r.lastNameShipping}`.toLowerCase().includes(lowerSearch) ||
      r.email.toLowerCase().includes(lowerSearch) ||
      r.holdIssue.toLowerCase().includes(lowerSearch) ||
      r.createdBy.toLowerCase().includes(lowerSearch) ||
      r.lastUpdatedBy.toLowerCase().includes(lowerSearch)
    );
  
  const columns: ColumnConfig<OrderStatusReport>[] = [
    reportCol('orderNumber', 'Order #', reportValToOrderLink),
    reportCol('orderDate', 'Order Date', reportDateToString),
    reportCol('brand', 'Brand'),
    reportCol('aba', 'ABA'),
    reportCol('accountNumber', 'Account #'),
    reportCol('orderStatus', 'Order Status'),
    reportCol('firstNameShipping', 'Ship F Name'),
    reportCol('lastNameShipping', 'Ship L Name'),
    reportCol('email', 'Email'),
    reportCol('holdIssue', 'Hold Issue'),
    reportCol('createdBy', 'Created By'),
    reportCol('createdDate', 'Created Date', reportDateToString),
    reportCol('lastUpdatedBy', 'Last Updated By'),
    reportCol('lastUpdatedDate', 'Last Updated Date', reportDateToString),
  ];
  
  return <ReportView
    title='Order Status'
    query={useGetOrderStatusReportReportQuery}
    columns={columns}
    initialSort={{ column: 'orderDate', direction: 'asc'}}
    filterFunc={filterFunc}
    downloadPath='/api/Reports/OrderStatusReport'
    downloadName='CW_Order_Status'/>
}