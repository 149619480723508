import {Ltv} from "./Ltv";
import {ColumnConfig} from "../../admin/shared/Grid";
import {reportCol, reportDateToString, reportMoneyToString, reportValToConsumerLink, reportValToOrderLink, ReportView} from "../ReportView";
import {useGetLtvReportQuery} from "../../../app/apiSlice";

export const LtvReport = () => {
  const filterFunc = (lowerSearch: string, report: Ltv[]) =>
    report.filter(r =>
      r.email.toLowerCase().includes(lowerSearch) ||
      r.brand.toLowerCase().includes(lowerSearch) ||
      r.shippingCity.toLowerCase().includes(lowerSearch) ||
      r.shippingState.toLowerCase().includes(lowerSearch) ||
      r.shippingZip.toLowerCase().includes(lowerSearch) ||
      r.paymentMethod.toLowerCase().includes(lowerSearch) ||
      r.paymentStatus.toLowerCase().includes(lowerSearch) ||
      r.shippingMethod.toLowerCase().includes(lowerSearch) ||
      r.customerId.toString().toLowerCase().includes(lowerSearch) ||
      r.orderNumber.toString().toLowerCase().includes(lowerSearch) ||
      r.daysSinceLastOrder.toString().includes(lowerSearch)
    );

  const columns: ColumnConfig<Ltv>[] = [
    reportCol('customerId', 'Cust. #', reportValToConsumerLink),
    reportCol('brand', 'Brand'),
    reportCol('email', 'Email'),
    reportCol('daysSinceLastOrder', 'Days Since Last Order'),
    reportCol('shippingCity', 'Ship City'),
    reportCol('shippingState', 'Ship State'),
    reportCol('shippingZip', 'Ship Zip'),
    reportCol('orderNumber', 'Order #', reportValToOrderLink),
    reportCol('orderDate', 'Order Date', reportDateToString),
    reportCol('orderNetTotal', 'Order Net', reportMoneyToString),
    reportCol('paymentMethod', 'Payment Meth.'),
    reportCol('paymentStatus', 'Payment Status'),
    reportCol('shippingMethod', 'Ship Meth.'),
    reportCol('netProductSales', 'Net Product', reportMoneyToString),
    reportCol('netShippingSales', 'Net Ship', reportMoneyToString),
    reportCol('netHandlingSales', 'Net Handling', reportMoneyToString),
    reportCol('retailDeliveryFee', 'Retail Delivery Fee', reportMoneyToString),
    reportCol('netTax', 'Net Tax', reportMoneyToString),
    reportCol('netDiscount', 'Net Discount', reportMoneyToString),
    reportCol('netAdjustments', 'Net Adj.', reportMoneyToString),
    reportCol('netRefunds', 'Net Refunds', reportMoneyToString),
  ];
  
  return (
    <ReportView
      title='LTV'
      query={useGetLtvReportQuery}
      columns={columns}
      initialSort={{ column: 'orderDate', direction: 'asc'}}
      filterFunc={filterFunc}
      downloadPath='/api/Reports/Ltv'
      downloadName='CW_LTV'/>
  );
}