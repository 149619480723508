import { Button, Input, InputGroup, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { Consumer } from "./Consumer";
import { useState } from "react";
import { useGetCustomersQuery } from "../../../app/apiSlice";

interface CustomerSelectProps {
  siteId: number,
  value?: Consumer,
  onChange: (value?: Consumer) => void,
  placeholder?: string,
}
export const CustomerSelect = (props: CustomerSelectProps) => {
  const [ search, setSearch ] = useState('');
  const { data: consumers } = useGetCustomersQuery({ siteId: props.siteId, search });
  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  
  let displayName = props.value ?
    props.value?.firstName + ' ' + props.value?.lastName :
    (props.placeholder ?? 'New Customer');
  if (props.value?.businessName) displayName += ` - ${props.value?.businessName}`;
  
  const toggleModal = () => setModalIsOpen(!modalIsOpen);
  const selectCustomer = (customer: Consumer) => {
    props.onChange(customer);
    setModalIsOpen(false);
  }
  
  const CustomerTable = () => (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Email</th>
          <th>Business</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
      { consumers?.map(c => (
        <tr key={c.id}>
          <td>{c.id}</td>
          <td>{c.firstName} {c.lastName}</td>
          <td>{c.email}</td>
          <td>{c.businessName}</td>
          <td>
            <Button color='primary' onClick={() => selectCustomer(c)}>Select</Button>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
  )
  
  return (<>
    <InputGroup>
      <Input disabled value={displayName}/>
      {/*<Button color='warning' onClick={clear}>Clear</Button>*/}
      {/*<Button color='primary' onClick={toggleModal}>Find Customer</Button>*/}
    </InputGroup>
    
    <Modal isOpen={modalIsOpen} toggle={toggleModal} size="lg">
      <ModalHeader toggle={toggleModal}>Find Customer</ModalHeader>
      <ModalBody>
        <InputGroup>
          <Input placeholder='Search by customer id, name, or email...' value={search ?? ''} onChange={e => setSearch(e.target.value)}/>
        </InputGroup>
        { consumers?.length ?
          CustomerTable() :
          <span className='my-2'>Sorry, no customers found. Change your search and try again.</span>
        }
      </ModalBody>
    </Modal>
  </>); 
}