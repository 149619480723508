import {PaymentMethodOption} from "../PaymentMethod";
import {Utils} from "../../../utils/Utils";
import {PaymentDetailsType} from "../PaymentDetails";


export class PaymentUtils {
    static wrapPaymentMethodOptionValidationMap(paymentMethodOption: PaymentMethodOption | undefined,
                                                forOption: PaymentMethodOption,
                                                map: Record<string, ((value?: string) => string | undefined)>): Record<string, ((value?: string) => string | undefined)> {
        return Utils.arrayToObject(Object.entries(map), ([key, errorFunc]) => {
            const func = (value?: string) => {
                return paymentMethodOption === forOption ? errorFunc(value) : undefined
            }
            return [key, func];
        });


    }
    static wrapPaymentDetailsValidationMap(paymentDetailsType: PaymentDetailsType | undefined,
                                                fortype: PaymentDetailsType,
                                                map: Record<string, ((value?: string) => string | undefined)>): Record<string, ((value?: string) => string | undefined)> {
        return Utils.arrayToObject(Object.entries(map), ([key, errorFunc]) => {
            const func = (value?: string) => {
                return paymentDetailsType === fortype ? errorFunc(value) : undefined
            }
            return [key, func];
        });


    }
}