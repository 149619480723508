import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {BrowserStorageType, persistInStorage, removeFromStorage} from "../../utils/Utils";
import {OrderEditCartDetails} from "../order/Order";
import Cookies from "js-cookie";
import {CartCookieType} from "./CartCookie";
import {apiSlice} from "../../app/apiSlice";

interface CartState {
  cartId?: CartIdType;
  adminCartId?: CartIdType;
  orderEditCart?: OrderEditCartDetails
}

export type CartIdType = string | undefined;

const cartIdLocalStorageKey = 'CartId-value';
const adminCartIdLocalStorageKey = 'AdminCartId-value';
const orderEditCartLocalStorageKey = 'OrderEditCart-value';

const initialState: CartState = {
  cartId: undefined,
  adminCartId: undefined,
  orderEditCart: undefined
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartId: (state, { payload: cartId }: PayloadAction<CartIdType>) => {
      const cartType = getCartTypeFromUrl();
      const stateKey = cartType === CartCookieType.ADMIN ? 'adminCartId' : 'cartId';
      
      state[stateKey] = cartId;
      if (cartId != undefined) {
        setCartIdCookie(cartType, cartId.toString());
      }
      else {
        clearCartIdCookie(cartType);
      }
    },
    forgetCartId: (state) => {
      clearCartIdCookie(getCartTypeFromUrl());
    },
    setOrderEditCart: (state, {payload: orderEditCartDetails}: PayloadAction<OrderEditCartDetails>) => {
      state.orderEditCart = orderEditCartDetails;
      persistInStorage(orderEditCartLocalStorageKey, orderEditCartDetails, BrowserStorageType.LocalStorage);
    },
    forgetOrderEditCartId: (state) => {
      state.orderEditCart = undefined;
      removeFromStorage(orderEditCartLocalStorageKey);
    },
  },
  extraReducers: builder => {
    builder.addMatcher(apiSlice.endpoints.createOrder.matchFulfilled, (state, { payload: resp }) => {
      // Forget cart id if order is fully complete
      if (!resp.isPartiallyComplete)
        cartSlice.caseReducers.forgetCartId(state);
    })
  }
});

function setCartIdCookie(id: CartCookieType, value: string) {
  Cookies.set(id, value, {
    sameSite: 'strict',
    secure: true
  });
}

function clearCartIdCookie(id: CartCookieType) {
  Cookies.remove(id);
}

function getCartTypeFromUrl() {
  return window.location.pathname.includes('/admin') ? CartCookieType.ADMIN : CartCookieType.LOCAL;
}

export const selectCartId = (state: RootState) => {
  return  getCartTypeFromUrl() === CartCookieType.LOCAL ? selectLocalCartId(state) : selectAdminCartId(state);
};
export const selectLocalCartId = (state: RootState) => {
  return Cookies.get(CartCookieType.LOCAL);
};
export const selectAdminCartId = (state: RootState) => {
  return Cookies.get(CartCookieType.ADMIN);
}
export const selectOrderEditCart = (state: RootState, orderId: number) =>  {
  return state.cart.orderEditCart?.orderId === orderId ? state.cart.orderEditCart : undefined;
}

export const { setCartId, forgetCartId, forgetOrderEditCartId, setOrderEditCart } = cartSlice.actions;

export default cartSlice.reducer;