
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal, ModalBody,
  ModalHeader, Nav,
  NavItem, NavLink,
  Row, Spinner,
  Table, FormFeedback
} from "reactstrap";
import React, { ChangeEvent, useEffect, useState } from "react";
import {getErrorMessage} from "../../../utils/Utils";
import {
  useGetConsumerDetailsByIdQuery,
  useGetConsumersOrdersQuery,
  useGetUserHistoryQuery,
  useSendPasswordSetupEmailMutation,
  useUpdateConsumerMutation
} from "../../../app/apiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { DateTime } from "luxon";
import {ConsumerDetails} from "./Consumer";
import "./ConsumerDetailsView.css";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {ConsumerControlListValues} from "./controlListValues/ConsumerControlListValues";
import { EmailInput } from "../../input/email/EmailInput";
import {useAppDispatch, useValidation} from "../../../app/hooks";
import {showInformationModal} from "../../modal/ModalSlice";

export const ConsumerDetailsView = () => {
  const { consumerId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const consumerIdInt = consumerId ? Number.parseInt(consumerId) : undefined;

  const { data: consumer, isLoading: loadingConsumer } = useGetConsumerDetailsByIdQuery(consumerIdInt ?? skipToken);
  const [ updateConsumer ] = useUpdateConsumerMutation();
  const [ sendPasswordSetupEmail ] = useSendPasswordSetupEmailMutation();

  const { data: history } = useGetUserHistoryQuery(consumer?.user?.id ?? skipToken);
  const { data: orders } = useGetConsumersOrdersQuery(consumer?.id ?? skipToken);
  const [activeTab, setActiveTab] = useState('General');

  const [editConsumer, setEditConsumer] = useState<ConsumerDetails | undefined>(consumer);

  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  const required = (name: string) => (val?: string) => !val ? `${name} is required.` : undefined;
  const validation = useValidation({
    firstName: required('First Name'),
    lastName: required('Last Name')
  });

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  useEffect(() => {
    if (consumer) {
      setEditConsumer(consumer)
      validation.setValue('firstName', consumer.firstName)
      validation.setValue('lastName', consumer.lastName)
    }
  }, [consumer]);

  const onSaveClick = async () => {
    if (!validation.allValid() || !editConsumer?.email) return;

    try {
      await updateConsumer({
        ...editConsumer
      }).unwrap();
      navigate('/admin/consumers');
    } catch (e: any) {
      if (e.status === 403 || e.originalStatus === 401) {
        dispatch(showInformationModal({ 
          title: 'Error',
          text: 'Sorry, you are not authorized to apply this change.'
        }));
      } else {
        dispatch(showInformationModal({
          title: 'Error',
          text: 'Error saving user: ' + getErrorMessage(e)
        }));
      }
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    validation.setValue(e.target.name, e.target.value);
    if (editConsumer) {
      setEditConsumer({...editConsumer, [e.target.name]: e.target.value});
    }
  }

  const onSendPasswordSetClick = async () => {
    if (!consumer)
      return;
    try {
      await sendPasswordSetupEmail(consumer.id).unwrap();
      toggleModal();
    } catch (e: any) {
      alert('Error resending email to user: ' + JSON.stringify(e));
    }
  }

  if (!editConsumer && loadingConsumer)
    return <div className="centered-spinner"><Spinner></Spinner></div>;

  return (
      <div className='d-flex flex-column px-4'>
        <div className="d-flex justify-content-between mb-2 align-items-center">
          <h2 className="mb-0">User</h2>
          <div className="d-flex">
            <Button color='primary' className='ms-1 consumer-detail-button' onClick={onSendPasswordSetClick}>
              {consumer?.hasPassword ? "Send Password Reset Email" : "Resend Setup Email"}
            </Button>
            <Button color='success' className='ms-1 consumer-detail-button' onClick={onSaveClick}>Save</Button>
            <Button color='danger' className='me-1 consumer-detail-button' onClick={() => navigate('/admin/admins')}>Cancel</Button>
          </div>
        </div>

        <Nav tabs>
          <NavItem>
            <NavLink active={activeTab === 'General'} onClick={() => setActiveTab('General')}>General</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'History'}
                     onClick={() => setActiveTab('History')}>History</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'Orders'}
                     onClick={() => setActiveTab('Orders')}>Orders</NavLink>
          </NavItem>
          <NavItem>
            <NavLink active={activeTab === 'ControlLists'}
                     onClick={() => setActiveTab('ControlLists')}>Control Lists</NavLink>
          </NavItem>
        </Nav>

        {activeTab === 'General' &&
            <Row className='pt-3'>
              {/*Fields*/}
              <Col>
                <FormGroup>
                  <Label for='Email'>Email</Label>
                  <EmailInput id='Email'
                              name='email'
                              value={editConsumer?.email ?? ''}
                              onChange={onChange}
                              suppressError={loadingConsumer}/>
                </FormGroup>

                <FormGroup>
                  <Label for='FirstName'>First Name</Label>
                  <Input id='FirstName'
                         autoComplete="autoComplete_Off"
                         name='firstName'
                         value={editConsumer?.firstName ?? ''}
                         maxLength={100}
                         invalid={validation.getError('firstName') !== undefined}
                         onChange={onChange}/>
                  <FormFeedback>{validation.getError('firstName')}</FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label for='LastName'>Last Name</Label>
                  <Input id='LastName'
                         autoComplete="autoComplete_Off"
                         name='lastName'
                         value={editConsumer?.lastName ?? ''}
                         maxLength={100}
                         invalid={validation.getError('lastName') !== undefined}
                         onChange={onChange}/>
                  <FormFeedback>{validation.getError('lastName')}</FormFeedback>
                </FormGroup>

              </Col>

              {/*Details*/}

              <Col>
                <Row>
                  <Col xs='4'>
                    <strong>Created Date:</strong>
                  </Col>
                  <Col>
                    {DateTime.fromISO(editConsumer?.user?.timeCreatedLocal ?? "").toLocaleString(DateTime.DATETIME_SHORT)}
                  </Col>
                </Row>
                <Row>
                  <Col xs='4'>
                    <strong>Created By:</strong>
                  </Col>
                  <Col>
                    {editConsumer?.user?.createdBy ?? 'System'}
                  </Col>
                </Row>
                <Row>
                  <Col xs='4'>
                    <strong>Last Modified Date:</strong>
                  </Col>
                  <Col>
                    {DateTime.fromISO(editConsumer?.user?.timeLastUpdatedLocal ?? "").toLocaleString(DateTime.DATETIME_SHORT)}
                  </Col>
                </Row>
                <Row>
                  <Col xs='4'>
                    <strong>Last Modified By:</strong>
                  </Col>
                  <Col>
                    {editConsumer?.user?.updatedBy ?? 'System'}
                  </Col>
                </Row>
                <Row>
                  <Col xs='4'>
                    <strong>Most Recent Routing Number:</strong>
                  </Col>
                  <Col>
                    {editConsumer?.mostRecentRoutingNumber ?? ''}
                  </Col>
                </Row>
              </Col>
            </Row>
        }

        {
            activeTab === 'History' &&
            <Table>
              <thead>
              <tr>
                <th>User</th>
                <th>Event Date</th>
                <th>Description</th>
              </tr>
              </thead>
              <tbody>
              { history?.map(record => (
                  <tr key={record.id}>
                    <td>{record.changeBy}</td>
                    <td>{DateTime.fromISO(record.timeOfChangeLocal).toLocaleString(DateTime.DATETIME_SHORT)}</td>
                    <td>{record.changeDescription}</td>
                  </tr>
              ))}
              </tbody>
            </Table>
        }

        {
            activeTab === 'Orders' &&
            <Table>
              <thead>
              <tr>
                <th>Order Id</th>
                <th>Order Total</th>
                <th>Time Created</th>
              </tr>
              </thead>
              <tbody>
              { orders?.map(record => (
                  <tr key={record.id}>
                    <td><a href={`/admin/order/${record.id}`}>{record.id}</a></td>
                    <td>{CurrencyFormatter.format(record.totalCharged)}</td>
                    <td>{DateTime.fromISO(record.timeCreatedLocal).toLocaleString(DateTime.DATETIME_SHORT)}</td>
                  </tr>
              ))}
              </tbody>
            </Table>
        }

        {
            activeTab === 'ControlLists' && consumer &&
            <ConsumerControlListValues consumerId={consumer.id}/>
        }

        <Modal isOpen={modalIsOpen} toggle={toggleModal} size="lg">
          <ModalHeader toggle={toggleModal}>Email Sent</ModalHeader>
          <ModalBody>
            <span className='my-2'>A password reset email has been sent to { consumer?.email ? <strong>{consumer?.email}</strong> : 'the address associated with your account' }.</span>
          </ModalBody>
        </Modal>
      </div>
  )
}