import {Control, Controller, ControllerRenderProps, FieldPath, FieldValues, useFormContext} from "react-hook-form";
import React, {ChangeEvent, PropsWithChildren} from "react";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import classnames from 'classnames';

import {RequiredAsterisk} from "../input/utils/RequiredAsterisk";
import {curry} from "lodash";

type InputType = 'text' | 'email' | 'tel' | 'radio' | 'select' | 'checkbox' | 'numeric' | 'hidden';

type FormInputParseValue = (value: any) => any | Promise<any>;
type FormInputOnChange = (value: any | undefined) => void | Promise<void>;
type FormInputOnBlurHandler = (value: any | undefined) => void | Promise<void>;

export interface FormInputProps<Type extends FieldValues> {
    readonly control?: Control<Type>;
    readonly name: FieldPath<Type>;
    readonly label: string;
    readonly disabled?: boolean;
    readonly type?: InputType;
    readonly placeholder?: string;
    readonly parseValue?: FormInputParseValue,
    readonly onChange?: FormInputOnChange,
    readonly valid?: boolean;
    readonly requiredAsterisk?: boolean;
    readonly className?: string;
    readonly labelClassName?: string;
    readonly autoComplete?: string;
    readonly readOnly?: boolean;
    readonly plaintext?: boolean;
    readonly defaultChecked?: boolean;
    readonly defaultValue?: any;
    readonly onFocus?: any;
    readonly onBlur?: FormInputOnBlurHandler;
    readonly trimOnBlur?: boolean;
}

export function FormInputField<Type extends FieldValues>(
    {
        control,
        label,
        name,
        placeholder,
        valid,
        className: _className,
        labelClassName,
        children,
        autoComplete,
        defaultValue,
        defaultChecked = false,
        disabled = false,
        type: initialType = 'text',
        readOnly = false,
        plaintext = false,
        requiredAsterisk = false,
        parseValue = (value) => value,
        onChange = () => void 0,
        onFocus,
        onBlur = () => void 0,
        trimOnBlur = true
    }: FormInputProps<Type> & PropsWithChildren) {

    const {setValue} = useFormContext();
    
    const isNumeric = initialType === 'numeric';
    const type = isNumeric ? 'text' : initialType;
    
    const className = classnames(
        _className, {
            'd-none': type === 'hidden',
        }
    )
    const labelFirst = type !== 'radio' && type !== 'checkbox';
    const labelClassnames = classnames(labelClassName, {
        'ms-2': !labelFirst
    });

    function renderLabel() {
        return <Label for={name} className={labelClassnames}>
            {label}
            {requiredAsterisk && <RequiredAsterisk/>}
        </Label>;
    }

    return (
        <FormGroup className={className}>
            {labelFirst && renderLabel()}
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={
                    ({field, fieldState}) =>
                        (
                            <>
                                <Input id={name}
                                       type={type}
                                       invalid={fieldState.invalid}
                                       valid={valid}
                                       placeholder={placeholder}
                                       autoComplete={autoComplete}
                                       disabled={disabled}
                                       {...field}
                                       readOnly={readOnly}
                                       plaintext={plaintext}
                                       defaultValue={defaultValue}
                                       defaultChecked={defaultChecked}
                                       onChange={handleOnChange(initialType, parseValue, onChange, field)}
                                       onFocus={onFocus}
                                       onBlur={e => {
                                           if (trimOnBlur) {
                                               setValue(name, e.target.value.trim() as any, {
                                                   shouldValidate: false,
                                                   shouldDirty: false,
                                                   shouldTouch: false,
                                               });
                                           }
                                           field.onBlur();
                                           onBlur(e.target.value);
                                       }}
                                >
                                    {children}
                                </Input>
                                {fieldState.invalid
                                    && <FormFeedback>{fieldState.error?.message}</FormFeedback>}
                            </>
                        )
                }/>
            {!labelFirst && renderLabel()}
        </FormGroup>
    );
}


const handleOnChange = curry(async function <Type extends FieldValues>(
    type: InputType,
    parseValue: FormInputParseValue,
    onChange: FormInputOnChange,
    field: ControllerRenderProps<Type>,
    event: ChangeEvent<HTMLInputElement>) {

    let value = undefined;
    switch (type) {
        case 'checkbox':
            value = await parseValue(event.target.checked);
            break;
        case "numeric":
            value = await  parseValue(event.target.value.replaceAll(/\D/g, ''));
            break;
        default:
            value = await parseValue(event.target.value);
    }
    field.onChange(value);
    await onChange(value);
});
    