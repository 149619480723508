import './AddEditUserAddress.css'
import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Form, FormGroup, Input, Label, Spinner} from "reactstrap";
import {
    useGetConsumerAddressByAddressIdQuery,
    useUpdateAddressMutation,
    useDeleteAddressMutation,
    useSaveAddressMutation
} from "../../../../../app/apiSlice";
import {ReactComponent as Trashcan} from '../../.././../../assets/icons/trash-can-regular.svg';
import {useTranslation} from "react-i18next";
import SaveAddressRequest from "../../../../user/addresses/userAddresses/addUserAddress/SaveAddressRequest";
import { useAppSelector } from "../../../../../app/hooks";
import {selectCurrentConsumerUser} from "../../../login/AuthenticationSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import { AddressInputVm } from "../../../../input/address/Address";
import { ValidatedAddressInput } from "../../../../input/validatedAddress/ValidatedAddressInput";


interface AddEditUserAddressProps {
    isEditMode: boolean,
    addressId?: number,
    returnToListView: () => void
}

export const AddEditUserAddress = ({ isEditMode, addressId, returnToListView }: AddEditUserAddressProps ) => {
    const { t } = useTranslation();
    const consumer = useAppSelector(selectCurrentConsumerUser);
    const [ showErrors, setShowErrors ] = useState(false);
    const [formState, setFormState] = useState<SaveAddressRequest>({
        consumerId: consumer?.id,
        street: "",
        streetTwo: "",
        city: "",
        stateCode: "",
        zip: "",
        isDefaultBillingAddress: false,
        isDefaultShippingAddress: false
    });
    
    const { data: savedAddress } = useGetConsumerAddressByAddressIdQuery(!addressId ? skipToken : {consumerId: consumer?.id, addressId});
    const [saveAddressMutation, {isLoading: isSaving}] = useSaveAddressMutation();
    const [updateAddressMutation, {isLoading: isUpdating}] = useUpdateAddressMutation();
    const [deleteAddressMutation, {isLoading: isDeleting}] = useDeleteAddressMutation();

    // load formState from savedAddress
    useEffect(() => {
        if (isEditMode && savedAddress) {
            setFormState(old => ({
                ...old,
                addressId,
                street: savedAddress?.street,
                city: savedAddress?.city,
                stateCode: savedAddress?.stateCode,
                zip: savedAddress?.zip,
                isDefaultBillingAddress: savedAddress?.isDefaultBillingAddress,
                isDefaultShippingAddress: savedAddress?.isDefaultShippingAddress
            }));
        }
    }, [isEditMode, savedAddress, addressId]);
    
    const onAddressChange = (address?: AddressInputVm) => {
        setFormState(prev => ({
            ...prev,
            street: address?.street,
            city: address?.city,
            stateCode: address?.stateCode,
            zip: address?.zip,
        }));
    }

    const onDefaultBillingChange = () => {
        setFormState((prev) => ({ ...prev, isDefaultBillingAddress: !prev.isDefaultBillingAddress }));
    }

    const onDefaultShippingChange = () => {
        setFormState((prev) => ({ ...prev, isDefaultShippingAddress: !prev.isDefaultShippingAddress }));
    }

    const onSubmit = async () => {
        setShowErrors(true);
        if (!formState.street || !formState.city || !formState.stateCode || !formState.zip) return; // invalid address
        
        if (isEditMode) {
            await updateAddressMutation(formState);
        } else {
            await saveAddressMutation(formState);
        }
            returnToListView();
    }

    const onCancel = () => {
        returnToListView();
    }

    const onDelete = async (consumerId: number, addressId: number) => {
        await deleteAddressMutation({consumerId, addressId});
        returnToListView();
    }

    return (
        <div>
            <Card className="user-address-card" key="addAddress">
                <CardBody className="d-flex justify-content-between align-items-center">
                    <Form className='col-12'>
                        <div className='toggle-group col-12'>
                            <FormGroup switch>
                                <Input id="isDefaultBillingAddress" checked={formState.isDefaultBillingAddress} type='switch' role='switch' onChange={() => onDefaultBillingChange()} />
                                <Label htmlFor='isDefaultBillingAddress'>{t(`saveAddress.labels.isDefaultBillingAddress`)}</Label>
                            </FormGroup>
                            <FormGroup switch>
                                <Input id="isDefaultShippingAddress" checked={formState.isDefaultShippingAddress} type='switch' role='switch' onChange={(e) => onDefaultShippingChange()} />
                                <Label htmlFor='isDefaultShippingAddress'>{t(`saveAddress.labels.isDefaultShippingAddress`)}</Label>
                            </FormGroup>
                        </div>

                        <ValidatedAddressInput value={formState} onChange={onAddressChange} required suppressError={!showErrors}/>
                    </Form>
                </CardBody>
            </Card>
            <span className='action-btn-container d-flex flex-column justify-content-start flex-sm-row justify-content-sm-between'>
                <div className='col-12 col-sm-6 col-md-5 action-btn-group d-flex flex-column flex-sm-row'>
                    <Button
                        type='button'
                        className='col-12 col-sm-6'
                        onClick={onSubmit}
                        disabled={isSaving || isUpdating || isDeleting}
                        color='primary'>
                        {(isSaving || isUpdating) && <Spinner/>}
                        {t(`saveAddress.buttons.save`)}
                    </Button>
                    <Button
                        className='col-12 col-sm-6 edit-btn'
                        onClick={onCancel}>
                        {t(`saveAddress.buttons.cancel`)}
                    </Button>
                </div>
                {isEditMode &&
                  <Button
                    color='danger'
                    className='col-12 col-sm-5 col-md-6 btn-outline-danger delete-btn'
                    disabled={isDeleting || isSaving || isUpdating}
                    onClick={() => (addressId && consumer?.id) && onDelete(consumer.id, addressId)}>
                      <Trashcan/>
                      {t('saveAddress.buttons.delete')}
                </Button>}
            </span>
        </div>
    )
}