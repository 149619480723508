
import './CategoryBrowser.css';
import React from "react";
import {SiteCategory} from "../Category";
import {CategoryPreview} from "../preview/CategoryPreview";

interface CategoryBrowserProps {
    siteCategories: SiteCategory[],
    contextualSiteCategory?: SiteCategory
}

export const CategoryBrowser = ({siteCategories, contextualSiteCategory}: CategoryBrowserProps) => {

    return (
        <div className="category-browser-container">

            {siteCategories.map((sc) => {
                return <CategoryPreview siteCategory={sc} defaultShouldLimitCardItems={siteCategories.length !== 1} contextualSiteCategory={contextualSiteCategory} key={sc.id}></CategoryPreview>
            })}

        </div>
    );
}
