
export interface OrderPayment {
  // billingAddress: Address;
  id: number;
  orderId: number;
  amount: number;
  amountRemaining: number;
  timeOfPaymentBegan: string;
  paymentBeganTimeLocal: string;
  paymentMethod: string;
  paymentStatus: string;
  paymentType: string;
  transactionStatus?: string;
  productNames?: string;
  key?: string; // Assign manual key because used in grid where id is not always unique
}

export enum TransactionStatus {
  SettledSuccessfully = 'settledSuccessfully',
  CapturedPendingSettlement = 'capturedPendingSettlement',
  Voided = 'voided',
  Declined = 'declined',
  Unknown = 'unknown'
}
