import {Alert, Button, Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Spinner} from "reactstrap";
import React, {useEffect, useState} from "react";
import "./RefundTab.css";
import {
    useGetOrderOverviewQuery,
    useGetOrderPaymentHistoryQuery,
    useGetOrderProductPaymentRefundsByOrderIdQuery,
    useGetOrderRefundsQuery,
    useGetRefundCategoriesQuery,
    useGetRefundReasonsQuery,
    useGetRefundTypesQuery,
    useSubmitRefundRequestMutation
} from "../../../../../../app/apiSlice";
import {CurrencyFormatter} from "../../../../../../utils/CurrencyFormatter";
import {EditRefund, RefundCategoryDescriptions, RefundRequest, RefundStatusNames, RefundTypeDescriptions} from "../Refund";
import {RefundBrowse} from "../browse/RefundBrowse";
import {OrderPaymentsBrowse} from "../../../payments/browse/OrderPaymentsBrowse";
import {ProductSummary} from "../../../../product/summary/ProductSummary";
import {SiteProductVariantPricing} from "../../../../../cart/CartPricing";
import {LoadingSpinner} from "../../../../../input/utils/LoadingSpinner";
import {DateTime} from "luxon";
import {useClaims} from "../../../../../../app/hooks";
import {ADMIN, CS3, FINANCE, TEAM_LEAD} from "../../../../../../utils/Roles";
import {getEnumValueFromName} from "../../../../../../utils/Utils";

export interface RefundTabProps {
    orderId: number
}

export const RefundTab = ({orderId}: RefundTabProps) => {
    const { data: order, isLoading } = useGetOrderOverviewQuery(orderId);
    const { data: refundTypes } = useGetRefundTypesQuery();
    const { data: refundCategories } = useGetRefundCategoriesQuery();
    const { data: refundReasons } = useGetRefundReasonsQuery();
    const { data: orderProductPaymentRefunds } = useGetOrderProductPaymentRefundsByOrderIdQuery(orderId);
    const { data: payments, isLoading: loadingPayments, error: paymentError } = useGetOrderPaymentHistoryQuery(orderId);
    const { data: orderRefunds, isLoading: loadingOrderRefunds } = useGetOrderRefundsQuery(orderId);
    const [ submitRefundRequest, {isLoading: isSubmitting} ] = useSubmitRefundRequestMutation();
    const [ siteProductVariantPricing, setSiteProductVariantPricing ] = useState<SiteProductVariantPricing | undefined>(undefined);
    const [ typeId, setTypeId ] = useState<number | undefined>(undefined);
    const [ categoryId, setCategoryId ] = useState<number | undefined>(undefined);
    const [ percentage, setPercentage ] = useState<number>(0);
    const [ refundEdit, setRefundEdit ] = useState<EditRefund>({
        amount: 0,
        amountDisplay: "0.00"
    });
    
    const { hasPermission } = useClaims();
    const canSubmitRefundRequest = hasPermission([ADMIN, CS3, TEAM_LEAD, FINANCE]);
    

    useEffect(() => {
        // If amount is set to higher than the max amount, then lower it to the max amount
        if (refundEdit.amount && refundEdit.amount > getMaxRefundAmount()) {
            const maxRefund = getMaxRefundAmount();
            setRefundEdit({
                ...refundEdit,
                amount: maxRefund,
                amountDisplay: maxRefund.toString()
            });
        }
    }, [refundEdit, setRefundEdit]);

    useEffect(() => {
        refreshRefundAmount();
    }, [siteProductVariantPricing, typeId, categoryId, percentage, orderRefunds]);

    useEffect(() => {
        // Select first option when types load
        const firstType = refundTypes?.find(() => true);
        // Select first option when categories load
        const firstCategory = refundCategories?.find(() => true);
        // Select first option when reasons load
        const firstReason = refundReasons?.find(() => true);

        setRefundEdit({
            ...refundEdit,
            categoryId: firstCategory?.id,
            typeId: firstType?.id,
            reasonId: firstReason?.id
        });
        setCategoryId(firstCategory?.id);
        setTypeId(firstType?.id);
    }, [refundTypes, refundCategories, refundReasons]);

    const onTypeOptionChanged = (typeValue?: string) => {
        const typeId = typeValue ? parseInt(typeValue) : undefined;

        setRefundEdit({
            ...refundEdit,
            typeId: typeId
        });
        setTypeId(typeId);
    }

    const onCategoryOptionChanged = (categoryValue?: string) => {
        const categoryId = categoryValue ? parseInt(categoryValue) : undefined;

        setCategoryId(categoryId);

        const matchingCategory = refundCategories?.find(rc => rc.id === categoryId);
        const categoryEnum = matchingCategory?.name as keyof typeof RefundCategoryDescriptions;

        // If Total category is selected, then force full refund
        if (categoryEnum === RefundCategoryDescriptions.Total) {
            const fullRefundType = refundTypes?.find(rt => rt.name === RefundTypeDescriptions.Full);
            setTypeId(fullRefundType?.id);
            setRefundEdit({
                ...refundEdit,
                categoryId: categoryId,
                typeId: fullRefundType?.id
            });
        }
        else {
            setRefundEdit({
                ...refundEdit,
                categoryId: categoryId
            });
        }
    }

    const refreshRefundAmount = () => {
        const matchingType = refundTypes?.find(rf => rf.id === refundEdit.typeId);
        const typeEnum = matchingType?.name as keyof typeof RefundTypeDescriptions;

        const matchingCategory = refundCategories?.find(rc => rc.id === refundEdit.categoryId);
        const categoryEnum = getEnumValueFromName(RefundCategoryDescriptions, matchingCategory?.name);
        let newAmount = undefined;

        if (categoryEnum === RefundCategoryDescriptions.Total) {
            newAmount = siteProductVariantPricing?.total;
        }
        else if (categoryEnum === RefundCategoryDescriptions.ProductTotal) {
            newAmount = siteProductVariantPricing?.productTotal;
        }
        else if (categoryEnum === RefundCategoryDescriptions.Handling) {
            newAmount = siteProductVariantPricing?.handling;
        }
        else if (categoryEnum === RefundCategoryDescriptions.Shipping) {
            newAmount = siteProductVariantPricing?.shippingPrice;
        }
        else if (categoryEnum === RefundCategoryDescriptions.TaxAmount) {
            newAmount = siteProductVariantPricing?.taxAmount;
        }

        if (typeEnum === RefundTypeDescriptions.Percentage) {
            newAmount = (newAmount ?? 0) * (percentage / 100);
        }

        setRefundEdit({
            ...refundEdit,
            amount: newAmount !== undefined && newAmount !== null ? Math.round(newAmount * 100) / 100 : refundEdit.amount,
            amountDisplay: newAmount !== undefined && newAmount !== null ? newAmount.toFixed(2).toString() : refundEdit.amount.toString()
        });
    }

    const onPercentageChange = (percentageValue?: string) => {
        const newPercentage = percentageValue && parseFloat(percentageValue) > 0 ? parseFloat(percentageValue) : 0;
        setPercentage(newPercentage);
    }

    const shouldShowPercentageControl = () => {

        const matchingType = refundTypes?.find(rf => rf.id === refundEdit.typeId);
        const typeEnum = matchingType?.name as keyof typeof RefundTypeDescriptions;

        return (typeEnum === RefundTypeDescriptions.Percentage);
    }

    const onReasonOptionChanged = (reasonValue?: string) => {
        const reasonId = reasonValue ? parseInt(reasonValue) : undefined;
        setRefundEdit({...refundEdit, reasonId: reasonId});
    }

    const onAmountChanged = (amountValue?: string) => {
        const amount = parseFloat(amountValue?.replace(/[^0-9.]/g,'') ?? "");
        setRefundEdit({
            ...refundEdit,
            amount,
            amountDisplay: amountValue ?? "0.00"
        });
    }

    const onStartRefundClicked = async () => {
        if (!refundEdit.typeId || !refundEdit.categoryId || !refundEdit.reasonId || !siteProductVariantPricing?.orderProductId) {
            return;
        }
        const refundRequest: RefundRequest = {
            orderProductId: siteProductVariantPricing.orderProductId,
            amount: refundEdit.amount,
            typeId: refundEdit.typeId,
            categoryId: refundEdit.categoryId,
            reasonId: refundEdit.reasonId
        };
        try {
            await submitRefundRequest(refundRequest).unwrap();
        }
        catch (e) {
            console.error(e);
        }
    }

    const getMaxRefundAmount = (useTotal?: boolean) => {
        let maxAmount = 0;
        if (!payments || payments.length === 0 || siteProductVariantPricing === undefined)
            return maxAmount;

        const matchingCategory = refundCategories?.find(rc => rc.id === refundEdit.categoryId);
        const categoryEnum = getEnumValueFromName(RefundCategoryDescriptions, matchingCategory?.name);

        if (categoryEnum === RefundCategoryDescriptions.Total || useTotal) {
            maxAmount = siteProductVariantPricing?.total ?? 0;
        }
        else if (categoryEnum === RefundCategoryDescriptions.ProductTotal) {
            maxAmount = siteProductVariantPricing?.productTotal ?? 0;
        }
        else if (categoryEnum === RefundCategoryDescriptions.Handling) {
            maxAmount = siteProductVariantPricing?.handling ?? 0;
        }
        else if (categoryEnum === RefundCategoryDescriptions.Shipping) {
            maxAmount = siteProductVariantPricing?.shippingPrice ?? 0;
        }
        else if (categoryEnum === RefundCategoryDescriptions.TaxAmount) {
            maxAmount = siteProductVariantPricing?.taxAmount ?? 0;
        }

        // Narrow down to refunds for this order product in the same category
        // This will get the total amount of unapplied refunds
        const categoryRefunds = orderRefunds ?
            orderRefunds.filter(or => or.orderProductId === siteProductVariantPricing.orderProductId &&
                                (or.category?.id === refundEdit.categoryId || useTotal || categoryEnum === RefundCategoryDescriptions.Total) &&
                                (or.status.name !== RefundStatusNames.Failed) && 
                                (!(orderProductPaymentRefunds ?? [])
                                    .some(oppr =>
                                        oppr.refundId === or.id)) &&
                                (or.status.name !== RefundStatusNames.Declined))
                        .reduce((pv, cv) => pv + (cv.amount ?? 0), 0) :
            0;
        const netRefundableAmount = +(maxAmount - categoryRefunds).toFixed(2);
        return netRefundableAmount > 0 ? netRefundableAmount : 0;
    }

    const shouldDisableAmount = () => {
        const matchingType = refundTypes?.find(rf => rf.id === refundEdit.typeId);
        const typeEnum = matchingType?.name as keyof typeof RefundTypeDescriptions;
        return (typeEnum === RefundTypeDescriptions.Full || typeEnum === RefundTypeDescriptions.Percentage);
    }

    const shouldDisableType = () => {
        const matchingCategory = refundCategories?.find(rc => rc.id === refundEdit.categoryId);
        const categoryEnum = matchingCategory?.name as keyof typeof RefundCategoryDescriptions;
        return (categoryEnum === RefundCategoryDescriptions.Total);
    }
    
    const isOrderTooOldForRefund = () => {
        if (!order) return false;
        
        const diff = DateTime.fromISO(order.timeCreated).diffNow();
        return diff.as('days') <= -180; //Orders older than 180 days
    }

    const shouldDisableStartRefund = () => {
        return getMaxRefundAmount() <= 0 || !siteProductVariantPricing || isSubmitting || loadingOrderRefunds;
    }
    
    if (isLoading)
        return <LoadingSpinner size="sm" />
    
    if (isOrderTooOldForRefund())
        return (
            <div className={"d-flex align-items-center justify-content-center"}>
                <Alert color="info">
                    It has been more than 180 days, refunds need to be processed through accounting directly.
                </Alert>
            </div>
        );
    
    return (
        <>
        { canSubmitRefundRequest &&
            <Row>
                {/*Refund Set Up*/}
                <Col>
                    <Card>
                        <CardHeader className='d-flex justify-content-between'>
                            <h5 className='mb-0'>Refund</h5>
                            <h5 className='mb-0'>Total Remaining: {CurrencyFormatter.format(getMaxRefundAmount(true))}</h5>
                        </CardHeader>
                        <CardBody>

                            <FormGroup className="refund-tab-row">
                                <Label for='Refund'>Refund Category</Label>
                                <Input type='select'
                                       onChange={e => onCategoryOptionChanged(e.target.value)}
                                       value={refundEdit.categoryId}
                                       disabled={!siteProductVariantPricing}
                                       placeholder="Category">
                                    {refundCategories?.map(refundCategory => (
                                        <option key={refundCategory.id} value={refundCategory.id}>{refundCategory.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <FormGroup className="refund-tab-row">
                                <Label for='Refund'>Refund Type</Label>
                                <Input type='select'
                                       onChange={e => onTypeOptionChanged(e.target.value)}
                                       value={refundEdit.typeId}
                                       disabled={!siteProductVariantPricing || shouldDisableType()}
                                       placeholder="Type">
                                    {refundTypes?.map(refundType => (
                                        <option key={refundType.id} value={refundType.id}>{refundType.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>

                            {shouldShowPercentageControl() &&
                                <FormGroup className="refund-tab-row">
                                    <Label for='Refund'>Percentage</Label>
                                    <InputGroup>
                                        <Input id='Percentage' className='form-control' type='text' maxLength={3} value={percentage} disabled={!siteProductVariantPricing} onChange={e => onPercentageChange(e.target.value)} placeholder='Percentage' />
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            }

                            <FormGroup className="refund-tab-row">
                                <Label for='Refund'>Refund</Label>
                                <InputGroup>
                                    <InputGroupText>$</InputGroupText>
                                    <Input type='number'
                                           value={refundEdit.amountDisplay}
                                           disabled={!siteProductVariantPricing || shouldDisableAmount()}
                                           onChange={e => onAmountChanged(e.target.value)}
                                           placeholder="Amount">
                                    </Input>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup className="refund-tab-row">
                                <Label for='Refund'>Reason</Label>
                                <Input type='select'
                                       onChange={e => onReasonOptionChanged(e.target.value)}
                                       value={refundEdit.reasonId}
                                       disabled={!siteProductVariantPricing}
                                       placeholder="Type">
                                    {refundReasons?.map(refundReason => (
                                        <option key={refundReason.id} value={refundReason.id}>{refundReason.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <span className="refund-tab-start-refund-container">
                                    <Button onClick={onStartRefundClicked}
                                            disabled={shouldDisableStartRefund()}>
                                      {isSubmitting && <Spinner></Spinner>}
                                      Start Refund
                                    </Button>

                                {refundEdit.amount > 0 && getMaxRefundAmount() === refundEdit.amount &&
                                    <Alert color="warning">
                                        Max Refund for Category Selected
                                    </Alert>
                                }
                                {getMaxRefundAmount() <= 0 && siteProductVariantPricing &&
                                    <Alert color="danger">
                                        All payments have been refunded or are pending a refund
                                    </Alert>
                                }
                                {!siteProductVariantPricing &&
                                    <Alert color="danger">
                                        Please select a product
                                    </Alert>
                                }

                                </span>

                        </CardBody>
                    </Card>
                </Col>
                
                {/*Product Selection*/}
                <Col xs='5'>
                    <ProductSummary
                        orderId={orderId}
                        locked={true}
                        onOrderProductSelected={setSiteProductVariantPricing}/>
                </Col>
            </Row>
        }
            
        {/*Transaction History*/}
        <Row>
            <Col>
                <Card>
                    <CardHeader>
                        <h5 className='m-0'>Transaction History</h5>
                    </CardHeader>
                    <CardBody>
                        <OrderPaymentsBrowse orderId={orderId}></OrderPaymentsBrowse>
                    </CardBody>
                </Card>
            </Col>
        </Row>

        {/*Order Refunds Browser*/}
        <Row>
            <Col>
                <Card>
                    <CardHeader>
                        <h5 className='m-0'>Order Refunds</h5>
                    </CardHeader>
                    <CardBody>
                        <RefundBrowse orderId={orderId}/>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
    );
}