
import './ConfigurationGroupView.css';
import {ConfigurationGroup} from "../ConfigurationGroup";
import {ProductInputList} from "../../list/ProductInputList";
import {Bank} from "../../../bank/Bank";
import {BankInformation} from "../../../bank/information/BankInformation";
import {useEffect, useState} from "react";
import {ConfigurationPaging, ReviewPage} from "../ConfigurationPaging";
import {ProductType, ProductTypes} from "../../../product/Product";
import {InputClassification, ProductInputValue} from "../../ProductInputValue";
import { ConfigurationRuleEvaluator } from "../rule/ConfigurationRuleEvaluator";
import { RuleTypes } from "../rule/ConfigurationRule";
import { useAppSelector } from "../../../../app/hooks";
import { selectAllInputValues } from "../../ProductInputSlice";
import { Configuration } from "../Configuration";
import { AddressInputVm } from "../../address/Address";
import {SiteProductVariantQuantityOption} from "../../../product/SiteProductVariantQuantityOption";

interface ConfigurationGroupViewProps {
    basePrice: number;
    groupedConfiguration: ConfigurationGroup,
    currentPage?: ConfigurationPaging,
    checkInputs: boolean,
    productTypes: ProductType[],
    siteProductId: number,
    productVariantId?: number,
    getConfigurationValueById: (inputClassification: InputClassification, productInputId?: number) => ProductInputValue | undefined,
    consumerId?: number,
    isReadOnly: boolean,
    selectedQuantityOption?: SiteProductVariantQuantityOption
}

export const ConfigurationGroupView = ({ basePrice, groupedConfiguration, currentPage, checkInputs, productTypes,
                                           siteProductId, productVariantId, getConfigurationValueById, consumerId, isReadOnly, selectedQuantityOption}: ConfigurationGroupViewProps) => {

    const productInputs = useAppSelector(selectAllInputValues);
    const [ isInEditMode, setIsInEditMode ] = useState(!isReadOnly);
    const isOnReviewPage = currentPage?.pagingKey === ReviewPage.pagingKey;
    const shouldHideGroup = isOnReviewPage && !groupedConfiguration.configurations?.some(c => c.showInReview);
    const isMissingRequirements = groupedConfiguration.configurations.some(configuration =>
        (configuration.configurationDetail.isRequired && !hasValue(configuration, productInputs.find(pi => pi.productInputId === configuration.configurationDetail.id))) ||
        ConfigurationRuleEvaluator.evaluateRules(configuration.rules, productInputs, productVariantId, [RuleTypes.Matching, RuleTypes.Differing, RuleTypes.PositiveMatch]).length > 0);

    useEffect(() => {
        setIsInEditMode(!isOnReviewPage && !isReadOnly);
    }, [isOnReviewPage, isReadOnly]);

    if (!groupedConfiguration.configurations || shouldHideGroup) {
        return <></>;
    }
    
    function hasValue(configuration: Configuration, productInput?: ProductInputValue) {
        let hasValue = false;
        if (!productInput) return hasValue;
        
        if (productInput.value && configuration.inputType.inputMethod === 'Address Input') {
            try {
                const address: AddressInputVm = JSON.parse(productInput.value);
                hasValue = !!(address && productInput.isComplete);
            }
            catch (err) {
                hasValue = false;
            }
        }
        else if (((productInput.value || productInput.selectedProductInputOptionId) && productInput.isComplete !== false)
            && (!configuration.shouldValidateValue || (configuration.shouldValidateValue && productInput.isValidated))) {
            hasValue = true;
        }
        
        return hasValue;
    }
    
    function toggleEditMode() {
        // don't let them exit edit mode with invalid inputs or while in forced read only mode
        if (!isMissingRequirements && !isReadOnly) {
            setIsInEditMode(!isInEditMode); 
        } 
        if (isInEditMode) {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    }

    function renderSave(){
        if (isOnReviewPage) {
            if (isInEditMode) {
                return <span className="configuration-group-view-save-toggle" onClick={toggleEditMode}>Save</span>
            }
            return <span className="configuration-group-view-save-toggle" onClick={toggleEditMode}>Edit</span>
        } else {
            return <></>
        }
    }

    return (
        <div className="configuration-group-view-container">

            <div className="configuration-group-view-header">
                <h4 className="configuration-group-view-name">{
                    isOnReviewPage ? 
                        (groupedConfiguration.reviewLabel ?? groupedConfiguration.name) : 
                        groupedConfiguration.actionLabel ?? groupedConfiguration.name}
                </h4>
                {renderSave()}
            </div>

            {
                // display extra bank information helpers (static text & photo, not the actual aba & account number inputs)
                // if this is the bank information config group & we're not on review page
                !isOnReviewPage && groupedConfiguration.groupKey.toLowerCase().includes(Bank.InformationGroupKey) &&
                !!productTypes.find(pt => pt.name === ProductTypes.Check) &&
                <BankInformation/>
            }

            <ProductInputList
                basePrice={basePrice}
                configurations={groupedConfiguration.configurations}
                isInEditMode={isInEditMode}
                currentPage={currentPage}
                checkInputs={checkInputs}
                productTypes={productTypes}
                siteProductId={siteProductId}
                productVariantId={productVariantId}
                getConfigurationValueById={getConfigurationValueById}
                consumerId={consumerId}
                selectedQuantityOption={selectedQuantityOption}
            />

        </div>
    );
}