
export interface PreviewMicrFormatRequest {
    micrFormat: string
    micrBankInformation?: MicrBankInformation,
    orderProductId?: number,
    isOrderProductSpecific: boolean
}

export interface MicrBankInformation {
    routingNumber?: string
    accountNumber: string
    startingCheckNumber: string
    endingCheckNumber?: string
}

export interface PreviewMicrResponse {
    micrPreviewText?: string
}

export enum MicrBankInformationFields {
    RoutingNumber,
    AccountNumber,
    CheckNumber
}