import {Address} from "../../input/address/Address";
import {AddressInputVm} from "../../input/address/Address";

export interface FinancialInstitution {
    id: number
    fullBankName: string
    name: string
    legacyName?: string
    abaNumber: string
    timeCreated: string
    timeLastUpdated: string
    createdById: number
    updatedById: number
    phoneNumber?: string
    fractionalNumber: string
    headquartersAddressId?: number
    mailingAddressId?: number
    headquartersAddress?: Address
    mailingAddress?: Address
    createdByName?: string
    updatedByName?: string
}

export interface FinancialInstitutionEdit {
    id?: number
    fullBankName?: string
    name?: string
    legacyName?: string
    abaNumber?: string
    timeCreated?: string
    timeLastUpdated?: string
    createdById?: number
    updatedById?: number
    phoneNumber?: string
    fractionalNumber?: string
    headquartersAddressId?: number
    mailingAddressId?: number
    headquartersAddress?: AddressInputVm
    mailingAddress?: AddressInputVm
    createdByName?: string
    updatedByName?: string
}

export enum FinancialInstitutionFieldNames {
    FullBankName = 'FullBankName',
    Name = 'Name',
    LegacyName = 'LegacyName',
    AbaNumber = 'AbaNumber',
    PhoneNumber = 'PhoneNumber',
    FractionalNumber = 'FractionalNumber',
    HeadquartersAddress = 'HeadquartersAddress',
    MailingAddress = 'MailingAddress',
}



export interface SaveFinancialInstitutionRequest {
    id?: number
    name?: string
    legacyName?: string
    phoneNumber?: string
    fractionalNumber?: string
    headquartersAddress?: Address
    mailingAddress?: Address
}

export interface ValidateFinancialInstitutionInfoRequest {
    aba: string,
    bankName: string
}

export interface ValidateFinancialInstitutionResponse {
    financialInstitutionInfoList?: ValidatedFinancialInstitutionInfo[],
    isValid: boolean
}

export interface ValidatedFinancialInstitutionInfo {
    fullBankName: string
    name: string
    headquartersAddress: Address
}