import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Configuration} from "../components/input/configuration/Configuration";
import {RootState} from "../app/store";
import {concat, filter, flow, groupBy, identity, map, overArgs, reduce, sortBy, values} from "lodash/fp";
import {CurrencyFormatter} from "../utils/CurrencyFormatter";
import {calculatePrice} from "../utils/Utils";
import {SiteProductVariantQuantityOption} from "../components/product/SiteProductVariantQuantityOption";
import {SiteProductVariant} from "../components/product/SiteProductVariant";
import {
    allConfigs,
    configGroupKey,
    configPagingKey,
    configurationHasPriceChange,
    hasGroup,
    hasPaging,
    makeGroup,
    makePage,
    makeReviewPage,
    ProductConfigurationPage,
    sortOrder
} from "./model";

interface ProductConfigurationState {
    currentPage: number;
    quantityOption?: SiteProductVariantQuantityOption;
    siteProductVariant?: SiteProductVariant;
    pages: ProductConfigurationPage[]
}

const initialState: ProductConfigurationState = {
    currentPage: 0,
    quantityOption: undefined,
    siteProductVariant: undefined,
    pages: []
};

export const productConfigurationSlice = createSlice({
        name: 'productConfiguration',
        initialState,
        reducers: {
            setProductConfigurations: (state, {payload}: PayloadAction<Configuration[]>) => {
                state.pages = concat(
                    organize(payload),
                    makeReviewPage()
                )
            },
            setQuantityOption: (state, {payload}: PayloadAction<SiteProductVariantQuantityOption>) => {
                state.quantityOption = payload;
            },
            setSiteProductVariant: (state, {payload}: PayloadAction<SiteProductVariant | undefined>) => {
                state.siteProductVariant = payload;
            },
            nextPage: state => {
                if (state.pages.length - 1 > state.currentPage) {
                    state.currentPage++;
                }
            },
            previousPage: state => {
                if (state.currentPage > 0) {
                    state.currentPage--;
                }
            }
        }
    }
)
export const getProductConfigurationPages = ({productConfiguration: {pages}}: RootState) => {
    return pages;
};
export const getCurrentPage = ({productConfiguration: {pages, currentPage}}: RootState) => {
    return pages[currentPage];
}

export const getConfigsWithPriceChange = ({productConfiguration}: RootState) => filter(
    configurationHasPriceChange, 
    allConfigs(productConfiguration.pages)
);

export const getPrice = (state: RootState) => {
    if (state.productConfiguration.quantityOption) {
        return CurrencyFormatter.format(
            calculatePrice(
                allConfigs(state.productConfiguration.pages),
                state.productInputs.values ?? [],
                state.productConfiguration.quantityOption
            )
        );
    }
}

export const getQuantityOption = (state: RootState) => {
    if (state.productConfiguration.quantityOption) {
        return state.productConfiguration.quantityOption;
    }
}

export const getSiteProductVariant = ({productConfiguration: {siteProductVariant}}: RootState) =>
    siteProductVariant

export const {
    setProductConfigurations,
    setQuantityOption,
    setSiteProductVariant,
    nextPage,
    previousPage
} = productConfigurationSlice.actions;

export default productConfigurationSlice.reducer;


/**
 * Type for a function that will take a collection of Configurations
 * and return a list of ProductConfigurationPages with a
 * Page -> Group -> Configuration hierarchy
 */
type Organize = (configs: readonly Configuration[]) => ProductConfigurationPage[];

/**
 * Organizes a collection of Configurations into a Page -> Group -> Configuration
 * hierarchy.
 * 
 * This function realise on lodash/fp and utilizes currying and flow.
 * 
 * @param configs: Configuration[] 
 */
const organize: Organize = flow(
    // Filter out any configuration without a page
    filter(hasPaging),
    
    // Group the configurations by what page they are on
    // This will return an object with the shape
    // { [pageKey]: Configuration[] }
    // with all configurations belonging to the page
    groupBy(configPagingKey),
    
    // Since all the values of the object will belong to the same page
    // we can disregard the pageKey/prop name and just worry about the
    // configurations. We will actually grab the page from the list later
    values,
    
    // Each item is a list of configurations all belonging to the same
    // page. Now we need to do a similar grouping by group
    map(
        // flow is returning a function that accepts a Configuration[]
        // and returns a ProductConfigurationGroup[] with each group
        // containing the appropriate configurations
        flow(
            filter(hasGroup),
            groupBy(configGroupKey),
            values,
            reduce(overArgs(concat, [
                identity,
                makeGroup
            ]), [])
        )
    ),
    
    // Reduce a list of ProductConfigurationGroup[] to a list of 
    // ProductConfigurationPage[] by concatenating (appending) a new 
    // page created from the list of groups
    // overArgs creates a function that calls the provided function
    // after using the transformer functions to transform the arguments
    // identity simply returns the value passed to it
    // the function from overArgs will be equivalent to
    // (pages, groups) => concat(pages, makePage(groups))
    reduce(overArgs(concat, [
        identity,
        makePage
    ]), []),
    sortBy<ProductConfigurationPage>(sortOrder),
);
