import './Reorder.css';
import {LoginForm} from '../../user/login/loginForm/LoginForm';
import {BrowserStorageType, fetchStorageValue} from '../../../utils/Utils';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Container} from 'reactstrap';

export const Reorder = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userInfoLocalStorageKey = 'ProductCatalogUserInfo-value';
    const consumer = fetchStorageValue(userInfoLocalStorageKey, BrowserStorageType.LocalStorage);

    useEffect(() => {
        if (consumer) {
            navigate(`/profile/orders`)
        }
    }, [navigate, consumer]);

    return (
        <span className='reorder-page d-flex'>
          <Container className='reorder-page-container d-flex flex-column'>
            <span className='reorder-content d-flex flex-column'>
              <div className='login-form-container'>
                <LoginForm title={t(`pages.reorder.title`)} showSignUp={false} redirectLink='/profile/orders' />
              </div>
            </span>
          </Container>
        </span>
    );
}
