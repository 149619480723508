import React, {Component, ErrorInfo, ReactNode} from "react";
import {StorefrontErrorMessage} from "./message/StorefrontErrorMessage";

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class StorefrontErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // Log error, it can be found and investigated via full story
        console.error('Error caught by StorefrontErrorBoundary:', error, errorInfo);
    }

    componentDidUpdate(prevProps: ErrorBoundaryProps) {
        // Reset hasError if the children prop has changed
        if (this.props.children !== prevProps.children) {
            this.setState({ hasError: false });
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <StorefrontErrorMessage/>
            );
        }
        return this.props.children;
    }
}

export default StorefrontErrorBoundary;

