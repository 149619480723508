
import './ConfigurationPageNavigation.css';
import {ConfigurationPaging} from "../ConfigurationPaging";

interface ConfigurationPageNavigationProps {
    pages: ConfigurationPaging[],
    currentPageIndex: number,
    onSelectedPageChange: (pageNumber: number) => void,
    isReadOnly: boolean,
}

export const ConfigurationPageNavigation = ({ pages, currentPageIndex, onSelectedPageChange, isReadOnly }: ConfigurationPageNavigationProps) => {

    function onPageSelected(index: number) {
        onSelectedPageChange(index);
    }

    function isPageSelected(index: number) {
        return index === currentPageIndex;
    }

    if (isReadOnly)
        return <></>;

    return (
        <div className="page-list-container">
            {pages.map((page, index) => {
                return (
                    <div
                        key={page.pagingKey}
                        onClick={(e) => onPageSelected(index)}
                        className={`page-control ${isPageSelected(index) ? 'selected-page' : 'unselected-page'}`}
                    >
                        <span className="step-label">Step {index + 1}:</span> {page.name}</div>
                )
            })}
        </div>
    )
}
