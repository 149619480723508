import './MicrTextInput.css';
import {FormFeedback, Input} from "reactstrap";
import {createArrayOfNumbersUsingMinAndMax, Regexes, replaceStringValueAtIndex} from "../../../../utils/Utils";
import {
    MICR_ACCOUNT_NUMBER_CHARACTER,
    MICR_FORMATTING_ALLOWED_CHARACTERS,
    MICR_FORMATTING_BLANK_CHARACTERS,
    MicrFormatEdit,
    MicrFormatConstraints,
    MicrFormatFieldNames, MICR_OPTIONAL_ACCOUNT_NUMBER_CHARACTER
} from "../MicrFormat";
import React, {useEffect, useState} from "react";
import {useGetOrderProductAccountNumberLengthByIdQuery} from "../../../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/query";

interface MicrTextInputProps {
    textValue: string,
    micrFormat: MicrFormatEdit,
    onChange?: (micrFieldName: MicrFormatFieldNames, value?: string) => void,
    isReadOnly: boolean
}

export const MicrTextInput = ({ textValue, micrFormat, onChange, isReadOnly }: MicrTextInputProps) => {

    const [textEditValue, setTextEditValue] = useState<string>(textValue);
    const micrLengthRange = createArrayOfNumbersUsingMinAndMax(0, MicrFormatConstraints.MicrTextLength - 1);
    const [brokenRuleText, setBrokenRuleText] = useState<string | undefined>(undefined);
    const [currentFocusIndex, setCurrentFocusIndex] = useState<number | undefined>(undefined);
    const {data: accountNumberLength, isLoading: isLoadingAccountNumberLength} = useGetOrderProductAccountNumberLengthByIdQuery(micrFormat.orderProductId ?? skipToken);

    useEffect(() => {
        setTextEditValue(textValue);
    }, [textValue]);

    useEffect(() => {
        setMicrInvalidState(micrFormat);
    }, [micrFormat]);

    const getMicrTextValueAtIndex = (micrRequestedIndex: number) => {
        const micrValueAtIndex = textEditValue[micrRequestedIndex];
        return micrValueAtIndex ?? "";
    }

    const isMicrFormatValid = () => {
        return brokenRuleText === undefined;
    }

    const setMicrInvalidState = (micrFormatToValidate: MicrFormatEdit) => {

        const requiredAccountNumberMatchString = `${MICR_ACCOUNT_NUMBER_CHARACTER}`;
        const minRegExp = new RegExp(requiredAccountNumberMatchString, "g");
        const requiredAccountNumberCount = micrFormatToValidate.formatTemplate.match(minRegExp)?.length ?? 0;
        // Use account number length if order product specific, otherwise min length value, otherwise match length
        const accountNumberMinLength = micrFormatToValidate.orderProductId ? accountNumberLength ?? 0 :
            (!micrFormatToValidate.usesAccountNumberMatch ?
                micrFormatToValidate.accountNumberMinLength ?? 0 :
                (micrFormatToValidate.accountNumberMatch ? micrFormatToValidate.accountNumberMatch.toString().length : 0));


        const optionalAccountNumberMatchString = `${MICR_OPTIONAL_ACCOUNT_NUMBER_CHARACTER}`;
        const optionalRegExp = new RegExp(optionalAccountNumberMatchString, "g");
        const totalAccountNumberCount = (micrFormatToValidate.formatTemplate.match(optionalRegExp)?.length ?? 0) + requiredAccountNumberCount;
        // Use account number length if order product specific, otherwise max length value, otherwise match length
        const accountNumberMaxLength = micrFormatToValidate.orderProductId ? accountNumberLength ?? 0 :
            (!micrFormatToValidate.usesAccountNumberMatch ?
                micrFormatToValidate.accountNumberMaxLength ?? 0 :
                (micrFormatToValidate.accountNumberMatch ? micrFormatToValidate.accountNumberMatch.toString().length : 0));

        // If order product specific override without a templated format, bypass these rules
        if ((micrFormatToValidate.orderProductId && (totalAccountNumberCount < 1)) || isLoadingAccountNumberLength){
            setBrokenRuleText(undefined);
            return;
        }

        if (requiredAccountNumberCount < accountNumberMinLength) {
            setBrokenRuleText(`The minimum account number length is ${accountNumberMinLength}`);
            return;
        }
        else if (totalAccountNumberCount > accountNumberMaxLength) {
            setBrokenRuleText(`The maximum account number length is ${accountNumberMaxLength}`);
            return;
        }
        else if (totalAccountNumberCount < accountNumberMaxLength) {
            setBrokenRuleText(`The maximum account number length is ${accountNumberMaxLength}, please add more optional characters`);
            return;
        }

        setBrokenRuleText(undefined);
    }

    const onMicrIndexFocusOrBlur = (isFocused: boolean, index: number) => {
        if (isFocused) {
            setCurrentFocusIndex(index);
        }
        else {
            setCurrentFocusIndex(undefined);
        }
    }

    const micrEditValueOnChange = (value: string) => {
        if (onChange)
            onChange(MicrFormatFieldNames.MicrFormatText, textEditValue);
    }

    const micrEditAtIndex = (micrEditIndex: number, value: string) => {
        const trimmedValue = value.trim().toUpperCase();
        const cleansedValue = cleanseValue(trimmedValue);
        const isInvalid = !Regexes.number.test(cleansedValue) && MICR_FORMATTING_ALLOWED_CHARACTERS.findIndex(c => c === cleansedValue) < 0;
        // Ignore invalid characters
        if (!isInvalid) {
            let tempMicrEdit = replaceStringValueAtIndex(textEditValue, micrEditIndex, cleansedValue);
            setTextEditValue(tempMicrEdit);
            takeSpecialCharacterAction(micrEditIndex, 'right');
        }
        else {
            takeSpecialCharacterAction(micrEditIndex, value);
        }
    }

    const takeSpecialCharacterAction = (micrEditIndex: number, value: string) => {
        if (value.toLowerCase().indexOf('left') > -1 && micrEditIndex > 0)
            document.getElementById(`micr-text-input-${(micrEditIndex - 1)}`)?.focus();

        else if (value.toLowerCase().indexOf('right') > -1 && micrEditIndex < MicrFormatConstraints.MicrTextLength)
            document.getElementById(`micr-text-input-${(micrEditIndex + 1)}`)?.focus();

        else if (value.toLowerCase().indexOf('delete') > -1) {
            let tempMicrEdit = replaceStringValueAtIndex(textEditValue, micrEditIndex, " ");
            setTextEditValue(tempMicrEdit);
        }
    }

    const cleanseValue = (value: string) => {
        // Turn supported characters to blank space, like Enter, Backspace, etc
        let cleansedValue = MICR_FORMATTING_BLANK_CHARACTERS.findIndex(c => c === value.toLowerCase()) > -1 ? ' ' : value.substring(value.length - 1, value.length);
        return cleansedValue;
    }

    const getMicrIndexText = (index: number) => {
        return (index % 5 > 0 && index !== (MicrFormatConstraints.MicrTextLength) && currentFocusIndex !== index)
            ? '.' : (MicrFormatConstraints.MicrTextLength - index);
    }

    const getMicrIndexTextClasses = (index: number) => {
        let returnClasses = "micr-text-input-index-text";
        if (currentFocusIndex === index) {
            returnClasses += " micr-text-input-index-text-focused";
        }
        return returnClasses;
    }

    return (
        <div className="micr-text-input-container">
            {micrLengthRange.map((micrIndex) => {
                return (
                    <div className="micr-text-input-column"
                         key={`micr-text-input-${micrIndex}`}>
                        <Input type="text"
                               id={`micr-text-input-${micrIndex}`}
                               className="micr-text-input-value"
                               disabled={isReadOnly}
                               onKeyDown={(e) => micrEditAtIndex(micrIndex, e.key)}
                               onChange={(e) => micrEditValueOnChange(e.target.value)}
                               invalid={!isReadOnly && !isMicrFormatValid()}
                               onBlur={(e) => onMicrIndexFocusOrBlur(false, micrIndex)}
                               onFocus={(e) => onMicrIndexFocusOrBlur(true, micrIndex)}
                               value={getMicrTextValueAtIndex(micrIndex)}></Input>
                        <span className={getMicrIndexTextClasses(micrIndex)}>{getMicrIndexText(micrIndex)}</span>
                    </div>
                );
            })}
            <FormFeedback className={!isReadOnly && !isMicrFormatValid() ? 'show-tooltip' : ''} tooltip>
                {brokenRuleText}
            </FormFeedback>
        </div>
    )
}