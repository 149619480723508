import './Guarantee.css';
import {useTranslation} from "react-i18next";
import ScrollToTop from '../../helpers/ScrollToTop';
import { Breadcrumb } from '../../breadcrumb/Breadcrumb';
import { Container } from 'reactstrap';

export const Guarantee = () => {
    const { t } = useTranslation();
    
    let securityText = [];
    for (let i = 0; i < 8; i++) {
        securityText.push(<span>{t(`pages.guarantee.security.item${i}`)}</span>)
    }

    return (
        <span className='guarantee-page d-flex flex-column'>
            <ScrollToTop></ScrollToTop>
            <Container>
                <Breadcrumb currentPage={t(`pages.guarantee.title`)}></Breadcrumb>
                <span className='guarantee-container d-flex flex-column'>
                    <span className='guarantee-title d-flex'>
                        <h1 className=''>{t(`pages.guarantee.title`)}</h1>
                    </span>
                    <span className='guarantee-body d-flex flex-column'>
                        <span className='guarantee-body-text d-flex flex-column'>
                            <span>{t(`pages.guarantee.satisfactionTitle`)}</span>
                            <span>{t(`pages.guarantee.satisfactionBody`)}</span>
                        </span>
                        <span className='guarantee-body-text d-flex flex-column'>
                            <span>{t(`pages.guarantee.privacyTitle`)}</span>
                            <span>{t(`pages.guarantee.privacyBody1`)}</span>
                            <br />
                            <span>{t(`pages.guarantee.privacyBody2`)}</span>
                        </span>
                        <span className='guarantee-body-text d-flex flex-column'>
                            {securityText}
                        </span>
                    </span>
                </span>
            </Container>
        </span>
    );
};