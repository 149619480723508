import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Spinner } from "reactstrap";
import { useGetCustomOverlayQuery, useGetImageHostQuery, useUploadCustomOverlayMutation } from "../../../app/apiSlice";
import { CartIdType } from "../../cart/CartSlice";
import styles from './CustomOverlayManager.module.css';
import { useRef, useState } from "react";
import { getErrorMessage, readFileToBase64 } from "../../../utils/Utils";
import { useAppDispatch } from "../../../app/hooks";
import { showInformationModal } from "../../modal/ModalSlice";

interface CustomOverlayManagerProps {
    orderId: number;
    cartId?: CartIdType;
    consumerId: number;
    locked: boolean;
}
export const CustomOverlayManager = ({ orderId, cartId, consumerId, locked }: CustomOverlayManagerProps) => {
    const { data: host } = useGetImageHostQuery();
    const { data: currentOverlay } = useGetCustomOverlayQuery({ orderId, cartId });
    const [ uploadCustomOverlay, { isLoading: isAttachingToOrder }] = useUploadCustomOverlayMutation();
    const dispatch = useAppDispatch();

    const [ fileName, setFileName ] = useState<string>();
    const [ fileBase64, setFileBase64 ] = useState<string>();
    const [ imgRefresh, setImgRefresh ] = useState(new Date().getTime());
    const [ isInManualEditMode, setIsInManualEditMode ] = useState(false);
    const [ lastImageLoadWasSuccessful, setLastImageLoadWasSuccessful ] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const isInReadOnlyMode = !!(locked || (!cartId && currentOverlay && !isInManualEditMode));
    const curFileName = fileName ?? currentOverlay?.imageFileName ?? '';
    const isMissingRequiredFields = !(curFileName && (lastImageLoadWasSuccessful || fileBase64));
    const noChanges = currentOverlay !== undefined && curFileName === currentOverlay?.imageFileName && !fileBase64;

    const fileSelected = async (file: File) => {
        setFileName(file.name);
        setFileBase64(await readFileToBase64(file));
    }

    const clearFile = () => {
        setFileName(undefined);
        setFileBase64(undefined);
        if (fileInputRef.current) fileInputRef.current.value = ''; // reset file picker
    }

    const attachToOrder = async () => {
        // validate
        if (isMissingRequiredFields || !currentOverlay) return;

        try {
            await uploadCustomOverlay({
                fileName: curFileName,
                softwareName: currentOverlay.printingSoftwareName,
                numSignatureLines: currentOverlay.numSignatureLines,
                fileBase64,
                consumerId,
            }).unwrap();
            setImgRefresh(new Date().getTime());
            setIsInManualEditMode(false);
        } catch (e: any) {
            dispatch(showInformationModal({
                title: 'Upload Failed',
                text: 'Error uploading custom overlay: ' + getErrorMessage(e)
            }));
        }
    }
    
    if (!currentOverlay?.orderOrCartCanUseCustomOverlay ?? false) {
        return <></>; // can't use a custom overlay for this order
    }
    
    return (
        <Card>
            <CardHeader>
                <h5>Custom Overlay</h5>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label className='w-100'>Printing Software:
                                <Input disabled value={currentOverlay?.printingSoftwareName}/>
                            </Label>
                        </FormGroup>
                        <FormGroup>
                            <Label className='w-100'>Number of Signature Lines:
                                <Input disabled value={currentOverlay?.numSignatureLines}/>
                            </Label>
                        </FormGroup>
                        <FormGroup>
                            <Label className="w-100">File Name:
                                <Input disabled={isInReadOnlyMode || !!fileBase64}
                                       value={curFileName}
                                       onChange={e => setFileName(e.target.value)}/>
                            </Label>
                        </FormGroup>
                        <FormGroup>
                            <Label className="w-100">File Upload:
                            <InputGroup>
                                <Input innerRef={fileInputRef}
                                       disabled={isInReadOnlyMode}
                                       type='file'
                                       onChange={e => fileSelected(e.target.files![0])}/>
                                { !isInReadOnlyMode &&
                                    <InputGroupText>
                                        <Button onClick={clearFile} size='sm' color='danger' className='py-0'>Clear</Button>
                                    </InputGroupText>
                                }
                            </InputGroup>
                            </Label>
                        </FormGroup>

                        { /* Buttons */}
                        { isInReadOnlyMode && <Button color='primary' onClick={() => setIsInManualEditMode(true)}>Edit</Button>}
                        { !isInReadOnlyMode &&
                            <Button color='primary'
                                    disabled={isAttachingToOrder || isMissingRequiredFields || noChanges}
                                    onClick={attachToOrder}>
                                Attach to Order
                                { isAttachingToOrder && <Spinner/>}
                            </Button>}
                    </Col>
                    <Col>
                        <div className={styles.overlayPreviewContainer}>
                            <img className={styles.overlayPreview}
                                 onLoad={() => setLastImageLoadWasSuccessful(true)}
                                 onError={() => setLastImageLoadWasSuccessful(false)}
                                 src={`${host}/overlay/${curFileName}?date=${imgRefresh}`}
                                 alt=""/>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}