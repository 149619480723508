
import './ShippingDisclaimer.css';
import {useTranslation} from "react-i18next";


export const ShippingDisclaimer = () => {
    const { t } = useTranslation();

    return (
        <span className="shipping-disclaimer-container">
            {t('shipping.disclaimer.message')}
        </span>
    )
}
