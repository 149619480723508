import {useGetOrderHistoryQuery} from "../../../../../app/apiSlice";
import {GridPage} from "../../../shared/GridPage";
import {ColumnConfig, GridConfig} from "../../../shared/Grid";
import {OrderHistory} from "./OrderHistory";
import React, {useEffect, useState} from "react";
import {SortState} from "../../../../../utils/Grid";
import {Utils} from "../../../../../utils/Utils";
import {DateTime} from "luxon";
import "./HistoryTab.css";
import {LoadingSpinner} from "../../../../input/utils/LoadingSpinner";

interface HistoryTabProps {
  orderId: number
}
export const HistoryTab = ({ orderId }: HistoryTabProps) => {
  const { data: queryHistory, isLoading } = useGetOrderHistoryQuery(orderId);
  const [ sort, setSort ] = useState<SortState<OrderHistory>>({ column: 'eventDateLocal', direction: 'desc' });
  const [ sortedHistory, setSortedHistory ] = useState(queryHistory);

  // sort
  useEffect(() => {
    setSortedHistory([...queryHistory ?? []].sort(Utils.sortBy(sort.column, sort.direction)));
  }, [sort, queryHistory]);
  
  const config: GridConfig<OrderHistory> = {
    configSections: [
      {
        columns: [
          new ColumnConfig({
            field: 'userEmail',
            display: 'User',
          }),
          new ColumnConfig({
            field: 'eventDateLocal',
            display: 'Date',
            cellDisplay: val => DateTime.fromISO(val as string).toLocaleString(DateTime.DATETIME_SHORT)
          }),
          new ColumnConfig({
            field: 'orderStatus',
            display: 'Status',
          }),
          new ColumnConfig({
            field: 'changeDescription',
            display: 'Description',
            cellDisplay: val => val ? <p className="history-row-text-body">{val as string}</p> : ''
          }),
          new ColumnConfig({
            field: 'changeType',
            display: 'Type',
          }),
          new ColumnConfig({
            field: 'unitNumber',
            display: 'Unit #',
          }),
          new ColumnConfig({
            field: 'batch',
            display: 'Batch',
          }),
          new ColumnConfig({
            field: 'container',
            display: 'Container',
          })
        ]
      }
    ]
  }
  
  if (isLoading)
    return <LoadingSpinner size="sm" />
  
  return (<GridPage
    gridConfig={config}
    records={sortedHistory ?? []}
    sort={sort}
    onSortChanged={setSort}
    shouldShowCriteriaPanel={false}
    />);
}