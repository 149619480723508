import {useFetchShippingOptionsQuery, useGetCartProductsQuery} from "../../app/apiSlice";
import {FormGroup, Input, Label, Spinner} from "reactstrap";
import React, {useEffect, useState} from "react";
import {CurrencyFormatter} from "../../utils/CurrencyFormatter";
import {Address} from "../input/address/Address";
import {ShippingOptionsRequest} from "./ShippingOptionsRequest";
import {ShippingOption} from "./ShippingOption";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {CartIdType} from "../cart/CartSlice";
import {useDebounce} from "../../app/hooks";
import {useTranslation} from "react-i18next";
import {isAddressEmpty} from "../../utils/Utils";
import './ShippingOptionSelector.css';

interface ShippingOptionSelectorProps {
    value?: number,
    toAddress?: Address,
    cartId?: CartIdType,
    orderId?: number,
    consumerId?: number,
    onChange: (shippingOption?: ShippingOption) => void,
    onFetchingShippingOptions: (isFetching: boolean) => void,
    locked?: boolean,
}

export const ShippingOptionSelector = ({value, toAddress, cartId, orderId, consumerId, onChange, onFetchingShippingOptions, locked}: ShippingOptionSelectorProps) => {

    const { t } = useTranslation();
    // Including cartProducts so that cartProduct changes will trigger shipping options refetch
    const { data: cartProducts } = useGetCartProductsQuery(cartId ?? skipToken);
    const [shippingOptionsRequest, setShippingOptionsRequest] = useState<ShippingOptionsRequest | undefined>(undefined)
    const debouncedRequest = useDebounce(shippingOptionsRequest, 2_000);
    const { data: options, isFetching, error: optionsError} = useFetchShippingOptionsQuery(debouncedRequest ?? skipToken);
    const [shippingOptions, setShippingOptions] = useState<ShippingOption[] | undefined>(undefined);
    const isDebouncing = JSON.stringify(shippingOptionsRequest) !== JSON.stringify(debouncedRequest);
    const [lastSelectedShippingOptionId, setLastSelectedShippingOptionId] = useState<number | undefined>(undefined);

    let noOptionText;
    if (optionsError && (toAddress?.street?.toLowerCase()?.includes('po box') ?? false)) noOptionText = t('shipping.noShippingOptionsPoBox');
    else if (isAddressEmpty(toAddress)) noOptionText = t('shipping.provideShippingAddress');
    else noOptionText = t('shipping.noShippingOptions');

    function updateShippingOptionsRequest(toAddress: Address) {
        setShippingOptionsRequest({cartId, orderId, toAddress, consumerId, timestamp: Date.now()});
    }

    useEffect(() => {
        onFetchingShippingOptions(isFetching || isDebouncing);
    }, [isFetching, isDebouncing]);

    useEffect(() => {
        if(!isDebouncing) {
            setShippingOptions(options);
        }
        else {
            setShippingOptions(undefined);
        }
    }, [options]);

    useEffect(() => {
        if (value && shippingOptions && shippingOptions?.length > 0  && !isDebouncing && !isFetching) {
            onChange(shippingOptions.find(so => so.id === value) ?? shippingOptions.find(so => so.isDefault) ?? shippingOptions[0]);
        }
        else if (!value && shippingOptions && shippingOptions?.length > 0  && !isDebouncing && !isFetching) {
            onChange(shippingOptions.find(so => so.id === lastSelectedShippingOptionId || so.isSelected) ?? shippingOptions.find(so => so.isDefault) ?? shippingOptions[0]);
        }
        else if (value && ((!shippingOptions || shippingOptions.length === 0) || isDebouncing || isFetching)) {
            onChange(undefined);
        }
    }, [value, shippingOptions, isFetching]);

    // update the shipping options request when toAddress changes, but make sure that we have all parts of the address & cartId or orderId
    useEffect(() => {
        if (toAddress?.street && toAddress?.city && toAddress?.zip && toAddress?.stateCode && (cartId !== undefined || orderId !== undefined)) {
            setShippingOptions(undefined);
            updateShippingOptionsRequest(toAddress);
        } else {
            setShippingOptionsRequest(undefined);
            setShippingOptions(undefined);
        }
    }, [cartId, orderId, toAddress, cartProducts, setShippingOptionsRequest, setShippingOptions]);

    const getShippingOptionById = (shippingOptionId?: number) => {
        const shippingOptionIndex = shippingOptions?.findIndex(so => so.id === shippingOptionId);
        return shippingOptionIndex !== undefined && shippingOptionIndex > -1 && shippingOptions && shippingOptions[shippingOptionIndex]
            ? shippingOptions[shippingOptionIndex] : undefined;
    }

    const onShippingOptionSelected = (shippingOption?: ShippingOption) => {
        setLastSelectedShippingOptionId(shippingOption?.id);
        onChange(shippingOption);
    }

    return (
        <FormGroup>
            <Label for='shipping'>Shipping {(isFetching || isDebouncing) && <Spinner size='sm'>Loading...</Spinner> }</Label>
            <Input type='select'
                   disabled={locked ?? false}
                   value={value ?? -1}
                   onChange={e => onShippingOptionSelected(getShippingOptionById(Number.parseInt(e.target.value)))}
                   placeholder="No available shipping options">
                {!isFetching && shippingOptions?.map(so => (
                  <option key={so.id} value={so.id}>{so.displayName} - {CurrencyFormatter.format(so.retailPrice)}</option>
                ))}
            </Input>
            { !isFetching && !isDebouncing && (!shippingOptions || shippingOptions.length === 0) &&
                <div className='shipping-option-selector.no-options-label'>{noOptionText}</div>
            }
        </FormGroup>
    );
}