import './About.css';
import {useTranslation} from "react-i18next";
import ScrollToTop from '../../helpers/ScrollToTop';
import { Breadcrumb } from '../../breadcrumb/Breadcrumb';
import {Container} from 'reactstrap';
import {doesTranslationValueExist, getTranslationValue} from "../../../utils/Utils";
import {Helmet} from "react-helmet-async";

export const About = () => {
  const { t } = useTranslation();

  function getDefaultDocumentMetadata () {
    return (
        <Helmet>
          <title>{t(`pages.about.metaTitle`)}</title>
          <meta name="description" content={t(`pages.about.metaDescription`)} />
        </Helmet>
    )
  }

  return (
    <span className='about-page d-flex flex-column'>
            <ScrollToTop></ScrollToTop>
            {getDefaultDocumentMetadata()}
            <Container>
                <Breadcrumb currentPage={t(`pages.about.title`)}></Breadcrumb>
                <span className='about-container d-flex flex-column'>
                    <span className='about-title d-flex'>
                      <h1>{t(`pages.about.title`)}</h1>
                    </span>
                    <span className='about-body d-flex flex-column'>
                      {doesTranslationValueExist(t, `pages.about.introTitle`) && (
                        <span className='about-content-title'>{t(`pages.about.introTitle`)}</span>
                      )}
                      {doesTranslationValueExist(t, `pages.about.introText1`) && (
                        <p>{getTranslationValue(t, 'pages.about.introText1')}</p>
                      )}
                        {doesTranslationValueExist(t, `pages.about.introText2`) && (
                            <p>{getTranslationValue(t, 'pages.about.introText2')}</p>
                        )}
                      <span className='d-flex flex-column'>
                        {doesTranslationValueExist(t, 'pages.about.section1') && (
                          <span className='d-flex flex-column'>
                          <span className='about-content-subtitle'>{getTranslationValue(t, 'pages.about.section1.title')}</span>
                            {doesTranslationValueExist(t, 'pages.about.section1.body1') && <p>{getTranslationValue(t, 'pages.about.section1.body1')}</p>}
                            {doesTranslationValueExist(t, 'pages.about.section1.body2') && <p>{getTranslationValue(t, 'pages.about.section1.body2')}</p>}
                            {doesTranslationValueExist(t, 'pages.about.section1.body3') && <p>{getTranslationValue(t, 'pages.about.section1.body3')}</p>}
                            {doesTranslationValueExist(t, 'pages.about.section1.body4') && <p>{getTranslationValue(t, 'pages.about.section1.body4')}</p>}
                        </span>
                        )}
                      </span>
                      {doesTranslationValueExist(t, 'pages.about.section2') && (
                        <span className='d-flex flex-column'>
                          {doesTranslationValueExist(t, `pages.about.titleHistory`) && (
                            <span className='about-content-main-title'>{getTranslationValue(t, 'pages.about.titleHistory')}</span>
                          )}
                          <span className='about-content-subtitle'>{getTranslationValue(t, 'pages.about.section2.title')}</span>
                          {doesTranslationValueExist(t, 'pages.about.section2.body1') && <p>{getTranslationValue(t, 'pages.about.section2.body1')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section2.body2') && <p>{getTranslationValue(t, 'pages.about.section2.body2')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section2.body3') && <p>{getTranslationValue(t, 'pages.about.section2.body3')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section2.body4') && <p>{getTranslationValue(t, 'pages.about.section2.body4')}</p>}
                        </span>
                      )}
                      {doesTranslationValueExist(t, 'pages.about.section3') && (
                        <span className='d-flex flex-column'>
                          <span className='about-content-subtitle'>{getTranslationValue(t, 'pages.about.section3.title')}</span>
                          {doesTranslationValueExist(t, 'pages.about.section3.body1') && <p>{getTranslationValue(t, 'pages.about.section3.body1')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section3.body2') && <p>{getTranslationValue(t, 'pages.about.section3.body2')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section3.body3') && <p>{getTranslationValue(t, 'pages.about.section3.body3')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section3.body4') && <p>{getTranslationValue(t, 'pages.about.section3.body4')}</p>}
                        </span>
                      )}
                      {doesTranslationValueExist(t, 'pages.about.section4') && (
                        <span className='d-flex flex-column'>
                          <span className='about-content-subtitle'>{getTranslationValue(t, 'pages.about.section4.title')}</span>
                          {doesTranslationValueExist(t, 'pages.about.section4.body1') && <p>{getTranslationValue(t, 'pages.about.section4.body1')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section4.body2') && <p>{getTranslationValue(t, 'pages.about.section4.body2')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section4.body3') && <p>{getTranslationValue(t, 'pages.about.section4.body3')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section4.body4') && <p>{getTranslationValue(t, 'pages.about.section4.body4')}</p>}
                        </span>
                      )}
                      {doesTranslationValueExist(t, 'pages.about.section5') && (
                        <span className='d-flex flex-column'>
                          <span className='about-content-subtitle'>{getTranslationValue(t, 'pages.about.section5.title')}</span>
                          {doesTranslationValueExist(t, 'pages.about.section5.body1') && <p>{getTranslationValue(t, 'pages.about.section5.body1')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section5.body2') && <p>{getTranslationValue(t, 'pages.about.section5.body2')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section5.body3') && <p>{getTranslationValue(t, 'pages.about.section5.body3')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section5.body4') && <p>{getTranslationValue(t, 'pages.about.section5.body4')}</p>}
                        </span>
                      )}
                      {doesTranslationValueExist(t, 'pages.about.section6') && (
                        <span className='d-flex flex-column'>
                          <span className='about-content-subtitle'>{getTranslationValue(t, 'pages.about.section6.title')}</span>
                          {doesTranslationValueExist(t, 'pages.about.section6.body1') && <p>{getTranslationValue(t, 'pages.about.section6.body1')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section6.body2') && <p>{getTranslationValue(t, 'pages.about.section6.body2')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section6.body3') && <p>{getTranslationValue(t, 'pages.about.section6.body3')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section6.body4') && <p>{getTranslationValue(t, 'pages.about.section6.body4')}</p>}
                        </span>
                      )}
                      {doesTranslationValueExist(t, 'pages.about.section7') && (
                        <span className='d-flex flex-column'>
                          <span className='about-content-subtitle'>{getTranslationValue(t, 'pages.about.section7.title')}</span>
                          {doesTranslationValueExist(t, 'pages.about.section7.body1') && <p>{getTranslationValue(t, 'pages.about.section7.body1')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section7.body2') && <p>{getTranslationValue(t, 'pages.about.section7.body2')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section7.body3') && <p>{getTranslationValue(t, 'pages.about.section7.body3')}</p>}
                          {doesTranslationValueExist(t, 'pages.about.section7.body4') && <p>{getTranslationValue(t, 'pages.about.section7.body4')}</p>}
                        </span>
                      )}
                    </span>
                </span>
            </Container>
        </span>
  );
};
