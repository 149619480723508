import {Button, Card, CardBody, CardHeader, Spinner, Table} from "reactstrap";
import {
    useAddProductToCartMutation,
    useGetCartExistsQuery,
    useGetCartProductsPricingQuery,
    useGetCartProductsQuery,
    useGetOrderProductsQuery,
    useRemoveProductFromCartMutation
} from "../../../../../app/apiSlice";
import {OrderProductRow} from "./OrderProductRow";
import "./SelectedOrderProductTable.css"
import {useEffect, useState} from "react";
import {FindProduct, ProductConfigurationState} from "../../../product/FindProduct";
import {CartIdType, forgetOrderEditCartId} from "../../../../cart/CartSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {CartProductRow} from "../../../product/CartProductRow";
import {CartProductVM} from "../../../../cart/CartProduct";
import {useAppDispatch} from "../../../../../app/hooks";
import {OrderProductVm} from "../../../../order/OrderProductVm";
import {VendorOrderProductStatusOverview} from "../../../vendor/VendorOrderProductStatusOverview";

export interface SelectedOrderProductTableProps {
  siteId: number,
  consumerId: number,
  orderId: number,
  locked?: boolean,
  orderEditCartId?: CartIdType,
  vendorProductStatusOverview?: VendorOrderProductStatusOverview,
  onProductAddedToCart: (cartId: CartIdType) => void,
  updateOrderProductQuantity: (orderProductId: number, quantityOptionId: number) => void,
  createCartFromOrder: () => Promise<void>
}

export const SelectedOrderProductTable = ({
                                            siteId,
                                            orderId,
                                            consumerId,
                                            locked,
                                            orderEditCartId,
                                            vendorProductStatusOverview,
                                            onProductAddedToCart,
                                            updateOrderProductQuantity,
                                            createCartFromOrder
                                          }: SelectedOrderProductTableProps) => {
    const { data: orderProducts } = useGetOrderProductsQuery(orderId);
    const { data: cartExists } = useGetCartExistsQuery(orderEditCartId ?? skipToken);
    const { data: orderEditCartProducts } = useGetCartProductsQuery((cartExists && orderEditCartId) ? orderEditCartId : skipToken);
    const { data: orderEditCartProductPricing } = useGetCartProductsPricingQuery(orderEditCartId ? {cartId: orderEditCartId} : skipToken);
    const [ updateCartProduct ] = useAddProductToCartMutation();
    const [ removeProductFromCart ] = useRemoveProductFromCartMutation();
    const [ productConfigurationState, setProductConfigurationState ] = useState<ProductConfigurationState>({});
    const [ searchIsOpen, setSearchIsOpen ] = useState(false);
    const [ orderProductIdPendingEdit, setOrderProductIdPendingEdit ] = useState<number | undefined>(undefined);
    const toggleSearch = () => setSearchIsOpen(!searchIsOpen);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (cartExists === false) {
            dispatch(forgetOrderEditCartId());
        }
    }, [cartExists]);

    useEffect(() => {
        if (orderEditCartProducts && orderProductIdPendingEdit) {
            editCartProductByOrderProductId(orderProductIdPendingEdit);
            setOrderProductIdPendingEdit(undefined);
        }
    }, [orderEditCartProducts])

    const addProduct = async () => {
        if (!orderEditCartId)
            await createCartFromOrder();
        // ensure that we have cleared out the product configuration state to make way for the new product
        setProductConfigurationState({});
        setSearchIsOpen(true);
    }

    const editCartProductByOrderProductId = (orderProductId: number) => {
        const cartProduct = orderEditCartProducts?.find(oecp => oecp?.orderProductId === orderProductId);
        if (cartProduct)
            editCartProduct(cartProduct);
    }

    const editCartProduct = (cartProduct: CartProductVM) => {
        setProductConfigurationState({
            siteProductVariantId: cartProduct.siteProductVariantId,
            quantityOptionId: cartProduct.quantityOption.id,
            cartProductId: cartProduct.id,
        });
        setSearchIsOpen(true);
    }

    const editOrderProduct = async (orderProduct: OrderProductVm) => {
        // Create order edit cart
        await createCartFromOrder();

        // Set that order product id as pending edit so that when cart products load we can launch edit for that cart product
        setOrderProductIdPendingEdit(orderProduct.id);
    }

    const onViewConfiguration = (orderProduct: OrderProductVm) => {
        setProductConfigurationState({
            siteProductVariantId: orderProduct.siteProductVariantId,
            quantityOptionId: orderProduct.quantityOption.id,
            orderProductId: orderProduct.id
        });
        setSearchIsOpen(true);
    }

    const removeFromCart = (cartProductId: number)  => {
        if (window.confirm('Are you sure you want to remove this product from the cart?')) {
            removeProductFromCart(cartProductId);
        }
    }

    const updateQuantity = (cartProductId: number, siteProductVariantId: number, quantityOptionId: number) => {
        updateCartProduct({
            cartProductId,
            siteProductVariantId,
            siteProductVariantQuantityOptionId: quantityOptionId,
        });
    }

  return (
    <Card>
      <CardHeader className="d-flex justify-content-between selected-order-product-table-card-header">
        <h5 className="mb-0">Products</h5>
          {orderProductIdPendingEdit && <Spinner/>}
        <Button color='primary' disabled={locked} onClick={() => addProduct()}>Add Product</Button>
          <FindProduct
              siteId={siteId}
              orderEditCartId={orderEditCartId}
              productConfigurationState={productConfigurationState}
              onProductConfigurationStateChanged={setProductConfigurationState}
              onProductAddedToCart={onProductAddedToCart}
              isOpen={searchIsOpen}
              toggleModal={toggleSearch}
              consumerId={consumerId}/>
      </CardHeader>
      <CardBody>
        <Table hover>
          <thead>
          <tr>
            <th>Sku</th>
            <th>Product Name</th>
            <th>Format</th>
            <th>Quantity</th>
            <th>Total</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {
              (!orderEditCartProducts || !orderEditCartId) && orderProducts?.map(p => (
              <OrderProductRow key={p.id}
                               orderProduct={p}
                               locked={locked}
                               onQuantityOptionChange={quantityOptionId => updateOrderProductQuantity(p.id, quantityOptionId)}
                               vendorProductStatus={vendorProductStatusOverview?.orderProductStatuses.find(ops => ops.orderProductId === p.id)}
                               onEditClick={editOrderProduct}
                               onViewConfigurationClick={onViewConfiguration}/>
            ))
          }
          {
              orderEditCartId && orderEditCartProducts && orderEditCartProducts?.map(p => (
                  <CartProductRow key={p.id}
                                  cartProduct={p}
                                  pricing={orderEditCartProductPricing?.siteProductVariantTotals?.find(svp => svp.cartProductId === p.id)}
                                  onQuantityOptionChange={quantityOptionId => updateQuantity(p.id, p.siteProductVariantId, quantityOptionId)}
                                  onEditClick={editCartProduct}
                                  onRemoveClick={removeFromCart}
                                  showUnsavedIcon={true}/>
              ))
          }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}