import './RefundApproveDeny.css';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner} from "reactstrap";
import React, {useEffect, useState} from "react";
import {Refund} from "../Refund";
import {
	useApproveDenyRefundRequestMutation,
	useDeleteSavedPaymentDetailsForUserMutation,
	useGetOrderOverviewQuery,
	useGetOrderPricingQuery,
	useGetOrderProductPaymentsQuery,
	useGetPreviousOrderPaymentInformationQuery,
	useGetSavedPaymentDetailsForUserQuery,
	useSavePaymentDetailsMutation
} from "../../../../../../app/apiSlice";
import {PaymentSelection} from "../../../../../payment/selection/PaymentSelection";
import {BillingAddress} from "../../../BillingAddress";
import {Address} from "../../../../../input/address/Address";
import {PaymentDetails, PaymentDetailsType} from "../../../../../payment/PaymentDetails";
import {useValidation} from "../../../../../../app/hooks";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {DateTime} from "luxon";
import {
	getErrorMessage,
	Regexes,
	validateCCFullNameString,
	validateCVCString,
	validateExpirationDateString
} from "../../../../../../utils/Utils";
import {PaymentMethodName, PaymentMethodOption} from "../../../../../payment/PaymentMethod";
import {PaymentUtils} from "../../../../../payment/utils/PaymentUtils";
import {TransactionStatus} from "../../details/OrderPayment";
import {CurrencyFormatter} from "../../../../../../utils/CurrencyFormatter";
import {AnalyticsTools} from "../../../../../../utils/AnalyticsHelper";

interface RefundApproveDenyProps {
	orderId?: number,
	isOpen: boolean,
	requestedRefund: Refund,
	isApproval: boolean,
	toggleModal: () => void
}
export const RefundApproveDeny = ({orderId, isOpen, requestedRefund, isApproval, toggleModal}: RefundApproveDenyProps) => {
	const {data: order} = useGetOrderOverviewQuery(orderId ?? skipToken);
	const {data: orderPricing} = useGetOrderPricingQuery(orderId ?? skipToken);
	const [ savePaymentDetails, {isLoading: isSavingPaymentDetails} ] = useSavePaymentDetailsMutation();
	const [ deleteSavedPaymentDetailsForUser, {isLoading: isDeletingSavedPayment}  ] = useDeleteSavedPaymentDetailsForUserMutation();
	const [ approveDenyRefundRequest, {isLoading: isApprovingOrDenying} ] = useApproveDenyRefundRequestMutation();
	const { data: savedPaymentDetails } = useGetSavedPaymentDetailsForUserQuery(order?.consumer.userId ?? skipToken);
	const [ selectedPaymentProfileId, setSelectedPaymentProfileId ] = useState<string | undefined>(undefined);
	const [ billingAddress, setBillingAddress ] = useState<Address | undefined>(undefined);
	const [ paymentDetails, setPaymentDetails ] = useState<PaymentDetails | undefined>(undefined);
	const [ errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const [ successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
	const [ isRefundAndRecharge, setIsRefundAndRecharge ] = useState<boolean>(false);
	const [ paymentMethodOption, setPaymentMethodOption ] = useState<PaymentMethodOption>();
	const {data: orderProductPayments} = useGetOrderProductPaymentsQuery(requestedRefund?.orderProductId ?? skipToken);
	const isAch = (orderProductPayments ?? []).some(opp => opp.paymentMethod === PaymentMethodName.ACH);
	const totalPostedAmount = (orderProductPayments ?? [])
		.filter(opp => opp.transactionStatus === TransactionStatus.SettledSuccessfully)
		.reduce((accumulator, orderProductPayment) => {
			return accumulator + (orderProductPayment.amount ?? 0);
		}, 0) ?? 0;
	const totalPaymentAmount = (orderProductPayments ?? [])
		.reduce((accumulator, orderProductPayment) => {
			return accumulator + (orderProductPayment.amountRemaining ?? 0);
		}, 0) ?? 0;
	const remainderPayment = totalPaymentAmount - requestedRefund.amount;
	const willRequireVoid = isApproval && !isAch && totalPostedAmount < (requestedRefund.amount);
	const { data: previousPaymentInformation } = useGetPreviousOrderPaymentInformationQuery(order?.id ?? skipToken);
	const required = (name: string) => (val?: string) => !val ? `${name} is required.` : undefined;
	const [paymentDetailsType, setPaymentDetailsType] = useState<PaymentDetailsType | undefined>(PaymentDetailsType.CreditCardPaymentDetails);
	const validation = useValidation({
		...PaymentUtils.wrapPaymentDetailsValidationMap(paymentDetailsType, PaymentDetailsType.CreditCardPaymentDetails, {
			payment_firstName: required('First Name'),
			payment_lastName: required('Last Name'),
			payment_fullName: val => validateCCFullNameString(val),
			payment_cardNumber: val => (!val || !Regexes.ccWithFormatting.test(val)) ? 'Please enter a valid card number' : undefined,
			payment_cardExpiration: val => validateExpirationDateString(val),
			payment_cvc: val => validateCVCString(val),
		}),
		...PaymentUtils.wrapPaymentMethodOptionValidationMap(paymentMethodOption, PaymentMethodOption.SavedPaymentMethod,
			{
				payment_savedMethod: required('Payment Method')
			}
		)
	});

	useEffect(() => {
		setIsRefundAndRecharge(isApproval && willRequireVoid && remainderPayment > 0);
	}, [requestedRefund, isApproval, willRequireVoid, orderProductPayments]);

	// Fill in billing info on previous payment loads
	useEffect(() => {
		setBillingAddress(previousPaymentInformation?.billingAddress);
	}, [previousPaymentInformation]);

	// Fill in saved payment details on previous payment loads
	useEffect(() => {
		if (previousPaymentInformation?.vendorPaymentProfileId && !selectedPaymentProfileId) {
			setSelectedPaymentProfileId(previousPaymentInformation.vendorPaymentProfileId);
		}
	}, [previousPaymentInformation]);

	const modalNavigation = (
		<div>
			<button className='btn-close' onClick={toggleModal}/>
		</div>
	);

	const onPaymentDetailsChanged = (updatedPaymentDetails?: PaymentDetails) => {
		setPaymentDetailsType(paymentDetails ? PaymentDetailsType[paymentDetails.paymentDetailsType as keyof typeof PaymentDetailsType] : undefined);
		setPaymentDetails(updatedPaymentDetails);
	}

	const onBillingChanged = (billingAddress?: Address) => {
		const setValue = (name: string, val: string) => validation.setValue(`billing_${name}`, val);

		setBillingAddress(billingAddress);

		// set these values for both shipping and billing
		setValue('streetAddress', billingAddress?.street ?? "");
		setValue('city', billingAddress?.city ?? "");
		setValue('state', billingAddress?.stateCode ?? "");
		setValue('zip', billingAddress?.zip ?? "");
	}

	const onSavePaymentDetailsClicked = async () => {
		if (!paymentDetails || !order?.consumer?.id) {
			setErrorMessage("Missing Information. Please enter all of the payment fields including billing address.");
			return;
		}

		try {
			const newPaymentProfileId = await savePaymentDetails({
				paymentDetails: paymentDetails,
				userId: order.consumer.userId
			}).unwrap();

			if (newPaymentProfileId) {
				setSuccessMessage('Payment method saved!');
			}
			else {
				setErrorMessage('Failed to save payment method. It\'s possible this payment method may already be saved.');
			}
		} catch (e) {
			setErrorMessage('Failed to save payment method.');
		}

	}

	const onRemoveSavedPaymentMethodClicked = async (customerPaymentProfileId: string) => {
		if (!order?.consumer?.id) {
			return;
		}

		try {
			await deleteSavedPaymentDetailsForUser({userId: order.consumer.userId, customerPaymentProfileId}).unwrap();
		} catch (e) {
			alert('Error deleting payment details: ' + JSON.stringify(e));
		}
	}

	const shouldHideBillingAddress = () => {
		return paymentDetails?.paymentDetailsType === PaymentDetailsType.SavedPaymentDetails;
	}

	const getRefundRequestDetailsTable = () => {
		if (!requestedRefund)
			return;
		return (
			<table className='table table-bordered refund-approve-deny-table'>
				<thead>
				<tr>
					<th>Amount</th>
					<th>Status</th>
					<th>Request Type</th>
					<th>Request Category</th>
					<th>Request Reason</th>
					<th>Date Requested</th>
					<th>Requester</th>
					<th>Order Product Id</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td >{CurrencyFormatter.format(requestedRefund.amount)}</td>
					<td >{requestedRefund.status.name}</td>
					<td >{requestedRefund.type?.name}</td>
					<td >{requestedRefund.category?.name}</td>
					<td >{requestedRefund.reason?.name}</td>
					<td >{DateTime.fromISO(requestedRefund.timeCreatedLocal.toString()).toLocaleString(DateTime.DATETIME_SHORT)}</td>
					<td >{requestedRefund.creatorEmail}</td>
					<td >{requestedRefund.orderProductId}</td>
				</tr>
				</tbody>
			</table>
		);
	}

	const getPaymentInformationEntry = () => {
		return (
			<>
				<Row className="mb-4">
					{!shouldHideBillingAddress() && <BillingAddress consumerId={order?.consumer.id}
									address={billingAddress}
									onFillFromCheck={undefined}
									onAddressChanged={onBillingChanged}
									suppressError={!validation.shouldShowErrors}
									useCardContainer={false}/> }
				</Row>
				<Row className="mb-4">
					<PaymentSelection selectedPaymentProfileId={selectedPaymentProfileId}
									  enabledSavedPaymentMethods={true}
									  savedPaymentDetails={savedPaymentDetails}
									  billingAddress={billingAddress}
									  onPaymentOptionMethodChange={setPaymentMethodOption}
									  onPaymentDetailsChanged={onPaymentDetailsChanged}
									  onSavePaymentDetailsClicked={onSavePaymentDetailsClicked}
									  onRemoveSavedPaymentMethodClicked={onRemoveSavedPaymentMethodClicked}
									  validation={validation.createProxy('payment')}
									  isSavingPaymentDetails={isSavingPaymentDetails}
									  isDeletingSavedPayment={isDeletingSavedPayment}
									  useCardContainer={false}
									  orderId={order?.id}/>
				</Row>
			</>
		)
	}

	const onRefundAndRechargeChange = () => {
		setIsRefundAndRecharge(!isRefundAndRecharge);
	}

	const getVoidRequiredDisclaimer = () => {
		return (
			<Alert color="warning">
				The order payment is not settled yet. If you wish to process a refund right now, it will result in a full void of {CurrencyFormatter.format(totalPaymentAmount)} and recharge of {CurrencyFormatter.format(remainderPayment)}.
				(You must enter payment information to recharge. If you do not wish to void and recharge, please wait for the transaction to settle.)
			</Alert>
		)
	}

	const getRefundAndRechargeToggle = () => {
		return (
			<FormGroup>
				<FormGroup className='refund-and-recharge-form-group'>
					<Input type='radio' checked={!isRefundAndRecharge} onChange={onRefundAndRechargeChange}/>
					<Label className='refund-and-recharge-label' onClick={() => setIsRefundAndRecharge(false)}>
						<Alert color='primary'>
							Refund the amount {CurrencyFormatter.format(requestedRefund.amount)} from the transaction.
						</Alert>
					</Label>
				</FormGroup>
				{remainderPayment > 0 && <FormGroup className='refund-and-recharge-form-group'>
					<Input type='radio' disabled={willRequireVoid} checked={isRefundAndRecharge} onChange={onRefundAndRechargeChange}/>
					<Label className='refund-and-recharge-label' onClick={() => setIsRefundAndRecharge(true)}>
						<Alert color='primary'>
							Refund the current transaction amount of {CurrencyFormatter.format(totalPaymentAmount)} and then charge the remaining amount of {CurrencyFormatter.format(remainderPayment)}.
							This option will require payment entry.
						</Alert>
					</Label>
				</FormGroup>}
			</FormGroup>
		)
	}

	const getErrorMessageDisplay = () => {
		return errorMessage && (
			<Alert color='danger'>
				{errorMessage}
			</Alert>
		)
	}

	const getSuccessMessageDisplay = () => {
		return successMessage && (
			<Alert color='success'>
				{successMessage}
			</Alert>
		)
	}

	const shouldDisableApprove = () => {
		return isApprovingOrDenying ||
			(isApproval && !isAch && isRefundAndRecharge && (!shouldHideBillingAddress() && (!paymentDetails ||
				(!billingAddress && paymentDetails?.paymentDetailsType !== PaymentDetailsType.SavedPaymentDetails))));
	}

	const onApproveDenyClicked = async () => {
		if (!requestedRefund || (isApproval && isRefundAndRecharge && !validation.allValid()))
			return;

		try {
			await approveDenyRefundRequest({refundId: requestedRefund.id, isApproved: isApproval, paymentDetails, isRefundAndRecharge}).unwrap();
			const orderProductPricing = orderPricing?.siteProductVariantTotals?.find(op => op.orderProductId === requestedRefund.orderProductId);
			if (isApproval && orderId && orderProductPricing) {
				AnalyticsTools.recordRefundEvent(orderId, orderProductPricing, requestedRefund);
			}
			closeModal();
		}
		catch (e: any) {
			setErrorMessage(getErrorMessage(e));
		}
	}

	const closeModal = () => {
		setErrorMessage(undefined);
		setSuccessMessage(undefined);
		validation.hideErrors(true);
		toggleModal();
	}

	return (
		<Modal isOpen={isOpen} toggle={closeModal} size='xl'>
			<ModalHeader toggle={closeModal} close={modalNavigation}>{isApproval ? 'Approve' : 'Deny'} Refund</ModalHeader>
			<ModalBody className='refund-approve-deny-container'>
				<Row className="mb-4">
					{getRefundRequestDetailsTable()}
					{isApproval && !isAch && !willRequireVoid && getRefundAndRechargeToggle()}
					{isApproval && !isAch && willRequireVoid && remainderPayment > 0 && getVoidRequiredDisclaimer()}
					{isApproval && !isAch && isRefundAndRecharge && getPaymentInformationEntry()}
					{getErrorMessageDisplay()}
					{getSuccessMessageDisplay()}
				</Row>
			</ModalBody>
			<Row>
				<div className='refund-approve-deny-button-container'>
					<Button color='primary' onClick={onApproveDenyClicked} disabled={shouldDisableApprove()}>
						{isApprovingOrDenying && <Spinner></Spinner>}
						{isApproval ? 'Approve' : 'Deny'}
					</Button>
					<Button onClick={closeModal}>Cancel</Button>
				</div>
			</Row>
		</Modal>
	)
}
