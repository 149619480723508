import {Button, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarToggler, NavItem, NavLink, UncontrolledDropdown} from "reactstrap";
import {Link} from "react-router-dom";
import {useState} from "react";
import {InteractionType} from '@azure/msal-browser';
import {AuthenticatedTemplate, MsalAuthenticationTemplate, useMsal} from "@azure/msal-react";
import './AdminLayout.css';
import {useClaims} from "../../app/hooks";
import {ADMIN, FINANCE, TEAM_LEAD, VIEW} from "../../utils/Roles";
import AdminErrorBoundary from "../errorBoundary/admin/AdminErrorBoundary";
import {loginRequest} from "../../authConfig";

interface AdminLayoutProps {
	children: JSX.Element
}

export const AdminLayout = ({ children }: AdminLayoutProps) => {
	const { instance } = useMsal();
	const activeUser = instance.getActiveAccount();
	const { hasPermission } = useClaims();
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	return (
		<div id='AdminSite'>
			<AuthenticatedTemplate>
				<div className="admin-navbar-container">
					<Link to='/admin'><h1 className="admin-brand">E-Commerce Management System</h1></Link>
				</div>
				<Navbar dark expand='md' className='mb-4 admin-navbar-container'>
					{/* <NavbarBrand tag={Link} to='/admin'>E-Commerce Management System</NavbarBrand> */}
					<NavbarToggler onClick={toggle} />
					<Collapse isOpen={isOpen} navbar>
						<Nav className='me-auto admin-navbar-nav' navbar>
							<NavItem>
								<NavLink tag={Link} to='/admin' className="admin-nav-link">Home</NavLink>
							</NavItem>
							<NavItem>
								<NavLink tag={Link} to='holds' className="admin-nav-link">Hold Queue</NavLink>
							</NavItem>
							<NavItem>
								<NavLink tag={Link} to='consumers' className="admin-nav-link">Consumers</NavLink>
							</NavItem>
							<NavItem>
								<NavLink tag={Link} to='financialInstitutions' className="admin-nav-link">Financial Institutions</NavLink>
							</NavItem>
							<NavItem>
								<NavLink tag={Link} to='controlLists' className="admin-nav-link">Control Lists</NavLink>
							</NavItem>

							{ hasPermission([ADMIN, TEAM_LEAD, FINANCE]) &&
                                <NavItem>
                                    <NavLink tag={Link} to='refunds' className="admin-nav-link">Refund Management</NavLink>
                                </NavItem>
							}

							{ hasPermission([ADMIN, FINANCE, VIEW]) &&
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret className="admin-nav-link">
                                        Reports
                                    </DropdownToggle>
                                    <DropdownMenu end>
										{ hasPermission([ADMIN, FINANCE]) &&
                                            <>
                                                <DropdownItem>
                                                    <NavLink tag={Link} to='reports/paymentMethodRollup'>Payment Method Rollup</NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink tag={Link} to='reports/customerEmailDownload'>Customer Email Download</NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink tag={Link} to='reports/orderMonthEnd'>Order Download</NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink tag={Link} to='reports/taxReport'>Tax Report</NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink tag={Link} to='reports/transactionReport'>Transaction Report</NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink tag={Link} to='reports/royaltiesFees'>Royalties Fees</NavLink>
                                                </DropdownItem>
                                            </>
										}
										{ hasPermission([ADMIN, VIEW, FINANCE]) &&
                                            <>
                                                <DropdownItem>
                                                    <NavLink tag={Link} to='reports/orderAchReject'>Order ACH Reject</NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink tag={Link} to='reports/orderItemDownload'>Order Item Download</NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink tag={Link} to='reports/orderStatusReport'>Order Status</NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink tag={Link} to='reports/ltv'>LTV</NavLink>
                                                </DropdownItem>
                                            </>
										}
										{ hasPermission([ADMIN, VIEW]) &&
                                            <>
                                                <DropdownItem>
                                                    <NavLink tag={Link} to='reports/orderDailySales'>Order Daily Sales</NavLink>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <NavLink tag={Link} to='reports/orderProductMix'>Order Product Mix</NavLink>
                                                </DropdownItem>
                                            </>
										}
										{ hasPermission([ADMIN]) &&
                                            <DropdownItem>
                                                <NavLink tag={Link} to='reports/sourceCodeSales'>Source Code Sales</NavLink>
                                            </DropdownItem>
										}
                                        <DropdownItem>
                                            <NavLink tag={Link} to='reports/orderRefundReport'>Order Refund</NavLink>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
							}

							{ hasPermission([ADMIN]) &&
								<NavItem>
									<NavLink tag={Link} to='endOfMonthReports' className="admin-nav-link">End-of-Month Reports</NavLink>
								</NavItem>
							}
							
							{ hasPermission([ADMIN, TEAM_LEAD]) &&
                                <NavItem>
                                    <NavLink tag={Link} to='admins' className="admin-nav-link">User Management</NavLink>
                                </NavItem>
							}

							{ hasPermission([ADMIN, FINANCE]) &&
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret className="admin-nav-link">Tax Processing</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <NavLink tag={Link} to='taxProcessing'>Process Transactions</NavLink>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavLink tag={Link} to='viewProcessedFiles'>View Processed Files</NavLink>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavLink tag={Link} to='viewHistoricalExports'>View Historical Exports</NavLink>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
							}
						</Nav>
						<div className="m-2 user-name">{activeUser?.name ?? ""}</div>
						<Button color='secondary' className="btn-secondary" outline onClick={() => instance.logoutRedirect()}>Logout</Button>
					</Collapse>
				</Navbar>

				<main className='px-2 w-100 admin-main'>
					{children}
				</main>
			</AuthenticatedTemplate>
			<AdminErrorBoundary>
				<MsalAuthenticationTemplate authenticationRequest={loginRequest} interactionType={InteractionType.Redirect} >
				</MsalAuthenticationTemplate>
			</AdminErrorBoundary>
		</div>
	)
};
