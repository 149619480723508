import {Configuration} from '../configuration/Configuration';
import './PrintingInput.css';
import {Input, Label} from 'reactstrap';
import {ProductInputValue} from '../ProductInputValue';
import {useTranslation} from "react-i18next";
import {FieldModifier} from "../configuration/FieldModifier";
import {useGetPrintingSoftwareQuery} from "../../../app/apiSlice";
import {RequiredAsterisk} from "../utils/RequiredAsterisk";
import {RuleTypes} from "../configuration/rule/ConfigurationRule";
import {useAppSelector, useConfigurationRules} from "../../../app/hooks";
import {getConfigurationIsSecureClass} from "../../../utils/Utils";
import { selectNumSignatureLines } from '../ProductInputSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import {OnValueChangeParams} from "../view/ProductInputView";

const BLANK_SELECT = 'pick one';

interface PrintingInputProps {
    configuration?: Configuration,
    productInputValue?: ProductInputValue,
    isInEditMode: boolean,
    getClassesForModifiers: (fieldModifiers?: FieldModifier[]) => string,
    onValueChange: (params: OnValueChangeParams) => void,
    checkInput: boolean,
    productVariantId?: number,
    consumerId?: number
}

export const PrintingInput = ({ configuration, productInputValue, isInEditMode, getClassesForModifiers, onValueChange, checkInput, productVariantId, consumerId }: PrintingInputProps) => {
    const { t } = useTranslation();
    const inputId = configuration?.id ?? -1;
    const inputLabel = configuration?.configurationDetail.label ?? "";
    const isRequired = configuration?.configurationDetail.isRequired;
    const printingValue = productInputValue?.value ?? BLANK_SELECT;
    const rulesState = useConfigurationRules(configuration?.rules, productInputValue?.value, productVariantId, [RuleTypes.Matching, RuleTypes.Differing, RuleTypes.PositiveMatch]);
    const numSignatureLines = useAppSelector(selectNumSignatureLines);
    const { data: printingSoftware } = useGetPrintingSoftwareQuery(numSignatureLines ? { consumerId, numSignatureLines } : skipToken);

    const containerClass = isInEditMode ? 'printing-software-input-container-edit-mode' : '';
    const labelClasses = isInEditMode ? '' : 'half-width-text-input';
    const hasAllRequiredProperties = (val: string = printingValue) => !!val && val !== BLANK_SELECT;
    const shouldShowFreeformField = !(printingSoftware?.find(ps => ps.displayName === printingValue) || printingValue === BLANK_SELECT);
    
    function shouldInputBeDisabled(inputType: string) {
        const shouldInputBeDisabled = rulesState.inputShouldBeDisabled && inputType.includes(rulesState.disabledMetaTag ?? "")
        if (shouldInputBeDisabled && printingValue) {
            onPrintingSoftwareValueChange('')
        }
        return shouldInputBeDisabled;
    }

    function onPrintingSoftwareValueChange(value: string) {
        value = value.trimStart();
        // passing this value into the validation function because we need to know if it's valid BEFORE submitting to redux store
        onValueChange({ inputValue: value, isComplete: hasAllRequiredProperties(value) });
    }

    function getInputClasses() {
        let classNames = getConfigurationIsSecureClass(configuration);
        let displayRequired = checkInput && isRequired && !hasAllRequiredProperties();

        if (displayRequired || rulesState.inputIsInvalid || (configuration?.shouldValidateValue && productInputValue?.isValidated === false)) {
            classNames += " invalid-printing-input ";
        }
        else if (configuration?.shouldValidateValue && productInputValue?.value && productInputValue?.isValidated === undefined) {
            classNames += " not-yet-validated-printing-input ";
        }
        else if (configuration?.shouldValidateValue && productInputValue?.isValidated) {
            classNames += " validated-printing-input ";
        }

        return classNames;
    }

    function getInputLabel(labelType: string) {
        return (
          <Label for={`${labelType}-input-${inputId}`} className={`printing-input-label ${labelClasses}`}>
                {t(`productConfiguration.${inputLabel}.${labelType}`)}
                {isRequired && isInEditMode && !shouldInputBeDisabled(labelType) &&
                    <RequiredAsterisk/>
                }
          </Label>
        )
    }

    function getEditModeTemplate() {
        return (
            <>
                <span className='printing-input-group d-flex flex-row flex-wrap'>
                    {/*Printing Software*/}
                    <span className="half-width-text-input">
                        {getInputLabel('printingSoftware')}
                        <Input
                            className={getInputClasses()}
                            id={`printingSoftware-input-${inputId}`}
                            type="select"
                            onChange={(e) => onPrintingSoftwareValueChange(e.target.value)}
                            value={printingValue}>
                            <option value={BLANK_SELECT}></option>
                            {printingSoftware?.map((printingSoftware) => {
                                return (
                                    <option key={printingSoftware.id} value={printingSoftware.displayName}>{printingSoftware.displayName}</option>
                                )
                            })}
                            <option value={ shouldShowFreeformField ? printingValue : ''}>Other...</option>
                        </Input>
                    </span>

                    {shouldShowFreeformField &&
                        <span className="half-width-text-input">
                            {getInputLabel('otherPrintingSoftware')}
                            <Input
                                className={getInputClasses()}
                                id={`otherPrintingSoftware-input-${inputId}`}
                                type="text"
                                disabled={shouldInputBeDisabled('otherPrintingSoftware')}
                                onChange={(e) => onPrintingSoftwareValueChange(e.target.value)}
                                value={printingValue}
                            />
                            <div dangerouslySetInnerHTML={{__html: t('productConfiguration.printingSoftwareLabel.otherPrintingSoftwareOverlayRequest')}}/>
                        </span>
                    }

                </span>      
            </>
        );
    }

    function getReadModeTemplate() {
        return (
            <>
                {getInputLabel('overallLabel')}
                <div className={`half-width-text-input ${getConfigurationIsSecureClass(configuration)}`}>
                    <div>{ printingValue }</div>
                </div>
            </>
        );
    }

    return (
        <span className={`printing-software-input-container ${containerClass} ${getClassesForModifiers(configuration?.fieldModifiers)}`}>
            {isInEditMode ? getEditModeTemplate() : getReadModeTemplate()}
        </span>
    );
}
