import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './i18n';
import {Provider} from 'react-redux';
import {store} from './app/store';
import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from '@azure/msal-react';
import {loginRequest, msalConfig} from './authConfig';
import {Brand, selectedBrand} from "./i18n";
import {Modal} from "./components/modal/Modal";
import {Spinner} from 'reactstrap';
import {isPrerender} from "./utils/Utils";
import {HelmetProvider} from "react-helmet-async";
import {getBingAnalyticsConfig, getGoogleAnalyticsConfig} from "./utils/AnalyticsHelper";

// Google Analytics.

const gaConfig = getGoogleAnalyticsConfig(selectedBrand);

gaConfig.ScriptContent.forEach(scriptContent => {
	const scriptElement = document.createElement("script");
	scriptElement.innerHTML = scriptContent;
	document.head.append(scriptElement);
});

const scriptElement = document.createElement("script");
scriptElement.src = gaConfig.ScriptSrc;
document.head.append(scriptElement);

// Bing Analytics.

const baConfig = getBingAnalyticsConfig(selectedBrand);

baConfig.ScriptContent.forEach(scriptContent => {
	const scriptElement = document.createElement("script");
	scriptElement.innerHTML = scriptContent;
	document.head.append(scriptElement);
});

// Prerender/Osano.

if (!isPrerender()) {
	// Osano strongly recommends that it's the very first element in the <head>.
	let osanoScript = document.createElement("script");
	switch (selectedBrand) {
		case Brand.CheckWorks:
			osanoScript.src = "https://cmp.osano.com/Azqk23TaC4XqEFU2a/418ee834-ab0f-449e-819e-52e03289e1ad/osano.js";
			break;
		case Brand.ChecksForFree:
			osanoScript.src = "https://cmp.osano.com/Azqk23TaC4XqEFU2a/046414db-2e48-4b20-9821-77dbd003345d/osano.js";
			break;
	}
	
	// Delaying when we add Osano here because the two google tag manager scripts above
	// result in an additional script being prepended. And we can't control when that
	// one gets prepended, so delaying here allows Osano to remain first always.
	setTimeout(function () {
		document.head.prepend(osanoScript);
	}, 500);
}

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;
const msalInstance = new PublicClientApplication(msalConfig);
const container = document.getElementById('app')!;

// Add brand name as class for css colors per brand to be applied (in site.css).
document.body.classList.add(selectedBrand.toString());

const root = createRoot(container);
root.render(
	<React.Suspense fallback={<div className='vw-100 vh-100 d-flex justify-content-center align-items-center'><Spinner/></div> }>
		<Provider store={store}>
			<BrowserRouter basename={baseUrl}>
				<MsalProvider instance={msalInstance} >
					<HelmetProvider>
						<App />
						<Modal/>
					</HelmetProvider>
				</MsalProvider>
			</BrowserRouter>
		</Provider>
	</React.Suspense>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const getActiveAccount = () => {
	const activeAccount = msalInstance.getActiveAccount();
	const accounts = msalInstance.getAllAccounts();

	if (!activeAccount && accounts.length === 0) {
		return null;
	}
	return activeAccount ?? accounts[0];
};

let expiry: Date | null = null;
let interval: number | undefined;

export const acquireAccessToken = async () => {
	const activeAccount = getActiveAccount();

	if (!activeAccount) {
		console.error("Tried to get an admin token when not logged in.");
		return null;
	}

	const request = {
		...loginRequest,
		account: activeAccount,
	};
	
	try {
		const authResult = await msalInstance.acquireTokenSilent(request);
		if (authResult?.accessToken) {
			expiry = authResult?.expiresOn;
			if (!interval) {
				interval = window.setInterval(() => {
					if (expiry) {
						const now = new Date();
						if (expiry.getTime() <= now.getTime()) {
							msalInstance.logoutRedirect({
								account: msalInstance.getActiveAccount()
							});
						}
					}
				}, 10000);
			}
		}
		return authResult.accessToken
	} catch (error) {
		await msalInstance.logoutRedirect({
			account: msalInstance.getActiveAccount()
		});
	}
};
