import './Layout.css';
import {NavigationMenu} from '../navigation/menu/NavigationMenu';
import {UserActionBar} from '../user/actionBar/UserActionBar';
import {Footer} from "../footer/Footer";
import {ScrollToTopButton} from "../helpers/ScrollToTopButton/ScrollToTopButton";

interface LayoutProps {
  children: JSX.Element
}

export const Layout = ({ children }: LayoutProps) => {
    return (
        <div className="site-container">
            <UserActionBar />
            <NavigationMenu />
                {children}
            <ScrollToTopButton />
            <Footer></Footer>
        </div>
    );
};