import './ButtonSingleSelect.css';
import {Button, Label} from 'reactstrap';
import { ProductInputValue } from '../ProductInputValue';
import {FieldModifier} from "../configuration/FieldModifier";
import {RequiredAsterisk} from "../utils/RequiredAsterisk";
import {useEffect, useMemo} from "react";
import {getConfigurationIsSecureClass, Utils} from "../../../utils/Utils";
import {
    getProductInputLabel, getProductInputOptionDisplayName,
    getProductInputOptions, isConfiguration, isConfigurationOption,
    isProductInputRequired,
    ProductInput,
    ProductInputOption
} from "../ProductInput";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {OnValueChangeParams} from "../view/ProductInputView";

interface ButtonSingleSelectProps {
    productInput: ProductInput,
    productInputValue?: ProductInputValue,
    isInEditMode: boolean,
    getClassesForModifiers: (fieldModifiers?: FieldModifier[], optionId?: number) => string,
    onValueChange: (params: OnValueChangeParams) => void
}

export const ButtonSingleSelect = ({ productInput, productInputValue, isInEditMode, getClassesForModifiers, onValueChange }: ButtonSingleSelectProps) => {
    const inputLabel = getProductInputLabel(productInput, isInEditMode);
    const options = useMemo(() => getProductInputOptions(productInput) ?? [], [productInput]);
    const isRequired = isProductInputRequired(productInput);
    const defaultOption = useMemo(() => options.find(o => o.isDefault), [options]);
    const selectedOption = useMemo(() => options?.find(o => o.id === productInputValue?.selectedProductInputOptionId), [options, productInputValue?.selectedProductInputOptionId])

    // reset to default value if needed
    useEffect(() => {
        if (!productInputValue?.isDirty && !productInputValue?.value && defaultOption) {
            onValueChange({ inputValue: defaultOption.value, optionId: defaultOption.id });
        }
    }, [productInputValue, onValueChange, defaultOption]);

    function onOptionSelected(option: ProductInputOption) {
        onValueChange({ inputValue: option.value, optionId: option.id });
    }

    function getPriceModification(option: ProductInputOption) {
        return isConfigurationOption(option) && option.priceModification ?
            `+(${CurrencyFormatter.format(option.priceModification)})` : "";
    }

    function hideIfRedundant() {
        // Return hidden class if there's only one option, and its selected
        if (options && options.length < 2 && productInputValue?.value)
            return "button-single-select-option-hide";
    }

    function getEditModeTemplate() {
        return (
          <>
            <span className={`button-single-select-option-container ${getConfigurationIsSecureClass(productInput)}`}>
                {[...options].sort(Utils.sortBy('sortOrder', 'asc')).map((option) => {
                    return (
                        <Button
                            aria-label={`${getProductInputOptionDisplayName(option)} ${getPriceModification(option)}`}
                            active={isButtonSelected(option)}
                            onClick={(e) => onOptionSelected(option)}
                            key={'button-single-select-' + option.value + '-' + option.id}
                            className={"button-single-select-option " + (isConfiguration(productInput) && getClassesForModifiers(productInput?.fieldModifiers, option.id))}>
                            <>
                                {getProductInputOptionDisplayName(option)} <span className='text-normal'>{getPriceModification(option)}</span>
                            </>
                        </Button>);
                })}
            </span>
              { productInput.helperText && <span className="d-block" dangerouslySetInnerHTML={{__html: productInput.helperText } }/>}
          </>
        );
    }

    function getReadModeTemplate() {
        const displayName = selectedOption ? getProductInputOptionDisplayName(selectedOption) : undefined;
        
        return (
            <span className={`text-input-read-only-value ${getConfigurationIsSecureClass(productInput)}`}>
                {displayName ?? productInputValue?.value}
            </span>
        );
    }

    function getValueRenderTemplate() {
        if (isInEditMode) {
            return getEditModeTemplate();
        }
        else {
            return getReadModeTemplate();
        }
    }

    function getClassesForLabel(inputLabel: string) {
        if (!isInEditMode) {
            return "half-width-text-input";
        }
        if (inputLabel.toLowerCase() === 'choose a font') {
            return "non-input-label"
        }
        return "";
    }

    function isButtonSelected(option: ProductInputOption) {
        return productInputValue?.selectedProductInputOptionId === option.id || (!productInputValue?.isDirty && !productInputValue && option.isDefault);
    }
    
    const getComponentLabel = () => (
      <h4 className={getClassesForLabel(inputLabel)}>
          {inputLabel}
          {isRequired && isInEditMode ? <RequiredAsterisk></RequiredAsterisk> : <></>}
      </h4>
    )
    
    const getConfigurationLabel = () => (
      <Label className={`button-single-select-input-label ${getClassesForLabel(inputLabel)}`}>
          {inputLabel}
          {isRequired && isInEditMode ? <RequiredAsterisk></RequiredAsterisk> : <></>}
      </Label>
    )

    return (
        <span className={`button-single-select-container ${getClassesForModifiers()}} ${hideIfRedundant()}`}>

            { isConfiguration(productInput) ? getConfigurationLabel() : getComponentLabel() }
            {getValueRenderTemplate()}
        </span>
    );
}
