
import axios from 'axios';

// Fetch all site cards
export function fetchAll(siteId: number) {
  return axios.get('/api/SiteCards?siteId=' + siteId);
}

// Fetch card items for site card id
export function fetchCardItemBySiteCardId(siteCardId: number) {
  return axios.get(`/api/SiteCards/${siteCardId}/CardItems`);
}