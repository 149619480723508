import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {InputClassification, ProductInputValue} from "./ProductInputValue";
import {AddToCartRequest} from "../cart/AddToCartModels";
import {BrowserStorageType, fetchStorageValue, persistInStorage} from "../../utils/Utils";
import {Configuration, ConfigurationValue, SelectedConfigurationsRequest} from "./configuration/Configuration";
import {GetPreviewRequest} from "../check/GetPreviewRequest";
import {CartIdType} from "../cart/CartSlice";

export interface ProductInputState {
  activeSiteProductId?: number;
  values: ProductInputValue[];
  accessoryConfigurations: Configuration[];
}

const valuesLocalStorageKey = 'ProductInputState-values';

const initialState: ProductInputState = {
  activeSiteProductId: undefined,
  values: fetchStorageValue(valuesLocalStorageKey, BrowserStorageType.SessionStorage) ?? [],
  accessoryConfigurations: []
};

export const productInputSlice = createSlice({
  name: 'productInput',
  initialState,
  reducers: {
    setProductInputValues: (state, {payload: inputValues}: PayloadAction<ProductInputValue[]>) => {
      state.values = inputValues;

      // Persist in local storage, so if page is refreshed the values aren't lost
      persistInStorage(valuesLocalStorageKey, state.values.filter(v => !v.isSecure), BrowserStorageType.SessionStorage);
    },
    productInputValueChanged: (state, { payload: inputValue}: PayloadAction<ProductInputValue>) => {

      const index = state.values.findIndex(pi => pi.productInputId === inputValue.productInputId && pi.inputClassification === inputValue.inputClassification);
      if (index === -1) state.values = [...state.values, inputValue];
      else {
        const newValues = state.values;
        newValues[index] = inputValue;
        state.values = [...newValues];
      }

      // Persist in local storage, so if page is refreshed the values aren't lost
      persistInStorage(valuesLocalStorageKey, state.values.filter(v => !v.isSecure), BrowserStorageType.SessionStorage);
    },
    productInputValueValidated: (state, { payload: inputValue}: PayloadAction<ProductInputValue>) => {
      // Only update if value is matching
      const index = state.values.findIndex(pi => pi.productInputId === inputValue.productInputId && pi.value === inputValue.value);
      if (index !== -1) {
        const newValues = state.values;
        newValues[index] = inputValue;
        state.values = [...newValues];}

      // Persist in local storage, so if page is refreshed the values aren't lost
      persistInStorage(valuesLocalStorageKey, state.values.filter(v => !v.isSecure), BrowserStorageType.SessionStorage);
    },
    removeProductInputValue: (state, { payload: productInputId}: PayloadAction<number>) => {
      const index = state.values.findIndex(pi => pi.productInputId === productInputId);
      if (index === -1) return; // not found

      const newValues = state.values;
      newValues[index] = {
        ...state.values[index],
        value: null,
        selectedProductInputOptionId: undefined,
        selectedSiteProductVariantQuantityOptionId: undefined,
        isDirty: false
      };
      state.values = [...newValues];
    },
    removeProductInputValueByConfigurationDetailId: (state, { payload: configDetailId}: PayloadAction<number>) => {
      // The configDetailId is in fact the same as the productInputId
      const index = state.values.findIndex(pi => pi.productInputId === configDetailId && pi.inputClassification === InputClassification.Configuration);
      if (index === -1) return; // not found
      const newValues = state.values;
      newValues[index] = {
          ...state.values[index],
          value: null,
          selectedProductInputOptionId: undefined,
          selectedSiteProductVariantQuantityOptionId: undefined,
          isDirty: false
      };
      state.values = [...newValues];
    },
    setActiveProductId: (state, { payload: siteProductId}: PayloadAction<number>) => {
      if (state.activeSiteProductId !== siteProductId) {
        state.values = [];
        state.accessoryConfigurations = [];
      }
      state.activeSiteProductId = siteProductId;
    },
    setAccessoryConfigurations: (state, {payload: configurations}: PayloadAction<Configuration[]>) => {
      state.accessoryConfigurations = configurations;
    },
  },
});

export const selectAllInputValues = (state: RootState) => state.productInputs.values;

export const selectChosenColorOption = (state: RootState) => state.productInputs.values.find(v => v.name === 'Color Choice')?.selectedProductInputOptionId;

export const selectAllAccessoryConfigurations = (state: RootState) => state.productInputs.accessoryConfigurations;

export const selectInputValue = (state: RootState, productInputId: number, inputClassification: InputClassification) =>
  state.productInputs.values.find(v => v.productInputId === productInputId && v.inputClassification === inputClassification);

const selectValuesByClassification = (state: RootState, classification: InputClassification): ProductInputValue[] =>
  state.productInputs.values.filter(v => v.inputClassification === classification);

export const selectComponentValues = (state: RootState): ProductInputValue[] =>
  selectValuesByClassification(state, InputClassification.Component);

export const selectConfigurationValues = (state: RootState): ProductInputValue[] =>
  selectValuesByClassification(state, InputClassification.Configuration);

export const selectQuantityValue = (state: RootState): ProductInputValue | undefined => 
  state.productInputs.values.find(v => v.inputClassification === InputClassification.Quantity);

export const selectCartRequest = (state: RootState, siteProductVariantId?: number, siteProductVariantQuantityOptionId?: number, consumerId?: number, cartId?: CartIdType): AddToCartRequest => ({
  // userId may be provided when working from admin site, as the cart is not necessarily for the
  // currently signed in user
  cartId,
  consumerId,
  siteProductVariantId,
  siteProductVariantQuantityOptionId,
  configurationValues: state.productInputs.values
      .filter(pi => pi.inputClassification === InputClassification.Configuration)
      .map(pi => ({
        configurationId: pi.configurationId,
        configurationDetailId: pi.productInputId,
        stringValue: pi.value,
        configOptionId: pi.selectedProductInputOptionId,
        siteProductVariantQuantityOptionId: pi.selectedSiteProductVariantQuantityOptionId,
        configurationKey: pi.configurationKey
      } as ConfigurationValue)),
  });

export const selectGetPreviewRequest = (state: RootState, sku: string, siteProductVariantId: number, cartId: CartIdType, siteId?: number): GetPreviewRequest => ({
    sku,
    siteId,
    cartId,
    siteProductVariantId,
    configurationValues: state.productInputs.values
      .filter(pi => pi.productInputId !== -1 && pi.value && pi.inputClassification === InputClassification.Configuration)
      .map(pi => ({
        configurationId: pi.configurationId,
        configurationDetailId: pi.productInputId,
        stringValue: pi.value,
        configOptionId: pi.selectedProductInputOptionId,
        siteProductVariantQuantityOptionId: pi.selectedSiteProductVariantQuantityOptionId,
      } as ConfigurationValue)),
  });

export const selectSelectedConfigurationsRequest = (state: RootState, siteProductVariantQuantityOptionId: number): SelectedConfigurationsRequest => ({
  siteProductVariantQuantityOptionId,
  configurationValues: state.productInputs.values
      .filter(pi => pi.inputClassification === InputClassification.Configuration)
      .map(pi => ({
        configurationId: pi.configurationId,
        stringValue: pi.value,
        configOptionId: pi.selectedProductInputOptionId,
        siteProductVariantQuantityOptionId: pi.selectedSiteProductVariantQuantityOptionId,
      } as ConfigurationValue)),
    });

export const selectAccessoryProductVariantQuantityOptionId = (state: RootState): number | undefined =>
    // only accessory product input values have the selectedSiteProductVariantQuantityOptionId non-null
    state.productInputs.values.find(pi => pi.selectedSiteProductVariantQuantityOptionId)?.selectedSiteProductVariantQuantityOptionId;

export const selectNumSignatureLines = (state: RootState): number | undefined => {
  const strSignLines = state.productInputs.values.find(pi => pi.configurationKey === 'SignatureLines')?.value;
  return strSignLines ? Number.parseInt(strSignLines) : undefined;
}

export const { setProductInputValues,
               productInputValueChanged,
               productInputValueValidated,
               removeProductInputValue,
               removeProductInputValueByConfigurationDetailId,
               setActiveProductId,
               setAccessoryConfigurations
} = productInputSlice.actions;

export default productInputSlice.reducer;