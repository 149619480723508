import {useTranslation} from 'react-i18next';
import {UserMenu} from '../menu/UserMenu';
import {BannerView} from '../../banner/BannerView';
import './UserActionBar.css';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowsRotate, faPhone, faTruck} from '@fortawesome/free-solid-svg-icons';
import {isValidSite} from "../../../utils/Utils";
import {ProductSearchForm} from "../../../productSearch/form/ProductSearchForm";

export const UserActionBar = () => {
	const {t} = useTranslation();
	const isValidatedSite = isValidSite(window.location.hostname);
	const phoneNumber = 'tel:' + t(`phoneNumber.number`);

	return (
		<header>
			<div className="user-action-bar-container">
				
				<span className="banner-view-container">
					<BannerView/>
				</span>
				<span className="user-action-spacer flex-spacer"/>

				{isValidatedSite ? (
					<>
						<ProductSearchForm/>
						<span className="user-action-container user-action-link">
							<Link className="signInButton" to={phoneNumber}>
								<FontAwesomeIcon icon={faPhone}/>
								<span className="user-action-text">{t('phoneNumber.display')}</span>
							</Link>
						</span>
						<span className="user-action-container user-action-link">
							<Link className="signInButton" to={'/track-order'}>
								<FontAwesomeIcon icon={faTruck}/>
								<span className="user-action-text">{t('userAction.trackYourOrder')}</span>
							</Link>
						</span>
						<span className="user-action-container user-action-link reorder-checks-action-link">
							<Link className="signInButton" to={'/reorder'}>
								<FontAwesomeIcon icon={faArrowsRotate}/>
								<span className="user-action-text">{t('userAction.reorderChecks')}</span>
							</Link>
						</span>

						<span className="user-action-container user-action-link login-button">
							<UserMenu/>
						</span>
					</>
				) : (<></>)}

			</div>
		</header>
	);
}
