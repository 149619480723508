import {useGetCartProductsPricingQuery, useGetOrderPricingQuery} from "../../../app/apiSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {CartPricingRequest, SiteProductVariantPricing} from "../../cart/CartPricing";
import {ShippingOption} from "../../shipping-options/ShippingOption";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import React, {useEffect, useState} from "react";
import {Address} from "../../input/address/Address";
import {CartIdType} from "../../cart/CartSlice";
import {useDebounce} from "../../../app/hooks";
import {LoadingSpinner} from "../../input/utils/LoadingSpinner";

interface LineItemSummaryProps {
  orderId?: number,
  cartId?: CartIdType,
  shippingOption?: ShippingOption,
  toAddress?: Address
}

export const LineItemSummary = ({orderId, cartId, shippingOption, toAddress}: LineItemSummaryProps) => {
  // pricing could be for the order or for the cart, depending on which id is provided
  const [ cartPricingRequest, setCartPricingRequest ] = useState<CartPricingRequest | undefined>();
  const debounceCartPricingRequest = useDebounce(cartPricingRequest, 2_000);
  const { data: cartPricing, isLoading: isLoadingCartPricing} = useGetCartProductsPricingQuery((debounceCartPricingRequest ?? cartPricingRequest) ?? skipToken);
  const { data: orderPricing } = useGetOrderPricingQuery(orderId ?? skipToken);
  const pricing = cartId ? cartPricing : orderPricing;

  useEffect(() => {
    if (cartId) {
        const productShippingServiceSelections = shippingOption?.items.map((i) => {
            return {
                cartProductId: i.cartProductId,
                cost: i.price ?? 0,
                retailCost: i.retailPrice ?? 0
            }
        }) ?? [];
      setCartPricingRequest({cartId: cartId, toAddress: toAddress, productShippingServiceSelections});
    }
    else {
      setCartPricingRequest(undefined);
    }
  }, [cartId, toAddress, shippingOption]);

  const getBasePriceDisplay = (productPricing: SiteProductVariantPricing) => {
      return CurrencyFormatter.format(productPricing.retailBasePrice);
  }

    const getSubTotalPriceDisplay = (productPricing: SiteProductVariantPricing) => {
        return CurrencyFormatter.format(productPricing.retailSubTotalPrice);
    }

    const getHandlingPriceDisplay = (productPricing: SiteProductVariantPricing) => {
        return CurrencyFormatter.format(productPricing.retailHandlingPrice);
    }

    const getShippingPriceDisplay = (productPricing: SiteProductVariantPricing) => {
        return CurrencyFormatter.format(productPricing.retailShippingPrice ?? 0);
    }

    const getDiscountPriceDisplay = (productPricing: SiteProductVariantPricing) => {
        const discountAmount = productPricing.discountAmount ?? 0;
        return CurrencyFormatter.format(discountAmount !== 0 ? -discountAmount : discountAmount);
    }

    const getRefundPriceDisplay = (productPricing: SiteProductVariantPricing) => {
        const refundAmount = productPricing.refundAmount ?? 0;
        const voidedAmount = productPricing.voidedAmount ?? 0;
        const combinedReturns = voidedAmount - refundAmount;
        return CurrencyFormatter.format(combinedReturns !== 0 ? -combinedReturns : combinedReturns);
    }

  const ProductRow = (productPricing: SiteProductVariantPricing) => {
    return (
      <tr key={`line-item-${productPricing.cartProductId}`}>
        <td>{productPricing.sku}</td>
        <td>{getBasePriceDisplay(productPricing)}</td>
        <td>{CurrencyFormatter.format(productPricing.retailEzShieldPrice)}</td>
        <td>{CurrencyFormatter.format(productPricing.retailWoodCutPrice)}</td>
        <td>{CurrencyFormatter.format(productPricing.retailCustomMessagePrice)}</td>
        <td>{CurrencyFormatter.format(productPricing.retailExtraSigLinePrice)}</td>
        <td>{CurrencyFormatter.format(productPricing.retailFontPrice)}</td>
        <td>{getDiscountPriceDisplay(productPricing)}</td>
        <td>{getRefundPriceDisplay(productPricing)}</td>
        <td>{getHandlingPriceDisplay(productPricing)}</td>
        <td>{getShippingPriceDisplay(productPricing)}</td>
        <td>{getSubTotalPriceDisplay(productPricing)}</td>
        <td>{CurrencyFormatter.format(productPricing.retailTaxPrice)}</td>
        <td>{CurrencyFormatter.format(productPricing.total)}</td>
      </tr>
    )
  };
  
  return (
      <>
          {isLoadingCartPricing && <LoadingSpinner size="sm" /> }
          {pricing?.siteProductVariantTotals && <table className='table table-bordered'>
              <thead>
              <tr>
                  <th>Sku</th>
                  <th>Base</th>
                  <th>EZ Shield</th>
                  <th>Woodcut</th>
                  <th>Cust. Mesg.</th>
                  <th>Extra Sign Line</th>
                  <th>Font</th>
                  <th>Discounts</th>
                  <th>Refunds</th>
                  <th>Handling</th>
                  <th>Shipping</th>
                  <th>SubTotal</th>
                  <th>Tax</th>
                  <th>Total</th>
              </tr>
              </thead>
              <tbody>
              { pricing?.siteProductVariantTotals?.map(ProductRow) }
              </tbody>
          </table>}
      </>
  );
}