import './ConfigurationControlBar.css';
import {ConfigurationPaging} from "../ConfigurationPaging";
import {Button, Container, Spinner} from "reactstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {SiteProduct} from "../../../product/Product";
import {faArrowLeft, faArrowRight, faCartShopping} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface ConfigurationControlBarProps {
	product: SiteProduct,
	pages: ConfigurationPaging[],
	currentPageIndex: number,
	cartProductId?: number,
	onBack?: ()=> void,
	onSelectedPageChange: (pageNumber: number, shouldScroll?: boolean) => void;
	addToCart: () => void;
	isAddingToCart: boolean;
	isReadOnly: boolean;
	onCancelChanges: () => void;
}

export const ConfigurationControlBar = ({ product, pages, currentPageIndex, cartProductId, onBack, onSelectedPageChange, addToCart, isAddingToCart, isReadOnly, onCancelChanges }: ConfigurationControlBarProps) => {
	const navigate = useNavigate();
	const isAdminSite = useLocation().pathname.includes('/admin/');

	const onNextButtonClicked = async () => {
		if ((pages.length - 1) <= currentPageIndex) {
			// On final page, add to cart
			addToCart();
		}
		else {
			// Not on final page, continue
			onSelectedPageChange(currentPageIndex + 1, true);
		}
	}

	function onPreviousButtonClicked() {
		if (currentPageIndex !== 0) {
			onSelectedPageChange(currentPageIndex - 1, true);
		}
		else {
			(!isAdminSite ? navigate(-1) : onBack?.());
		}
	}

	function getNextPageName() {
		const nextPageIndex = (currentPageIndex + 1);

		if (isNotOnLastPage()) {
			return (pages[nextPageIndex].actionLabel ?? pages[nextPageIndex].name);
		}
		else {
			return (cartProductId ? "Update Product in Cart" : "Add to Cart");
		}
	}

	function isNotOnLastPage() {
		const nextPageIndex = (currentPageIndex + 1);
		return (pages.length > nextPageIndex && pages[nextPageIndex]);
	}

	return (
		<Container className="configuration-control-page-container">
			{!isReadOnly &&
				<span className="configuration-control-page-nav-button-container">
					<Button className='previous-configuration-page-button' onClick={(_) => onPreviousButtonClicked()}>
						<FontAwesomeIcon icon={faArrowLeft} /> 
						<h6>Back</h6>
					</Button>


					{ !!cartProductId && <Button color='danger' className='cancel-configuration-changes-button' onClick={onCancelChanges}>Cancel Changes</Button> }

					<Button
						disabled={isAddingToCart}
						onClick={(_) => onNextButtonClicked()}
						className="next-configuration-page-button"
						color="primary"
					>
					<>
						{isAddingToCart && <Spinner/>}
						<h6>{getNextPageName()}</h6>
						{isNotOnLastPage() ? <FontAwesomeIcon icon={faArrowRight} /> : <FontAwesomeIcon icon={faCartShopping} />}
					</>
					</Button>
				</span>
			}
		</Container>
	)
}
