
export interface SiteProductVariantQuantityOption {
  id: number,
  siteProductVariantId: number,
  quantity: number,
  description: string,
  caption?: string,
  isDefault: boolean,
  price: number
  checksPerPad?: number,
  padsPerUnit?: number,
  variantDescription?: string,
  isActive: boolean,
  
  // isPublic not important to the client app, it will only ever get ispublic true products
}

export function isBestDeal(quantity: SiteProductVariantQuantityOption, options: readonly SiteProductVariantQuantityOption[]): boolean {
  if (options.length <= 1) {
    return false;
  }
  const pricePerCheck = pricePerPack(quantity);
  return options.filter(qo => qo.isActive)
      .filter(qo => 
        pricePerCheck >= pricePerPack(qo)).length === 1;
}

export const pricePerPack = (option: SiteProductVariantQuantityOption) =>
    option.price / option.quantity

export function calculateSavings(option: SiteProductVariantQuantityOption, options: readonly SiteProductVariantQuantityOption[]): number {
  const highestPricedOption = options.reduce((previousValue, currentValue) => {
    return pricePerPack(previousValue) >= pricePerPack(currentValue)
        ? previousValue
        : currentValue
  });
  return (pricePerPack(highestPricedOption) - pricePerPack(option)) * option.quantity;
}

export function isLargestQuantity(option: SiteProductVariantQuantityOption, options: readonly SiteProductVariantQuantityOption[]): boolean {
  return options.every(o => o.quantity <= option.quantity);
}


export interface QuantityValueForProduct {
  productId: number,
  quantityValue: number
}