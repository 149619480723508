import {
    useGetBankInformationByOrderProoductIdQuery,
    useGetFinancialInstitutionByIdQuery,
    useGetFinancialInstitutionsQuery,
    useGetMicrCheckProductTypesQuery,
    useGetMicrFormatByIdQuery,
    useGetMicrFormatHistoryQuery,
    useSaveMicrFormatMutation
} from "../../../../app/apiSlice";
import {Link, useNavigate, useParams} from "react-router-dom";
import './MicrEdit.css';
import {Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, FormFeedback, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, Table} from "reactstrap";
import {
    CheckProductTypeName,
    DEFAULT_MICR_MAX_ACCOUNT_NUMBER_LENGTH,
    DEFAULT_MICR_MIN_ACCOUNT_NUMBER_LENGTH,
    MICR_ACCOUNT_NUMBER_CHARACTER,
    MICR_OPTIONAL_ACCOUNT_NUMBER_CHARACTER,
    MicrFormatConstraints,
    MicrFormatEdit,
    MicrFormatFieldNames,
    MicrFormatTemplate
} from "../MicrFormat";
import {createArrayOfNumbersUsingMinAndMax, Regexes, replaceStringCharacterGroupWithValue} from "../../../../utils/Utils";
import {MicrTextInput} from "../textInput/MicrTextInput";
import {MicrLegend} from "../legend/MicrLegend";
import React, {useEffect, useState} from "react";
import {MicrPreview} from "../preview/MicrPreview";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {ProductType} from "../../../product/Product";
import {useAppDispatch, useClaims, useValidation} from "../../../../app/hooks";
import {ADMIN, CS3, TEAM_LEAD} from "../../../../utils/Roles";
import {DateTime} from "luxon";
import {OrderProductVm} from "../../../order/OrderProductVm";
import {Typeahead} from "react-bootstrap-typeahead";
import {FinancialInstitution} from "../../financialInstitution/FinancialInstitution";
import {LoadingSpinner} from "../../../input/utils/LoadingSpinner";
import {showConfirmationModal, showInformationModal} from "../../../modal/ModalSlice";


interface MicrEditProps {
    orderId?: number
    orderProduct?: OrderProductVm
    propMicrFormatId?: number
    propFinancialInstitutionId?: number
    allowFinancialInstitutionEdit?:boolean
    onEditModeToggle?: (orderProductId: number) => void
}

export const MicrEdit = ({orderId, orderProduct, propMicrFormatId, propFinancialInstitutionId, allowFinancialInstitutionEdit, onEditModeToggle}: MicrEditProps) => {
    const dispatch = useAppDispatch();
    const { paramMicrFormatId, paramFinancialInstitutionId } = useParams();
    const [micrFormatId, setMicrFormatId] = useState<number | undefined>(paramMicrFormatId ? Number.parseInt(paramMicrFormatId) : (propMicrFormatId ? propMicrFormatId : undefined));
    const financialInstitutionId = paramFinancialInstitutionId ? Number.parseInt(paramFinancialInstitutionId) : (propFinancialInstitutionId ? propFinancialInstitutionId : undefined);
    const { hasPermission } = useClaims();
    const { data: micrFormat, isLoading } = useGetMicrFormatByIdQuery(micrFormatId ?? skipToken);
    const { data: financialInstitutions } = useGetFinancialInstitutionsQuery(!allowFinancialInstitutionEdit ? skipToken : undefined);
    const { data: financialInstitution } = useGetFinancialInstitutionByIdQuery(financialInstitutionId ?? skipToken);
    const { data: checkProductTypes} = useGetMicrCheckProductTypesQuery();
    const { data: micrBankInformation } = useGetBankInformationByOrderProoductIdQuery(orderProduct?.id ?? skipToken);
    const { data: history } = useGetMicrFormatHistoryQuery(micrFormatId ?? skipToken);
    const [activeTab, setActiveTab] = useState('General');
    const [ showTypeaheadOptions, setShowTypeaheadOptions ] = useState<boolean>(false);
    const accountNumberLengthRange = createArrayOfNumbersUsingMinAndMax(MicrFormatConstraints.AccountNumberMin, MicrFormatConstraints.AccountNumberMax);
    const [micrFormatEdit, setMicrFormatEdit] = useState<MicrFormatEdit | undefined>(undefined);
    const [ saveMicrFormat ] = useSaveMicrFormatMutation();
    const navigate = useNavigate();
    // CS3 can save if this is an order-specific MICR format
    const hasPermissionToSave = hasPermission([ADMIN, TEAM_LEAD, ...(orderProduct ? [CS3] : [])]);
    const requiredCount = () =>
        micrFormatEdit?.formatTemplate?.match(Regexes.micrAccountNumberChar)?.length ?? 0;
    
    const optionalCount = () =>
        micrFormatEdit?.formatTemplate?.match(Regexes.micrOptionalAccountNumberChar)?.length ?? 0;
    
    const totalCount = () =>
        requiredCount() + optionalCount();

    useEffect(() => {
        if (micrFormatEdit) {
            validation.setValue(MicrFormatFieldNames.AccountNumberMin, micrFormatEdit.accountNumberMinLength?.toString());
            validation.setValue(MicrFormatFieldNames.AccountNumberMax, micrFormatEdit.accountNumberMaxLength?.toString());
            validation.setValue(MicrFormatFieldNames.AccountNumberMatch, micrFormatEdit.accountNumberMatch);
            validation.hideErrors(false);
        }
    }, [micrFormatEdit]);
    
    const validateMinLength = (val?: string) => {
        if (!micrFormatEdit || micrFormatEdit.orderProductId) return undefined;
        if (micrFormatEdit.usesAccountNumberMatch) return undefined;
        if (micrFormatEdit.accountNumberMinLength! > micrFormatEdit.accountNumberMaxLength!)
            return 'Min length must be less than or equal to max length';
        if (requiredCount() !== Number.parseInt(val!))
            return `Micr Template requires ${requiredCount()} digits`;
    }
    
    const validateMaxLength = (val?: string) => {
        if (!micrFormatEdit || micrFormatEdit.orderProductId) return undefined;
        if (micrFormatEdit.usesAccountNumberMatch) return undefined;
        if (micrFormatEdit.accountNumberMinLength! > micrFormatEdit.accountNumberMaxLength!)
            return 'Min length must be less than or equal to max length';
        
        if (totalCount() !== Number.parseInt(val!)) {
            return `Micr Template allows ${totalCount()} digits`;
        }
    }
    
    const validateAccountNumberMatch = (val?: string) => {
        if (!micrFormatEdit || micrFormatEdit.orderProductId) return undefined;
        if (!micrFormatEdit.usesAccountNumberMatch) return undefined;
        if (!val)
            return 'Required';
        if (val.length > 15)
            return 'Account number cannot be greater than 15 digits';
        if (val.length < requiredCount())
            return `Micr Template requires ${requiredCount()} digits`;
        if (val.length > totalCount())
            return `Micr Template only allows ${totalCount()} digits`;
    }
    
    const validation = useValidation({
        [MicrFormatFieldNames.AccountNumberMin]: validateMinLength,
        [MicrFormatFieldNames.AccountNumberMax]: validateMaxLength,
        [MicrFormatFieldNames.AccountNumberMatch]: validateAccountNumberMatch,
    });

    useEffect(() => {
        // If editing existing micr
        if (micrFormat) {
            // If the micr being edited is not associated with an order id, but we're in the context of an order,
            // Then we're creating a new micr override for this order based on the previous matching micr
            // Set id to undefined so that we create a new micr override for this order
            const editId = (!micrFormat.orderProductId && orderProduct?.id) ? undefined : micrFormat.id;
            setMicrFormatEdit({...micrFormat,
                usesAccountNumberMatch: (micrFormat.accountNumberMatch !== undefined && micrFormat.accountNumberMatch !== null),
                orderProductId: orderProduct?.id,
                id: editId
            });
        }
        // If creating new micr for financial institution
        else if (!micrFormat && !micrFormatId && financialInstitutionId && financialInstitution) {
            setMicrFormatEdit({
                financialInstitutionId: financialInstitutionId,
                formatTemplate: replaceStringCharacterGroupWithValue('R', MicrFormatTemplate.Default, financialInstitution.abaNumber.toString()),
                accountNumberMinLength: DEFAULT_MICR_MIN_ACCOUNT_NUMBER_LENGTH,
                accountNumberMaxLength: DEFAULT_MICR_MAX_ACCOUNT_NUMBER_LENGTH,
                orderProductId: orderProduct?.id,
                isActive: true
            });
        }
        // If creating new micr with no financial institution for order
        else if (!micrFormat && !financialInstitutionId && !micrFormatId) {
            setMicrFormatEdit({
                formatTemplate: MicrFormatTemplate.Default,
                accountNumberMinLength: DEFAULT_MICR_MIN_ACCOUNT_NUMBER_LENGTH,
                accountNumberMaxLength: DEFAULT_MICR_MAX_ACCOUNT_NUMBER_LENGTH,
                orderProductId: orderProduct?.id,
                isActive: true
            });
        }
    }, [micrFormat, financialInstitution]);

    useEffect(() => {
        const firstFinancialInstitution = financialInstitutions?.find(fi => true);
        if (firstFinancialInstitution && micrFormatEdit && !micrFormatEdit?.financialInstitutionId) {
            setMicrFormatEdit({...micrFormatEdit, financialInstitutionId: firstFinancialInstitution.id});
        }
    }, [financialInstitutions])

    const confirmSaveMicr = async () => {
        if (micrFormatEdit?.formatTemplate && micrFormatEdit.financialInstitutionId) {
            const micrId = await saveMicrFormat({
                ...micrFormatEdit,
                formatTemplate: micrFormatEdit.formatTemplate,
                financialInstitutionId: micrFormatEdit.financialInstitutionId,
                // force undefined on usesAccountNumberMatch if we're editing order
                usesAccountNumberMatch: (orderId || orderProduct) ? undefined: micrFormatEdit.usesAccountNumberMatch,
            }).unwrap();
            setMicrFormatId(micrId);
            // If saving a new non-order-specific micr, redirect back to financial institution
            if (!micrFormatId && financialInstitutionId && !orderProduct?.id)
                navigate(`/admin/financialInstitution/${financialInstitutionId}`);

            if (orderProduct && onEditModeToggle)
                onEditModeToggle(orderProduct.id);
        }
    }

    const onCancelMicrEditClick = () => {
        if (orderProduct && onEditModeToggle) {
            onEditModeToggle(orderProduct.id);
        }
        else {
            window.history.back();
        }
    }

    const onSaveMicrClick = async () => {

        if (!micrFormatEdit
            || !validation.allValid()
            || (((micrFormatEdit?.formatTemplate && micrFormatEdit?.formatTemplate.length !== 65)
                || (micrFormatEdit.checkProductTypes === undefined || micrFormatEdit.checkProductTypes.length === 0)
                || (micrFormatEdit.accountNumberMatch === undefined && (micrFormatEdit.accountNumberMaxLength === undefined || micrFormatEdit.accountNumberMinLength === undefined))
                || (micrFormatEdit.financialInstitutionId === undefined) )
                && !micrFormatEdit.orderProductId)
        ) {
            dispatch(showInformationModal({
                title: 'Cannot Save',
                text: 'Some required information is missing. Please make sure at least one check type is selected, either the min/max account number length are set OR the account number exact match is set, and enter a valid Micr format.',
            }))
            return;
        }

        const {diffList, content} = buildMicrDiffList();
        const size = Math.max(...(diffList.map(mdl => mdl.length))) > 60 ? 'xl' : 'm';

        dispatch(showConfirmationModal({
            title: 'Save Micr Format?',
            content: content,
            affirmText: 'Yes, Save',
            size,
            onConfirm: confirmSaveMicr
        }));
    }
    
    const updateMicrEdit = <Key extends keyof MicrFormatEdit>(key: Key, value: MicrFormatEdit[Key]) => {
        const update = {
            ...micrFormatEdit,
            [key]: value
        } as MicrFormatEdit;
        
        setMicrFormatEdit(update);
        validation.setValue(key, value?.toString());
    }

    const buildMicrDiffList = () => {
        const diffList: string[] = [];


        if (micrFormat?.accountNumberMaxLength !== micrFormatEdit?.accountNumberMaxLength) {
            diffList.push(
                `Account Number Max Length was updated from ${micrFormat?.accountNumberMaxLength?.toString() ?? "No value"} to ${micrFormatEdit?.accountNumberMaxLength?.toString() ?? "No value"}`
            );
        }
        if (micrFormat?.accountNumberMinLength !== micrFormatEdit?.accountNumberMinLength) {
            diffList.push(
                `Account Number Min Length was updated from ${micrFormat?.accountNumberMinLength?.toString() ?? "No value"} to ${micrFormatEdit?.accountNumberMinLength?.toString() ?? "No value"}`
            );
        }
        if (micrFormat?.accountNumberMatch !== micrFormatEdit?.accountNumberMatch) {
            diffList.push(
                `Account Number Match was updated from ${micrFormat?.accountNumberMatch?.toString() ?? "No value"} to ${micrFormatEdit?.accountNumberMatch?.toString() ?? "No value"}`
            );
        }
        if (micrFormat?.formatTemplate !== micrFormatEdit?.formatTemplate) {
            diffList.push(
                `Format template was updated from 
                ${micrFormat?.formatTemplate.replace(/ /g,"_") ?? "No value"} 
to 
                ${micrFormatEdit?.formatTemplate?.replace(/ /g,"_") ?? "No value"}`
            );
        }
        if (micrFormat?.financialInstitutionId !== micrFormatEdit?.financialInstitutionId) {
            diffList.push(
                `Financial Institution was updated from ${micrFormat?.financialInstitution?.name?.toString() ?? "No value"} to ${micrFormatEdit?.financialInstitution?.name?.toString() ?? "No value"}`
            );
        }
        
        if (micrFormat?.isActive !== micrFormatEdit?.isActive && typeof micrFormatEdit?.isActive === 'boolean') {
            const active = micrFormatEdit.isActive;
            diffList.push(
                `Micr format was set to ${active ? 'active' : 'inactive'}`
            );
            if (!active) {
                if (micrFormat?.matchingInProgressOrderIds && micrFormat.matchingInProgressOrderIds.length > 0)
                    diffList.push(
                        `The following order ids are new orders that match this micr format: ${micrFormat.matchingInProgressOrderIds.join(', ')}`
                    )
                else
                    diffList.push(
                        `There are no new orders that match this micr format.`
                    )
            }
        }

        const content = <>
            <p>Are you sure you want to update the Micr Format?</p>
            <ul className='confirmation-modal-list-points-container'>
                { diffList.map((dl, i) => 
                    <li key={`confirmation-modal-list-point-${i}`}>{dl}</li>)
                }
            </ul>
        </>;
        
        return { diffList, content };
    }

    const adjustFormatToMatchRange = (formatTemplate: string, minLength?: number, maxLength?: number) => {
        if (!minLength || !maxLength)
            return formatTemplate;

        let requiredCharacterCount = formatTemplate.split(MICR_ACCOUNT_NUMBER_CHARACTER).length - 1;

        if (requiredCharacterCount < minLength) {
            formatTemplate = addPadding(formatTemplate, MICR_ACCOUNT_NUMBER_CHARACTER, MICR_ACCOUNT_NUMBER_CHARACTER, minLength - requiredCharacterCount, true);
        }
        else if (requiredCharacterCount > minLength) {
            formatTemplate = removePadding(formatTemplate, MICR_ACCOUNT_NUMBER_CHARACTER, requiredCharacterCount - minLength);
        }

        requiredCharacterCount = formatTemplate.split(MICR_ACCOUNT_NUMBER_CHARACTER).length - 1;
        const optionalCharacterCount = formatTemplate.split(MICR_OPTIONAL_ACCOUNT_NUMBER_CHARACTER).length - 1;
        const accountNumberCharacterCount = requiredCharacterCount + optionalCharacterCount;
        if (accountNumberCharacterCount < maxLength) {
            formatTemplate = addPadding(formatTemplate, MICR_OPTIONAL_ACCOUNT_NUMBER_CHARACTER, MICR_ACCOUNT_NUMBER_CHARACTER, maxLength - accountNumberCharacterCount, false);
        }
        else if (accountNumberCharacterCount > maxLength) {
            formatTemplate = removePadding(formatTemplate, MICR_OPTIONAL_ACCOUNT_NUMBER_CHARACTER, accountNumberCharacterCount - maxLength);
        }

        return padToMaxLength(formatTemplate);
    }

    function padToMaxLength(input: string) {
        if (input.length >= MicrFormatConstraints.MicrTextLength) {
            return input;
        }

        const paddingLength = MicrFormatConstraints.MicrTextLength - input.length;
        const padding = " ".repeat(paddingLength);

        return input + padding;
    }

    function addPadding(input: string, padChar: string, referenceChar: string, paddingCount: number, isAppend: boolean): string {
        const targetLength = MicrFormatConstraints.MicrTextLength;
        const trimmedInput = input.trimEnd();
        const trimmedLength = trimmedInput.length;

        // If no whitespace at the end, then just return
        if (trimmedLength >= targetLength) {
            return input;
        }

        const lastIndexOfReferenceChar = isAppend ? trimmedInput.lastIndexOf(referenceChar) : trimmedInput.indexOf(referenceChar);
        const indexOfPaddingChar = isAppend ? lastIndexOfReferenceChar + 1 : lastIndexOfReferenceChar;

        const padding = padChar.repeat(paddingCount);
        return trimmedInput.slice(0, indexOfPaddingChar) + padding + trimmedInput.slice(indexOfPaddingChar);
    }

    function removePadding(input: string, charToReplace: string, replaceCount: number): string {
        if (replaceCount <= 0) {
            return input;
        }

        // Replace one at a time, this helps when formatting oddly formatted strings
        for (let i = 0; i < replaceCount; i++) {
            const regex = new RegExp(`${charToReplace}(?=${charToReplace}{0,0}(?![${charToReplace}\\d]))`, "");
            input = input.replace(regex, "");
        }

        return input;
    }

    const onMicrValueChange = (micrFieldName: MicrFormatFieldNames, value?: string) => {
        if (!micrFormatEdit) {
            return;
        }

        let updatedFormatTemplate;
        switch (micrFieldName) {
            case MicrFormatFieldNames.AccountNumberMin:
                updatedFormatTemplate = adjustFormatToMatchRange(micrFormatEdit.formatTemplate, value ? Number.parseInt(value) : undefined, micrFormatEdit.accountNumberMaxLength);
                setMicrFormatEdit({...micrFormatEdit, formatTemplate: updatedFormatTemplate, accountNumberMinLength: Number.parseInt(value ?? "")});
                break;
            case MicrFormatFieldNames.AccountNumberMax:
                updatedFormatTemplate = adjustFormatToMatchRange(micrFormatEdit.formatTemplate, micrFormatEdit.accountNumberMinLength, value ? Number.parseInt(value) : undefined);
                setMicrFormatEdit({...micrFormatEdit, formatTemplate: updatedFormatTemplate, accountNumberMaxLength: Number.parseInt(value ?? "")});
                break;
            case MicrFormatFieldNames.AccountNumberMatch:
                const isInvalid = !Regexes.number.test(value ?? "");
                if (!isInvalid || !value)
                    setMicrFormatEdit({...micrFormatEdit, accountNumberMatch: value ?? ""});
                break;
            case MicrFormatFieldNames.MicrFormatText:
                setMicrFormatEdit({...micrFormatEdit, formatTemplate: value ?? ""});
                break;
            case MicrFormatFieldNames.FinancialInstitution:
                const parsedFinancialInstitutionId = value ? Number.parseInt(value) : undefined;
                const updatedFinancialInstitutionIndex = financialInstitutions?.findIndex((fi) => fi.id === parsedFinancialInstitutionId);
                if (parsedFinancialInstitutionId && financialInstitutions && updatedFinancialInstitutionIndex !== undefined && updatedFinancialInstitutionIndex > -1) {
                    setMicrFormatEdit({...micrFormatEdit,
                        financialInstitutionId: parsedFinancialInstitutionId,
                        financialInstitution: financialInstitutions[updatedFinancialInstitutionIndex]
                    });
                }
                else {
                    setMicrFormatEdit({...micrFormatEdit,
                        financialInstitutionId: undefined,
                        financialInstitution: undefined
                    });
                }
                break;
        }
    }

    const onMicrProductTypeChange = (checkProductType: ProductType, checked: boolean) => {
        if (checkProductType.name === CheckProductTypeName.Business && checked && financialInstitution) {
            setMicrFormatEdit(old => old && ({...old, formatTemplate: replaceStringCharacterGroupWithValue('R', MicrFormatTemplate.Business, financialInstitution.abaNumber.toString()), checkProductTypes: [...(old.checkProductTypes ?? []), checkProductType]}));
        }
        if (!checked && financialInstitution) {
            setMicrFormatEdit(old => old && ({...old, formatTemplate: replaceStringCharacterGroupWithValue('R', MicrFormatTemplate.Default, financialInstitution.abaNumber.toString()), checkProductTypes: [...(old.checkProductTypes ?? []), checkProductType]}));
        }
        if (checkProductType.name === CheckProductTypeName.Personal && checked && financialInstitution) {
            setMicrFormatEdit(old => old && ({...old, formatTemplate: replaceStringCharacterGroupWithValue('R', MicrFormatTemplate.Personal, financialInstitution.abaNumber.toString()), checkProductTypes: [...(old.checkProductTypes ?? []), checkProductType]}));
        }
        if (checked) {   
            setMicrFormatEdit(old => old && ({...old, checkProductTypes: [...(old.checkProductTypes ?? []), checkProductType]}));
        } else {
            setMicrFormatEdit(old => old && ({...old, checkProductTypes: old.checkProductTypes?.filter(cpt => cpt.id !== checkProductType.id) ?? []}));
        }
    }

    const isProductTypeSelected = (checkProductType: ProductType) => {
        if (!micrFormatEdit) {
            return false;
        }

        const checkProductTypeIndex = micrFormatEdit.checkProductTypes?.findIndex(cpt => cpt.id === checkProductType.id) ?? -1;
        return checkProductTypeIndex > -1;
    }

    const areChangesPending = () => {
        return (
            !micrFormat || (
                micrFormat.isActive !== micrFormatEdit?.isActive ||
                micrFormat.formatTemplate !== micrFormatEdit?.formatTemplate ||
                micrFormat.accountNumberMinLength !== micrFormatEdit?.accountNumberMinLength ||
                micrFormat.accountNumberMaxLength !== micrFormatEdit?.accountNumberMaxLength||
                micrFormat.accountNumberMatch !== micrFormatEdit?.accountNumberMatch ||
                micrFormat.financialInstitutionId !== micrFormatEdit?.financialInstitutionId ||
                (
                    !compareCheckProductTypes(micrFormat.checkProductTypes ?? [], micrFormatEdit.checkProductTypes ?? [])
                )
            )
        );
    }

    const isMissingRequiredData = () => {
        return (
            !micrFormatEdit?.financialInstitutionId || !micrFormatEdit.formatTemplate
        )
    }
    const compareCheckProductTypes = (checkProductTypesOne: ProductType[], checkProductTypesTwo: ProductType[]) => {
        if (checkProductTypesOne.length !== checkProductTypesTwo.length) {
            return false;
        }

        for (const checkProductTypeOne of checkProductTypesOne) {
            let found = false;
            for (const checkProductTypeTwo of checkProductTypesTwo) {
                if (checkProductTypeOne.id === checkProductTypeTwo.id) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                return false;
            }
        }

        return true;
    }


    const getPreviewElement = () => {
        if (!micrFormatEdit?.formatTemplate || !micrFormatEdit.financialInstitutionId) {
            return <></>;
        }
        return (
            <MicrPreview micrFormat={micrFormatEdit}
                         accountNumberDefault={micrBankInformation?.accountNumber}
            ></MicrPreview>
        )
    }

    const shouldEditingBeDisabled = () => {
        // Disable editing if micr is inactive
        return !!(micrFormat && !micrFormat.isActive);
    }

    const getMatchInputRow = () => {
        if (!micrFormatEdit || micrFormatEdit.orderProductId) {
            return <></>;
        }
        return micrFormatEdit.usesAccountNumberMatch
            ? (
                <Row>
                    <Col className="micr-edit-input-column">
                        <Label className="micr-edit-input-label">Account Number Match:</Label>
                        <Input type="text"
                               className="micr-edit-input"
                               disabled={shouldEditingBeDisabled()}
                               invalid={validation.getError(MicrFormatFieldNames.AccountNumberMatch) !== undefined}
                               onChange={(e) => onMicrValueChange(MicrFormatFieldNames.AccountNumberMatch, e.target.value)}
                               value={micrFormatEdit.accountNumberMatch ?? ""}/>
                        <FormFeedback>{validation.getError(MicrFormatFieldNames.AccountNumberMatch)}</FormFeedback>
                    </Col>
                </Row>
            )
            : (
                <Row>
                    <Col className="micr-edit-input-column">
                        <Label className="micr-edit-input-label">Account Number Min Length:</Label>
                        <Input type="select"
                               className="micr-edit-input"
                               disabled={shouldEditingBeDisabled()}
                               invalid={validation.getError(MicrFormatFieldNames.AccountNumberMin) !== undefined}
                               onChange={(e) => onMicrValueChange(MicrFormatFieldNames.AccountNumberMin, e.target.value)}
                               value={micrFormatEdit.accountNumberMinLength}>
                            {accountNumberLengthRange.map((accountNumberLength) => {
                                return (
                                    <option key={`account-number-length-${accountNumberLength}`}
                                            value={accountNumberLength}>
                                        {accountNumberLength}
                                    </option>
                                );
                            })}
                        </Input>
                        <FormFeedback>{validation.getError(MicrFormatFieldNames.AccountNumberMin)}</FormFeedback>
                    </Col>
                    <Col className="micr-edit-input-column">
                        <Label className="micr-edit-input-label">Account Number Max Length:</Label>
                        <Input type="select"
                               className="micr-edit-input"
                               disabled={shouldEditingBeDisabled()}
                               invalid={validation.getError(MicrFormatFieldNames.AccountNumberMax) !== undefined}
                               onChange={(e) => onMicrValueChange(MicrFormatFieldNames.AccountNumberMax, e.target.value)}
                               value={micrFormatEdit.accountNumberMaxLength}>
                            {accountNumberLengthRange.map((accountNumberLength) => {
                                return (
                                    <option key={`account-number-length-${accountNumberLength}`} value={accountNumberLength}>
                                        {accountNumberLength}
                                    </option>
                                );
                            })}
                        </Input>
                        <FormFeedback>{validation.getError(MicrFormatFieldNames.AccountNumberMax)}</FormFeedback>
                    </Col>
                </Row>
            )
    }

    const handleFinancialInstitutionSelected = (selectedFinancialInstitution: FinancialInstitution[]) => {
        onMicrValueChange(MicrFormatFieldNames.FinancialInstitution, selectedFinancialInstitution[0] ?
            `${selectedFinancialInstitution[0].id}` : undefined);
        setShowTypeaheadOptions(false);
    }

    const getFinancialInstitutionControl = () => {
        if (allowFinancialInstitutionEdit && financialInstitutions) {
            const selectedFi = financialInstitutions?.find(fi => fi.id === micrFormatEdit?.financialInstitutionId);
            return (
                <span className="financial-institution-select-menu">
                    <Label for='financial-institution-list'
                           className="micr-edit-input-label">Financial Institution</Label>
                    <Typeahead
                        clearButton
                        maxResults={25}
                        paginate
                        id="financial-institution-selection"
                        options={financialInstitutions ?? []}
                        labelKey={(opt) => typeof opt !== 'string' ? `${opt.name} - ${opt.abaNumber}` : opt}
                        onChange={(selectedOption) => handleFinancialInstitutionSelected(selectedOption as FinancialInstitution[])}
                        placeholder="Select a financial institution..."
                        open={showTypeaheadOptions}
                        onFocus={() => setShowTypeaheadOptions(true)}
                        onBlur={() => setShowTypeaheadOptions(false)}
                        selected={micrFormatEdit?.financialInstitutionId && selectedFi ? [ selectedFi ] : []}
                        style={{maxHeight: '38px'}}
                    />

                </span>
            )
        }
        else {
            return (
                <Link to={`/admin/financialInstitution/${micrFormatEdit?.financialInstitutionId}`}
                      className="micr-edit-financial-instutition-name">
                    {micrFormatEdit?.financialInstitution?.name}
                </Link>
            )
        }
    }

    const getOrderIdDisplay = () => {
        if (!orderProduct)
            return <></>;

        return (
            <>
                <Label className="micr-edit-input-label">
                    Order Product: {orderProduct?.sku}
                </Label>
            </>
        )
    }

    const getMicrMatchingRuleRow = () => {
        if(!micrFormatEdit || micrFormatEdit?.orderProductId)
            return <></>;

        return (

            <Row>
                <Col className="micr-edit-input-matches-on-selector-container">
                    <ButtonGroup>
                        <Button
                            color="primary"
                            outline
                            onClick={() => setMicrFormatEdit({...micrFormatEdit, usesAccountNumberMatch: true})}
                            active={micrFormatEdit.usesAccountNumberMatch}
                        >
                            Matches on Account Number Exact Match
                        </Button>
                        <Button
                            color="primary"
                            outline
                            onClick={() => setMicrFormatEdit({...micrFormatEdit, usesAccountNumberMatch: false})}
                            active={!micrFormatEdit.usesAccountNumberMatch}
                        >
                            Matches on Account Number Range
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        )
    }

    const getProductTypesRow = () => {
        if (!micrFormatEdit || micrFormatEdit.orderProductId)
            return <></>;

        return (
            <Row>
                {checkProductTypes?.map((cpt) => {
                    return (
                        <Col key={`micr-edit-check-format-${cpt.id}`}>
                            <FormGroup switch>
                                <Input type="switch"
                                       role="switch"
                                       disabled={shouldEditingBeDisabled()}
                                       checked={isProductTypeSelected(cpt)}
                                       onChange={(e) => onMicrProductTypeChange(cpt, e.target.checked)}
                                />
                                <Label check
                                       className="micr-edit-label">{cpt.name}</Label>
                            </FormGroup>
                        </Col>
                    )
                })}
            </Row>
        );
    }
    
    const getEnabledSwitch = () => {
        if (typeof micrFormatEdit?.isActive !== 'boolean')
            return <></>;
        
        const onActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            updateMicrEdit('isActive', !micrFormatEdit?.isActive!);
        }
        
        return (!orderProduct && <FormGroup switch>
            <Label>
                <Input type="switch"
                       role="switch"
                       onChange={onActiveChange}
                       checked={micrFormatEdit?.isActive}></Input>
                <span>Is Active</span>
            </Label>
        </FormGroup>)
    }
               

    
    if (isLoading) {
        return <LoadingSpinner size="sm" />
    }
    
    if (!micrFormatEdit) {
        return (micrFormatId ? <>Invalid micr id</> : <>Invalid financial institution id</>);
    }

    return (
        <Container>


            <Nav tabs>
                <NavItem>
                    <NavLink active={activeTab === 'General'} onClick={() => setActiveTab('General')}>General</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={activeTab === 'History'}
                             onClick={() => setActiveTab('History')}>History</NavLink>
                </NavItem>
            </Nav>

            {
                activeTab === 'General' &&
                <Card className="financial-institution-details-card">

                    <CardHeader className='d-flex justify-content-between card-header'>
                        <h5 className="m-0">Micr Format</h5>
                        <span className="micr-edit-action-button-container">
                            <Button color='secondary'
                                    onClick={onCancelMicrEditClick}>
                                Cancel
                            </Button>
                            { hasPermissionToSave &&
                                <Button color="success"
                                        disabled={!areChangesPending() || isMissingRequiredData()}
                                        onClick={onSaveMicrClick}
                                >Save</Button>
                            }
                        </span>
                    </CardHeader>
                    <CardBody>

                        <Row>
                            <Col>
                                {getFinancialInstitutionControl()}
                            </Col>
                            <Col className="d-flex flex-column align-items-end">
                                { getEnabledSwitch() }
                                { getOrderIdDisplay() }
                            </Col>
                        </Row>

                        {getMicrMatchingRuleRow()}

                        {getMatchInputRow()}

                        {getProductTypesRow()}

                        <Row className="micr-edit-template-edit">
                            <Col>
                                <h5 className="m-0">Micr Template</h5>
                                <MicrTextInput textValue={micrFormatEdit.formatTemplate ?? ""}
                                               micrFormat={micrFormatEdit}
                                               onChange={onMicrValueChange}
                                               isReadOnly={shouldEditingBeDisabled()}/>
                                <span className='text-danger'>{ validation.getError(MicrFormatFieldNames.MicrFormatText) }</span>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs='6'>
                                <MicrLegend/>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            }

            {
                activeTab === 'History' &&
                <Table>
                    <thead>
                    <tr>
                        <th>User</th>
                        <th>Event Date</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    { history?.map(record => (
                        <tr key={record.id}>
                            <td>{record.changeBy}</td>
                            <td>{DateTime.fromISO(record.timeOfChangeLocal).toLocaleString(DateTime.DATETIME_SHORT)}</td>
                            <td className="micr-edit-history-description">{record.changeDescription}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            }
        </Container>
    )
}