import React, {ChangeEvent, useState} from "react";
import {ColumnConfig, GridConfig} from "../../shared/Grid";
import {GridPage} from "../../shared/GridPage";
import {SortState} from "../../../../utils/Grid";
import {EndOfMonthReportValue} from "../EndOfMonthReportValue";
import {DateTime} from "luxon";
import {Link} from "react-router-dom";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Spinner} from "reactstrap";

export const EndOfMonthReportsBrowse = () => {

	// Initial fake data.
	
	const initialEomReportValues: EndOfMonthReportValue[] = [
		{
			id: 5,
			throughDate: '2024-05-31T06:00:00.000',
			runBy: 'Some User',
			runDate: '2024-06-01T14:21:00.000',
			ordersCompleted: 'View',
			transactions: 'View',
			taxes: 'View',
		},
		{
			id: 4,
			throughDate: '2024-04-30T06:00:00.000',
			runBy: 'Some User',
			runDate: '2024-05-01T14:09:05.000',
			ordersCompleted: 'View',
			transactions: 'View',
			taxes: 'View',
		},
		{
			id: 3,
			throughDate: '2024-03-31T06:00:00.000',
			runBy: 'Some User',
			runDate: '2024-04-01T14:40:05.000',
			ordersCompleted: 'View',
			transactions: 'View',
			taxes: 'View',
		},
		{
			id: 2,
			throughDate: '2024-02-28T06:00:00.000',
			runBy: 'Some User',
			runDate: '2024-03-01T14:55:00.000',
			ordersCompleted: 'View',
			transactions: 'View',
			taxes: 'View',
		},
		{
			id: 1,
			throughDate: '2024-01-31T06:00:00.000',
			runBy: 'Some User',
			runDate: '2024-02-01T16:39:00.000',
			ordersCompleted: 'View',
			transactions: 'View',
			taxes: 'View',
		},
	];
	
	// State.
	
	const [eomReportValues , setEomReportValues] = useState(initialEomReportValues);
	const [isDateSelectionModalOpen, setIsDateSelectionModalOpen] = useState(false);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
	const [throughDate, setThroughDate] = useState(getDateToday);
	const [isLoading, setIsLoading] = useState(false);
	const [sort, setSort] =
		useState<SortState<EndOfMonthReportValue>>({ column: 'throughDate', serverColumn: 'throughDate', direction: 'desc' });

	// Event handlers.
	
	const toggleDateSelectionModal = () => setIsDateSelectionModalOpen(old => !old);
	
	const toggleIsSuccessModalOpen = () => setIsSuccessModalOpen(old => !old);

	const toggleIsErrorModalOpen = () => setIsErrorModalOpen(old => !old);
	
	const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
		setThroughDate(e.target.value);
	};
	
	const handleDateSubmit = async () => {
		// Fake loading wait.
		setIsLoading(true);
		await new Promise(r => setTimeout(r, 3000));
		setIsLoading(false);
		
		setIsDateSelectionModalOpen(false);

		// Format date.
		const throughDateTime = throughDate + 'T06:00:00.000';
		const throughDateTimeObj = new Date(throughDateTime);

		// Imitate failure (for Wednesday dates).
		if (throughDateTimeObj.getDay() === 3) {
			setIsErrorModalOpen(true);
			return;
		}
		
		// Add new record.
		const newRecord = {
			id: 0,
			throughDate: throughDateTime,
			runBy: 'Some User',
			runDate: getDateTimeToday(),
			ordersCompleted: 'View',
			transactions: 'View',
			taxes: 'View',
		};
		eomReportValues.unshift(newRecord);
		setEomReportValues(eomReportValues);

		setIsSuccessModalOpen(true);
	};

	// Custom modal close button.
	
	const btnClose = (
		<Button className="btn-close bg-white border-white"
		        disabled={isLoading}
		        onClick={toggleDateSelectionModal}
		        aria-label="Close"/>
	);
	
	// Grid column configuration.
	
	const config: GridConfig<EndOfMonthReportValue> = {
		configSections: [
			{
				columns: [
					new ColumnConfig({
						field: 'id',
						display: 'Id',
						isHidden: true,
					}),
					new ColumnConfig({
						field: 'throughDate',
						display: 'Through Date',
						cellDisplay: val => formatCellDate(val),
					}),
					new ColumnConfig({
						field: 'runBy',
						display: 'Run By',
					}),
					new ColumnConfig({
						field: 'runDate',
						display: 'Run Date',
						cellDisplay: val => formatCellDateTime(val),
					}),
					new ColumnConfig({
						field: 'ordersCompleted',
						display: 'Orders Completed',
						cellDisplay: val => (<Link to={`#`}>{val}</Link>),
					}),
					new ColumnConfig({
						field: 'transactions',
						display: 'Transactions',
						cellDisplay: val => (<Link to={`#`}>{val}</Link>),
					}),
					new ColumnConfig({
						field: 'taxes',
						display: 'Taxes',
						cellDisplay: val => (<Link to={`#`}>{val}</Link>),
					}),
				]
			}
		]
	};
	
	return (
		<>
			<GridPage records={eomReportValues ?? []}
			          gridConfig={config}
			          title={'End-of-Month Reports'}
			          sort={sort}
			          shouldShowCriteriaPanel={false}
			          onSortChanged={setSort}
			          extraActions={[
				          {
					          key: 'close-month',
					          children: 'Close Month',
					          color: 'primary',
					          className: 'mx-1',
					          onClick: () => {setIsDateSelectionModalOpen(true)}
				          }
			          ]}
			/>

			{/* Date selection modal. */}
			<Modal size='l' isOpen={isDateSelectionModalOpen} toggle={toggleDateSelectionModal} backdrop='static' fade={false}>
				<ModalHeader toggle={toggleDateSelectionModal} close={btnClose}>Close Month</ModalHeader>
				<ModalBody>
					<FormGroup>
						<Label for='ThroughDate'>Select a Through Date</Label>
						<Input id='ThroughDate' autoComplete="autoComplete_off" type='date'
						       name='throughDate'
						       max={getDateToday()}
						       value={throughDate}
						       onChange={handleDateChange} disabled={isLoading}/>
					</FormGroup>
					<div className={'d-flex flex-row-reverse'}>
						<Button onClick={() => handleDateSubmit()} disabled={isLoading} color='primary' className='mx-2'>Go!</Button>
						<Button onClick={() => setIsDateSelectionModalOpen(false)} disabled={isLoading} className='mx-2'>Cancel</Button>
						{isLoading && <div><Spinner className='mx-2 mt-1'></Spinner></div>}
					</div>
				</ModalBody>
			</Modal>

			{/* Success modal. */}
			<Modal size='l' isOpen={isSuccessModalOpen} toggle={toggleIsSuccessModalOpen} backdrop='static' fade={false}>
				<ModalHeader toggle={toggleIsSuccessModalOpen}>Close Month</ModalHeader>
				<ModalBody>
					<p>Reports generated!</p>
					<div className={'d-flex flex-row-reverse'}>
						<Button onClick={() => setIsSuccessModalOpen(false)} color='primary' className='mx-2'>Ok</Button>
					</div>
				</ModalBody>
			</Modal>

			{/* Error modal. */}
			<Modal size='l' isOpen={isErrorModalOpen} toggle={toggleIsErrorModalOpen} backdrop='static' fade={false}>
				<ModalHeader toggle={toggleIsErrorModalOpen}>Close Month</ModalHeader>
				<ModalBody>
					<p>Error! Reports were not generated.</p>
					<div className={'d-flex flex-row-reverse'}>
						<Button onClick={() => setIsErrorModalOpen(false)} color='primary' className='mx-2'>Ok</Button>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
};

//TODO: Centralize these.

const formatCellDate = (value: string | number | undefined) => {
	return value
		? DateTime.fromISO(`${value as string}Z`)
			.setZone("local")
			.toLocaleString(DateTime.DATE_SHORT)
		: "Never modified.";
};

const formatCellDateTime = (value: string | number | undefined) => {
	return value
		? DateTime.fromISO(`${value as string}Z`)
			.setZone("local")
			.toLocaleString(DateTime.DATETIME_SHORT)
		: "Never modified.";
};

const getDateToday = () => {
	const theDate = new Date();
	theDate.setDate(theDate.getDate());
	return theDate.toISOString().substring(0,10);
};

const getDateTimeToday = () => {
	const theDate = new Date();
	theDate.setDate(theDate.getDate());
	return theDate.toISOString().substring(0,23);
};
