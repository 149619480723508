import './SubBanner.css';
import {Banner} from "../../banner/Banner";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {DisplayImage} from "../../image/display/DisplayImage";

interface PromotionItemProps {
	banner: Banner
}

export const SubBanner = ({banner}: PromotionItemProps ) => {
	const BannerContent = () => (
		<>
			<div className="header-sub-banner-icon">
				<DisplayImage imageId={banner.imageId}></DisplayImage>
			</div>

			<div className="header-sub-banner-text-group">
				{banner.textField1 && (
					<h1 className="header-sub-banner-text-1">{banner.textField1}</h1>
				)}
				{banner.textField2 && (
					<h4 className="header-sub-banner-text-2">{banner.textField2}</h4>
				)}
			</div>

			{banner.textField1 && banner.link && (
				<div className="header-sub-banner-button">
					<Button color="primary" className="d-inline-flex">
						<h6>{banner.buttonText}</h6>
						<FontAwesomeIcon icon={faArrowRight}/>
					</Button>
				</div>
			)}
		</>
	)

	return banner.link ?
		<Link to={banner.link} className="header-sub-banner">
			<BannerContent/>
		</Link> :
		<div className="header-sub-banner">
			<BannerContent/>
		</div>
}
