import { Button } from "reactstrap"

interface ActionRowProps {
    className?: string
    onSearch?: () => void
    onReset?: () => void
}
export const ActionRow = ({ className, onSearch, onReset}: ActionRowProps) =>
    <div className={`d-flex justify-content-between flex-row-reverse ${className ?? ''}`}>
        <Button color='primary' onClick={onSearch}>Search</Button>
        <Button onClick={onReset}>Reset</Button>
    </div>
