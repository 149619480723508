
interface DownloadFileOptions {
    readonly url: string;
    readonly fileName: string;
    readonly method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    readonly headers?: HeadersInit;
}

export default async function downloadFile({fileName, headers, method = 'GET', url}: DownloadFileOptions) {
    const res = await fetch(url, {
        method,
        headers
    });

    if (!res.ok || res.status === 204) {
        return false
    }

    const blob = await res.blob();
    const objUrl = URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = objUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(objUrl);
    return true;
}
