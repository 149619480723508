import { GridPage } from "../../../shared/GridPage";
import React, { useState, useEffect } from "react";
import { ColumnConfig, GridConfig } from "../../../shared/Grid";
import { SortState } from "../../../../../utils/Grid";
import { Utils } from "../../../../../utils/Utils";
import './OrderPaymentsBrowse.css';
import { DateTime } from "luxon";
import { CurrencyFormatter } from "../../../../../utils/CurrencyFormatter";
import { useGetOrderPaymentHistoryQuery, useGetFailedPaymentsHistoryQuery } from "../../../../../app/apiSlice";
import { OrderPayment } from "../../tabs/details/OrderPayment";
import { FailedPayment } from "../../tabs/details/FailedPayment";
import { ACHRejectionNew } from "../../../../payment/achRejection/ACHRejectionNew";
import { PaymentMethodName } from "../../../../payment/PaymentMethod";
import { PaymentStatus } from "../../../../payment/Payment";

interface OrderPaymentsProps {
    orderId: number
}

export const OrderPaymentsBrowse = ({ orderId }: OrderPaymentsProps) => {
    const { data: queryOrderPayments } = useGetOrderPaymentHistoryQuery(orderId);
    const { data: queryFailedPayments } = useGetFailedPaymentsHistoryQuery(orderId);
    const [sortOrderPayments, setSortOrderPayments] = useState<SortState<OrderPayment>>({ column: 'id', direction: 'asc' });
    const [sortFailedPayments, setSortFailedPayments] = useState<SortState<FailedPayment>>({ column: 'id', direction: 'asc' });
    const [sortedOrderPayments, setSortedOrderPayments] = useState(queryOrderPayments);
    const [sortedFailedPayments, setSortedFailedPayments] = useState(queryFailedPayments);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const containsCompletedACHPayments =
        [...queryOrderPayments?.filter(op => op.paymentMethod === PaymentMethodName.ACH && op.paymentStatus === PaymentStatus.Complete) ?? []].length > 0;

    useEffect(() => {
        const workingOrderPayments = [...queryOrderPayments ?? []];
        setSortedOrderPayments(
            workingOrderPayments.sort(Utils.sortBy(sortOrderPayments.column, sortOrderPayments.direction))
        );
    }, [sortOrderPayments, queryOrderPayments]);

    useEffect(() => {
        const workingFailedPayments = [...queryFailedPayments ?? []];
        setSortedFailedPayments(
            workingFailedPayments.sort(Utils.sortBy(sortFailedPayments.column, sortFailedPayments.direction))
        );
    }, [sortFailedPayments, queryFailedPayments]);

    const orderPaymentsConfig: GridConfig<OrderPayment> = {
        configSections: [
            {
                columns: [
                    new ColumnConfig({
                        field: 'amount',
                        display: 'Amount',
                        cellDisplay: val => typeof val === 'number' ? CurrencyFormatter.format(val) : '',
                    }),
                    new ColumnConfig({
                        field: 'paymentMethod',
                        display: 'Payment Method',
                    }),
                    new ColumnConfig({
                        field: 'paymentStatus',
                        display: 'Transaction Status',
                    }),
                    new ColumnConfig({
                        field: 'paymentType',
                        display: 'Transaction Type',
                    }),
                    new ColumnConfig({
                        field: 'timeOfPaymentBegan',
                        display: 'Time of Transaction',
                        cellDisplay: val => val ? DateTime.fromISO(val as string + 'Z').toLocaleString(DateTime.DATETIME_SHORT) : ''
                    })
                ]
            }
        ]
    };

    const failedPaymentsConfig: GridConfig<FailedPayment> = {
        configSections: [
            {
                columns: [
                    new ColumnConfig({
                        field: 'invoiceId',
                        display: 'Invoice Id'
                    }),
                    new ColumnConfig({
                        field: 'amount',
                        display: 'Amount',
                        cellDisplay: val => typeof val === 'number' ? CurrencyFormatter.format(val) : '',
                    }),
                    new ColumnConfig({
                        field: 'paymentMethod',
                        display: 'Payment Method',
                    }),
                    new ColumnConfig({
                        field: 'errorCode',
                        display: 'Error Code',
                    }),
                    new ColumnConfig({
                        field: 'errorMessage',
                        display: 'Error Message',
                    }),
                    new ColumnConfig({
                        field: 'lastFourDigits',
                        display: 'Last Four Digits',
                    }),

                    new ColumnConfig({
                        field: 'timeBegan',
                        display: 'Time of Transaction',
                        cellDisplay: val => val ? DateTime.fromISO(val as string + 'Z').toLocaleString(DateTime.DATETIME_SHORT) : ''
                    })
                ]
            }
        ]
    };



    return (<>
        <h6>Successful Payments</h6>
        <GridPage records={[...sortedOrderPayments?.map((wop, idx) => { return { ...wop, key: `${wop.id}-${wop.paymentType}` } }) ?? []]}
            gridConfig={orderPaymentsConfig}
            sort={sortOrderPayments}
            shouldShowCriteriaPanel={false}
            newLinkText={containsCompletedACHPayments ? 'New ACH Rejection' : undefined}
            onNewModalButtonClicked={containsCompletedACHPayments ? () => setModalIsOpen(true) : undefined}
            onSortChanged={setSortOrderPayments}
            noRecordsMessage={"No payments exist on record for this order"} />

        <br />

        {queryFailedPayments?.length ?
            <>
                <h6>Failed Payments</h6>
                <GridPage records={[...sortedFailedPayments?.map((wop, idx) => { return { ...wop, key: `${wop.id}` } }) ?? []]}
                          gridConfig={failedPaymentsConfig}
                          sort={sortFailedPayments}
                          shouldShowCriteriaPanel={false}
                          onSortChanged={setSortFailedPayments} />

            </> : <></>}

        {!!orderId && containsCompletedACHPayments && <ACHRejectionNew orderId={orderId} isOpen={modalIsOpen} toggleModal={() => setModalIsOpen(old => !old)} />}
    </>)
}