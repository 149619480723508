import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

export interface GridAction<T> {
  name: string,
  className: string,
  action: (clicked: T) => void,
}

interface ActionButtonProps<T> {
  action: GridAction<T>
  entity: T
}
export function ActionButton<T>({action, entity}: ActionButtonProps<T>) {
  return (
    <Button className={action.className}
            onClick={() => action.action(entity)}>
      {action.name}
    </Button>
  )
}

export interface PagingInfo {
  lastId?: number
  lastValue?: string
  sortKey: string
  sortDirection: string
}

export interface SortState<T> {
  column: keyof T,
  serverColumn?: string,
  direction: 'asc' | 'desc'
}

interface SortButtonProps<T> {
  children: JSX.Element | string,
  column: keyof T,
  serverColumn?: string,
  sortState: SortState<T>,
  sortStateChanged: (newState: SortState<T>) => void,
}
export function SortButton<T>({children, column, serverColumn, sortState, sortStateChanged}: SortButtonProps<T>) {
  const sortLinkClick = () => {
    let direction: 'asc' | 'desc' = 'asc';
    if (column === sortState.column && sortState.direction === 'asc')
      direction = 'desc';
    sortStateChanged({ column, serverColumn, direction });
  }
  
  return (
    <Button color='link' className='shadow-none text-dark' onClick={sortLinkClick}>
      {children}
      {
        sortState.column === column &&
          <FontAwesomeIcon className='ps-2' icon={sortState.direction === 'asc' ? faSortUp : faSortDown}/>
      }
    </Button>
  )
}