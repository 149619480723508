import { Claim } from "../components/admin/Claim";

export const ADMIN = 'Admin';
export const TEAM_LEAD = 'Team Lead';
export const CS3 = 'CS3';
export const VIEW = 'View';
export const FINANCE = 'Finance';

export const EDITING_ROLES = [
  ADMIN,
  TEAM_LEAD,
  CS3,
];

export const hasPermission = (claims: Claim[], requiredRoles: string[]) =>
  requiredRoles.some(r => claims?.some(c => c.value === r));


export const hasAdmin = (claims?: Claim[]) =>
  hasPermission(claims ?? [], [ADMIN]);