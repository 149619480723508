import {ConfigurationDetail} from "./ConfigurationDetail";
import {InputType} from "../InputType";
import {FieldModifier} from "./FieldModifier";
import {ConfigurationRule} from "./rule/ConfigurationRule";

export interface Configuration {
  id: number,
  configurationKey: string,
  label: string,
  header?: string,
  sortOrder: number,
  showInReview: boolean,
  regexValue?: string,
  helperText?: string,
  autoCompleteValue?: string,
  isSecure: boolean
  configurationDetail: ConfigurationDetail,
  inputType: InputType,
  fieldModifiers: FieldModifier[],
  rules: ConfigurationRule[],
  shouldValidateValue: boolean
}

export interface ConfigurationFetchOptions {
  siteProductId: number,
  siteProductVariantId?: number,
  onlyIncludeInitial?: boolean,
  quantityOptionId?: number
}

export interface ConfigurationValue {
  configurationId: number,
  stringValue?: string,
  configOptionId?: number,
  siteProductVariantQuantityOptionId?: number,
}

export interface SelectedConfigurationsRequest {
  siteProductVariantQuantityOptionId: number,
  configurationValues: ConfigurationValue[]
}

export enum BankConfigurationKeys {
  RoutingNumber = "RoutingNumber",
  DepositRoutingNumber = "DepositRoutingNumber",
  AccountNumber = "BankAccountNumber",
  BankName = "BankName",
  BankAddress = "BankAddress",
}

export enum ConfigurationKeys {
  CheckStartNumber = "CheckStartNumber",
}

export enum CalculatedConfigurationLabels {
  CheckEndNumber = "Check End Number"
}

export interface BankConfiguration {
  accountNumber?: string,
  routingNumber?: string,
  depositRoutingNumber?: string,
  configurationId: number,
  stringValue?: string,
  productVariantId: number
}

export interface BankConfigurationValidationResponse {
  isBlocked: boolean,
  showWarning: boolean,
  warningMessage?: string,
  bankName?: string,
  printedBankAddress?: string
}