import { SliceState } from "../../../utils/Utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAll, CriteriaAndPaging } from "./ControlListValueAPI";
import { RootState } from "../../../app/store";
import {ControlListValue} from "./ControlListValue";

export interface ControlListValueState {
    controlListValues?: ControlListValue[];
    status: SliceState;
    canGetMore: boolean;
}

const initialState: ControlListValueState = {
    controlListValues: undefined,
    status: 'idle',
    canGetMore: true,
};

export const fetchControlListValues = createAsyncThunk(
    'controlListValues/fetchAll',
    async (query: CriteriaAndPaging) =>
        (await fetchAll(query)).data
);

export const fetchMoreControlListValues = createAsyncThunk(
    'controlListValues/fetchMore',
    async (query: CriteriaAndPaging) =>
        (await fetchAll(query)).data
);

export const refetchAllControlListValues = createAsyncThunk(
    'controlListValues/refetch',
    async (query: CriteriaAndPaging) =>
        (await fetchAll(query)).data
);

export const controlListValueSlice = createSlice({
    name: 'controlListValue',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(fetchControlListValues.pending, state => {
                state.status = 'loading'
            })
            .addCase(fetchControlListValues.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.controlListValues = action.payload;
                state.canGetMore = action.payload.length === 50;
            })
            .addCase(fetchControlListValues.rejected, state => {
                state.status = 'failed';
                state.canGetMore = false;
            })

            // fetch more
            .addCase(fetchMoreControlListValues.pending, state => {
                state.status = 'loading'
            })
            .addCase(fetchMoreControlListValues.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (action.payload.length) state.controlListValues = state.controlListValues?.concat(action.payload);
                state.canGetMore = action.payload.length === 50;
            })
            .addCase(fetchMoreControlListValues.rejected, state => {
                state.status = 'failed';
                state.canGetMore = false;
            })

            // refetch
            .addCase(refetchAllControlListValues.pending, state => {
                state.status = 'loading'
                state.controlListValues = [];
            })
            .addCase(refetchAllControlListValues.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.controlListValues = action.payload;
                state.canGetMore = action.payload.length === 50;
            })
            .addCase(refetchAllControlListValues.rejected, state => {
                state.status = 'failed';
                state.canGetMore = false;
            });
    }
});

// Fetch hold flags
export const selectControlListValues = (state: RootState) => state.controlListValues.controlListValues;
export const canFetchMoreControlListValues = (state: RootState) => state.controlListValues.canGetMore;
export const isFetchingControlListValues = (state: RootState) => state.controlListValues.status === 'loading' ||
    state.controlListValues.status === 'idle';

const { reducer } = controlListValueSlice;
export default reducer;