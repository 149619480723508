import './OrderDetails.css'

import {Link, useNavigate} from "react-router-dom";
import {ProfileTab} from "../../user/profile/ProfileTab";
import React, {useEffect, useState} from "react";
import {OrderVm} from "../Order";
import {formatDate, Utils} from "../../../utils/Utils";
import {Button, Card, CardBody, Col, Row, Spinner} from "reactstrap";
import {DisplayImage} from "../../image/display/DisplayImage";
import {OrderProductVm} from "../OrderProductVm";
import {useCreateOrderEditCartMutation, useGetOrderPricingQuery, useGetOrderShipmentsByOrderQuery} from "../../../app/apiSlice";
import {OrderShipmentVm} from "../OrderShipment.model";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBox} from "@fortawesome/free-solid-svg-icons";
import {skipToken} from "@reduxjs/toolkit/query";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {setCartId} from "../../cart/CartSlice";
import {useAppDispatch} from "../../../app/hooks";
import {showInformationModal} from "../../modal/ModalSlice";

export interface OrderDetailsProps {
    selectedOrder: OrderVm
}
export const OrderDetails = ({selectedOrder}: OrderDetailsProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [ createOrderCart, {isLoading: isCreatingOrderCart}  ] = useCreateOrderEditCartMutation();
    const { data: shipments } = useGetOrderShipmentsByOrderQuery(selectedOrder.id);
    const { data: pricing, isFetching: isFetchingPricing } = useGetOrderPricingQuery(selectedOrder.id ?? skipToken);
    const getProducts = () => selectedOrder.products ?? [];
    const [focusedOrderProduct, setFocusedOrderProduct] = useState<number | undefined>();
    
    const [productOrderMap, setProductOrderMap] = useState<Record<number, OrderShipmentVm[]>>();

    useEffect(() => {
        if (!Array.isArray(shipments)) 
            return;
        
        const map = Utils.collectToArray(shipments, shipment => [shipment.orderProductId, shipment]);
        setProductOrderMap(map);
        
    }, [shipments]);
    
    const getTrackingButton = (product: OrderProductVm) =>
        (productOrderMap?.[product.id] ?? [])
            .filter(shipment => shipment.shippingCarrier.trackingUrl && shipment.trackingNumber)
            .map((shipment, currentIndex, src) =>
                <div key={shipment.id}>
                    <hr/>
                    { src.length > 1 
                        ? <div>
                            Shipment {currentIndex + 1}
                          </div>
                        : <div></div>
                    }
                    <Link className="btn btn-primary d-inline-block" target="_blank"
                          to={`${shipment.shippingCarrier.trackingUrl}${shipment.trackingNumber}`}>
                        <span>
                            <FontAwesomeIcon icon={ faBox }></FontAwesomeIcon>
                        </span>
                        <span className="package-track-span">Track Package</span>
                    </Link>
                </div>
            );
    
    const getProductNameAndDescription = (product: OrderProductVm) =>
        <>
            <h5>
                { product.siteProductVariantName }
            </h5>
            <div className="text-muted">
                { product.quantityDescription }
            </div>
        </>

    const getSelectedOrderCardDetails = () =>
        getProducts().map(product =>
            <React.Fragment key={`selected-product-details-${product.id}`}>
                <hr/>
                { getProductNameAndDescription(product) }
            </React.Fragment>
        );

    const getSelectedOrderTaxLineItems = () =>
        pricing?.taxLineItems.map(taxLineItem =>
            <>
                <div className="d-flex justify-content-between">
                <span className="text-muted">
                    {taxLineItem.description}
                </span>
                    <h5 className="d-inline">
                        {CurrencyFormatter.format(taxLineItem.amount ?? 0)}
                    </h5>
                </div>
            </>
        );

    const getSelectedOrderCardTotals = () =>
        <>
            <hr/>
            <div className="d-flex justify-content-between">
                <span className="text-muted">
                    Products Total
                </span>
                <h5 className="d-inline">
                    {CurrencyFormatter.format(pricing?.productTotal ?? 0)}
                </h5>
            </div>
            <div className="d-flex justify-content-between">
                <span className="text-muted">
                    Shipping
                </span>
                <h5 className="d-inline">
                    {CurrencyFormatter.format(pricing?.shippingTotal ?? 0)}
                </h5>
            </div>
            <div className="d-flex justify-content-between">
                <span className="text-muted">
                    Handling
                </span>
                <h5 className="d-inline">
                    {CurrencyFormatter.format(pricing?.handlingTotal ?? 0)}
                </h5>
            </div>
            <div className="d-flex justify-content-between">
                <span className="text-muted">
                    Tax
                </span>
                <h5 className="d-inline">
                    {CurrencyFormatter.format(pricing?.retailTaxAmountWithoutLineItems ?? 0)}
                </h5>
            </div>
            {getSelectedOrderTaxLineItems()}
            <hr/>
            <div className="d-flex justify-content-between">
                            <span className="text-muted">
                                Order Total
                            </span>
                <h5 className="d-inline">
                    {CurrencyFormatter.format(pricing?.total ?? 0)}
                </h5>
            </div>
        </>
    ;

    const reorderProduct = async (orderProductId: number) => {
        try {
            setFocusedOrderProduct(orderProductId);
            const orderId = selectedOrder.id;
            const cartId = await createOrderCart({orderId, orderProductId, isReorder: true}).unwrap();
            dispatch(setCartId(cartId));
            navigate('/cart');
        } catch (e:any) {
            dispatch(showInformationModal({
                title: 'Could not start reorder',
                text: 'Sorry! We were unable to start this reorder at this time.'
            }));
        }
        setFocusedOrderProduct(undefined);
    }
    
    const getSelectedOrderCardItems = () =>
       getProducts().map(product =>
            <Card className="mb-md-4 mb-sm-2" key={product.id}>
                <CardBody>
                    <Row className="mt-2">
                        <Col xs={ 4 }>
                            <div className="image-container">
                                <DisplayImage imageId={ product.thumbnailId ?? product.imageId }></DisplayImage>
                            </div>
                        </Col>
                        <Col xs={ 7 } className="d-flex flex-column justify-content-center offset-1">
                            { getProductNameAndDescription(product) }
                        </Col>
                    </Row>
                    <Button color="primary"
                            disabled={product.doNotReorder || isCreatingOrderCart}
                            onClick={() => reorderProduct(product.id)}>
                        {isCreatingOrderCart && focusedOrderProduct === product.id && <Spinner/>}
                        Reorder
                    </Button>
                    <div>
                        { getTrackingButton(product) }
                    </div>
                </CardBody>
            </Card>
        );

    if (isFetchingPricing || !pricing) {
        return (
            <Spinner size="sm">
                Loading...
            </Spinner>
        );
    }
    
    return (
        <Row>
            <Col sm={12} md={7}>
                <Link to={`/profile/${ProfileTab.OrderHistory}`}>{`Orders / Order # ${selectedOrder.id}`}</Link>
                <div className="order-details-overview-container">
                    <h5>Ordered on {formatDate(selectedOrder.timeCreated)}</h5>

                    <h5>Shipping to: </h5>
                    <div>{ selectedOrder.address.street }</div>
                    <div>{ selectedOrder.address.city }, { selectedOrder.address.stateCode } { selectedOrder.address.zip }</div>
                </div>
                { getSelectedOrderCardItems() }
            </Col>
            <Col sm={12} md={5}>
                <Card>
                    <CardBody>
                        <h3>Your Order</h3>
                        { getSelectedOrderCardDetails() }
                        { getSelectedOrderCardTotals() }
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}