import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { SiteNavigation } from './SiteNavigation';
import { fetchAll } from './NavigationAPI';

export interface NavigationState {
  siteNavigation?: SiteNavigation[];
  status: 'idle' | 'loading' | 'failed' | 'succeeded';
}

const initialState: NavigationState = {
  siteNavigation: undefined,
  status: 'idle',
};

export const fetchSiteNavigation = createAsyncThunk(
  'siteNavigation/fetchAll',
  async () => {
    const response = await fetchAll();
    const siteNavigationWithChildrenPlaced = placeSiteNavigationChildren(response.data);
    return siteNavigationWithChildrenPlaced;
  }
);

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchSiteNavigation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSiteNavigation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.siteNavigation = action.payload;
      })
      .addCase(fetchSiteNavigation.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

function placeSiteNavigationChildren(siteNavigationElements: SiteNavigation[]) {
  let returnSiteNavigationElements: SiteNavigation[] = siteNavigationElements.filter((sne => !sne.parentSiteNavigationId));
  returnSiteNavigationElements.forEach((rsne) => {
    rsne.children = siteNavigationElements.filter((sne) => sne.parentSiteNavigationId === rsne.id);
  });

  return returnSiteNavigationElements;
}

// Fetch navigation items
export const selectSiteNavigation = (state: RootState) => state.navigation.siteNavigation;

export default navigationSlice.reducer;
