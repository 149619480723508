
import './RequiredAsterisk.css';

export const RequiredAsterisk = () => {

    return (
        <span className="required-asterisk">
            *
        </span>
    )
}
