import { reportCol, reportDateToString, reportValToOrderLink, ReportView } from "../ReportView";
import { CustomerEmailDownload } from "./CustomerEmailDownload";
import { ColumnConfig } from "../../admin/shared/Grid";
import { useGetCustomerEmailDownloadReportQuery } from "../../../app/apiSlice";

export const CustomerEmailDownloadReport = () => {
  const filterFunc = (lowerSearch: string, records: CustomerEmailDownload[]) =>
    records.filter(r =>
      r.orderNumber.toString().includes(lowerSearch) ||
      r.brand.toLowerCase().includes(lowerSearch) ||
      r.orderStatus.toLowerCase().includes(lowerSearch) ||
      `${r.firstNameShipping} ${r.lastNameShipping}`.toLowerCase().includes(lowerSearch) ||
      r.emailAddress.toLowerCase().includes(lowerSearch) ||
      r.productCode.toLowerCase().includes(lowerSearch) ||
      r.productDescription.toLowerCase().includes(lowerSearch) ||
      r.productCategory.toLowerCase().includes(lowerSearch) ||
      r.shippingMethod.toLowerCase().includes(lowerSearch) ||
      r.paymentMethod.toLowerCase().includes(lowerSearch) ||
      r.paymentStatus.toLowerCase().includes(lowerSearch)
    );
  
  const columns: ColumnConfig<CustomerEmailDownload>[] = [
    reportCol('orderNumber', 'Order #', reportValToOrderLink),
    reportCol('brand', 'Brand'),
    reportCol('orderStatus', 'Order Status'),
    reportCol('orderDate', 'Order Date', reportDateToString),
    reportCol('firstNameShipping', 'Ship F Name'),
    reportCol('lastNameShipping', 'Ship L Name'),
    reportCol('emailAddress', 'Email'),
    reportCol('productCode', 'Product Code'),
    reportCol('productDescription', 'Product Desc.'),
    reportCol('productCategory', 'Product Cat.'),
    reportCol('shippingMethod', 'Ship Meth.'),
    reportCol('paymentMethod', 'Payment Meth.'),
    reportCol('paymentStatus', 'Payment Status'),
    reportCol('paymentAmount', 'Payment Amt.'),
    reportCol('reprint', 'Reprint', val => val === 0 ? 'No' : 'Yes')
  ];
  
  return <ReportView
    title='Customer Email Download'
    query={useGetCustomerEmailDownloadReportQuery}
    columns={columns}
    initialSort={{column: 'orderNumber', direction: 'asc'}}
    filterFunc={filterFunc}
    downloadPath='/api/Reports/CustomerEmailDownload'
    downloadName='CW_Customer_Email Download'/>
}