import './OrderProductPreview.css';
import {useTranslation} from "react-i18next";
import React from "react";
import {OrderProductVm} from "../OrderProductVm";
import {DisplayImage} from "../../image/display/DisplayImage";

interface OrderProductPreviewProps {
    orderProduct: OrderProductVm
}

export const OrderProductPreview = ({orderProduct}: OrderProductPreviewProps) => {

    return (
        <div className="order-product-preview-container">

            <span className="order-product-preview-image-container">
                <DisplayImage imageId={orderProduct.thumbnailId ?? orderProduct.imageId}></DisplayImage>
            </span>
            <span>
                <h4>{orderProduct.siteProductVariantName}</h4>
                <h5>{orderProduct.quantityDescription}</h5>
            </span>
        </div>
    );

}