import './HomeHeader.css';
import {useGetBannersByTypeQuery} from '../../../app/apiSlice';
import ScrollToTop from '../../helpers/ScrollToTop';
import {LoadingSpinner} from "../../input/utils/LoadingSpinner";
import {DisplayImage} from "../../image/display/DisplayImage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Button} from "reactstrap";
import {SubBanner} from "../item/SubBanner";

export const HomeHeader = () => {
	const PROMOTION_TYPE_ID = 2;
	const {data, isLoading} = useGetBannersByTypeQuery(PROMOTION_TYPE_ID);

	if (isLoading) {
		return (<LoadingSpinner size="sm" />);
	}
	
	const getHeaderBanner = () => {
		if (data && data[0]) {
			switch(data[0].siteId) {
				// Checkworks.
				case 1: {
					return (
						<div className="header-banner-main">
							<DisplayImage imageName="home-header-short"></DisplayImage>

							<div className="header-banner-overlay">
								<h4 className="header-banner-text-1 mb-3">Find a Check Style That Fits You</h4>

								<h1 className="header-banner-text-2 mb-3">
									Affordable, Designer-Quality Checks for Less
								</h1>

								<h4 className="header-banner-text-3 mb-4">
									Offering versatility, practicality, and ultimate
									security, order checks that exceed your requirements.
								</h4>

								<div className="header-banner-button-row">
									<a href="/category/personal-checks">
										<Button color="primary" className="btn-primary header-banner-button-1">
											<h6>Shop Personal Checks</h6>
											<FontAwesomeIcon icon={faArrowRight}/>
										</Button>
									</a>
									<a href="/category/business-checks">
										<Button color="primary" className="header-banner-button-2">
											<h6>Shop Business Checks</h6>
											<FontAwesomeIcon icon={faArrowRight}/>
										</Button>
									</a>
								</div>
							</div>
						</div>
					)
				}
				// ChecksForFree.
				case 2: {
					return (
						<div className="header-banner-main">
							<DisplayImage imageName="Quality Checks for Free from ChecksForFree.com"></DisplayImage>

							<div className="header-banner-overlay">
								<h1 className="header-banner-text-2 mb-3">
									Affordable, Designer-Quality Checks for Less
								</h1>

								<h4 className="header-banner-text-3 mb-4">
									Offering versatility, practicality, and ultimate
									security, order checks that exceed your requirements.
								</h4>
								
								<div className="header-banner-button-row">
									<a href="/category/personal-checks">
										<Button color="primary" className="btn-primary header-banner-button-1">
											<h6>Shop Personal Checks</h6>
											<FontAwesomeIcon icon={faArrowRight}/>
										</Button>
									</a>
								</div>
							</div>
						</div>
					)
				}
			}
		}
	};

	const getHeaderSubBanners = () => {
		if (data) {
			return (
				<div className="header-sub-banner-container">
					{data && (
						data.map((banner, index) => {
							return <SubBanner banner={banner} key={index}/>
						})
					)}
				</div>
			)
		}
	};

	return (
		<div>
			{getHeaderBanner()}
			{getHeaderSubBanners()}
			<ScrollToTop/>
		</div>
	);
}
