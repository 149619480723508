import {Configuration} from "../components/input/configuration/Configuration";

/**
 * Checks the configuration for the `autoCompleteValue` or uses the value supplied.
 * If the result or boolean supplied by autoCompleteDisabled is `true`, the `"off"` autocomplete attribute value will be returned.
 * @param configuration A configuration option to check or a string to supply for the autocomplete attribute.
 * @param autoCompleteDisabled A function or boolean to determine whether `"off"` should be returned instead of the configuration value.
 * 
 * @returns Determined autoComplete value or undefined if none is specified.
 */
export function autoCompleteResolver(configuration?: Configuration | string, autoCompleteDisabled?: boolean | (() => boolean)): string | undefined {
    if ((typeof autoCompleteDisabled === 'function' && autoCompleteDisabled()) || (typeof autoCompleteDisabled === 'boolean' && autoCompleteDisabled)) {
        return 'off';
    }
    
    if (typeof configuration === 'string') {
        return configuration;
    }
    
    return configuration?.autoCompleteValue ?? undefined;
}