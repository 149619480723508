import './OrderProductUnitDetail.css';
import {OrderProductVm} from "../../../../../../order/OrderProductVm";
import {UnitDetail} from "./UnitDetail";
import {Button, Table} from "reactstrap";
import {useCancelOrderProductReprintMutation, useGetOrderOverviewQuery, useReprintOrderProductMutation} from "../../../../../../../app/apiSlice";
import {OrderProductReprint} from "./OrderProductReprint";
import {VendorOrderProductStatus} from "../../../../../vendor/VendorOrderProductStatus";
import {OrderStatus} from "../../../../../../order/Order";

interface OrderProductUnitDetailProps {
    orderProduct: OrderProductVm,
    vendorProductStatus?: VendorOrderProductStatus
}

export const OrderProductUnitDetail = ({orderProduct, vendorProductStatus}: OrderProductUnitDetailProps) => {

    const { data: order } = useGetOrderOverviewQuery(orderProduct.orderId);
    const [ reprintOrderProduct ] = useReprintOrderProductMutation();
    const [ cancelOrderProductReprint ] = useCancelOrderProductReprintMutation();

    const onReprintClick = async (index: number) => {
        await reprintOrderProduct({orderProductId: orderProduct.id, quantityIndex: index, vendorOrderProductUnitId: getVendorUnitId(index)}).unwrap();
    }

    const onReprintCancelClick = async (orderProductReprintId: number) => {
        await cancelOrderProductReprint(orderProductReprintId).unwrap();
    }

    const shouldReprintBeDisabled = (unitIndex: number) => {
        // Disable if the status is (something other than complete or manufacturing) or (we don't have the unit id) or (there's atleast one reprint for this unit already)
        const hasIncompatibleOrderStatus = (order?.status !== OrderStatus.Complete);
        const hasRequestedReprintAlready = !!orderProduct.orderProductReprints.find(opr => opr.quantityIndex === unitIndex);
        return (hasIncompatibleOrderStatus || hasRequestedReprintAlready);
    }

    const getVendorUnitId = (index: number) => {
        if (!vendorProductStatus?.units || vendorProductStatus.units.length < (index + 1)) return undefined;
        
        return vendorProductStatus.units[index].vendorOrderProductUnitId;
    }

    const UnitDetailRow = (unitDetail: UnitDetail, index: number) => {
        return (
            <tr key={`unit-detail-index-${unitDetail.itemQuantity}`}>
                <td>{index + 1}</td>
                <td>{unitDetail.itemQuantity}</td>
                <td>{getVendorUnitId(index) ?? 'N/A'}</td>
                <td className="order-product-unit-detail-right-align-row">
                    <Button onClick={() => onReprintClick(index)}
                            disabled={shouldReprintBeDisabled(index)}>Reprint</Button>
                </td>
            </tr>
        )
    };

    const ReprintRow = (orderProductReprint: OrderProductReprint, index: number) => {
        return (
            <tr key={`reprint-index-${orderProductReprint.id}`}>
                <td>{orderProductReprint.quantityIndex + 1}</td>
                <td>{orderProductReprint.isAcknowledged ? 'Yes' : 'No'}</td>
                <td className="order-product-unit-detail-right-align-row">
                    {!orderProductReprint.isAcknowledged && <Button onClick={() => onReprintCancelClick(orderProductReprint.id)}>Cancel</Button>}
                </td>
            </tr>
        )
    }

    return (
        <>
            <Table>
                <thead>
                <tr>
                    <th>Unit #</th>
                    <th>Item Count</th>
                    <th>Vendor Unit Id</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                { orderProduct.unitDetails?.map(UnitDetailRow) }
                </tbody>
            </Table>


            <h4>Reprints</h4>
            {orderProduct.orderProductReprints.length === 0 &&
                <span>There are no reprints requested.</span>
            }
            {orderProduct.orderProductReprints.length > 0 &&
                <Table>
                    <thead>
                    <tr>
                        <th>Reprint Unit #</th>
                        <th>Is Acknowledged</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    { orderProduct.orderProductReprints?.map(ReprintRow) }
                    </tbody>
                </Table>
            }
        </>
    );
}