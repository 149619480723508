
import './HoldEdit.css';
import {
    Alert,
    Button,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import React, {useState} from "react";
import {HoldFlag, HoldFlagUpdateInfo} from "../HoldsFlag";
import {useGetHoldFlagStatusesQuery, useUpdateHoldFlagMutation} from "../../../../app/apiSlice";
import { DateTime } from "luxon"
import {getErrorMessage} from "../../../../utils/Utils";
import {NoteBrowse} from "../../note/browse/NoteBrowse";

interface HoldEditProps {
    requestedHoldFlag: HoldFlag,
    isOpen: boolean,
    toggleModal: (shouldRefresh?: boolean) => void,
}
export const HoldEdit = ({requestedHoldFlag, isOpen, toggleModal}: HoldEditProps) => {

    const { data: holdsFlagStatuses } = useGetHoldFlagStatusesQuery();
    const [ updateHoldFlag, {isLoading: isUpdating} ] = useUpdateHoldFlagMutation();
    const [ statusId, setStatusId ] = useState(requestedHoldFlag.holdFlagStatusId);
    const [ note, setNote ] = useState<string | undefined>();
    const [ errorMessage, setErrorMessage ] = useState<string | undefined>(undefined);

    const modalNavigation = (
        <div>
            <button className='btn-close' onClick={() => toggleModal()}/>
        </div>
    );

    const onStatusChanged = (statusValue: string) => {
        const newStatusId = parseInt(statusValue);
        setStatusId(newStatusId);
    }

    const onNoteChanged = (noteValue: string) => {
        setNote(noteValue);
    }

    const onUpdateClicked = async () => {
        const holdFlagDialogInfo = ({holdFlagId: requestedHoldFlag.id, statusId, note} as HoldFlagUpdateInfo);
        if (!holdFlagDialogInfo)
            return;
        try {
            await updateHoldFlag(holdFlagDialogInfo).unwrap();
            toggleModal(true);
        }
        catch (e: any) {
            setErrorMessage(getErrorMessage(e));
        }
    }

    const shouldDisableUpdate = () => {
        return (requestedHoldFlag.holdFlagStatusId === statusId && !note) || isUpdating;
    }

    const getHoldFlagDetailsTable = () => {
        return (
            <>
                <table className='table table-bordered'>
                    <thead>
                    <tr>
                        <th>Order Id</th>
                        <th>Reason</th>
                        <th>Time of Hold</th>
                        <th>Consumer Email</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td >{requestedHoldFlag.orderId}</td>
                        <td >{requestedHoldFlag.holdFlagReason.name}</td>
                        <td >{DateTime.fromISO(requestedHoldFlag.timeOfFlagLocal).toLocaleString(DateTime.DATETIME_SHORT)}</td>
                        <td >{requestedHoldFlag.consumer.email}</td>
                    </tr>
                    </tbody>
                </table>

                <FormGroup>
                    <Label for='Status'>Status</Label>
                    <Input id='Status' name='status'  type="select" onChange={(e) => onStatusChanged(e.target.value)} value={statusId}>
                        {holdsFlagStatuses?.map((hfs) => {
                            return (
                                <option key={hfs.id} value={hfs.id}>
                                    {hfs.name}
                                </option>
                            );
                        })}
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label for='Notes'>New Note</Label>
                    <Input id='Notes' name='notes'  type="textarea" rows="4" onChange={(e) => onNoteChanged(e.target.value)} value={note} />
                </FormGroup>

                {requestedHoldFlag.notes ? <NoteBrowse orderNotes={requestedHoldFlag.notes}></NoteBrowse> : <></> }
            </>
        );
    }

    return (
        <Modal isOpen={isOpen} toggle={() => toggleModal()} size='xl'>
            <ModalHeader toggle={() => toggleModal()} close={modalNavigation}>Update Hold Flag</ModalHeader>
            <ModalBody>
                {getHoldFlagDetailsTable()}
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                <Row>
                    <div className='hold-edit-button-container'>
                        <Button color='primary' onClick={onUpdateClicked} disabled={shouldDisableUpdate()}>
                            {isUpdating && <Spinner></Spinner>}
                            Update Hold Flag
                        </Button>
                        <Button onClick={() => toggleModal()}>Cancel</Button>
                    </div>
                </Row>
            </ModalBody>
        </Modal>
    )
}