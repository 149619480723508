import {OrderCreationResponse, OrderVm} from "./Order";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CriteriaAndPaging, fetchAll} from "./OrderAPI";
import {RootState} from "../../app/store";
import {SliceState} from '../../utils/Utils';
import {OrderSearchCriteria} from "../admin/order/OrderSearchCriteria";

export interface OrderState {
    orders?: OrderVm[];
    status: SliceState;
    canGetMore: boolean;
    criteria: OrderSearchCriteria;
    latestConsumerOrderResponse: OrderCreationResponse | null;
}

const initialState: OrderState = {
    orders: undefined,
    status: 'idle',
    canGetMore: true,
    criteria: {},
    latestConsumerOrderResponse: null,
};

export const fetchOrders = createAsyncThunk(
    'orders/fetchAll',
    async (query: CriteriaAndPaging) =>
        (await fetchAll(query)).data
)

export const fetchMoreOrders = createAsyncThunk(
    'orders/fetchMore',
    async (query: CriteriaAndPaging) =>
        (await fetchAll(query)).data
)

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        changeSearchCriteria: state => {
            state.status = 'idle'
        },
        setOrderSearchCriteria: (state, {payload}: PayloadAction<OrderSearchCriteria>) => {
            state.criteria = payload
        },
        setLatestOrderCreationResponse: (state, {payload: orderCreationResponse}: PayloadAction<OrderCreationResponse>) => {
            state.latestConsumerOrderResponse = orderCreationResponse;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchOrders.pending, state => {
                state.status = 'loading'
                state.orders = [];
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.orders = action.payload;
                state.canGetMore = action.payload.length === 50;
            })
            .addCase(fetchOrders.rejected, state => {
                state.status = 'failed'
                state.canGetMore = false;
            })


            .addCase(fetchMoreOrders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (action.payload.length) state.orders = state.orders?.concat(action.payload);
                state.canGetMore = action.payload.length === 50;
            });
    }
});

// Fetch orders
export const selectOrders = (state: RootState) => state.orders.orders;
export const canFetchMoreOrders = (state: RootState) => state.orders.canGetMore;
export const isFetchingOrders = (state: RootState) => state.orders.status === 'loading' ||
    state.orders.status === 'idle';
export const selectOrderSearchCriteria = (state: RootState) => state.orders.criteria;

export const selectLatestConsumerOrderId = (state: RootState) => state.orders.latestConsumerOrderResponse;

const {actions, reducer} = orderSlice;

export const {
    changeSearchCriteria,
    setOrderSearchCriteria,
    setLatestOrderCreationResponse
} = actions;

export default reducer;