import './Contact.css';
import {useTranslation} from "react-i18next";
import { Button } from 'reactstrap';
import {DisplayImage} from "../../image/display/DisplayImage";
import {MediaQueryTypes, useMediaQuery} from '../../../app/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

export const Contact = () => {
    const { t } = useTranslation();
    const phoneNumber = 'tel:' + t(`phoneNumber.number`);
    const isMobile = useMediaQuery(MediaQueryTypes.IS_MOBILE);

    return (
        <div className='contact-container d-flex flex-column'>
            <span className={isMobile ? 'title-logo mobile' : 'title-logo'}>
                <DisplayImage imageName={isMobile ? t('mobileLogoImageName') : t('logoImageName')} showLoading={false}></DisplayImage>
            </span>
            
            <div className='contact-supporting-text d-flex flex-column'>
                <span className='supporting-text'>
                    <p>{t('footer.contact.needHelpText')} {t(`footer.contact.contactText`)} </p>
                </span>
                
                <a id="phone-number-text" href={phoneNumber}>
                    <Button className='contact-button d-flex flex-row' >
                        <FontAwesomeIcon icon={faPhone} />
                        <h6>{t(`phoneNumber.display`)}</h6>
                    </Button>
                </a>
            </div>
        </div>
    );
};