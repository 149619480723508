import {Button, Card, CardBody, CardHeader, FormFeedback, Input, Label} from "reactstrap";
import "./PaymentSelection.css";
import {useGetCartOrderPaymentMethodsQuery} from "../../../app/apiSlice";
import {useEffect, useState} from "react";
import {PaymentDetailsEntry} from "../detailsEntry/PaymentDetailsEntry";
import {PaymentMethodOption} from "../PaymentMethod";
import {PaymentDetails} from "../PaymentDetails";
import {Address} from "../../input/address/Address";
import {SavedPaymentSelection} from "../savedPaymentSelection/SavedPaymentSelection";
import {SavedPaymentDetails} from "../SavedPaymentDetails";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {useAppSelector, ValidationHook} from "../../../app/hooks";
import {selectCurrentConsumerUser} from "../../user/login/AuthenticationSlice";
import {CartIdType} from "../../cart/CartSlice";
import {useTranslation} from "react-i18next";

interface PaymentSelectionProps {
    selectedPaymentProfileId?: string,
    enabledSavedPaymentMethods: boolean,
    savedPaymentDetails?: SavedPaymentDetails[],
    billingAddress?: Address,
    onPaymentDetailsChanged: (paymentDetails?: PaymentDetails) => void,
    onSavePaymentDetailsClicked: () => void,
    onRemoveSavedPaymentMethodClicked: (paymentProfileId: string) => void,
    onPaymentOptionMethodChange?: (option: PaymentMethodOption) => void,
    useCardContainer: boolean,
    cartId?: CartIdType,
    orderId?: number,
    paymentInfo?: PaymentDetails,
    validation?: ValidationHook,
    isSavingPaymentDetails: boolean,
    isDeletingSavedPayment: boolean
}

export const PaymentSelection = ({selectedPaymentProfileId, enabledSavedPaymentMethods, savedPaymentDetails, billingAddress,
                                     onPaymentDetailsChanged, onSavePaymentDetailsClicked, onRemoveSavedPaymentMethodClicked,
                                     onPaymentOptionMethodChange, useCardContainer, cartId, orderId, paymentInfo, validation,
                                     isSavingPaymentDetails, isDeletingSavedPayment}: PaymentSelectionProps) => {
    const consumer = useAppSelector(selectCurrentConsumerUser);
    const {data: paymentMethods} = useGetCartOrderPaymentMethodsQuery(!cartId && !orderId ? skipToken : {cartId, orderId});
    const [ selectedPaymentMethodId, setSelectedPaymentMethodId ] = useState<number | undefined>(undefined);
    const [ selectedPaymentMethodOption, setSelectedPaymentMethodOption ] = useState<PaymentMethodOption>(PaymentMethodOption.NewPaymentMethod);
    const { t } = useTranslation();


    useEffect(() => {
        onPaymentOptionMethodChange?.(selectedPaymentMethodOption);
        if (selectedPaymentMethodOption === PaymentMethodOption.NewPaymentMethod) {
            onPaymentDetailsChanged(undefined);
        }
    }, [selectedPaymentMethodOption]);

    useEffect(() => {
        if (!enabledSavedPaymentMethods && selectedPaymentMethodOption === PaymentMethodOption.SavedPaymentMethod) {
            setSelectedPaymentMethodOption(PaymentMethodOption.NewPaymentMethod);
            onPaymentDetailsChanged(undefined);
        }
        else if (enabledSavedPaymentMethods && selectedPaymentProfileId) {
            const matchingSavedPaymentDetails =
                savedPaymentDetails?.find(spd => spd.paymentProfileId === selectedPaymentProfileId);
            onPaymentDetailsChanged(matchingSavedPaymentDetails);
            setSelectedPaymentMethodOption(PaymentMethodOption.SavedPaymentMethod);
        }
    }, [enabledSavedPaymentMethods, selectedPaymentProfileId]);

    useEffect(() => {
        if (paymentMethods && !selectedPaymentMethodId) {
            const firstPaymentMethodId = paymentMethods && paymentMethods.length > 0 ? paymentMethods[0].id : -1;
            setPaymentMethodInfo(firstPaymentMethodId);
        }
    }, [paymentMethods]);

    const isOptionSelected = (paymentMethodId: number) => {
        return paymentMethodId === selectedPaymentMethodId;
    }
    
    const setPaymentMethodInfo = (id: number) => {
         validation?.setValue('method', id.toString());
         setSelectedPaymentMethodId(id)
    }

    const getPaymentMethodsRadioControl = () => {
        if (!paymentMethods) {
            return <></>;
        }
        return (
            <span className="d-flex flex-wrap payment-selection-option-container">
                {paymentMethods.map((paymentMethod, index) => {
                    return (
                        <span key={'radio-button-payment-method-select-' + paymentMethod.id} className={validation?.getError('method') ? 'is-invalid' : ''}>
                            <Input
                                name="payment-method-selection"
                                id={`PaymentMethod${paymentMethod.id}`}
                                type="radio"
                                invalid={!!validation?.getError('method')}
                                value={paymentMethod.id}
                                checked={isOptionSelected(paymentMethod.id)}
                                className="radio-option-input"
                                onChange={() => setPaymentMethodInfo(paymentMethod.id)}
                            />
                            <Label check for={`PaymentMethod${paymentMethod.id}`}>
                                {paymentMethod.name}
                            </Label>
                        </span>);
                })}
                <FormFeedback>{validation?.getError('method')}</FormFeedback>
            </span>
        )
    }

    const getSelectedPaymentMethod = () => {
        const paymentMethodIndex = paymentMethods?.findIndex(pm => pm.id === selectedPaymentMethodId);
        return !!(paymentMethods && paymentMethodIndex !== undefined && paymentMethods[paymentMethodIndex])
            ? paymentMethods[paymentMethodIndex] : undefined;
    }

    const getPaymentMethodEntry = () => {
        if (selectedPaymentMethodOption === PaymentMethodOption.NewPaymentMethod) {
            return (
                <span>
                {getPaymentMethodsRadioControl()}
                    <PaymentDetailsEntry billingAddress={billingAddress}
                                         paymentMethod={getSelectedPaymentMethod()}
                                         allowSavingPaymentType={!!consumer}
                                         onPaymentDetailsChanged={onPaymentDetailsChanged}
                                         onSavePaymentDetailsClicked={onSavePaymentDetailsClicked}
                                         paymentInfo={paymentInfo}
                                         validation={validation}
                                         isSavingPaymentDetails={isSavingPaymentDetails}/>
                </span>
            );
        }
        else {
            return (
                <SavedPaymentSelection selectedPaymentProfileId={selectedPaymentProfileId}
                                       savedPaymentDetails={savedPaymentDetails}
                                       onPaymentDetailsChanged={onPaymentDetailsChanged}
                                       validation={validation}
                                       isDeletingSavedPayment={isDeletingSavedPayment}
                                       onRemoveSavedPaymentMethodClicked={onRemoveSavedPaymentMethodClicked}></SavedPaymentSelection>
            )
        }
    }
    
    const getBody = () => <>
        <span className="payment-method-selection-options">
                <Button 
                    color="primary"
                    active={selectedPaymentMethodOption === PaymentMethodOption.NewPaymentMethod}
                    onClick={() => setSelectedPaymentMethodOption(PaymentMethodOption.NewPaymentMethod)}>New Payment Method</Button>
                <Button
                    color="secondary"
                    disabled={!enabledSavedPaymentMethods}
                    active={selectedPaymentMethodOption === PaymentMethodOption.SavedPaymentMethod}
                    onClick={() => setSelectedPaymentMethodOption(PaymentMethodOption.SavedPaymentMethod)}>Saved Payment Methods</Button>
        </span>
        <p>{t(`payment.message`)}</p>

        {getPaymentMethodEntry()}
    </>

    return (useCardContainer) ? (
        <Card>
            <CardHeader className="payment-selection-card-header card-header">
                <h5 className="m-0">Payment Selection</h5>
            </CardHeader>
            <CardBody>
                { getBody() }
            </CardBody>
        </Card>
    ) : (
        <>
            <h5 className="m-0">Payment Selection</h5>
            { getBody() }
        </>

    )
}