import {SiteProductVariantQuantityOption} from "./SiteProductVariantQuantityOption"
import {Category} from "../category/Category";
import {Image} from "../image/Image";
import {Configuration} from "../input/configuration/Configuration";
import {SiteProductImage} from "./SiteProductImage";
import {SiteProductVariant} from "./SiteProductVariant";
import {Component} from "../input/component/Component";
import {flow, groupBy, filter, map, reduce, find} from "lodash/fp";
import {curry} from "lodash";
import {read} from "node:fs";
import {ComponentOption} from "../input/component/ComponentOption";

export interface Product {
    id: number,
    name: string,
    description: string,
    promotionText?: string,
    quantityOptions?: SiteProductVariantQuantityOption[],
    categories?: Category[]

    // isPublic not important to the client app, it will only ever get ispublic true products
}

export interface SiteProduct {
    id: number,
    name: string,
    description: string,
    promotionText?: string,
    types: ProductType[],

    siteId: number,
    productId: number,
    imageId?: number,
    urlName?: string,
    thumbnailId?: number,
    metaTitle?: string,
    metaDescription?: string,

    quantityOptions?: SiteProductVariantQuantityOption[],
    categories?: Category[],
    primaryCategoryName?: string,
    images?: SiteProductImage[],
    image?: Image
    configurations?: Configuration[]
}

export function isCheckProduct(siteProduct: SiteProduct): boolean {
    return siteProduct.types
        .findIndex(t => t.name === ProductTypes.Check) > -1;
}

type PricingGridHeaders = ['Singles' | 'Normal Collation', 'Duplicates' | 'Reverse Collation'];

export interface PricingGridViewModel {
    readonly headers: PricingGridHeaders;
    readonly prices: Readonly<Record<string, QuantityPrice>>;
}

export interface QuantityPrice {
    readonly quantity: number;
    readonly price1: number | 'Not Available';
    readonly price2: number | 'Not Available';
}

const MULTI_PART_FORMAT = 'Multi-Part Format';
const COLLATION_COMPONENT = 'Collation'
const SINGLES_OPTION = 'Single';
const DUPLICATES_OPTION = 'Duplicates';
const NORMAL_COLLATION_OPTION = 'Normal Collation';
const REVERS_COLLATION_OPTION = 'Reverse Collation';

export function getPricingTableViewModel(
    product: SiteProduct,
    variants: ReadonlyArray<SiteProductVariant>,
    components: ReadonlyArray<Component>)
    : PricingGridViewModel | null {

    const multiPartComponent = getMultiPartFormatComponent(components)
    const collationComponent = getCollationComponent(components)

    let singlesOption: ComponentOption | undefined;
    let duplicatesOption: ComponentOption | undefined;
    let isMultiPart = false;
    let isCollation = false;
    let applicableVariants: { variant2: SiteProductVariant | undefined; variant1: SiteProductVariant | undefined } | undefined;
    let headers: PricingGridHeaders = ['Singles', 'Duplicates'];
    
    if (multiPartComponent) {
        singlesOption = getSinglesOption(multiPartComponent);
        duplicatesOption = getDuplicatesOption(multiPartComponent);
    }

    if (multiPartComponent && singlesOption && duplicatesOption) {
        applicableVariants = {
            variant1: findVariantSupportingOption(singlesOption, variants),
            variant2: findVariantSupportingOption(duplicatesOption, variants)
        };
        isMultiPart = !!(applicableVariants.variant2 && applicableVariants.variant1);
    }

    if (!isMultiPart && collationComponent) {
        singlesOption = getNormalCollationOption(collationComponent);
        duplicatesOption = getReverseCollationOption(collationComponent);

        if (singlesOption && duplicatesOption) {
            applicableVariants = {
                variant1: findVariantSupportingOption(singlesOption, variants),
                variant2: findVariantSupportingOption(duplicatesOption, variants)
            };
            isCollation = true;
        }
    }
    
    if (!isMultiPart && !isCollation) {
        return null;
    }

    if (isCollation) {
        headers = ['Normal Collation', 'Reverse Collation'];
    }
    
    const prices = product.quantityOptions
        ?.reduce((results, qo) => {
            if (!qo.isActive) {
                return results;
            }
            const isPrice1 = qo.siteProductVariantId === applicableVariants?.variant1?.id;
            const isPrice2 = qo.siteProductVariantId === applicableVariants?.variant2?.id;
            const descriptionIsInResults = qo.description in results;

            if (!descriptionIsInResults) {
                if (isPrice1) {
                    return {
                        ...results,
                        [qo.description]: {
                            quantity: qo.quantity,
                            price1: qo.price,
                            price2: 'Not Available'
                        } as QuantityPrice
                    }
                } else if (isPrice2) {
                    return {
                        ...results,
                        [qo.description]: {
                            quantity: qo.quantity,
                            price2: qo.price,
                            price1: 'Not Available'
                        } as QuantityPrice
                    }
                }
            }

            if (isPrice1) {
                return {
                    ...results,
                    [qo.description]: {
                        ...results[qo.description],
                        price1: qo.price
                    } as QuantityPrice
                }
            } else if (isPrice2) return {
                ...results,
                [qo.description]: {
                    ...results[qo.description],
                    price2: qo.price
                } as QuantityPrice
            }
            return results;
        }, {} as Readonly<Record<string, QuantityPrice>>) || {};

    return {headers, prices};
}

const getComponent = curry((name: string, components: readonly Component[]) =>
    find(c => c.name === name, components));

const getMultiPartFormatComponent = getComponent(MULTI_PART_FORMAT);
const getCollationComponent = getComponent(COLLATION_COMPONENT);

const getOption = curry((optionDescription: string, component: Component | undefined) => find(
    o => o.description === optionDescription
    , component?.componentOptions || []));
const getSinglesOption = getOption(SINGLES_OPTION);
const getDuplicatesOption = getOption(DUPLICATES_OPTION);
const getNormalCollationOption = getOption(NORMAL_COLLATION_OPTION);
const getReverseCollationOption = getOption(REVERS_COLLATION_OPTION);

const variantSupportsOption = curry((option: ComponentOption | undefined, variant: SiteProductVariant) =>
    variant.componentIds?.includes(option?.id || -1));

const findVariantSupportingOption = curry((option: ComponentOption | undefined, variants: readonly SiteProductVariant[]) =>
    find(variantSupportsOption(option), variants)) as (
        (
            option: ComponentOption | undefined,
            variants: readonly SiteProductVariant[]
        ) => SiteProductVariant | undefined);


export interface ProductType {
    id: number,
    name: string,
    isCartExclusive: boolean
}

export enum ProductTypes {
    Check = "Check",
    PersonalChecking = 'Personal Checking',
}

export interface GetAccessoryProductRequest {
    accessoryProductId: number,
    siteProductId: number
}