
import './RefundManagement.css';
import {RefundBrowse} from "../../order/tabs/refund/browse/RefundBrowse";
import {Container} from "reactstrap";

export const RefundManagement = () => {

    return (
        <Container className='d-flex flex-column w-100 admin-home-container'>
            <RefundBrowse />
        </Container>
    );
}