import './Accordions.css';
import {useTranslation} from "react-i18next";
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap';
import { useState } from 'react';
import {doesTranslationValueExist, getTranslationValue} from "../../../../utils/Utils";

export const SecurityAccordion = () => {
    const { t } = useTranslation();

    const [open, setOpen] = useState('');
    const toggle = (id: string) => setOpen(open === id ? '0' : id);

    return (
        <div className='securrty-accordion-container'>
            <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                    <AccordionHeader targetId='1'>{getTranslationValue(t, `pages.faq.security.title1`)}</AccordionHeader>
                    <AccordionBody accordionId='1'>
                        <span>
                            {getTranslationValue(t, `pages.faq.security.body1`)}
                            {doesTranslationValueExist(t, 'pages.faq.security.body1Link') && (
                              <>
                                  <a href={getTranslationValue(t, `pages.faq.security.body1Link`)} target='_blank'>
                                      {getTranslationValue(t, `pages.faq.security.body1LinkText`)}
                                  </a>.
                              </>
                            )}
                        </span>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId='2'>{getTranslationValue(t, `pages.faq.security.title2`)}</AccordionHeader>
                    <AccordionBody accordionId='2'>
                        <span>{getTranslationValue(t, `pages.faq.security.body2`)}</span>
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </div>
    )
}