import {OrderSearchCriteria} from "./order/OrderSearchCriteria";
import {OrderGrid} from "./order/OrderGrid";
import {Button, Input} from "reactstrap";
import {useEffect, useState} from "react";
import {OrderSearchCriteriaPanel} from "./order/OrderSearchCriteriaPanel";
import {Link, URLSearchParamsInit, useSearchParams} from "react-router-dom";
import {useGetOrderStatusesQuery} from "../../app/apiSlice";
import './AdminHome.css';
import {useAppDispatch, useAppSelector, useClaims} from "../../app/hooks";
import {selectOrderSearchCriteria, setOrderSearchCriteria} from "../order/OrderSlice";

function criteriaToParams(orderSearchCriteria: OrderSearchCriteria): URLSearchParamsInit | undefined {
    const criteriaEntries = Object.entries(orderSearchCriteria);
    if (criteriaEntries.length === 0) return;
    return criteriaEntries
        .reduce((params, [key, value]) => {
            if (!value) return params;
            if (typeof value === 'number') return {
                ...params,
                [key]: value.toString()
            }
            if (Array.isArray(value)) return {
                ...params,
                [key]: value.map(v => v.toString())
            }
            return {
                ...params,
                [key]: value
            }
        }, {} as any);
}

function searchParamsToCriteria(searchParams: URLSearchParams): OrderSearchCriteria {
    return Array.from(searchParams.entries())
        .reduce((criteria, [key, value]) => {
            switch (key) {
                case 'orderId':
                    return {
                        ...criteria,
                        orderId: Number.parseInt(value, 10)
                    }
                case 'statusIds':
                    return {
                        ...criteria,
                        statusIds: criteria.statusIds
                            ? [...criteria.statusIds, Number.parseInt(value, 10)]
                            : [Number.parseInt(value, 10)]
                    }
                default:
                    return {
                        ...criteria,
                        [key]: value
                    }
            }
        }, {} as OrderSearchCriteria);
}

export const AdminHome = () => {
    const dispatch = useAppDispatch();
    const orderSearchCriteria = useAppSelector(selectOrderSearchCriteria);
    const [searchParams, setSearchParams] = useSearchParams();
    
    const {hasEditingRole} = useClaims();

    const [search, setSearch] = useState('');
    const [criteriaIsOpen, setCriteriaIsOpen] = useState(true);

    // Update the query string when the criteria change
    useEffect(() => {
        const newParams = criteriaToParams(orderSearchCriteria)
        if (newParams) {
            setSearchParams(newParams, {
                replace: true
            })
        }
    }, [orderSearchCriteria]);

    // Update the criteria when the page loads with a query string
    useEffect(() => {
        dispatch(setOrderSearchCriteria(searchParamsToCriteria(searchParams)));
    }, [searchParams]);

    const ToolRow = () => (
        <div className='d-flex flex-row-reverse mb-3'>
            <Input
                className='w-50 mx-1 ms-auto'
                placeholder='Filter results by text...'
                defaultValue={search}
                onChange={e => setSearch(e.target.value)}/>
            {hasEditingRole &&
                <Link className='btn btn-primary mx-1'
                      to='order/new'>
                    Create New Order
                </Link>
            }
            <Button color='secondary' 
                    className='criteria-button btn-secondary'
                    outline={true}
                    onClick={() => setCriteriaIsOpen(old => !old)}>
                {criteriaIsOpen ? 'Close' : 'Open'} Search Criteria
            </Button>
        </div>
    );

    return (
        <div className='d-flex justify-content-between w-100 h-100'>
            <div className={'d-flex flex-column grid-criteria-container mx-2'}>
                <OrderSearchCriteriaPanel isOpen={criteriaIsOpen}/>
            </div>
            <div className='d-flex flex-column admin-home-right-side-content'>
                {ToolRow()}
                <OrderGrid search={search.toLowerCase()}/>
            </div>
        </div>
    );
}