import {HoldsQueueBrowse} from "../../holdsQueue/browse/HoldsQueueBrowse";

interface HoldInfoTabProps {
    orderId: number
}

export const HoldInfoTab = ({orderId}: HoldInfoTabProps) => {

    return (
        <HoldsQueueBrowse orderId={orderId}></HoldsQueueBrowse>
    );

}