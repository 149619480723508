import {CartIdType} from "../cart/CartSlice";

export interface Discount {
    id: number,
    amount?: number,
    percentage?: number,
    discountTypeId: number,
    cartId?: CartIdType,
    orderId?: number,
    createdById: number,
    timeCreated: string,
    description?: string,

    effectiveAmount?: number,
    type: DiscountType
}

export interface EditDiscount {
    id?: number,
    amount?: number,
    percentage?: number,
    discountTypeId?: number,
    cartId?: CartIdType,
    orderId?: number,
    createdById?: number,
    timeCreated?: string,

    discountType?: DiscountType
}

export interface DiscountType {
    id: number,
    name: string,
}

export enum DiscountTypeNames {
    Amount = "Amount",
    Percentage = "Percentage",
    FreeShipping = "Free Shipping"
}

export interface SaveDiscountRequest {
    discountId?: number,
    amount?: number,
    percentage?: number,
    discountTypeId: number,
    cartId?: CartIdType,
    orderId?: number,
}