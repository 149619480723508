import { GridPage } from "../../shared/GridPage";
import React, { useEffect, useState } from "react";
import { ColumnConfig, GridConfig } from "../../shared/Grid";
import { PagingInfo, SortState } from "../../../../utils/Grid";
import { Link } from "react-router-dom";
import {
    useDeleteControlListValueMutation,
    useGetControlListActionsQuery,
    useGetControlListGroupsQuery,
    useGetControlListReasonsQuery,
} from "../../../../app/apiSlice";
import {useAppDispatch, useAppSelector, useClaims, useDebounce} from "../../../../app/hooks";
import { Consumer } from "../../customer/Consumer";
import { ControlListValue, ControlListValueFetchSearchCriteria, } from "../ControlListValue";
import {
    canFetchMoreControlListValues,
    fetchControlListValues,
    fetchMoreControlListValues, isFetchingControlListValues,
    refetchAllControlListValues,
    selectControlListValues
} from "../ControlListValueSlice";
import { ControlListValueEdit } from "../edit/ControlListValueEdit";
import { Button } from "reactstrap";
import { getPrettyAddress, Utils } from "../../../../utils/Utils";
import { Address } from "../../../input/address/Address";
import { closeModal, showConfirmationModal, showInformationModal } from "../../../modal/ModalSlice";
import { ModalType } from "../../../modal/ModalType";
import { ADMIN, CS3, TEAM_LEAD } from "../../../../utils/Roles";
import { ControlListValueType } from "../ControlListGroup";

const ConsumerCellDisplay = ( {val}: {val: Consumer}) => { return <Link to={`/admin/consumer/${(val)?.id}`}>{(val)?.email}</Link>};

export const ControlListBrowse = () => {
    const { hasPermission } = useClaims();
    const { data: controlListGroups } = useGetControlListGroupsQuery();
    const { data: controlListActions } = useGetControlListActionsQuery();
    const { data: controlListReasons } = useGetControlListReasonsQuery();
    const [ deleteControlListValue ] = useDeleteControlListValueMutation();
    const [defaultCriteria, setDefaultCriteria] = useState<ControlListValueFetchSearchCriteria>({});
    const [criteria, setCriteria] = useState(defaultCriteria);
    const debouncedCriteria = useDebounce(criteria, 500);
    const controlListValues = useAppSelector(selectControlListValues);
    const moreAvailable = useAppSelector(canFetchMoreControlListValues);
    const isLoading = useAppSelector(isFetchingControlListValues);

    const dispatch = useAppDispatch();
    const fetchStatus = useAppSelector(state => state.controlListValues.status);
    const [ focusedControlListValue, setFocusedControlListValue ] = useState<ControlListValue>();
    const [ showEditModal, setShowEditModal ] = useState(false);
    const hasBasicEditPermission = hasPermission([CS3, TEAM_LEAD, ADMIN]);
    
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 500);
    const [sort, setSort] = useState<SortState<ControlListValue>>({ column: 'stringValueOne', serverColumn: 'stringValueOne', direction: 'asc' });
    
    const selectedGroup = controlListGroups?.find(clg => clg.id === debouncedCriteria.controlListGroupId);
    const valueOneLabel = selectedGroup?.stringValueOneLabel ?? 'Value One';
    const valueTwoLabel = selectedGroup?.stringValueTwoLabel ?? 'Value Two';
    const usesValueTwo = selectedGroup?.valueTwoType !== ControlListValueType.None;
    const usesConsumerValueOne = selectedGroup?.valueOneType === ControlListValueType.UserId;

    // set default group when groups load
    useEffect(() => {
        const firstGroupId = controlListGroups?.[0]?.id;
        setDefaultCriteria(old => ({ ...old, controlListGroupId: firstGroupId }));
        setCriteria(old => ({ ...old, controlListGroupId: firstGroupId }));
    }, [controlListGroups]);

    // set default selected actions when actions load
    useEffect(() => {
        const selectedActionIds = controlListActions?.filter(cla => cla.name !== 'Whitelist')?.map(cla => cla.id);
        setDefaultCriteria(old => ({ ...old, controlListActionIds: selectedActionIds }));
        setCriteria(old => ({ ...old, controlListActionIds: selectedActionIds }));
    }, [controlListActions]);

    // set default selected reasons when reasons load
    useEffect(() => {
        const selectedReasonIds = controlListReasons?.map(clr => clr.id);
        setDefaultCriteria(old => ({ ...old, controlListReasonIds: selectedReasonIds }));
        setCriteria(old => ({ ...old, controlListReasonIds: selectedReasonIds }));
    }, [controlListReasons]);

    // initial pull of data and on sort/search change
    useEffect(() => {
        // Only do initial fetch if all filters are loaded
        if (defaultCriteria.controlListGroupId && defaultCriteria.controlListActionIds && defaultCriteria.controlListReasonIds) {
            dispatch(fetchControlListValues({ criteria: {...debouncedCriteria, search: debouncedSearch}, paging: { sortKey: sort.serverColumn!, sortDirection: sort.direction }}));
        }
    }, [debouncedCriteria, sort, debouncedSearch, defaultCriteria, dispatch]);

    // Clear focused clv on modal close
    useEffect(() => {
        if(!showEditModal) {
            setFocusedControlListValue(undefined);
        }
    }, [showEditModal]);

    const toggleEditModal = (controlListValue?: ControlListValue) => {
        setFocusedControlListValue(controlListValue);
        setShowEditModal(old => !old);
    }
    
    const onValueSave = () => {
        dispatch(refetchAllControlListValues({criteria: debouncedCriteria}));
    }

    const fetchMore = (paging: PagingInfo) => {
        if (fetchStatus !== 'succeeded') return; // don't try to grab again if we're already loading
        dispatch(fetchMoreControlListValues({ criteria: {...debouncedCriteria, search}, paging}));
    }

    const onDeleteClick = (controlListValueId: number, event :  React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // Stop propagation to prevent the row click event from firing as well
        event.stopPropagation();
        dispatch(showConfirmationModal( {
            modalType: ModalType.Delete,
            onConfirm: () => confirmDeleteControlListValue(controlListValueId)
        }));
    }

    const confirmDeleteControlListValue = async (controlListValueId: number) => {
        try {
            if (controlListValueId) await deleteControlListValue(controlListValueId).unwrap();
            dispatch(closeModal());
            dispatch(refetchAllControlListValues({criteria: { ...criteria, search }, paging: { sortKey: sort.serverColumn!, sortDirection: sort.direction }}));
        } catch (e: any) {
            dispatch(showInformationModal({
                title: 'Could Not Delete Control List Value!',
                text: e.status === 401 ? 'Sorry, you do not have permission to do that.' : 'Error deleting control list value. Please try again.', 
            }))
        }
    }

    const getStringDisplayValue = (val?: string) => {
        if (!val)
            return "";
        try {
            const address: Address = JSON.parse(val);
            if (address.street)
                return getPrettyAddress(address);
            return val;
        }
        catch {
            return val;
        }
    }
    
    const getConsumerCellDisplay = (val: Consumer) => {
        return <ConsumerCellDisplay val={val}/>;
    }

    const config: GridConfig<ControlListValue> = {
        configSections: [
            {
                columns: [
                    new ColumnConfig({
                        field: 'controlListGroupId',
                        display: 'Group',
                        isHidden: true,
                        type: 'select',
                        choices: controlListGroups?.map(clg => ({ value: clg.id, display: clg.name })) ?? []
                    }),
                    new ColumnConfig({
                        field: 'controlListActionIds',
                        display: 'Action',
                        isHidden: true,
                        type: 'multi-select',
                        choices: controlListActions?.map(cla => ({ value: cla.id, display: cla.name })) ?? []
                    }),
                    new ColumnConfig({
                        field: 'controlListAction.name',
                        display: 'Action',
                        isSearchCriteria: false,
                    }),
                    new ColumnConfig({
                        field: 'controlListReason.name',
                        display: 'Reason',
                        isSearchCriteria: false,
                    }),
                    ...(usesConsumerValueOne ?
                        // use consumer select if this group uses consumer value one
                        [new ColumnConfig<ControlListValue>({
                            field: 'consumer',
                            display: 'Consumer',
                            isSearchCriteria: false,
                            cellDisplay: val => val ? getConsumerCellDisplay(val as Consumer) : "No val",
                        })] :
                        // otherwise, just use a regular string value
                        [new ColumnConfig<ControlListValue>({
                            field: 'stringValueOne',
                            display: valueOneLabel,
                            cellDisplay: val => val ? getStringDisplayValue(val as string) : "",
                        })]),
                    ...(usesValueTwo ? [new ColumnConfig<ControlListValue>({
                        field: 'stringValueTwo',
                        display: valueTwoLabel,
                        cellDisplay: val => val ? getStringDisplayValue(val as string) : "",
                    })] : []),
                    new ColumnConfig({
                        field: 'description',
                        display: 'Description',
                    }),
                    new ColumnConfig({
                        field: 'controlListReasonIds',
                        display: 'Reason',
                        isHidden: true,
                        type: 'multi-select',
                        choices: controlListReasons?.map(clr => ({ value: clr.id, display: clr.name })) ?? []
                    }),
                    new ColumnConfig({
                        field: 'controlListGroup.name',
                        display: 'Group',
                        isSearchCriteria: false,
                    }),
                    ...(hasBasicEditPermission ? [new ColumnConfig<ControlListValue>({
                        field: 'id',
                        display: 'Action',
                        cellDisplay: val => val && Utils.isValidNumber(val) ? <Button color='danger' className='btn btn-primary m-auto' onClick={(e) => onDeleteClick(val, e)}>Delete</Button> : '',
                        isSortable: false,
                        isSearchCriteria: false,
                    })] : []),
                ]
            }
        ]
    };

    return (
        <>
            <GridPage records={controlListValues ?? []}
                      gridConfig={config}
                      defaultCriteria={defaultCriteria}
                      title={'Control Lists'}
                      sort={sort}
                      onNewModalButtonClicked={hasBasicEditPermission ? toggleEditModal : undefined}
                      shouldShowCriteriaPanel={true}
                      onSortChanged={setSort}
                      onSearchChange={setSearch}
                      moreRecordsAvailable={moreAvailable}
                      onCriteriaChange={setCriteria}
                      onShouldFetchMore={fetchMore}
                      onRowClick={hasBasicEditPermission ? toggleEditModal : undefined}
                      isLoading={isLoading}/>
            
            
            { hasBasicEditPermission && 
                <ControlListValueEdit isOpen={showEditModal} onIsOpenChange={() => toggleEditModal()} onValueSaved={onValueSave} requestedControlListValue={focusedControlListValue}/>
            }
        </>
    )
}