import {InputClassification} from "../../input/ProductInputValue";

export interface AddToCartPreviewLineItem {
    productInputId: number,
    label: string,
    price: number,
    inputClassification: InputClassification,
    lineItemClassification: LineItemClassification
}

export enum LineItemClassification {
    Configuration = "Configuration",
    AddOn = "AddOn"
}