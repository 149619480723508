import {FinancialInstitution} from "../financialInstitution/FinancialInstitution";
import {ProductType} from "../../product/Product";

export interface MicrFormat {
    id: number
    financialInstitutionId: number
    accountNumberMinLength?: number
    accountNumberMaxLength?: number
    accountNumberMatch?: string
    orderProductId?: number
    checkProductTypes?: ProductType[]
    formatTemplate: string
    timeCreated: string
    timeLastUpdated: string
    createdById: number
    createdByName: string,
    updatedById: number
    updatedByName: string,
    financialInstitution?: FinancialInstitution,
    isActive: boolean,
    matchingInProgressOrderIds?: number[]
}

export interface MicrFormatEdit {
    id?: number
    financialInstitutionId?: number
    accountNumberMinLength?: number
    accountNumberMaxLength?: number
    accountNumberMatch?: string
    usesAccountNumberMatch?: boolean
    orderProductId?: number
    checkProductTypes?: ProductType[]
    formatTemplate: string
    timeCreated?: string
    timeLastUpdated?: string
    createdById?: number
    createdByName?: string,
    updatedById?: number
    updatedByName?: string,
    financialInstitution?: FinancialInstitution
    isActive?: boolean
}

export interface SaveMicrFormatRequest {
    id?: number
    financialInstitutionId: number
    accountNumberMinLength?: number
    accountNumberMaxLength?: number
    accountNumberMatch?: string
    usesAccountNumberMatch?: boolean
    orderProductId?: number
    checkProductTypes?: ProductType[]
    formatTemplate: string
}

export interface MicrFormatOrderResponse {
    micrFormatId?: number
    orderProductId: number
    isMicrProduct: boolean
}

export enum MicrFormatFieldNames {
    AccountNumberMin = 'AccountNumberMin',
    AccountNumberMax = 'AccountNumberMax',
    MicrFormatText = 'MicrFormatText',
    AccountNumberMatch = 'AccountNumberMatch',
    UsesAccountNumberMatch = 'UserAccountNumberMatch',
    FinancialInstitution = 'FinancialInstitution',
}


export enum MicrFormatConstraints {
    AccountNumberMin = 1,
    AccountNumberMax = 16,
    MicrTextLength = 65
}

export enum MicrFormatTemplate {
    Personal = '                      :RRRRRRRRR:ZZ#########<NNNNN               ',
    Business = '             <NNNNNN< :RRRRRRRRR:ZZ#########<                    ',
    Default = '                      :RRRRRRRRR:ZZ#########<                    '
}

export enum CheckProductTypeName {
    Personal = 'Personal Checking',
    Business = 'Business Checking'
    
}

export const MICR_WHITESPACE_TYPICAL_FRONT = 15;
export const MICR_WHITESPACE_TYPICAL_BACK = 50;

export const MICR_ACCOUNT_NUMBER_CHARACTER = '#';

export const MICR_OPTIONAL_ACCOUNT_NUMBER_CHARACTER = 'Z';

export const MICR_ROUTING_NUMBER_CHARACTER = 'R';

export const MICR_CHECK_NUMBER_CHARACTER = 'N';
export const MICR_ROUTING_NUMBER_BOUNDARY_CHARACTER = ':';

export const MICR_ONUS_CHARACTER = '<';

export const MICR_FORMATTING_ALLOWED_CHARACTERS = [
    MICR_ACCOUNT_NUMBER_CHARACTER,
    MICR_OPTIONAL_ACCOUNT_NUMBER_CHARACTER,
    MICR_CHECK_NUMBER_CHARACTER,
    MICR_ROUTING_NUMBER_CHARACTER,
    MICR_ROUTING_NUMBER_BOUNDARY_CHARACTER,
    MICR_ONUS_CHARACTER,
    '-',
    ' '
];

export const MICR_FORMATTING_BLANK_CHARACTERS = [
    '',
    ' ',
    'enter',
    'backspace',
    'escape'
]

export const DEFAULT_MICR_MIN_ACCOUNT_NUMBER_LENGTH = 9;
export const DEFAULT_MICR_MAX_ACCOUNT_NUMBER_LENGTH = 11;