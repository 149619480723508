import { GridPage } from "../../shared/GridPage";
import { useGetOrderNotesQuery } from "../../../../app/apiSlice";
import React, { useEffect, useState } from "react";
import { ColumnConfig, GridConfig } from "../../shared/Grid";
import { SortState } from "../../../../utils/Grid";
import {Utils} from "../../../../utils/Utils";
import './NoteBrowse.css';
import {Note} from "../Note";
import {DateTime} from "luxon";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {NoteCreate} from "../create/NoteCreate";

interface NoteBrowseProps {
    orderId?: number,
    orderNotes?: Note[]
}

export const NoteBrowse = ({ orderId, orderNotes }: NoteBrowseProps) => {
    const { data: queryNotes, isLoading, refetch } = useGetOrderNotesQuery(orderId ?? skipToken);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState<SortState<Note>>({ column: 'id', direction: 'asc' });
    const [ sortedNotes, setSortedNotes ] = useState(queryNotes);
    const [ newNoteIsOpen, setNewNoteIsOpen ] = useState(false);

    // sort
    useEffect(() => {
        setSortedNotes(notes => {
            const workingNotes = [...notes ?? queryNotes ?? orderNotes ?? []];
            return workingNotes.sort(Utils.sortBy(sort.column, sort.direction));
        });
    }, [sort, orderNotes, queryNotes]);

    // filter
    useEffect(() => {
        const lowerSearch = search.toLowerCase();
        setSortedNotes(
            !lowerSearch ? queryNotes ?? orderNotes : [...queryNotes ?? orderNotes ?? []]?.filter(note =>
                (note.createdByEmail && note.createdByEmail.toLowerCase().includes(lowerSearch)) ||
                (note.text && note.text.toLowerCase().includes(lowerSearch))
            )
        );
    }, [search, queryNotes, orderNotes]);

    const closeModal = (shouldRefresh: boolean | undefined = false) => {
        setNewNoteIsOpen(false);
        if (shouldRefresh)
            refetch();
    }

    const onNewNoteClicked = async () => {
        setNewNoteIsOpen(true);
    }

    const config: GridConfig<Note> = {
        configSections: [
            {
                columns: [
                    new ColumnConfig({
                        field: 'subject',
                        display: 'Subject'
                    }),
                    new ColumnConfig({
                        field: 'text',
                        display: 'Text',
                        cellDisplay: val => val ? <p className="note-row-text-body">{val as string}</p> : ''
                    }),
                    new ColumnConfig({
                        field: 'noteCategory.name',
                        display: 'Category'
                    }),
                    new ColumnConfig({
                        field: 'createdByEmail',
                        display: 'Created By'
                    }),
                    new ColumnConfig({
                        field: 'timeCreated',
                        display: 'Time Created',
                        cellDisplay: val => val ? DateTime.fromISO(val as string + 'Z').toLocaleString(DateTime.DATETIME_SHORT) : ''
                    }),
                ]
            }
        ]
    };

    return (
        <>
            <GridPage records={sortedNotes ?? []}
                      gridConfig={config}
                      title={'Notes'}
                      sort={sort}
                      shouldShowCriteriaPanel={false}
                      onNewModalButtonClicked={orderId ? onNewNoteClicked : undefined}
                      onSortChanged={setSort}
                      onSearchChange={setSearch}
                      noRecordsMessage={queryNotes && queryNotes.length > 0 && (orderNotes === undefined)
                          ? "No Notes found using the search criteria"
                          : `No Notes exist on record ${orderId ? 'for this Order Id' : ''}`
                      }
                      isLoading={isLoading}
            />

            {orderId &&
              <NoteCreate
                orderId={orderId}
                isOpen={newNoteIsOpen}
                toggleModal={closeModal}/>
            }
        </>
    )
}