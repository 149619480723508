import { Badge, Input, InputGroup, Label } from 'reactstrap';
import { Configuration } from '../configuration/Configuration';
import './TextRadioSelect.css';
import { Component } from '../component/Component';
import {
  calculateSavings,
  isBestDeal, isLargestQuantity, pricePerPack,
  SiteProductVariantQuantityOption
} from '../../product/SiteProductVariantQuantityOption';
import {useEffect, useState} from "react";
import {ProductInputValue} from "../ProductInputValue";
import {ProductType, ProductTypes} from "../../product/Product";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {OnValueChangeParams} from "../view/ProductInputView";

interface TextRadioSelectProps {
  basePrice: number,
  quantityOptions?: SiteProductVariantQuantityOption[],
  productInput?: Component | Configuration,
  productInputValue?: ProductInputValue,
  productTypes: ProductType[],
  onValueChange: (params: OnValueChangeParams) => void
}

export const TextRadioSelect = ({ productInput, basePrice, quantityOptions, productInputValue, productTypes, onValueChange}: TextRadioSelectProps) => {

  const [ previouslySelectedQuantity, setPreviouslySelectedQuantity ] = useState<number | undefined>(undefined);

  useEffect(() => {
    // If no value selected yet or previously selected value is no longer a valid option
    if (!productInputValue?.isDirty || !quantityOptions?.some(qo => qo.id === productInputValue.selectedProductInputOptionId)) {
      const defaultOption = getDefaultOption();
      const previouslySelectedQuantity = getPreviouslySelectedQuantity();
      if (previouslySelectedQuantity) {
        onValueChange({ inputValue: `${previouslySelectedQuantity.id}`, optionId: previouslySelectedQuantity.id });
      }
      else if (defaultOption) {
        setPreviouslySelectedQuantity(defaultOption.quantity);
        onValueChange({ inputValue: `${defaultOption.id}`, optionId: defaultOption.id });
      }
    }
  }, [productInputValue, quantityOptions]);

  function onOptionSelected(quantityOption: SiteProductVariantQuantityOption, optionId?: number) {
    setPreviouslySelectedQuantity(quantityOption.quantity);
    onValueChange({ inputValue: quantityOption.id?.toString(), optionId: optionId });
  }

  function getDefaultOption() {
    const defaultOptionIndex = quantityOptions?.findIndex(qo => qo.isDefault);
    if (!quantityOptions || defaultOptionIndex === undefined || defaultOptionIndex === -1) {
      return null;
    }
    return quantityOptions[defaultOptionIndex];
  }

  function getPreviouslySelectedQuantity() {
    const previouslySelectedOptionIndex = quantityOptions?.findIndex(qo => qo.quantity === previouslySelectedQuantity);
    if (!quantityOptions || previouslySelectedOptionIndex === undefined || previouslySelectedOptionIndex === -1) {
      return null;
    }
    return quantityOptions[previouslySelectedOptionIndex];
  }

  function isOptionSelected(value: string) {
    return productInputValue?.value === value;
  }

  function getTotalPrice(quantityOption: SiteProductVariantQuantityOption) {
    return quantityOption.price;
  }

  function getDeltaPrice(quantityOption: SiteProductVariantQuantityOption) {
    return quantityOption.price - basePrice;
  }

  function getPriceToDisplay(quantityOption: SiteProductVariantQuantityOption) {
    const totalPrice = getTotalPrice(quantityOption).toFixed(2);
    
    if (productInput && !('configurationDetail' in productInput)) return `$${totalPrice}`;
    
    const deltaPrice = getDeltaPrice(quantityOption).toFixed(2);
    if (productInput?.configurationDetail.showDeltaPrice && productInput.configurationDetail.showTotalPrice) {
      return `+$${deltaPrice} (Total: $${totalPrice})`;
    }
    else if (productInput?.configurationDetail.showDeltaPrice) {
      return `+$${deltaPrice}`;
    }
    else {
      return `$${totalPrice}`
    }
  }

  function getPriceElement(quantityOption: SiteProductVariantQuantityOption) {

    if ((quantityOption.price || (productInput && 'configurationDetail' in productInput && productInput.configurationDetail.alwaysDisplayPrice)))
    {
      const displayPrice = getPriceToDisplay(quantityOption);
      return (
          <span className='radio-button-price'>
                {displayPrice}
            </span>
      );
    }
  }

  function getChecksPerPad(quantityOption: SiteProductVariantQuantityOption) {
    const unitDescriptionText = productTypes && productTypes[0] && productTypes[0].name && productTypes[0].name.toLowerCase() === "check" ?
        " checks in total" : " in total";
    if (quantityOption.checksPerPad && quantityOption.padsPerUnit) {
      return (
          <span className='radio-button-checks-per-pack'>
              {`${quantityOption.padsPerUnit * quantityOption.checksPerPad} ${unitDescriptionText}`}
          </span>
      );
    }
  }

  function getInfoBadge(infoText?: string) {
    if (infoText) {
      return (
          <Badge pill color='fcae1e' className='info-badge'>
            {infoText}
          </Badge>
      );
    }
  }

  const displayBestDealBadge = productTypes.some(({name}) => 
      name === ProductTypes.PersonalChecking);
  
  return (
      <div className="input-text-radio-select-container" >
        {productInput && 'configurationKey' in productInput && productInput.configurationKey}
        <InputGroup className='radio-button-group'>

          {quantityOptions?.map((quantityOption: SiteProductVariantQuantityOption) => {
            return (
                <Label className={`radio-button-label${isOptionSelected(`${quantityOption.id}`) ? ' checked' : ''}`} key={quantityOption.id}>
                  <Input
                      type='radio'
                      name='radio-group'
                      className='radio-select-button'
                      checked={isOptionSelected(`${quantityOption.id}`) ?? false}
                      onChange={() => onOptionSelected(quantityOption, quantityOption.id)}/>
                  <span className='radio-button-text'>
                    {quantityOption.description}
                    {displayBestDealBadge 
                        && isBestDeal(quantityOption, quantityOptions) 
                        && isLargestQuantity(quantityOption, quantityOptions)
                        && <BestDealBadge savings={calculateSavings(quantityOption, quantityOptions)}/>}
                  </span>
                  {getInfoBadge(quantityOption.caption)}
                  {quantityOption.checksPerPad && quantityOption.padsPerUnit ? 
                    getChecksPerPad(quantityOption) : getPriceElement(quantityOption)}
                </Label>
            )
          })}
        </InputGroup>
      </div>
  );
}

const BestDealBadge = ({savings}: {savings: number}) =>
    <Badge color="success" style={{marginLeft: '1rem'}}>
      <span className="hide-on-mobile me-2">
        Best Deal!   
      </span>
      Save {CurrencyFormatter.format(savings)}
    </Badge>