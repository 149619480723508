// share the CSS from the rest of the card items
import './CardItemBrowse.css';
import { useGetRecentOrderProductsQuery } from "../../../app/apiSlice";
import { ProductPreview } from "../../product/preview/ProductPreview";
import { useTranslation } from "react-i18next";
import {useAppSelector} from "../../../app/hooks";
import {selectCurrentConsumerUser} from "../../user/login/AuthenticationSlice";
import {skipToken} from "@reduxjs/toolkit/query";

export const PreviousOrdersBrowse = () => {
  const { t } = useTranslation();
  const consumer = useAppSelector(selectCurrentConsumerUser);
  const { data: recentProducts } = useGetRecentOrderProductsQuery(consumer ? undefined : skipToken);
  
  if (!recentProducts?.length) return <></>
  
  return <div className='card-item-browse-container'>
    <div className="card-item-header">
      <h2 className="card-item-title">{t('home.previousOrders')}</h2>
    </div>
    
    <hr className="card-item-browse-hr"/>
    
    <div className="card-item-previews aspect-ratio-2">
      { recentProducts.map(product => (
        <ProductPreview key={product.id} product={product}/>
      ))}
    </div>
  </div>
}