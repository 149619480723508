import React, {useState} from "react";
import {GridCriteriaPanel} from "./GridCriteriaPanel";
import {Grid, GridConfig} from "./Grid";
import {PagingInfo, SortState} from "../../../utils/Grid";
import './GridPage.css'
import {ToolRow} from "./ToolRow";
import {ButtonProps} from "reactstrap";

interface GridPageProps<T> {
  gridConfig: GridConfig<T>
  onSearchChange?: (search: string) => void
  defaultCriteria?: any
  onCriteriaChange?: (criteria: any) => void
  onResetCriteria?: () => void
  records: T[]
  sort: SortState<T>
  onSortChanged: (newSort: SortState<T>) => void
  moreRecordsAvailable?: boolean
  onShouldFetchMore?: (paging: PagingInfo) => void
  onShouldDisplayMore?: () => void
  onShouldRefetch?: () => void
  newLink?: string
  newLinkText?: string
  shouldShowCriteriaPanel: boolean
  noRecordsMessage?: string
  title?: string
  onRowClick?: (record: T) => void
  onNewModalButtonClicked?: () => void
  printable?: boolean
  isLoading?: boolean
  onDownload?: () => void
  autoSearch?: boolean
  extraActions?: ButtonProps[]
}

export function GridPage<T> (props: GridPageProps<T>) {
  const [criteriaIsOpen, setCriteriaIsOpen] = useState(true);
  const columns = props.gridConfig.configSections?.flatMap(section => section.columns);

  return (
    <div className='d-flex w-100 h-100'>
      { props.shouldShowCriteriaPanel &&
        <div className="d-flex flex-column grid-criteria-container mx-2">
          <GridCriteriaPanel
                  autoSearch={props.autoSearch ?? true}
                  configSections={props.gridConfig.configSections}
                  defaultCriteria={props.defaultCriteria}
                  onSearch={props.onCriteriaChange!}
                  onShouldRefetch={props.onShouldRefetch}
                  onResetCriteria={props.onResetCriteria}
                  isOpen={criteriaIsOpen}/>
        </div>
      }
      <div className='d-flex flex-column grid-page-right-side-content'>
        <ToolRow shouldShowCriteriaPanel={props.shouldShowCriteriaPanel}
                 title={props.title}
                 printable={props.printable}
                 onDownload={props.onDownload}
                 newLink={props.newLink}
                 newLinkText={props.newLinkText}
                 onNewModalButtonClicked={props.onNewModalButtonClicked}
                 onSearchChange={props.onSearchChange}
                 toggleCriteriaText={criteriaIsOpen ? 'Close' : 'Open'}
                 onShowCriteriaToggle={() => setCriteriaIsOpen(curr => !curr)}
                 extraActions={props.extraActions}/>
        <Grid records={props.records}
              columns={columns}
              actions={props.gridConfig.actions}
              sort={props.sort}
              onSortChanged={props.onSortChanged}
              onShouldFetchMore={props.onShouldFetchMore}
              onShouldDisplayMore={props.onShouldDisplayMore}
              moreRecordsAvailable={props.moreRecordsAvailable}
              noRecordsMessage={props.noRecordsMessage}
              isLoading={props.isLoading}
              onRowClick={props.onRowClick}/>
      </div>
    </div>
  );
}