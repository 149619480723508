import { GridPage } from "../../shared/GridPage";
import React, { useEffect, useMemo, useState } from "react";
import { ColumnConfig, GridConfig } from "../../shared/Grid";
import {PagingInfo, SortState} from "../../../../utils/Grid";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import {HoldFlag, HoldFlagFetchSearchCriteria} from "../HoldsFlag";
import {
    useGetHoldFlagReasonsQuery,
    useGetHoldFlagStatusesQuery,
    useGetSitesQuery,
} from "../../../../app/apiSlice";
import {useAppDispatch, useAppSelector, useDebounce} from "../../../../app/hooks";
import {
    changeSearch,
    canFetchMoreHoldFlags,
    fetchHoldFlags,
    selectHoldFlags,
    refetchAllHoldFlags,
    fetchMoreHoldFlags, isFetchingHoldFlags
} from "../HoldFlagSlice";
import {Consumer} from "../../customer/Consumer";
import {HoldEdit} from "../edit/HoldEdit";
import {HoldNew} from "../new/HoldNew";
import {CurrencyFormatter} from "../../../../utils/CurrencyFormatter";
import { reportValToOrderLink } from "../../../reports/ReportView";

interface HoldsQueueBrowseProps {
    orderId?: number
}

export const HoldsQueueBrowse = ({orderId}: HoldsQueueBrowseProps) => {
    const { data: holdsFlagReasons, isLoading: reasonsLoading } = useGetHoldFlagReasonsQuery();
    const { data: holdsFlagStatuses, isLoading: statusesLoading } = useGetHoldFlagStatusesQuery();
    const { data: sites, isLoading: sitesLoading } = useGetSitesQuery();
    
    // This component is used in Order > Hold Info (no criteria panel, as well as 
    // in Hold Queue (in nav - which does have a criteria panel).
    // When we already have the orderId, then we know that we do NOT have a criteria panel, so
    // do not manually set it to hide Resolved status items.
    const defaultCriteria: HoldFlagFetchSearchCriteria = useMemo(() => (orderId ? { orderId } : {
        holdFlagReasonIds: holdsFlagReasons?.map(hfr => hfr.id),
        holdFlagStatusIds: holdsFlagStatuses?.filter(hfs => hfs.name !== "Resolved").map(hfs => hfs.id)
    }), [orderId, holdsFlagReasons, holdsFlagStatuses]);
    const [criteria, setCriteria] = useState(defaultCriteria);
    const debouncedCriteria = useDebounce(criteria, 500);
    const holdFlags = useAppSelector(selectHoldFlags);
    const moreAvailable = useAppSelector(canFetchMoreHoldFlags);
    const isLoading = useAppSelector(isFetchingHoldFlags);
    const dispatch = useAppDispatch();
    const fetchStatus = useAppSelector(state => state.holdFlags.status);
    const [ focusedHoldFlag, setFocusedHoldFlag ] = useState<HoldFlag | undefined>(undefined);
    const [ editHoldFlagIsOpen, setEditHoldFlagIsOpen ] = useState(false);
    const [ newHoldFlagIsOpen, setNewHoldFlagIsOpen ] = useState(false);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState<SortState<HoldFlag>>({ column: 'orderId', serverColumn: 'o.Id', direction: 'asc' });
    const isDebouncing = JSON.stringify(criteria) !== JSON.stringify(debouncedCriteria);
    const isLoadingAnything = isLoading || isDebouncing || sitesLoading || statusesLoading || reasonsLoading;
    
    // update criteria when hold flag reasons & hold flag statuses load
    useEffect(() => {
        if (!orderId) {
            setCriteria({
                holdFlagReasonIds: holdsFlagReasons?.map(hfr => hfr.id),
                holdFlagStatusIds: holdsFlagStatuses?.filter(hfs => hfs.name !== "Resolved").map(hfs => hfs.id)
            });
        }
    }, [holdsFlagReasons, holdsFlagStatuses, orderId])

    // initial pull of data and on sort/search change
    useEffect(() => {
        // Only do initial fetch if all filters are loaded
        if (orderId || (defaultCriteria.holdFlagReasonIds && defaultCriteria.holdFlagStatusIds && !isDebouncing)) {
            dispatch(fetchHoldFlags({ criteria: {...criteria, search}, paging: { sortKey: sort.serverColumn!, sortDirection: sort.direction }}));
        }
    }, [debouncedCriteria, sort, search, defaultCriteria, orderId, criteria, dispatch, isDebouncing]);

    // on search change, reset the slice
    useEffect(() => {
        dispatch(changeSearch());
    }, [debouncedCriteria, search, dispatch]);

    const onRowClick = (holdFlag: HoldFlag) => {
        setFocusedHoldFlag(holdFlag);
        setEditHoldFlagIsOpen(true);
    }

    const fetchMore = (paging: PagingInfo) => {
        if (fetchStatus !== 'succeeded') return; // don't try to grab again if we're already loading
        dispatch(fetchMoreHoldFlags({ criteria: {...debouncedCriteria, search}, paging}));
    }

    const onNewHoldFlagClicked = async () => {
        setNewHoldFlagIsOpen(true);
    }

    const closeModal = (shouldRefresh: boolean | undefined = false) => {
        setFocusedHoldFlag(undefined);
        setNewHoldFlagIsOpen(false);
        setEditHoldFlagIsOpen(false);
        if (shouldRefresh)
            dispatch(refetchAllHoldFlags({criteria}));
    }

    const config: GridConfig<HoldFlag> = {
        configSections: [
            {
                columns: [
                    new ColumnConfig({
                        field: 'storefront',
                        display: 'Storefront',
                        serverColumn: 's.Name',
                        isSearchCriteria: false,
                        isHidden: !!orderId, // if we have an order id, we're looking at this grid at an order-level.
                    }),
                    new ColumnConfig({
                        field: 'siteId',
                        display: 'Storefront',
                        isHidden: true,
                        type: 'select',
                        choices: [ { value: -1, display: 'All Storefronts' }, ...(sites?.map(s => ({ value: s.id, display: s.name })) ?? [])],
                    }),
                    new ColumnConfig({
                        field: 'consumer',
                        display: 'Consumer',
                        serverColumn: 'c.Email',
                        isSearchCriteria: false,
                        cellDisplay: val => (<Link to={`/admin/consumer/${(val as Consumer)?.id}`}>{(val as Consumer)?.email}</Link>),
                    }),
                    new ColumnConfig({
                        field: 'nameOnAccount',
                        display: 'Name on Account',
                        serverColumn: 'acctName.NameOnAccount',
                        isSearchCriteria: false
                    }),
                    new ColumnConfig({
                        field: 'email',
                        display: 'Email',
                        isHidden: true,
                    }),
                    new ColumnConfig({
                        field: 'orderId',
                        display: 'Order Id',
                        serverColumn: 'o.Id',
                        isSearchCriteria: false,
                        cellDisplay: val => reportValToOrderLink(val as number),
                    }),
                    new ColumnConfig({
                        field: 'aba',
                        display: 'ABA',
                        serverColumn: 'aba.RoutingNumber',
                    }),
                    new ColumnConfig({
                        field: 'accountNumber',
                        display: 'Account Number',
                        serverColumn: 'acctNum.AccountNumber',
                        isSearchCriteria: false
                    }),
                    ...(orderId === undefined ? [new ColumnConfig<HoldFlag>({
                        field: 'orderStatus',
                        display: 'Order Status',
                        serverColumn: 'os.Name',
                        isSearchCriteria: false,
                    })] : []),
                    ...(orderId === undefined ? [new ColumnConfig<HoldFlag>({
                        field: 'orderTotal',
                        display: 'Order Total',
                        serverColumn: 'totalCharged.TotalCharged',
                        isSearchCriteria: false,
                        cellDisplay: val => val ? `${CurrencyFormatter.format(val as number)}` : "$-.--",
                    })] : []),
                    new ColumnConfig({
                        field: 'holdFlagStatus.name',
                        display: 'Status',
                        serverColumn: 'hfs.Name',
                        isSearchCriteria: false,
                    }),
                    new ColumnConfig({
                        field: 'holdFlagReason.name',
                        display: 'Reason',
                        serverColumn: 'hfr.Name',
                        isSearchCriteria: false,
                    }),
                    new ColumnConfig({
                        field: 'timeOfFlag',
                        display: 'Time of Flag',
                        serverColumn: 'hf.TimeOfFlag',
                        isSearchCriteria: false,
                        cellDisplay: val => val ? DateTime.fromISO(`${val as string}Z`).setZone("local").toLocaleString(DateTime.DATETIME_SHORT) : 'Never modified.',
                    }),
                    new ColumnConfig({
                        field: 'timeOfFlag',
                        display: '# Days on Hold',
                        serverColumn: 'hf.TimeOfFlag',
                        isSearchCriteria: false,
                        cellDisplay: val => {
                            const currentDate = new Date();
                            const timeOfFlag = val ? new Date(`${val as string}Z`) : null;
                            if (!timeOfFlag)
                                return '0 days ago';
                            const timeDifference = currentDate.getTime() - timeOfFlag.getTime();
                            return `${Math.floor(timeDifference / 86400000)} days ago`;
                        },
                    }),
                    new ColumnConfig({
                        field: 'holdDescription',
                        display: 'Description',
                        isSortable: false,
                        isSearchCriteria: false
                    }),
                    new ColumnConfig({
                        field: 'notes',
                        display: '# of Notes',
                        isSortable: false,
                        isSearchCriteria: false,
                        cellDisplay: val => {
                            const noteCount = Array.isArray(val) ? val.length : 0;
                            const isPlural = noteCount !== 1;
                            return `${noteCount} note${isPlural ? 's' : ''}`;
                        }
                    }),
                    new ColumnConfig({
                        field: 'holdFlagStatusIds',
                        display: 'Hold Flag Status',
                        isHidden: true,
                        type: 'multi-select',
                        choices: holdsFlagStatuses?.map(hfs => ({ value: hfs.id, display: hfs.name })) ?? []
                    }),
                    new ColumnConfig({
                        field: 'holdFlagReasonIds',
                        display: 'Hold Flag Reason',
                        isHidden: true,
                        type: 'multi-select',
                        choices: holdsFlagReasons?.map(hfr => ({ value: hfr.id, display: hfr.name })) ?? []
                    }),
                ]
            }
        ]
    };

    return (
        <>
            <GridPage records={isLoadingAnything ? [] : holdFlags ?? []}
                      gridConfig={config}
                      defaultCriteria={defaultCriteria}
                      title={'Holds'}
                      sort={sort}
                      shouldShowCriteriaPanel={!orderId}
                      onNewModalButtonClicked={orderId ? onNewHoldFlagClicked : undefined}
                      onSortChanged={setSort}
                      onSearchChange={setSearch}
                      moreRecordsAvailable={moreAvailable}
                      onCriteriaChange={setCriteria}
                      onShouldFetchMore={fetchMore}
                      onRowClick={onRowClick}
                      isLoading={isLoadingAnything}/>

            {focusedHoldFlag &&
              <HoldEdit
                requestedHoldFlag={focusedHoldFlag}
                isOpen={editHoldFlagIsOpen}
                toggleModal={closeModal}/>
            }

            {orderId &&
              <HoldNew
                orderId={orderId}
                isOpen={newHoldFlagIsOpen}
                toggleModal={closeModal}/>
            }

        </>
    )
}