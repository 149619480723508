
import './CartProductView.css';
import {CartProductVM} from "../CartProduct";
import {
    useGetProductQuery,
    useGetSiteProductVariantQuantityOptionsQuery
} from "../../../app/apiSlice";
import {DisplayImage} from "../../image/display/DisplayImage";
import {Button, Input} from "reactstrap";
import {CurrencyFormatter} from "../../../utils/CurrencyFormatter";
import {Link} from "react-router-dom";
import {SiteProductVariantQuantityOption} from "../../product/SiteProductVariantQuantityOption";
import {SiteProductVariantPricing} from "../CartPricing";

interface CartProductViewProps {
    cartProduct: CartProductVM,
    siteProductVariantPricing?: SiteProductVariantPricing,
    onQuantityChanged: (cartProductId: number, quantityOption: SiteProductVariantQuantityOption) => void;
    onDeleteClick: (cartProductId: number) => void;
}

export const CartProductView = ({ cartProduct, siteProductVariantPricing, onQuantityChanged, onDeleteClick}: CartProductViewProps) => {

    const {data: siteProduct} = useGetProductQuery(cartProduct.siteProductId);
    const {data: siteProductVariantQuantityOptions} = useGetSiteProductVariantQuantityOptionsQuery(cartProduct.siteProductVariantId);

    function getPromotionCardImage() {
        if (!siteProduct || (!siteProduct.thumbnailId && !siteProduct.imageId)) {
            return <></>
        }
        return (
            <span className="cart-product-view-image">
                <DisplayImage imageId={siteProduct.thumbnailId ?? siteProduct.imageId}></DisplayImage>
            </span>
        );
    }

    const onQuantityChange = (selectedValue: string) => {
        const selectedQuantityOptionIndex = siteProductVariantQuantityOptions?.findIndex(spqo => spqo.id === parseInt(selectedValue));

        if (selectedQuantityOptionIndex !== undefined && selectedQuantityOptionIndex > -1 && siteProductVariantQuantityOptions) {
            onQuantityChanged(cartProduct.id, siteProductVariantQuantityOptions[selectedQuantityOptionIndex]);
        }
    }

    const getQuantityOptions = () => {
        if (!siteProductVariantQuantityOptions || siteProductVariantQuantityOptions.length < 1) {
            return <></>;
        }

        return (
            <div className="cart-product-view-information-row">

                <Input type="select" onChange={(e) => onQuantityChange(e.target.value)} value={cartProduct.quantityOption.id}>
                    {siteProductVariantQuantityOptions.filter(spvqo => spvqo.isActive).map((spvqo) => {
                        return (
                            <option key={spvqo.id} value={spvqo.id}>
                                {spvqo.description}
                            </option>
                        );
                    })}
                </Input>
            </div>
        );

    }

    const getDescriptiveConfigurationValues = () => {
        if (!cartProduct.descriptiveConfigurationValues || cartProduct.descriptiveConfigurationValues.length < 1) {
            return <></>;
        }

        return cartProduct.descriptiveConfigurationValues.map(dcv => {
            return (
                <div key={dcv} className="cart-product-view-information-row descriptive-configuration-value">
                    {dcv}
                </div>
            )
        });
    }

    if (!siteProduct || !siteProductVariantPricing) {
        return <></>;
    }

    return (
        <div className="cart-product-view-container" >

            <div className="cart-product-view-card">
                {getPromotionCardImage()}
                <div className="cart-product-view-information">

                    <div className="cart-product-view-information-row">
                        <h5>{siteProduct.name}</h5>
                        <h5>{CurrencyFormatter.format(siteProductVariantPricing.productTotal)}</h5>
                    </div>

                    {getQuantityOptions()}

                    {getDescriptiveConfigurationValues()}

                    <div className="cart-product-view-information-row-left-align">
                        <Link to={`/productConfiguration/${cartProduct.siteProductVariantId}/${cartProduct.quantityOption.id}?pageNumber=3&cartProductId=${cartProduct.id}`}>
                            {cartProduct.isConfigurable ? <Button color="link">Edit</Button> : <></>}
                        </Link>
                        <Button color="link" className="cart-product-view-delete-button" onClick={() => onDeleteClick(cartProduct.id)}>Delete</Button>
                    </div>

                </div>
            </div>
        </div>
    );
}

//<FreeShippingLabel />