import './AdminErrorMessage.css';
import {Container} from 'reactstrap';
import ScrollToTop from "../../../helpers/ScrollToTop";
import React, {ErrorInfo} from "react";
import {useTranslation} from "react-i18next";

interface AdminErrorMessageProps {
    errorInfo?: ErrorInfo
    error?: Error
}
export const AdminErrorMessage = ({errorInfo, error}: AdminErrorMessageProps) => {

    return (
        <span className='storefront-error-boundary-page d-flex flex-column'>
            <ScrollToTop></ScrollToTop>
            <Container>
                <span className='storefront-error-boundary-container d-flex flex-column'>
                    <span className='storefront-error-boundary-title d-flex'>
                        Something went wrong!
                    </span>
                    <span className='storefront-error-boundary-body d-flex flex-column'>
                        {error?.message}
                    </span>
                    <span className='storefront-error-boundary-body d-flex flex-column'>
                        {error?.stack}
                    </span>
                </span>
            </Container>
        </span>
    );
}