import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { ConsumerAddressInput } from "../../input/address/ConsumerAddressInput";
import { Address, DefaultAddressType } from "../../input/address/Address";
import "./BillingAddress.css";
import {useTranslation} from "react-i18next";

interface BillingAddressProps {
  consumerId?: number,
  address?: Address,
  onAddressChanged: (address?: Address) => void,
  onFillFromCheck?: () => void,
  locked?: boolean,
  useCardContainer: boolean,
  suppressError?: boolean
}
export const BillingAddress = (props: BillingAddressProps) => {    
    const { t } = useTranslation();
    
    const getBillingAddressContent = () => {
        return (props.useCardContainer) ?
            <Card id="billing-information-card">
                <CardHeader className='d-flex justify-content-between billing-address-card-header'>
                    <h5 className="m-0">Billing Address</h5>
                    {props.onFillFromCheck !== undefined &&
                        <Button color='primary' onClick={props.onFillFromCheck}>Fill from Check</Button>}
                </CardHeader>
                <CardBody>
                    <p>{t(`billing.message`)}</p>
                    {getAddressInput()}
                </CardBody>
            </Card> :
            <>
                <h5 className="m-0">Billing Address</h5>
                {getAddressInput()}
            </>
    }

    const getAddressInput = () => {
          return (
            <ConsumerAddressInput address={props.address}
                                  onAddressChanged={address => props.onAddressChanged(address)}
                                  consumerId={props.consumerId}
                                  defaultAddress={DefaultAddressType.Billing}
                                  locked={props.locked ?? false}
                                  suppressError={props.suppressError}/>
          )
    }

    return (
        getBillingAddressContent()
    );
};