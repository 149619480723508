import './SiteCardView.css';
import {CardItemBrowse} from './cardItem/CardItemBrowse';
import React from 'react';
import {useGetSiteCardsQuery} from "../../app/apiSlice";
import {PreviousOrdersBrowse} from "./cardItem/PreviousOrdersBrowse";
import {useTranslation} from "react-i18next";
import {TrustBanner} from "../trustBanner/TrustBanner";

export const SiteCardView = () => {
    const {data: siteCards} = useGetSiteCardsQuery();
    const {t} = useTranslation();

    return (
        <div className="site-card-browse-container">
            <PreviousOrdersBrowse/>

            {siteCards?.map((siteCard, index) => {
                let trustBanner = null;
                if (index === 1) {
                    trustBanner = <TrustBanner isFullWidth={true} showCheckIcon={true}/>;
                }
                return (
                    <>
                        {trustBanner}
                        <section>
                            <CardItemBrowse siteCard={siteCard} key={siteCard.id}></CardItemBrowse>
                        </section>
                    </>
                );
            })}

        </div>
    );
}