/**
 * Analytics configuration.
 * Values used in /utils/AnalyticsHelper.ts.
 */

export const googleAnalytics = {
	CheckWorks: {
		AnalyticsSendTo: "G-L0Z4NGR6SH",
		ConversionSendTo: "AW-1072738799/MN1YCNTDwAMQ7-PC_wM",
		ScriptSrc: "https://www.googletagmanager.com/gtag/js?id=G-L0Z4NGR6SH",
		IFrameScriptSrc: "https://www.googletagmanager.com/ns.html?id=GTM-TCZMLL9J",
		ScriptContent: [
			"(function(w,d,s,l,i) { w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); }) (window,document,'script','dataLayer','GTM-T52RVG4');",
			"window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-L0Z4NGR6SH'); gtag('config', 'AW-1072738799');"
		]
	},
	ChecksForFree: {
		AnalyticsSendTo: "G-F91KV6CZ48",
		ConversionSendTo: "AW-11300916091/ozaqCKLRweQYEPuW2Ywq",
		ScriptSrc: "https://www.googletagmanager.com/gtag/js?id=G-F91KV6CZ48",
		IFrameScriptSrc: "https://www.googletagmanager.com/ns.html?id=GTM-T52RVG4",
		ScriptContent: [
			"(function(w,d,s,l,i) { w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); }) (window,document,'script','dataLayer','GTM-TCZMLL9J');",
			"window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-F91KV6CZ48'); gtag('config', 'AW-11300916091');"
		]
	}
};

export const bingAnalytics = {
	CheckWorks: {
		IsSupported: true,
		ScriptContent: [
			"(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:\"4063925\"};o.q=w[u],w[u]=new UET(o),w[u].push(\"pageLoad\")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!==\"loaded\"&&s!==\"complete\"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,\"script\",\"//bat.bing.com/bat.js\",\"uetq\");\n"
		]
	},
	ChecksForFree: {
		IsSupported: false,
		ScriptContent: [
			"(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:\"109001185\", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push(\"pageLoad\")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!==\"loaded\"&&s!==\"complete\"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,\"script\",\"//bat.bing.com/bat.js\",\"uetq\");"
		]
	}
};
