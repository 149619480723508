import './UserLogin.css';
import { LoginForm } from './loginForm/LoginForm';
import {useSearchParams} from "react-router-dom";
import { Container } from 'reactstrap';

export const UserLogin = () => {
    const [searchParams] = useSearchParams();
    const redirectTo = searchParams.get("redirectTo");

    return (
        <span className='sign-in-page d-flex'>
            <Container className='sign-in-page-container d-flex flex-column'>
                <span className='sign-in-content d-flex flex-column'>
                   <LoginForm redirectLink={redirectTo ? redirectTo : '/'} />
                </span>
            </Container>
        </span>
    );
}