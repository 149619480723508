import { Input } from "reactstrap"
import { NumberStringStringTuple } from "../ConsumerControlListValuesVm"
import { ChangeEvent } from "react"

interface ControlListComboInputProps {
    id: string
    value1: string
    value2: string
    onChange: (value1: string, value2: string) => void
    options?: NumberStringStringTuple[]
    invalid?: boolean
}
export const ControlListComboInput = ({id, value1, value2, onChange, options, invalid}: ControlListComboInputProps) => {
    
    const onSelectChange = (e: ChangeEvent<HTMLInputElement>) => {
        const [val1, val2] = e.target.value.split('-');
        onChange(val1, val2);
    }
    
    return (
        <Input id={id} type='select' value={`${value1}-${value2}`} onChange={onSelectChange} invalid={invalid}>
            {options?.map(o =>
                <option key={`${o.item1}-${o.item2}-${o.item3}`} value={`${o.item2}-${o.item3}`}>{o.item2} - {o.item3}</option>
            )}
        </Input>
    )
}