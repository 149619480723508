import './Accordions.css';
import {useTranslation} from "react-i18next";
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap';
import { useState } from 'react';
import {doesTranslationValueExist, getTranslationValue} from "../../../../utils/Utils";

export const ContactAccordion = () => {
    const { t } = useTranslation();

    const [open, setOpen] = useState('');
    const toggle = (id: string) => setOpen(open === id ? '0' : id);

    return (
        <span className='contact-accordion-container'>
            <Accordion open={open} toggle={toggle}>
                {doesTranslationValueExist(t, 'pages.faq.contact.title1') && (
                  <AccordionItem>
                      <AccordionHeader targetId='1'>{t(`pages.faq.contact.title1`)}</AccordionHeader>
                      <AccordionBody accordionId='1'>
                        {doesTranslationValueExist(t, 'pages.faq.contact.backgroundBody') ? (
                          <span>{t(`pages.faq.contact.backgroundBody`)}</span>
                        ) : (
                          <span className='d-flex flex-column'>
                            <span className='d-flex flex-column'>
                              <span className='contact-info-title mb-0'>{getTranslationValue(t, `pages.faq.contact.subTitle1`)}</span>
                              <span className='mb-0'>{getTranslationValue(t, `address.name`)}</span>
                              <span className='mb-0'>{getTranslationValue(t, `address.part1`)}</span>
                              <span className='mb-0'>{getTranslationValue(t, `address.part2`)}</span>
                            </span>
                            <span className='d-flex flex-column'>
                              <span className='contact-info-title mb-0'>{getTranslationValue(t, `pages.faq.contact.subTitle2`)}</span>
                              <span>{getTranslationValue(t, 'phoneNumber.display')}</span>
                              <span>{getTranslationValue(t, 'pages.faq.contact.hours')}</span>
                            </span>
                            <span className='d-flex flex-column'>
                              <span className='contact-info-title mb-0'>{getTranslationValue(t, `pages.faq.contact.subTitle3`)}</span>
                              <a href={'mailto:' + getTranslationValue(t, 'emails.help')} target='_blank'>
                                {getTranslationValue(t, 'emails.help')}
                              </a>
                            </span>
                          </span>
                        )}
                      </AccordionBody>
                  </AccordionItem>
                )}
                {doesTranslationValueExist(t, 'pages.faq.contact.title2') && (
                  <AccordionItem>
                      <AccordionHeader targetId='2'>{t(`pages.faq.contact.title2`)}</AccordionHeader>
                      <AccordionBody accordionId='2'>
                        {doesTranslationValueExist(t, 'pages.faq.contact.bodyAddressTitle') ? (
                          <span className='contact-info-content d-flex'>
                            <span className='d-flex flex-column'>
                                <span className='contact-info-title'>{t(`pages.faq.contact.bodyAddressTitle`)}</span>
                                <span>{t(`address.name`)}</span>
                                <span>{t(`address.part1`)}</span>
                                <span>{t(`address.part2`)}</span>
                            </span>
                            <span className='d-flex flex-column'>
                                <span className='contact-info-title'>{t(`pages.faq.contact.bodyCustomerService`)}</span>
                                <span>{t(`phoneNumber.display`)}</span>
                                <span>{t(`pages.faq.contact.bodyHours`)}</span>
                            </span>
                            <span className='d-flex flex-column'>
                                <span className='contact-info-title'>{t(`pages.faq.contact.bodyEmailTitle`)}</span>
                                <span>{t(`emails.help`)}</span>
                            </span>
                        </span>
                        ) : (
                          <span>
                            <span className='contact-info-title'>{getTranslationValue(t, `pages.faq.help.contact.subTitle2`)}</span>
                            <span>{getTranslationValue(t, `phoneNumber.display`)}</span>
                            <span>{getTranslationValue(t, `pages.faq.help.contact.hours`)}</span>
                          </span>
                        )}
                      </AccordionBody>
                  </AccordionItem>
                )}
                {
                    doesTranslationValueExist(t, 'links.bbbMemberLink') && (
                      <AccordionItem>
                          <AccordionHeader targetId='3'>{t(`pages.faq.contact.title3`)}</AccordionHeader>
                          <AccordionBody accordionId='3'>
                            {doesTranslationValueExist(t, 'pages.faq.contact.bbbMemberLinkText') ? (
                              <span>{t(`pages.faq.contact.bbbMemberBody`)}<a href={t('pages.faq.contact.bbbMemberLink')} target='_blank'>{t('pages.faq.contact.bbbMemberLinkText')}</a></span>
                            ) : (
                              <span>
                                <span className='contact-info-title'>{getTranslationValue(t, `pages.faq.help.contact.subTitle3`)}</span>
                                <a href={'mailto:' + getTranslationValue(t, `emails.customerService`)} target='_blank'>
                                  {getTranslationValue(t, `emails.customerService`)}
                                </a>
                              </span>
                            )}
                          </AccordionBody>
                      </AccordionItem>
                    )}
            </Accordion>
        </span>
    )
}