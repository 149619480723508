import { Product } from "../product/Product";
import {OrderProductVm} from "./OrderProductVm";
import {Consumer} from "../admin/customer/Consumer";
import {CartIdType} from "../cart/CartSlice";
import {ShippingService} from "../shipping-options/ShippingService";
import {AddressVm} from "../input/address/Address";

export interface Order {
  id: number;
  timeCreated: string; timeCreatedLocal: string;
  timeLastUpdated: string; timeLastUpdatedLocal: string;
  timeOfOrderPayment?: string; timeOfOrderPaymentLocal?: string;
  totalCharged: number
  orderStatusId: number;
  orderOrigin: string;
  consumerId: number;
  
  siteName: string;
  siteId: number;
  consumerName: string;
  consumerEmail: string;
  status: string;
  createdBy: string;
  updatedBy: string;
  containsMicrProduct?: boolean;
  consumer: Consumer;
  shippingService?: ShippingService
}

export interface OrderVm extends Order {
  customerId: number;
  customerEmail: string;
  firstName: string;
  lastName: string;
  businessName?: string;
  phoneNumber?: string;
  routingNumber?: string;
  accountNumber?: string;
  products?: OrderProductVm[];
  status: string;
  address: AddressVm;
}

export interface OrderProduct extends Product {
  quantity: number;
  doNotReOrder: boolean;
  doNotShip: boolean;
}

export interface OrderTrackingFetch {
  zip: string;
  orderId: number;
}

export interface OrderTracking {
  id: number,
  order: Order,
  trackingNumber?: string;
  trackingUrl?: string;
  productName: string;
  carrier: string;
  isShipped: boolean;
  quantity: number;
  quantityToShip: number;
  shippingCarrierName: string;
  shippingOptionName: string;
}

export interface OrderEditCartDetails {
  orderId: number,
  orderProductId?: number,
  cartId: CartIdType
}

export interface OrderProductUpdateRequest {
  orderId: number,
  orderProductId?: number
  siteProductVariantQuantityOptionId?: number,
  isReorder?: boolean
}

export interface OrderCreationResponse {
  orderInvoiceGuid: string;
  orderId: number,
  isPartiallyComplete: boolean
}

export enum OrderStatus {
  NewOrder = 'New Order',
  Reprint = 'Reprint',
  BadData = 'Bad Data',
  Hold = 'Hold',
  Manufacturing = 'Manufacturing',
  Complete = 'Complete',
  Cancelled = 'Cancelled'

}