import './WhyCheckworks.css'
import {useTranslation} from "react-i18next";
import { Link, useLocation } from 'react-router-dom';
import {Container} from 'reactstrap';
import {doesTranslationValueExist, getTranslationValue} from "../../../utils/Utils";

export const WhyCheckworks = () => {
    const { t } = useTranslation();
    const path = useLocation().pathname;
    
    return (
        <div className={ path === "/" ? 'why-checkworks-section-home' : 'why-checkworks-section-product' }>
            <Container className='why-checkworks-container d-flex flex-row'>
                <div className='why-checkworks-content d-flex flex-column'>
                    <div className='why-checkworks-heading d-flex flex-column'>
                        <h6 className='sub-heading'>
                            {t(`footer.whyUs.title`)}
                        </h6>
                        <h2 className='header-text'>
                            {t(`footer.whyUs.contentTitle`)}
                        </h2>
                    </div>

                    {path === "/" &&
                        <div className='why-checkworks-body-container d-flex flex-column'>
                            <div className='why-checkworks-body d-flex flex-column'>
                                <p className='body-text'>
                                    {t(`footer.whyUs.content1`)}

                                    <>
                                        {doesTranslationValueExist(t, 'footer.whyUs.contentLink1') && (
                                            <>
                                                <a href={getTranslationValue(t, 'footer.whyUs.contentLink1')}>
                                                    {getTranslationValue(t, 'footer.whyUs.contentLinkText1')}
                                                </a>
                                            </>
                                        )}

                                        {doesTranslationValueExist(t, 'footer.whyUs.content2') && (
                                            <>{t(`footer.whyUs.content2`)}</>
                                        )}

                                        {doesTranslationValueExist(t, 'footer.whyUs.contentLink2') && (
                                            <>
                                                <a href={getTranslationValue(t, 'footer.whyUs.contentLink2')}>
                                                    {getTranslationValue(t, 'footer.whyUs.contentLinkText2')}
                                                </a>
                                            </>
                                        )}

                                        {doesTranslationValueExist(t, 'footer.whyUs.content3') && (
                                            <>{t(`footer.whyUs.content3`)}</>
                                        )}

                                        {doesTranslationValueExist(t, 'footer.whyUs.contentLink3') && (
                                            <>
                                                <a href={getTranslationValue(t, 'footer.whyUs.contentLink3')}>
                                                    {getTranslationValue(t, 'footer.whyUs.contentLinkText3')}
                                                </a>
                                            </>
                                        )}

                                        {doesTranslationValueExist(t, 'footer.whyUs.content4') && (
                                            <>{t(`footer.whyUs.content4`)}</>
                                        )}

                                        {doesTranslationValueExist(t, 'footer.whyUs.contentLink4') && (
                                            <>
                                                <a href={getTranslationValue(t, 'footer.whyUs.contentLink4')}>
                                                    {getTranslationValue(t, 'footer.whyUs.contentLinkText4')}
                                                </a>
                                            </>
                                        )}

                                        {doesTranslationValueExist(t, 'footer.whyUs.content5') && (
                                            <>{t(`footer.whyUs.content5`)}</>
                                        )}

                                        {doesTranslationValueExist(t, 'footer.whyUs.contentLink5') && (
                                            <>
                                                <a href={getTranslationValue(t, 'footer.whyUs.contentLink5')}>
                                                    {getTranslationValue(t, 'footer.whyUs.contentLinkText5')}
                                                </a>
                                            </>
                                        )}

                                        {doesTranslationValueExist(t, 'footer.whyUs.content6') && (
                                            <>{t(`footer.whyUs.content6`)}</>
                                        )}
                                    </>
                                </p>

                            </div>
                            <div className='why-checkworks-button d-flex flex-row'>
                                <Link className='btn-secondary d-flex flex-row' to='/about-us'>
                                    <h6>{t(`footer.whyUs.button`)}</h6>
                                </Link>
                            </div>
                        </div>
                    }

                    {/* First Column  */}
                    {path.includes("category") &&
                        <div className='why-checkworks-text-body-content'>    
                            <div className='why-checkworks-body d-flex flex-column'>
                                <span className='body-text-v2'>
                                    {t(`footer.whyUs.content1`)}
                                        {doesTranslationValueExist(t, 'footer.whyUs.contentLink1') && (
                                            <>
                                                <a href={getTranslationValue(t, 'footer.whyUs.contentLink1')}>
                                                    {getTranslationValue(t, 'footer.whyUs.contentLinkText1')}
                                                </a>
                                            </>
                                        )}
                                        
                                    {doesTranslationValueExist(t, 'footer.whyUs.content2') && (
                                        <>
                                            <>{t(`footer.whyUs.content2`)}</>
                                            {doesTranslationValueExist(t, 'footer.whyUs.contentLink2') && (
                                                <>
                                                    <a href={getTranslationValue(t, 'footer.whyUs.contentLink2')}>
                                                        {getTranslationValue(t, 'footer.whyUs.contentLinkText2')}
                                                    </a>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {doesTranslationValueExist(t, 'footer.whyUs.content3') && (
                                        <>
                                            {doesTranslationValueExist(t, 'footer.whyUs.content3') && (
                                                <>{t(`footer.whyUs.content3`)}</>
                                            )}
                                            {doesTranslationValueExist(t, 'footer.whyUs.contentLink3') && (
                                                <>
                                                    <a href={getTranslationValue(t, 'footer.whyUs.contentLink3')}>
                                                        {getTranslationValue(t, 'footer.whyUs.contentLinkText3')}
                                                    </a>
                                                </>
                                            )}
                                        </>
                                    )}
                                </span>
                            </div>

                            {/* Second Column  */}
                            <div className='why-checkworks-body d-flex flex-column'>
                                <span className='body-text-v2'>
                                {doesTranslationValueExist(t, 'footer.whyUs.content4') && (
                                        <>
                                            {doesTranslationValueExist(t, 'footer.whyUs.content4') && (
                                                <>{t(`footer.whyUs.content4`)}</>
                                            )}
                                            {doesTranslationValueExist(t, 'footer.whyUs.contentLink4') && (
                                                <>
                                                    <a href={getTranslationValue(t, 'footer.whyUs.contentLink4')}>
                                                        {getTranslationValue(t, 'footer.whyUs.contentLinkText4')}
                                                    </a>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {doesTranslationValueExist(t, 'footer.whyUs.content5') && (
                                        <>
                                            {doesTranslationValueExist(t, 'footer.whyUs.content5') && (
                                                <>{t(`footer.whyUs.content5`)}</>
                                            )}
                                            {doesTranslationValueExist(t, 'footer.whyUs.contentLink5') && (
                                                <>
                                                    <a href={getTranslationValue(t, 'footer.whyUs.contentLink5')}>
                                                        {getTranslationValue(t, 'footer.whyUs.contentLinkText5')}
                                                    </a>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {doesTranslationValueExist(t, 'footer.whyUs.content6') && (
                                        <>
                                            {doesTranslationValueExist(t, 'footer.whyUs.content6') && (
                                                <>{t(`footer.whyUs.content6`)}</>
                                            )}
                                            {doesTranslationValueExist(t, 'footer.whyUs.contentLink6') && (
                                                <>
                                                    <a href={getTranslationValue(t, 'footer.whyUs.contentLink6')}>
                                                        {getTranslationValue(t, 'footer.whyUs.contentLinkText6')}
                                                    </a>
                                                </>
                                            )}
                                        </>
                                    )}
                                </span>
                            </div>
                        </div>
                    }

                </div>
            </Container>
        </div>
    );
};